import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          General: "General",
          Dashboard: "Dashboard",
          Default: "Default",
          Project: "Project",
          Ecommerce: "Ecommerce",
          Education: "Education",
          Widgets: "Widgets",
          Chart: "Chart",
          Applications: "Applications",
          "Project-List": "Project List",
          "Create New": "Create New",
          "File Manager": "File Manager",
          "Add Products": "Add Product",
          Products: "Product",
          "Product Page": "Product Page",
          "Product List": "Product List",
          "Payment Details": "Payment Details",
          "Order History": "Order History",
          Invoice: "Invoice",
          "Invoice-1": "Invoice-1",
          "Invoice-2": "Invoice-2",
          "Invoice-3": "Invoice-3",
          "Invoice-4": "Invoice-4",
          "Invoice-5": "Invoice-5",
          "Invoice-6": "Invoice-6",
          Cart: "Cart",
          Wishlist: "Wishlist",
          Checkout: "Checkout",
          Pricing: "Pricing",
          Chat: "Chat",
          Users: "Users",
          "Letter Box": "Letter Box",
          "Private Chat": "Private Chat",
          "Group Chat": "Group Chat",
          "User Profile": "User Profile",
          "User Edit": "User Edit",
          "User Cards": "User Cards",
          Bookmark: "Bookmarks",
          Contact: "Contacts",
          Task: "Task",
          Calendar: "Calendar",
          "Social App": "Social App",
          Todo: "Todo",
          "Search Result": "Search Result",
          "Form Validation": "Form Validation",
          "Base Inputs": "Base Inputs",
          "Checkbox & Radio": "Checkbox & Radio",
          "Input Groups": "Input Groups",
          "Input Mask": "Input Mask",
          "Mega Option": "Mega Option",
          Datepicker: "Datepicker",
          Touchspin: "Touchspin",
          Switch: "Switch",
          Forms: "Forms",
          "Form Controls": "Form Controls",
          "Forms & Table": "Forms & Table",
          Typeahead: "Typeahead",
          Clipboard: "Clipboard",
          "Form Widget": "Form Widget",
          "Form Wizard 1": "Form Wizard 1",
          "Form Wizard 2": "Form Wizard 2",
          "Two Factor": "Two Factor",
          "Form Layout": "Form Layout",
          Table: "Table",
          "Reactstrap Tables": "Reactstrap Tables",
          "Basic Tables": "Basic Tables",
          "Table Components": "Table Components",
          "Data Tables": "Data Tables",
          "Basic Init": "Basic Init",
          "Advance Init": "Advance Init",
          API: "API",
          "Data Source": "Data Source",
          Components: "Components",
          Typography: "Typography",
          Avatars: "Avatars",
          "Helper Classes": "Helper Classes",
          Grid: "Grid",
          "Tag & Pills": "Tag & Pills",
          Progress: "Progress",
          Modal: "Modal",
          Alert: "Alert",
          Popover: "Popover",
          Tooltip: "Tooltip",
          Dropdown: "Dropdown",
          Accordion: "Accordion",
          Tabs: "Tabs",
          Lists: "Lists",
          "Bonus-Ui": "Bonus-Ui",
          Scrollable: "Scrollable",
          "Tree View": "Tree View",
          Toasts: "Toasts",
          Rating: "Rating",
          Dropzone: "Dropzone",
          Tour: "Tour",
          SweetAlert2: "SweetAlert2",
          "Reactstrap Carousel": "Reactstrap Carousel",
          Ribbons: "Ribbons",
          Pagination: "Pagination",
          Breadcrumb: "Breadcrumb",
          "Range Slider": "Range Slider",
          "Image Cropper": "Image Cropper",
          "Basic Card": "Basic Card",
          "Creative Card": "Creative Card",
          Timeline: "Timeline",
          Icons: "Icons",
          "Flag Icon": "Flag Icon",
          "Fontawesome Icon": "Fontawesome Icon",
          "Ico Icon": "Ico Icon",
          "Themify Icon": "Themify Icon",
          "Feather Icon": "Feather Icon",
          "Weather Icon": "Weather Icon",
          Buttons: "Buttons",
          "Default Style": "Default Style",
          "Flat Style": "Flat Style",
          "Edge Style": "Edge Style",
          "Raised Style": "Raised Style",
          "Button Group": "Button Group",
          Charts: "Charts",
          "Apex Chart": "Apex Chart",
          "Google Chart": "Google Chart",
          "Chart JS Chart": "Chart JS Chart",
          Pages: "Pages",
          "Sample Page": "Sample Page",
          Others: "Others",
          "Error Pages": "Error Pages",
          "Error 400": "Error 400",
          "Error 401": "Error 401",
          "Error 403": "Error 403",
          "Error 404": "Error 404",
          "Error 500": "Error 500",
          "Error 503": "Error 503",
          "Login Simple": "Login Simple",
          "Login with Bg Image": "Login with bg Image",
          "Login With Image Two": "Login With Image Two",
          "Login With Validation": "Login With Validation",
          "Login With Tooltip": "Login With Tooltip",
          "Login With Sweetalert": "Login With Sweetalert",
          "Register Simple": "Register Simple",
          "Register With Bg Image": "Register With Bg Image",
          "Register With Bg Two": "Register With Bg Two",
          "Register Wizard": "Register Wizard",
          "Unlock User": "Unlock User",
          "Forget Password": "Forget Password",
          "Reset Password": "Reset Password",
          Maintenance: "Maintenance",
          "Coming Simple": "Coming Simple",
          "Coming With Bg Video": "Coming With Bg Video",
          "Coming With Bg Image": "Coming With Bg Image",
          Miscellaneous: "Miscellaneous",
          Gallery: "Gallery",
          "Gallery Grids": "Gallery Grids",
          "Gallery Grid Desc": "Gallery Grid Desc",
          "Masonry Gallery": "Masonry Gallery",
          "Masonry With Desc": "Masonry With Desc",
          "Hover Effect": "Hover Effect",
          Blog: "Blog",
          "Blog Details": "Blog Details",
          "Blog Single": "Blog Single",
          "Add Post": "Add Post",
          FAQ: "FAQ",
          "Job Search": "Job Search",
          "Cards View": "Cards View",
          "List View": "List View",
          "Job Detail": "Job Detail",
          Apply: "Apply",
          Learning: "Learning",
          "Learning List": "Learning List",
          "Detailed Course": "Detailed Course",
          Map: "Map",
          "Google Map": "Google Map",
          "Leaflet Map": "Leaflet Map",
          Editor: "Editor",
          "CK Editor": "CK Editor",
          "MDE Editor": "MDE Editor",
          "ACE Editor": "ACE Editor",
          Knowledgebase: "Knowledgebase",
          SupportTicket: "SupportTicket",
          Authentication: "Authentication",
          "Login With Bg Image": "Login With Bg Image",
          "Coming Soon": "Coming Soon",
          "Pinned": "Pinned",
        },
      },
      cn: {
        translations: {
          Dashboards: "仪表盘",
          Widgets: "小部件",
          Project: "项目",
          Email: "电子邮件",
          Chat: "聊天",
          Users: "用户",
          Bookmarks: "书签",
          Contacts: "联系人",
          Tasks: "任务",
          Calendar: "日历",
          "Social App": "社交应用",
          Todo: "待办事项",
          "Search Result": "搜索结果",
          Forms: "表单",
          "Bonus Ui": "额外UI",
          Icons: "图标",
          Buttons: "按钮",
          Charts: "图表",
          "Sample Page": "示例页面",
          Others: "其他",
          Gallery: "画廊",
          Blog: "博客",
          FAQ: "常见问题",
          "Job Search": "职位搜索",
          Learning: "学习",
          Maps: "地图",
          Editor: "编辑器",
          Knowledgebase: "知识库",
          "Support Ticket": "支持工单",
          Default: "默认",
          Ecommerce: "电子商务",
          Crypto: "加密货币",
          General: "通用",
          Chart: "图表",
          "Project list": "项目列表",
          "Create list": "创建列表",
          "File Manager": "文件管理器",
          Product: "产品",
          "Product Page": "产品页面",
          "Product List": "产品列表",
          "Payment Detail": "支付详情",
          "Order History": "订单历史",
          Invoice: "发票",
          Cart: "购物车",
          Wishlist: "愿望清单",
          Checkout: "结账",
          Pricing: "定价",
          "Mail Box": "邮箱",
          "Read Mail": "阅读邮件",
          Compose: "撰写",
          ChatApp: "聊天应用",
          VideoCall: "视频通话",
          "User Profile": "用户资料",
          "User Edit": "编辑用户",
          "User Cards": "用户卡片",
          Draggable: "可拖动",
          "Form Controls": "表单控件",
          "Form Validation": "表单验证",
          "Basic Input": "基本输入",
          "Checkbox & Radio": "复选框和单选按钮",
          "Input Groups": "输入组",
          "Mega Option": "超级选项",
          "Form Widgets": "表单小部件",
          "Date picker": "日期选择器",
          Typeahead: "类型提示",
          "Date Time Picker": "日期时间选择器",
          "Date Rangepicker": "日期范围选择器",
          TouchSpin: "TouchSpin",
          "Select 2": "选择 2",
          Switch: "开关",
          ClipBoard: "剪贴板",
          Tables: "表格",
          "Reactstrap Tables": "Reactstrap 表格",
          "Basic Tables": "基本表格",
          "Sizing Tables": "调整大小的表格",
          "Border Tables": "边框表格",
          "Styling Tables": "样式表格",
          "Data Tables": "数据表",
          "Basic Init": "基本初始化",
          "Advance Init": "高级初始化",
          API: "API",
          "Data Sources": "数据源",
          "Ui-Kits": "UI工具包",
          Typography: "排版",
          Avatars: "头像",
          "Helper-Classes": "辅助类",
          Grid: "网格",
          "Tag & Pills": "标签与药丸",
          Progress: "进度",
          Modal: "模态框",
          Alert: "警告框",
          Popover: "弹出框",
          Tooltip: "提示框",
          Spinners: "加载动画",
          Dropdown: "下拉菜单",
          Accordion: "手风琴",
          Tabs: "选项卡",
          "Bootstrap Tabs": "Bootstrap 选项卡",
          "Line Tabs": "线性选项卡",
          Shadow: "阴影",
          List: "列表",
          Scrollable: "可滚动",
          "Bootstrap Notify": "Bootstrap 通知",
          "Tree View": "树视图",
          Step: "步骤",
          Rating: "评分",
          Dropzone: "文件上传",
          Tour: "新手引导",
          SweetAlert: "SweetAlert",
          "Owl Carousel": "Owl 走马灯",
          Ribbons: "带子",
          Pagination: "分页",
          Breadcrumb: "面包屑",
          "Range Slider": "范围滑块",
          "Image Cropper": "图片裁剪",
          Sticky: "粘性",
          "Drag and Drop": "拖放",
          Upload: "上传",
          "Basic Card": "基本卡片",
          "Creative Card": "创意卡片",
          "Tabbed Card": "选项卡式卡片",
          Timeline: "时间线",
          "Flag Icon": "旗帜图标",
          "Font Awesome": "字体图标",
          "Ico Icons": "Ico 图标",
          "Themify Icons": "Themify 图标",
          "Feather Icons": "Feather 图标",
          "Whether Icons": "Whether 图标",
          "Apex Charts": "Apex 图表",
          "Google Charts": "Google 图表",
          "ChartJs Charts": "ChartJs 图表",
          "Coming Soon": "即将上映",
          "Coming Sample": "即将上映示例",
          "Coming with Bg-Image": "即将上映带背景图片",
          Authentication: "身份验证",
          "Login Simple": "简单登录",
          "Login With bg image": "带背景图片的登录",
          "Login with image two": "带两张图片的登录",
          "Login with validation": "带验证的登录",
          "Login with tooltip": "带提示的登录",
          "Login with sweetaleart": "带 SweetAlert 的登录",
          "Register Simple": "简单注册",
          "Register with Bg image": "带背景图片的注册",
          "Register with Bg Video": "带背景视频的注册",
          "Unlock User": "解锁用户",
          "Forgot Password": "忘记密码",
          "Create Passward": "创建密码",
          Maintenance: "维护",
          "Error Page 1": "错误页面 1",
          "Error Page 2": "错误页面 2",
          "Error Page 3": "错误页面 3",
          "Error Page 4": "错误页面 4",
          "Gallery Grid": "画廊网格",
          "Gallery Grid Desc": "画廊网格描述",
        },
      },
      ae: {
        translations: {
          Dashboards: "لوحات القيادة",
          Widgets: "الحاجيات",
          Project: "مشروع",
          Email: "البريد الإلكتروني",
          Chat: "دردشة",
          Users: "المستخدمين",
          Bookmarks: "المرجعية",
          Contacts: "جهات الاتصال",
          Tasks: "المهام",
          Calendar: "التقويم",
          "Social App": "تطبيق اجتماعي",
          Todo: "المفكرة",
          "Search Result": "نتائج البحث",
          Forms: "النماذج",
          "Bonus Ui": "واجهة مكافأة",
          Icons: "الرموز",
          Buttons: "الأزرار",
          Charts: "الرسوم البيانية",
          "Sample Page": "صفحة عينة",
          Others: "آخرين",
          Gallery: "معرض الصور",
          Blog: "المدونة",
          FAQ: "الأسئلة الشائعة",
          "Job Search": "بحث عن وظيفة",
          Learning: "التعلم",
          Maps: "الخرائط",
          Editor: "المحرر",
          Knowledgebase: "قاعدة المعرفة",
          "Support Ticket": "تذكرة الدعم",
          Default: "افتراضي",
          Ecommerce: "التجارة الإلكترونية",
          Crypto: "عملة مشفرة",
          General: "عام",
          Chart: "رسم بياني",
          "Project list": "قائمة المشروع",
          "Create list": "إنشاء قائمة",
          "File Manager": "مدير الملفات",
          Product: "المنتج",
          "Product Page": "صفحة المنتج",
          "Product List": "قائمة المنتج",
          "Payment Detail": "تفاصيل الدفع",
          "Order History": "سجل الطلبات",
          Invoice: "الفاتورة",
          Cart: "السلة",
          Wishlist: "قائمة الرغبات",
          Checkout: "الدفع",
          Pricing: "التسعير",
          "Mail Box": "صندوق البريد",
          "Read Mail": "قراءة البريد",
          Compose: "كتابة",
          ChatApp: "تطبيق الدردشة",
          VideoCall: "مكالمة فيديو",
          "User Profile": "ملف المستخدم",
          "User Edit": "تعديل المستخدم",
          "User Cards": "بطاقات المستخدم",
          Draggable: "قابل للسحب",
          "Form Controls": "عناصر النموذج",
          "Form Validation": "التحقق من النموذج",
          "Basic Input": "إدخال أساسي",
          "Checkbox & Radio": "خانة الاختيار وزر الراديو",
          "Input Groups": "مجموعات الإدخال",
          "Mega Option": "خيارات ضخمة",
          "Form Widgets": "حاجيات النموذج",
          "Date picker": "منتقي التاريخ",
          Typeahead: "التلميح التلقائي",
          "Date Time Picker": "منتقي التاريخ والوقت",
          "Date Rangepicker": "منتقي نطاق التاريخ",
          TouchSpin: "لمس الدوران",
          "Select 2": "تحديد 2",
          Switch: "مفتاح التبديل",
          ClipBoard: "الحافظة",
          Tables: "الجداول",
          "Reactstrap Tables": "جداول Reactstrap",
          "Basic Tables": "الجداول الأساسية",
          "Sizing Tables": "تحديد حجم الجداول",
          "Border Tables": "جداول الحدود",
          "Styling Tables": "تنسيق الجداول",
          "Data Tables": "جداول البيانات",
          "Basic Init": "بدء أساسي",
          "Advance Init": "بدء متقدم",
          API: "واجهة برمجة التطبيقات",
          "Data Sources": "مصادر البيانات",
          "Ui-Kits": "أدوات واجهة المستخدم",
          Typography: "الأسلوب",
          Avatars: "الصور الرمزية",
          "Helper-Classes": "الفئات المساعدة",
          Grid: "الشبكة",
          "Tag & Pills": "علامات وأقراص",
          Progress: "التقدم",
          Modal: "النمط",
          Alert: "التنبيه",
          Popover: "البوبوفر",
          Tooltip: "تلميح",
          Spinners: "الرموز المتحركة",
          Dropdown: "القائمة المنسدلة",
          Accordion: "الأكورديون",
          Tabs: "علامات التبويب",
          "Bootstrap Tabs": "علامات تبويب Bootstrap",
          "Line Tabs": "علامات تبويب خطية",
          Shadow: "الظل",
          List: "القائمة",
          Scrollable: "قابل للتمرير",
          "Bootstrap Notify": "Bootstrap Notify",
          "Tree View": "عرض الشجرة",
          Step: "الخطوة",
          Rating: "التقييم",
          Dropzone: "منطقة الإسقاط",
          Tour: "جولة",
          SweetAlert: "سويت التنبيه",
          "Owl Carousel": "أويل كاروسيل",
          Ribbons: "شرائط",
          Pagination: "الترقيم",
          Breadcrumb: "فتات الخبز",
          "Range Slider": "شريط التمرير",
          "Image Cropper": "أداة القص للصور",
          Sticky: "ملتصق",
          "Drag and Drop": "اسحب وأسقط",
          Upload: "تحميل",
          "Basic Card": "بطاقة أساسية",
          "Creative Card": "بطاقة إبداعية",
          "Tabbed Card": "بطاقة بعلامات تبويب",
          Timeline: "الجدول الزمني",
          "Flag Icon": "أيقونة العلم",
          "Font Awesome": "الخط الرائع",
          "Ico Icons": "أيقونات ICO",
          "Themify Icons": "أيقونات Themify",
          "Feather Icons": "أيقونات Feather",
          "Whether Icons": "أيقونات Weather",
          "Apex Charts": "رسومات Apex",
          "Google Charts": "رسومات Google",
          "ChartJs Charts": "رسومات ChartJs",
          "Coming Soon": "قريبًا",
          "Coming Sample": "مثال قادم",
          "Coming with Bg-Image": "قريبًا مع صورة الخلفية",
          Authentication: "المصادقة",
          "Login Simple": "تسجيل الدخول البسيط",
          "Login With bg image": "تسجيل الدخول مع صورة الخلفية",
          "Login with image two": "تسجيل الدخول مع صورتين",
          "Login with validation": "تسجيل الدخول مع التحقق",
          "Login with tooltip": "تسجيل الدخول مع التلميح",
          "Login with sweetaleart": "تسجيل الدخول مع SweetAlert",
          "Register Simple": "التسجيل البسيط",
          "Register with Bg image": "التسجيل مع صورة الخلفية",
          "Register with Bg Video": "التسجيل مع فيديو الخلفية",
          "Unlock User": "فتح المستخدم",
          "Forgot Password": "نسيت كلمة المرور",
          "Create Passward": "إنشاء كلمة مرور",
          Maintenance: "الصيانة",
          "Error Page 1": "صفحة الخطأ 1",
          "Error Page 2": "صفحة الخطأ 2",
          "Error Page 3": "صفحة الخطأ 3",
          "Error Page 4": "صفحة الخطأ 4",
          "Gallery Grid": "شبكة المعرض",
          "Gallery Grid Desc": "وصف شبكة المعرض",
        },
      },
      du: {
        translations: {
          Dashboards: "Dashboards",
          Widgets: "Widgets",
          Project: "Projekt",
          Email: "E-Mail",
          Chat: "Chat",
          Users: "Benutzer",
          Bookmarks: "Lesezeichen",
          Contacts: "Kontakte",
          Tasks: "Aufgaben",
          Calendar: "Kalender",
          "Social App": "Soziale App",
          Todo: "To-Do",
          "Search Result": "Suchergebnis",
          Forms: "Formulare",
          "Bonus Ui": "Bonus-Benutzeroberfläche",
          Icons: "Symbole",
          Buttons: "Schaltflächen",
          Charts: "Diagramme",
          "Sample Page": "Beispiel-Seite",
          Others: "Andere",
          Gallery: "Galerie",
          Blog: "Blog",
          FAQ: "FAQ",
          "Job Search": "Jobsuche",
          Learning: "Lernen",
          Maps: "Karten",
          Editor: "Editor",
          Knowledgebase: "Wissensdatenbank",
          "Support Ticket": "Support-Ticket",
          Default: "Standard",
          Ecommerce: "E-Commerce",
          Crypto: "Kryptowährung",
          General: "Allgemein",
          Chart: "Diagramm",
          "Project list": "Projektliste",
          "Create list": "Liste erstellen",
          "File Manager": "Dateimanager",
          Product: "Produkt",
          "Product Page": "Produktseite",
          "Product List": "Produktliste",
          "Payment Detail": "Zahlungsdetails",
          "Order History": "Bestellverlauf",
          Invoice: "Rechnung",
          Cart: "Warenkorb",
          Wishlist: "Wunschliste",
          Checkout: "Kasse",
          Pricing: "Preisgestaltung",
          "Mail Box": "Postfach",
          "Read Mail": "E-Mail lesen",
          Compose: "Verfassen",
          ChatApp: "Chat-Anwendung",
          VideoCall: "Videoanruf",
          "User Profile": "Benutzerprofil",
          "User Edit": "Benutzer bearbeiten",
          "User Cards": "Benutzerkarten",
          Draggable: "Ziehbar",
          "Form Controls": "Formularelemente",
          "Form Validation": "Formularvalidierung",
          "Basic Input": "Grundlegende Eingabe",
          "Checkbox & Radio": "Checkbox & Radio",
          "Input Groups": "Eingabegruppen",
          "Mega Option": "Mega-Option",
          "Form Widgets": "Formular-Widgets",
          "Date picker": "Datumsauswahl",
          Typeahead: "Autovervollständigung",
          "Date Time Picker": "Datums- und Uhrzeitauswahl",
          "Date Rangepicker": "Datumsbereichsauswahl",
          TouchSpin: "TouchSpin",
          "Select 2": "Select 2",
          Switch: "Schalter",
          ClipBoard: "Zwischenablage",
          Tables: "Tabellen",
          "Reactstrap Tables": "Reactstrap-Tabellen",
          "Basic Tables": "Grundlegende Tabellen",
          "Sizing Tables": "Tabellengrößenanpassung",
          "Border Tables": "Tabellen mit Rahmen",
          "Styling Tables": "Tabellen-Styling",
          "Data Tables": "Daten-Tabellen",
          "Basic Init": "Grundlegende Initialisierung",
          "Advance Init": "Erweiterte Initialisierung",
          API: "API",
          "Data Sources": "Datenquellen",
          "Ui-Kits": "UI-Kits",
          Typography: "Typografie",
          Avatars: "Avatare",
          "Helper-Classes": "Hilfsklassen",
          Grid: "Raster",
          "Tag & Pills": "Tags & Pillen",
          Progress: "Fortschritt",
          Modal: "Modales Fenster",
          Alert: "Warnung",
          Popover: "Popover",
          Tooltip: "Tooltip",
          Spinners: "Ladeanzeigen",
          Dropdown: "Dropdown-Menü",
          Accordion: "Akkordeon",
          Tabs: "Tabs",
          "Bootstrap Tabs": "Bootstrap Tabs",
          "Line Tabs": "Linien-Tabs",
          Shadow: "Schatten",
          List: "Liste",
          Scrollable: "Scrollbar",
          "Bootstrap Notify": "Bootstrap Benachrichtigungen",
          "Tree View": "Baumansicht",
          Step: "Schritt",
          Rating: "Bewertung",
          Dropzone: "Dropzone",
          Tour: "Tour",
          SweetAlert: "SweetAlert",
          "Owl Carousel": "Owl Carousel",
          Ribbons: "Ribbons",
          Pagination: "Seitennummerierung",
          Breadcrumb: "Breadcrumb",
          "Range Slider": "Bereichsschieberegler",
          "Image Cropper": "Bildzuschneider",
          Sticky: "Sticky",
          "Drag and Drop": "Ziehen und Ablegen",
          Upload: "Hochladen",
          "Basic Card": "Grundkarte",
          "Creative Card": "Kreative Karte",
          "Tabbed Card": "Tabbed-Karte",
          Timeline: "Zeitleiste",
          "Flag Icon": "Flaggensymbol",
          "Font Awesome": "Font Awesome",
          "Ico Icons": "Ico Icons",
          "Themify Icons": "Themify Icons",
          "Feather Icons": "Feather Icons",
          "Whether Icons": "Whether Icons",
          "Apex Charts": "Apex Charts",
          "Google Charts": "Google Charts",
          "ChartJs Charts": "ChartJs Charts",
          "Coming Soon": "Demnächst",
          "Coming Sample": "Beispiel kommt bald",
          "Coming with Bg-Image": "Kommt mit Hintergrundbild",
          Authentication: "Authentifizierung",
          "Login Simple": "Einfache Anmeldung",
          "Login With bg image": "Anmeldung mit Hintergrundbild",
          "Login with image two": "Anmeldung mit zwei Bildern",
          "Login with validation": "Anmeldung mit Validierung",
          "Login with tooltip": "Anmeldung mit Tooltip",
          "Login with sweetaleart": "Anmeldung mit SweetAlert",
          "Register Simple": "Einfache Registrierung",
          "Register with Bg image": "Registrierung mit Hintergrundbild",
          "Register with Bg Video": "Registrierung mit Hintergrundvideo",
          "Unlock User": "Benutzer entsperren",
          "Forgot Password": "Passwort vergessen",
          "Create Passward": "Passwort erstellen",
          Maintenance: "Wartung",
          "Error Page 1": "Fehlerseite 1",
          "Error Page 2": "Fehlerseite 2",
          "Error Page 3": "Fehlerseite 3",
          "Error Page 4": "Fehlerseite 4",
          "Gallery Grid": "Galerie-Raster",
          "Gallery Grid Desc": "Beschreibung des Galerie-Rasters",
        },
      },
      es: {
        translations: {
          Dashboards: "Tableros de control",
          Widgets: "Widgets",
          Project: "Proyecto",
          Email: "Correo electrónico",
          Chat: "Chat",
          Users: "Usuarios",
          Bookmarks: "Marcadores",
          Contacts: "Contactos",
          Tasks: "Tareas",
          Calendar: "Calendario",
          "Social App": "Aplicación social",
          Todo: "Tareas pendientes",
          "Search Result": "Resultado de búsqueda",
          Forms: "Formularios",
          "Bonus Ui": "UI adicional",
          Icons: "Iconos",
          Buttons: "Botones",
          Charts: "Gráficos",
          "Sample Page": "Página de muestra",
          Others: "Otros",
          Gallery: "Galería",
          Blog: "Blog",
          FAQ: "Preguntas frecuentes",
          "Job Search": "Búsqueda de empleo",
          Learning: "Aprendizaje",
          Maps: "Mapas",
          Editor: "Editor",
          Knowledgebase: "Base de conocimientos",
          "Support Ticket": "Ticket de soporte",
          Default: "Predeterminado",
          Ecommerce: "Comercio electrónico",
          Crypto: "Criptomonedas",
          General: "General",
          Chart: "Gráfico",
          "Project list": "Lista de proyectos",
          "Create list": "Crear lista",
          "File Manager": "Gestor de archivos",
          Product: "Producto",
          "Product Page": "Página de producto",
          "Product List": "Lista de productos",
          "Payment Detail": "Detalles de pago",
          "Order History": "Historial de pedidos",
          Invoice: "Factura",
          Cart: "Carrito",
          Wishlist: "Lista de deseos",
          Checkout: "Pagar",
          Pricing: "Precios",
          "Mail Box": "Buzón de correo",
          "Read Mail": "Leer correo",
          Compose: "Redactar",
          ChatApp: "Aplicación de chat",
          VideoCall: "Video llamada",
          "User Profile": "Perfil de usuario",
          "User Edit": "Editar usuario",
          "User Cards": "Tarjetas de usuario",
          Draggable: "Arrastrable",
          "Form Controls": "Controles de formulario",
          "Form Validation": "Validación de formulario",
          "Basic Input": "Entrada básica",
          "Checkbox & Radio": "Casilla de verificación y Radio",
          "Input Groups": "Grupos de entrada",
          "Mega Option": "Opción mega",
          "Form Widgets": "Widgets de formulario",
          "Date picker": "Selector de fecha",
          Typeahead: "Búsqueda predictiva",
          "Date Time Picker": "Selector de fecha y hora",
          "Date Rangepicker": "Selector de rango de fechas",
          TouchSpin: "TouchSpin",
          "Select 2": "Seleccionar 2",
          Switch: "Interruptor",
          ClipBoard: "Portapapeles",
          Tables: "Tablas",
          "Reactstrap Tables": "Tablas de Reactstrap",
          "Basic Tables": "Tablas básicas",
          "Sizing Tables": "Ajuste de tamaño de tablas",
          "Border Tables": "Tablas con bordes",
          "Styling Tables": "Estilo de tablas",
          "Data Tables": "Tablas de datos",
          "Basic Init": "Inicialización básica",
          "Advance Init": "Inicialización avanzada",
          API: "API",
          "Data Sources": "Fuentes de datos",
          "Ui-Kits": "Kits de UI",
          Typography: "Tipografía",
          Avatars: "Avatares",
          "Helper-Classes": "Clases de ayuda",
          Grid: "Cuadrícula",
          "Tag & Pills": "Etiquetas y Píldoras",
          Progress: "Progreso",
          Modal: "Modal",
          Alert: "Alerta",
          Popover: "Popover",
          Tooltip: "Tooltip",
          Spinners: "Indicadores de carga",
          Dropdown: "Menú desplegable",
          Accordion: "Acordeón",
          Tabs: "Pestañas",
          "Bootstrap Tabs": "Pestañas de Bootstrap",
          "Line Tabs": "Pestañas de línea",
          Shadow: "Sombra",
          List: "Lista",
          Scrollable: "Desplazable",
          "Bootstrap Notify": "Notificaciones de Bootstrap",
          "Tree View": "Vista de árbol",
          Step: "Paso",
          Rating: "Calificación",
          Dropzone: "Zona de soltar archivos",
          Tour: "Tour",
          SweetAlert: "SweetAlert",
          "Owl Carousel": "Carrusel de búhos",
          Ribbons: "Cintas",
          Pagination: "Paginación",
          Breadcrumb: "Migajas de pan",
          "Range Slider": "Control deslizante de rango",
          "Image Cropper": "Recortador de imágenes",
          Sticky: "Adhesivo",
          "Drag and Drop": "Arrastrar y soltar",
          Upload: "Subir",
          "Basic Card": "Tarjeta básica",
          "Creative Card": "Tarjeta creativa",
          "Tabbed Card": "Tarjeta con pestañas",
          Timeline: "Línea de tiempo",
          "Flag Icon": "Icono de bandera",
          "Font Awesome": "Fuente impresionante",
          "Ico Icons": "Iconos de Ico",
          "Themify Icons": "Iconos Themify",
          "Feather Icons": "Iconos Feather",
          "Whether Icons": "Iconos Whether",
          "Apex Charts": "Gráficos de Apex",
          "Google Charts": "Gráficos de Google",
          "ChartJs Charts": "Gráficos de ChartJs",
          "Coming Soon": "Próximamente",
          "Coming Sample": "Muestra de próximamente",
          "Coming with Bg-Image": "Próximamente con imagen de fondo",
          Authentication: "Autenticación",
          "Login Simple": "Inicio de sesión simple",
          "Login With bg image": "Inicio de sesión con imagen de fondo",
          "Login with image two": "Inicio de sesión con dos imágenes",
          "Login with validation": "Inicio de sesión con validación",
          "Login with tooltip": "Inicio de sesión con tooltip",
          "Login with sweetaleart": "Inicio de sesión con SweetAlert",
          "Register Simple": "Registro simple",
          "Register with Bg image": "Registro con imagen de fondo",
          "Register with Bg Video": "Registro con vídeo de fondo",
          "Unlock User": "Desbloquear usuario",
          "Forgot Password": "Olvidé mi contraseña",
          "Create Passward": "Crear contraseña",
          Maintenance: "Mantenimiento",
          "Error Page 1": "Página de error 1",
          "Error Page 2": "Página de error 2",
          "Error Page 3": "Página de error 3",
          "Error Page 4": "Página de error 4",
          "Gallery Grid": "Cuadrícula de galería",
          "Gallery Grid Desc": "Descripción de la cuadrícula de galería",
        },
      },
      fr: {
        translations: {
          Dashboards: "Tableaux de bord",
          Widgets: "Widgets",
          Project: "Projet",
          Email: "Email",
          Chat: "Chat",
          Users: "Utilisateurs",
          Bookmarks: "Signets",
          Contacts: "Contacts",
          Tasks: "Tâches",
          Calendar: "Calendrier",
          "Social App": "Application sociale",
          Todo: "À faire",
          "Search Result": "Résultats de recherche",
          Forms: "Formulaires",
          "Bonus Ui": "UI bonus",
          Icons: "Icônes",
          Buttons: "Boutons",
          Charts: "Graphiques",
          "Sample Page": "Page d'exemple",
          Others: "Autres",
          Gallery: "Galerie",
          Blog: "Blog",
          FAQ: "FAQ",
          "Job Search": "Recherche d'emploi",
          Learning: "Apprentissage",
          Maps: "Cartes",
          Editor: "Éditeur",
          Knowledgebase: "Base de connaissances",
          "Support Ticket": "Ticket de support",
          Default: "Par défaut",
          Ecommerce: "Commerce électronique",
          Crypto: "Crypto",
          General: "Général",
          Chart: "Graphique",
          "Project list": "Liste de projets",
          "Create list": "Créer une liste",
          "File Manager": "Gestionnaire de fichiers",
          Product: "Produit",
          "Product Page": "Page produit",
          "Product List": "Liste de produits",
          "Payment Detail": "Détail du paiement",
          "Order History": "Historique des commandes",
          Invoice: "Facture",
          Cart: "Panier",
          Wishlist: "Liste de souhaits",
          Checkout: "Paiement",
          Pricing: "Tarification",
          "Mail Box": "Boîte de réception",
          "Read Mail": "Lire le courrier",
          Compose: "Composer",
          ChatApp: "Application de chat",
          VideoCall: "Appel vidéo",
          "User Profile": "Profil de l'utilisateur",
          "User Edit": "Modifier l'utilisateur",
          "User Cards": "Cartes d'utilisateur",
          Draggable: "Glisser-déposer",
          "Form Controls": "Contrôles de formulaire",
          "Form Validation": "Validation de formulaire",
          "Basic Input": "Saisie de base",
          "Checkbox & Radio": "Cases à cocher et radio",
          "Input Groups": "Groupes d'entrée",
          "Mega Option": "Option méga",
          "Form Widgets": "Widgets de formulaire",
          "Date picker": "Sélecteur de date",
          Typeahead: "Suggestion de texte",
          "Date Time Picker": "Sélecteur de date et heure",
          "Date Rangepicker": "Plage de dates",
          TouchSpin: "TouchSpin",
          "Select 2": "Sélection 2",
          Switch: "Interrupteur",
          ClipBoard: "Presse-papiers",
          Tables: "Tables",
          "Reactstrap Tables": "Tables Reactstrap",
          "Basic Tables": "Tables de base",
          "Sizing Tables": "Tables de dimensionnement",
          "Border Tables": "Tables bordées",
          "Styling Tables": "Styles de table",
          "Data Tables": "Tables de données",
          "Basic Init": "Initialisation de base",
          "Advance Init": "Initialisation avancée",
          API: "API",
          "Data Sources": "Sources de données",
          "Ui-Kits": "Kits d'interface utilisateur",
          Typography: "Typographie",
          Avatars: "Avatars",
          "Helper-Classes": "Classes d'aide",
          Grid: "Grille",
          "Tag & Pills": "Balises et pilules",
          Progress: "Progression",
          Modal: "Modale",
          Alert: "Alerte",
          Popover: "Pop-over",
          Tooltip: "Info-bulle",
          Spinners: "Spinner",
          Dropdown: "Menu déroulant",
          Accordion: "Accordéon",
          Tabs: "Onglets",
          "Bootstrap Tabs": "Onglets Bootstrap",
          "Line Tabs": "Onglets linéaires",
          Shadow: "Ombre",
          List: "Liste",
          Scrollable: "Défilement",
          "Bootstrap Notify": "Notifier Bootstrap",
          "Tree View": "Vue arborescente",
          Step: "Étape",
          Rating: "Évaluation",
          Dropzone: "Zone de dépôt",
          Tour: "Visite guidée",
          SweetAlert: "SweetAlert",
          "Owl Carousel": "Carrousel Owl",
          Ribbons: "Rubans",
          Pagination: "Pagination",
          Breadcrumb: "Fil d'Ariane",
          "Range Slider": "Curseur de plage",
          "Image Cropper": "Recadrage d'image",
          Sticky: "Collant",
          "Drag and Drop": "Glisser-déposer",
          Upload: "Télécharger",
          "Basic Card": "Carte de base",
          "Creative Card": "Carte créative",
          "Tabbed Card": "Carte à onglets",
          Timeline: "Chronologie",
          "Flag Icon": "Icône de drapeau",
          "Font Awesome": "Police impressionnante",
          "Ico Icons": "Icônes Ico",
          "Themify Icons": "Icônes Themify",
          "Feather Icons": "Icônes Feather",
          "Whether Icons": "Icônes Whether",
          "Apex Charts": "Graphiques Apex",
          "Google Charts": "Graphiques Google",
          "ChartJs Charts": "Graphiques ChartJs",
          "Coming Soon": "Bientôt disponible",
          "Coming Sample": "Échantillon à venir",
          "Coming with Bg-Image": "À venir avec image de fond",
          Authentication: "Authentification",
          "Login Simple": "Connexion simple",
          "Login With bg image": "Connexion avec image de fond",
          "Login with image two": "Connexion avec deux images",
          "Login with validation": "Connexion avec validation",
          "Login with tooltip": "Connexion avec info-bulle",
          "Login with sweetaleart": "Connexion avec SweetAlert",
          "Register Simple": "Inscription simple",
          "Register with Bg image": "Inscription avec image de fond",
          "Register with Bg Video": "Inscription avec vidéo de fond",
          "Unlock User": "Déverrouiller l'utilisateur",
          "Forgot Password": "Mot de passe oublié",
          "Create Passward": "Créer un mot de passe",
          Maintenance: "Maintenance",
          "Error Page 1": "Page d'erreur 1",
          "Error Page 2": "Page d'erreur 2",
          "Error Page 3": "Page d'erreur 3",
          "Error Page 4": "Page d'erreur 4",
          "Gallery Grid": "Grille de galerie",
          "Gallery Grid Desc": "Description de la grille de galerie",
        },
      },
      pt: {
        translations: {
          Dashboards: "Painéis",
          Widgets: "Widgets",
          Project: "Projeto",
          Email: "Email",
          Chat: "Chat",
          Users: "Usuários",
          Bookmarks: "Marcadores",
          Contacts: "Contatos",
          Tasks: "Tarefas",
          Calendar: "Calendário",
          "Social App": "Aplicativo Social",
          Todo: "Tarefas Pendentes",
          "Search Result": "Resultado da Pesquisa",
          Forms: "Formulários",
          "Bonus Ui": "UI de Bônus",
          Icons: "Ícones",
          Buttons: "Botões",
          Charts: "Gráficos",
          "Sample Page": "Página de Exemplo",
          Others: "Outros",
          Gallery: "Galeria",
          Blog: "Blog",
          FAQ: "Perguntas Frequentes",
          "Job Search": "Busca por Empregos",
          Learning: "Aprendizado",
          Maps: "Mapas",
          Editor: "Editor",
          Knowledgebase: "Base de Conhecimento",
          "Support Ticket": "Ticket de Suporte",
          Default: "Padrão",
          Ecommerce: "Comércio Eletrônico",
          Crypto: "Criptomoeda",
          General: "Geral",
          Chart: "Gráfico",
          "Project list": "Lista de Projetos",
          "Create list": "Criar Lista",
          "File Manager": "Gerenciador de Arquivos",
          Product: "Produto",
          "Product Page": "Página do Produto",
          "Product List": "Lista de Produtos",
          "Payment Detail": "Detalhes de Pagamento",
          "Order History": "Histórico de Pedidos",
          Invoice: "Fatura",
          Cart: "Carrinho",
          Wishlist: "Lista de Desejos",
          Checkout: "Finalizar Compra",
          Pricing: "Preços",
          "Mail Box": "Caixa de Entrada",
          "Read Mail": "Ler Email",
          Compose: "Escrever",
          ChatApp: "Aplicativo de Chat",
          VideoCall: "Videochamada",
          "User Profile": "Perfil de Usuário",
          "User Edit": "Editar Usuário",
          "User Cards": "Cartões de Usuário",
          Draggable: "Arrastável",
          "Form Controls": "Controles de Formulário",
          "Form Validation": "Validação de Formulário",
          "Basic Input": "Entrada Básica",
          "Checkbox & Radio": "Checkbox e Rádio",
          "Input Groups": "Grupos de Entrada",
          "Mega Option": "Opção Mega",
          "Form Widgets": "Widgets de Formulário",
          "Date picker": "Selecionador de Data",
          Typeahead: "Sugestão de Digitação",
          "Date Time Picker": "Selecionador de Data e Hora",
          "Date Rangepicker": "Intervalo de Datas",
          TouchSpin: "TouchSpin",
          "Select 2": "Selecionar 2",
          Switch: "Interruptor",
          ClipBoard: "Área de Transferência",
          Tables: "Tabelas",
          "Reactstrap Tables": "Tabelas Reactstrap",
          "Basic Tables": "Tabelas Básicas",
          "Sizing Tables": "Ajuste de Tabelas",
          "Border Tables": "Tabelas com Bordas",
          "Styling Tables": "Estilização de Tabelas",
          "Data Tables": "Tabelas de Dados",
          "Basic Init": "Inicialização Básica",
          "Advance Init": "Inicialização Avançada",
          API: "API",
          "Data Sources": "Fontes de Dados",
          "Ui-Kits": "Kits de UI",
          Typography: "Tipografia",
          Avatars: "Avatares",
          "Helper-Classes": "Classes Auxiliares",
          Grid: "Grade",
          "Tag & Pills": "Etiquetas e Pílulas",
          Progress: "Progresso",
          Modal: "Modal",
          Alert: "Alerta",
          Popover: "Popover",
          Tooltip: "Dica de Ferramenta",
          Spinners: "Indicadores de Carregamento",
          Dropdown: "Menu Suspenso",
          Accordion: "Acordeão",
          Tabs: "Guias",
          "Bootstrap Tabs": "Guias Bootstrap",
          "Line Tabs": "Guias Lineares",
          Shadow: "Sombra",
          List: "Lista",
          Scrollable: "Rolável",
          "Bootstrap Notify": "Bootstrap Notify",
          "Tree View": "Visão de Árvore",
          Step: "Passo",
          Rating: "Avaliação",
          Dropzone: "Área de Descarte",
          Tour: "Tour",
          SweetAlert: "SweetAlert",
          "Owl Carousel": "Carrossel de Coruja",
          Ribbons: "Fitas",
          Pagination: "Paginação",
          Breadcrumb: "Breadcrumb",
          "Range Slider": "Controle Deslizante de Faixa",
          "Image Cropper": "Cortador de Imagem",
          Sticky: "Fixo",
          "Drag and Drop": "Arrastar e Soltar",
          Upload: "Upload",
          "Basic Card": "Cartão Básico",
          "Creative Card": "Cartão Criativo",
          "Tabbed Card": "Cartão com Abas",
          Timeline: "Linha do Tempo",
          "Flag Icon": "Ícone de Bandeira",
          "Font Awesome": "Font Awesome",
          "Ico Icons": "Ícones Ico",
          "Themify Icons": "Ícones Themify",
          "Feather Icons": "Ícones Feather",
          "Whether Icons": "Ícones Whether",
          "Apex Charts": "Gráficos Apex",
          "Google Charts": "Gráficos Google",
          "ChartJs Charts": "Gráficos ChartJs",
          "Coming Soon": "Em Breve",
          "Coming Sample": "Amostra Em Breve",
          "Coming with Bg-Image": "Em Breve com Imagem de Fundo",
          Authentication: "Autenticação",
          "Login Simple": "Login Simples",
          "Login With bg image": "Login com Imagem de Fundo",
          "Login with image two": "Login com Duas Imagens",
          "Login with validation": "Login com Validação",
          "Login with tooltip": "Login com Dica de Ferramenta",
          "Login with sweetaleart": "Login com SweetAlert",
          "Register Simple": "Registro Simples",
          "Register with Bg image": "Registro com Imagem de Fundo",
          "Register with Bg Video": "Registro com Vídeo de Fundo",
          "Unlock User": "Desbloquear Usuário",
          "Forgot Password": "Esqueci a Senha",
          "Create Passward": "Criar Senha",
          Maintenance: "Manutenção",
          "Error Page 1": "Página de Erro 1",
          "Error Page 2": "Página de Erro 2",
          "Error Page 3": "Página de Erro 3",
          "Error Page 4": "Página de Erro 4",
          "Gallery Grid": "Grade de Galeria",
          "Gallery Grid Desc": "Descrição da Grade de Galeria",
        },
      },
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
