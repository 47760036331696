import App from "./App";
import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "@fortawesome/free-solid-svg-icons";
import "@fortawesome/react-fontawesome";
import "./i18n";
import { unstable_batchedUpdates } from "react-dom";
import HelperHTTP from "./_utils/http_module_handler";
import { remotePath } from "./base/settings/Paths";
import { useSelector } from "react-redux";
import { ReduxDataKey } from "./base/store/store";

unstable_batchedUpdates(() => {
  console.error = () => {};
});

let persisted = localStorage.getItem("persist:" + remotePath.localStorageKey());
let userAuthed = JSON.parse(persisted ? persisted : "{}")?.auth;
let userAuthedId = null;
let userAuthedEmail = null;

if (userAuthed) {
  userAuthed = typeof userAuthed === "string" ? JSON.parse(userAuthed)?.user : userAuthed?.user;
  userAuthedId = typeof userAuthed === "object" ? userAuthed?.id : null;
  userAuthedEmail = typeof userAuthed === "object" ? userAuthed?.email : null;
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  async sendError(payload) {
    let req = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.EXCEPTIONS)
      .noSendSessionPayload()
      .api()
      .post({ data: payload });
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in Error Boundary:", error);
    console.error("Error details:", errorInfo);
    this.setState({ errorInfo });
    this.sendError({
      message: "React error: " + this.state.error?.toString(),
      stackTrace: this.state.errorInfo?.toString(),
      url: window.location.href,
      user_id: userAuthedId ?? "",
      user_email: userAuthedEmail ?? "",
    });
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI6
      return (
        <div className="p-5">
          <h1>Something went wrong.</h1>
          <details style={{ whiteSpace: "pre-wrap" }}>
            <summary>Error Details:</summary>
            <p>{this.state.error?.toString()}</p>
            <pre>{this.state.errorInfo?.componentStack}</pre>
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

const rootElement = document.getElementById("root");

const root = ReactDOM.createRoot(rootElement);

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
