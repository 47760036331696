import { Fragment, useEffect, useState } from "react";
import { Key, Lock } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";

import { Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import FeedbackMessages from "src/base/FeedbackMessage";
import { cleanErrorsAndMessages, fetchForgotReset, Session } from "src/base/store/authSlice";
import { ReduxDataAuthKey, ReduxDataKey } from "src/base/store/store";

const ForgotPasswordModifyPage = () => {
  const [isPasswordVisible, setisPasswordVisible] = useState(false);
  const { trans } = useEBSSSLayout();
  const dispatcher = useDispatch<any>();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const [newPassword, setNewPassword] = useState({
    token: "",
    password: "",
    password_confirmation: "",
  });

  const toggle = () => {
    setisPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatcher(fetchForgotReset({ ...newPassword, activeLanguage: "pt" }));
  };

  useEffect(() => {
    toast(trans("auth.popup-password-reset-sent-text"));
    dispatcher(cleanErrorsAndMessages());
  }, []);

  return sessionNow?.user?.id || sessionNow?.message?.forgotPassword === "CHANGED" ? (
    <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
  ) : (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col
            xl="7 order-1"
            style={{
              backgroundImage: `url("https://micaela.3.ebsss.dev/platform-images/settings/customer_area_background/7679a69f-f07c-4c7c-9af4-f43d771cee18.jpg?p=slider&s=09962273dea6f982797368e0cd6b4f6b")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "block",
            }}
          ></Col>
          <Col xl="5 p-0">
            <div className="login-card1">
              <Form className="theme-form login-form">
                <div className="login-header text-center">
                  <h4>{trans("auth.password-reset-title")}</h4>
                  <h6>{trans("auth.password-reset-description")}</h6>
                </div>
                <FormGroup>
                  <Label>{trans("auth.access_code")}</Label>
                  <InputGroup>
                    <InputGroupText>
                      <Key />
                    </InputGroupText>
                    <Input
                      autoComplete="off"
                      aria-autocomplete="none"
                      type="text"
                      name="token"
                      required
                      defaultValue={newPassword.token}
                      onChange={(event) =>
                        setNewPassword({
                          ...newPassword,
                          token: event.target.value,
                        })
                      }
                    />
                  </InputGroup>
                  <FeedbackMessages messages={sessionNow?.errors?.forgotPassword["token"]} />
                </FormGroup>
                <FormGroup>
                  <Label>{trans("auth.password")}</Label>
                  <InputGroup style={{ position: "relative" }}>
                    <InputGroupText>
                      <Lock />
                    </InputGroupText>
                    <Input
                      type={isPasswordVisible ? "text" : "password"}
                      required
                      defaultValue={newPassword.password}
                      onChange={(event) =>
                        setNewPassword({
                          ...newPassword,
                          password: event.target.value,
                        })
                      }
                    />

                    <div className="text-primary small show-hide-toggle" onClick={toggle}>
                      <span>{!isPasswordVisible ? trans("website.show") : trans("website.hide")}</span>
                    </div>
                  </InputGroup>
                  <FeedbackMessages messages={sessionNow?.errors?.forgotPassword["password"]} />
                </FormGroup>
                <FormGroup>
                  <Label>{trans("auth.password_confirmation")}</Label>
                  <InputGroup>
                    <InputGroupText>
                      <Lock />
                    </InputGroupText>
                    <Input
                      type={isPasswordVisible ? "text" : "password"}
                      required
                      defaultValue={newPassword.password_confirmation}
                      onChange={(event) =>
                        setNewPassword({
                          ...newPassword,
                          password_confirmation: event.target.value,
                        })
                      }
                    />
                    <div className="text-primary small show-hide-toggle" onClick={toggle}>
                      <span>{!isPasswordVisible ? trans("website.show") : trans("website.hide")}</span>
                    </div>
                  </InputGroup>
                  <FeedbackMessages messages={sessionNow?.errors?.forgotPassword["password_confirmation"]} />
                </FormGroup>
                <FormGroup>
                  <EBSSSButton loadingKey={ReduxDataAuthKey.forgotPassword} onClick={handleSubmit}>
                    {trans("auth.define-password-button")}
                  </EBSSSButton>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ForgotPasswordModifyPage;
