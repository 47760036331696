import { Fragment } from "react/jsx-runtime";
import { CSS } from "src/_utils/styles";
import { Breadcrumbs } from "src/AbstractElements";
import DefaultContain from "src/Component/DashBoard/Default";
import { useEBSSS } from "./context/EBSSSContext/useEBSSS";
import { getQueryParams } from "src/_utils/urls";
import { useDispatch } from "react-redux";
import { clearCartAndOrderSessionSimple } from "./store/authSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Page = ({
  children,
  breads,
  hideTitle,
  hideBreadcrumbs,
  isCMS,
}: {
  children: any;
  breads: any;
  hideTitle?: boolean;
  hideBreadcrumbs?: boolean;
  isCMS?: boolean;
}) => {
  const { loaded } = useEBSSS();
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const exceptLoaderOnRoutes = ["v2/crm/products", "v2/crm/seller", "v2/crm/contacts/center/"];

  useEffect(() => {
    const getQueryParamsSettings = getQueryParams(window.location.href);
    if (getQueryParamsSettings?.resetCart) {
      dispatcher(clearCartAndOrderSessionSimple());
      navigate(process.env.PUBLIC_URL + "/crm/products?page=1", {
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    if (!isCMS) {
      let dettachCMSTemplate = Array.from(document.querySelectorAll('[cms="true"], [href*="cms=true"], [src*="cms=true"]'));
      console.log("dettachCMSTemplate: ", dettachCMSTemplate);
      dettachCMSTemplate.forEach((el) => el.remove());
    }
    return () => {
      if (!isCMS) {
        let dettachCMSTemplate = Array.from(document.querySelectorAll('[cms="true"], [href*="cms=true"], [src*="cms=true"]'));
        console.log("dettachCMSTemplate: ", dettachCMSTemplate);
        dettachCMSTemplate.forEach((el) => el.remove());
      }
    };
  }, []);

  return (
    <Fragment>
      {!isCMS ? (
        hideTitle && hideBreadcrumbs ? (
          <></>
        ) : (
          <Breadcrumbs hideTitle={hideTitle} hideBreadcrumbs={hideBreadcrumbs} breads={breads} title={!hideBreadcrumbs ? breads[breads.length - 1].name : ""} />
        )
      ) : (
        <></>
      )}
      <DefaultContain>
        {exceptLoaderOnRoutes.some((expt) => window.location.pathname.includes(expt)) ? (
          children
        ) : loaded ? (
          children
        ) : (
          <div className="w-100 justify-center justify-content-center d-flex no-print-ebsss" style={CSS({ height: "50vh" })}>
            <div className="loader d-flex align-center justify-center m-auto text-center">
              <div className="loader-bar"></div>
              <div className="loader-bar"></div>
              <div className="loader-bar"></div>
              <div className="loader-bar"></div>
              <div className="loader-bar"></div>
              <div className="loader-ball"></div>
            </div>
          </div>
        )}
      </DefaultContain>
    </Fragment>
  );
};
export default Page;
