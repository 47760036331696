//@ts-nocheck
export const menuLayout = {
  top: "horizontal-wrapper",
  left: "compact-wrapper",
};

export const mainValidEmailDomains = ["gmail", "outlook", "live", "yahoo", "hotmail"];

export const debugScript = `
<!-- JS
============================================ -->
<!-- Modernizer JS -->
<script src="/assets/js/vendor/modernizr-3.11.7.min.js"></script>
<!-- Modernizer JS -->
<script src="/assets/js/vendor/theme-jquery-3.6.0.min.js"></script>
<!-- Popper JS -->
<script src="/assets/js/vendor/popper.js"></script>
<!-- Bootstrap JS -->
<script src="/assets/js/vendor/theme-bootstrap.min.js"></script>

<!-- Slick Slider JS -->
<script src="/assets/js/plugins/countdown.js"></script>
<script src="/assets/js/plugins/counterup.js"></script>
<script src="/assets/js/plugins/images-loaded.js"></script>
<script src="/assets/js/plugins/theme-isotope.js"></script>
<script src="/assets/js/plugins/instafeed.js"></script>
<script src="/assets/js/plugins/theme-jquery-ui.js"></script>
<script src="/assets/js/plugins/theme-jquery-ui-touch-punch.js"></script>
<script src="/assets/js/plugins/magnific-popup.js"></script>
<script src="/assets/js/plugins/owl-carousel.js"></script>
<script src="/assets/js/plugins/scrollup.js"></script>
<script src="/assets/js/plugins/theme-waypoints.js"></script>
<script src="/assets/js/plugins/slick.js"></script>
<script src="/assets/js/plugins/theme-wow.js"></script>
<script src="/assets/js/plugins/textillate.js"></script>
<script src="/assets/js/plugins/elevatezoom.js"></script>
<script src="/assets/js/plugins/sticky-sidebar.js"></script>
<script src="/assets/js/plugins/smoothscroll.js"></script>
<!-- Main JS -->
<script src="/assets/js/theme-main.js"></script>

<script>
  setTimeout(function () {
    $('.preloader_background').fadeToggle();
  }, 1300);
</script>

                <script src="https://unpkg.com/react@18/umd/react.development.js"></script>                <script src="https://unpkg.com/react-dom@18/umd/react-dom.development.js"></script>                <script src="https://unpkg.com/@babel/standalone@7/babel.min.js"></script>                <script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>                <link rel="stylesheet" href="https://chatwidget.3.ebsss.dev/chat/chat-pop.css">                <script src="https://chatwidget.3.ebsss.dev/chat/core.js"></script>                <script                    type="text/babel"                    src="https://chatwidget.3.ebsss.dev/chat/react.js"                ></script>                <script type='text/javascript'>                    const CHAT_VARS_0KBfXf = {                        guid: '0KBfXf',                        chatChannelId: '3490526c-8c69-4fbe-91e6-3fa42ae3afa0',                        tenantId: 'micaela',                        tenantApiBaseURL: 'api.3.ebsss.dev',                        assetsBaseUrl: 'https://chatwidget.3.ebsss.dev',                        domainBaseURL: 'https://micaela.3.ebsss.dev',                        pusherCluster: 'eu',                        pusherClient: 'a51677f326c10bdbd566',                    };                </script>                <script type="text/javascript">                    const chatInstance_0KBfXf = new EBSSSChatModule({                        type: 'pop',                        modalOpenButton: null,                        ...CHAT_VARS_0KBfXf,                    })                    .init();                </script>                            <script type='text/babel'>                ReactDOM.createRoot(                    document.getElementById("root-0KBfXf")                ).render(                    <RenderReactChatInstance {...CHAT_VARS_0KBfXf} sufixGUID={'0KBfXf'} />                );            </script>




<div id="cookie-consent-container"></div><div id="iframe-iclient-overlay" style="opacity: 0;display:none;background:rgba(0,0,0,0.5);position:fixed;width:100%;height:100%;z-index: 9999999999;top: 0px;">
        <div id="iframe-iclient-div-iframe" style="border:5px solid #FFF;width:80%;margin-left:10%;margin-top:5%;height:85vh;background: #f6f6f6;border-radius: 5px; box-shadow: 0px 0px 30px #000;">
            <div class="iframe-iclient-logo" style="width: 50px;height: 50px;border-radius:50%;background:#FFF;margin-left: -30px;margin-top: -30px;position: absolute;border: 3px solid #0000e7;box-sizing: content-box;">
                <a href="https://www.ebsss.com" target="_blank" style="outline:none"> <img src="https://website.3.ebsss.dev/assets/images/iconeWebMarket.png" style="width: 40px;margin: 5px;"></a>
            </div>
            <div id="close-iframe-iclient" style="width: 30px;height: 30px;border-radius:50%;background:#FFF;margin-left: calc(80% - 15px);margin-top: -20px;border: 1px solid #000;position: absolute;cursor:pointer;box-sizing: content-box;">
                <div style="margin: 0px 8.5px;font-size: 20px;line-height: 32px;font-weight: bold;font-family: sans-serif;color: #000;cursor:pointer;">X</div>
            </div>
            <div style="overflow: hidden; -webkit-overflow-scrolling: touch; width: 100%;height:100%;">
                <iframe id="iframe-client-iframe" name="iframe-client-iframe"  style="width:100%;height: 100%;border:0px;"></iframe>
            </div>
        </div>
        </div>
        <div id="iframe-iclient-image-overlay" style="opacity: 0;display:none;background:rgba(0,0,0,0.5);position:fixed;width:100%;height:100%;z-index: 9999999999;top: 0px;">
            <div id="iframe-iclient-div-image" style="border:5px solid #FFF;width:80%;margin-left:10%;margin-top:5%;height:auto;background: #f6f6f6;border-radius: 5px; box-shadow: 0px 0px 30px #000;">
                <div class="iframe-iclient-logo" style="width: 50px;height: 50px;border-radius:50%;background:#FFF;margin-left: -30px;margin-top: -30px;position: absolute;border: 3px solid #0000e7;box-sizing: content-box;">
                    <a href="https://www.ebsss.com" target="_blank" style="outline:none"> <img src="https://website.3.ebsss.dev/assets/images/iconeWebMarket.png" style="width: 40px;margin: 5px;"></a>
                </div>
                <div id="close-image-iclient" style="width: 30px;height: 30px;border-radius:50%;background:#FFF;margin-left: calc(80% - 15px);margin-top: -20px;border: 1px solid #000;position: absolute;cursor:pointer;box-sizing: content-box;">
                    <div style="margin: 0px 8.5px;font-size: 20px;line-height: 32px;font-weight: bold;font-family: sans-serif;color: #000;cursor:pointer;">X</div>
                </div>
                <img id="iframe-client-image" style="max-width: 80vw;max-height: 80vh;margin-left: auto;margin-right: auto;display: block;">
            </div>
        </div>
        <div id="iframe-iclient-content-overlay" style="opacity: 0;display:none;background:rgba(0,0,0,0.5);position:fixed;width:100%;height:100%;z-index: 9999999999;top: 0px;">

            <div id="iframe-iclient-div-content" style="border:5px solid #FFF;width:80%;margin-left:10%;margin-top:5%;    height: calc(100vh - 100px);background: #f6f6f6;border-radius: 5px; box-shadow: 0px 0px 30px #000;">
                <div class="iframe-iclient-logo" style="width: 50px;height: 50px;border-radius:50%;background:#FFF;margin-left: -30px;margin-top: -30px;position: absolute;border: 3px solid #0000e7;box-sizing: content-box;">
                    <a href="https://www.ebsss.com" target="_blank" style="outline:none"> <img src="https://website.3.ebsss.dev/assets/images/iconeWebMarket.png" style="width: 40px;margin: 5px;"></a>
                </div>
                <div id="close-content-iclient" style="width: 30px;height: 30px;border-radius:50%;background:#FFF;margin-left: calc(80% - 15px);margin-top: -20px;border: 1px solid #000;position: absolute;cursor:pointer;box-sizing: content-box;">
                    <div style="margin: 0px 8.5px;font-size: 20px;line-height: 32px;font-weight: bold;font-family: sans-serif;color: #000;cursor:pointer;">X</div>
                </div>
                <iframe id="iframe-client-content"  src="about:blank"  name="iframe-client-content"  style="width:100%;height: 100%;border:0px;" ></iframe>

            </div>
        </div>
        <div id="iframe-iclient-message-overlay" style="opacity: 0;display:none;background:rgba(0,0,0,0.5);position:fixed;width:100%;height:100%;z-index: 9999999999;top: 0px;">

            <div id="iframe-iclient-div" style="border:5px solid #FFF;width:270px;margin-left: calc(50% - 135px);  margin-top: calc(50vh - 100px);   height: auto;background: #f6f6f6;border-radius: 5px; box-shadow: 0px 0px 30px #000;">
                <div class="iframe-iclient-logo" style="width: 50px;height: 50px;border-radius:50%;background:#FFF;margin-left: -30px;margin-top: -30px;position: absolute;border: 3px solid #0000e7;box-sizing: content-box;">
                    <a href="https://www.ebsss.com" target="_blank" style="outline:none"> <img src="https://website.3.ebsss.dev/assets/images/iconeWebMarket.png" style="width: 40px;margin: 5px;"></a>
                </div>
                <div id="close-message-iclient" style="width: 30px;height: 30px;border-radius:50%;background:#FFF;    margin-left: 255px;margin-top: -20px;border: 1px solid #000;position: absolute;cursor:pointer;box-sizing: content-box;">
                    <div style="margin: 0px 8.5px;font-size: 20px;line-height: 32px;font-weight: bold;font-family: sans-serif;color: #000;cursor:pointer;">X</div>
                </div>
                <div id="iframe-client-message" style="width:100%;border:0px;text-align: center;font-size: 21px;font-family: sans-serif;padding: 30px 0px;"></div>
            </div>
        </div>
        <script type="text/javascript">
                        if ($(".iclient-buy-now").length > 0) {
                            $(document).on("click", ".iclient-buy-now", function (event) {
                                event.preventDefault();
                                event.preventDefault();

                                if (this.getAttribute("href").indexOf("?") <= 0) {
                                    var linkDestination =  this.getAttribute("href") + "?";
                                } else {
                                    var linkDestination =  this.getAttribute("href");
                                }

                                let qtd = 1;
                                let opt, opt_child, opt_custom;
                                let opt_price = 0;

                                if ($(this).closest("form") != null &&
                                    $(this).closest("form").find(".iclient-cart-quantity").length > 0) {

                                    qtd = $(this).closest("form").find(".iclient-cart-quantity").val();

                                    linkDestination += "quantity="  +qtd;
                                }

                                if ($(this).closest("form") != null &&
                                    $(this).closest("form").find(".iclient-cart-option").length > 0) {

                                    opt = $(this).closest("form").find(".iclient-cart-option").val();

                                    linkDestination += "&option=" + opt;
                                }

                                if ($(this).closest("form") != null &&
                                    $(this).closest("form").find(".iclient-cart-option_price").length > 0) {

                                    opt_price = $(this).closest("form").find(".iclient-cart-option_price").val();

                                    linkDestination += "&option_price=" + opt_price;
                                }

                                if ($(this).closest("form") != null &&
                                    $(this).closest("form").find(".iclient-cart-child").length > 0) {

                                    opt_child = $(this).closest("form").find(".iclient-cart-child").val();

                                    linkDestination += "&child=" + opt_child;
                                }

                                if ($(this).closest("form") != null &&
                                    $(this).closest("form").find(".iclient-cart-custom-field").length > 0) {

                                    opt_custom = $(this).closest("form").find(".iclient-cart-custom-field").val();

                                    linkDestination += "&custom_field=" + opt_custom;
                                }

                                linkDestination += "&cms=1";

                                linkDestination += "&buy_now=1";

                                // console.log("link", linkDestination);

                                interactIclientCart(linkDestination);

                            })
                        }

                        function showIclientIframe(url){
                            console.log("SHOW ICLIENT IFRAME")
                            var _iOSDevice = false;//!!navigator.platform.match(/iPhone|iPod|iPad/);
                            if(_iOSDevice){
                                window.open(url);
                                return true;
                            }
                            var popup = document.getElementById("iframe-iclient-message-overlay");
                            fadeOutElement(popup);
                            if(inIframe()){
                                //window.open(url, "window name","height=200,width=200,modal=yes,alwaysRaised=yes");
                                try {
                                    document.getElementById("iframe-client-iframe").contentWindow.postMessage("showLoading","*");
                                }catch(err) {}
                                window.open(url, "iframe-client-iframe");//,"height=200,width=200,modal=yes,alwaysRaised=yes");
                            }else{
                                var popup = document.getElementById("iframe-iclient-overlay");
                                fadeInElement(popup);
                                try {
                                    document.getElementById("iframe-client-iframe").contentWindow.postMessage("showLoading","*");
                                }catch(err) {}
                                document.getElementById("iframe-client-iframe").setAttribute("src",url);
                                cancelIClientHide=true;
                            }
                        }


                        function iClientMessage(text,type,autoclose){
                            type = type||null;

                            autoclose = autoclose||null;
                            var popup = document.getElementById("iframe-iclient-message-overlay");
                            fadeInElement(popup);
                            document.getElementById("iframe-client-message").innerHTML = text;
                            if(autoclose!=null){
                                cancelIClientHide = false;
                                setTimeout(function(){
                                    if(!cancelIClientHide){
                                        hideIclientIframe();
                                    }
                                },autoclose);
                            }
                        }

                        function showIclientIframeImage(url){
                            var popup = document.getElementById("iframe-iclient-image-overlay");
                            fadeInElement(popup);
                            document.getElementById("iframe-client-image").setAttribute("src",url);
                        }

                        function showIclientIframeContent(content){
                            var popup = document.getElementById("iframe-iclient-content-overlay");
                            fadeInElement(popup);
                            iframe  = document.getElementById("iframe-client-content");//.setAttribute("src",url);
                            iframe = iframe.contentWindow || ( iframe.contentDocument.document || iframe.contentDocument);

                            iframe.document.open();
                            iframe.document.write(content);
                            iframe.document.close();


                            window.open(url, "iframe-client-content");
                            cancelIClientHide=true;

                        }

                        function hideIclientIframe(){
                                if(madeLoginLogoff){
                                                location.reload();       }
                            if(addedProductToCart){
                                                location.reload();       }

                            if( document.getElementById("iframe-client-iframe").getAttribute("src") != null && document.getElementById("iframe-client-iframe").getAttribute("src").indexOf("youtube.com")){
                                document.getElementById("iframe-client-iframe").setAttribute("src","");
                            }

                            var popup = document.getElementById("iframe-iclient-overlay");
                            fadeOutElement(popup);
                            var popup = document.getElementById("iframe-iclient-image-overlay");
                            fadeOutElement(popup);
                            var popup = document.getElementById("iframe-iclient-content-overlay");
                            fadeOutElement(popup);
                            var popup = document.getElementById("iframe-iclient-message-overlay");
                            fadeOutElement(popup);
                            cancelIClientHide=false;
                        }

                        function fadeOutElement(element) {
                            var op = 1;  // initial opacity
                            var timer = setInterval(function () {
                                if (op <= 0.1){
                                    clearInterval(timer);
                                    element.style.display ="none";
                                }
                                element.style.opacity = op;
                                element.style.filter = "alpha(opacity=" + op * 100 + ")";
                                op -= 0.1;
                            }, 50);
                        }

                        function fadeInElement(element) {
                            element.style.display = "block";
                            var op = 0;  // initial opacity
                            var timer = setInterval(function () {
                                if (op >= 0.9){
                                    clearInterval(timer);
                                    element.style.display = "block";
                                }
                                element.style.opacity = op;
                                element.style.filter = "alpha(opacity=" + op * 100 + ")";
                                op += 0.1;
                            }, 50);
                        }

                        function inIframe () {
                            try {
                                return window.self !== window.top;
                            } catch (e) {
                                return true;
                            }
                        }


                    </script><script>
            if(typeof jQuery!="undefined"){
                jQuery(window).on("load", function() {
                    jQuery(".cms-loading").fadeOut();
                });
            }else if(typeof $!="undefined"){
                $(window).on("load", function() {
                    $(".cms-loading").fadeOut();
                });
            }else{
                (function(){
                    try{
                        document.getElementByClassName("cms-loading")[0].style.display="none";
                    }catch(error){
                        if(document.querySelector(".cms-loading") !=null ){
                            document.querySelector(".cms-loading").style.display="none";
                        }
                    }
                })();
            }
            </script>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-154684450-1"></script>
            <script>
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag("js", new Date());

                gtag("config", "UA-154684450-1");
            </script>

            <script>
                $( document ).ready(function() {
                    if ($(".iclient-cart-variations.level1")) {
                        let first_option_first_level = $(".iclient-cart-variations.level1 option:nth-child(1)")
                        let first_option_first_level_text = "Selecione ";

                        // console.log("nivel 1", $(".iclient-cart-variations.level1"), "fofl", first_option_first_level, "foflt", first_option_first_level_text)

                        first_option_first_level.text(first_option_first_level_text)
                    }


                    $("body").on("change",".iclient-cart-variations", function () {
                        var create_new = true;
                        let clicked_element = this.options[this.selectedIndex];
                        let first_element = $(".iclient-cart-variations option:nth-child(1)");

                        console.log("clicked_element", clicked_element)
                        console.log("first_element", first_element)

                        if ($(this).hasClass("level1")) {
                            $(".level1 option:nth-child(1)").attr("disabled", "disabled")

                            $(".level2").addClass("d-none");
                            $(".level3").addClass("d-none");
                            $(".level4").addClass("d-none");
                            $(".level5").addClass("d-none");
                            $(".level6").addClass("d-none");
                            $(".level7").addClass("d-none");
                            $(".level8").addClass("d-none");

                            var class_select = "level2";

                            if ( $( ".level2" ).length ) {
                                create_new = false;
                            }

                        } else if ($(this).hasClass("level2")) {
                            var class_select = "level3";

                            $(".level3").addClass("d-none");
                            $(".level4").addClass("d-none");
                            $(".level5").addClass("d-none");
                            $(".level6").addClass("d-none");
                            $(".level7").addClass("d-none");
                            $(".level8").addClass("d-none");

                            if ($( ".level3" ).length) {
                               create_new = false;
                            }

                        } else if ($(this).hasClass("level3")) {
                            var class_select = "level4";

                            $(".level4").addClass("d-none");
                            $(".level5").addClass("d-none");
                            $(".level6").addClass("d-none");
                            $(".level7").addClass("d-none");
                            $(".level8").addClass("d-none");

                            if ($(".level4").length) {
                                create_new = false;
                            }
                        } else if ($(this).hasClass("level4")) {
                            var class_select = "level5";

                            $(".level5").addClass("d-none");
                            $(".level6").addClass("d-none");
                            $(".level7").addClass("d-none");
                            $(".level8").addClass("d-none");

                            if ( $( ".level5" ).length ) {
                                create_new = false;
                            }
                        } else if ($(this).hasClass("level5")) {
                            var class_select = "level6";

                            $(".level6").addClass("d-none");
                            $(".level7").addClass("d-none");
                            $(".level8").addClass("d-none");

                            if ( $( ".level6" ).length ) {
                                create_new = false;
                            }

                        } else if ($(this).hasClass("level6")) {
                            var class_select = "level7";

                            $(".level7").addClass("d-none");

                            if ( $( ".level7" ).length ) {
                                create_new = false;
                            }

                        } else if ($(this).hasClass("level7")) {
                            var class_select = "level8";

                            if ( $( ".level8" ).length ) {
                                create_new = false;
                            }
                        }

                        var class_to_reply = "." + class_select;
                        if ($(this).val() == "") {

                            $(class_to_reply).find("option:not(:first)").remove()
                        }


                        $.ajax({
                            url: "",
                            type:"GET",
                            data: {
                                "create_new": create_new,
                                "class": class_select,
                                "_token": "M79P5eyRFoMOBNW3VmLVnQOqCIyEmmPZ60Y4TCJl",
                                "id": $(this).val()
                            },
                            contentType: "application/x-www-form-urlencoded;charset=UTF-8"
                        }).done(function (result) {
                            console.log("o resultado da url get variation", result);

                            if (result.type == "cat") {
                                console.log("result type is cat")
                                console.log("Nme de grupo", result.group_name)

                                $(".iclient-cart").addClass("d-none");
                                $(".iclient-buy-now").addClass("d-none")

                                if (create_new == true) {
                                    console.log("result type is cat and create new is true", result.type, create_new)

                                    $(".others_levels").append(result.childs);
                                } else {
                                    console.log("create new", create_new)

                                    // first_element.removeAttr("disabled")

                                    var class_to_reply = "." + class_select;
                                    $(class_to_reply).removeClass("d-none");
                                    $(class_to_reply).find("option:not(:first)").remove();

                                    let first_option_text = "Selecione"
                                    if (result.group_name &&
                                        result.group_name != undefined) {
                                        first_option_text += " " + result.group_name
                                    }

                                    $(class_to_reply).find("option:first").text(first_option_text)

                                    // $(class_to_reply).find("option:first").removeAttr("disabled")

                                    var ajax_promises = [];


                                    $.each(result.childs, function( index, element ) {
                                        console.log("loop index", index, "loop element", element)

                                        var ajax_promise = $.ajax({
                                            url: "",
                                            type:"GET",
                                            data:{
                                                "create_new": create_new,
                                                "class":class_select,
                                                "_token":"M79P5eyRFoMOBNW3VmLVnQOqCIyEmmPZ60Y4TCJl",
                                                "id":element.id
                                            },
                                            contentType: "application/x-www-form-urlencoded;charset=UTF-8"
                                        }).then(function (ret) {
                                            console.log("atencao nesse", ret)

                                            return {
                                                element: element,
                                                ret: ret
                                            };
                                        });

                                        ajax_promises.push(ajax_promise);
                                    });

                                    $.when.apply($, ajax_promises).then(function () {

                                        for (var i = 0; i < arguments.length; i++) {
                                            console.log("a classe para reply pegando o primeiro option", $(class_to_reply).find("option:first"))
                                            console.log("argument no  loop", arguments[i])

                                            var arg = arguments[i];

                                            // Adicionar as opções ordenadas ao DOM
                                            $(class_to_reply).append($("<option>", {
                                                value: arg.element.id,
                                                text: arg.element.name
                                            }));
                                        }
                                    });

                                    // setTimeout(function () {
                                    //     $(class_to_reply).find("option:first").attr("selected", true)
                                    //     $(class_to_reply).find("option:first").attr("disabled", true)
                                    // }, 300)
                                }
                            } else {
                                if (result.type == "product") {

                                    console.log("result type is not cat", result.type)
                                    console.log("result.childs", result.childs)

                                    if (result.childs != null) {

                                        var url = "https://micaela.3.ebsss.dev/crm/product/getPhotoOption/"+result.childs.token;
                                        $.get(url, function(photo){
                                            $(".class_variation_product_image").attr("src", photo);
                                            $(".class_variation_product_image_mini").attr("src", photo);
                                            $(".class_variation_product_image_mini").data("image", photo);
                                            $(".class_variation_product_image_mini").data("zoom-image", photo);
                                            if(typeof $.fn.elevateZoom === "function"){
                                                $(".class_variation_product_image").data("zoom-image", photo).elevateZoom({
                                                    gallery: "gallery",
                                                    responsive: true,
                                                    zoomType: "lens",
                                                    containLensZoom: true
                                                 });
                                            }


                                        });

                                        var url = "https://micaela.3.ebsss.dev/crm/product/getPhotoExtra/"+result.childs.token;
                                        $.get(url, function(photo){
                                            $(".class_variation_product_image_thumbs").empty();
                                            $(".class_variation_product_image_thumbs").html(photo);
                                            if(typeof $.fn.elevateZoom === "function"){

                                                $(".class_variation_product_image").elevateZoom({
                                                    gallery: "gallery",
                                                    responsive: true,
                                                    zoomType: "lens",
                                                    containLensZoom: true });
                                            }
                                        });
                                    }

                                    if (result &&
                                        result.atributes.tipo_de_utilizacao){
                                        $(".class_variation_product_tag").text(result.atributes.tipo_de_utilizacao);
                                    }

                                    if (result &&
                                        result.atributes.disponibilidade){
                                        $(".class_variation_product_availability").text(result.atributes.disponibilidade);
                                    }

                                    if (result &&
                                        result.childs != null) {

                                        $(".class_variation_product_name").text(result.childs.name);
                                        $(".class_variation_product_description").html(result.childs.description);
                                        $(".class_variation_product_model").text(result.childs.model);
                                        if(result.childs.price_to_show != null){
                                            $(".class_variation_product_price").text(result.childs.price_to_show);
                                        }else{
                                        var price = result.childs.price+"";
                                            $(".class_variation_product_price").text(price);
                                        }
                                        $(".iclient-cart-child").val(result.childs.token);
                                    }

                                    if (result &&
                                        result.category &&
                                        result.category.title) {
                                        $(".class_variation_product_category").text(result.category.title);
                                    }

                                    var urlCategory = "";
                                    if (result &&
                                        result.category &&
                                        result.category.slug) {
                                        urlCategory = "/cat/"+result.category.slug;
                                    }

                                    $(".class_variation_product_category").attr("href",urlCategory);
                                    $(".class_variation_product_atribute").html(result.htmlAtribute);

                                    if (result &&
                                        result.product_draft == "true") {
                                        console.log("Produto Disabled", result.product_draft)
                                        if (!$(".iclient-cart").hasClass("d-none")) {
                                            $(".iclient-cart").addClass("d-none");
                                        }

                                        if (!$(".iclient-buy-now").hasClass("d-none")) {
                                            $(".iclient-buy-now").addClass("d-none")
                                        }

                                        let element_original_text = clicked_element.innerText;
                                        let new_text = element_original_text + " - Produto Não Disponível"
                                        clicked_element.innerText = new_text
                                        clicked_element.setAttribute("disabled", "disabled")

                                        console.log('Draft Product');
                                    } else {
                                        console.log("Produto Disabled", result.product_draft)

                                        if ($(".iclient-cart").hasClass("d-none")) {
                                            $(".iclient-cart").removeClass("d-none");
                                        }

                                        if ($(".iclient-buy-now").hasClass("d-none")) {
                                            $(".iclient-buy-now").removeClass("d-none")
                                        }

                                        console.log('Non-Draft Product');
                                    }
                                } else {
                                    console.log("elemento clicado lá no else vazio", clicked_element)

                                    if ($(".iclient-buy-now") &&
                                        !$(".iclient-buy-now").hasClass("d-none")) {
                                        $(".iclient-buy-now").addClass("d-none")
                                    }

                                    if ($(".iclient-cart") &&
                                        !$(".iclient-cart").hasClass("d-none")) {
                                        $(".iclient-cart").addClass("d-none")
                                    }
                                }


                            }
                        });


                    });
                });
            </script>



            <!-- block options price -->

            <script>

            function processQuantityParameter(){
               var options_price = $("#option_price option:selected").val();

                var url = "https://micaela.3.ebsss.dev/crm/product/getImageOptionPrice/"+options_price+"/big";
                $.get(url, function(photo){
                    $(".class_variation_product_image").attr("src", photo);
                    $(".class_variation_product_image_mini").attr("src", photo);
                    $(".class_variation_product_image_mini").data("image", photo);
                    $(".class_variation_product_image_mini").data("zoom-image", photo);
                    if(typeof $.fn.elevateZoom === "function"){

                        $(".class_variation_product_image").data("zoom-image", photo).elevateZoom({
                            gallery: "gallery",
                            responsive: true,
                            zoomType: "lens",
                            containLensZoom: true
                         });
                    }
                });
                $(".class_variation_product_description").text($("#option_price option:selected").data("description"));
                $(".class_variation_product_price").text($("#option_price option:selected").data("price"));
            }
            $("body").on("change","#option_price",processQuantityParameter);
            if($("#option_price").length)
            {
            processQuantityParameter();
            }
            </script>

        <script type="text/javascript">
            var cancelIClientHide = false;
            var madeLoginLogoff = false;

            function showIclientAddedToCartMessage(){
                addedProductToCart = true;

                console.log("added", addedProductToCart)

                // first button receives
                // onclick=\"showIclientIframe('https://micaela.3.ebsss.dev/crm/cart')\"

                // second button receives
                // onclick=\"showIclientIframe('https://micaela.3.ebsss.dev/crm/checkout')\"

                iClientMessage("Adicionado ao carrinho<br/><br/><a href=\"https://micaela.3.ebsss.dev/crm/cart\" target=\"_blank\" class=\"btn btn-default\" >Ver</a><br/><br/><a href=\"https://micaela.3.ebsss.dev/crm/checkout\" target=\"_blank\" class=\"btn btn-primary\" >Realizar Check-out</a> ", "warn", 3000);
            }

            function didLoginLogoff(){
                madeLoginLogoff = true;
            }

            function interactIclientCart(url){
                // console.log("url", url);

               // document.getElementById("iframe-client-iframe").setAttribute("src",url);
               // iClientMessage("Adicionado ao carrinho<br/><br/><button class=\"btn btn-default\" onclick=\"showIclientIframe('https://micaela.3.ebsss.dev/crm/cart')\">Ver</button><br/><br/><button class=\"btn btn-primary\" onclick=\"showIclientIframe('https://micaela.3.ebsss.dev/crm/checkout')\">Carrinho</button> ","warn",3000);


                var httpRequestICL = new XMLHttpRequest();

                // console.log("httpRequestICL", httpRequestICL)

               // httpRequestICL.responseType = "json";

                httpRequestICL.open("GET", url);

                //httpRequestICL.withCredentials = "true";
                //client.setRequestHeader("Set-Cookie","session=abc");
                //client.setRequestHeader("Cookie",cookie);

                httpRequestICL.send();

                httpRequestICL.onreadystatechange = (e) => {
                    // console.log("e sozinho", e);

                    try {
                        response = JSON.parse(httpRequestICL.responseText);

                        console.log("response do request icl", response)

                        if (response.buy_now &&
                            response.success) {
                            // console.log("response buy now", response)

                            window.location.href = response.redirect;
                        } else if (response.success) {
                            showIclientAddedToCartMessage();
                        } else if (typeof response.redirect != "undefined") {
                            showIclientIframe(response.redirect);
                        } else if (typeof response.message != "undefined") {
                            iClientMessage(response.message);
                        }
                    } catch (er) {
                        console.log("err catch", er);
                    }
                }

            }
        </script>

        <script type="text/javascript">
            var addedProductToCart = false;
            function initIclient(){


                var list = document.getElementsByClassName("iclient-iframe");
                for(i=0;i<list.length;i++){
                    var iclientLink = list[i];
                    iclientLink.addEventListener("click", function(event){
                        event.preventDefault();
                        var linkDestination =  this.getAttribute("href");
                        showIclientIframe( this.getAttribute("href"));
                    });
                }
                var list = document.getElementsByClassName("iclient-cart");
                for(i=0;i<list.length;i++){
                    var iclientLink = list[i];
                    iclientLink.addEventListener("click", function(event){
                        event.preventDefault();
                        if(this.getAttribute("href").indexOf("?")<=0){
                            var linkDestination =  this.getAttribute("href")+"?";
                        }else{
                            var linkDestination =  this.getAttribute("href");
                        }
                        if($(this).closest("form")!=null && $(this).closest("form").find(".iclient-cart-quantity").length>0){
                            var qtd = $(this).closest("form").find(".iclient-cart-quantity").val();
                            linkDestination+="&quantity="+qtd;
                        }
                        if($(this).closest("form")!=null && $(this).closest("form").find(".iclient-cart-option").length>0){
                            var opt = $(this).closest("form").find(".iclient-cart-option").val();
                            linkDestination+="&option="+opt;
                        }
                        if($(this).closest("form")!=null && $(this).closest("form").find(".iclient-cart-option_price").length>0){
                            var opt = $(this).closest("form").find(".iclient-cart-option_price").val();
                            linkDestination+="&option_price="+opt;
                        }
                        if($(this).closest("form")!=null && $(this).closest("form").find(".iclient-cart-child").length>0){
                            var opt = $(this).closest("form").find(".iclient-cart-child").val();
                            linkDestination+="&child="+opt;
                        }
                        if($(this).closest("form")!=null && $(this).closest("form").find(".iclient-cart-custom-field").length>0){
                            var opt = $(this).closest("form").find(".iclient-cart-custom-field").val();
                            linkDestination+="&custom_field="+opt;
                        }
                        linkDestination += "&cms=1";

                        console.log(linkDestination);

                        interactIclientCart( linkDestination );
                    });
                }
                var list = document.getElementsByClassName("iclient-iframe-image");
                for(i=0;i<list.length;i++){


                    var iclientLink = list[i];
                    iclientLink.addEventListener("click", function(event){
                        event.preventDefault();

                        if ( (" " + this.className + " ").replace(/[\n\t]/g, " ").indexOf(" iclient-full-height ") > -1 ){
                            document.getElementById("iframe-iclient-div-image").style.overflowY="scroll";
                            document.getElementById("iframe-client-image").style.maxHeight="initial";
                            document.getElementById("iframe-iclient-div-image").style.height="90vh";
                        }else{
                            document.getElementById("iframe-iclient-div-image").style.overflowY="initial";
                            document.getElementById("iframe-client-image").style.maxHeight="80vh";
                            document.getElementById("iframe-iclient-div-image").style.height="initial";
                        }

                        var linkDestination =  this.getAttribute("href");
                        showIclientIframeImage( this.getAttribute("href"));
                    });
                }
                var list = document.getElementsByClassName("iclient-iframe-content");
                for(i=0;i<list.length;i++){
                    var iclientLink = list[i];
                    iclientLink.addEventListener("click", function(event){
                        event.preventDefault();

                        var linkDivContent =  this.getAttribute("data-div");
                        if(linkDivContent!=null){
                            var content = document.getElementById(linkDivContent);

                            showIclientIframeContent( content.innerHTML);
                        }
                    });
                }

                document.getElementById("close-iframe-iclient").addEventListener("click", function(event){

                    hideIclientIframe();
                });


                document.getElementById("close-image-iclient").addEventListener("click", function(event){
                    hideIclientIframe();
                });
                document.getElementById("close-content-iclient").addEventListener("click", function(event){
                    hideIclientIframe();
                });
                document.getElementById("close-message-iclient").addEventListener("click", function(event){
                    hideIclientIframe();
                });


                window.addEventListener("message", function(event) {

                    if(typeof event.data != "undefined" &&  event.data == "addProductCart"){
                        if($(".iclient-blocks").length>0){
                            addedProductToCart = true;
                        }
                    }

                    if(typeof event.data != "undefined" &&  event.data == "hideIclientIframe"){
                        hideIclientIframe();
                    }
                    if(typeof event.data != "undefined" &&  event.data == "acceptCookies"){
                        agreeToCookies();
                        hideIclientIframe();
                    }
                    if(typeof event.data != "undefined" &&  event.data == "showAddedToCart"){
                        showIclientAddedToCartMessage();
                    }
                    if(typeof event.data != "undefined" &&  event.data == "didLoginLogoff"){
                        didLoginLogoff();
                    }
                });






                initCookies();

        };





    var cookieVarLevel = 'micaela_level';
    var cookieVar = 'micaela';

function setCookie(name, value, expirationInDays) {
    var date = new Date();
    date.setTime(date.getTime() + (expirationInDays * 24 * 60 * 60 * 1000));
    document.cookie = name + '=' + value + ';expires=' + date.toUTCString() +';path=/;';
}
function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return '';
}
function deleteCookie( name ) {
    document.cookie = name+'=; Max-Age=-99999999;';
    for (var i = 0; i < localStorage.length; i++){
        if(name==localStorage.key(i)){
            localStorage.getItem(i).dataCache.splice(i,1);
        }
    }
}
function agreeToCookies(e) {
    console.log('cookie accepted')
    console.log(document.cookie);
    setCookie(cookieVar,'accepted', 365);
    document.cookie+=cookieVar+'=1;';
    console.log(document.cookie);
    document.querySelector('.cookieconsent').style.display = 'none';
    if(document.querySelector('.cookieconsent.config')){
        document.querySelector('.cookieconsent.config').style.display = 'block';
    }
    //localStorage.setItem(cookieVar, true);

}


function initCookies(){
    currentCookies = document.cookie.split(';');

   {  document.getElementById("cookie-consent-container").innerHTML += "        <div class='cookieconsent config' style='        position: fixed;        padding: 0px 7px 0px 3px;        left: 0;        bottom: 0;        background-color: rgba(0,0,0,0.50);        color: #FFF;        text-align: center;        width: 50px;        z-index: 99999999999;        height: 22px;        font-size: 10px;        font-family: Arial;        border-radius: 0px 7px 0px 0px;        cursor: pointer;'>Cookies</a>";        document.querySelector('.cookieconsent.config').onclick = function(e) {            e.preventDefault();            var cs=[];            var sitecookies = document.cookie.split(';');            for(var j=0;j<sitecookies.length;j++){                var varValue  = sitecookies[j].split('=');                cs.push(varValue[0].trim().replace('/',''))            }            var cookieList = cs.join(',');            showIclientIframe("https://micaela.3.ebsss.dev/cookie-info/"+cookieList);        };}
}

(function(){ initIclient() })();
</script>
`;

export const debugHead = `
<!-- CSS
============================================ -->
    <!-- Bootstrap CSS -->
    <link rel="stylesheet" href="/assets/css/vendor/theme-bootstrap.min.css">
    <!-- Icon Font CSS -->
    <link rel="stylesheet" href="/assets/css/vendor/line-awesome.css">
    <link rel="stylesheet" href="/assets/css/vendor/themify.css">
    <!-- Other CSS -->
    <link rel="stylesheet" href="/assets/css/plugins/animate.css">
    <link rel="stylesheet" href="/assets/css/plugins/owl-carousel.css">
    <link rel="stylesheet" href="/assets/css/plugins/slick.css">
    <link rel="stylesheet" href="/assets/css/plugins/magnific-popup.css">
    <link rel="stylesheet" href="/assets/css/plugins/jquery-ui.css">
    <link rel="stylesheet" href="/assets/css/theme-style.css">

    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">

    <style>
      /* CORES DO TEMA */
      a:hover {
        color: #d41f26;
      }

      #scrollUp {
        background-color: #d41f26;
      }

      .default-btn a.btn-color-theme {
        color: #d41f26;
      }

      .default-btn a.btn-color-theme-bg {
        background-color: #d41f26;
      }

      .default-btn a.btn-style-outline {
        border: 1px solid #d41f26;
      }

      .btn-hover a:hover {
        border: 1px solid #d41f26;
      }

      .btn-hover a::after {
        background: #d41f26;
      }

      .main-menu.menu-common-style nav ul li ul.submenu li a:hover {
        color: #d41f26;
      }

      .main-menu.menu-common-style nav ul li ul.mega-menu>li ul li a:hover {
        color: #d41f26;
      }

      .main-menu.menu-common-style nav ul li:hover a {
        color: #d41f26;
      }

      .main-menu.menu-common-style.menu-margin-4>nav>ul>li:hover>a {
        color: #d41f26;
      }

      .main-menu.menu-common-style.menu-margin-5>nav>ul>li:hover>a {
        color: #d41f26;
      }

      .stick .main-menu.menu-common-style.menu-font-2.menu-font-2-white>nav>ul>li:hover>a {
        color: #d41f26;
      }

      .header-right-wrap .common-style:hover button {
        color: #d41f26;
      }

      .header-right-wrap .cart-wrap button span.count-theme-color {
        color: #d41f26;
      }

      .header-right-wrap .cart-wrap button span.mini-cart-price {
        color: #d41f26;
      }

      .header-right-wrap .cart-wrap-2 button span.mini-cart-price-2 {
        color: #d41f26;
      }

      .header-right-wrap .cart-wrap-2 button span.count-style-2 {
        background-color: #d41f26;
      }

      .header-right-wrap .cart-wrap-2 button:hover i {
        color: #d41f26;
      }

      .header-right-wrap .search-wrap-2 button i:hover {
        color: #d41f26;
      }

      .header-right-wrap .setting-wrap a:hover {
        color: #d41f26;
      }

      .header-right-wrap .setting-wrap .setting-content ul li ul li a:hover {
        color: #d41f26;
      }

      .header-right-wrap .header-login a:hover {
        color: #d41f26;
      }

      .header-right-wrap .header-wishlist a:hover {
        color: #d41f26;
      }

      .shopping-cart-content .shopping-cart-top a:hover {
        color: #d41f26;
      }

      .shopping-cart-content ul li .shopping-cart-title h4 a:hover {
        color: #d41f26;
      }

      .shopping-cart-content ul li .shopping-cart-delete a:hover {
        color: #d41f26;
      }

      .mobile-off-canvas-active .mobile-aside-close:hover i {
        color: #d41f26;
      }

      .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button:hover {
        color: #d41f26;
      }

      .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a:hover {
        color: #d41f26;
      }

      .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a:hover {
        color: #d41f26;
      }

      .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:hover>a {
        color: #d41f26;
      }

      .main-search-active .sidebar-search-icon button:hover {
        color: #d41f26;
      }

      .main-search-active .sidebar-search-input .form-search button:hover {
        color: #d41f26;
      }

      .search-style-2 .form-search button:hover {
        color: #d41f26;
      }

      .setting-wrap-2 .setting-content2-left .currency-dropdown ul li a:hover {
        color: #d41f26;
      }

      .setting-wrap-2 .setting-content2-left:hover a {
        color: #d41f26;
      }

      .setting-wrap-2 .setting-content2-right a:hover {
        color: #d41f26;
      }

      .header-contact-search-wrap .header-contact ul li a:hover {
        color: #d41f26;
      }

      .header-contact-search-wrap .search-style-3 .form-search-3 button:hover {
        color: #d41f26;
      }

      .header-contact-search-wrap .search-style-4 .form-search-4 button:hover {
        color: #d41f26;
      }

      .cart-wrap-3 button span.mini-cart-price-3 {
        color: #d41f26;
      }

      .cart-wrap-3 button:hover i {
        color: #d41f26;
      }

      .clickable-mainmenu-active .clickable-mainmenu-close:hover i {
        color: #d41f26;
      }

      .clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav .sidebar-menu li a:hover {
        color: #d41f26;
      }

      .clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav .sidebar-menu li ul li a:hover {
        color: #d41f26;
      }

      .slider-area .nav-style-1 .owl-nav .owl-prev:hover {
        color: #d41f26;
      }

      .slider-area .nav-style-1 .owl-nav .owl-next:hover {
        color: #d41f26;
      }

      .slider-area .nav-style-3 .owl-nav button:hover {
        color: #d41f26;
      }

      .slider-area .nav-style-4 .owl-nav button:hover {
        color: #d41f26;
      }

      .slider-area .nav-style-5 .owl-nav button:hover {
        color: #d41f26;
      }

      .slider-content-1 h3 {
        color: #d41f26;
      }

      .slider-content-2 .slider-price-video .slider-video a i {
        color: #d41f26;
      }

      .slider-content-2 .slider-price-video .slider-video a:hover {
        color: #d41f26;
      }

      .slider-content-3 h1 {
        color: #d41f26;
      }

      .slider-content-4 .slider-btn-4>a:hover {
        border: 1px solid #d41f26;
        background-color: #d41f26;
      }

      .slider-content-4 .slider-btn-4>a:hover i {
        color: #d41f26;
      }

      .slider-content-5 h1 span.text-red {
        color: #d41f26;
      }

      .slider-content-5 .slider-btn-5 a:hover {
        border: 1px solid #d41f26;
      }

      .slider-content-6 .slider-btn-6 a {
        background-color: #d41f26;
      }

      .slider-content-6 .slider-btn-6 a:hover {
        color: #d41f26;
      }

      .slider-content-9 .slider-btn-9 a:hover {
        border: 1px solid #d41f26;
      }

      .slider-content-10 .slider-btn-10 a {
        border: 1px solid #d41f26;
      }

      .slider-content-border2::before {
        background-color: #d41f26;
      }

      .slider-video-7 a {
        color: #d41f26;
      }

      .slider-social-wrap a:hover {
        color: #d41f26;
      }

      .scroll-down a:hover:before {
        border: 3px solid #d41f26;
      }

      .drone-shape-1 .layer.layer-border::before {
        background-color: #d41f26;
      }

      .drone-shape-1 .layer.layer-border::after {
        background: #d41f26;
      }

      .drone-shape-2 .layer.layer-border:before {
        background-color: #d41f26;
      }

      .drone-shape-2 .layer.layer-border::after {
        background: #d41f26;
      }

      .banner-content a:hover {
        border-bottom: 1px solid #d41f26;
        color: #d41f26;
      }

      .banner-wrap .banner-content-5 .banner-zoom a:hover {
        border: 1px solid #d41f26;
        background-color: #d41f26;
      }

      .banner-wrap .banner-content-6 span {
        color: #d41f26;
      }

      .banner-wrap .banner-content-6 a:hover {
        border-bottom: 1px solid #d41f26;
      }

      .banner-wrap .banner-content-7 h3 span {
        color: #d41f26;
      }

      .banner-wrap .banner-content-7 a:hover {
        border-bottom: 1px solid #d41f26;
      }

      .banner-wrap .banner-content-8 h3 span {
        color: #d41f26;
      }

      .banner-wrap .banner-content-9 a:hover {
        border-bottom: 1px solid #d41f26;
      }

      .banner-wrap .banner-content-10-btn a:hover {
        border-bottom: 1px solid #d41f26;
      }

      .banner-wrap .banner-content-16 h3 a:hover {
        color: #d41f26;
      }

      .section-title-3 p span {
        color: #d41f26;
      }

      .product-wrap .product-img>span {
        background-color: #d41f26;
      }

      .product-wrap .product-img .product-action a:hover {
        border: 1px solid #d41f26;
        background-color: #d41f26;
      }

      .product-wrap .product-img .product-action-3 a {
        border: 1px solid #d41f26;
      }

      .product-wrap .product-img .product-action-3 a:hover {
        border: 1px solid #d41f26;
        background-color: #d41f26;
      }

      .product-wrap .product-img .product-action-2 a {
        color: #d41f26;
      }

      .product-wrap .product-img .product-action-4 .product-action-4-style a:hover {
        color: #d41f26;
      }

      .product-wrap .product-content .price-addtocart .product-addtocart a {
        color: #d41f26;
      }

      .modal-dialog .modal-header .close:hover {
        color: #d41f26;
      }

      .product-details-content .product-ratting-review .product-ratting i {
        color: #d41f26;
      }

      .product-details-content .pro-details-size .pro-details-size-content ul li a:hover {
        color: #d41f26;
      }

      .product-details-content .pro-details-compare-wishlist .pro-details-compare a:hover {
        color: #d41f26;
      }

      .product-details-content .pro-details-compare-wishlist .pro-details-wishlist a:hover {
        color: #d41f26;
      }

      .nav-style-2.owl-carousel>.owl-nav button:hover {
        background-color: #d41f26;
        border: 1px solid #d41f26;
      }

      .product-tab-list a.active {
        background-color: #d41f26;
      }

      .single-recent-selling .recent-selling-content .price span {
        color: #d41f26;
      }

      .shop-topbar-wrapper .shop-topbar-left .view-mode a.active {
        color: #d41f26;
      }

      .shop-list-wrap .product-list-img .product-list-quickview a:hover {
        border: 1px solid #d41f26;
        background-color: #d41f26;
      }

      .shop-list-wrap .shop-list-content .product-list-action a:hover {
        border: 1px solid #d41f26;
        background-color: #d41f26;
      }

      .footer-widget .footer-social ul li a:hover {
        color: #d41f26;
      }

      .footer-widget .footer-title-4 h3:before {
        background-color: #d41f26;
      }

      .footer-widget .footer-list ul li a:hover {
        color: #d41f26;
      }

      .footer-widget .footer-list-2 ul li a:hover {
        color: #d41f26;
      }

      .footer-widget .footer-list-4 ul li a:hover {
        color: #d41f26;
      }

      .copyright p a:hover {
        color: #d41f26;
      }

      .copyright-2 p a:hover {
        color: #d41f26;
      }

      .copyright-3 p a:hover {
        color: #d41f26;
      }

      .copyright-5 p a:hover {
        color: #d41f26;
      }

      .copyright-4 p a:hover {
        color: #d41f26;
      }

      .footer-tag-wrap .footer-tag-list ul li a:hover {
        color: #d41f26;
      }

      .footer-menu nav ul li a:hover {
        color: #d41f26;
      }

      .footer-social-2 ul li a:hover {
        color: #d41f26;
      }

      .footer-contact-wrap-2 .footer-contact-content-2 .footer-info-hm5 p a:hover {
        color: #d41f26;
      }

      .footer-contact-wrap-2 .footer-contact-content-2 .footer-social-hm5 ul li a:hover {
        color: #d41f26;
      }

      .overview-content .overview-video a i {
        color: #d41f26;
      }

      .overview-content .overview-video a:hover {
        color: #d41f26;
      }

      .deal-content-2 .deal-5-btn a {
        background-color: #d41f26;
      }

      .deal-content-4 .deal-btn4 a {
        color: #d41f26;
        border: 1px solid #d41f26;
      }

      .blog-wrap .blog-content-2 h3 a:hover {
        color: #d41f26;
      }

      .blog-wrap .blog-content-2 .blog-meta .blog-author a:hover {
        color: #d41f26;
      }

      .blog-wrap .blog-content-2 .blog-meta .blog-author a:before {
        background-color: #d41f26;
      }

      .blog-wrap .blog-content-2 .blog-meta .blog-like a i {
        color: #d41f26;
      }

      .blog-wrap .blog-content-2 .blog-meta .blog-like a:hover {
        color: #d41f26;
      }

      .blog-wrap .blog-border::before {
        background-color: #d41f26;
      }

      .pagination-style ul li a.active,
      .pagination-style ul li a:hover {
        background-color: #d41f26;
      }

      .pagination-style ul li a.next {
        background-color: #d41f26;
      }

      .pagination-style ul li a.prev {
        background-color: #d41f26;
      }

      .breadcrumb-content ul li {
        color: #d41f26;
      }

      .breadcrumb-content ul li a:hover {
        color: #d41f26;
      }

      .dec-review-bottom .dec-review-wrap .dec-review-img-wrap .dec-client-name .dec-client-rating i {
        color: #d41f26;
      }

      .dec-review-bottom .dec-review-wrap .dec-review-content .review-content-bottom .review-like span {
        color: #d41f26;
      }

      .dec-review-bottom .dec-review-wrap .dec-review-content .review-content-bottom .review-date span {
        color: #d41f26;
      }

      .product-details-img .zoompro-span>span {
        background-color: #d41f26;
      }

      .product-details-img .product-dec-slider .product-dec-icon:hover {
        background-color: #d41f26;
        border: 1px solid #d41f26;
      }

      .product-details-img .product-dec-slider-2 .product-dec-icon:hover {
        background-color: #d41f26;
        border: 1px solid #d41f26;
      }

      .box-slider-active.owl-carousel .owl-nav button:hover {
        background-color: #d41f26;
        border: 1px solid #d41f26;
      }

      .blog-details-wrap .blog-details-author .author-social ul li a:hover {
        color: #d41f26;
      }

      .blog-details-wrap .blog-tag ul li a:hover {
        background-color: #d41f26;
      }

      .sidebar-widget .sidebar-search .sidebar-search-form button:hover {
        color: #d41f26;
      }

      .sidebar-widget .price-filter #slider-range .ui-slider-range {
        background: #d41f26 none repeat scroll 0 0;
      }

      .sidebar-widget .price-filter #slider-range .ui-slider-handle {
        border: 4px solid #d41f26;
      }

      .sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input:checked~.checkmark {
        background-color: #d41f26;
        border: 2px solid #d41f26;
      }

      .sidebar-widget .sidebar-widget-list ul li a:hover span {
        background-color: #d41f26;
      }

      .sidebar-widget .sidebar-widget-tag ul li a:hover {
        background-color: #d41f26;
      }

      .sidebar-widget .sidebar-widget-video .blog-video-icon a {
        background-color: #d41f26;
      }

      .sidebar-widget .sidebar-widget-video .blog-video-icon a:hover {
        color: #d41f26;
      }

      .cart-table-content table tbody>tr td.product-name a:hover {
        color: #d41f26;
      }

      .cart-table-content table tbody>tr td.product-remove a:hover {
        color: #d41f26;
      }

      .cart-table-content table tbody>tr td.product-wishlist-cart>a {
        background-color: #d41f26;
      }

      .cart-shiping-update-wrapper .cart-shiping-update>a:hover,
      .cart-shiping-update-wrapper .cart-clear>button:hover,
      .cart-shiping-update-wrapper .cart-clear>a:hover {
        background-color: #d41f26;
      }

      .cart-tax .tax-wrapper .tax-select-wrapper button.cart-btn-2:hover,
      .discount-code-wrapper .tax-wrapper .tax-select-wrapper button.cart-btn-2:hover {
        background-color: #d41f26;
      }

      .cart-tax .discount-code form button.cart-btn-2:hover,
      .discount-code-wrapper .discount-code form button.cart-btn-2:hover {
        background-color: #d41f26;
      }

      .grand-totall h4.grand-totall-title {
        color: #d41f26;
      }

      .grand-totall a:hover {
        background-color: #d41f26;
      }

      .login-register-wrapper .login-register-tab-list a.active h4 {
        color: #d41f26;
      }

      .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a:hover {
        color: #d41f26;
      }

      .login-register-wrapper .login-form-container .login-register-form form .button-box button {
        background-color: #d41f26;
      }

      .single-contact-info>i {
        color: #d41f26;
      }

      .single-contact-info ul li i {
        color: #d41f26;
      }

      .contact-from button {
        background-color: #d41f26;
      }

      .checkout-login-info form .button-remember-wrap button:hover {
        background-color: #d41f26;
        border: 1px solid #d41f26;
      }

      .checkout-login-info .checkout-login-social ul li a:hover {
        background-color: #d41f26;
      }

      .checkout-login-info2 ul li a:hover {
        color: #d41f26;
      }

      .checkout-login-info3 form input[type=submit]:hover {
        background-color: #d41f26;
      }

      .billing-info-wrap h3:before {
        background-color: #d41f26;
      }

      .your-order-area h3:before {
        background-color: #d41f26;
      }

      .your-order-area .your-order-wrap .your-order-info ul li span {
        float: right;
        color: #d41f26;
      }

      .your-order-area .Place-order a {
        background-color: #d41f26;
      }

      .compare-table .table tbody tr td.product-image-title .category {
        color: #d41f26;
      }

      .compare-table .table tbody tr td.product-image-title .title:hover {
        color: #d41f26;
      }

      .compare-table .table tbody tr td.pro-remove button:hover {
        color: #d41f26;
      }

      .myaccount-tab-menu a:hover,
      .myaccount-tab-menu a.active {
        background-color: #d41f26;
        border-color: #d41f26;
      }

      .myaccount-content .welcome a:hover {
        color: #d41f26;
      }

      .myaccount-content .welcome strong {
        color: #d41f26;
      }

      .myaccount-content .account-details-form .single-input-item button {
        background-color: #d41f26;
      }

      .saved-message {
        border-top: 3px solid #d41f26;
      }

      .about-us-content h2 span {
        color: #d41f26;
      }

      .about-us-content h2::before {
        background-color: #d41f26;
      }

      .about-us-content .about-us-btn a {
        background-color: #d41f26;
      }
      /* CORES DO TEMA FIM */
    </style>
    <style>
      /* GLOBAL */
      .theme-hide- {
        display: none!important;
      }
      /* GLOBAL FIM */

      /* HEADER */
      .stick .header-right-wrap.mt-40 {
        margin-top: 17px;
      }

      .header-right-wrap .cart-wrap-2 button {
        width: 110px;
      }

      .header-right-wrap .cart-wrap-2 button span.count-style-2 {
        right: 14px;
      }

      .theme-header-logo {
        height: 100%;
        display: flex;
        align-items: center;
      }

      /*
      .main-header-wrap {
        padding-bottom: 20px;
      }
      */

      .theme-border-style {
        border-right: 1px solid #9c9687;
      }

      .search-wrap-2.search-wrap-2-mrg.theme-header-options.theme-border-style, .setting-wrap.setting-wrap-mrg.theme-header-options.theme-border-style {
        margin: 14px 14px 0 0;
        padding: 0 14px 0 0;
      }

      .header-right-wrap .setting-wrap a i {
        font-size: 22px;
        margin: 0;
      }

      .header-right-wrap .setting-wrap .setting-content {
        background: #fff none repeat scroll 0 0;
        padding: 26px 30px 32px;
        position: absolute;
        right: 190px;
        top: 90%;
        width: 180px;
      }


      .setting-content.theme-header-user-panel.show > ul > li > a, .setting-content.theme-header-user-panel > ul > li > a {
        justify-content: right;
      }

      .setting-content.theme-header-lang-panel.show, .header-right-wrap .setting-wrap .setting-content.theme-header-lang-panel {
        background: #fff none repeat scroll 0 0;
        padding: 26px 30px 32px;
        position: absolute;
        right: 130px;
        width: 150px;
        top: 90%;
      }

      .setting-content.theme-header-lang-panel.show > ul > li > a,  .header-right-wrap .setting-wrap .setting-content.theme-header-lang-panel > ul > li > a {
        justify-content: right !important;
      }

      .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.theme-header-social-media {
        color: #373737;
      }

      .main-menu.menu-common-style.menu-margin-4 > nav > ul > li {
        margin-right: 50px;
      }

      .shopping-cart-content ul li {
        flex-wrap: nowrap;
      }

      .theme-fav-1 {
        color: #d7000a !important;
      }

      .product-wrap .product-img .product-action-3 a:hover .theme-fav-1{
        color: #ffffff !important;
      }

      .product-wrap .product-img .product-action a:hover .theme-fav-1 {
        color: #ffffff !important;
      }

      .theme-flex-align-center {
        align-items: center;
      }

      .theme-menu-center .main-menu.menu-common-style.menu-margin-4 > nav > ul > li {
        margin: 0 25px !important;
      }

      @media only screen and (max-width: 1630px) {
        .theme-menu-center .main-menu.menu-common-style.menu-margin-4 > nav > ul > li {
          margin: 0 20px !important;
        }
      }

      @media only screen and (max-width: 1495px) {
        .theme-menu-center .main-menu.menu-common-style.menu-margin-4 > nav > ul > li {
          margin: 0 15px !important;
        }
      }

      @media only screen and (max-width: 1112px) {
        .theme-menu-center .main-menu.menu-common-style.menu-margin-4 > nav > ul > li {
          margin: 0 8px !important;
        }
      }
      /* HEADER FIM */

      /* SLIDER */
      .dot-style-2 .owl-dots {
        position: absolute;
        bottom: -30px;
        left: 48%;
      }

      .slider-area {
        position: relative;
        margin-bottom: 150px;
      }

      .slider-height-8 {
        height: 535px;
      }

      @media only screen and (max-width: 1399px) {
        .slider-height-8 {
          height: 470px;
        }
      }

      @media only screen and (max-width: 1199px) {
        .slider-height-8 {
          height: 380px;
        }
      }

      @media only screen and (max-width: 991px) {
        .slider-area {
          position: relative;
          margin-bottom: 50px;
        }

        .slider-height-8 {
          height: 300px;
        }
      }

      @media only screen and (max-width: 767px) {
        .res-white-overly-xs:before {
          display: none;
        }

        .slider-area {
          position: relative;
          margin-bottom: 0;
        }

        .slider-height-8 {
          height: 370px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      @media only screen and (max-width: 479px) {
        .slider-height-8 {
          height: 200px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      /* SLIDER FIM */

      .slider-content-4 h1 {
        font-size: 72px;
        color: #373737;
      }

      .slider-content-4 .slider-btn-4 > a {
        border: 1px solid #ff5151;
        color: #ff5151;
      }

      .slider-content-4 .slider-btn-4 > a > i {
        background-color: #ff5151;
      }

      .slider-content-4 .slider-btn-4 > a {
        border: 1px solid #d41f26;
        color: #d41f26;
      }

      .slider-content-4 .slider-btn-4 > a > i {
        background-color: #d41f26;
      }

      .slider-content-4 .slider-btn-4 > a {
        padding: 12px 25px 12px 25px;
      }

      .slider-sin-img-hm4 {
        height: unset;
      }

      @media only screen and (min-width: 1366px) and (max-width: 1600px) {
        .slider-sin-img-hm4 img {
          width: 100%;
          margin-left: 0;
        }
      }

      @media only screen and (min-width: 1200px) and (max-width: 1365px) {
        .slider-sin-img-hm4 img {
          width: 100%;
          margin-left: 0;
          margin-top: 100px;
        }
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .slider-sin-img-hm4 img {
          width: 100%;
          margin-left: 0;
          margin-top: 100px;
        }
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .slider-content-4 h1 {
          font-size: 45px;
          line-height: 50px;
        }
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .slider-content-4 h1 {
          font-size: 35px;
          line-height: 46px;
        }
      }

      @media only screen and (max-width: 767px) {
        .slider-content-4 h1 {
          font-size: 35px;
          line-height: 45px;
        }
      }
      /* SLIDER FIM */

      /* DESTAQUE CARROSSEL */
      .theme-home-feat-title {
        min-height: 64.78px;
      }
      /* DESTAQUE CARROSSEL FIM */

      /* HOME DESTAQUE MANUAL */
      .banner-wrap .banner-content-5 {
        padding: 30px 40px 10px 40px;
        background: rgb(0,0,0);
        background: -moz-linear-gradient(0deg, rgba(0,0,0,0.6054796918767507) 13%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.6054796918767507) 13%, rgba(255,255,255,0) 100%);
        background: linear-gradient(0deg, rgba(0,0,0,0.6054796918767507) 13%, rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
      }
      /* DESTAQUE MANUAL FIM */

      /* DESTAQUE COM ABAS */
      .theme-tab-feat-title > a > h5 {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-weight: 700;
      }
      /* DESTAQUE COM ABAS FIM */

      /* SOBRE */
      .theme-feature-icons .feature-border {
        border: 1px solid #f1f1f100;
        padding: 45px 0 45px;
        background-color: #d41f26;
      }

      .theme-feature-icons .feature-wrap.mb-30 {
        text-align: center;
        margin: 0 40px;
      }

      .theme-feature-icons .feature-wrap.mb-30 span {
        color: #fff;
      }

      .theme-feature-icons .feature-wrap.mb-30 h5 {
        color: #fff;
        font-weight: 700;
      }

      @media only screen and (max-width: 991px) {
        .about-us-content {
          margin-top: 30px;
        }

        .theme-feature-icons .feature-wrap.mb-30 {
          text-align: center;
          margin: 10px 40px;
        }
      }

      .about-us-content.theme-about-text-second {
        margin-top: 30px;
      }
      /* SOBRE FIM */

      /* PAGINA CATEGORIA DE PRODUTO */
      .theme-cat-pro-title-container {
        min-height: 43.19px;
      }

      .product-filter-wrapper {
        margin-top: 3px;
      }

      .theme-cat-all-products {
        font-weight: 600;
      }

      span.theme-cat-keyword-filter {
        padding: 5px 10px;
        border: 1px solid #e9e9e9;
        margin: 5px;
      }

      .product-wrap .product-content-2 .pro-price-2 {
        justify-content: space-between;
      }

      .product-wrap .product-content-2 .pro-price-2 span.theme-pro-label {
        font-weight: 400;
        font-size: 16px;
        margin: 0 0 0 10px;
        padding: 3px 5px;
        background-color: #d7000a;
        color: #fff;
      }

      .shop-list-wrap .shop-list-content span.theme-pro-label {
        font-weight: 400;
        font-size: 16px;
        margin: 0;
        padding: 3px 5px;
        background-color: #d7000a;
        color: #fff;
      }

      @media only screen and (max-width: 767px) {
        .shop-topbar-wrapper {
          display: flex;
          flex-wrap: wrap;
          padding: 15px 15px;
          -webkit-box-pack: inherit;
          -webkit-justify-content: inherit;
          -ms-flex-pack: inherit;
          justify-content: center;
        }

        .shop-topbar-wrapper > div {
          flex-basis: 100%;
          display: flex;
          justify-content: center;
        }
      }

      /* paginacao */
      .pagination {
        display: flex;
        padding-left: 0;
        justify-content: center;
        list-style: none;
      }

      .pagination-style ul li {
        display: inline-block;
        margin: 0 15px;
      }

      .pagination-style ul li a {
        font-size: 18px;
        color: #706c6c;
        width: 35px;
        height: 35px;
        line-height: 37px;
        border-radius: 100%;
        background-color: transparent;
        text-align: center;
        display: flex;
        justify-content: center;
        border: none;
        align-items: center;
      }

      .page-item a, .page-item span {
        font-size: 18px !important;
      }

      .page-item:first-child .page-link {
        width: 48px;
        color: #fff;
        background-color: #d41f26;
        height: 35px;
        border-color: #d41f26;
        border-radius: 50px 0px 0px 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px 0 0px;
      }

      .page-item:last-child .page-link {
        width: 48px;
        color: #fff;
        background-color: #d41f26;
        height: 35px;
        border-color: #d41f26;
        border-radius: 0 50px 50px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0px 0 20px;
      }

      .page-item.active .page-link {
        font-size: 18px;
        color: #ffffff;
        width: 35px;
        height: 35px;
        line-height: 37px;
        border-radius: 100%;
        background-color: #d7000a;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }
      /* PAGINA CATEGORIA DE PRODUTO FIM */

      /* PAGINA PRODUTO */
      .pro-details-compare-wishlist.theme-pro-availability {
        padding-top: 16px;
        border-bottom: none;
        border-top: 1px solid #f1f1f1;
        margin: 30px 0;
        padding-bottom: 0;
      }

      .product-details-content .pro-details-size {
        font-size: 16px;
      }

      .product-details-content .pro-details-size span {
        display: inline;
        text-transform: uppercase;
      }

      .product-details-content .pro-details-size div {
        margin-bottom: 5px;
      }

      .pro-details-price-wrap.theme-pro-price-wrap {
        margin-top: 36px;
      }

      select.iclient-cart-variations.level1.form-control {
        margin-bottom: 10px;
      }

      .theme-pro-buy-btns {
        display: flex;
      }

      .theme-pro-add-btn {
        margin-left: 20px;
      }

      .theme-pro-attr-label {
        font-weight:700;
      }
      /* PAGINA PRODUTO FIM */

      /* CONTACTOS */
      .g-recaptcha {
        margin-bottom: 30px;
      }

      .single-contact-info.text-center.mb-30 {
        min-height: 360px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      iframe#map_mapa-21 {
        width: 100%;
      }
      /* CONTACTOS */

      /* FOOTER */
      .footer-widget .footer-about p {
        margin: 6px 0 0;
      }
      /* FOOTER FIM */

      /* LOADER */
      .preloader_background {
        position: fixed;
        z-index: 999999;
        background: #fff;
        width: 100%;
        height: 100%;
      }

      .preloader_content {
        border: 0 solid transparent;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .preloader_img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height:225px;
        width: auto;
      }

      ul.loader_unstyled_list {
        list-style: none;
      }

      li.loader_list_item {
        text-align: center;
      }
    </style>

  <!-- Web Application Manifest -->
<link rel="manifest" href="https://micaela.3.ebsss.dev/manifest.json">
<!-- Chrome for Android theme color -->
<meta name="theme-color" content="#d7000a">

<!-- Add to homescreen for Chrome on Android -->
<meta name="mobile-web-app-capable" content="yes">
<meta name="application-name" content="Micaela Larisch">
<meta name="categories" content="tutus,t-shirts,calcas,calcoes,macacos,feminino,masculino,calcoes,casacos,macacos,saias,estacionamento,subcategoria do feminino,sub sub categoria do feminino,2024">
<link rel="icon" sizes="512x512" href="https://micaela.3.ebsss.dev/platform-images/settings/pwa_icon_image/4fb68bbb-d48e-49f0-9baf-ad859ce3c1e6.png?fm=png&amp;fit=crop&amp;w=512&amp;h=512&amp;s=f02877db3e5f5f9231e6f2f50aa2fcf0">

<!-- Add to homescreen for Safari on iOS -->
<meta name="apple-mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-status-bar-style" content="#d7000a">
<meta name="apple-mobile-web-app-title" content="Micaela Larisch">
<link rel="apple-touch-icon" href="https://micaela.3.ebsss.dev/platform-images/settings/pwa_icon_image/4fb68bbb-d48e-49f0-9baf-ad859ce3c1e6.png?fm=png&amp;fit=crop&amp;w=512&amp;h=512&amp;s=f02877db3e5f5f9231e6f2f50aa2fcf0">


<link href="/platform-images/settings/pwa_splash_image/654e4005-d91b-445f-87ef-a47280fa94ad.png?fm=png&amp;fit=crop&amp;w=640&amp;h=1136&amp;s=235d22f50899d1c5178375ab67d5f7ed" media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
<link href="/platform-images/settings/pwa_splash_image/654e4005-d91b-445f-87ef-a47280fa94ad.png?fm=png&amp;fit=crop&amp;w=750&amp;h=1334&amp;s=421a947844d153d720405ab176592862" media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
<link href="/platform-images/settings/pwa_splash_image/654e4005-d91b-445f-87ef-a47280fa94ad.png?fm=png&amp;fit=crop&amp;w=1242&amp;h=2208&amp;s=e80d6258ff2468325afa2214b8d3d9af" media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)" rel="apple-touch-startup-image" />
<link href="/platform-images/settings/pwa_splash_image/654e4005-d91b-445f-87ef-a47280fa94ad.png?fm=png&amp;fit=crop&amp;w=1125&amp;h=2436&amp;s=0209fcecac53992b1824c1e47e7f5651" media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)" rel="apple-touch-startup-image" />
<link href="/platform-images/settings/pwa_splash_image/654e4005-d91b-445f-87ef-a47280fa94ad.png?fm=png&amp;fit=crop&amp;w=828&amp;h=1792&amp;s=a3d06a5540b70f75c4752643ef22f998" media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
<link href="/platform-images/settings/pwa_splash_image/654e4005-d91b-445f-87ef-a47280fa94ad.png?fm=png&amp;fit=crop&amp;w=1242&amp;h=2688&amp;s=79bd3931dfe6d583a2a3c6ce6d21ca9a" media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)" rel="apple-touch-startup-image" />
<link href="/platform-images/settings/pwa_splash_image/654e4005-d91b-445f-87ef-a47280fa94ad.png?fm=png&amp;fit=crop&amp;w=1536&amp;h=2048&amp;s=11f1f015f3508acf81cdb0d7e396ad9b" media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
<link href="/platform-images/settings/pwa_splash_image/654e4005-d91b-445f-87ef-a47280fa94ad.png?fm=png&amp;fit=crop&amp;w=1668&amp;h=2224&amp;s=80ae4e200583e59b5b1d8af3be6e7ca0" media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
<link href="/platform-images/settings/pwa_splash_image/654e4005-d91b-445f-87ef-a47280fa94ad.png?fm=png&amp;fit=crop&amp;w=1668&amp;h=2388&amp;s=0412cb849ddd6ccda6f3b247304af664" media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
<link href="/platform-images/settings/pwa_splash_image/654e4005-d91b-445f-87ef-a47280fa94ad.png?fm=png&amp;fit=crop&amp;w=2048&amp;h=2732&amp;s=51206b10a74e68277b8eecea437e8dd2" media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />

<!-- Tile for Win8 -->
<meta name="msapplication-TileColor" content="">
<meta name="msapplication-TileImage" content="https://micaela.3.ebsss.dev/platform-images/settings/pwa_icon_image/4fb68bbb-d48e-49f0-9baf-ad859ce3c1e6.png?fm=png&amp;fit=crop&amp;w=512&amp;h=512&amp;s=f02877db3e5f5f9231e6f2f50aa2fcf0">

<script type="text/javascript">
    // Initialize the service worker
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('https://micaela.3.ebsss.dev/serviceworker.js', {
            scope: '.'
        }).then(function (registration) {
            // Registration was successful
            console.log('Micaela Larisch PWA: ServiceWorker registration successful with scope: ', registration.scope);
        }, function (err) {
            // registration failed :(
            console.log('Micaela Larisch PWA: ServiceWorker registration failed: ', err);
        });
    }

            var cmsProductCategories = [];

    window.onload = function () {
        var aTags = document.querySelectorAll('.class_variation_product_category');
        aTags.forEach(function (aTag) {
            var categories = aTag.textContent.split(', ');
            if (categories.length >= 2) {
                for (var i = 0; i < categories.length; i++) {
                    var matchedCategory = cmsProductCategories.find(function(category) {
                        return category.title === categories[i];
                    });

                    if (matchedCategory) {
                        var newATag = document.createElement('a');
                        newATag.href = "/cat/" + matchedCategory.slug;
                        newATag.className = 'class_variation_product_category';
                        newATag.textContent = categories[i];
                        aTag.parentNode.insertBefore(newATag, aTag.nextSibling);
                    } else {
                        // Use the original categories[i] as fallback if no match is found
                        var newATag = document.createElement('a');
                        newATag.href = "/cat/" + categories[i];
                        newATag.className = 'class_variation_product_category';
                        newATag.textContent = categories[i];
                        aTag.parentNode.insertBefore(newATag, aTag.nextSibling);
                    }

                    if (i < categories.length - 1) {
                        var comma = document.createTextNode(', ');
                        aTag.parentNode.insertBefore(comma, aTag.nextSibling);
                    }
                }
                aTag.parentNode.removeChild(aTag);
            }
        });
    };
</script><link href='https://micaela.3.ebsss.dev/customer/app-assets/fonts/flag-icon-css/css/flag-icon.min.css' rel='stylesheet' type='text/css' />

            <style>
                .iclientCheckContainer {
                    display: block;
                    position: relative;
                    padding-left: 35px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    font-size: 22px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;


                  }


                  .iclientCheckContainer input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 25px !important;
                    width: 25px !important;
                    margin-left: -35px;
                    z-index: 99999;
                  }

                  /* Create a custom checkbox */
                  .iclientCheckContainer .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    background-color: #eee;
                    border: 3px solid #DDD;
                  }

                  /* On mouse-over, add a grey background color */
                  .iclientCheckContainer:hover input ~ .checkmark {
                    background-color: #ccc;
                  }

                  /* When the checkbox is checked, add a blue background */
                  .iclientCheckContainer input:checked ~ .checkmark {
                    background-color: #2196F3;
                  }

                  /* Create the checkmark/indicator (hidden when not checked) */
                  .iclientCheckContainer .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                  }

                  /* Show the checkmark when checked */
                  .iclientCheckContainer input:checked ~ .checkmark:after {
                    display: block;
                  }

                  /* Style the checkmark/indicator */
                  .iclientCheckContainer .checkmark:after {
                    left: 7px;
                    top: 4px;
                    width: 5px;
                    height: 10px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }

            </style>

                        <style>
                            .iframe-iclient-logo{
                                display:none;
                            }
                        </style>
                        <style>/* Website - CSS Personalizado */

/* barra topo */

.navbar-dark {
    background: #d9d9d9; /* cor fundo */
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff; /* ícones botões */
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: #fff;
}

/* barra topo FIM */

/* botões topo */

.navbar-dark .navbar-nav .nav-link {
    color: #000;
}

    .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
        color: #000;
    }

/* botões topo FIM */

/* esconde cor de fundo da imagem do produto */

.shopping-cart .product-img {
    background: #f4f5fa00;
}

/* esconde cor de fundo da imagem do produto FIM */

/* botões "Compra" */

.shopping-cart .nav.nav-tabs li.nav-item .nav-link.active {
    background-color: #d7000a; /* cor fundo ativo*/
}

html body a:hover {
    color: #000; /* cor âncoras hover */
}

/* botões compras FIM */

/* botão comprar */

.btn-danger {
    color: #fff;
    border-color: #404040 !important;
    background-color: #404040 !important;
}

.btn-danger:hover {
    color: #fff !important;
    border-color: #d7000a !important;
    background-color: #d7000a !important;
}

.btn-danger:focus, .btn-danger:active {
    color: #fff !important;
    border-color: #d7000a !important;
    background-color: #d7000a !important;
}

/* botão comprar FIM */

/* botão continuar a comprar */

.btn-warning {
    color: #fff;
    border-color: #404040 !important;
    background-color: #404040 !important;
}

.btn-warning:hover {
    color: #000 !important;
    border-color: #000000 !important;
    background-color: #00000000 !important;
}

.btn-warning:focus, .btn-warning:active {
    color: #000 !important;
    border-color: #000000 !important;
    background-color: #00000000 !important;
}

/* botão continuar a comprar Fim */

/* botões da página de registo (força o texto dos botões a ficarem dentro das caixas) */

.btn-block {
    white-space: normal;
}

/* botões da página de registo FIM */

#iframe-client-message button, #iframe-client-message br{
    display: none;
}


@media (min-width: 1199px) {
.ecommerce-cart .product-shop .col-md-6 {
    max-width: 33.333%;
}
}

@media (min-width: 1399px) {
.ecommerce-cart .product-shop .col-md-6 {
    max-width: 25%;
}
}</style>
`;
