import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useDispatch, useSelector } from "react-redux";
import { ReduxDataKey } from "../../base/store/store";
import { cleanErrorsAndMessages, Session, signOut } from "../../base/store/authSlice";
import { cacheLoader, Layout } from "src/base/store/layoutSlice";
import { remotePath } from "src/base/settings/Paths";
import CMSLayout from "src/Pages/CMS/CMSLayout";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import loadjs from "loadjs";
import EBSSSModal from "src/base/atoms/EBSSSModal";
import { CSS } from "src/_utils/styles";
import ProductPage from "../Products/ProductPage";
import { handleFormSubmit } from "./Handlers/handleFormSubmit";
import { handleLinkClick } from "./Handlers/handleLinkClick";
import { renderItemsInCartPreview } from "./Renders/renderItemsInCartPreview";
import { renderLoading } from "./Renders/renderLoading";
import EBSSSLoading from "src/base/atoms/EBSSSLoading";

function addMissingClasses(preloaderBackground) {
  // Select the main div with class "preloader_background"
  if (preloaderBackground) {
    preloaderBackground.classList.add("w-100", "h-100", "d-flex", "justify-items-center", "overflow-hidden");
  }

  // Select the child div with class "preloader_content"
  const preloaderContent = preloaderBackground?.querySelector(".preloader_content");
  if (preloaderContent) {
    preloaderContent.classList.add("m-auto", "d-flex", "justify-items-center", "overflow-hidden");
  }

  // Select the ul with class "loader_unstyled_list"
  const loaderList = preloaderContent?.querySelector(".loader_unstyled_list");
  if (loaderList) {
    loaderList.classList.add("m-auto", "d-flex", "justify-items-center", "overflow-hidden");
  }

  // Select the li with class "loader_list_item"
  const loaderListItem = loaderList?.querySelector(".loader_list_item");
  if (loaderListItem) {
    loaderListItem.classList.add("m-auto", "d-flex", "w-100", "justify-items-center", "overflow-hidden");
  }

  // Select the img element with class "preloader_img"
  const preloaderImg = loaderListItem?.querySelector(".preloader_img");
  if (preloaderImg) {
    preloaderImg.classList.add("m-auto", "justify-items-center", "overflow-hidden");
  }
}

const cmsStyleButtons = {
  addCheckout: {
    className: "bg-white btn btn-light border text-dark px-4 border-danger fw-light",
    style: {
      backgroundColor: "#FFF",
      color: "#000",
      borderWidth: 1,
      borderRadius: 30,
      borderColor: "#D41F26",
      padding: 10,
    },
  },
  add: {
    className: "bg-white btn btn-light border text-dark px-4 border-danger fw-light",
    style: {
      backgroundColor: "#FFF",
      color: "#000",
      borderWidth: 1,
      borderRadius: 30,
      borderColor: "#D41F26",
      padding: 10,
    },
  },
};

const mainReplacers = (htmlString) => {
  let startHtmlString = htmlString;

  startHtmlString = startHtmlString.replaceAll(`onchange="window.location.href=value"`, `onchangereplaced="redirector"`);
  startHtmlString = startHtmlString.replaceAll(/(<a[^>]+)href="[^"]*\/auth\/sign-out-cms"([^>]*>)/g, '$1onclickreplaced="logout"$2');
  startHtmlString = startHtmlString.replaceAll(`/crm/cart`, "/checkout");
  startHtmlString = startHtmlString.replaceAll(`/crm/favorites`, "/favorites");
  startHtmlString = startHtmlString.replaceAll(`/cms-form/`, "/form/");
  startHtmlString = startHtmlString.replaceAll(`/cms/sort`, "");
  // startHtmlString = startHtmlString.replaceAll(/\/platform-images\/(?!.*icon.*)/g, "/platform-images/v2/");
  startHtmlString = startHtmlString.replaceAll(/\/platform-images\/(?=.*clientlazy=1)(?=.*fill=transparent)/g, "/platform-images/v2/");

  return startHtmlString;
};

const CMSRender = () => {
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const {
    htmlPage,
    htmlPageSettings,
    product,
    loadedHtml,
    productVariation,
    appSettings,
    fetchProduct,
    setProduct,
    setProductVariation,
    addBookmarks,
    fetchProductVariation,
    setLoadedHtml,
    resetLoginState,
    addItemToCart,
    setAppSettings,
  } = useEBSSS();
  const { cmsSnapshot, changeLanguage, trans } = useEBSSSLayout();
  const navigate = useNavigate();
  const [bodyEl, setBodyEl] = useState<{ content: string; scripts: Array<any>; urls: Array<any>; code: Array<any> }>({
    content: "",
    scripts: [],
    urls: [],
    code: [],
  });
  const paramsPage = useParams();
  const [, setSearchParams] = useSearchParams();
  const dispatcher = useDispatch();
  const [modalAddProduct, setModalAddProduct] = useState(false);
  const containerRef = useRef(null);
  const [optionProductSelected, setOptionProductSelected] = useState(null);
  const [variationSelected, setVariationSelected] = useState(null);
  const [variationChain, setVariationChain] = useState([]);
  const [currentlyVariations, setCurrentlyVariations] = useState(null);
  const [currentlyIdVariations, setCurrentlyIdVariations] = useState(null);
  const [tempProductToAddModal, setTempProductToAddModal] = useState(null);
  const [tempAppSettingsToAddModal, setTempAppSettingsToAddModal] = useState(null);
  const [loadElement, setLoadElement] = useState(null);

  const toggleModalAddProduct = () => {
    setModalAddProduct(!modalAddProduct);
  };

  const logout = () => {
    resetLoginState();
    dispatcher(signOut() as any);
    navigate(process.env.PUBLIC_URL);
  };

  function handleChange(event, vch, index) {
    const selectedValue = event.target.value;

    if (!selectedValue) {
      return;
    }
    if (vch?.type === "product") {
      setProduct(vch.value.find((vchP) => Number(vchP.id) === Number(selectedValue)));
      return;
    }

    setVariationSelected(selectedValue);

    let getChildLevel = Number(event.target.id.replace("child", ""));

    Array.from(document.querySelector(`#child${getChildLevel}`).parentElement.children).forEach((elSel) => {
      if (Number(elSel.id.replace("child", "")) > getChildLevel) {
        elSel.remove();
      }
    });

    console.log("to ", getChildLevel);

    if (variationChain.length > index + 1) {
      setVariationChain(variationChain.slice(0, index + 1));
    }
  }

  function renderVariations(vch, startId, index) {
    const container = startId.parentElement;

    const select = document.createElement("select");
    select.className = "select2 form-control mt-1";
    select.name = `child${index + 2}`;
    select.id = `child${index + 2}`;
    select.onchange = (e) => {
      return handleChange(e, vch, index);
    };

    // Create and append the default option
    const defaultOption = document.createElement("option");
    defaultOption.value = "";
    defaultOption.textContent = vch.group_name || "Select";
    select.appendChild(defaultOption);

    // Add options from the `vch.value` array
    if (vch.value[0] !== null && vch.value && vch.value.length > 0) {
      vch.value.forEach((optionItem) => {
        const option = document.createElement("option");
        option.value = optionItem?.id;
        option.textContent = optionItem?.name;
        select.appendChild(option);
      });
    } else {
      const option = document.createElement("option");
      option.textContent = trans("website.unavailable");
      option.disabled = true;
      select.appendChild(option);
    }

    container.appendChild(select);
  }

  function getContentBetweenHeaderAndFooter() {
    const parser = new DOMParser();
    const doc = parser.parseFromString(cmsSnapshot, "text/html");

    const header = doc.querySelector("header");
    const footer = doc.querySelector("footer");

    if (!header || !footer) {
      console.error("Header or footer not found in the provided HTML.");
      return "";
    }

    let loadContainer = document.createElement("div");

    let loadIndicator = document.createElement("div");
    loadIndicator.style.height = "90vh";
    loadIndicator.classList.add("d-flex");
    loadIndicator.classList.add("m-auto");
    loadIndicator.classList.add("align-items-center");
    loadIndicator.classList.add("justify-content-center");
    loadIndicator.appendChild(renderLoading(ebsssLayout));

    loadContainer.appendChild(header);
    loadContainer.appendChild(loadIndicator);
    loadContainer.appendChild(footer);

    doc.body.innerHTML = loadContainer.innerHTML;

    return doc.body.innerHTML;
  }

  function getOutermostParent(element, formRef) {
    let outermostParent = element;

    while (outermostParent.parentElement && outermostParent.parentElement !== formRef) {
      outermostParent = outermostParent.parentElement;
    }
    return outermostParent;
  }

  useEffect(() => {
    if (optionProductSelected) {
      let { price, photo, title } =
        product?.options
          ?.map((po) => po?.values)
          ?.flat()
          ?.find((op) => op?.token === optionProductSelected?.value) ?? {};

      setProduct({
        ...product,
        price,
        photo,
        title: product?.title + " " + title,
      });
      document.querySelector(".class_variation_product_price").textContent = ebsssLayout.commonAppSettings?.currency_symbol + " " + price;
    }
  }, [optionProductSelected]);

  useEffect(() => {
    if (variationChain[variationChain.length - 1]?.type === "product" && htmlPageSettings?.type === "crm_product") {
      document.querySelector(".iclient-cart").classList.remove("d-none");
    }
  }, [variationChain, variationSelected]);

  useEffect(() => {
    if (variationChain.length > 0 && htmlPageSettings?.type === "crm_product") {
      renderVariations(variationChain[variationChain.length - 1], document.querySelector(".level1"), variationChain.length + 1);
    }
  }, [variationChain]);

  useEffect(() => {
    if (product?.settings?.productsChild && product?.settings?.productsChild?.length > 0 && !currentlyVariations && !currentlyIdVariations) {
      setCurrentlyVariations(product?.settings?.productsChild);
      setCurrentlyIdVariations(product?.id);
    }
  }, [product?.id]);

  useEffect(() => {
    if (product && variationSelected && currentlyVariations && currentlyVariations?.length > 0 && htmlPageSettings?.type === "crm_product") {
      fetchProductVariation(currentlyIdVariations, variationSelected, null);
    }
  }, [variationSelected]);

  useEffect(() => {
    if (productVariation && htmlPageSettings?.type === "crm_product") {
      setVariationChain((s) => [...s, productVariation]);
    }
  }, [productVariation]);

  useEffect(() => {
    if (htmlPage) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlPage, "text/html");

      let bodyTag = doc.body;

      let bodyContent = bodyTag.outerHTML;
      let bodyElData: { content: string; scripts: Array<any>; urls: Array<any>; code: Array<any> } = {
        content: bodyContent,
        scripts: [],
        urls: [],
        code: [],
      };

      //Body
      if (window["BUILD_MODE"] === "DEV" || localStorage.getItem(window.location.host?.toUpperCase() + "_REACT_SCRIPT_INJECTION") === "true") {
        const bodyScripts = Array.from(bodyTag.querySelectorAll("script"));
        console.log(bodyScripts.find((bs) => bs.textContent.includes("calendar")));
        if (bodyScripts.length > 0) {
          bodyScripts.forEach((link) => {
            if (link.getAttribute("src") && link.getAttribute("src").startsWith("/")) {
              let fullUrlLinkHref = `${remotePath.clientInstanceUrl()}${link.getAttribute("src")}`;
              link.setAttribute("src", `${fullUrlLinkHref + (fullUrlLinkHref.includes("?") ? fullUrlLinkHref + "&" : "?") + "cms=true"}`);
            }
          });

          bodyTag.querySelectorAll("script").forEach((e) => e.remove());
          if (bodyTag.querySelector(".cart-wrap")) {
            let oldCartItem = bodyTag.querySelector(".cart-wrap");
            oldCartItem.innerHTML = "";
            oldCartItem.classList.add("me-5");
          }
          if (bodyTag.querySelector(".template-product-extras-container")) {
            let extrasContainer = bodyTag.querySelector(".template-product-extras-container");
            extrasContainer.classList.add("d-flex", "flex-wrap");
          }
          if (bodyTag.querySelector(".preloader_background") && !ebsssLayout?.loader) {
            let originalLoader = bodyTag.querySelector(".preloader_background");
            addMissingClasses(originalLoader);
            originalLoader.querySelectorAll("img")?.forEach((i) => {
              i.setAttribute("src", `${remotePath.clientInstanceUrl()}${i.getAttribute("src")}`);
            });
            dispatcher(cacheLoader({ loader: `<div class='loading-container'>${originalLoader.outerHTML}</div>` } as any));
          }
          bodyTag.querySelector(".preloader_background").remove();
          bodyContent = bodyTag.outerHTML;
          bodyElData = {
            ...bodyElData,
            content: bodyContent,
          };
        }

        bodyElData = {
          ...bodyElData,
          scripts: bodyScripts,
          urls: bodyScripts.map((elsc) => elsc.src).filter((elsc) => elsc.trim()),
          code: bodyScripts.filter((elsc) => elsc.textContent && elsc.getAttribute("element-template")).map((elsc) => elsc.textContent),
        };
      }

      setBodyEl({
        ...bodyElData,
      });

      let intervalCheckContentSet = setInterval(() => {
        console.log("Checking root-inner to add static listeners (redir, logout, add product...)");
        if (document && document.querySelector("#root-inner") && document.querySelector("#root-inner")?.children?.length > 0) {
          const elementsWithOnChange = Array.from(document.querySelectorAll("[onchangereplaced]"));
          const elementsWithOnClick = Array.from(document.querySelectorAll("[onclickreplaced]"));

          console.log(elementsWithOnChange, elementsWithOnClick);

          if (elementsWithOnChange.length > 0) {
            elementsWithOnChange?.forEach((e) => {
              if (e.getAttribute("onchangereplaced") === "redirector") {
                e.addEventListener("change", (event) => {
                  const value = event.target["value"];
                  if (value) {
                    setSearchParams(`${value}`);
                  }
                });
              }
            });
          }

          if (elementsWithOnClick.length > 0) {
            elementsWithOnClick?.forEach((e) => {
              if (e.getAttribute("onclickreplaced") === "logout") {
                e.addEventListener("click", logout);
              }
            });
          }

          if (htmlPageSettings) {
            if (htmlPageSettings?.type === "crm_product") {
              if (product?.options?.length > 0) {
                product.options.forEach((opt) => {
                  if (opt.values && opt?.values?.length > 0) {
                    opt.values.forEach((opv, i) => {
                      if (i === 0) {
                        console.log(opv.product_option_id);
                        setOptionProductSelected({
                          option: opt.token,
                          value: opv.token,
                          product_option_id: opv.product_option_id,
                          price: product?.options?.find((optFind) => optFind.token === opt.token)?.values?.find((valFind) => valFind.token === opv.token)
                            ?.price,
                        });
                      }
                    });
                  }
                });
              }

              if (document.querySelector(".iclient-cart")) {
                document.querySelectorAll(".iclient-cart").forEach((el) => {
                  if (el.getAttribute("href") === "Não") {
                    el.remove();
                  }
                });

                if (product?.product_extras && Array.isArray(product?.product_extras)) {
                  document.querySelectorAll('[name="extra[]"').forEach((el) => {
                    el.addEventListener("click", () => {
                      if (!window["PRODUCT_EXTRAS"]) {
                        window["PRODUCT_EXTRAS"] = [];
                      }
                      let val = product?.product_extras?.find((ext) => ext.token === el.getAttribute("value"));

                      if (val) {
                        let selectedExtrasBefore = [...window["PRODUCT_EXTRAS"]];
                        if (selectedExtrasBefore.includes(val.id)) {
                          window["PRODUCT_EXTRAS"] = selectedExtrasBefore.filter((x) => x !== val.id);
                          return;
                        }
                        let marked = document.createElement("div");
                        let markedText = document.createElement("span");
                        markedText.textContent = "✓";
                        markedText.style.margin = "auto";
                        markedText.style.maxHeight = "75%";
                        markedText.style.justifyContent = "center";
                        markedText.style.display = "flex";
                        markedText.style.alignItems = "center";
                        markedText.style.fontSize = "x-small";

                        marked.style.width = "13.5px";
                        marked.style.height = "13.5px";
                        marked.style.position = "absolute";
                        marked.style.backgroundColor = "#AFF435";
                        marked.style.color = "#FFFFFF";
                        marked.style.textAlign = "center";
                        marked.style.justifyContent = "center";
                        marked.style.display = "flex";
                        marked.style.borderRadius = "3px";
                        marked.style.border = "1.5px solid #228b22";
                        marked.appendChild(markedText);
                        marked.onclick = () => {
                          if (!window["PRODUCT_EXTRAS"]) {
                            window["PRODUCT_EXTRAS"] = [];
                          }
                          window["PRODUCT_EXTRAS"] = selectedExtrasBefore.filter((x) => x !== val.id);
                          marked.remove();
                        };
                        el.after(marked);
                        window["PRODUCT_EXTRAS"] = [...selectedExtrasBefore, val.id];
                      }
                    });
                  });
                }

                if (product?.product_payment_types && Array.isArray(product?.product_payment_types) && product?.product_payment_types?.length > 1) {
                  let checkIfHasPaymenyTypeTag = document.querySelector(".iclient-payment-types");
                  let getButtonsContainerUntilFormContainer, getContainer;

                  if (!checkIfHasPaymenyTypeTag) {
                    getContainer = document.querySelector(".iclient-cart").closest("form");
                    getButtonsContainerUntilFormContainer = getOutermostParent(document.querySelector(".iclient-cart"), getContainer);
                  } else {
                    getContainer = document.querySelector(".iclient-payment-types").closest("form");
                    checkIfHasPaymenyTypeTag = checkIfHasPaymenyTypeTag.parentElement;
                  }

                  if (getContainer) {
                    let paymentMethodLabel = document.createElement("p");
                    paymentMethodLabel.textContent = trans("billing.payment_type");

                    let paymentMethodContainer = document.createElement("div");
                    paymentMethodContainer.style.display = "flex";
                    paymentMethodContainer.style.flexDirection = "row";
                    paymentMethodContainer.style.rowGap = "20px";
                    paymentMethodContainer.style.columnGap = "10px";
                    paymentMethodContainer.style.marginBottom = "10px";
                    paymentMethodContainer.style.flexWrap = "wrap";

                    let removeOtherBorderColors = () => {
                      paymentMethodContainer.querySelectorAll("span").forEach((div) => {
                        div["style"].backgroundColor = "#f9f9f9";
                        div["style"].color = "#000";
                      });
                    };

                    console.log(product?.product_payment_types);

                    product?.product_payment_types?.forEach((paymentType) => {
                      let paymentMethod = document.createElement("div");
                      paymentMethod.style.display = "inline-block";

                      let paymentName = document.createElement("span");
                      paymentName.id = paymentType?.payment_type?.token;
                      paymentName.style.backgroundColor = "#f9f9f9";
                      paymentName.style.borderRadius = "25px";
                      paymentName.style.padding = "10px";
                      paymentName.style.borderColor = "#f9f9f9";
                      paymentName.style.borderWidth = "1px";
                      paymentName.style.cursor = "pointer";
                      paymentName.textContent = paymentType?.payment_type?.name;

                      paymentMethod.addEventListener("click", (e) => {
                        console.log("From listener: ", paymentType?.payment_type?.token);
                        window["PRODUCT_PAYMENT_TYPE"] = paymentType?.payment_type?.token;
                        removeOtherBorderColors();
                        paymentName["style"].backgroundColor = "#AFF435";
                        paymentName["style"].color = "#fff";
                      });
                      paymentMethod.appendChild(paymentName);
                      paymentMethodContainer.appendChild(paymentMethod);
                    });
                    if (getButtonsContainerUntilFormContainer) {
                      getContainer.insertBefore(paymentMethodContainer, getButtonsContainerUntilFormContainer);
                      getContainer.insertBefore(paymentMethodLabel, paymentMethodContainer);
                    } else {
                      checkIfHasPaymenyTypeTag.appendChild(paymentMethodContainer);
                    }
                  }
                }
              }
              if (product?.settings?.customFields) {
                // handleProductCustomField(product, setUserCustomFields);
              }
              if (document.querySelector(".iclient-cart-option_price")) {
                document.querySelector(".iclient-cart-option_price").addEventListener("change", (e) => {
                  let childrenOption = e.target["value"];
                  let optionsToFind = product?.options?.map((po) => po.values)?.flat();
                  let copyOptionsToFind = product?.options;
                  let opt = optionsToFind.find((o) => o.token === childrenOption);

                  setOptionProductSelected({
                    value: childrenOption,
                    price: opt?.price,
                    product_option_id: opt?.product_option_id,
                    option: copyOptionsToFind.find((o) => o.values.find((ov) => ov.token === childrenOption))?.token,
                  });
                });
              }
              if (document.querySelector(".iclient-cart-variations")) {
                document.querySelector(".level1").addEventListener("change", (e) => {
                  let variationValue = e.target["value"];
                  document.querySelectorAll('[id^="child"]').forEach((el) => el.remove());
                  setVariationSelected(variationValue);
                  setVariationChain([]);
                });
              }
              if (document.querySelectorAll('input[type="radio"]:not([value])')) {
                document.querySelectorAll('input[type="radio"]:not([value])').forEach((el) => el.remove());
              }
            }
          }

          clearInterval(intervalCheckContentSet);
        }
      }, 1000);
    }
  }, [htmlPage, htmlPageSettings]);

  // useEffect(() => {
  //   if (bodyEl.content && sessionNow?.session?.cart?.length > 0) {
  //     let itemContainer = document.querySelector(".shopping-cart-content");
  //     itemContainer.childNodes.forEach((cn) => cn.remove());
  //     sessionNow?.session?.cart.forEach((prod) => {
  //       renderItemsInCartPreview(prod["p"], ebsssLayout, sessionNow, product, navigate, trans);
  //     });
  //   }
  // }, [bodyEl.content, sessionNow?.session?.cart]);

  console.log(optionProductSelected);

  useEffect(() => {
    if (!modalAddProduct && htmlPageSettings?.type === "crm_product") {
      setProduct(null);
      setAppSettings(null);
    }
  }, [modalAddProduct, setAppSettings, setProduct]);

  useEffect(() => {
    setModalAddProduct(false);
  }, [sessionNow?.session?.cart?.length]);

  useEffect(() => {
    let submitHandler = (event) => handleFormSubmit(event, null, null, ebsssLayout, navigate);
    let clickHandler = (event) =>
      handleLinkClick(
        event,
        ebsssLayout,
        sessionNow,
        product,
        window["PRODUCT_PAYMENT_TYPE"],
        window["PRODUCT_EXTRAS"],
        optionProductSelected,
        paramsPage,
        appSettings,
        tempProductToAddModal,
        tempAppSettingsToAddModal,
        htmlPageSettings,
        handleFormSubmit,
        navigate,
        trans,
        addItemToCart,
        changeLanguage,
        dispatcher,
        addBookmarks,
        setModalAddProduct,
        fetchProduct,
        setTempProductToAddModal,
        setTempAppSettingsToAddModal
      );
    document.addEventListener("submit", submitHandler);
    document.addEventListener("click", clickHandler);

    return () => {
      document.removeEventListener("submit", submitHandler);
      document.removeEventListener("click", clickHandler);
    };
  }, [document, product]);

  useEffect(() => {
    let guaranteeRenderingTimer = setTimeout(() => {
      requestAnimationFrame(() => {
        let toAddSc = [];
        if (
          (window["BUILD_MODE"] === "DEV" && bodyEl.urls?.length > 0) ||
          localStorage.getItem(window.location.host?.toUpperCase() + "_REACT_SCRIPT_INJECTION") === "true"
        ) {
          console.log("Checking if has scripts to add...");
          bodyEl.urls?.forEach((sc) => {
            console.log("+ scripts being checked if it was added before...");
            if (!document.querySelector(`script[src="${sc}"]`)) {
              toAddSc.push(sc);
            }
          });

          if (toAddSc.length > 0) {
            console.log("+ Scripts wasnt added before");
            loadjs(toAddSc, "all-scripts", { async: true, numRetries: 3 });
            loadjs.ready("all-scripts", () => {
              console.log(">>> Scripts loaded");
              bodyEl.code.forEach((st) => {
                let createElScript = document.createElement("script");
                createElScript.async = true;
                createElScript.textContent = st;
                document.body.appendChild(createElScript);
              });
              let delayToScriptsActivation = setTimeout(() => {
                console.log(">>> Showing content after scripts loaded");
                setLoadedHtml(true);
                clearTimeout(delayToScriptsActivation);
              }, 1000);
            });
          } else {
            setLoadedHtml(true);
          }
        }
        clearTimeout(guaranteeRenderingTimer);
      });
    }, 0);

    return () => {
      if (window["BUILD_MODE"] === "DEV" || localStorage.getItem(window.location.host?.toUpperCase() + "_REACT_SCRIPT_INJECTION") === "true") {
        console.log("- Unmounting scripts...");
        loadjs.reset();
        bodyEl.urls?.forEach((sc) => {
          if (document.querySelector(`script[src="${sc}"]`)) {
            document.querySelector(`script[src="${sc}"]`).remove();
          }
        });
      }
    };
  }, [bodyEl.urls]);

  useEffect(() => {
    if (!loadedHtml && cmsSnapshot) {
      let feedbackLoading = getContentBetweenHeaderAndFooter();
      setLoadElement(feedbackLoading);
    }
  }, [loadedHtml]);

  useEffect(() => {
    return () => {
      setCurrentlyIdVariations(null);
      setCurrentlyVariations(null);
      setProductVariation(null);
      dispatcher(cleanErrorsAndMessages());
    };
  }, []);

  return (
    <CMSLayout>
      {!loadElement && !loadedHtml ? <EBSSSLoading /> : <></>}
      {loadedHtml ? <></> : <div dangerouslySetInnerHTML={{ __html: loadElement }} />}
      <div ref={containerRef} className={loadedHtml ? "" : "d-none"} id="root-inner" dangerouslySetInnerHTML={{ __html: mainReplacers(bodyEl.content) }} />
      <EBSSSModal
        hideFooter
        hideSubmitButton
        style={CSS({ borderRadius: 15 })}
        modalTitle="Adicionar ao Carrinho"
        modal={modalAddProduct}
        toggle={toggleModalAddProduct}
        triggerEl={<></>}
      >
        <div style={CSS({ marginLeft: "3rem", marginRight: "3rem", marginTop: "3rem" })}>
          <ProductPage
            isCMS={true}
            cmsStyleButtons={true ? {} : cmsStyleButtons}
            cmsExtra={window["PRODUCT_EXTRAS"]}
            cmsOption={optionProductSelected}
            cmsVariation={variationSelected}
            cmsPaymentType={window["PRODUCT_PAYMENT_TYPE"]}
            openModal={modalAddProduct}
          />
        </div>
      </EBSSSModal>
    </CMSLayout>
  );
};

export default CMSRender;
