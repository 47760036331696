//@ts-nocheck
import { CSSProperties, useState } from "react";
import { Col } from "reactstrap";
import { ToolTip } from "../AbstractElements";

const TooltipToggle = ({
  children,
  title,
  id,
  style,
}: {
  children: JSX.Element | JSX.Element[];
  title: string | JSX.Element;
  id: number;
  style: CSSProperties;
}) => {
  const [basictooltip, setbasictooltip] = useState(false);
  const toggle = () => setbasictooltip(!basictooltip);
  return (
    <Col style={style}>
      <div id={"TooltipExample" + id}>{children}</div>
      <ToolTip
        attrToolTip={{
          placement: "top",
          isOpen: basictooltip,
          target: "TooltipExample" + id,
          toggle: toggle,
        }}
      >
        {title}
      </ToolTip>
    </Col>
  );
};

export default TooltipToggle;
