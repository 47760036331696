import { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgotPasswordModifyPage from "src/Pages/Auth/ForgotPasswordModifyPage";
import ForgotPasswordVerifyPage from "src/Pages/Auth/ForgotPasswordVerifyPage";
import LoginPage from "src/Pages/Auth/LoginPage";
import RegisterPage from "src/Pages/Auth/RegisterPage";
import RegisterPagePassword from "src/Pages/Auth/RegisterPagePassword";
import RegisterPageToken from "src/Pages/Auth/RegisterPageToken";
import CheckoutPage from "src/Pages/Checkout/CheckoutPage";
import PreCheckoutPage from "src/Pages/Checkout/PreCheckoutPage";
import TermsPage from "src/Pages/Terms/TermsPage";
import TicketPublicAnswer from "src/Pages/Ticket/TicketPublicAnswer";
import AuthRouter from "./MainRouter";
import MiddlewareAuthRoute from "./MiddlewareAuthRoute";
import MiddlewareTermsRoute from "./MiddlewareTermsRoute";
import { guestRoutes } from "./GuestRoutes";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import { useSelector } from "react-redux";
import Layout from "../Layout/Layout";
import MainRouter from "./MainRouter";
import FormsPublicPage from "src/Pages/Forms/FormsPublicPage";
import FormPublicPage from "src/Pages/Forms/FormPublicPage";
import MiddlewareEnabledRoute from "./MiddlewareEnabledRoute";
import { cmsRoutes } from "./CMSRoutes";
import CMSContainer from "../Pages/CMS/CMSContainer";
import { Layout as LayoutType } from "src/base/store/layoutSlice";
import MiddlewareVersionRoute from "./MiddlewareVersionRoute";
import MultiCheckoutPage from "src/Pages/Checkout/MultiCheckoutPage";
import SellerPage from "src/Pages/Seller/SellerPage";

const Routers = () => {
  const ebsssLayout: LayoutType = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  console.log(cmsRoutes(ebsssLayout?.template?.pages));
  return (
    <Fragment>
      <BrowserRouter basename={"/"}>
        <Routes>
          <Route path={"/"} element={<MiddlewareVersionRoute />}>
            <Route path={"/"} element={<MiddlewareEnabledRoute />}>
              <Route path={`${process.env.PUBLIC_URL}/terms`} element={<TermsPage />} />
              <Route path={`${process.env.PUBLIC_URL}/auth/sign-in`} element={<LoginPage />} />
              <Route path={`${process.env.PUBLIC_URL}/auth/sign-in/forgot`} element={<ForgotPasswordVerifyPage />} />
              <Route path={`${process.env.PUBLIC_URL}/auth/sign-in/forgot/modify`} element={<ForgotPasswordModifyPage />} />
              <Route path={`${process.env.PUBLIC_URL}/auth/sign-up`} element={<RegisterPage />} />
              <Route path={`${process.env.PUBLIC_URL}/auth/sign-up/token`} element={<RegisterPageToken />} />
              <Route path={`${process.env.PUBLIC_URL}/auth/sign-up/password`} element={<RegisterPagePassword />} />
              <Route path={`${process.env.PUBLIC_URL}/checkout/pre`} element={<PreCheckoutPage />} />
              <Route path={`${process.env.PUBLIC_URL}/checkout/multi`} element={<MultiCheckoutPage />} />
              <Route path={`${process.env.PUBLIC_URL}/checkout/:seller?`} element={<CheckoutPage />} />
              <Route path={`${process.env.PUBLIC_URL}/crm/home`} element={<FormsPublicPage />} />
              <Route path={`${process.env.PUBLIC_URL}/form/:slug`} element={<FormPublicPage />} />
              {guestRoutes.map(({ path, Component }, i) => (
                <Route element={<Layout hideLayout={false} children={undefined} classNames={undefined} />} key={i}>
                  <Route path={path} element={Component} />
                </Route>
              ))}
              {ebsssLayout?.template && ebsssLayout?.template?.pages?.length > 0 ? (
                cmsRoutes(ebsssLayout?.template?.pages)?.map(({ path, Component }, i) => (
                  <Route element={<CMSContainer hideLayout={false} children={undefined} classNames={undefined} />} key={i}>
                    <Route path={path} element={Component} />
                  </Route>
                ))
              ) : (
                <></>
              )}
              <Route path={"/"} element={<MiddlewareAuthRoute />}>
                <Route path={"/"} element={<MiddlewareTermsRoute />}>
                  <Route path={`/*`} element={<MainRouter />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};
export default Routers;
