import { Fragment, useEffect, useState } from "react";
import { remotePath } from "src/base/settings/Paths";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { ToastContainer } from "react-toastify";
import EBSSSRightCart from "src/base/atoms/EBSSSRightCart";
import { useSelector } from "react-redux";
import { ReduxDataKey } from "src/base/store/store";
import { Session } from "src/base/store/authSlice";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { Layout } from "src/base/store/layoutSlice";
import { DEFINED_STYLES } from "./DefinedStyles";

const CMSLayout = ({ children, ...rest }) => {
  const [headEl, setHeadEl] = useState({ styles: "", links: [], scripts: [] });
  const { htmlPage, loadedHtml, setLoadedHtml } = useEBSSS();
  const [showRightCart, setShowRightCart] = useState(false);
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);

  useEffect(() => {
    if (htmlPage) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlPage, "text/html");

      //Head
      let getHeadScripts = Array.from(doc.head.querySelectorAll("script"));

      let headElData = {
        scripts: getHeadScripts,
        styles: "",
        links: [],
      };

      // if (window["BUILD_MODE"] === "DEV" || localStorage.getItem(window.location.host?.toUpperCase() + "_REACT_SCRIPT_INJECTION") === "true") {
      let getCSSLinks = Array.from(doc.head.querySelectorAll("link"));
      if (getCSSLinks.length > 0) {
        getCSSLinks.forEach((link) => {
          if (link.getAttribute("href") && link.getAttribute("href").startsWith("/")) {
            let fullUrlLinkHref = `${remotePath.clientInstanceUrl()}${link.getAttribute("href")}`;
            if (link.getAttribute("href").includes("/platform-images/")) {
              link.setAttribute("href", fullUrlLinkHref);
            } else {
              link.setAttribute("href", `${fullUrlLinkHref + (fullUrlLinkHref.includes("?") ? fullUrlLinkHref + "&" : "?") + "cms=true"}`);
            }
          }
        });
        //}

        let getCSSStyles = Array.from(doc.head.querySelectorAll("style"));
        getCSSStyles = getCSSStyles.map((st) => {
          st.setAttribute("cms", "true");
          return st;
        });
        let styleContent = getCSSStyles.map((style) => style.outerHTML).join("\n");

        headElData = {
          ...headElData,
          styles: styleContent,
          links: getCSSLinks,
        };
      }

      setHeadEl({
        ...headElData,
      });
    }
  }, [htmlPage]);

  useEffect(() => {
    if (window["BUILD_MODE"] !== "DEV" && localStorage.getItem(window.location.host?.toUpperCase() + "_REACT_SCRIPT_INJECTION") !== "true") {
      console.log("-> Loaded html + styles, but receiving external scripts out of React, end loading...");
      setLoadedHtml(!loadedHtml);
    }
  }, [headEl.links]);

  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          {window["BUILD_MODE"] === "DEV" || localStorage.getItem(window.location.host?.toUpperCase() + "_REACT_SCRIPT_INJECTION") === "true" ? (
            <>
              {headEl.links?.map((lk) => {
                //@ts-ignore
                return <link cms="true" rel="stylesheet" href={lk.getAttribute("href")} />;
              })}
              <style
                //@ts-ignore
                cms="true"
              >{`${headEl.styles.concat(DEFINED_STYLES)}`}</style>
            </>
          ) : (
            <></>
          )}
          {headEl.scripts?.map((lk) => {
            //@ts-ignore
            return <script cms="true" async type={lk.getAttribute("type")} src={lk.getAttribute("src")} />;
          })}
        </Helmet>
        <div className="relative">
          {children}
          {!showRightCart && sessionNow?.session?.cart?.length > 0 ? (
            <div
              className="shadow-sm"
              style={{
                position: "fixed",
                top: "2%",
                right: "15px",
                backgroundColor: "#afaa",
                borderRadius: "50%",
                width: 50,
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                zIndex: 1000,
                cursor: "pointer",
              }}
              onClick={() => setShowRightCart(true)}
            >
              <i className="la la-shopping-cart text-center fs-4"></i>
            </div>
          ) : (
            <></>
          )}
        </div>
      </HelmetProvider>
      <ToastContainer />
      <EBSSSRightCart showRightCart={showRightCart} setShowRightCart={setShowRightCart} />
    </Fragment>
  );
};
export default CMSLayout;
