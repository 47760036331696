import { Fragment, useEffect, useState } from "react";
import { Trash2 } from "react-feather";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { scrollUpPage } from "src/_utils/elements";
import { CSS, parseHTML } from "src/_utils/styles";
import { getQueryParams } from "src/_utils/urls";
import Page from "src/base/Page";
import { EBSSSPagination } from "src/base/atoms/EBSSSPagination";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { remotePath } from "src/base/settings/Paths";
import { H6, Image, LI, P, UL } from "../../AbstractElements";

const BookmarksPage = () => {
  const { trans } = useEBSSSLayout();
  const { bookmarks, setBookmarks, loaded, getBookmarks, removeBookmarks } = useEBSSS();
  const [page, setPage] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [filterSize, setFilterSize] = useState(undefined);
  const [stepSize, setStepSize] = useState(0);
  const [values, setValues] = useState([1, 100]);
  const [filterPrice, setFilterPrice] = useState({
    min: 0,
    max: undefined,
  });

  const removeBk = async (token) => {
    try {
      setBookmarks((s) => {
        return { ...s, data: s.data?.filter((b) => b.token !== token) };
      });
      await removeBookmarks(token);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!bookmarks && Array.isArray(bookmarks?.data) && bookmarks?.data?.length) {
      let filtersPriceMinMax = {
        ...filterPrice,
        max: Math.ceil(Number(bookmarks?.data?.sort((a, b) => Number(b.price) - Number(a.price))[0]?.price)),
      };
      setFilterPrice({
        ...filtersPriceMinMax,
      });
      setStepSize(
        //@ts-ignore
        String(bookmarks?.data?.sort((a, b) => Number(b.price) - Number(a.price))[0]?.price ?? 0) / 20
      );
      setValues([filtersPriceMinMax.min, filtersPriceMinMax.max]);
    }
  }, [bookmarks?.data]);

  useEffect(() => {
    let actualParams = getQueryParams(window.location.href);
    if (actualParams?.page) {
      setPage((p) => {
        if (p !== null) {
          return p;
        } else {
          return Number(actualParams?.page);
        }
      });
    }
  }, [window.location.search]);

  useEffect(() => {
    scrollUpPage();
    if (bookmarks?.data) {
      if (page && page > 0) {
        getBookmarks(page);
      }
    }
  }, [page]);

  useEffect(() => {
    scrollUpPage();
    let actualParams = getQueryParams(window.location.href);
    if (!bookmarks?.data) {
      getBookmarks(actualParams?.page);
    }
  }, []);

  const [Sidebar, setSidebar] = useState(false);
  const OnClickHandel = () => {
    setSidebar(!Sidebar);
  };

  return (
    <Page
      breads={[
        {
          name: `${trans("website.favorites")}`,
          active: true,
          url: "/favorites?page=1",
        },
      ]}
    >
      <Fragment>
        <div className="email-wrap bookmark-wrap">
          <Row>
            <Col xl="12" md="12" className="box-col-12">
              <div className="email-right-aside bookmark-tabcontent">
                <Card className="email-body radius-left">
                  <CardBody className="pb-0">
                    <div className={`details-bookmark text-center ''`}>
                      <Fragment>
                        <Row id="bookmarkData">
                          {bookmarks?.data?.length > 0 ? (
                            bookmarks?.data?.map((data) => {
                              return (
                                <Col xl="3" lg="6" sm="6" md="4" key={data.id} className="xl-25 box-col-3">
                                  <Card className="bookmark-card o-hidden" style={CSS({ height: "97.5%" })}>
                                    <div className="details-website">
                                      <Link to={`${process.env.PUBLIC_URL}/crm/products/${data?.product?.slug}`}>
                                        <Image
                                          attrImage={{
                                            className: "img-fluid",
                                            src: `${
                                              data.product.photo
                                                ? remotePath.assetsUrl(data.product.photo)
                                                : "https://www.coop.se/Assets/Images/missing-image-product.png"
                                            }`,
                                            alt: "",
                                          }}
                                        />
                                      </Link>
                                      <div className="desciption-data">
                                        <div className="title-bookmark">
                                          <Link to={`${process.env.PUBLIC_URL}/crm/products/${data?.product?.slug}`}>
                                            <H6
                                              attrH6={{
                                                className: "title_0",
                                              }}
                                            >
                                              {data?.product?.name}{" "}
                                            </H6>
                                          </Link>
                                          <div className="hover-block text-center">
                                            <UL
                                              attrUL={{
                                                className: "simple-list flex-row",
                                              }}
                                            >
                                              <LI>
                                                <span
                                                  style={CSS({
                                                    cursor: "pointer",
                                                  })}
                                                  onClick={async () => await removeBk(data.token)}
                                                >
                                                  <Trash2 className="text-danger" />
                                                </span>
                                              </LI>
                                            </UL>
                                          </div>
                                          <div className="content-general">
                                            <P
                                              attrPara={{
                                                className: "desc_3",
                                              }}
                                            >
                                              {data.desc}
                                            </P>
                                            <span className="collection_3">{data.colection}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                </Col>
                              );
                            })
                          ) : (
                            <Col xl="12">
                              <div className="no-favourite">
                                {" "}
                                <span>{trans("website.no_favorites_found")}</span>
                              </div>{" "}
                            </Col>
                          )}
                        </Row>
                      </Fragment>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
        <EBSSSPagination page={page} data={{ ...bookmarks, data: bookmarks?.data?.map((b) => b.product) }} />
      </Fragment>
    </Page>
  );
};
export default BookmarksPage;
