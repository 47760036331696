export const renderSpinner = (color = "light") => {
  let spin = document.createElement("div");
  spin.id = "spin-add-product";
  spin.innerHTML = `
    <div class="spinner-border spinner-border-sm spinner-grow-sm text-light text-sm fs-6" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
  `;

  return { spin, removeSpinner: () => spin.remove() };
};
