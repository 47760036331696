import { Fragment, useEffect, useRef, useState } from "react";
import { Mail, Phone, User } from "react-feather";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { getCountryCode } from "src/_utils/validations";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import FeedbackMessages from "src/base/FeedbackMessage";
import { cleanErrorsAndMessages, fetchSignup, Session } from "src/base/store/authSlice";
import { ReduxDataAuthKey, ReduxDataKey } from "src/base/store/store";
import { EmailAddress, Login } from "src/Constant";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import HelperHTTP from "src/_utils/http_module_handler";
import { remotePath } from "src/base/settings/Paths";
import { Image } from "src/AbstractElements";
import { Layout } from "src/base/store/layoutSlice";

const RegisterPage = () => {
  const { trans } = useEBSSSLayout();
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
  });
  const [emailConfirmation, setEmailConfirmation] = useState("");
  const dispatcher = useDispatch<any>();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const [availableEmail, setAvailableEmail] = useState(null);
  const [loadingEmailCheck, setLoadingEmailCheck] = useState(false);
  const debounceTimeoutRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatcher(fetchSignup({ ...user, activeLanguage: "pt" }));
  };

  useEffect(() => {
    dispatcher(cleanErrorsAndMessages());
  }, []);

  const handleEmailCheck = async (emailToCheck) => {
    setLoadingEmailCheck(true);
    const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CHECK_EMAIL)
      .api()
      .post({
        data: { email: emailToCheck },
      });

    console.log(fetch);
    if (fetch?.data || fetch?.errors) {
      setLoadingEmailCheck(false);
      setAvailableEmail(fetch?.data ? fetch?.data?.available : fetch?.errors.available);
    }
  };
  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  const handleChangeBouncer = (value: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(async () => {
      await handleEmailCheck(user.email);
    }, 2500);
  };

  useEffect(() => {
    if (user.email && user.email.includes("@") && user.email.includes(".")) {
      handleChangeBouncer(user.email);
    }
  }, [user.email]);

  useEffect(() => {
    let dettachCMSTemplate = Array.from(document.querySelectorAll('[cms="true"], [href*="cms=true"], [src*="cms=true"]'));
    console.log("dettachCMSTemplate: ", dettachCMSTemplate);
    dettachCMSTemplate.forEach((el) => el.remove());
    return () => {
      let dettachCMSTemplate = Array.from(document.querySelectorAll('[cms="true"], [href*="cms=true"], [src*="cms=true"]'));
      console.log("dettachCMSTemplate: ", dettachCMSTemplate);
      dettachCMSTemplate.forEach((el) => el.remove());
    };
  }, []);

  useEffect(() => {
    if (user.email) {
      localStorage.setItem(remotePath.localStorageKey() + "_TEMP_EMAIL_REGISTERING", user.email);
    }
  }, [user.email]);

  return sessionNow?.user?.id ? (
    <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
  ) : sessionNow?.redirect?.signupToken ? (
    <Navigate to={`${process.env.PUBLIC_URL}/auth/sign-up/token`} />
  ) : (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col
            xl="7 order-1"
            style={{
              backgroundImage: `url("https://micaela.3.ebsss.dev/platform-images/settings/customer_area_background/7679a69f-f07c-4c7c-9af4-f43d771cee18.jpg?p=slider&s=09962273dea6f982797368e0cd6b4f6b")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "block",
            }}
          ></Col>
          <Col xl="5 p-0">
            <div className="login-card1">
              <Form className="theme-form login-form">
                <div className="d-flex flex-row w-100 m-auto mb-4">
                  {ebsssLayout?.commonAppSettings?.customer_area_logo ? (
                    <Image
                      attrImage={{
                        style: CSS({ cursor: "pointer" }),
                        onClick: () => navigate(process.env.PUBLIC_URL),
                        className: "align-self-center m-auto",
                        src: remotePath.assetsUrl(ebsssLayout?.commonAppSettings?.customer_area_logo),
                        alt: "logo",
                        width: "125px",
                        height: "100%",
                      }}
                    />
                  ) : (
                    <h1>{ebsssLayout?.commonAppSettings?.instance_title}</h1>
                  )}
                </div>
                <div className="login-header text-center">
                  <h4>{trans("auth.sign-up-title")}</h4>
                  <h6>{trans("auth.sign-up-description")}</h6>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label>{trans("auth.first_name")}</Label>
                      <InputGroup>
                        <InputGroupText>
                          <User />
                        </InputGroupText>
                        <Input type="text" required defaultValue={user.first_name} onChange={(event) => setUser({ ...user, first_name: event.target.value })} />
                      </InputGroup>
                      <FeedbackMessages messages={sessionNow?.errors?.signup["first_name"]} />
                    </FormGroup>
                  </div>
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label>{trans("auth.last_name")}</Label>
                      <InputGroup>
                        <InputGroupText>
                          <User />
                        </InputGroupText>
                        <Input type="text" required defaultValue={user.last_name} onChange={(event) => setUser({ ...user, last_name: event.target.value })} />
                      </InputGroup>
                      <FeedbackMessages messages={sessionNow?.errors?.signup["last_name"]} />
                    </FormGroup>
                  </div>
                </div>

                <FormGroup>
                  <Label>{trans("auth.mobile")}</Label>
                  <InputGroup>
                    <InputGroupText>
                      <Phone />
                    </InputGroupText>
                    <PhoneInput
                      inputStyle={CSS({ width: 306, display: "flex" })}
                      countrySelectorStyleProps={{
                        dropdownStyleProps: {
                          style: CSS({
                            zIndex: 999999,
                          }),
                        },
                        buttonStyle: CSS({
                          backgroundColor: "#EFEFFD",
                          border: 0,
                        }),
                      }}
                      required
                      defaultCountry={getCountryCode()}
                      value={user.mobile_number}
                      onChange={(phone) =>
                        setUser({
                          ...user,
                          mobile_number: phone.replace("+", ""),
                        })
                      }
                    />
                  </InputGroup>
                  <FeedbackMessages messages={sessionNow?.errors?.signup["mobile_number"]} />
                </FormGroup>

                <FormGroup>
                  <Label>{trans("auth.email")}</Label>
                  <InputGroup>
                    <InputGroupText>
                      <Mail />
                    </InputGroupText>
                    <Input
                      className={availableEmail === false ? "is-invalid" : availableEmail === true ? "is-valid" : ""}
                      type="email"
                      required
                      defaultValue={user.email}
                      onChange={(event) => setUser({ ...user, email: event.target.value })}
                    />
                  </InputGroup>
                  <FeedbackMessages
                    messages={sessionNow?.errors?.signup["email"] || (availableEmail === false ? [trans("website.already-registered-email")] : [])}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{trans("auth.email-confirmation-repeat")}</Label>
                  <InputGroup>
                    <InputGroupText>
                      <Mail />
                    </InputGroupText>
                    <Input
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      className={
                        emailConfirmation.length > 5 && emailConfirmation.includes("@") && emailConfirmation.includes(".")
                          ? emailConfirmation !== user.email
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }
                      type="email"
                      required
                      defaultValue={emailConfirmation}
                      onChange={(event) => setEmailConfirmation(event.target.value)}
                    />
                  </InputGroup>
                  <FeedbackMessages
                    messages={
                      emailConfirmation.length > 5 && emailConfirmation.includes("@") && emailConfirmation.includes(".")
                        ? emailConfirmation !== user.email
                          ? [trans("website.email-are-not-equal")]
                          : []
                        : ""
                    }
                  />
                </FormGroup>
                <FormGroup>
                  {loadingEmailCheck ? (
                    <div className="text-end">{trans("website.wait-validating-email")}</div>
                  ) : availableEmail && emailConfirmation === user.email ? (
                    <EBSSSButton loadingKey={ReduxDataAuthKey.signup} onClick={handleSubmit}>
                      {trans("auth.sign_up")}
                    </EBSSSButton>
                  ) : (
                    <EBSSSButton loadingKey={ReduxDataAuthKey.signup} onClick={() => {}} color="light">
                      {trans("auth.sign_up")}
                    </EBSSSButton>
                  )}
                </FormGroup>
                <div className="login-social-title">
                  <h5>{trans("auth.already_registered_sign_in")}</h5>
                </div>
                <p>
                  {trans("auth.sign-up-page")}
                  <Link to={`${process.env.PUBLIC_URL}/auth/sign-in`} className="ms-1 me-1">
                    {Login}
                  </Link>
                </p>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RegisterPage;
