import "https://maps.googleapis.com/maps/api/js?key=AIzaSyBQX-7AYoDEYYCGSwIvzXtFV8qf8tBYmKY&libraries=places&loading=async";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import { Fragment } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import {
  default as CustomizerProvider,
  default as CustomProvider,
} from "./_helper/customizer/CustomizerProvider";
import "./App.css";
import { EBSSSProvider } from "./base/context/EBSSSContext";
import { TranslateProvider } from "./base/context/EBSSSLayoutContext";
import store, { persistor } from "./base/store/store";
import Loader from "./Layout/Loader";
import Routers from "./Routes";

function App() {
  return (
    <Fragment>
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <TranslateProvider>
            <EBSSSProvider>
              <CustomizerProvider>
                <CustomProvider>
                  <AnimationThemeProvider>
                    <Routers />
                  </AnimationThemeProvider>
                </CustomProvider>
              </CustomizerProvider>
            </EBSSSProvider>
          </TranslateProvider>
        </PersistGate>
      </Provider>
    </Fragment>
  );
}
export default App;
