import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { remotePath } from "src/base/settings/Paths";

const PageComponent = ({ page, pageItems, pageDocuments, trans }) => {
  return (
    <div className="content-wrapper text-muted">
      <div className="content-header row">
        <div className="content-header-left col-md-6 col-12 mb-2">
          <h3
            className="content-header-title mb-0"
            dangerouslySetInnerHTML={{ __html: page?.title }}
          />
        </div>
      </div>
      <div className="content-body text-muted">
        <section className="basic-inputs">
          <div className="row match-height">
            <div
              className={`col-md-${
                pageItems?.length > 0 || pageDocuments?.length > 0 ? "9" : "12"
              }`}
            >
              <div className="card">
                <div className="card-block">
                  <div className="card-body" style={{ overflow: "auto" }}>
                    <div dangerouslySetInnerHTML={{ __html: page?.content }} />
                  </div>
                </div>
              </div>
            </div>
            {(pageItems?.length > 0 || pageDocuments?.length > 0) && (
              <div className="col-md-3">
                {pageItems?.length > 0 && (
                  <div className="card text-muted">
                    <div className="card-header">
                      <h4 className="card-title">
                        {trans("website.page-items")}
                      </h4>
                    </div>
                    <div className="card-block">
                      <div className="card-body">
                        <ul>
                          {pageItems?.map((item, index) => (
                            <li key={index}>
                              <h4>{item.title}</h4>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {pageDocuments?.length > 0 && (
                  <div className="card text-muted">
                    <div className="card-header">
                      <h4 className="card-title">
                        {trans("website.page-documents")}
                      </h4>
                    </div>
                    <div className="card-block">
                      <div className="card-body">
                        <ul>
                          {pageDocuments?.map((document, index) => (
                            <li key={index}>
                              <h4>{document.title}</h4>
                              <p>{document.description}</p>
                              <a
                                className="waves-effect waves-light btn"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${remotePath.assetsUrl(
                                  document.document
                                )}`}
                              >
                                <i className="fa fa-download left"></i>
                                {trans("website.page-document-download")}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

const CustomPage = () => {
  const { fetchCustomPage, customPage } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const getCustomPage = useParams();

  useEffect(() => {
    fetchCustomPage(getCustomPage?.slug);
  }, []);

  return (
    <Page
      breads={[
        {
          name: customPage?.page ? customPage?.page?.title : "",
          active: true,
          url: "/page/" + getCustomPage?.slug,
        },
      ]}
    >
      <Card>
        <CardBody>
          {customPage ? (
            <PageComponent
              page={customPage?.page}
              pageDocuments={customPage?.pageDocuments}
              pageItems={customPage?.pageItems}
              trans={trans}
            />
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
    </Page>
  );
};

export default CustomPage;
