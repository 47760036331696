import { useState } from "react";
import EBSSSTable from "src/base/atoms/EBSSSTable";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import TablePage from "src/base/TablePage";

const ReservationsPage = () => {
  const { trans } = useEBSSSLayout();
  const [page, setPage] = useState(1);
  return (
    <TablePage
      title=""
      description=""
      breads={[{ name: trans("website.reservations"), active: false }]}
    >
      <EBSSSTable pageHandler={setPage} data={[]} cols={[]} />
    </TablePage>
  );
};

export default ReservationsPage;
