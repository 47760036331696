import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { reverseDate } from "src/_utils/elements";
import { CSS, parseHTMLToReact } from "src/_utils/styles";
import EBSSSModal from "src/base/atoms/EBSSSModal";
import EBSSSTicket from "src/base/atoms/EBSSSTicket";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { remotePath } from "src/base/settings/Paths";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";

const ContractTerm = ({ contract, trans }) => {
  const version = contract.term.version;
  const createdAtTerms = contract.term.created_at.split(" ");
  const createdAtContract = contract.created_at.split(" ");
  const content = contract.term.content;
  const [modalTerms, setModalTerms] = useState(false);
  const toggleTerms = () => {
    setModalTerms(!modalTerms);
  };

  console.log("CONTRACT: ", contract);

  return (
    <EBSSSModal
      modalSize="modal-lg"
      hideSubmitButton
      modalTitle={trans("website.contract-terms")}
      modal={modalTerms}
      toggle={toggleTerms}
      triggerEl={
        <div style={CSS({ fontSize: 14, cursor: "pointer" })} className="d-flex">
          <a
            onClick={(e) => {
              e.preventDefault();
              toggleTerms();
            }}
            className="link-primary text-decoration-none"
          >
            <i className="fa fa-file"></i> {trans("website.see_contract")}
          </a>
        </div>
      }
    >
      <div>
        {/* Term Version */}
        <h2>{version}</h2>
        {/* Date and Time */}
        <small>
          {reverseDate(createdAtTerms[0])} {createdAtTerms[1]}
        </small>
        <hr />
        <small>
          {trans("profile.when")}: {reverseDate(createdAtContract[0])} {createdAtContract[1]}
        </small>
        <hr />
        {/* Term Content */}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </EBSSSModal>
  );
};

const ContractDetails = ({ contract, paymentRequests, terms, trans }) => {
  const objTrans = {
    title: "billing.contract-details",
    vigent: "billing.contract-vigent",
    remainingDays: "billing.contract-remaining-days",
    productValue: "billing.contract-product-value",
    extraValue: "billing.contract-extra-value",
    taxValue: "billing.contract-tax-value",
    feeValue: "billing.contract-fee-value",
    contractValue: "billing.contract-value",
    nextCharge: "billing.contract-next-charge",
    frequency: "billing.contract-frequency",
    product: "billing.contract-product",
    orderItemName: "billing.order-item-name",
    orderInfo: "billing.order-info",
    orderTotal: "billing.order-total",
    orderItemOption: "billing.order-item-option",
    orderItemAmount: "billing.order-item-amount",
    orderItemInstallationAmount: "billing.order-item-installation-amount",
    orderItemFeeAmount: "billing.order-item-fee-amount",
    orderItemTaxAmount: "billing.order-item-tax-amount",
    reservationPayments: "entity.reservation-payments",
    openPaymentRequest: "entity.reservation-open-payment-request",
    paymentRequestStatusExpired: "billing.payment-request-status-expired",
  };

  const statusBadgeClass = `badge ${contract.status} bg-${contract.status_color}`;
  const percentCompleted = contract.days_totals > 0 ? 100 - Math.min(Math.max(Math.floor((100 * contract.days_remaining) / contract.days_totals), 0), 100) : 0;

  return (
    <div>
      {/* Contract Details Card */}
      <div className="card text-muted">
        <div className="row">
          <div className="card-head col-lg-12">
            <div className="card-header card-head-colour d-flex justify-content-between">
              <h4 className="card-title">{trans(objTrans.title)}</h4>
              <div className="heading-elements">
                <span className={statusBadgeClass}>
                  {trans(objTrans.title)} - {contract.status}
                </span>
                <span className="badge badge-info">
                  <i className="fa fa-edit" style={{ fontSize: "10px" }}></i>#{contract.id}
                </span>
              </div>
            </div>
            <div className="card-body">
              <span>
                <strong>{trans("billing.contract-information")}:</strong>
                {contract.information ? contract.information : trans("website.no-data")}
              </span>
              <br />
              <span className="progress-text">
                <strong>{trans(objTrans.vigent)}:</strong>
                <br />
                {contract.start_at} - {contract.end_at}
              </span>
              {terms}
              <br />
              <br />
              {contract.status === "active" && (
                <>
                  <span className="progress-number">
                    <b>
                      {contract.days_remaining} {trans(objTrans.remainingDays)}
                    </b>
                    {contract.days_totals - contract.days_remaining + 1} / {contract.days_totals}
                  </span>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated bg-info"
                      role="progressbar"
                      aria-valuenow={percentCompleted}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: `${percentCompleted}%` }}
                    ></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* Contract Values */}
        <div className="d-flex gap-3 mb-3 justify-content-around text-muted">
          <div className="">
            {contract.product_value > 0 && (
              <ul className="align-content-end d-flex">
                <li>
                  <i className="fa fa-money"></i> {trans(objTrans.productValue)}
                  : <br />
                  <span className="text-muted">
                    {contract.product_value} {contract?.currency?.symbol}
                  </span>
                </li>
              </ul>
            )}
            {contract.extra_value > 0 && (
              <ul className="align-content-end d-flex">
                <li>
                  <i className="fa fa-flag"></i> {trans(objTrans.extraValue)}: <br />
                  <span className="text-muted">
                    {contract.extra_value} {contract?.currency?.symbol}
                  </span>
                </li>
              </ul>
            )}
            {contract.tax_value > 0 && (
              <ul className="align-content-end d-flex">
                <li>
                  <i className="fa fa-flag-checkered"></i> {trans(objTrans.taxValue)}: <br />
                  <span className="text-muted">
                    {contract.tax_value} {contract?.currency?.symbol}
                  </span>
                </li>
              </ul>
            )}
            {contract.fee_value > 0 && (
              <ul className="align-content-end d-flex">
                <li>
                  <i className="fa fa-flag-o"></i> {trans(objTrans.feeValue)}: <br />
                  <span className="text-muted">
                    {contract.fee_value} {contract?.currency?.symbol}
                  </span>
                </li>
              </ul>
            )}
            <ul className="align-content-end d-flex">
              <li>
                <i className="las la-money-bill-wave-alt"></i> {trans(objTrans.contractValue)}: <br />
                <span className="text-muted">
                  {contract.value} {contract?.currency?.symbol}
                </span>
              </li>
            </ul>
          </div>
          <div className="">
            <ul className="align-content-end d-flex">
              <li>
                <i className="lar la-calendar"></i> {trans(objTrans.nextCharge)}
                : <br />
                {new Date(contract.get_next_charge_date).toLocaleDateString()}
              </li>
            </ul>
            <ul className="align-content-end d-flex">
              <li>
                <i className="las la-flag-checkered"></i> {trans(objTrans.frequency)}: <br />
                {trans("billing.contract-frequency-" + contract.frequency)}
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Contract Product Card */}
      <div className="card text-muted">
        <div className="card-header card-head-colour d-flex justify-content-between">
          <h4 className="card-title">{trans(objTrans.product)}</h4>
        </div>
        <div className="col-md-12 col-sm-12">
          <div className="card-content">
            <div className="card-body row">
              {contract.product.photo && (
                <div className="col-lg-3">
                  <img src={remotePath.assetsUrl(contract.product.photo)} alt="Product" style={{ width: "100%" }} className="rounded-3" />
                </div>
              )}
              <div className="col-lg-9">
                <h4 className="card-title">{contract.product && `${trans(objTrans.orderItemName)}: ${contract.product.name}`}</h4>
                <p className="card-text d-flex flex-row align-items-center">
                  {contract.order_item.order && (
                    <>
                      <strong>{trans(objTrans.orderInfo)}:</strong> <span className="ms-1">#{contract.order_item.order.id}</span>{" "}
                      <Link target="_blank" rel="noopener noreferrer" to={process.env.PUBLIC_URL + `/crm/orders/${contract.order_item.order.token}`}>
                        &nbsp;
                        <i
                          className="la la-suitcase"
                          aria-hidden="true"
                          style={{ fontSize: "24px" }} // Estilo inline para mais espaço e tamanho do ícone
                        ></i>
                      </Link>
                    </>
                  )}
                </p>
                <p className="card-text">
                  {contract.order_item.order && (
                    <>
                      <strong>{trans(objTrans.orderTotal)}: </strong>
                      <span className="ms-1">
                        {contract.order_item.order.total} {contract?.currency?.symbol}
                      </span>
                    </>
                  )}
                </p>
                <p className="card-text">
                  {contract.orderItem && (
                    <>
                      <strong>{trans(objTrans.orderItemOption)}: </strong>
                      <br />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: contract.order_item.item_option.replace(/\n/g, "<br/>"),
                        }}
                      />
                      <br />
                      <strong>{trans(objTrans.orderItemAmount)}: </strong>
                      {contract.order_item.amount}
                      <br />
                      {contract.order_item.installation_fee && (
                        <>
                          {trans(objTrans.orderItemInstallationAmount)}: {contract.order_item.installation_fee}
                          <br />
                        </>
                      )}
                      {contract.order_item.fee_amount && (
                        <>
                          <strong>{trans(objTrans.orderItemFeeAmount)}: </strong>
                          {contract.order_item.fee_amount}
                          <br />
                        </>
                      )}
                      {contract.order_item.tax_amount && (
                        <>
                          <strong>{trans(objTrans.orderItemTaxAmount)}: </strong>
                          {contract.order_item.tax_amount}
                          <br />
                        </>
                      )}
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Payment Requests */}
      {paymentRequests?.length > 0 && (
        <div className="card text-muted">
          <div className="card-header card-head-colour d-flex justify-content-between">
            <h4 className="card-title">{trans(objTrans.reservationPayments)}</h4>
          </div>
          <div className="card-content collapse show">
            <div className="card-body">
              <div className="row">
                {paymentRequests.map((paymentRequest) => (
                  <div
                    key={paymentRequest.id}
                    className="d-flex justify-content-between align-items-center shadow-sm border-2 rounded-2 mt-1 col-lg-12"
                    style={{ padding: "10px" }}
                  >
                    {paymentRequest.paymentMomment && (
                      <>
                        <strong>{paymentRequest.paymentMomment.name}</strong>
                        <hr />
                      </>
                    )}
                    <span>#{paymentRequest.id}</span>
                    <b className="float-right">
                      {paymentRequest.amount} {paymentRequest?.currency?.symbol}
                    </b>
                    <br />
                    {new Date(paymentRequest.expected_date) < new Date() && paymentRequest.status === "open" ? (
                      <span className="badge badge-default badge-danger">{trans(objTrans.paymentRequestStatusExpired)}</span>
                    ) : (
                      <span className={`badge badge-sm badge-${paymentRequest.status_color_bootstrap} badge-lg`}>
                        {trans(objTrans.paymentRequestStatusExpired)} - {paymentRequest.status}
                      </span>
                    )}
                    <br />
                    <br />
                    <div className="text-right">
                      <Link className="btn btn-primary btn-sm" to={process.env.PUBLIC_URL + `/crm/contract-payment/${paymentRequest.token}`}>
                        <i className="fa fa-eye"></i> {trans(objTrans.openPaymentRequest)}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ContractPage = () => {
  const { contract, appSettings, fetchContract } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const getOrder = useParams();
  const location = useLocation();
  const [IsOpen, setIsOpen] = useState(false);
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);

  const contractProductName = contract?.product?.name;
  const contractTerm = contract?.term;
  const contractReservation = contract?.reservation;
  const userAuthenticated = sessionNow?.user;
  const cancellationRecord = {} as any;
  const cancellationRuleName = cancellationRecord?.rule?.name;
  const cancellationPercentage = cancellationRecord?.percentage;
  const cancellationFee = cancellationRecord?.fee;
  const cancellationCurrencySymbol = cancellationRecord?.currency?.symbol;
  const cancellationDaysBefore = cancellationRecord?.days_before;
  const cancellationHoursBefore = cancellationRecord?.hours_before;
  const rebookPercentage = cancellationRecord?.percentage_rebook;
  const rebookFee = cancellationRecord?.fee_rebook;
  const reservationToken = contractReservation?.token;
  const userMismatch = !userAuthenticated || (contractReservation?.user && userAuthenticated?.id !== contractReservation?.user?.id);

  useEffect(() => {
    fetchContract(getOrder?.token);
  }, []);

  return (
    <Page
      breads={[
        {
          name: trans("website.contracts"),
          active: false,
          url: "/crm/contracts",
        },
        {
          name: trans("website.my-contract") + " #" + (contract?.id ? contract?.id : ""),
          active: true,
        },
      ]}
    >
      {!!contract ? (
        <Card>
          <CardBody>
            <div className="content-body text-muted row text-muted">
              <div className="col-lg-6 pl-0 pr-0">
                <div className="col-lg-12 pl-1 pr-1 mb-3">
                  <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                      <h3 className="content-header-title mb-0 d-inline-block">{contractProductName}</h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="col-lg-12">
                    <ContractDetails
                      terms={
                        contractTerm ? (
                          <div className="col-lg-12">
                            <ContractTerm contract={contract} trans={trans} />
                          </div>
                        ) : (
                          <></>
                        )
                      }
                      contract={contract}
                      paymentRequests={contract?.all_payment_requests}
                      trans={trans}
                    />
                  </div>
                </div>

                {userAuthenticated && <div className="col-lg-6">{/* Include the contract chat tab component */}</div>}

                {cancellationRecord && (
                  <>
                    <div
                      className="modal fade text-left"
                      id="modal-cancellation"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="modalCancellation"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <form action={`/entity-reservation/cancel`} method="POST" id="form-profile-avatar" encType="multipart/form-data">
                            <input type="hidden" name="reservation" value={reservationToken} />

                            <div className="modal-header bg-danger white">
                              <h4 className="modal-title white" id="modalCancellation">
                                {trans("website.request-cancellation")}
                              </h4>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>

                            <div className="modal-body">
                              <p>
                                {trans("website.cancellation-rule")}: {cancellationRuleName}
                              </p>

                              {cancellationPercentage > 0 && (
                                <p>
                                  {trans("entity.cancellation-percentage-to-pay")}: <b>{cancellationPercentage}%</b>
                                </p>
                              )}

                              {cancellationFee && (
                                <p>
                                  {trans("website.cancellation-fee-to-pay")}:{" "}
                                  <b>
                                    {cancellationFee} {cancellationCurrencySymbol}
                                  </b>
                                </p>
                              )}

                              {cancellationDaysBefore > 0 && (
                                <p>
                                  {trans("entity.cancellation-days-before-to-proceed")}:{" "}
                                  <b>
                                    {cancellationDaysBefore} {trans("common.days")}
                                  </b>
                                </p>
                              )}

                              {cancellationHoursBefore > 0 && (
                                <p>
                                  {trans("entity.cancellation-hours-before-to-proceed")}:{" "}
                                  <b>
                                    {cancellationHoursBefore} {trans("common.hours")}
                                  </b>
                                </p>
                              )}

                              <hr />

                              <p>{trans("entity.confirm-cancellation-reservation-and-add-notes")}</p>

                              <textarea name="notes" className="form-control" placeholder={trans("entity.request-cancellation-note")} rows={5}></textarea>

                              {userMismatch && (
                                <>
                                  <hr />
                                  <p>{trans("website.fill-cancellation-token")}</p>
                                  <input type="text" name="action_token" className="form-control" placeholder={trans("entity.cancellation-token")} />
                                </>
                              )}
                            </div>

                            <div className="modal-footer">
                              <button type="button" className="btn grey btn-outline-secondary" data-dismiss="modal">
                                {trans("common.cancel")}
                              </button>
                              <button type="submit" className="btn btn-outline-primary">
                                {trans("common.confirm")}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="modal fade text-left" id="modal-rebook" tabIndex={-1} role="dialog" aria-labelledby="modalRebook" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <form action={`/entity-reservation/rebook`} method="POST" id="form-profile-avatar" encType="multipart/form-data">
                            <input type="hidden" name="reservation" value={reservationToken} />

                            <div className="modal-header bg-warning white">
                              <h4 className="modal-title white" id="modalRebook">
                                {trans("website.request-rebook")}
                              </h4>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>

                            <div className="modal-body">
                              <p>
                                {trans("website.cancellation-rule")}: {cancellationRuleName}
                              </p>

                              {rebookPercentage > 0 && (
                                <p>
                                  {trans("website.rebook-percentage-to-pay")}: <b>{rebookPercentage}%</b>
                                </p>
                              )}

                              {rebookFee && (
                                <p>
                                  {trans("website.rebook-fee-to-pay")}:{" "}
                                  <b>
                                    {rebookFee} {cancellationCurrencySymbol}
                                  </b>
                                </p>
                              )}

                              {cancellationDaysBefore > 0 && (
                                <p>
                                  {trans("entity.cancellation-days-before-to-proceed")}: <b>{cancellationDaysBefore}</b>
                                </p>
                              )}

                              {cancellationHoursBefore > 0 && (
                                <p>
                                  {trans("entity.cancellation-hours-before-to-proceed")}: <b>{cancellationHoursBefore}</b>
                                </p>
                              )}

                              <hr />

                              <p>{trans("entity.confirm-rebook-reservation-and-add-notes")}</p>

                              <textarea name="notes" className="form-control" placeholder={trans("entity.request-rebook-note")} rows={5}></textarea>

                              {userMismatch && (
                                <>
                                  <hr />
                                  <p>{trans("website.fill-rebook-token")}</p>
                                  <input type="text" name="action_token" className="form-control" placeholder={trans("website.rebook-token")} />
                                </>
                              )}
                            </div>

                            <div className="modal-footer">
                              <button type="button" className="btn grey btn-outline-secondary" data-dismiss="modal">
                                {trans("common.cancel")}
                              </button>
                              <button type="submit" className="btn btn-outline-primary">
                                {trans("common.confirm")}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <EBSSSTicket data={contract} dataType={"contract"} callbackRefresh={() => fetchContract(getOrder?.token)} />
            </div>
          </CardBody>
        </Card>
      ) : (
        <></>
      )}
    </Page>
  );
};
export default ContractPage;
