import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ReduxDataKey } from "src/base/store/store";
import Layout from "../Layout/Layout";
import { authRoutes } from "./AuthRoutes";
import { Session } from "src/base/store/authSlice";

const MainRouter = () => {
  return (
    <Fragment>
      <Routes>
        {authRoutes.map(({ path, Component }, i) => (
          <Route element={<Layout hideLayout={false} children={undefined} classNames={undefined} />} key={i}>
            <Route path={path} element={Component} />
          </Route>
        ))}
      </Routes>
    </Fragment>
  );
};

export default MainRouter;
