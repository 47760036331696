import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";

const RewardWalletPage = () => {
  const { fetchRewardWallet, rewardWallet, appSettings } = useEBSSS();
  const { trans } = useEBSSSLayout();

  useEffect(() => {
    fetchRewardWallet();
  }, []);

  return (
    <Page
      breads={[
        {
          name: trans("website.rwd_historic-wallets"),
          active: true,
          url: "/crm/rwd-wallet-historic",
        },
      ]}
    >
      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-body text-muted">
            <section className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-content">
                    <div className="card">
                      <div className="card-content">
                        <div className="row justify-center d-flex flex-row justify-content-between pb-5">
                          <div className="col border-right-blue-grey border-right-lighten-4 text-center">
                            <div className="card-body text-center d-flex align-items-center justify-content-evenly">
                              <i className="fa fa-chevron-up fs-2 text-muted"></i>
                              <h1 className="display-4 text-muted">
                                {rewardWallet?.current_balance_user ?? 0}
                              </h1>
                            </div>
                            <span className="text-muted">
                              {trans("common.balance")}
                            </span>
                          </div>
                          <div className="col border-right-blue-grey border-right-lighten-4 text-center">
                            <div className="card-body text-center d-flex align-items-center justify-content-evenly">
                              <i className="fa fa-chevron-up fs-2 text-success"></i>
                              <h1 className="display-4 text-muted">
                                {rewardWallet?.in_points}
                              </h1>
                            </div>
                            <span className="text-muted">
                              {trans("website.in_wallet")}
                            </span>
                          </div>
                          <div className="col border-right-blue-grey border-right-lighten-4 text-center">
                            <div className="card-body text-center d-flex align-items-center justify-content-evenly">
                              <i className="fa fa-chevron-up fs-2 text-danger"></i>
                              <h1 className="display-4 text-muted">
                                {rewardWallet?.out_points}
                              </h1>
                            </div>
                            <span className="text-muted">
                              {trans("website.out_wallet")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      {rewardWallet?.data && rewardWallet?.data.length > 0 ? (
                        <div className="table-responsive">
                          <table
                            id="invoices-list"
                            className="table table-white-space table-responsive table-bordered row-grouping display no-wrap icheck table-middle"
                            style={{ display: "inline-table" }}
                          >
                            <thead>
                              <tr>
                                <th>{trans("common.id")}</th>
                                <th>{trans("common.type")}</th>
                                <th>{trans("common.date")}</th>
                                <th>{trans("common.value")}</th>
                                <th>{trans("common.balance")}</th>
                                <th>{trans("common.provenance")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {rewardWallet?.data.map((invoice) => {
                                const colorType =
                                  invoice.type_operation === "Entrada"
                                    ? "green"
                                    : invoice.type_operation === "Saída"
                                    ? "red"
                                    : "orange";

                                return (
                                  <tr key={invoice.id}>
                                    <td>
                                      <span className="badge bg-gray">
                                        {invoice.id}
                                      </span>
                                    </td>
                                    <td>
                                      <span className={`badge bg-${colorType}`}>
                                        {invoice.type_operation}
                                      </span>
                                    </td>
                                    <td>
                                      {new Date(
                                        invoice.created_at
                                      ).toLocaleString()}
                                    </td>
                                    <td>{invoice.value}</td>
                                    <td>
                                      {invoice.balance_after_operation}{" "}
                                      {trans("website.type-points")}
                                    </td>
                                    <td>
                                      {invoice.type_operation === "Entrada" &&
                                      invoice.payment_request?.token ? (
                                        <Link
                                          to={`${process.env.PUBLIC_URL}/crm/contract-payment/${invoice.payment_request.token}`}
                                        >
                                          {invoice.payment_request.id}
                                          {trans("common._gp-in")}
                                        </Link>
                                      ) : invoice.type_operation ===
                                          "Entrada" &&
                                        !invoice.payment_request?.token ? (
                                        trans("gamify.award")
                                      ) : invoice.type_operation === "Saída" &&
                                        invoice.payment_request?.token ? (
                                        <Link
                                          to={`${process.env.PUBLIC_URL}/crm/contract-payment/${invoice.payment_request.token}`}
                                        >
                                          {invoice.payment_request.id}
                                          {trans("common._gp-out")}
                                        </Link>
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p>{trans("website.historic-wallets-empty")}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default RewardWalletPage;
