import { Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";

const ListContain = ({ children, title, description }) => {
  const { cmsLayout } = useEBSSSLayout();

  return (
    <Fragment>
      <Container fluid={cmsLayout ? false : true} className={cmsLayout ? "" : "default-dash"} id="inner-container">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{title} </H5>
                <span>{description}</span>
              </CardHeader>
              <CardBody>{children}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ListContain;
