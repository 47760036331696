import { Fragment } from "react";
import CommonModal from "src/_core/Ui-kits/Modals/common/modal";
import { Btn } from "src/AbstractElements";

const EBSSSModal = ({
  label,
  children,
  triggerEl,
  modal,
  toggle,
  modalTitle,
  customConfirmAction,
  customConfirmButtonText,
  customCloseButtonText,
  hideSubmitButton,
  hideFooter,
  modalSize,
  dismissable,
  loadingSubmitKey,
  asyncToggle,
  style,
}: {
  children: JSX.Element | string;
  modal: boolean;
  toggle: Function;
  modalTitle: string;
  label?: string;
  triggerEl?: JSX.Element;
  customConfirmAction?: Function;
  customConfirmButtonText?: string | JSX.Element;
  customCloseButtonText?: string | JSX.Element;
  hideSubmitButton?: boolean;
  hideFooter?: boolean;
  modalSize?: "modal-sm" | "modal-lg" | "modal-xl" | "modal-md" | null;
  dismissable?: boolean;
  loadingSubmitKey?: boolean;
  asyncToggle?: boolean;
  style?: Record<any, any>;
}) => {
  return (
    <Fragment>
      {label ? <Btn attrBtn={{ color: "primary", onClick: toggle }}>{label}</Btn> : triggerEl}
      <CommonModal
        isOpen={modal}
        title={modalTitle}
        toggler={toggle}
        customConfirmAction={customConfirmAction}
        customConfirmButtonText={customConfirmButtonText}
        customCloseButtonText={customCloseButtonText}
        hideSubmitButton={hideSubmitButton || false}
        hideFooter={hideFooter || false}
        modalSize={modalSize}
        dismissable={typeof dismissable === "boolean" ? dismissable : true}
        loadingSubmitKey={loadingSubmitKey}
        asyncToggle={asyncToggle}
        style={style}
      >
        {children}
      </CommonModal>
    </Fragment>
  );
};

export default EBSSSModal;
