import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Media } from "reactstrap";
import { scrollUpPage } from "src/_utils/elements";
import { CSS } from "src/_utils/styles";
import { H6, Image, P } from "src/AbstractElements";
import EBSSSInitialNameProfile from "src/base/atoms/EBSSSInitialNameProfile";
import EBSSSTicket from "src/base/atoms/EBSSSTicket";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { remotePath } from "src/base/settings/Paths";

const TicketPublicAnswer = () => {
  const { contact, loaded, fetchContactMade } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const getContactCenterMessage = useParams();
  const [typeFrom, setTypeFrom] = useState("");

  console.log("contact: ", contact);

  useEffect(() => {
    if (contact) {
      setTypeFrom(
        getContactCenterMessage?.type === "ticket"
          ? contact?.order_id && !contact?.reservation_id
            ? "order"
            : contact?.reservation_id
            ? "reservation"
            : contact?.contract_id
            ? "contract"
            : contact?.lead_id
            ? "lead"
            : "project"
          : "chat"
      );
    }
  }, [contact]);

  useEffect(() => {
    scrollUpPage();
    fetchContactMade(getContactCenterMessage?.type as "chat" | "ticket", getContactCenterMessage?.token, getContactCenterMessage?.user);
  }, []);

  return (
    <div className="pt-2">
      {contact ? (
        <div className="container mt-5">
          <div className="card shadow-sm">
            <div className="card-header pb-0 pl-5 pr-5 pt-5">
              <div className="email-top">
                <Media>
                  {contact?.user?.profile?.avatar ? (
                    <Image
                      attrImage={{
                        width: "50px",
                        height: "100%",
                        className: "me-3 rounded-circle",
                        src: `${
                          contact?.user?.profile?.avatar
                            ? remotePath.assetsUrl(contact?.user?.profile?.avatar)
                            : "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"
                        }`,
                        alt: "",
                      }}
                    />
                  ) : (
                    <div className="me-3">
                      <EBSSSInitialNameProfile user={contact?.user} width={50} height={50} />
                    </div>
                  )}
                  <Media body>
                    <H6
                      attrH6={{
                        className: "d-block",
                        style: CSS({ color: "#6362E8", fontWeight: 600 }),
                      }}
                    >
                      {contact?.user?.profile?.first_name + " " + contact?.user?.profile?.last_name}
                    </H6>
                    <P attrPara={{ className: "text-muted" }}>{contact?.created_at}</P>
                  </Media>
                </Media>
                <hr />
              </div>
            </div>
            <div className="card-body">
              <p className="card-text">{contact?.message}</p>
            </div>
          </div>

          <div className="mt-4">
            {contact ? (
              <EBSSSTicket
                contactCenter={true}
                data={[]}
                dataType={typeFrom}
                replierToken={getContactCenterMessage?.user}
                dataId={getContactCenterMessage?.type === "ticket" ? contact[typeFrom + "_id"] : 0}
                secondaryDataId={typeFrom === "reservation" ? Number(getContactCenterMessage?.parent_entity) : null}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TicketPublicAnswer;
