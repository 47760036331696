export const DEFINED_STYLES = `
  <style>
      .template-product-extras-container {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        width: 100% !important;
      }

      .template-product-extra-item {
        width: 100% !important;
        max-width: 50% !important; /* For half-width on larger screens */
        margin-bottom: 1rem !important;
        box-sizing: border-box !important;
      }

      .template-product-extra-content {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        padding: 1rem !important;
        border: 1px solid #ccc !important;
        border-radius: 0.25rem !important;
        background-color: #f8f9fa !important; /* Light background */
        color: #000 !important;
        height: 100% !important;
        margin: 5px !important;
      }

      .template-product-extra-checkbox {
        width: 15px !important;
      }

      .template-product-extra-details {
        display: flex !important;
        flex-direction: column !important;
        margin-left: 0.5rem !important;
      }

      .template-product-extra-title {
        font-size: 1rem !important;
      }

      .template-product-extra-description {
        font-size: 0.875rem !important;
        color: #6c757d !important; /* Slightly lighter for secondary text */
      }

      .template-product-extra-price {
        font-size: 0.75rem !important;
        margin-left: 0.5rem !important;
        color: #6c757d !important;
      }

      input[type="radio"] {
        height: 15px !important;
      }
  </style>
`;
