import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProductRedirector = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(window.location.pathname.replace("product", "products"));
  }, []);
  return <div></div>;
};

export default ProductRedirector;
