//@ts-nocheck
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { Layout } from "src/base/store/layoutSlice";
import { ReduxDataKey } from "src/base/store/store";
import { LI, UL } from "../../AbstractElements";
import { editProfile, Session } from "src/base/store/authSlice";

const Language = () => {
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { changeLanguage } = useEBSSSLayout();
  const dispatcher = useDispatch();

  return (
    <Fragment>
      <div className={`language-dropdown onhover-show-div`}>
        <UL attrUL={{ className: `simple-list p-3` }}>
          {ebsssLayout?.languages?.map((lg) => {
            return (
              <LI attrLI={{ key: lg?.id }}>
                <a
                  data-lng={lg?.code}
                  onClick={(e) => {
                    e.preventDefault();
                    changeLanguage(lg?.code?.toLowerCase());
                    if (sessionNow?.user?.id) {
                      dispatcher(
                        editProfile({
                          id: sessionNow?.user.id,
                          form: {
                            //Address
                            language_id: lg?.id,
                            address_line_1: sessionNow?.user?.profile?.address_line_1 ?? "",
                            address_line_2: sessionNow?.user?.profile?.address_line_2 ?? "",
                            locality: sessionNow?.user?.profile?.locality ?? "",
                            postal_code: sessionNow?.user?.profile?.postal_code ?? "",
                            country_code: sessionNow?.user?.profile?.country_code ?? "pt",
                            administrative_area: sessionNow?.user?.profile?.administrative_area ?? "",
                            //Profile
                            first_name: sessionNow?.user?.profile?.first_name ?? "",
                            last_name: sessionNow?.user?.profile?.last_name ?? "",
                            mobile_number: sessionNow?.user?.mobile_number ?? "",
                            gender: sessionNow?.user?.profile?.gender ?? "male",
                            vat_number: sessionNow?.user?.profile?.vat_number ?? "",
                            date_of_birth: sessionNow?.user?.profile?.date_of_birth?.substring(0, 10) ?? "",
                          },
                        })
                      );
                    }
                  }}
                >
                  {lg?.name}
                </a>
              </LI>
            );
          })}
        </UL>
      </div>
    </Fragment>
  );
};
export default Language;
