import { Fragment, useEffect } from "react";
import { Archive } from "react-feather";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { H4, H5, Image, LI, UL } from "src/AbstractElements";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { remotePath } from "src/base/settings/Paths";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";

const FormsListing = ({ category, forms, trans, length }) => {
  return (
    <Fragment>
      <Col md={length === 1 ? "9" : "6"} xs="12" className="browse" key={category.id}>
        <div
          className="browse-articles"
          style={CSS({
            width: "97.5%",
            height: "92.5%",
          })}
        >
          <H4>
            <span>
              <Archive />
            </span>
            {category.category_name ?? trans("website.forms")}
          </H4>
          {forms.map((form) => (
            <UL attrUL={{ className: "simple-list" }}>
              <LI>
                <a>
                  <H5 attrH5={{ style: CSS({ fontSize: 15 }) }}>
                    <Link to={`${process.env.PUBLIC_URL}/forms/${form.slug}`} className="button-link" key={form.slug}>
                      <div className="d-flex align-items-baseline text-muted">
                        {form.icon && (
                          <div style={CSS({ width: 25 })}>
                            <i className={`fa ${form.icon}`} />
                          </div>
                        )}
                        <span>{form.title}</span>
                      </div>
                    </Link>
                  </H5>
                  <span className="badge badge-primary pull-right">{""}</span>
                </a>
              </LI>
            </UL>
          ))}
        </div>
      </Col>
    </Fragment>
  );
};

const FormsPage = () => {
  const { trans } = useEBSSSLayout();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { forms, tourProducts, sellers, appSettings, fetchForms } = useEBSSS();
  const navigate = useNavigate();

  useEffect(() => {
    fetchForms();
  }, []);

  const invertedFormsRelationship = forms
    ?.map((form) => {
      return {
        id: form.parent_category_id,
        category_name: form.parent_category_name,
      };
    })
    ?.reduce((acc, current) => {
      if (!acc.some((item) => item.id === current.id)) {
        acc.push({
          ...current,
          forms: forms.filter((f) => f.parent_category_id === current.id),
        });
      }
      return acc;
    }, []);

  return (
    <Page breads={[{ name: trans("website.forms"), active: false, url: "/forms" }]}>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center justify-content-end">
            <Link to={process.env.PUBLIC_URL + "/forms/replied/filter/all"} className="btn btn-outline-primary">
              {trans("website.replied-forms")}
            </Link>
          </div>
          <div className="tuna-signup-left">
            <div className="mb-3 d-flex p-3 d-none">
              {appSettings?.logo ? (
                <Image
                  attrImage={{
                    style: CSS({ cursor: "pointer" }),
                    onClick: () => navigate(process.env.PUBLIC_URL),
                    className: "m-auto tuna-signup-logo",
                    src: remotePath.assetsUrl(appSettings?.logo),
                    alt: "logo",
                    width: "150px",
                    height: "100%",
                  }}
                />
              ) : (
                <h1>{appSettings?.instance_title}</h1>
              )}
            </div>
            <br />
            {appSettings?.crm_show_formsweb_link && !appSettings?.sys_multi_sellers ? (
              /*appSettings?.crm_home_style === "grid"*/ true ? (
                <div className="d-flex">
                  <div className="row w-100">
                    <div className={`d-flex flex-row flex-wrap ${invertedFormsRelationship?.length === 1 ? "justify-content-center" : ""}`}>
                      {invertedFormsRelationship?.map((cat, index) => (
                        <FormsListing category={cat} forms={cat.forms} trans={trans} length={invertedFormsRelationship?.length} />
                      ))}
                    </div>

                    {false && appSettings?.crm_tours && tourProducts ? (
                      tourProducts?.map((product) => (
                        <Col xl="3" lg="6" sm="6" md="4" className="xl-25 box-col-3">
                          <Card className="shadow-sm o-hidden p-3">
                            <Link to={`${process.env.PUBLIC_URL}/tours/tour/${product.slug}/book-now`} title={product.name}>
                              <div className="d-flex flex-row gap-2">
                                {product?.getDefaultImage ? (
                                  <Image
                                    attrImage={{
                                      className: "rounded-2",
                                      src: remotePath.assetsUrl(product.getDefaultImage?.image),
                                      alt: "product-image",
                                      width: "75px",
                                      height: "100%",
                                    }}
                                  />
                                ) : (
                                  <></>
                                )}
                                <div className="d-flex flex-column">
                                  <span>{product.name}</span>
                                  <div className="price-from small">
                                    {trans("common.from")} {product.getDefaultPrice} €
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </Card>
                        </Col>
                      ))
                    ) : (
                      <></>
                    )}
                    {false && appSettings?.crm_tours && appSettings?.tours_feature_transfer ? (
                      <Col xl="3" lg="6" sm="6" md="4" className="xl-25 box-col-3">
                        <Card className="shadow-sm o-hidden p-3">
                          <Link
                            to={process.env.PUBLIC_URL + "/tours/transfers"}
                            style={{
                              color: appSettings?.application_color_1,
                            }}
                          >
                            <i className="me-1 fa fa-bus" />
                            {trans("tours.transfers-link")}
                          </Link>
                        </Card>
                      </Col>
                    ) : (
                      <></>
                    )}
                    {false && appSettings?.crm_customer_area_sales && appSettings?.public_products ? (
                      <Col xl="3" lg="6" sm="6" md="4" className="xl-25 box-col-3">
                        <Card className="shadow-sm o-hidden p-3">
                          <Link to={process.env.PUBLIC_URL + "/crm/products"} className="button-link">
                            <i className="me-1 fa fa-shopping-cart" />
                            {trans("website.menu-buy-products")}
                          </Link>
                        </Card>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <div className="list">
                  {forms?.map((form) => (
                    <Link to={`${process.env.PUBLIC_URL}/forms/${form.slug}`} className="button-link" key={form.slug}>
                      {form.icon && <i className={`me-1 fa ${form.icon}`} />}
                      {form.title}
                    </Link>
                  ))}
                  {false && appSettings?.crm_tours && tourProducts ? (
                    tourProducts?.map((product) => (
                      <Link
                        to={`${process.env.PUBLIC_URL}/tours/tour/${product.slug}/book-now`}
                        title={product.name}
                        className="button-link button-tour-product"
                        style={{
                          backgroundImage: product.getDefaultImage ? `url(${(product.getDefaultImage.image, { p: "products-home" })})` : "none",
                        }}
                        key={product.slug}
                      >
                        <i className="me-1 fa fa-calendar" />
                        {product.name}
                        <div className="price-from">
                          {trans("common.from")} {product.getDefaultPrice} €
                        </div>
                      </Link>
                    ))
                  ) : (
                    <></>
                  )}
                  {false && appSettings?.crm_customer_area_sales && appSettings?.public_products ? (
                    <Link to={process.env.PUBLIC_URL + "/crm/products"} className="button-link">
                      <i className="me-1 fa fa-shopping-cart" />
                      {trans("website.menu-buy-products")}
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
              )
            ) : appSettings?.crm_show_formsweb_link && appSettings?.sys_multi_sellers && sellers ? (
              <div className="grid">
                <div className="row">
                  {false &&
                    sellers?.map((seller) => (
                      <div className="col-sm-4" key={seller.username}>
                        <Link
                          to={`${process.env.PUBLIC_URL}/crm/seller/${seller.username}`}
                          title={seller.username}
                          className="button-tour-product button-link"
                          style={{
                            backgroundImage: seller.profile && seller.profile.avatar ? `url(${(seller.profile.avatar, { p: "products-home" })})` : "none",
                          }}
                        >
                          {seller.getFullName()}
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <></>
            )}

            {false && appSettings?.hidden_faq === 0 ? (
              <Link to={process.env.PUBLIC_URL + "/faq"} className="bottom-left me-2">
                <i className="me-1 fa fa-question-circle" />
                {trans("website.faq-link")}
              </Link>
            ) : (
              <></>
            )}
            {false && appSettings?.crm_customer_area ? (
              <>
                {!sessionNow.user || sessionNow?.user?.status === "guest" ? (
                  <Link to={process.env.PUBLIC_URL + "/auth/sign-in"} className="bottom-right">
                    <i className="me-1 fa fa-lock" />
                    {trans("website.sign-in-link")}
                  </Link>
                ) : (
                  <Link to={process.env.PUBLIC_URL + "/dashboard"} className="bottom-right">
                    <i className="me-1 fa fa-user" />
                    {trans("website.customer_area")}
                  </Link>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </CardBody>
      </Card>
    </Page>
  );
};

export default FormsPage;
