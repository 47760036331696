import moment from "moment";
import { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { scrollUpPage } from "src/_utils/elements";
import { getQueryParams } from "src/_utils/urls";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

const EventsPage = () => {
  const { trans } = useEBSSSLayout();
  const { fetchEvents, events, appSettings } = useEBSSS();
  const [page, setPage] = useState(null);

  useEffect(() => {
    setPage((p) => {
      if (p !== null) {
        return p;
      } else {
        return Number(getQueryParams(window.location.href)?.page);
      }
    });
  }, [window.location.search]);

  useEffect(() => {
    scrollUpPage();
    if (!events) {
      fetchEvents(1);
      return;
    }
    if (page) {
      fetchEvents(page);
    }
  }, [page]);

  console.log(events);

  return (
    <Page
      breads={[
        { name: trans("website.events"), active: true, url: "/crm/events" },
      ]}
    >
      <Calendar
        localizer={localizer}
        scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={new Date()}
        onSelectEvent={(event) => console.log(event)}
        views={allViews}
        showMultiDayTimes
        step={60}
        startAccessor="start"
        endAccessor="end"
        events={events?.data?.map((ev, iev) => {
          let recDay = "";
          if (ev?.recurrency_day == 1) {
            recDay = trans("common.sunday");
          }
          if (ev?.recurrency_day == 2) {
            recDay = trans("common.monday");
          }
          if (ev?.recurrency_day == 3) {
            recDay = trans("common.tuesday");
          }
          if (ev?.recurrency_day == 4) {
            recDay = trans("common.wednesday");
          }
          if (ev?.recurrency_day == 5) {
            recDay = trans("common.thursday");
          }
          if (ev?.recurrency_day == 6) {
            recDay = trans("common.friday");
          }
          if (ev?.recurrency_day == 7) {
            recDay = trans("common.saturday");
          }

          let finalObjEv = {
            id: ev.id,
            title: ev.title,
            allDay: false,
            start: new Date(ev.start_at),
            end: new Date(ev.end_at),
          };

          console.log("Event", ev.id, ": ", finalObjEv);
          return finalObjEv;
        })}
      />
    </Page>
  );
};
export default EventsPage;
