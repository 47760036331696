import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { scrollUpPage } from "src/_utils/elements";
import { CSS } from "src/_utils/styles";
import { getQueryParams } from "src/_utils/urls";
import { Btn } from "src/AbstractElements";
import EBSSSTable from "src/base/atoms/EBSSSTable";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { remotePath } from "src/base/settings/Paths";
import TablePage from "src/base/TablePage";

export const ProposalSeller = ({ proposal }) => {
  const seller = proposal?.seller;

  if (!seller) {
    return <td>-</td>;
  }

  const isUsernameValid = seller.username && isNaN(seller.username);

  return (
    <td>
      <a href={`/crm/seller/${isUsernameValid ? seller.username : seller.token}`}>
        <span className="badge badge-default bg-purple m-1">{isUsernameValid ? seller.username : seller.email}</span>
      </a>
    </td>
  );
};

export const ProposalItems = ({ proposal, appSettings, trans }) => {
  const items = proposal?.items?.filter((item) => item.type === "product");

  return (
    <td>
      <ul className="list-unstyled users-list m-0">
        {items?.length > 0 &&
          items?.slice(0, 3).map((item, index) => (
            <li key={index} data-toggle="tooltip" data-popup="tooltip-custom" data-original-title={item?.product?.name} className="avatar avatar-sm pull-up">
              {!appSettings?.application_no_image && item?.product?.photo ? (
                <img
                  className="media-object rounded-circle no-border-top-radius no-border-bottom-radius"
                  src={remotePath.assetsUrl(item?.product?.photo)}
                  alt={item?.product?.name}
                />
              ) : (
                <></>
              )}
            </li>
          ))}
        {items?.length > 3 && (
          <li className="avatar avatar-sm">
            <span className="badge badge-info">
              {items?.length} {trans("website.total-qtd-products")}
            </span>
          </li>
        )}
      </ul>
    </td>
  );
};

export const ProposalStatus = ({ proposal, trans }) => {
  const renderStatus = (status) => {
    switch (status) {
      case "accepted":
        return <p className="text-success">{trans("website.proposal-status-accepted")}</p>;
      case "declined":
        return <p className="text-danger">{trans("website.proposal-status-declined")}</p>;
      case "waiting":
        return <p className="text-warning">{trans("website.proposal-status-waiting")}</p>;
      case "new":
        return <p className="text-warning">{trans("website.proposal-status-new")}</p>;
      default:
        return null;
    }
  };

  return <>{renderStatus(proposal.status)}</>;
};

export const ProposalTotal = ({ proposal }) => {
  const shouldShowTotal = ["waiting", "accepted", "declined"].includes(proposal.status);

  return <>{shouldShowTotal ? `${proposal.total} ${proposal?.currency?.symbol}` : "-"}</>;
};

const ProposalsPage = () => {
  const { trans } = useEBSSSLayout();
  const { proposals, appSettings, fetchProposals } = useEBSSS();
  const [page, setPage] = useState(null);
  const [cols, setCols] = useState([]);

  useEffect(() => {
    setPage((p) => {
      if (p !== null) {
        return p;
      } else {
        return Number(getQueryParams(window.location.href)?.page);
      }
    });
  }, [window.location.search]);

  useEffect(() => {
    scrollUpPage();
    if (!proposals) {
      fetchProposals(1);
      return;
    }
    if (page) {
      fetchProposals(page);
    }
  }, [page]);

  console.log(proposals);

  useEffect(() => {
    if (!appSettings || !proposals) {
      return;
    }

    let cols: any = [
      {
        name: "#",
        selector: (row: { id: any }) => <div className="text-muted">{row.id}</div>,
        sortable: true,
        center: true,
      },
      {
        name: trans("common.created_at"),
        selector: (row: { created_at: any }) => row.created_at,
        sortable: true,
        center: false,
      },
    ];

    if (appSettings?.sys_multi_sellers) {
      cols = [
        ...cols,
        {
          name: trans("website.seller_name"),
          selector: (row: { created_at: any }) => <ProposalSeller proposal={row} />,
          sortable: true,
          center: false,
        },
      ];
    }

    cols = [
      ...cols,
      {
        name: trans("website.validate-untill"),
        selector: (row: { valid_till: any }) => row.valid_till,
        sortable: true,
        center: false,
      },
      {
        name: trans("website.product"),
        selector: (row: { created_at: any }) => <ProposalItems proposal={row} trans={trans} appSettings={appSettings} />,
        sortable: true,
        center: false,
      },
      {
        name: trans("common.status"),
        selector: (row: { created_at: any }) => <ProposalStatus proposal={row} trans={trans} />,
        sortable: true,
        center: false,
      },
      {
        name: trans("website.total-amount"),
        selector: (row: { created_at: any }) => <ProposalTotal proposal={row} />,
        sortable: true,
        center: false,
      },
      {
        name: "",
        selector: (row: any): any => {
          console.log(row.status);
          return row.status === "waiting" || row.status === "accepted" || row.status === "declined" ? (
            <div style={CSS({ gap: 5, display: "flex" })}>
              <Link to={process.env.PUBLIC_URL + "/crm/proposals/" + row.token}>
                <Btn
                  className="text-white btn-pill"
                  attrBtn={{
                    color: "info",
                    className: "info",
                  }}
                >
                  {trans("common.open")}
                </Btn>
              </Link>
            </div>
          ) : (
            <></>
          );
        },
        center: true,
      },
    ];
    setCols(cols);
  }, [appSettings]);

  return (
    <TablePage
      breads={[
        {
          name: trans("website.proposals"),
          active: false,
          url: "/crm/proposals",
        },
      ]}
      title=""
      description=""
    >
      <EBSSSTable pageHandler={setPage} data={proposals} cols={cols} />
    </TablePage>
  );
};
export default ProposalsPage;
