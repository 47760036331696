import { Dispatch } from "react";
import { TableColumn } from "react-data-table-component";
import TableData from "../../Component/Ecommerce/List/TableData";

type Cols<T> = TableColumn<T>[];

const EBSSSTable = ({
  data,
  cols = [],
  topAction,
  pageHandler,
  onRowClicked,
}: {
  data: any;
  cols: Cols<any>;
  topAction?: React.ReactNode | React.ReactNode[];
  pageHandler?: Dispatch<number>;
  onRowClicked?: (row: any) => void;
}) => {
  //@ts-ignore
  return <TableData data={data} columns={cols} pageHandler={pageHandler} topAction={topAction} onRowClicked={onRowClicked} />;
};

export default EBSSSTable;
