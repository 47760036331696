import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { reverseDate, scrollUpPage } from "src/_utils/elements";
import { CSS } from "src/_utils/styles";
import { getQueryParams } from "src/_utils/urls";
import EBSSSTable from "src/base/atoms/EBSSSTable";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import TablePage from "src/base/TablePage";

const EntityReservationsPage = () => {
  const { trans } = useEBSSSLayout();
  const { reservations, fetchReservations } = useEBSSS();
  const [page, setPage] = useState(null);

  useEffect(() => {
    setPage((p) => {
      if (p !== null) {
        return p;
      } else {
        return Number(getQueryParams(window.location.href)?.page);
      }
    });
  }, [window.location.search]);

  useEffect(() => {
    scrollUpPage();
    if (!reservations) {
      fetchReservations(1);
      return;
    }
    if (page) {
      fetchReservations(page);
    }
  }, [page]);

  console.log(reservations?.data);

  const de = (i) => (reservations?.data[i]?.order_item ? reservations?.data[i]?.order_item?.rental_start_at?.split(" ") : []);
  const ate = (i) => (reservations?.data[i]?.order_item ? reservations?.data[i]?.order_item?.rental_end_at?.split(" ") : []);
  const create = (i) => reservations?.data[i]?.created_at?.split(" ");

  let cols: any = [
    {
      name: trans("website.reference"),
      selector: (row: any) =>
        row.reference ? (
          <span
            className="badge bg-gray"
            style={CSS({
              backgroundColor: "#a9a9a9",
              color: "white",
              fontSize: 14,
            })}
          >
            {row.reference}
          </span>
        ) : (
          row.token
        ),
      sortable: true,
      center: true,
      width: "175px",
    },
    {
      name: trans("website.product"),
      selector: (row: any) =>
        row.order_item ? (
          <>
            {row.order_item.product.name}
            {reservations?.data?.some((row) => row.order_item && row.order_item.rental_people > 0) && (
              <>
                <br />
                <i className="la la-users"></i>
                {row.order_item.rental_people}
              </>
            )}
          </>
        ) : (
          <></>
        ),
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: trans("website.reservation-period"),
      selector: (row: any, idx) => (
        <div className="d-flex flex-row align-items-center gap-3">
          <div className="d-flex flex-column text-center">
            <span>{reverseDate(de(idx)[0])} </span>
            <span> {de(idx)[1]}</span>
          </div>
          <div>{trans("common.until")}</div>
          <div className="d-flex flex-column text-center">
            <span>{reverseDate(ate(idx)[0])} </span>
            <span>{ate(idx)[1]}</span>
          </div>

          {row.order_item?.shift_time && (
            <>
              <b>{row.order_item?.shift_time?.name}</b>
            </>
          )}
        </div>
      ),
      sortable: false,
      center: true,
      width: "300px",
    },
    {
      name: trans("common.status"),
      selector: (row: any) => (
        <>
          <small
            className={`badge ${row.status} bg-${row.statusColor}`}
            style={CSS({
              backgroundColor: row.statusColor === "yellow" ? "orange" : row.statusColor,
            })}
          >
            {trans(`entity.reservation-status-${row.status}`)}
          </small>
        </>
      ),
      sortable: false,
      center: true,
      width: "160px",
    },
    {
      name: trans("common.created_at"),
      selector: (row: any, idx) => (
        <div>
          <div className="d-flex flex-column text-center">
            <span>{reverseDate(create(idx)[0])} </span>
            <span> {create(idx)[1]}</span>
          </div>
        </div>
      ),
      sortable: false,
      center: true,
      width: "240px",
    },
    {
      name: "",
      selector: (row: any) => (
        <Link to={`${process.env.PUBLIC_URL}/entity-reservations/${row.token}`} state={{ row }} className="btn btn-primary">
          <i className="la la-folder-open" aria-hidden="true"></i> &nbsp;
          {trans("common.open")}
        </Link>
      ),
      sortable: true,
      center: true,
    },
  ];

  return (
    <TablePage
      title=""
      description=""
      breads={[
        {
          name: trans("website.menu-entity-reservations"),
          active: true,
          url: "/entity-reservations",
        },
      ]}
    >
      <EBSSSTable pageHandler={setPage} data={reservations} cols={cols} />
    </TablePage>
  );
};

export default EntityReservationsPage;
