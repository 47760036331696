//@ts-nocheck
import { Link } from "react-router-dom";
import { Col, Row, Accordion } from "reactstrap";
import { Btn, LI, UL, H5 } from "../../AbstractElements";
import { AddNewBookmark } from "../../Constant";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { CSS } from "src/_utils/styles";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import { useCallback, useContext, useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Input } from "reactstrap";
import { ReduxDataKey } from "src/base/store/store";
import CustomContext from "../../_helper/customizer";
import { HeaderBookmarkSvg } from "src/Data/svgIcons";
import { Layout } from "../store/layoutSlice";
import { useEBSSSLayout } from "../context/EBSSSLayoutContext/useEBSSSLayout";
import { FaPlusCircle, FaPlusSquare } from "react-icons/fa";
import { PlusCircle } from "react-feather";

const MegaDropdownMenu = ({ balance, commonAppSettings, sessionNow, megaMenu, trans }) => {
  const [showBalance, setShowBalance] = useState(false);
  const [isOpen, setIsOpen] = useState(1);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

  const toggleBalanceVisibility = () => {
    setShowBalance(!showBalance);
  };

  return (
    <Card className="p-2">
      {sessionNow?.user?.id ? (
        <div
          style={{
            borderRadius: "25px",
            borderColor: "#a9a9a9",
            color: "#000",
            height: 35,
            padding: 2.5,
          }}
          className="d-flex flex-row justify-content-between align-items-center"
        >
          <div>
            <i
              className={`me-2 ${showBalance ? "la la-eye" : "la la-eye-slash"}`}
              id="togglePassword2"
              style={{
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={toggleBalanceVisibility}
              title="Toggle balance visibility"
            ></i>
            <span>Balance: </span>
            &nbsp;
          </div>
          {showBalance && (
            <div>
              {commonAppSettings?.wlt_allows_charging === 1 ? (
                <Link
                  to={process.env.PUBLIC_URL + "/create/deposit"}
                  title="Add money"
                  style={{
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  {balance} {commonAppSettings?.currency_symbol}
                </Link>
              ) : (
                `${balance} ${commonAppSettings?.currency_symbol}`
              )}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      {sessionNow?.user?.id ? (
        <></>
      ) : commonAppSettings?.crm_hide_header_login_buttons ? (
        <></>
      ) : (
        <div className="d-flex flex-row align-items-center justify-content-center gap-3 mb-3 mt-2">
          <div>
            <Link to={process.env.PUBLIC_URL + "/auth/sign-in"}>
              <Btn attrBtn={{ color: "primary" }}>{trans("website.login")}</Btn>
            </Link>
          </div>
          <div>
            <Link to={process.env.PUBLIC_URL + "/auth/sign-up"}>
              <Btn attrBtn={{ color: "light" }}>{trans("website.register")}</Btn>
            </Link>
          </div>
        </div>
      )}
      <Accordion defaultActiveKey="0">
        <div className="default-according " id="accordion-0">
          <Card>
            <CardHeader>
              <H5 attrH5={{ className: "mb-0" }}>
                <Btn
                  attrBtn={{
                    as: Card.Header,
                    className: "btn btn-link text-capitalize rounded-0",
                    color: "default",
                    onClick: () => toggle(0),
                  }}
                >
                  <h6 className="dropdown-menu-header">
                    <i className="la la-folder-open"></i> {trans("website.menu-topic-general")}
                  </h6>
                </Btn>
              </H5>
            </CardHeader>
            <Collapse isOpen={isOpen === 0}>
              <CardBody className="bg-light">
                {megaMenu?.customPages?.map((page, index) => (
                  <div key={index} data-menu="">
                    <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + `/page/${page.slug}`}>
                      {page.title}
                    </Link>
                  </div>
                ))}
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </Accordion>
      <Accordion>
        <div className="default-according" id="accordion-1">
          <Card>
            <CardHeader>
              <H5 attrH5={{ className: "mb-0" }}>
                <Btn
                  attrBtn={{
                    as: Card.Header,
                    className: "btn btn-link text-capitalize rounded-0",
                    color: "default",
                    onClick: () => toggle(1),
                  }}
                >
                  <h6 className="dropdown-menu-header">
                    <i className="la la-exclamation-triangle"></i> {trans("website.menu-topic")}
                  </h6>
                </Btn>
              </H5>
            </CardHeader>
            <Collapse isOpen={isOpen === 1}>
              <CardBody className="bg-light">
                <div>
                  <div data-menu="">
                    <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + "/terms-conditions"}>
                      {trans("website.menu-terms-conditions")}
                    </Link>
                  </div>
                  <div data-menu="">
                    <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + "/privacy-policy"}>
                      {trans("website.menu-privacy-policy")}
                    </Link>
                  </div>
                  {commonAppSettings?.hidden_faq ? (
                    <></>
                  ) : (
                    <div data-menu="">
                      <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + "/faq"}>
                        {trans("website.menu-faq")}
                      </Link>
                    </div>
                  )}
                </div>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </Accordion>
      <Accordion>
        <div className="default-according" id="accordion-2">
          <Card>
            <CardHeader>
              <H5 attrH5={{ className: "mb-0" }}>
                <Btn
                  attrBtn={{
                    as: Card.Header,
                    className: "btn btn-link text-capitalize rounded-0",
                    color: "default",
                    onClick: () => toggle(2),
                  }}
                >
                  <h6 className="dropdown-menu-header">
                    <i className="la la-paper-plane"></i> {trans("website.menu-actions")}
                  </h6>
                </Btn>
              </H5>
            </CardHeader>
            <Collapse isOpen={isOpen === 2}>
              <CardBody className="bg-light">
                <div className="menu-list">
                  <div>
                    {sessionNow && sessionNow?.user?.status !== "guest" && (
                      <>
                        <div data-menu="">
                          <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + "/forms/replied/filter/all"}>
                            {trans("website.see-replied-forms")}
                          </Link>
                        </div>
                        {(commonAppSettings?.crm_crm === 1 || commonAppSettings?.crm_booking === 1) && (
                          <div data-menu="">
                            <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + "/crm/user-files"}>
                              {trans("website.menu-files")}
                            </Link>
                          </div>
                        )}
                      </>
                    )}

                    {commonAppSettings?.crm_booking === 1 && (
                      <div data-menu="">
                        <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + "/book"}>
                          {trans("booking.new-appointment")}
                        </Link>
                      </div>
                    )}

                    {commonAppSettings?.crm_crm === 1 && commonAppSettings?.crm_customer_area_sales === 1 && (
                      <div data-menu="">
                        <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + "/crm/products?page=1"}>
                          {trans("website.menu-buy-products")}
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </Accordion>
      {!megaMenu?.linkList || megaMenu?.linkList?.length === 0 ? (
        commonAppSettings?.crm_customer_area && (
          <Accordion>
            <div className="default-according" id="accordion-3">
              <Card>
                <CardHeader>
                  <H5 attrH5={{ className: "mb-0" }}>
                    <Btn
                      attrBtn={{
                        as: Card.Header,
                        className: "btn btn-link text-capitalize rounded-0",
                        color: "default",
                        onClick: () => toggle(3),
                      }}
                    >
                      <h6 className="dropdown-menu-header">
                        <i className="la la-user"></i> {trans("website.menu-account")}
                      </h6>
                    </Btn>
                  </H5>
                </CardHeader>
                <Collapse isOpen={isOpen === 3}>
                  <CardBody className="bg-light">
                    <div className="menu-list">
                      {sessionNow && sessionNow?.user?.status !== "guest" ? (
                        <>
                          <div data-menu="">
                            <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + "/dashboard"}>
                              {trans("website.dashboard")}
                            </Link>
                          </div>
                          <div data-menu="">
                            <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + "/dashboard/profile"}>
                              {trans("website.menu-profile")}
                            </Link>
                          </div>
                          {commonAppSettings?.crm_customer_area_sales === 1 && (
                            <div data-menu="">
                              <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + "/crm/favorites"}>
                                {trans("website.menu-favorites")}
                              </Link>
                            </div>
                          )}
                          <div data-menu="">
                            <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + "/dashboard/gdpr"}>
                              {trans("gdpr.title")}
                            </Link>
                          </div>
                        </>
                      ) : commonAppSettings?.crm_hide_header_login_buttons ? (
                        <></>
                      ) : (
                        <div className="d-flex flex-row align-items-center justify-content-center gap-3 mb-3 mt-2">
                          <div>
                            <Link to={process.env.PUBLIC_URL + "/auth/sign-in"}>
                              <Btn attrBtn={{ color: "primary" }}>{trans("website.login")}</Btn>
                            </Link>
                          </div>
                          <div>
                            <Link to={process.env.PUBLIC_URL + "/auth/sign-up"}>
                              <Btn attrBtn={{ color: "light" }}>{trans("website.register")}</Btn>
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Collapse>
              </Card>
            </div>
          </Accordion>
        )
      ) : (
        <Accordion>
          <div className="default-according" id="accordion-3">
            <Card>
              <CardHeader>
                <H5 attrH5={{ className: "mb-0" }}>
                  <Btn
                    attrBtn={{
                      as: Card.Header,
                      className: "btn btn-link text-capitalize rounded-0",
                      color: "default",
                      onClick: () => toggle(3),
                    }}
                  >
                    <h6 className="dropdown-menu-header">
                      <i className="la la-anchor"></i> {trans("website.menu-links")}
                    </h6>
                  </Btn>
                </H5>
              </CardHeader>
              <Collapse isOpen={isOpen === 3}>
                <CardBody className="bg-light">
                  <div>
                    {megaMenu?.linkList?.map((link, index) => (
                      <div key={index} data-menu="">
                        <Link className="dropdown-item menu-item fs-6 fw-bold" to={process.env.PUBLIC_URL + link.url}>
                          {link.label}
                        </Link>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Collapse>
            </Card>
          </div>
        </Accordion>
      )}
    </Card>
  );
};

const EBSSSMegaMenu = () => {
  // eslint-disable-next-line
  const { trans } = useEBSSSLayout();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey["AUTH"]]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);

  return (
    <Fragment>
      <LI attrLI={{ className: "onhover-dropdown" }}>
        <div>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" fill="#fff" stroke="#000" stroke-width="1.5" />

            <line x1="12" y1="8" x2="12" y2="16" stroke="black" stroke-width="1.5" stroke-linecap="round" />
            <line x1="8" y1="12" x2="16" y2="12" stroke="black" stroke-width="1.5" stroke-linecap="round" />

            <g>
              <path
                d="M2.99609 8.71995C3.56609 5.23995 5.28609 3.51995 8.76609 2.94995"
                stroke="#130F26"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.76616 20.99C5.28616 20.41 3.56616 18.7 2.99616 15.22L2.99516 15.224C2.87416 14.504 2.80516 13.694 2.78516 12.804"
                stroke="#130F26"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.2446 12.804C21.2246 13.694 21.1546 14.504 21.0346 15.224L21.0366 15.22C20.4656 18.7 18.7456 20.41 15.2656 20.99"
                stroke="#130F26"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.2661 2.94995C18.7461 3.51995 20.4661 5.23995 21.0361 8.71995"
                stroke="#130F26"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </div>
        <div className={`onhover-show-div`}>
          <MegaDropdownMenu
            sessionNow={sessionNow}
            megaMenu={ebsssLayout?.megaMenu}
            balance={ebsssLayout?.balance_wallet}
            commonAppSettings={ebsssLayout?.commonAppSettings}
            trans={trans}
          />
        </div>
      </LI>
    </Fragment>
  );
};

export default EBSSSMegaMenu;
