import { useEffect, useRef, useState } from "react";
import { Star } from "react-feather";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Media, Row } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { Btn, H6, Image, P } from "src/AbstractElements";
import EBSSSInitialNameProfile from "src/base/atoms/EBSSSInitialNameProfile";
import EBSSSModal from "src/base/atoms/EBSSSModal";
import EBSSSTicket from "src/base/atoms/EBSSSTicket";
import { remotePath } from "src/base/settings/Paths";
import { Layout } from "src/base/store/layoutSlice";
import { ReduxDataKey } from "src/base/store/store";
import { Action } from "src/Constant";

export const ContactCenterMailContain = ({ contacts, settings, trans, sessionNow }: any) => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const history = useNavigate();
  const params = useParams();
  const [searchKeyword, setSearchKeyword] = useState("");
  const debounceTimeoutRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [creatingTicket, setCreatingTicket] = useState({ type: "", id: null, categoryFormId: null });
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);

  useEffect(() => {
    if (params.keyword) {
      setSearchKeyword(params.keyword);
    }
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  const entitiesTicketable = ["reservations", "contracts", "orders", "projects", "leads"];
  const invertedFormsRelationship = settings?.forms
    ?.map((form) => {
      return {
        id: form.parent_category_id,
        category_name: form.parent_category_name,
      };
    })
    ?.reduce((acc, current) => {
      if (!acc.some((item) => item.id === current.id)) {
        acc.push({
          ...current,
          forms: settings?.forms?.filter((f) => f.parent_category_id === current.id),
        });
      }
      return acc;
    }, []);

  console.log(invertedFormsRelationship);

  const toggleModal = () => {
    setModal(!modal);
    let cleanModal = setTimeout(() => {
      setCreatingTicket({ type: "", id: null, categoryFormId: null });
      clearTimeout(cleanModal);
    }, 250);
  };

  const handleChangeBouncer = (event, value: string) => {
    if (!value) {
      history(`${process.env.PUBLIC_URL}/crm/contacts/center?page=1`);
      return;
    }

    setSearchKeyword(value);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      history(`${process.env.PUBLIC_URL}/crm/contacts/center/search/${value}?page=1`);
    }, 1500);
  };

  const handleIfHasChildrenRoute = (item) => {
    if (item.reservation_id) {
      return "/" + item.order_id;
    }

    return "";
  };

  console.log(settings);

  return (
    <Fragment>
      <div className="email-right-aside">
        <Card className="email-body">
          <div className="email-profile">
            <div>
              <div className="pe-0 b-r-light"></div>
              <div className="email-top">
                <Row>
                  <div className="col-md-6 col-12">
                    <Media>
                      <Label className="email-chek d-block" htmlFor="chk-ani">
                        <Input className="checkbox_animated d-none" id="chk-ani" type="checkbox" />
                        <EBSSSModal
                          hideFooter
                          hideSubmitButton
                          modalSize="modal-lg"
                          modalTitle={trans("website.ticket-create")}
                          modal={modal}
                          toggle={toggleModal}
                          triggerEl={
                            <Btn
                              attrBtn={{
                                onClick: toggleModal,
                                className: `btn btn-info img-fluid d-flex align-items-center gap-2`,
                                alt: "",
                              }}
                            >
                              <i className="la la-plus-circle fs-5"> </i> {trans("common.ticket-create")}
                            </Btn>
                          }
                        >
                          {creatingTicket.type ? (
                            <div>
                              <div>
                                <span className="text-muted my-3 fs-6 mb-2">{trans("common.select")}</span>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    console.log("Select: ", e.target.value);
                                    setCreatingTicket({ ...creatingTicket, id: e.target.value });
                                  }}
                                >
                                  <option value={null}>---</option>
                                  {creatingTicket?.type !== "form"
                                    ? settings?.all_types[creatingTicket.type]
                                        ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                                        ?.map((op) => {
                                          return (
                                            <option value={op.id}>
                                              <span className="text-capitalize">
                                                {trans("website." + creatingTicket?.type?.slice(0, -1)) + " #" + op.id}{" "}
                                                {(creatingTicket?.type === "orders"
                                                  ? " | " + op.total + " " + ebsssLayout.commonAppSettings.currency_symbol
                                                  : creatingTicket?.type === "reservations"
                                                  ? ""
                                                  : creatingTicket?.type === "contracts"
                                                  ? " | " + op.value + " " + ebsssLayout.commonAppSettings.currency_symbol
                                                  : creatingTicket?.type === "projects"
                                                  ? " | " + op.name
                                                  : creatingTicket?.type === "leads"
                                                  ? ""
                                                  : " | " + op.total) + " "}
                                                {creatingTicket?.type === "reservations" ? " | " + trans("website.reference") + " " + op.reference : ""}
                                              </span>
                                            </option>
                                          );
                                        })
                                    : invertedFormsRelationship
                                        ?.find((i) => i.id === creatingTicket.categoryFormId)
                                        ?.forms?.map((op) => {
                                          return (
                                            <option value={op.id}>
                                              <span className="text-capitalize">{op?.title}</span>
                                            </option>
                                          );
                                        })}
                                </select>
                              </div>
                              <div className={"py-3"}>
                                <span className="text-muted my-3 fs-6 mb-2">{trans("website.type-your-message")}</span>
                                <EBSSSTicket
                                  validateBeforeSend
                                  fullWidth
                                  contactCenter
                                  dataId={creatingTicket?.id}
                                  dataType={creatingTicket?.type === "form" ? "lead" : creatingTicket.type.slice(0, -1)}
                                  data={null}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex gap-5 flex-column justify-content-start">
                              <div className="w-100">
                                <span className="text-muted my-3 fs-6 mb-2">{trans("website.create-ticket-associated-to")}</span>
                                <div className="d-flex col-12 flex-row flex-wrap justify-content-start">
                                  {entitiesTicketable.map((op) => {
                                    console.log(op, settings?.all_types?.hasOwnProperty(op));
                                    return settings?.all_types?.hasOwnProperty(op) && settings?.all_types[op]?.length > 0 ? (
                                      <div className={`text-center`} style={CSS({ marginRight: 5, marginBottom: 5 })}>
                                        <Btn
                                          attrBtn={{
                                            onClick: () => {
                                              setCreatingTicket({ ...creatingTicket, type: op });
                                            },
                                            className: "btn btn-info m-auto d-flex gap-2 align-items-center",
                                            style: CSS({ width: "100%" }),
                                            alt: "",
                                          }}
                                        >
                                          <i className="la la-plus-circle fs-5"> </i> <span>{trans("website." + op)}</span>
                                        </Btn>
                                      </div>
                                    ) : (
                                      <></>
                                    );
                                  })}
                                </div>
                              </div>
                              <div>
                                <span className="text-muted my-3 fs-6 mb-2">{trans("website.or-create-from")}</span>
                                <div className="d-flex col-12 flex-row flex-wrap justify-content-start">
                                  {invertedFormsRelationship?.map((op) => {
                                    return (
                                      <div className={`text-center`} style={CSS({ marginRight: 5, marginBottom: 5 })}>
                                        <Btn
                                          attrBtn={{
                                            onClick: () => {
                                              setCreatingTicket({ ...creatingTicket, type: "form", categoryFormId: op.id });
                                            },
                                            className: "btn btn-info m-auto d-flex gap-2 align-items-center",
                                            style: CSS({ width: "100%" }),
                                            alt: "",
                                          }}
                                        >
                                          <i className="la la-plus-circle fs-5"> </i> <span>{op.category_name ?? trans("website.forms")}</span>
                                        </Btn>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          )}
                        </EBSSSModal>
                      </Label>
                      <Media body>
                        <div className="dropdown d-none">
                          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle color="primary" caret>
                              {Action}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem className="dropdown-item">Refresh</DropdownItem>
                              <DropdownItem className="dropdown-item">Mark as important</DropdownItem>
                              <DropdownItem className="dropdown-item">Move to spam</DropdownItem>
                              <DropdownItem className="dropdown-item">Move to Trash</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </Media>
                    </Media>
                  </div>
                  <div className="col-md-6 col-12">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (debounceTimeoutRef.current) {
                          clearTimeout(debounceTimeoutRef.current);
                        }
                        history(`${process.env.PUBLIC_URL}/crm/contacts/center/search/${searchKeyword}?page=1`);
                      }}
                    >
                      <div className="form-group d-flex flex-row align-items-center gap-2 mt-2 mt-md-0">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder={trans("website.search")}
                          id="product-query"
                          value={searchKeyword}
                          onChange={(e) => handleChangeBouncer(e, e.target.value)}
                        />
                        <i className="fa fa-search"></i>
                      </div>
                    </Form>
                  </div>
                </Row>
              </div>
              <div className="inbox">
                {contacts?.data?.map((item) => {
                  return (
                    <Media key={item.id} className="d-flex align-items-center">
                      <div className="media-size-email">
                        <Label className="d-block mb-0 d-none" htmlFor="chk-ani">
                          <Input className="checkbox_animated" id="chk-ani" type="checkbox" />
                        </Label>
                        <i className={item.user_id === sessionNow?.user?.id ? "me-2 la la-mail-reply" : "me-2 la la-mail-forward"}></i>
                        <Star className="like d-none" />
                        <span
                          className={`d-none text-capitalize badge rounded-2 p-2 m-2 ${
                            item.contact_type === "ticket" ? "bg-light text-dark" : item.contact_type === "chat" ? "bg-dark" : "bg-secondary"
                          }`}
                        >
                          {item.contact_type}
                        </span>
                        <span
                          className={`badge bg-white border text-dark rounded-2 p-2 m-2 ${
                            item.order_id && !item.reservation_id
                              ? "border-danger"
                              : item.reservation_id
                              ? "border-warning"
                              : item.contract_id
                              ? "border-success"
                              : item.lead_id
                              ? "border-black"
                              : "border-info"
                          }`}
                        >
                          {item.order_id && !item.reservation_id
                            ? trans("website.order")
                            : item.reservation_id
                            ? trans("website.entity-reservation")
                            : item.contract_id
                            ? trans("website.contract")
                            : item.lead_id
                            ? trans("website.lead")
                            : trans("website.project")}
                        </span>
                        {item?.reservation_id ? <span className="badge rounded-2 p-2 m-2 bg-dark">{item?.reservation_reference}</span> : <></>}
                        {item?.user?.profile?.avatar ? (
                          <Image
                            attrImage={{
                              className: "me-3 rounded-circle",
                              src: `${
                                item?.user?.profile?.avatar
                                  ? remotePath.assetsUrl(item?.user?.profile?.avatar)
                                  : "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"
                              }`,
                              alt: "",
                            }}
                          />
                        ) : (
                          <EBSSSInitialNameProfile user={item?.user} width={25} height={25} fontSize={12.5} />
                        )}
                      </div>
                      <Link
                        className="ms-2"
                        to={{
                          pathname: `${process.env.PUBLIC_URL}/crm/contacts/center/${item.contact_type}/${item.token}${handleIfHasChildrenRoute(item)}`,
                        }}
                        state={item}
                      >
                        <Media body className="text-truncate">
                          <H6>{item.user.profile.first_name + " " + item.user.profile.last_name}</H6>
                          <P
                            attrPara={{
                              className: "",
                              style: CSS({ width: "calc(100% - 125px)" }),
                            }}
                          >
                            {item.message}
                          </P>
                          <span className="d-none d-xl-block">
                            {new Date(item.created_at).toLocaleDateString() + " " + new Date(item.created_at).toLocaleTimeString()}
                          </span>
                        </Media>
                      </Link>
                    </Media>
                  );
                })}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};
