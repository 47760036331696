//@ts-nocheck
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { Image } from "src/AbstractElements";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { remotePath } from "src/base/settings/Paths";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import { FormComponent, FormDescription, FormLangs, SentFormFeedback } from "./FormCommon";

const FormPublicPage = () => {
  const { trans } = useEBSSSLayout();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { form, appSettings, fetchForm, sendForm } = useEBSSS();
  const [sentForm, setSentForm] = useState(false);
  const getForm = useParams();
  const navigate = useNavigate();

  console.log("FORM: ", form);
  console.log("APPSETTINGS: ", appSettings);

  useEffect(() => {
    fetchForm(getForm?.slug);
  }, []);

  return (
    <div
      style={CSS({
        backgroundImage: `url(${remotePath.assetsUrl(appSettings?.background)})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        minHeight: "100vh",
        height: "100%",
      })}
    >
      <Card
        style={CSS({
          background: "#00000000",
          marginBottom: 0,
        })}
      >
        {!sentForm ? (
          <CardBody>
            <div className="mb-3 d-flex p-3">
              {appSettings?.logo ? (
                <Image
                  attrImage={{
                    style: CSS({ cursor: "pointer" }),
                    onClick: () => navigate(process.env.PUBLIC_URL),
                    className: "m-auto tuna-signup-logo",
                    src: remotePath.assetsUrl(appSettings?.logo),
                    alt: "logo",
                    width: "150px",
                    height: "100%",
                  }}
                />
              ) : (
                <h1>{appSettings?.instance_title}</h1>
              )}
            </div>
            <div className="rounded col-12 col-md-9 m-auto p-lg-3 text-dark" style={CSS({ backgroundColor: "#ffffff90" })}>
              <br />
              <div className="d-flex flex-row justify-content-between align-items-top">
                <FormDescription description={form?.form?.description} />
                <FormLangs availableLanguages={form?.available_languages} trans={trans} fetcher={(lang) => fetchForm(getForm?.slug, lang)} />
              </div>
              <FormComponent form={form} authUser={sessionNow?.user} sendForm={sendForm} trans={trans} appSettings={appSettings} setSentForm={setSentForm} />
            </div>
          </CardBody>
        ) : (
          <CardBody>
            <SentFormFeedback trans={trans} authUser={sessionNow?.user} />
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default FormPublicPage;
