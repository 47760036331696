import axios, { AxiosRequestConfig } from "axios";

export default class HelperHTTP {
  static token = "";
  static instanceId = "";
  static fileIncluded = false;
  static returnsBlob = false;
  static ignoreSession = false;

  static httpRequest = async ({ base, endpoint, options }) => {
    const axiosInstance = axios.create({
      baseURL: base,
    });

    let configData: AxiosRequestConfig = {
      url: endpoint,
      data: options?.data ? options?.data : undefined,
      headers: options?.headers ? { "Content-Type": "application/json", ...options?.headers } : { "Content-Type": "application/json" },
      method: options?.method ?? "GET",
    };

    if (!!this.fileIncluded) {
      configData.headers = {
        ...configData.headers,
        "Content-Type": "multipart/form-data; boundary=" + Math.random().toString().slice(0, 2),
        Accept: "application/json",
      };
    }

    if (!!this.returnsBlob) {
      configData = { ...configData, responseType: "blob" };
    }

    try {
      const requested = await axiosInstance(configData);
      console.log("RAW HTTP RESPONSE: ", requested);

      if (!!this.returnsBlob) {
        this.handleBlob(requested);
        return;
      }

      return requested.data;
    } catch (err) {
      return err.response.data;
    }
  };
  static configRequest: any = {};
  static sessionRequest: any = {};

  static api() {
    this.configRequest = {
      ...this.configRequest,
      endpoint: "/api" + this.configRequest.endpoint,
    };
    return this;
  }

  static addSessionState(session) {
    this.sessionRequest = session;
    return this;
  }

  static withUser(userId) {
    this.appendQuery({ user_id: userId });
    return this;
  }

  static withLanguage(lang) {
    this.appendQuery({ active_language: lang });
    return this;
  }

  static mobileFlag() {
    this.appendQuery({ mobile: "true" });
    return this;
  }

  static withTenant(instanceId) {
    this.instanceId = instanceId;
    return this;
  }

  static hasFile() {
    this.fileIncluded = true;
    return this;
  }

  static hasBlob() {
    this.returnsBlob = true;
    return this;
  }

  static handleBlob(response) {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", new Date().getTime() + ".pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.returnsBlob = false;
  }

  static customRequest(baseUrl, endpoint) {
    this.configRequest = {
      ...this.configRequest,
      base: baseUrl,
      endpoint,
    };
    return this;
  }

  static managerRequest(endpoint) {
    this.configRequest = {
      ...this.configRequest,
      endpoint,
      base: window.location.origin,
    };
    return this;
  }

  static noSendSessionPayload() {
    this.ignoreSession = true;
    return this;
  }

  static paginated(page, pageKey = "page") {
    let endpointToComplete = this.configRequest.endpoint;

    if (endpointToComplete.includes("?")) {
      endpointToComplete += `&${pageKey}=${page ?? "1"}`;
    } else {
      endpointToComplete += `?${pageKey}=${page ?? "1"}`;
    }

    this.configRequest = {
      ...this.configRequest,
      endpoint: endpointToComplete,
    };
    return this;
  }

  static appendQuery(params) {
    let endpointToComplete = this.configRequest.endpoint;
    let parsedQuery = Object.entries(params);
    let queryString = parsedQuery.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as unknown as any)}`).join("&");

    if (endpointToComplete.includes("?")) {
      endpointToComplete = endpointToComplete + "&" + queryString;
    } else {
      endpointToComplete = endpointToComplete + "?" + queryString;
    }

    this.configRequest = {
      ...this.configRequest,
      endpoint: endpointToComplete,
    };
    return this;
  }

  static authed({ tk, hdrs }: { tk?: string; hdrs?: Array<any> }) {
    if (hdrs) {
      this.configRequest = {
        ...this.configRequest,
        options: {
          ...this.configRequest?.options,
          headers: { ...hdrs },
        },
      };
      return this;
    }

    this.configRequest = {
      ...this.configRequest,
      options: {
        ...this.configRequest.options,
        headers: {
          ...this.configRequest.options?.headers,
          Authorization: tk,
        },
      },
    };

    return this;
  }

  static async get() {
    return await this.httpRequest({
      ...this.configRequest,
      options: { ...this.configRequest.options, method: "GET" },
    });
  }
  static async delete() {
    return await this.httpRequest({
      ...this.configRequest,
      options: { ...this.configRequest.options, method: "DELETE" },
    });
  }
  static async post(config) {
    return await this.httpRequest({
      ...this.configRequest,
      options: {
        ...this.configRequest.options,
        method: "POST",
        data: this.ignoreSession ? config?.data : { ...config?.data, session: this.sessionRequest },
      },
    });
  }
  static async patch(config) {
    return await this.httpRequest({
      ...this.configRequest,
      options: {
        ...this.configRequest.options,
        method: "PATCH",
        data: this.ignoreSession ? config?.data : { ...config?.data, session: this.sessionRequest },
      },
    });
  }
  static async put(config) {
    return await this.httpRequest({
      ...this.configRequest,
      options: {
        ...this.configRequest.options,
        method: "PUT",
        data: this.ignoreSession ? config?.data : { ...config?.data, session: this.sessionRequest },
      },
    });
  }
}
