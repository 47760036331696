import ForgotPasswordModifyPage from "src/Pages/Auth/ForgotPasswordModifyPage";
import ForgotPasswordVerifyPage from "src/Pages/Auth/ForgotPasswordVerifyPage";
import LoginPage from "src/Pages/Auth/LoginPage";
import RegisterPage from "src/Pages/Auth/RegisterPage";
import RegisterPagePassword from "src/Pages/Auth/RegisterPagePassword";
import RegisterPageToken from "src/Pages/Auth/RegisterPageToken";
import CheckoutPage from "src/Pages/Checkout/CheckoutPage";
import PreCheckoutPage from "src/Pages/Checkout/PreCheckoutPage";
import ContractPaymentPage from "src/Pages/ContractPayments/ContractPaymentPage";
import CustomPage from "src/Pages/Custom/CustomPage";
import FAQPage from "src/Pages/FAQ/FAQPage";
import FormPublicPage from "src/Pages/Forms/FormPublicPage";
import FormsPublicPage from "src/Pages/Forms/FormsPublicPage";
import GDPRPage from "src/Pages/GDPR/GDPRPage";
import ProductPage from "src/Pages/Products/ProductPage";
import ProductRedirector from "src/Pages/Products/ProductRedirector";
import ProductsCategoriesPage from "src/Pages/Products/ProductsCategoriesPage";
import ProductsPage from "src/Pages/Products/ProductsPage";
import ProductsSearchPage from "src/Pages/Products/ProductsSearchPage";
import SellerCategoryPage from "src/Pages/Seller/SellerCategoryPage";
import SellerPage from "src/Pages/Seller/SellerPage";
import SellerSearchPage from "src/Pages/Seller/SellerSearchPage";
import PolicyConditionPage from "src/Pages/Terms/PolicyConditionPage";
import TermsConditionPage from "src/Pages/Terms/TermsConditionPage";
import TermsPage from "src/Pages/Terms/TermsPage";
import TicketPublicAnswer from "src/Pages/Ticket/TicketPublicAnswer";

export const guestRoutes = [
  //Seller
  {
    path: `${process.env.PUBLIC_URL}/crm/seller/:slug`,
    Component: <SellerPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/seller/:slug/category/:token`,
    Component: <SellerCategoryPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/seller/:slug/search/:name`,
    Component: <SellerSearchPage />,
  },

  //FAQ
  { path: `${process.env.PUBLIC_URL}/faq`, Component: <FAQPage /> },

  //Pages
  {
    path: `${process.env.PUBLIC_URL}/page/:slug`,
    Component: <CustomPage />,
  },

  //TOS
  {
    path: `${process.env.PUBLIC_URL}/terms-conditions`,
    Component: <TermsConditionPage />,
  },

  //POS
  {
    path: `${process.env.PUBLIC_URL}/privacy-policy`,
    Component: <PolicyConditionPage />,
  },

  //Contract payment
  {
    path: `${process.env.PUBLIC_URL}/crm/contract-payment/:token`,
    Component: <ContractPaymentPage />,
  },
  //Contract payment
  {
    path: `${process.env.PUBLIC_URL}/crm/contract-payment/:token/:gateway/:gateway_param`,
    Component: <ContractPaymentPage />,
  },

  //Products
  {
    path: `${process.env.PUBLIC_URL}/crm/product/:slug`,
    Component: <ProductRedirector />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/products/:slug`,
    Component: <ProductPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/products`,
    Component: <ProductsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/products/category/:slug`,
    Component: <ProductsCategoriesPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/products/search/:name`,
    Component: <ProductsSearchPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/answer/:user/:type/:token/:parent_entity?`,
    Component: <TicketPublicAnswer />,
  },
];
