import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import EBSSSTicket from "src/base/atoms/EBSSSTicket";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";

function getFileIconClass(fileLink) {
  const extension = fileLink.split(".").pop().toLowerCase();
  switch (extension) {
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
      return "la la-file-image-o";
    case "svg":
    case "tif":
    case "webp":
    case "psd":
    case "ai":
    case "eps":
      return "la la-file-image-o";
    case "doc":
    case "docx":
      return "la la-file-word-o";
    case "pdf":
      return "la la-file-pdf-o";
    case "xls":
    case "xlsx":
      return "la la-file-excel-o";
    case "pps":
    case "ppt":
      return "la la-file-powerpoint-o";
    case "mp3":
    case "wav":
      return "la la-file-audio-o";
    case "zip":
    case "rar":
    case "apk":
      return "la la-file-archive-o";
    case "mp4":
    case "avi":
    case "mpg":
    case "mpeg":
    case "webm":
      return "la la-file-video-o";
    case "txt":
    case "rtf":
      return "la la-file-text-o";
    case "html":
    case "php":
    case "java":
    case "js":
      return "la la-file-code-o";
    default:
      return "la la-file";
  }
}

const ProjectPage = () => {
  const { project, fetchProject, sendTicket } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const getProject = useParams();

  useEffect(() => {
    fetchProject(getProject?.token);
  }, []);

  const taskCompleteCount = project?.tasks?.filter((task) => task.status === "completed")?.length;
  const taskTotalCount = project?.tasks?.length;
  const percentComplete = taskTotalCount > 0 ? (100 * taskCompleteCount) / taskTotalCount : 0;

  return (
    <Page
      breads={[
        { name: trans("website.projects"), active: false, url: "crm/projects" },
        { name: trans("website.project") + " " + project?.name, active: true },
      ]}
    >
      {!!project ? (
        <Card>
          <CardBody>
            <div className="content-body text-muted row">
              <div className="col-lg-6 pl-0 pr-0">
                <div className="col-lg-12">
                  <div className="card text-muted">
                    <div className="card-head">
                      <div className="card-header card-head-colour">
                        <h4 className="card-title">{trans("website.projects_details")}</h4>
                        <div className="heading-elements">
                          <span
                            className="badge"
                            style={{
                              backgroundColor: project?.category?.color,
                            }}
                          >
                            {project?.category?.title}
                          </span>
                          {project?.subcategory && (
                            <span
                              className="badge"
                              style={{
                                backgroundColor: project?.category?.color,
                              }}
                            >
                              {project?.subcategory?.name}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="px-1">
                        <ul className="d-flex justify-content-center gap-3 list-inline list-inline-pipe text-center p-1 border-bottom-grey border-bottom-lighten-3">
                          {project?.responsible && (
                            <li>
                              Responsible: <span className="text-muted">{project?.responsible_full_name}</span>
                            </li>
                          )}
                          <li>
                            {trans("website.task_start_at")}: <span className="text-muted">{new Date(project?.start_at).toLocaleDateString()}</span>
                          </li>
                          <li>
                            {trans("website.task_end_at")}: <span className="text-muted">{new Date(project?.end_at).toLocaleDateString()}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div id="project-info" className="card-body row">
                      <div className="project-info-count col-lg-6 text-center col-md-12">
                        <div className="project-info-icon">
                          <h2>{project?.user_count}</h2>
                          <div className="project-info-sub-icon">
                            <span className="la la-user"></span>
                          </div>
                        </div>
                        <div className="project-info-text pt-1">
                          <h5>{trans("website.users")}</h5>
                        </div>
                      </div>
                      <div className="project-info-count col-lg-6 text-center col-md-12">
                        <div className="project-info-icon">
                          <h2>{project?.task_count}</h2>
                          <div className="project-info-sub-icon">
                            <span className="la la-calendar-check-o"></span>
                          </div>
                        </div>
                        <div className="project-info-text pt-1">
                          <h5>{trans("website.tasks")}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row py-2">
                        <div className="col-lg-12 col-md-12">
                          <div className="insights px-2">
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="text-info h3">{`${percentComplete.toFixed(2)}%`}</span>
                              <span className="float-right">{trans("website.tasks_completed")}</span>
                            </div>
                            <div className="progress progress-sm mt-1 mb-0">
                              <div
                                className="progress-bar bg-info"
                                style={{ width: `${percentComplete}%` }}
                                aria-valuenow={25}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header card-head-colour">
                      <h4 className="card-title">{trans("website.files")}</h4>
                    </div>
                    <div className="card-content">
                      <div className="card-body" style={{ height: "337px", overflowY: "auto" }}>
                        <div className="list-group">
                          {project?.files?.length > 0 ? (
                            project?.files?.map((file) => {
                              const fileLink = `/files/${file.filepath}`;
                              const fileIconClass = getFileIconClass(fileLink);
                              return (
                                <a href={fileLink} target="_blank" className="list-group-item" key={file.id} rel="noreferrer">
                                  <div className="media">
                                    <div className="media-left pr-1">
                                      <i className={`${fileIconClass} attachment-icon`} style={{ fontSize: "38px" }}></i>
                                    </div>
                                    <div className="media-body w-100">
                                      <h6 className="media-heading mb-0">{file.filename}</h6>
                                      <p className="font-small-2 mb-0 text-muted">{new Date(file.start_at).toLocaleDateString()}</p>
                                    </div>
                                  </div>
                                </a>
                              );
                            })
                          ) : (
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center text-center">
                              <div className="d-flex flex-column">
                                <i className="h1 fa fa-folder text-secondary"></i>
                                <span className="text-muted">{trans("website.no_files")}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <EBSSSTicket data={project} dataType={"project"} callbackRefresh={() => fetchProject(getProject?.token)} />
            </div>
          </CardBody>
        </Card>
      ) : (
        <></>
      )}
    </Page>
  );
};
export default ProjectPage;
