import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { ProductFeatures, ProductGrid, SkeletonLoader } from "../Products/ProductsCommon";
import { EBSSSPagination } from "src/base/atoms/EBSSSPagination";
import { useSelector } from "react-redux";
import { ReduxDataKey } from "src/base/store/store";
import { getQueryParams } from "src/_utils/urls";
import { scrollUpPage } from "src/_utils/elements";
import { Session } from "src/base/store/authSlice";

const SellerPage = () => {
  const { trans } = useEBSSSLayout();
  const getSeller = useParams();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { products, categories, seller, loaded, appSettings, fetchSeller } = useEBSSS();
  const [page, setPage] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [filterSize, setFilterSize] = useState(undefined);
  const [stepSize, setStepSize] = useState(0);
  const [values, setValues] = useState([1, 100]);
  const [filterPrice, setFilterPrice] = useState({
    min: 0,
    max: undefined,
  });

  useEffect(() => {
    if (!!products && Array.isArray(products?.data) && products?.data?.length) {
      let filtersPriceMinMax = {
        ...filterPrice,
        max: Math.ceil(Number(products?.data?.sort((a, b) => Number(b.price) - Number(a.price))[0]?.price)),
      };
      setFilterPrice({
        ...filtersPriceMinMax,
      });
      setStepSize(
        //@ts-ignore
        String(products?.data?.sort((a, b) => Number(b.price) - Number(a.price))[0]?.price ?? 0) / 20
      );
      setValues([filtersPriceMinMax.min, filtersPriceMinMax.max]);
    }
  }, [products?.data]);

  useEffect(() => {
    let actualParams = getQueryParams(window.location.href);
    if (actualParams?.page) {
      setPage((p) => {
        if (p !== null) {
          return p;
        } else {
          return Number(actualParams?.page);
        }
      });
    }
  }, [window.location.search]);

  useEffect(() => {
    scrollUpPage();
    if (products?.data) {
      if (page && page > 0) {
        fetchSeller(getSeller?.slug, page);
      }
    }
  }, [page]);

  useEffect(() => {
    scrollUpPage();
    fetchSeller(getSeller?.slug, 1);
  }, []);

  return (
    <Page breads={[]} hideTitle hideBreadcrumbs>
      <div className="d-flex flex-column pt-4">
        <div className="w-100 p-4 mb-3" style={CSS({ height: 200, backgroundColor: "#EEEEEE" })}>
          <h3>{appSettings?.seller_fullname}</h3>
        </div>
        <Fragment>
          <Container fluid={true} className="product-wrapper">
            <div className="product-grid mb-3">
              <ProductFeatures
                values={values}
                setValues={setValues}
                categories={categories}
                sortBy={sortBy}
                setSortBy={setSortBy}
                filterSize={filterSize}
                setFilterSize={setFilterSize}
                stepSize={stepSize}
                setStepSize={setStepSize}
                filterPrice={filterPrice}
                setFilterPrice={setFilterPrice}
                basePath={"/crm/seller/" + getSeller?.slug}
                categoryPath={"/crm/seller/" + getSeller?.slug + "/category"}
                searchPath={"/crm/seller/" + getSeller?.slug + "/search"}
              />
              {!loaded ? (
                <div className="d-flex flex-row flex-wrap">
                  <SkeletonLoader isLoading={!loaded} />
                </div>
              ) : (
                <ProductGrid perLine={Number(appSettings?.columns)} products={products} trans={trans} values={values} sortBy={sortBy} filterSize={filterSize} />
              )}
              <EBSSSPagination page={page} data={products} />
            </div>
          </Container>
        </Fragment>
      </div>
    </Page>
  );
};

export default SellerPage;
