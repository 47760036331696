import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AnimationThemeContext from "src/_helper/AnimationTheme";
import { scrollUpPage } from "src/_utils/elements";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { proceedAfterAcceptTerms, proceedAfterRenewTerms, Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import Footer from "src/Layout/Footer";
import Header from "src/Layout/Header";
import SideBarLayout from "src/Layout/SideBar-Layout";
import TapTop from "src/Layout/TapTop";
import Themecustomizer from "src/Layout/ThemeCustomizer";
import { default as CheckContext, default as CustomContext } from "../../_helper/customizer";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ConfigDB from "src/Config/Theme-Config";
import { Layout } from "src/base/store/layoutSlice";
import EBSSSCheckbox from "src/base/atoms/EBSSSCheckbox";

const TermsPage = ({ startOn, assignsNeed, setNeverSigned }: { startOn?: number; setNeverSigned?: (v) => void; assignsNeed?: string[] }) => {
  const { TOS, POS, fetchTOS, fetchPOS, agreeWithTerms } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const [goToTerm, setGoToTerm] = useState<any>(startOn ? startOn : 0);
  const dispatch = useDispatch();
  const [checkRead, setCheckRead] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const { sidebar_types, settings, toggleIcon, setToggleIcon, defaultClass, setDefaultClass } = useContext<any>(CheckContext);
  const { setIsVertical } = useContext<any>(CustomContext);
  const settings1 = localStorage.getItem("sidebar_Settings") || settings;
  const sidebar_types1 = localStorage.getItem("sidebar_types") || sidebar_types;
  const location = useLocation();
  const { animation } = useContext<any>(AnimationThemeContext);
  const animationTheme = localStorage.getItem("animation") || animation || ConfigDB.data.router_animation;
  const nodeRef = useRef(null);
  window.addEventListener("resize", () => {
    if (window.innerWidth - 440 <= 575) {
      setToggleIcon(true);
    } else {
      setToggleIcon(false);
    }
    if (window.innerWidth <= 1200) {
      setIsVertical(true);
    } else {
      setIsVertical(false);
    }
    if (window.innerWidth <= 992) {
      setDefaultClass(true);
    } else setDefaultClass(false);
  });

  console.log("Assigs needed from terms page: ", assignsNeed);

  useEffect(() => {
    fetchTOS();
    fetchPOS();
  }, []);

  useEffect(() => {
    scrollUpPage();
    setCheckRead(false);
  }, [goToTerm]);

  useEffect(() => {
    if (goToTerm === 2) {
      setGoToTerm("next");
      if (setNeverSigned) {
        dispatch(proceedAfterAcceptTerms());
        setNeverSigned(false);
      } else {
        dispatch(proceedAfterRenewTerms());
      }
    }
  }, [goToTerm]);

  useEffect(() => {
    if (!TOS && POS) {
      setGoToTerm(1);
    }
  }, [TOS, POS]);

  const accept = async (tkn: string) => {
    if (loadingAccept) {
      return;
    }
    setLoadingAccept(true);

    let getAlreadySignedTerms = JSON.parse(localStorage.getItem("TEMP_TERM_SIGNED") ?? "[]");
    getAlreadySignedTerms.push({
      term: {
        type: [TOS, POS][goToTerm]?.terms?.type,
        version_no: [TOS, POS][goToTerm]?.terms?.version_no,
      },
      updated_at: new Date().getTime(),
      recent: true,
    });

    try {
      await agreeWithTerms(tkn).then((res) => {
        if (res?.user_service_term_condition) {
          console.log(res);

          localStorage.setItem("TEMP_TERM_SIGNED", JSON.stringify(getAlreadySignedTerms));

          if (
            (assignsNeed && assignsNeed.length === 1 && assignsNeed.includes("terms_conditions")) ||
            (assignsNeed && assignsNeed.length === 1 && assignsNeed.includes("privacy_policy"))
          ) {
            console.log("Already signed before and its just renewing");
            setGoToTerm("next");
            dispatch(proceedAfterRenewTerms());
            return;
          }

          if (TOS && POS) {
            setGoToTerm(goToTerm + 1);
            return;
          }

          if ((TOS && !POS) || (!TOS && POS)) {
            setGoToTerm("next");
            dispatch(proceedAfterAcceptTerms());
          }
        } else {
          toast(trans("website.an_unexpected_error_occurs"));
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingAccept(false);
    }
  };

  useEffect(() => {
    return () => {
      setGoToTerm(0);
    };
  }, []);

  const strictLayout = window.location.href.includes("v2/checkout") || window.location.href.includes("v2/answer");

  return TOS || POS ? (
    goToTerm === "next" ? (
      <Navigate to={process.env.PUBLIC_URL + "/dashboard"} />
    ) : (
      <Fragment>
        <TapTop />
        <div className={`page-wrapper ${!defaultClass ? sidebar_types1 : "compact-wrapper"} ${settings1}`} id="pageWrapper">
          <div
            className={`page-header ${toggleIcon ? "close_icon" : ""} no-print-ebsss ${
              strictLayout || !sessionNow?.user?.id ? "ebsss-hide-margin-left-unauthed-top" : ""
            }`}
          >
            <Header strictLayout={strictLayout} />
          </div>
          <div className={`page-body-wrapper ${!sessionNow?.user?.id ? "mt-5" : ""}`}>
            {strictLayout || !sessionNow?.user?.id ? (
              <></>
            ) : (
              <div className={`sidebar-wrapper ${toggleIcon ? "close_icon" : ""} no-print-ebsss`}>
                <div>
                  <SideBarLayout />
                </div>
              </div>
            )}
            <div
              className={`page-body no-margin-print-ebsss ${strictLayout || !sessionNow?.user?.id ? "ml-0" : ""}`}
              style={strictLayout || !sessionNow?.user?.id ? { marginLeft: 0 } : {}}
              ref={nodeRef}
            >
              <TransitionGroup>
                <CSSTransition key={location.key} timeout={100} classNames={animationTheme} nodeRef={nodeRef} unmountOnExit>
                  <div>
                    <Page
                      breads={[
                        {
                          name: [TOS, POS][goToTerm]?.terms?.type.includes("privacy")
                            ? trans("website.menu-privacy-policy")
                            : trans("website.menu-terms-conditions"),
                          active: true,
                        },
                      ]}
                    >
                      <div className="d-flex flex-column mt-3 container mb-3">
                        <h3 className="text-center">
                          {[TOS, POS][goToTerm]?.terms?.type.includes("privacy")
                            ? trans("website.menu-privacy-policy")
                            : trans("website.menu-terms-conditions")}
                        </h3>
                        <h4 className="text-center">
                          {trans("website.version-no")} {[TOS, POS][goToTerm]?.terms?.version_no}
                        </h4>
                        <h3 className="text-center">{[TOS, POS][goToTerm]?.terms?.published_at}</h3>
                        <div
                          className="p-3 ebsss-terms-points"
                          dangerouslySetInnerHTML={{
                            __html: [TOS, POS][goToTerm]?.termTranslations?.content,
                          }}
                        ></div>
                        <div className="ms-3 mb-4">
                          <EBSSSCheckbox
                            selected={checkRead}
                            id="agreement"
                            label={trans("website.agree-term")}
                            action={(e) => {
                              console.log("agreement box");
                              setCheckRead(!checkRead);
                            }}
                          />
                        </div>
                        {checkRead ? (
                          <button className="btn btn-primary w-50 m-auto mb-3" onClick={() => accept([TOS, POS][goToTerm]?.terms?.token)}>
                            {trans("website.agree-term")}
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Page>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </div>
            <Footer strictLayout={strictLayout || !sessionNow?.user?.id} />
          </div>
        </div>
        <Themecustomizer />
        <ToastContainer />
      </Fragment>
    )
  ) : (
    <></>
  );
};

export default TermsPage;
