//@ts-nocheck
import { Link } from "react-router-dom";
import { Image } from "../../AbstractElements";

const SidebarLogo = ({ logo, alt }) => {
  return (
    <div className="logo-icon-wrapper no-print-ebsss">
      <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
        <Image
          attrImage={{
            className: "img-fluid for-dark",
            src: `${logo}`,
            alt: alt,
          }}
        />
      </Link>
    </div>
  );
};

export default SidebarLogo;
