//@ts-nocheck
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { H4, Image } from "../../AbstractElements";
import sidebar from "../../assets/images/side-bar.png";
import { BuyNow } from "../../Constant";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { Layout } from "src/base/store/layoutSlice";
import { ReduxDataKey } from "src/base/store/store";
import { useSelector } from "react-redux";

const SidebarSection = () => {
  const { trans } = useEBSSSLayout();
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  return (
    <Fragment>
      {ebsssLayout?.commonAppSettings?.hidden_faq ? (
        <></>
      ) : (
        <div className="sidebar-img-section no-print-ebsss px-3">
          <div className="sidebar-img-content pt-4">
            <H4>{trans("website.do-you-need-help")}</H4>
            <p className="txt">{trans("website.check-our-faq")}</p>
            <Link to={process.env.PUBLIC_URL + "/faq"} className="btn btn-secondary">
              {trans("website.go-help")}
            </Link>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default SidebarSection;
