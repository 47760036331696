import { CSS } from "src/_utils/styles";
import TooltipToggle from "../TooltipToggle";

const EBSSSTooltip = ({ title }: { title: string }) => {
  return (
    <TooltipToggle
      title={title}
      id={0}
      style={CSS({
        display: "flex",
        flexDirection: "row",
        position: "relative",
      })}
    >
      <i className="la la-info-circle fs-6"></i>
    </TooltipToggle>
  );
};

export default EBSSSTooltip;
