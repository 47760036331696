import { Fragment, useEffect } from "react";

import { Outlet, useLocation } from "react-router-dom";

import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { getQueryParams } from "src/_utils/urls";
import { scrollUpPage } from "src/_utils/elements";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";

const CMSContainer = ({ hideLayout, children, classNames, ...rest }) => {
  const { htmlPage, fetchHtmlPage, setProduct } = useEBSSS();
  const { setCMSSnapshot } = useEBSSSLayout();
  const location = useLocation();

  const resetWindowProductState = () => {
    window["PRODUCT_PAYMENT_TYPE"] = [];
    window["PRODUCT_EXTRAS"] = [];
  };

  useEffect(() => {
    if (typeof document !== "undefined") {
      console.log(window.location.origin + location.pathname + location.search);
      setProduct(null);
      let paramsFromUrl = getQueryParams(window.location.origin + location.pathname + location.search);
      let pathnameWithoutVersion = location.pathname.split("/v2/")[1];
      resetWindowProductState();
      fetchHtmlPage({ page: pathnameWithoutVersion, queryParams: paramsFromUrl })
        .then(scrollUpPage)
        .finally(() => {
          console.log("Loaded raw HTML from API");
          localStorage.setItem(window.location.host.toUpperCase() + "_ACTUAL_URL", location.pathname + location.search);
          window.dispatchEvent(
            new StorageEvent("storage", {
              key: window.location.host.toUpperCase() + "_ACTUAL_URL",
              newValue: location.pathname + location.search,
              storageArea: localStorage,
            })
          );
        });
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    setCMSSnapshot(htmlPage);
  }, [htmlPage]);

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};
export default CMSContainer;
