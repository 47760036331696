import { authRoutes } from "src/Routes/AuthRoutes";

export const buildQueryParamUrl = (params, getFromActualUrl = false) => {
  const queryParams = Object.entries(params)
    .filter(([key, value]) => value !== null && value !== undefined)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as unknown as any)}`)
    .join("&");

  return `${queryParams ? (getFromActualUrl && window.location.href.includes("?") ? "&" : "?") + queryParams : ""}`;
};

export const getQueryParams: (u?: string, queried?: boolean) => Record<string, any> = (url?: string, queried: boolean = false) => {
  let params = {};

  // Use URL and URLSearchParams to parse the URL
  let queryString = "";
  if (!queried) {
    queryString = new URL(url).search;
  }
  let urlParams = new URLSearchParams(queried ? url : queryString);

  // Iterate over the URLSearchParams object to construct the params object
  for (let [key, value] of urlParams) {
    params[key] = value;
  }

  return params;
};

export const isActualRouteProtected = (): boolean => {
  return authRoutes.map((r) => r.path).some((pg) => window.location.href.includes(pg));
};

export const isActualRoutePublic = (templatePages): boolean => {
  const EXCEPTIONS = [
    "v2/crm/home",
    "v2/form/",
    "v2/answer",
    "v2/crm/products",
    "v2/terms-conditions",
    "v2/privacy-policy",
    "v2/page",
    "v2/faq",
    "v2/crm/contract-payment/",
  ].concat(templatePages?.map((pgs) => process.env.PUBLIC_URL.replace("/", "") + "/" + pgs.slug));
  console.log("Public pages: ", EXCEPTIONS);
  return EXCEPTIONS.some((excp) => window.location.href.includes(excp));
};

export const removeBeforeFirstSlash = (inputString) => {
  // Find the position of the first slash
  let index = inputString.indexOf("/");

  // If a slash is found, remove everything before and including the first slash
  if (index !== -1) {
    return inputString.substring(index + 1);
  }

  // If no slash is found, return the original string
  return inputString;
};
