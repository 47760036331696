import { ListGroupItem } from "reactstrap";

const LI = (props) => {
  const { children = "", val = "" } = props;
  return (
    <ListGroupItem {...props.attrLI}>
      {val ? <div dangerouslySetInnerHTML={{ __html: val }} /> : ""} {children}
    </ListGroupItem>
  );
};

export default LI;
