import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import TermsPage from "src/Pages/Terms/TermsPage";

const MiddlewareTermsRoute = () => {
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const [toAssign, setToAssign] = useState([]);
  const [neverSigned, setNeverSigned] = useState(false);

  useEffect(() => {
    let checkAssign = [];

    console.log("signeds: ", sessionNow?.user?.user_service_term_conditions);

    if (sessionNow?.user?.user_service_term_conditions?.length === 0) {
      setNeverSigned(true);
      return;
    }

    // Verifica se o user_service_term_conditions existe antes de filtrar
    let checkTOS = sessionNow?.user?.user_service_term_conditions?.filter(
      (uspTOS) => typeof uspTOS === "object" && uspTOS.term && uspTOS.term?.type.includes("terms_conditions")
    );
    let checkLastTOSAssign = checkTOS?.[checkTOS.length - 1];

    let checkPOS = sessionNow?.user?.user_service_term_conditions?.filter(
      (uspPOS) => typeof uspPOS === "object" && uspPOS.term && uspPOS.term?.type.includes("privacy_policy")
    );
    let checkLastPOSAssign = checkPOS?.[checkPOS.length - 1];

    let allChecks = [];
    if (checkLastTOSAssign) {
      allChecks.push(checkLastTOSAssign);
    }
    if (checkLastPOSAssign) {
      allChecks.push(checkLastPOSAssign);
    }

    // Certifica-te de que signAt não é undefined antes de aceder às suas propriedades
    allChecks?.forEach((signAt) => {
      if (signAt && signAt.term && signAt.updated_at) {
        console.log(
          signAt.term.type,
          "\n",
          "Signed in: ",
          new Date(signAt.updated_at).toLocaleDateString() + " " + new Date(signAt.updated_at).toLocaleTimeString(),
          "Updated in: ",
          new Date(signAt.term.updated_at).toLocaleDateString() + " " + new Date(signAt.term.updated_at).toLocaleTimeString()
        );
        if (
          new Date(signAt.updated_at).getTime() < new Date(signAt.term.updated_at).getTime() ||
          new Date(signAt.updated_at).getTime() < new Date(signAt.term.created_at).getTime()
        ) {
          checkAssign.push(signAt.term.type);
        }
      }
    });

    console.log("ALL CHECKS: ", allChecks);
    console.log("ALL ASSINGS: ", checkAssign);

    setToAssign(checkAssign);
  }, [sessionNow?.user?.user_service_term_conditions]);

  return !neverSigned &&
    (toAssign?.length === 0 ||
      sessionNow?.user?.newly_accepted_terms === "true" ||
      sessionNow?.user?.renew_accepted_terms === "true" ||
      sessionNow?.user?.user_service_term_conditions?.every((v) => typeof v === "string" && v === "signed")) ? (
    <Outlet />
  ) : !neverSigned && toAssign?.length === 1 && toAssign.includes("terms_conditions") ? (
    <TermsPage startOn={0} assignsNeed={[...toAssign]} />
  ) : !neverSigned && toAssign?.length === 1 && toAssign.includes("privacy_policy") ? (
    <TermsPage startOn={1} assignsNeed={[...toAssign]} />
  ) : (
    <TermsPage startOn={0} setNeverSigned={setNeverSigned} />
  );
};

export default MiddlewareTermsRoute;
