import { Fragment } from "react";
import { Home } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";
import H3 from "../Headings/H3Element";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { CSS } from "src/_utils/styles";

const Breadcrumbs = (props) => {
  const { title = "", parent = "", hideTitle = false } = props;
  const navigate = useNavigate();
  const { loaded } = useEBSSS();
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            {!hideTitle ? (
              <Col xs="12" sm="6">
                <H3>{title}</H3>
              </Col>
            ) : (
              <></>
            )}
            {loaded && !props.hideBreadcrumbs ? (
              <Col xs="12" sm={hideTitle ? "12" : "6"}>
                <div className="breadcrumb">
                  <Breadcrumb>
                    <BreadcrumbItem onClick={() => navigate(`${process.env.PUBLIC_URL}/dashboard`)} style={CSS({ cursor: "pointer" })}>
                      <Home />
                    </BreadcrumbItem>
                    {props.breads ? (
                      props.breads.map((b) => {
                        return (
                          <BreadcrumbItem
                            active={!b.active}
                            onClick={b?.url ? () => navigate(process.env.PUBLIC_URL + b.url) : null}
                            style={b?.url ? CSS({ cursor: "pointer" }) : {}}
                          >
                            {b.name}
                          </BreadcrumbItem>
                        );
                      })
                    ) : (
                      <>
                        <BreadcrumbItem>{parent}</BreadcrumbItem>
                        <BreadcrumbItem active>{title}</BreadcrumbItem>
                      </>
                    )}
                  </Breadcrumb>
                </div>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
