import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { isActualRoutePublic } from "src/_utils/urls";
import { Session } from "src/base/store/authSlice";
import { Layout } from "src/base/store/layoutSlice";
import { ReduxDataKey } from "src/base/store/store";

const MiddlewareEnabledRoute = () => {
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);

  // if (window.location.href.includes("/crm/products")) {
  //   if (
  //     !sessionNow?.user?.id &&
  //     !ebsssLayout?.commonAppSettings?.public_products
  //   ) {
  //     return <Navigate to={`${process.env.PUBLIC_URL}/auth/sign-in`} />;
  //   }
  // }

  // if (window.location.href.includes("/crm/contract-payment/")) {
  //   console.log("cont pay login");
  //   if (
  //     !sessionNow?.user?.id &&
  //     ebsssLayout?.commonAppSettings
  //       ?.billing_payment_request_verify_user_to_show
  //   ) {
  //     console.log("cont pay login redir");

  //     return <Navigate to={`${process.env.PUBLIC_URL}/auth/sign-in`} />;
  //   }
  // }

  // if (window.location.pathname === "/") {
  //   return <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />;
  // }

  return <Outlet />;
};
export default MiddlewareEnabledRoute;
