import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Layout } from "src/base/store/layoutSlice";
import { ReduxDataKey } from "src/base/store/store";

const MiddlewareVersionRoute = () => {
  const location = useLocation();
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);

  if (location.pathname === "/" || location.pathname === process.env.PUBLIC_URL || location.pathname === process.env.PUBLIC_URL + "/") {
    let hasDefinedInitialPage = ebsssLayout?.template?.pages?.find((lg: { type: string }) => lg.type === "ini_page");
    return <Navigate to={process.env.PUBLIC_URL + "/" + (hasDefinedInitialPage && hasDefinedInitialPage.slug !== "/" ? hasDefinedInitialPage?.slug : "")} />;
  }

  return location.pathname.startsWith(process.env.PUBLIC_URL) ? (
    <Outlet />
  ) : (
    <Navigate to={`${process.env.PUBLIC_URL}${location.pathname}${location.search}`} />
  );
};
export default MiddlewareVersionRoute;
