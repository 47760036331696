//@ts-nocheck
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { Btn } from "../../../../AbstractElements";
import EBSSSButton from "src/base/atoms/EBSSSButton";

const CommonModal = (props) => {
  const { trans } = useEBSSSLayout();
  console.log("HAS LOADING KEY SETTED?: ", typeof props.loadingSubmitKey === "boolean");
  return (
    <Modal
      style={props.style ? props.style : {}}
      modalClassName={props.modalSize}
      isOpen={props.isOpen}
      toggle={props.dismissable ? props.toggler : () => {}}
      size={props.size}
      centered
    >
      {props.dismissable ? (
        <ModalHeader toggle={props.dismissable ? props.toggler : () => {}}>{props.title}</ModalHeader>
      ) : (
        <div class="modal-header">
          <h5 class="modal-title">{props.title}</h5>
        </div>
      )}
      <ModalBody className={props.bodyClass}>{props.children}</ModalBody>
      {!props.hideFooter ? (
        <ModalFooter>
          <Btn
            attrBtn={{
              color: "light",
              onClick: props.dismissable ? props.toggler : () => {},
            }}
          >
            {props.customCloseButtonText ? props.customCloseButtonText : trans("common.close")}
          </Btn>
          {!props.hideSubmitButton ? (
            <EBSSSButton
              loadingKey={props.loadingSubmitKey}
              type="button"
              color="primary"
              onClick={async () => {
                if (props.asyncToggle) {
                  await props.customConfirmAction().then(() => {
                    if (props.dismissable) {
                      props.toggler();
                    }
                  });
                } else {
                  props.customConfirmAction();
                  if (props.dismissable) {
                    props.toggler();
                  }
                }
              }}
            >
              {props.customConfirmButtonText ? props.customConfirmButtonText : trans("common.send")}
            </EBSSSButton>
          ) : (
            <></>
          )}
        </ModalFooter>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default CommonModal;
