import { Fragment, useEffect, useState } from "react";
import { Calendar, Globe, Lock, Mail, Map, MapPin, Phone, User } from "react-feather";
import { FaCity, FaGlobe, FaIdCard, FaTransgender } from "react-icons/fa";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { getCountryCode, updatePostCodeMask } from "src/_utils/validations";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import EBSSSProfileHeader from "src/base/atoms/EBSSSProfileHeader";
import EBSSSProfileOptions from "src/base/atoms/EBSSSProfileOptions";
import EBSSSProfileTabHeader from "src/base/atoms/EBSSSProfileTabHeader";
import EBSSSTooltip from "src/base/atoms/EBSSSTooltip";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import FeedbackMessages from "src/base/FeedbackMessage";
import Page from "src/base/Page";
import { cleanErrorsAndMessages, editAvatar, editPassword, editProfile, Session } from "src/base/store/authSlice";
import { Layout } from "src/base/store/layoutSlice";
import { ReduxDataAuthKey, ReduxDataKey } from "src/base/store/store";
import InputMask from "react-input-mask";

const ContainerUserData = ({ sessionNow, data, ebsssLayout, setData, handleSubmit }) => {
  const { trans } = useEBSSSLayout();
  return (
    <Fragment>
      <Col xl="8" lg="12" md="7" className="xl-65">
        <Row className="row">
          <Col sm="12">
            <Card>
              <div className="profile-post">
                <EBSSSProfileTabHeader title={trans("profile.my-profile")} h6={trans("profile.explanation_my_profile")} />
                <div className="post-body">
                  <Form className="theme-form login-form bg-transparent text-muted">
                    <div className="row">
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label>{trans("profile.first_name")}: *</Label>
                          <InputGroup>
                            <InputGroupText>
                              <User />
                            </InputGroupText>
                            <Input
                              type="text"
                              required
                              defaultValue={data.first_name}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  first_name: event.target.value,
                                })
                              }
                            />
                          </InputGroup>
                          <FeedbackMessages messages={sessionNow?.errors?.profile["first_name"]} />
                        </FormGroup>
                      </div>
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label>{trans("profile.last_name")}: *</Label>
                          <InputGroup>
                            <InputGroupText>
                              <User />
                            </InputGroupText>
                            <Input
                              type="text"
                              required
                              defaultValue={data.last_name}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  last_name: event.target.value,
                                })
                              }
                            />
                          </InputGroup>
                          <FeedbackMessages messages={sessionNow?.errors?.profile["last_name"]} />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label style={{ display: "flex", flexDirection: "row" }}>
                            {trans("profile.mobile")}: * <EBSSSTooltip title={trans("website.mobile_number")} />
                          </Label>
                          <InputGroup>
                            <InputGroupText>
                              <Phone />
                            </InputGroupText>
                            <PhoneInput
                              className="col"
                              inputStyle={CSS({
                                width: "100%",
                                height: 45,
                                display: "flex",
                              })}
                              countrySelectorStyleProps={{
                                dropdownStyleProps: {
                                  style: CSS({
                                    zIndex: 999999,
                                  }),
                                },
                                buttonStyle: CSS({
                                  height: 45,

                                  backgroundColor: "#EFEFFD",
                                  border: 0,
                                }),
                              }}
                              defaultCountry={getCountryCode()}
                              value={data.mobile_number}
                              onChange={(phone) =>
                                setData({
                                  ...data,
                                  mobile_number: phone.replace("+", ""),
                                })
                              }
                            />
                          </InputGroup>
                          <FeedbackMessages messages={sessionNow?.errors?.profile["mobile_number"]} />
                        </FormGroup>
                      </div>
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label>{trans("profile.vat_number")}:</Label>
                          <InputGroup>
                            <InputGroupText>
                              <FaIdCard />
                            </InputGroupText>
                            <Input
                              type="text"
                              defaultValue={data.vat_number}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  vat_number: event.target.value,
                                })
                              }
                            />
                          </InputGroup>
                          <FeedbackMessages messages={sessionNow?.errors?.profile["vat_number"]} />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label>{trans("profile.date_of_birth")}:</Label>
                          <InputGroup>
                            <InputGroupText>
                              <Calendar />
                            </InputGroupText>
                            <Input
                              type="date"
                              defaultValue={data.date_of_birth}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  date_of_birth: event.target.value,
                                })
                              }
                            />
                          </InputGroup>
                          <FeedbackMessages messages={sessionNow?.errors?.profile["date_of_birth"]} />
                        </FormGroup>
                      </div>
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label>{trans("profile.gender")}:</Label>
                          <InputGroup style={CSS({ height: 46 })}>
                            <InputGroupText>
                              <FaTransgender />
                            </InputGroupText>
                            <Input
                              type="select"
                              defaultValue={data.gender}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  gender: event.target.value,
                                })
                              }
                            >
                              <option value="male">{trans("common.gender_male")}</option>
                              <option value="female">{trans("common.gender_female")}</option>
                              <option value="other">{trans("common.other")}</option>
                            </Input>
                          </InputGroup>
                          <FeedbackMessages messages={sessionNow?.errors?.profile["gender"]} />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="col-12">
                      <FormGroup>
                        <Label>{trans("profile.language")}:</Label>
                        <InputGroup style={CSS({ height: 46 })}>
                          <InputGroupText>
                            <FaGlobe />
                          </InputGroupText>
                          <Input
                            type="select"
                            defaultValue={ebsssLayout?.languages?.find((tlang) => tlang.code?.toLowerCase() === ebsssLayout?.activeLanguage?.toLowerCase())?.id}
                            onChange={(event) =>
                              setData({
                                ...data,
                                language_id: event.target.value,
                              })
                            }
                          >
                            {ebsssLayout?.languages?.map((lan) => {
                              return (
                                <option key={lan.id} value={lan.id}>
                                  {lan.name}
                                </option>
                              );
                            })}
                          </Input>
                        </InputGroup>
                        <FeedbackMessages messages={sessionNow?.errors?.profile["language_id"]} />
                      </FormGroup>
                    </div>

                    <FormGroup>
                      <EBSSSButton loadingKey={ReduxDataAuthKey.profile} onClick={handleSubmit}>
                        Guardar
                      </EBSSSButton>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
};
const ContainerAddressData = ({ sessionNow, data, setData, handleSubmit, mask, countries }) => {
  const { trans } = useEBSSSLayout();

  return (
    <Fragment>
      <Col xl="8" lg="12" md="7" className="xl-65">
        <Row className="row">
          <Col sm="12">
            <Card>
              <div className="profile-post">
                <EBSSSProfileTabHeader title={trans("common.address")} h6={trans("common.explanation_address")} />
                <div className="post-body">
                  <Form className="theme-form login-form bg-transparent text-muted">
                    <div className="row">
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label>{trans("common.address")}:</Label>
                          <InputGroup>
                            <InputGroupText>
                              <MapPin />
                            </InputGroupText>
                            <Input
                              type="text"
                              required
                              defaultValue={data.address_line_1}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  address_line_1: event.target.value,
                                })
                              }
                            />
                          </InputGroup>
                          <FeedbackMessages messages={sessionNow?.errors?.profile["address_line_1"]} />
                        </FormGroup>
                      </div>
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label>
                            {" "}
                            <br />
                          </Label>
                          <InputGroup>
                            <InputGroupText>
                              <MapPin />
                            </InputGroupText>
                            <Input
                              type="text"
                              required
                              defaultValue={data.address_line_2}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  address_line_2: event.target.value,
                                })
                              }
                            />
                          </InputGroup>
                          <FeedbackMessages messages={sessionNow?.errors?.profile["address_line_2"]} />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label>{trans("common.address_locality")}</Label>
                          <InputGroup>
                            <InputGroupText>
                              <FaCity size={24} />
                            </InputGroupText>
                            <Input
                              type="text"
                              required
                              defaultValue={data.locality}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  locality: event.target.value,
                                })
                              }
                            />
                          </InputGroup>
                          <FeedbackMessages messages={sessionNow?.errors?.profile["locality"]} />
                        </FormGroup>
                      </div>
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label>{trans("common.address_postal_code")}</Label>
                          <InputGroup>
                            <InputGroupText>
                              <Mail />
                            </InputGroupText>
                            <InputMask
                              mask={mask}
                              maskChar={null}
                              id="card-address-postal-code"
                              name="card-address-postal-code"
                              type="text"
                              className="form-control"
                              placeholder={trans("website.cart-card-address-postal-code")}
                              required
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  postal_code: event.target.value,
                                })
                              }
                              defaultValue={data.postal_code}
                            >
                              {/* @ts-ignore */}
                              {(inputProps) => <Input {...inputProps} />}
                            </InputMask>
                          </InputGroup>
                          <FeedbackMessages messages={sessionNow?.errors?.profile["postal_code"]} />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label>{trans("common.address_administrative_area")}</Label>
                          <InputGroup>
                            <InputGroupText>
                              <Map />
                            </InputGroupText>
                            <Input
                              type="text"
                              required
                              defaultValue={data.administrative_area}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  administrative_area: event.target.value,
                                })
                              }
                            />
                          </InputGroup>
                          <FeedbackMessages messages={sessionNow?.errors?.profile["administrative_area"]} />
                        </FormGroup>
                      </div>
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label>{trans("common.address_country")}</Label>
                          <InputGroup style={CSS({ height: 46 })}>
                            <InputGroupText>
                              <Globe />
                            </InputGroupText>
                            <Input
                              type="select"
                              defaultValue={data.country_code}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  country_code: event.target.value,
                                })
                              }
                            >
                              {countries.map((ct) => {
                                return <option value={ct.code.toUpperCase()}>{ct.country}</option>;
                              })}
                            </Input>
                          </InputGroup>
                          <FeedbackMessages messages={sessionNow?.errors?.profile["country_code"]} />
                        </FormGroup>
                      </div>
                    </div>
                    <FormGroup>
                      <EBSSSButton loadingKey={ReduxDataAuthKey.profile} onClick={handleSubmit}>
                        {trans("common.save")}
                      </EBSSSButton>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
};
const ContainerPasswordData = ({ sessionNow, data, setData, handleSubmit, isPasswordVisible, toggle }) => {
  const { trans } = useEBSSSLayout();
  return (
    <Fragment>
      <Col xl="8" lg="12" md="7" className="xl-65">
        <Row className="row">
          <Col sm="12">
            <Card>
              <div className="profile-post">
                <EBSSSProfileTabHeader title={trans("profile.change_password")} h6={trans("profile.change_password_description")} />
                <div className="post-body">
                  <Form className="theme-form login-form bg-transparent text-muted">
                    <FormGroup>
                      <Label>{trans("profile.current_password")}</Label>
                      <InputGroup>
                        <InputGroupText>
                          <Lock />
                        </InputGroupText>
                        <Input
                          type={isPasswordVisible ? "text" : "password"}
                          required
                          defaultValue={data.password_old}
                          onChange={(event) =>
                            setData({
                              ...data,
                              password_old: event.target.value,
                            })
                          }
                        />

                        <div className="show-hide" onClick={toggle}>
                          <span className={!isPasswordVisible ? "show" : ""}></span>
                        </div>
                      </InputGroup>
                      <FeedbackMessages messages={sessionNow?.errors?.profile["password_old"]} />
                    </FormGroup>
                    <FormGroup>
                      <Label>{trans("profile.new_password")}</Label>
                      <InputGroup>
                        <InputGroupText>
                          <Lock />
                        </InputGroupText>
                        <Input
                          type={isPasswordVisible ? "text" : "password"}
                          required
                          defaultValue={data.password}
                          onChange={(event) =>
                            setData({
                              ...data,
                              password: event.target.value,
                            })
                          }
                        />

                        <div className="show-hide" onClick={toggle}>
                          <span className={!isPasswordVisible ? "show" : ""}></span>
                        </div>
                      </InputGroup>
                      <FeedbackMessages messages={sessionNow?.errors?.profile["password"]} />
                    </FormGroup>
                    <FormGroup>
                      <Label>{trans("profile.confirm_password")}</Label>
                      <InputGroup>
                        <InputGroupText>
                          <Lock />
                        </InputGroupText>
                        <Input
                          type={isPasswordVisible ? "text" : "password"}
                          required
                          defaultValue={data.password_confirmation}
                          onChange={(event) =>
                            setData({
                              ...data,
                              password_confirmation: event.target.value,
                            })
                          }
                        />

                        <div className="show-hide" onClick={toggle}>
                          <span className={!isPasswordVisible ? "show" : ""}></span>
                        </div>
                      </InputGroup>
                      <FeedbackMessages messages={sessionNow?.errors?.profile["password_confirmation"]} />
                    </FormGroup>
                    <FormGroup>
                      <EBSSSButton loadingKey={ReduxDataAuthKey.profile} onClick={handleSubmit}>
                        Guardar
                      </EBSSSButton>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
};

const UserProfilePage = () => {
  const dispatcher = useDispatch<any>();
  const [isPasswordVisible, setisPasswordVisible] = useState(false);
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const [selectedSection, setSelectedSection] = useState<"user" | "address" | "password">("user");

  const [credentials, setCredentials] = useState({
    //Address
    address_line_1: sessionNow?.user?.profile?.address_line_1 ?? "",
    address_line_2: sessionNow?.user?.profile?.address_line_2 ?? "",
    locality: sessionNow?.user?.profile?.locality ?? "",
    postal_code: sessionNow?.user?.profile?.postal_code ?? "",
    country_code: sessionNow?.user?.profile?.country_code ?? "pt",
    administrative_area: sessionNow?.user?.profile?.administrative_area ?? "",
    //Profile
    first_name: sessionNow?.user?.profile?.first_name ?? "",
    last_name: sessionNow?.user?.profile?.last_name ?? "",
    mobile_number: sessionNow?.user?.profile?.mobile_number ?? "",
    gender: sessionNow?.user?.profile?.gender ?? "male",
    language_id: sessionNow?.user?.profile?.language_id ?? 131,
    vat_number: sessionNow?.user?.profile?.vat_number ?? "",
    date_of_birth: sessionNow?.user?.profile?.date_of_birth?.substring(0, 10) ?? "",
  });

  const [password, setPassword] = useState({
    password: "",
    password_old: "",
    password_confirm: "",
  });

  const [mask, setMask] = useState("*********"); // Default mask

  useEffect(() => {
    updatePostCodeMask(credentials.country_code, setMask);
  }, [credentials.country_code]);

  const toggle = () => {
    setisPasswordVisible(!isPasswordVisible);
  };

  const handleSubmitProfile = (e) => {
    e.preventDefault();
    dispatcher(editProfile({ id: sessionNow?.user.id, form: credentials }));
  };

  const handleSubmitAddress = (e) => {
    e.preventDefault();
    dispatcher(editProfile({ id: sessionNow?.user.id, form: credentials }));
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
    dispatcher(editPassword({ id: sessionNow?.user.id, form: password }));
  };

  const handleSubmitAvatar = (avatar: string | Blob) => {
    let newFormData = new FormData();
    newFormData.append("avatar", avatar);
    dispatcher(editAvatar({ id: sessionNow?.user.id, formData: newFormData }));
  };

  useEffect(() => {
    if (sessionNow?.message.profile && Object.keys(sessionNow?.errors.profile).length === 0) {
      toast(sessionNow?.message.profile);
      dispatcher(cleanErrorsAndMessages());
    }
  }, [sessionNow?.message.profile]);

  useEffect(() => {
    dispatcher(cleanErrorsAndMessages());
  }, []);

  return (
    <Page breads={[{ name: "Perfil", active: true }]}>
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <EBSSSProfileHeader user={sessionNow?.user} handleSubmitAvatar={handleSubmitAvatar} />
            <Col xl="4" md="5" lg="12" className="xl-35">
              <div className="default-according style-1 faq-accordion job-accordion">
                <Row>
                  <EBSSSProfileOptions user={sessionNow?.user} selectedSection={selectedSection} setSelectedSection={setSelectedSection} />
                </Row>
              </div>
            </Col>
            {selectedSection === "user" ? (
              <ContainerUserData
                sessionNow={sessionNow}
                data={credentials}
                ebsssLayout={ebsssLayout}
                setData={setCredentials}
                handleSubmit={(e) => handleSubmitProfile(e)}
              />
            ) : selectedSection === "address" ? (
              <ContainerAddressData
                mask={mask}
                sessionNow={sessionNow}
                data={credentials}
                setData={setCredentials}
                handleSubmit={(e) => handleSubmitAddress(e)}
                countries={ebsssLayout.countries}
              />
            ) : (
              <ContainerPasswordData
                sessionNow={sessionNow}
                data={password}
                setData={setPassword}
                handleSubmit={(e) => handleSubmitPassword(e)}
                isPasswordVisible={isPasswordVisible}
                toggle={toggle}
              />
            )}
          </Row>
        </div>
      </Container>
    </Page>
  );
};
export default UserProfilePage;
