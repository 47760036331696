import { Fragment, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { FaUpload } from "react-icons/fa";
import { Card, Col, Form } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { H4, H6, Image, LI, UL } from "src/AbstractElements";
import { remotePath } from "src/base/settings/Paths";
import EBSSSModal from "./EBSSSModal";
import EBSSSInitialNameProfile from "./EBSSSInitialNameProfile";

const EBSSSProfileHeader = ({ user, handleSubmitAvatar }) => {
  const [modal, setModal] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const toggle = () => {
    setModal(!modal);
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(meta, file, status);
    setAvatarFile(file);
  };

  const handleSubmit = () => {
    handleSubmitAvatar(avatarFile);
  };

  console.log(user);

  return (
    <Fragment>
      <Col sm="12">
        <Card
          className="profile-header bg-image"
          style={{
            backgroundImage: `url(https://www.zembula.com/wp-content/uploads/2019/10/header-background-01.png)`,
            /*backgroundImage: `url(${
              remotePath.assetsUrl(user.profile.avatar)
            })`,*/
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "block",
          }}
        >
          <div className="profile-img-wrrap">
            <Image
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "block",
              }}
              attrImage={{
                className: "img-fluid bg-img-cover",
                src: ``,
                alt: "",
              }}
            />
          </div>
          <div className="userpro-box">
            <div className="img-wrraper">
              <div className="avatar">
                <EBSSSModal
                  customConfirmAction={handleSubmit}
                  modalTitle="Edição de avatar"
                  modal={modal}
                  toggle={toggle}
                  triggerEl={
                    user?.profile?.avatar ? (
                      <Image
                        attrImage={{
                          onClick: toggle,
                          className: "img-fluid",
                          style: CSS({ cursor: "pointer" }),
                          alt: "",
                          src: `${remotePath.assetsUrl(user.profile.avatar)}`,
                        }}
                      />
                    ) : (
                      <EBSSSInitialNameProfile user={user} onClick={toggle} />
                    )
                  }
                >
                  <Form>
                    <div className="dz-message needsclick">
                      <Dropzone
                        onChangeStatus={handleChangeStatus}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        inputContent={<FaUpload />}
                        styles={{
                          dropzoneActive: { borderColor: "green" },
                        }}
                      />
                    </div>
                  </Form>
                </EBSSSModal>
              </div>
            </div>
            <div className="user-designation">
              <div className="title">
                <a target="_blank" href="#javascript">
                  <H4>
                    {user.profile.first_name} {user.profile.last_name}
                  </H4>
                  <H6>{user.email}</H6>
                </a>
              </div>
              {/* <div className="social-media">
                <UL
                  attrUL={{
                    className: "simple-list user-list-social flex-row",
                  }}
                >
                  <LI>
                    <a href={user?.profile?.facebook}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </LI>
                  <LI>
                    <a href={user?.profile?.linkedin}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </LI>
                  <LI>
                    <a href={user?.profile?.twitter}>
                      <i className="fa fa-x-twitter"></i>
                    </a>
                  </LI>
                  <LI>
                    <a href={user?.profile?.instagram}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </LI>
                  <LI>
                    <a href={"skype:" + user?.profile?.skype}>
                      <i className="fa fa-skype"></i>
                    </a>
                  </LI>
                </UL>
              </div> */}
            </div>
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};
export default EBSSSProfileHeader;
