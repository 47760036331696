//@ts-nocheck
import { Fragment } from "react";
import { Bell, X } from "react-feather";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Media } from "reactstrap";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import { H3, H5, LI, P, UL } from "../../../AbstractElements";
import { HeaderNotificationSvg } from "../../../Data/svgIcons";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";

const Notification = () => {
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { removeNotifications } = useEBSSS();
  const { trans } = useEBSSSLayout();
  return (
    <Fragment>
      <LI attrLI={{ className: "onhover-dropdown" }}>
        <div className="notification-box">
          <Link to={process.env.PUBLIC_URL + "/crm/notifications"}>
            <HeaderNotificationSvg />
          </Link>
          <span className="badge rounded-pill badge-warning">
            {sessionNow.user["notifications_count"]}
          </span>
        </div>
        <div className={`onhover-show-div notification-dropdown`}>
          <div className="dropdown-title">
            <H3>{trans("website.notifications")}</H3>
            <a className="f-right" href="#javascript">
              <Bell />
            </a>
          </div>
          <UL attrUL={{ className: "simple-list custom-scrollbar" }}>
            {sessionNow?.user?.notifications?.map((item) => {
              return (
                <LI key={item.id}>
                  <Media>
                    <div
                      className={`notification-img d-flex ms-2 ${item.class}`}
                    >
                      <i className="fa fa-exclamation m-auto"></i>
                    </div>
                    <Media body>
                      <H5>
                        <Link
                          to={`${process.env.PUBLIC_URL}/users/userprofile`}
                          className="f-14 m-0"
                        >
                          {item.subject}
                        </Link>
                      </H5>
                      <P>{item.desp}</P>
                      <span>{item.created_at}</span>
                    </Media>
                    <div className="notification-right">
                      <span onClick={() => removeNotifications(item.token)}>
                        <X />
                      </span>
                    </div>
                  </Media>
                </LI>
              );
            })}
            <LI attrLI={{ className: "p-0" }}>
              <Link
                to={process.env.PUBLIC_URL + "/crm/notifications"}
                className="btn btn-primary"
              >
                {trans("website.see-more")}
              </Link>
            </LI>
          </UL>
        </div>
      </LI>
    </Fragment>
  );
};
export default Notification;
