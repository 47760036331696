import { Link } from "react-router-dom";
import { CSS } from "src/_utils/styles";
import { buildQueryParamUrl, getQueryParams } from "src/_utils/urls";

export const EBSSSPagination = ({ page, data, filters }: { page: number; data: any; filters?: Record<string, any> }) => {
  console.log(page);
  return data?.total > 10 ? (
    <nav aria-label="Page navigation example" className="d-flex justify-content-center mt-3 mb-5">
      <ul className="pagination">
        <li style={CSS({ cursor: "pointer" })} className={`page-item ${page === 1 ? "disabled" : ""}`}>
          <Link
            to={buildQueryParamUrl({
              ...getQueryParams(window.location.href),
              ...filters,
              page: page - 1 >= 1 ? page - 1 : page,
            })}
            className="page-link"
          >
            <span
              aria-hidden="true"
              style={CSS({
                color: "var(--theme-default) !important",
              })}
            >
              «
            </span>
          </Link>
        </li>
        {Array.from({ length: data?.last_page }, (_, i) => i + 1).map((p) => (
          <li key={p} style={CSS({ cursor: "pointer" })} className={`page-item`}>
            <Link
              replace
              to={buildQueryParamUrl({
                ...getQueryParams(window.location.href),
                ...filters,
                page: p,
              })}
              style={CSS({
                borderColor:
                  (page === p && page === 1 && p === 1) || (p === 1 && !page) || (!page && p === 1)
                    ? "var(--theme-default) !important"
                    : page === p
                    ? "var(--theme-default) !important"
                    : "",
                backgroundColor:
                  (page === p && page === 1 && p === 1) || (p === 1 && !page) || (!page && p === 1)
                    ? "var(--theme-default) !important"
                    : page === p
                    ? "var(--theme-default) !important"
                    : "",
              })}
              className={`page-link ${
                (page === p && page === 1 && p === 1) || (p === 1 && !page) || (!page && p === 1) ? "active" : page === p ? "active" : ""
              }`}
            >
              <span
                style={CSS({
                  color: (page ? page !== p : p !== 1) ? "var(--theme-default) !important" : "",
                })}
              >
                {p}
              </span>
            </Link>
          </li>
        ))}
        <li style={CSS({ cursor: "pointer" })} className={`page-item ${page === data?.last_page ? "disabled" : ""}`}>
          <Link
            to={buildQueryParamUrl({
              ...getQueryParams(window.location.href),
              ...filters,
              page: page + 1 <= data?.last_page ? page + 1 : page,
            })}
            className="page-link"
            aria-label="Next"
          >
            <span
              aria-hidden="true"
              style={CSS({
                color: "var(--theme-default) !important",
              })}
            >
              »
            </span>
          </Link>
        </li>
      </ul>
    </nav>
  ) : (
    <></>
  );
};
