function validateTaxPayerNumber(taxPayerNumber) {
  // NIF Algo Validation
  // http://pt.wikipedia.org/wiki/N%C3%BAmero_de_identifica%C3%A7%C3%A3o_fiscal

  var hasError = 0;

  if (
    taxPayerNumber.substr(0, 1) != "1" && // pessoa singular
    taxPayerNumber.substr(0, 1) != "2" && // pessoa singular
    taxPayerNumber.substr(0, 1) != "3" && // pessoa singular
    taxPayerNumber.substr(0, 2) != "45" && // pessoa singular não residente
    taxPayerNumber.substr(0, 1) != "5" && // pessoa colectiva
    taxPayerNumber.substr(0, 1) != "6" && // administração pública
    taxPayerNumber.substr(0, 2) != "70" && // herança indivisa
    taxPayerNumber.substr(0, 2) != "71" && // pessoa colectiva não residente
    taxPayerNumber.substr(0, 2) != "72" && // fundos de investimento
    taxPayerNumber.substr(0, 2) != "77" && // atribuição oficiosa
    taxPayerNumber.substr(0, 2) != "79" && // regime excepcional
    taxPayerNumber.substr(0, 1) != "8" && // empresário em nome individual (extinto)
    taxPayerNumber.substr(0, 2) != "90" && // condominios e sociedades irregulares
    taxPayerNumber.substr(0, 2) != "91" && // condominios e sociedades irregulares
    taxPayerNumber.substr(0, 2) != "98" && // não residentes
    taxPayerNumber.substr(0, 2) != "99" // sociedades civis
  ) {
    hasError = 1;
  }
  let check1 = taxPayerNumber.substr(0, 1) * 9;
  let check2 = taxPayerNumber.substr(1, 1) * 8;
  let check3 = taxPayerNumber.substr(2, 1) * 7;
  let check4 = taxPayerNumber.substr(3, 1) * 6;
  let check5 = taxPayerNumber.substr(4, 1) * 5;
  let check6 = taxPayerNumber.substr(5, 1) * 4;
  let check7 = taxPayerNumber.substr(6, 1) * 3;
  let check8 = taxPayerNumber.substr(7, 1) * 2;

  let total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8;
  let partial = total / 11;
  let moduleEleven = total - parseInt(String(partial)) * 11;
  let matcher;

  if (moduleEleven == 1 || moduleEleven == 0) {
    matcher = 0;
  } else {
    matcher = 11 - moduleEleven;
  }

  var lastDigit = taxPayerNumber.substr(8, 1) * 1;

  if (lastDigit != matcher) {
    hasError = 1;
  }

  if (hasError == 1) {
    return false;
  }

  return true;
}

export const useFormValidation = (trans, keyEl = "id") => {
  let trans_vat = trans("common.invalid_vat");
  let trans_required = trans("common.required");
  let trans_isnt_equal_than = trans("common.isnt_equal_than");
  let trans_isnt_greather_than = trans("common.isnt_greather_than");
  let trans_isnt_less_than = trans("common.isnt_less_than");
  let trans_isnt_email = trans("common.isnt_email");
  let trans_and = trans("common.and");

  function formValidation(toValidate, event = null, callbackSuccess = null) {
    function renderInvalidFeedback(inputEl, translation, idx = null) {
      if (inputEl) {
        var invalidFeedbackDiv = document.createElement("div");

        invalidFeedbackDiv.className = "invalid-feedback mb-3";
        invalidFeedbackDiv.id = "invalid-" + inputEl.getAttribute(keyEl) + "-validation-" + translation;

        invalidFeedbackDiv.innerHTML = translation;

        if (document.querySelectorAll('[id^="invalid-' + inputEl.getAttribute(keyEl) + '-validation-"]').length > 0) {
          document.querySelectorAll('[id^="invalid-' + inputEl.getAttribute(keyEl) + '-validation-"]').forEach((el) => el.remove());
        }

        inputEl.parentNode.insertBefore(invalidFeedbackDiv, inputEl.nextSibling);
      } else {
        console.error("Input element with ID '" + inputEl.getAttribute(keyEl) + "' not found.");
      }
    }

    function validateString(input, validationParams = null, ignoreFeedbackRender = null) {
      // Check if the input is a string
      if (input?.tagName !== "DIV" && typeof input?.value !== "string") {
        return false; // Input is not a string, validation fails
      }

      if (validationParams === null) {
        return false;
      }

      if (typeof validationParams === "object") {
        let errorBag = [];

        if (validationParams?.validateOnlyIf) {
          const inputDependable = document.querySelector("#" + validationParams.validateOnlyIf?.inputIdentifier);
          if (validationParams?.validateOnlyIf?.value && validationParams?.validateOnlyIf?.value !== inputDependable["value"]) {
            return "SKIPPED";
          }
        }

        if ("required" in validationParams) {
          if (input.tagName === "DIV") {
            if (input.getAttribute("asradio") == "1") {
              let radioInputsChecked = input.querySelectorAll('input[type="radio"]:checked');
              console.log("radiosChecked: ", radioInputsChecked);
              if (radioInputsChecked?.length === 0) {
                if (!ignoreFeedbackRender) {
                  renderInvalidFeedback(input, trans_required);
                }
                return true;
              }
            }
            if (input.getAttribute("ascheckbox") == "1") {
              let checkboxesInputsChecked = input.querySelectorAll('input[type="checkbox"]:checked');
              console.log("checkboxesChecked: ", checkboxesInputsChecked);
              if (checkboxesInputsChecked?.length === 0) {
                if (!ignoreFeedbackRender) {
                  renderInvalidFeedback(input, trans_required);
                }
                return true;
              }
            }
          } else {
            if (input.type === "radio" && !input.checked) {
              if (!ignoreFeedbackRender) {
                renderInvalidFeedback(input, trans_required);
              }
              return true;
            }

            if (input.type === "checkbox" && !input.checked) {
              if (!ignoreFeedbackRender) {
                renderInvalidFeedback(input, trans_required);
              }
              return true;
            }

            if (!input.value.trim().length) {
              if (!ignoreFeedbackRender) {
                renderInvalidFeedback(input, trans_required);
              }
              return true;
            }
          }
        }

        if ("isVAT" in validationParams) {
          if (!validateTaxPayerNumber(input.value)) {
            errorBag.push(trans_vat);
          }
        }

        if ("equal" in validationParams) {
          if (input.value.length !== Number(validationParams.equal)) {
            errorBag.push(trans_isnt_equal_than + " " + Number(validationParams.equal));
          }
        }

        if ("between" in validationParams) {
          var range = validationParams.between.split("-");
          var lowerBound = parseFloat(range[0]);
          var upperBound = parseFloat(range[1]);

          if (input.value.length < Number(lowerBound) || input.value.length > Number(upperBound)) {
            errorBag.push(trans_isnt_greather_than + " " + lowerBound + " " + trans_and + " " + trans_isnt_less_than + " " + upperBound);
          }
        }

        if ("email" in validationParams) {
          const emailRegex = /.+@.+\..+/;
          if (!emailRegex.test(input.value)) {
            errorBag.push(trans_isnt_email);
          }
        }

        if (!("required" in validationParams) && input.value.length === 0) {
          return false;
        }

        if (errorBag.length > 0) {
          errorBag.forEach((feedbackErr, idx) => {
            if (!ignoreFeedbackRender) {
              renderInvalidFeedback(input, feedbackErr, idx);
            }
          });
          return true;
        }

        return false;
      }

      return false;
    }

    let validateResult = {};

    toValidate.forEach((validable) => {
      const inputElement = document.querySelector(keyEl !== "id" ? `[${keyEl}="${validable.inputIdentifier}"]` : "#" + validable.inputIdentifier);
      const feedbackElement = validable.feedbackElement ? document.querySelector("#invalid-" + validable.feedbackElement + "-validation") : null;
      const resValidationInput = validateString(inputElement, validable.rules ? validable.rules : null);

      if (resValidationInput === "SKIPPED") {
        return;
      }

      if (resValidationInput) {
        if (inputElement?.classList.contains("is-valid")) {
          inputElement?.classList.remove("is-valid");
        }

        inputElement?.classList.add("is-invalid");

        if (!feedbackElement?.classList.contains("d-none")) {
          feedbackElement?.classList.add("d-none");
        }

        validateResult[validable.inputIdentifier] = false;
      } else {
        if (inputElement?.classList.contains("is-invalid")) {
          inputElement?.classList.remove("is-invalid");
        }

        inputElement?.classList.add("is-valid");

        if (feedbackElement?.classList.contains("d-none")) {
          feedbackElement?.classList.remove("d-none");
        }

        validateResult[validable.inputIdentifier] = true;
      }
    });

    if (Object.values(validateResult).filter((res) => res === false)?.length === 0) {
      window["VALIDATION_SUCCESS"] = true;
      if (callbackSuccess) {
        callbackSuccess();
      }
    } else {
      window["VALIDATION_SUCCESS"] = false;
      if (event) {
        event.preventDefault();
      } else {
        return;
      }
    }
  }

  return [formValidation];
};
