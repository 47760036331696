import { Link } from "react-router-dom";
import { useEBSSSLayout } from "./context/EBSSSLayoutContext/useEBSSSLayout";

const ResetTable = () => {
  const { trans } = useEBSSSLayout();
  return (
    <div className="text-center d-flex flex-column align-content-center">
      {trans("website.there_are_no_data_to_display")}
      <Link to={process.env.PUBLIC_URL + "/dashboard"} className="mt-3">
        {trans("website.go-to-dashboard")}
      </Link>
    </div>
  );
};

export default ResetTable;
