//@ts-nocheck
import { useContext, useState } from "react";
import { Image } from "../../AbstractElements";
import CheckContext from "../../_helper/customizer/index";
import { Link } from "react-router-dom";

const SidebarIcon = ({ logo, alt }) => {
  const { toggleSidebar } = useContext<any>(CheckContext);
  const [toggle, setToggle] = useState(false);
  const openCloseSidebar = () => {
    setToggle(window.innerWidth > 1016 ? !toggle : toggle);
    toggleSidebar(window.innerWidth > 1016 ? toggle : !toggle);
  };
  return (
    <div className="logo-wrapper no-print-ebsss">
      <Link to={process.env.PUBLIC_URL}>
        <Image
          attrImage={{
            className: "img-fluid for-light",
            src: `${logo}`,
            alt: alt,
          }}
        />
        <Image
          attrImage={{
            className: "img-fluid for-dark",
            src: `${logo}`,
            alt: alt,
          }}
        />
      </Link>
      <div className="back-btn" onClick={() => openCloseSidebar()}>
        <i className="fa fa-angle-left"></i>
      </div>
    </div>
  );
};
export default SidebarIcon;
