import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { scrollUpPage } from "src/_utils/elements";
import { CSS } from "src/_utils/styles";
import { getQueryParams } from "src/_utils/urls";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import EBSSSCheckbox from "src/base/atoms/EBSSSCheckbox";
import EBSSSTable from "src/base/atoms/EBSSSTable";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import TablePage from "src/base/TablePage";

const ContractPaymentsPage = () => {
  const { contractPayments, appSettings, fetchContractPayments, attachRequestPayments } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const [page, setPage] = useState(null);
  const [selectedPrs, setSelectedPrs] = useState([]);
  const [loadingAttach, setLoadingAttach] = useState(false);
  const [canAttachPrs, setCanAttachPrs] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setPage((p) => {
      if (p !== null) {
        return p;
      } else {
        return Number(getQueryParams(window.location.href)?.page);
      }
    });
  }, [window.location.search]);

  useEffect(() => {
    scrollUpPage();
    if (!contractPayments) {
      fetchContractPayments(1);
      return;
    }
    if (page) {
      fetchContractPayments(page);
    }
  }, [page]);

  useEffect(() => {
    setSelectedPrs([]);
  }, [canAttachPrs]);

  let cols: any[] = [
    {
      name: "#",
      sortable: true,
      center: true,
      width: 70,
      cell: (row) => {
        return (
          <div key={row.id} className="d-flex flex-row align-items-center">
            {(canAttachPrs && row?.status === "open" && new Date(row?.expected_date).getTime() > new Date().getTime()) ||
            (canAttachPrs &&
              !["attach-cancel", "attach-expired", "attach-grouped", "attach-open", "attach-paid", "paid", "cancel", "open", "expired"].includes(row.status)) ||
            (canAttachPrs &&
              appSettings?.billing_allow_payments_on_expired_requests &&
              ((new Date(row?.expected_date).getTime() < new Date().getTime() && row?.status === "open") || row?.status === "expired")) ? (
              <EBSSSCheckbox
                id={"multi-gp-" + row.id}
                label={<label className="ebsss-checkbox-label">{row.id}</label>}
                selected={selectedPrs.includes(row.id)}
                action={() => setSelectedPrs(selectedPrs.includes(row.id) ? selectedPrs.filter((pr) => pr !== row.id) : [...selectedPrs, row.id])}
              />
            ) : (
              row.id
            )}
          </div>
        );
      },
    },
  ];

  if (appSettings?.sys_multi_sellers) {
    cols.push({
      name: trans("website.seller_name"),
      selector: (row: any) =>
        row.order && row.order.seller ? (
          <Link to={`${process.env.PUBLIC_URL}/crm/seller/${row.order.seller.username || row.order.seller.token}`}>
            <span className="badge badge-default bg-purple text-white m-1">{row.order.seller.username || row.order.seller.email}</span>
          </Link>
        ) : (
          "-"
        ),
      sortable: true,
      center: false,
      onClick: () => console.log("1dasndalsk"),
    });
  }

  cols = [
    ...cols,
    {
      name: trans("common.created_at"),
      selector: (row: { created_at: any }) => row.created_at,
      sortable: true,
      center: true,
    },
    {
      name: trans("common.status"),
      selector: (row: any) => (
        <>
          {!appSettings?.billing_allow_payments_on_expired_requests && new Date(row?.expected_date) < new Date() && row?.status === "open" ? (
            <span className="badge bg-danger badge-lg text-white">{trans("billing.payment-request-status-expired")}</span>
          ) : (
            <span className={`badge bg-${row?.statusColorBootStrap} badge-lg text-white`}>{trans(`billing.payment-request-status-${row?.status}`)}</span>
          )}
          {row.payment_proof && (
            <Link target="_blank" rel="noopener noreferrer" to={`${process.env.PUBLIC_URL}/files/${row.payment_proof}`} className="badge badge-sm bg-success">
              <i className="fa fa-paperclip"></i>
            </Link>
          )}
          {row.stripe_receipt_url && (
            <Link to={row.stripe_receipt_url} target="_blank" rel="noopener noreferrer" className="badge badge-sm bg-success">
              <i className="fa fa-cc-stripe"></i>
            </Link>
          )}
        </>
      ),
      sortable: true,
      center: true,
    },
    {
      name: trans("billing.expected-payment-date"),
      selector: (row: any) => row.expected_date,
      sortable: true,
      center: true,
    },
    {
      name: trans("website.total-amount"),
      selector: (row: any) => `${Number(row.amount).toFixed(2)} ${row.currency.symbol}`,
      sortable: true,
      center: true,
    },
    {
      name: "",
      selector: (row: any): any => {
        return (
          <div style={CSS({ gap: 5, display: "flex" })}>
            <Link
              to={
                process.env.PUBLIC_URL +
                "/crm/contract-payment/" +
                row.token +
                (appSettings?.billing_allow_payments_on_expired_requests && new Date(row?.expected_date) < new Date() && row?.status === "open"
                  ? "?expirePaidEnabled=1"
                  : "")
              }
              state={{
                order: { ...row, settings: appSettings },
              }}
              className="btn btn-primary"
            >
              <i className="la la-folder-open"></i>&nbsp;
              {trans("common.open")}
            </Link>
            {row.moloni_pdf && (
              <Link to={row.moloni_pdf} className="btn btn-info">
                <i className="la la-file"></i>
              </Link>
            )}
          </div>
        );
      },
      sortable: true,
      center: true,
    },
  ];

  return (
    <TablePage
      title=""
      description=""
      breads={[
        {
          name: trans("website.payment-requests"),
          active: false,
          url: "/crm/contract-payments",
        },
      ]}
    >
      <div className="w-100 d-flex justify-content-between mb-3">
        {contractPayments?.data?.length > 0 ? (
          <EBSSSButton
            onClick={() => {
              setCanAttachPrs(!canAttachPrs);
            }}
            color={canAttachPrs ? "info" : "light"}
          >
            <div className="ms-2 d-flex flex-row gap-2 align-items-center">
              {canAttachPrs ? <i className="la la-check-circle fs-5"></i> : <i className="la la-plus-circle fs-5"></i>}
              <span>{trans("billing.allow-master-payment-request")}</span>
            </div>
          </EBSSSButton>
        ) : (
          <></>
        )}
        {selectedPrs.length > 1 ? (
          <EBSSSButton
            loadingKey={loadingAttach}
            onClick={async () => {
              setLoadingAttach(true);
              await attachRequestPayments(selectedPrs)
                .then((created) => {
                  console.log("Created attached: ", created);
                  if (created) {
                    navigate(process.env.PUBLIC_URL + "/crm/contract-payment/" + created?.attached_token);
                  }
                })
                .catch((e) => {
                  console.log(e);
                })
                .finally(() => {
                  setLoadingAttach(false);
                });
            }}
            color="success"
          >
            <div className="ms-2 d-flex flex-row gap-2 align-items-center">
              <i className="la la-plus-circle fs-5"></i>
              <span>{trans("billing.generate-master-payment-request")}</span>
            </div>
          </EBSSSButton>
        ) : (
          <></>
        )}
      </div>
      <EBSSSTable pageHandler={setPage} data={contractPayments} cols={cols} />
    </TablePage>
  );
};

export default ContractPaymentsPage;
