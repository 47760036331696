import { Fragment, useState } from "react";
import { useNavigate } from "react-router";
import { Card, CardBody } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { LI, UL } from "src/AbstractElements";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";

const FormsRepliedSidebar = ({ repliedForms, formId }: any) => {
  const { trans } = useEBSSSLayout();
  const [IsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const OnHandelClick = () => {
    setIsOpen(!IsOpen);
  };

  console.log("FormsRepliedSidebar: ", repliedForms);

  return (
    <Fragment>
      <div className="md-sidebar job-sidebar">
        <a
          className="btn btn-primary md-sidebar-toggle"
          href="#javascript"
          onClick={(e) => {
            e.preventDefault();
            OnHandelClick();
          }}
        >
          {trans("website.category_filter")}
        </a>
        <div
          className={`md-sidebar-aside custom-scrollbar ${
            IsOpen ? "open" : ""
          } ebsss-form-replies-filter-container`}
          style={{ height: 300 }}
        >
          <div className="email-left-aside">
            <Card>
              <CardBody>
                <div className="email-app-sidebar">
                  <UL
                    attrUL={{
                      className: "simple-list nav main-menu ",
                      role: "tablist",
                    }}
                  >
                    <LI>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(
                            process.env.PUBLIC_URL + "/forms/replied/filter/all"
                          );
                        }}
                        className={
                          window.location.href.includes("/filter/all")
                            ? "shadow-sm border-2 rounded-2"
                            : ""
                        }
                        style={CSS({ cursor: "pointer", fontSize: 10 })}
                      >
                        <span className="title">
                          <i className={"la la-pen"}></i>
                          {trans("website.all")}
                        </span>
                        <span className="d-none badge pull-right"></span>
                      </a>
                    </LI>
                    {repliedForms &&
                      repliedForms?.forms?.map((item, idx) => {
                        console.log(item);
                        return (
                          <LI key={item.id}>
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(
                                  process.env.PUBLIC_URL +
                                    `/forms/replied/filter/${item.id}?category=${item.category_id}`
                                );
                              }}
                              className={
                                formId === item.id
                                  ? "shadow-sm border-2 rounded-2"
                                  : ""
                              }
                              style={CSS({ cursor: "pointer", fontSize: 10 })}
                            >
                              <span className="title">
                                <i className={"la la-pen"}></i>
                                {item.title}
                              </span>
                              <span className="d-none badge pull-right"></span>
                            </a>
                          </LI>
                        );
                      })}
                  </UL>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormsRepliedSidebar;
