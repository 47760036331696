export const scrollUpPage = () => window.scrollTo({ top: -50, behavior: "instant" });

export const parseHTMLString = (dc: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(dc, "text/html");
  return doc?.body?.textContent ?? "";
};

export const printContent = () => {
  // var printContents = document.querySelector("#inner-container").innerHTML;
  // var originalContents = document.body.innerHTML;
  // document.body.innerHTML = printContents;
  window.print();
  //document.body.innerHTML = originalContents;
};

export function countryCodeToFlag(countryCode: string) {
  // Define a literal object mapping country codes to emoji flags
  const flagMapping = {
    EN: `fi fi-${countryCode.toLowerCase()}`, // United States
    US: `fi fi-${countryCode.toLowerCase()}`, // United States
    CA: `fi fi-${countryCode.toLowerCase()}`, // Canada
    GB: `fi fi-${countryCode.toLowerCase()}`, // United Kingdom
    FR: `fi fi-${countryCode.toLowerCase()}`, // France
    DE: `fi fi-${countryCode.toLowerCase()}`, // Germany
    JP: `fi fi-${countryCode.toLowerCase()}`, // Japan
    IT: `fi fi-${countryCode.toLowerCase()}`, // Italy
    AU: `fi fi-${countryCode.toLowerCase()}`, // Australia
    ES: `fi fi-${countryCode.toLowerCase()}`, // Spain
    NL: `fi fi-${countryCode.toLowerCase()}`, // Netherlands
    CH: `fi fi-${countryCode.toLowerCase()}`, // Switzerland
    SE: `fi fi-${countryCode.toLowerCase()}`, // Sweden
    NO: `fi fi-${countryCode.toLowerCase()}`, // Norway
    DK: `fi fi-${countryCode.toLowerCase()}`, // Denmark
    BE: `fi fi-${countryCode.toLowerCase()}`, // Belgium
    AT: `fi fi-${countryCode.toLowerCase()}`, // Austria
    FI: `fi fi-${countryCode.toLowerCase()}`, // Finland
    IE: `fi fi-${countryCode.toLowerCase()}`, // Ireland
    PT: `fi fi-${countryCode.toLowerCase()}`, // Portugal
    GR: `fi fi-${countryCode.toLowerCase()}`, // Greece
    SG: `fi fi-${countryCode.toLowerCase()}`, // Singapore
    BR: `fi fi-${countryCode.toLowerCase()}`, // Brazil
    IN: `fi fi-${countryCode.toLowerCase()}`, // India
    CN: `fi fi-${countryCode.toLowerCase()}`, // China
    KR: `fi fi-${countryCode.toLowerCase()}`, // South Korea
    RU: `fi fi-${countryCode.toLowerCase()}`, // Russia
    MX: `fi fi-${countryCode.toLowerCase()}`, // Mexico
    AR: `fi fi-${countryCode.toLowerCase()}`, // Argentina
    ZA: `fi fi-${countryCode.toLowerCase()}`, // South Africa
    EG: `fi fi-${countryCode.toLowerCase()}`, // Egypt
    SA: `fi fi-${countryCode.toLowerCase()}`, // Saudi Arabia
    TR: `fi fi-${countryCode.toLowerCase()}`, // Turkey
    ID: `fi fi-${countryCode.toLowerCase()}`, // Indonesia
    MY: `fi fi-${countryCode.toLowerCase()}`, // Malaysia
    TH: `fi fi-${countryCode.toLowerCase()}`, // Thailand
    PH: `fi fi-${countryCode.toLowerCase()}`, // Philippines
    VN: `fi fi-${countryCode.toLowerCase()}`, // Vietnam
    IR: `fi fi-${countryCode.toLowerCase()}`, // Iran
    IQ: `fi fi-${countryCode.toLowerCase()}`, // Iraq
    SY: `fi fi-${countryCode.toLowerCase()}`, // Syria
    QA: `fi fi-${countryCode.toLowerCase()}`, // Qatar
    KW: `fi fi-${countryCode.toLowerCase()}`, // Kuwait
    BH: `fi fi-${countryCode.toLowerCase()}`, // Bahrain
    AE: `fi fi-${countryCode.toLowerCase()}`, // United Arab Emirates
    PK: `fi fi-${countryCode.toLowerCase()}`, // Pakistan
  };

  // Check if the provided country code exists in the mapping
  if (flagMapping.hasOwnProperty(countryCode)) {
    return flagMapping[countryCode];
  } else {
    return countryCode; // Return this if the country code is not found
  }
}

export function msToTime(ms, trans) {
  let days = Math.floor(ms / (24 * 60 * 60 * 1000));
  ms %= 24 * 60 * 60 * 1000;

  let hours = Math.floor(ms / (60 * 60 * 1000));
  ms %= 60 * 60 * 1000;

  let minutes = Math.floor(ms / (60 * 1000));
  // Conditional formatting
  let daysText = days > 0 ? `${trans("website.payment-request-expire-in-less-than-days")} ${days} ${trans("common.days").toLowerCase()}` : "";
  let hoursText = hours > 0 ? `${trans("website.payment-request-expire-in-less-than-hours")} ${hours} ${trans("common.hours").toLowerCase()}` : "";
  let minutesText = minutes > 0 ? `${trans("website.payment-request-expire-in-less-than-minutes")} ${minutes} ${trans("common.minutes").toLowerCase()}` : "";

  // Combine and return only non-empty strings
  return days > 0 ? daysText : hours > 0 ? hoursText : minutes > 0 ? minutesText : 0;
}

export const reverseDate = (d) => {
  return d ? d.split("-").reverse().join("/") : "Invalid Date";
};
