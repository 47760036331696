//@ts-nocheck
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import EBSSSTicket from "src/base/atoms/EBSSSTicket";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import FormsRepliedSidebar from "./FormsRepliedSidebar";
import { parseHTML } from "src/_utils/styles";

const FormsRepliedDefaultView = () => {
  const { trans } = useEBSSSLayout();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { repliedForm, repliedForms, appSettings, fetchRepliedForm } = useEBSSS();
  const getRepliedForm = useParams();

  console.log("Replieds tickets: ", repliedForm?.tickets);

  useEffect(() => {
    fetchRepliedForm(getRepliedForm?.id, true);
  }, []);

  console.log(repliedForm?.lead?.note);

  return (
    <Page
      breads={[
        {
          name: trans("website.form"),
          active: false,
          url: "/forms/replied/filter/all",
        },
        {
          name: trans("website.lead") + " " + getRepliedForm?.id,
          active: true,
        },
      ]}
    >
      <div className="email-wrap mb-3">
        <Row>
          <Col xl="3" md="6" className="xl-30 box-col-3">
            <FormsRepliedSidebar trans={trans} repliedForms={repliedForms} appSettings={appSettings} />
          </Col>
          <Col xl="9" md="12" className="xl-70 box-col-9">
            <Card>
              <CardBody>
                <h4 className="mb-3">{trans("website.form_info")}</h4>
                <div className="d-flex flex-column gap-1">
                  <span>
                    {trans("website.lead_name")}: {repliedForm?.lead?.client_name}
                  </span>
                  <span>
                    {trans("website.lead_phone")}: {repliedForm?.lead?.mobile}
                  </span>
                  <span>
                    {trans("website.lead_email")}: {repliedForm?.lead?.client_email}
                  </span>
                </div>
                <div className="my-3">
                  {repliedForm?.lead?.data?.map((df) => {
                    return (
                      <div>
                        <span>{df.field_text}:</span>
                        {!df.value_text?.startsWith("http") ? (
                          <p>{df.value_text}</p>
                        ) : (
                          <div>
                            <a href={df.value_text} target="_blank" rel="noreferrer">
                              {trans("website.see_file")}
                            </a>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {repliedForm?.lead?.note ? (
                    <div className="d-flex flex-column mt-3">
                      <span>{trans("website.note")}:</span>
                      <div dangerouslySetInnerHTML={{ __html: repliedForm?.lead?.note.replaceAll("===========", "<br />") }}></div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <EBSSSTicket
                  fullWidth
                  dataType="lead"
                  callbackRefresh={() => fetchRepliedForm(getRepliedForm?.id, true)}
                  data={{
                    ...repliedForm?.lead,
                    id: repliedForm?.lead?.id,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Page>
  );
};

export default FormsRepliedDefaultView;
