//@ts-nocheck
import { Fragment } from "react";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";

const Loader = (props) => {
  const { loaded } = useEBSSS();

  return (
    <Fragment>
      <div
        className={`loader-wrapper ${
          !loaded || props.override ? "" : "loaderhide"
        } no-print-ebsss`}
      >
        <div className="loader">
          <div className="loader-bar"></div>
          <div className="loader-bar"></div>
          <div className="loader-bar"></div>
          <div className="loader-bar"></div>
          <div className="loader-bar"></div>
          <div className="loader-ball"></div>
        </div>
      </div>
    </Fragment>
  );
};
export default Loader;
