import { Fragment, useEffect, useState } from "react";
import { Key, Lock, Mail, Phone, User } from "react-feather";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { getCountryCode } from "src/_utils/validations";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import FeedbackMessages from "src/base/FeedbackMessage";
import {
  cleanErrorsAndMessages,
  fetchForgotEmail,
  fetchForgotReset,
  fetchPassword,
  fetchSignin,
  fetchSignup,
  fetchToken,
  proceedAfterAcceptTerms,
  Session,
} from "src/base/store/authSlice";
import { ReduxDataAuthKey, ReduxDataKey } from "src/base/store/store";
import { EmailAddress, Login } from "src/Constant";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import TermsPage from "../Terms/TermsPage";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { toast } from "react-toastify";
import { Layout } from "src/base/store/layoutSlice";

const PasswordForm = ({ outOriginalPage, setEndRegistrationCheckoutModal, emailRecovery }) => {
  const { trans } = useEBSSSLayout();
  const dispatcher = useDispatch<any>();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const [isPasswordVisible, setisPasswordVisible] = useState(false);
  const [resetStage, setResetStage] = useState<"start" | "password">("start");
  const [email, setEmail] = useState(emailRecovery);
  const [newPassword, setNewPassword] = useState({
    token: "",
    password: "",
    password_confirmation: "",
  });

  console.log("LOGIN: ", sessionNow);

  const toggle = () => {
    setisPasswordVisible(!isPasswordVisible);
  };

  const handleSubmitTokenReset = async (e) => {
    e.preventDefault();
    dispatcher(fetchForgotEmail({ email, activeLanguage: "pt" }));
  };

  const handleSubmitPasswordReset = async (e) => {
    e.preventDefault();
    dispatcher(fetchForgotReset({ ...newPassword, activeLanguage: "pt" }));
  };

  useEffect(() => {
    dispatcher(cleanErrorsAndMessages());
  }, []);

  useEffect(() => {
    if (sessionNow?.message?.forgotPassword === "SENT") {
      console.log("Changing to 2nd stage of reset password...");
      setResetStage("password");
    }
  }, [sessionNow?.message?.forgotPassword]);

  return !sessionNow?.user?.id ? (
    resetStage === "start" ? (
      <>
        <div className={`login-header text-center ${outOriginalPage ? "p-4" : ""}`}>
          <div className="login-header text-center">
            <h4>{trans("auth.password-forgot-title")}</h4>
            <h6>{trans("auth.password-forgot-description")}</h6>
          </div>
        </div>

        <div className={`${outOriginalPage ? "p-4" : ""}`}>
          <FormGroup>
            <Label>{trans("auth.email")}</Label>
            <InputGroup>
              <InputGroupText>
                <Mail />
              </InputGroupText>
              <Input type="email" required placeholder="you@gmail.com" value={email} onChange={(event) => setEmail(event.target.value)} />
            </InputGroup>
            <FeedbackMessages messages={sessionNow?.errors?.forgotPassword["email"]} />
          </FormGroup>
          <FormGroup>
            <EBSSSButton loadingKey={ReduxDataAuthKey.forgotPassword} onClick={handleSubmitTokenReset}>
              {trans("auth.password_reset_button")}
            </EBSSSButton>
          </FormGroup>
        </div>
      </>
    ) : (
      <>
        <div className={`login-header text-center ${outOriginalPage ? "p-4" : ""}`}>
          <h4>{trans("auth.password-reset-title")}</h4>
          <h6>{trans("auth.password-reset-description")}</h6>
        </div>
        <div className={`${outOriginalPage ? "p-4" : ""}`}>
          <FormGroup>
            <Label>{trans("auth.access_code")}</Label>
            <InputGroup>
              <InputGroupText>
                <Key />
              </InputGroupText>
              <Input
                autoComplete="off"
                aria-autocomplete="none"
                type="text"
                name="token"
                required
                value={newPassword.token}
                onChange={(event) =>
                  setNewPassword({
                    ...newPassword,
                    token: event.target.value,
                  })
                }
              />
            </InputGroup>
            <FeedbackMessages messages={sessionNow?.errors?.forgotPassword["token"]} />
          </FormGroup>
          <FormGroup>
            <Label>{trans("auth.password")}</Label>
            <InputGroup style={{ position: "relative" }}>
              <InputGroupText>
                <Lock />
              </InputGroupText>
              <Input
                type={isPasswordVisible ? "text" : "password"}
                required
                value={newPassword.password}
                onChange={(event) =>
                  setNewPassword({
                    ...newPassword,
                    password: event.target.value,
                  })
                }
              />

              <div className="text-primary small show-hide-toggle" onClick={toggle}>
                <span>{!isPasswordVisible ? trans("website.show") : trans("website.hide")}</span>
              </div>
            </InputGroup>
            <FeedbackMessages messages={sessionNow?.errors?.forgotPassword["password"]} />
          </FormGroup>
          <FormGroup>
            <Label>{trans("auth.password_confirmation")}</Label>
            <InputGroup>
              <InputGroupText>
                <Lock />
              </InputGroupText>
              <Input
                type={isPasswordVisible ? "text" : "password"}
                required
                value={newPassword.password_confirmation}
                onChange={(event) =>
                  setNewPassword({
                    ...newPassword,
                    password_confirmation: event.target.value,
                  })
                }
              />
              <div className="text-primary small show-hide-toggle" onClick={toggle}>
                <span>{!isPasswordVisible ? trans("website.show") : trans("website.hide")}</span>
              </div>
            </InputGroup>
            <FeedbackMessages messages={sessionNow?.errors?.forgotPassword["password_confirmation"]} />
          </FormGroup>
          <FormGroup>
            <EBSSSButton loadingKey={ReduxDataAuthKey.forgotPassword} onClick={handleSubmitPasswordReset}>
              {trans("auth.define-password-button")}
            </EBSSSButton>
          </FormGroup>
        </div>
      </>
    )
  ) : (
    <></>
  );
};

export default PasswordForm;
