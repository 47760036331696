//@ts-nocheck
import { Fragment, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Clock, Truck } from "react-feather";
import { PhoneInput } from "react-international-phone";
import Ratings from "react-ratings-declarative";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { Card, CardBody, Col, FormGroup, Input, Label, Media, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { parseHTMLString, scrollUpPage } from "src/_utils/elements";
import { CSS, parseHTML, parseHTMLInner, parseHTMLToReact } from "src/_utils/styles";
import Page from "src/base/Page";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import EBSSSItemAvailability from "src/base/atoms/EBSSSItemAvailability";
import EBSSSModal from "src/base/atoms/EBSSSModal";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { remotePath } from "src/base/settings/Paths";
import { cleanErrorsAndMessages, clearSelectedSeller, Session } from "src/base/store/authSlice";
import { ReduxDataAuthKey, ReduxDataKey } from "src/base/store/store";
import { Btn, H3, H4, H5, H6, Image, LI, P, UL } from "../../AbstractElements";
import { emitSwal } from "../Checkout/CheckoutPage";
import { getCountryCode, isValidDate, isValidTime } from "src/_utils/validations";
import { useFormValidation } from "src/base/hooks/form_validator_handler";
import HelperHTTP from "src/_utils/http_module_handler";
import { Layout } from "src/base/store/layoutSlice";
import EBSSSCheckbox from "src/base/atoms/EBSSSCheckbox";

const isVertical = !true;

function convertDateFormat(dateString, noTime = false) {
  let date;
  if (typeof dateString === "string") {
    date = new Date(dateString);
  } else {
    date = dateString;
  }

  // Extract the date and time components
  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0");
  const day = String(date?.getDate()).padStart(2, "0");
  const hours = String(date?.getHours()).padStart(2, "0");
  const minutes = String(date?.getMinutes()).padStart(2, "0");
  const seconds = String(date?.getSeconds()).padStart(2, "0");

  if (noTime) {
    return `${year}-${month}-${day}`;
  }

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function parseDateArray(dateArray) {
  const startDate = new Date(dateArray[0]).getTime();
  const endDate = new Date(dateArray[1]).getTime();

  const startFormatted = new Date(startDate).toISOString().split("T")[0];
  const endFormatted = new Date(endDate).toISOString().split("T")[0];

  return `${startFormatted} - ${endFormatted}`;
}

const ProductAttributes = ({ product }) => {
  const [hasPrintedTab, setHasPrintedTab] = useState(false);

  if (product?.prd_attributes?.filter((prd) => prd.value && prd.value !== "-1").length === 0) {
    return <></>;
  }

  if (
    product?.prd_attributes &&
    (product?.prd_attributes?.length > 1 || (product?.prd_attributes?.length === 1 && product?.prd_attributes[0].value !== null))
  ) {
    if (!hasPrintedTab) {
      setHasPrintedTab(true);
    }

    return (
      <div className="d-flex flex-row flex-wrap">
        {product?.prd_attributes
          ?.filter((prd) => prd.value && prd.value !== "-1")
          ?.map((productAttribute, index) => {
            if (productAttribute.attribute && !!productAttribute.value) {
              let val = "";

              if (["select", "radio", "checkbox"].includes(productAttribute.attribute.type)) {
                const values = JSON.parse(productAttribute.attribute.values);

                if (productAttribute.attribute.type === "checkbox") {
                  const selectedValues = productAttribute.value.split(",");
                  val = selectedValues
                    .map((i) => values[i])
                    .filter(Boolean)
                    .join(", ");
                } else {
                  val = values[productAttribute.value] || "";
                }
              }

              return (
                <div className="col-6 p-1" key={index}>
                  <b style={CSS({ color: "#2D3D6F" })}>{productAttribute.attribute.name}: </b>
                  <span className={`value_${productAttribute.attribute.slug.replace("-", "_").toLowerCase()}`}>
                    {productAttribute.attribute.type === "boolean" ? (productAttribute.value ? "Yes" : "No") : val || productAttribute.value}
                  </span>
                </div>
              );
            }

            return null;
          })}
      </div>
    );
  }

  return null;
};

const ProductSlider = ({ product }) => {
  const [state, setState] = useState({ nav1: null, nav2: null });
  const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    });
  }, []);

  const { nav1, nav2 } = state;
  const picsArray = [{ photo: product?.photo }]?.concat(Array.isArray(product?.images) && product?.images?.length > 0 ? product?.images : []);
  return (
    <Row className="d-flex flex-column align-items-center">
      <div className="product-main col-12 mb-3 mt-1">
        <div className="pro-slide-single mb-3">
          <Slider asNavFor={nav2} arrows={true} ref={(slider) => (slider1.current = slider)}>
            {picsArray?.map((item, i, ar) => (
              <Image
                attrImage={{
                  src: remotePath.assetsUrl(picsArray?.length >= 3 ? (ar[i + 1] ? ar[i + 1]?.photo : ar[0]?.photo) : item?.photo),
                  alt: "",
                  className: "img-fluid w-100",
                }}
                key={picsArray?.length >= 3 ? (ar[i + 1] ? ar[i + 1]?.photo : ar[0]?.photo) : item?.photo}
              />
            ))}
          </Slider>
        </div>
      </div>
      <div className="product-thumbnail col-12">
        <div className="pro-slide-thumbnails">
          {picsArray?.length > 1 ? (
            <Slider
              arrows={true}
              asNavFor={nav1}
              ref={(slider) => (slider2.current = slider)}
              slidesToShow={picsArray?.length <= 1 ? 0 : picsArray?.length === 2 ? 2 : 3}
              speed={500}
              swipeToSlide={true}
              // focusOnSelect={picsArray?.length <= 1 ? true : picsArray?.length === 2 ? true : picsArray?.length === 3 ? true : false}
              focusOnSelect={false}
              className="text-center"
            >
              {picsArray?.map((item) => (
                <Image
                  attrImage={{
                    src: remotePath.assetsUrl(item?.photo),
                    alt: "",
                    className: "img-fluid",
                  }}
                  key={item?.photo}
                />
              ))}
            </Slider>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Row>
  );
};

const CatalogButtons = ({ product, cmsStyleButtons, appSettings, trans, history, addToCart, paymentTypeSelected, sessionNow, formValidation }) => {
  const isFavorite = () => {
    return false;
  };
  const { sendProductProposal } = useEBSSS();
  const dispatch = useDispatch();
  const [hideOther, setHideOther] = useState("");
  const [formDataProposal, setFormDataProposal] = useState({
    proposal_name: "",
    proposal_email: "",
    proposal_mobile_number: "",
    proposal_user_request: "",
  });
  const [modal, setModal] = useState(false);
  const [loadingSubmitProposal, setLoadingSubmitProposal] = useState(false);

  const toggle = () => {
    if (!modal) {
      if (
        product?.settings?.customFields &&
        product?.settings?.customFields?.length > 0 &&
        product?.settings?.customFields?.filter((cfr) => cfr.required === "yes")?.length > 0
      ) {
        formValidation(
          product?.settings?.customFields
            ?.filter((cfr) => cfr.required === "yes")
            ?.map((cfr) => {
              return {
                inputIdentifier: `custom-fields-data-${product.id}-${cfr.id}`,
                feedbackElement: cfr.id + "-feedback",
                rules: {
                  required: true,
                },
              };
            })
        );
        //}

        if (window["VALIDATION_SUCCESS"]) {
          setModal(!modal);
        }
        return;
      }
    }
    setModal(!modal);
  };

  const handleInputChange = (event, v = null, k = null) => {
    setFormDataProposal({
      ...formDataProposal,
      [k ? k : event.target.name]: v ? v : event.target.value,
    });
  };

  return (
    <div className="pb-3 mt-3 d-flex flex-row align-items-end justify-content-end gap-3 flex-wrap">
      {(product?.instant_payment !== 1 || (product?.type === "physical" && product?.stock - product?.quantity < 0)) && product?.instant_payment !== 1 ? (
        <>
          <EBSSSItemAvailability item={product} />
          <div>
            <EBSSSModal
              asyncToggle
              loadingSubmitKey={loadingSubmitProposal}
              customConfirmAction={async () => {
                setLoadingSubmitProposal(true);
                await sendProductProposal({
                  proposal_user_request: formDataProposal?.proposal_user_request,
                  proposal_name: formDataProposal.proposal_name
                    ? formDataProposal.proposal_name
                    : sessionNow?.user?.id
                    ? sessionNow?.user?.profile?.first_name + " " + sessionNow?.user?.profile?.last_name
                    : "",
                  proposal_email: formDataProposal.proposal_email ? formDataProposal.proposal_email : sessionNow?.user?.id ? sessionNow?.user?.email : "",
                  proposal_mobile_number: formDataProposal.proposal_mobile_number
                    ? formDataProposal.proposal_mobile_number
                    : sessionNow?.user?.id
                    ? sessionNow?.user?.mobile_number
                    : "",
                  proposal_product: product?.id,
                  proposal_product_payload: await addToCart(product?.token, paymentTypeSelected, true),
                  from_product_page: true,
                })
                  .then((d) => {
                    let proposalModalSuccess = {
                      type: "s",
                      message: trans("website.proposal-requested"),
                      configSwal: {},
                    };
                    proposalModalSuccess.configSwal["buttons"] = {
                      confirm: {
                        text: "OK",
                        className: "bg-success",
                        closeModal: true,
                      },
                    };
                    proposalModalSuccess.configSwal["callback"] = async (r) => {
                      let resSwal = await r;
                      setFormDataProposal({
                        ...formDataProposal,
                        proposal_user_request: "",
                      });
                      if (resSwal || resSwal === null) {
                        window.location.reload();
                      }
                      return;
                    };

                    emitSwal({
                      ...proposalModalSuccess,
                    });
                    return;
                  })
                  .catch(() => {
                    setLoadingSubmitProposal(false);
                  })
                  .finally(() => {
                    setLoadingSubmitProposal(false);
                  });
              }}
              modalTitle={trans("website.request-proposal-modal")}
              modal={modal}
              toggle={toggle}
              triggerEl={
                <Btn
                  attrBtn={{
                    onClick: toggle,
                    color: "primary",
                    className: "primary-button-cart secondary-hover-button-cart",
                  }}
                >
                  <i
                    className={
                      (product?.instant_payment !== 1 || (product?.type === "physical" && product?.stock - product?.quantity < 0)) &&
                      product?.instant_payment !== 1
                        ? "fa fa-clock me-2"
                        : "fa fa-shopping-basket me-2"
                    }
                  ></i>
                  <span>{trans("website.get-proposal-now")}</span>
                </Btn>
              }
            >
              <div>
                {parseHTML(trans("website.request-proposal-text"))}
                {!sessionNow?.user?.id ? (
                  <>
                    <hr />
                    <p>{trans("website.request-proposal-form-title")}</p>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="proposal_name">
                        {trans("common.name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="proposal_name"
                        name="proposal_name"
                        autoComplete="off"
                        spellCheck="false"
                        maxLength={255}
                        value={formDataProposal.proposal_name}
                        onChange={handleInputChange}
                      />
                      <div id={`invalid-proposal_name-feedback-validation`}></div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="proposal_email">
                        {trans("common.email")}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="proposal_email"
                        name="proposal_email"
                        autoComplete="off"
                        spellCheck="false"
                        maxLength={255}
                        value={formDataProposal.proposal_email}
                        onChange={handleInputChange}
                      />
                      <div id={`invalid-proposal_email-feedback-validation`}></div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="proposal_mobile_number">
                        {trans("common.mobile_number")}
                      </label>
                      <PhoneInput
                        inputStyle={CSS({
                          width: "100%",
                          display: "flex",
                          borderEndEndRadius: 5,
                          borderStartEndRadius: 5,
                        })}
                        countrySelectorStyleProps={{
                          dropdownStyleProps: {
                            style: CSS({
                              zIndex: 999999,
                            }),
                          },
                          buttonStyle: CSS({
                            backgroundColor: "#EFEFFD",
                            border: 0,
                          }),
                        }}
                        required
                        name="proposal_mobile_number"
                        defaultCountry={getCountryCode()}
                        value={formDataProposal.proposal_mobile_number}
                        onChange={(v) => handleInputChange(null, v.replace("+", ""), "proposal_mobile_number")}
                      />

                      <div id={`invalid-proposal_mobile_number-feedback-validation`}></div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <textarea
                  placeholder={trans("website.request-proposal-user-requirements")}
                  value={formDataProposal.proposal_user_request}
                  name="proposal_user_request"
                  onChange={handleInputChange}
                  className="form-control"
                  rows={3}
                  cols={3}
                ></textarea>
              </div>
            </EBSSSModal>
          </div>
        </>
      ) : (
        <></>
      )}
      {!(product?.instant_payment !== 1 || (product?.type === "physical" && product?.stock - product?.quantity < 0)) ? (
        !hideOther || hideOther !== "right" ? (
          <EBSSSButton
            disableDefaultColor={true}
            style={cmsStyleButtons?.addCheckout?.style}
            className={`button_submit ${
              product?.product_type === "variation" ? "d-none" : ""
            } btn catalog-buttons-margin primary-button-cart secondary-hover-button-cart`}
            customClassName={cmsStyleButtons?.addCheckout?.className}
            loadingKey={ReduxDataAuthKey.cart}
            onClick={async () => {
              setHideOther("left");
              await addToCart(product?.token, paymentTypeSelected).then((r) => {
                setHideOther("");
                if (product?.settings?.customFields && product?.settings?.customFields?.length > 0 && r === "custom_field_validation_fail") {
                  return;
                }

                if (
                  sessionNow?.session?.cart?.some((item) => {
                    if (item.p.instant_payment !== 1 || (item.p.type === "physical" && item.p.stock - item.quantity < 0)) {
                      if (item.p && item.p.instant_payment !== 1) {
                        return true;
                      }
                    }
                  })
                ) {
                  history(`${process.env.PUBLIC_URL}/checkout/pre`);
                  return;
                }
                if (sessionNow?.session?.sellers && Object.values(sessionNow?.session?.sellers)?.length > 0) {
                  history(`${process.env.PUBLIC_URL}/checkout/multi`);
                  return;
                }

                history(`${process.env.PUBLIC_URL}/checkout`);
              });
            }}
          >
            <i className="fa fa-shopping-basket me-2"></i>
            {product?.instant_payment && product?.type === "physical"
              ? trans("website.buy-now")
              : product?.instant_payment && product?.type === "service"
              ? trans("website.buy-now-service")
              : trans("website.request-purchase")}
          </EBSSSButton>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {!hideOther || hideOther !== "left" ? (
        <EBSSSButton
          disableDefaultColor={true}
          style={CSS({ ...cmsStyleButtons?.add?.style, ...{ display: "none !important" } })}
          className={`btn d-none show-on-iframe catalog-buttons-margin primary-button-cart secondary-hover-button-cart`}
          customClassName={cmsStyleButtons?.add?.className}
          loadingKey={ReduxDataAuthKey.cart}
          onClick={async () => {
            setHideOther("right");
            await addToCart(product?.token, paymentTypeSelected).then(() => {
              setHideOther("");
            });
          }}
        >
          <i className={"fa fa-shopping-basket me-2"}></i>
          {(product?.instant_payment !== 1 || (product?.type === "physical" && product?.stock - product?.quantity < 0)) && product?.instant_payment !== 1
            ? trans("website.add-proposal")
            : product?.instant_payment && product?.type === "physical"
            ? trans("website.buy-now-close-iframe")
            : trans("website.request-purchase-close-iframe")}
        </EBSSSButton>
      ) : (
        <></>
      )}
    </div>
  );
};

const DateSingledata = ({
  setSelectedRentalStartAt,
  selectedRentalStartAt,
  handleChangeShiftTime,
  product,
  filter,
}: {
  setSelectedRentalStartAt: any;
  selectedRentalStartAt: any;
  filter?: any;
}) => {
  const startCount = new Date();

  const filterDays = (date, start) => {
    if (date.getTime() < startCount.getTime()) {
      return false;
    }
    if (filter) {
      let customFilter = filter(date);
      if (!customFilter) {
        return customFilter;
      }
    }
    return true;
  };

  return (
    <Fragment>
      <Col xl="6">
        <div className="daterange-card">
          <div className="theme-form">
            <FormGroup>
              <DatePicker
                className="form-control digits"
                selected={selectedRentalStartAt ? new Date(new Date(selectedRentalStartAt).getTime() /*+ 86400000*/) : new Date()}
                onKeyDown={(e) => {
                  e.preventDefault();
                  return;
                }}
                onChange={(update: Date) => {
                  const dayUpdate = new Date(new Date(convertDateFormat(update).split(" ")[0]).getTime() /*+ 86400000*/);

                  if (product?.settings?.shiftTimes) {
                    const checkShiftTiming = Object.values(product?.settings?.shiftTimes).map((sts) => {
                      return { ...sts, week_day: sts?.week_day?.split(",") };
                    });

                    const fromWeek = String(dayUpdate.getDay());

                    let foundShiftTime = checkShiftTiming.filter((st) => st.week_day.length !== 7).find((st) => st?.week_day?.includes(fromWeek));

                    if (checkShiftTiming.length === 1 || !foundShiftTime) {
                      checkShiftTiming.find((st) => st?.week_day?.includes(fromWeek));
                    }

                    if (foundShiftTime) {
                      handleChangeShiftTime(null, foundShiftTime?.token);
                    }
                  }
                  setSelectedRentalStartAt(convertDateFormat(dayUpdate, true));
                }}
                filterDate={(d) => filterDays(d, startCount)}
              />
            </FormGroup>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

const DateRangedata = ({ shiftSelected, setSelectedRentalStartAt, selectedRentalStartAt }) => {
  const [startDate, endDate] = selectedRentalStartAt ?? [];
  var date1 = new Date().getDate();
  var month = new Date().getMonth();
  var year = new Date().getFullYear();
  const startDate1 = date1 - 1 + "/" + month + "/" + year;
  const todayDate = date1 + "/" + month + "/" + year;
  const last7day = date1 - 7 + "/" + month + "/" + year;
  const last30day = date1 - 30 + "/" + month + "/" + year;
  const startCount = new Date();
  const filterDays = (date, start) => {
    if (date.getTime() < startCount.getTime()) {
      return false;
    }
    if (shiftSelected?.week_day && shiftSelected?.week_day?.split(",")?.length !== 7) {
      if (shiftSelected?.week_day?.split(",")?.includes(String(date.getDay()))) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Fragment>
      <Col xl="6" style={CSS({ zIndex: 99999999 })}>
        <div className="daterange-card">
          <div className="theme-form" style={CSS({ zIndex: 99999999 })}>
            <FormGroup>
              <DatePicker
                style={CSS({ zIndex: 99999999 })}
                className="form-control digits"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                isClearable={true}
                onKeyDown={(e) => {
                  e.preventDefault();
                  return;
                }}
                onChange={(update) => {
                  setSelectedRentalStartAt(update);
                }}
                filterDate={(d) => filterDays(d, startCount)}
              />
            </FormGroup>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export function ProductPrice({ product, currencySymbol, appSettings, trans, optionProductSelected }) {
  return (
    <>
      {product?.price_to_show ? (
        <div className="price-reviews clearfix">
          {product?.price_in_points > 0 && product?.how_to_sell === "only_points" ? (
            <>
              <span className="price-box">
                <span className="price h4">
                  {optionProductSelected ? optionProductSelected?.price + " " + appSettings?.currency_symbol : product?.price_to_show}
                </span>
              </span>
              {product?.seller && (
                <span className="float-right d-none">
                  ({trans("website.product-sold-by-seller")}
                  <a href={`/crm/seller/${product?.seller.username}`}>{product?.seller.fullName}</a>)
                </span>
              )}
              <br />
              <br />
              <span className="product-price-points-total">
                <span className="text-price" id="total-price">
                  {trans("website.will_pay_with")}: {parseInt(product?.price_in_points)} {trans("website.type-points")}
                </span>
              </span>
              <br />
              <br />
            </>
          ) : (
            <>
              <span className="price-box">
                <span className="price h4">
                  {optionProductSelected ? optionProductSelected?.price + " " + appSettings?.currency_symbol : product?.price_to_show}
                </span>
              </span>
              {product?.seller && (
                <span className="float-right d-none">
                  ({trans("website.product-sold-by-seller")}
                  <a href={`/crm/seller/${product?.seller.username}`}>{product?.seller.fullName}</a>)
                </span>
              )}
            </>
          )}
        </div>
      ) : !product?.options?.length && product?.price > 0 ? (
        <div className="price-reviews clearfix">
          <span className="price-box">
            <span className="price h4">
              {product?.price} {currencySymbol}
            </span>
            {product?.type === "entities" && product?.rental_type !== "shift_time" && (
              <small className="price h6 ms-2 fw-bold text-lowercase">({trans("website.entity-price-per-" + product?.rental_type)})</small>
            )}
          </span>
          {product?.seller && (
            <span className="float-right d-none">
              ({trans("website.product-sold-by-seller")}
              <a href={`/crm/seller/${product?.seller.username}`}>{product?.seller.fullName}</a>)
            </span>
          )}
        </div>
      ) : null}
    </>
  );
}

function RewardToWin({ product, appSettings, trans }) {
  let rewardToWin = product?.reward_to_win;

  // Refactor to show reward_to_win
  if (rewardToWin > 0) {
    rewardToWin = rewardToWin.toString().replace(/0+$/, "").replace(/\.$/, "");
  } else {
    rewardToWin = "";
  }

  rewardToWin = rewardToWin > 0 ? rewardToWin : "";

  return rewardToWin > 0 ? (
    <div
      style={CSS({
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      })}
    >
      <i className="fa fa-trophy text-warning fs-4 me-3"></i>
      <h4 className="fw-bold">{rewardToWin}</h4>
    </div>
  ) : (
    <></>
  );
}

function ProductOptions({ product, currencySymbol, appSettings, optionProductSelected, setOptionProductSelected, trans }) {
  if (product?.type === "contract") return null;

  const renderOptions = () => {
    if (!product?.options || product?.options.length === 0) return null;

    return (
      <div className="form-group">
        <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{trans("website.options")}</div>
        <select
          name="option"
          id="option"
          className="select2 form-control"
          value={optionProductSelected?.option + "@" + optionProductSelected?.value}
          onChange={(e) => {
            let [, childrenOption] = e.target.value.split("@");
            let optionsToFind = product?.options?.map((po) => po.values)?.flat();
            let copyOptionsToFind = product?.options;
            let opt = optionsToFind.find((o) => o.token === childrenOption);

            setOptionProductSelected({
              value: childrenOption,
              price: opt?.price,
              product_option_id: opt?.product_option_id,
              option: copyOptionsToFind.find((o) => o.values.find((ov) => ov.token === childrenOption))?.token,
            });
            // let [parentOption, childrenOption] = e.target.value.split("@");

            // let copyAlreadySelected = [...optionProductSelected];
            // let checkIfAlreadySelectedFromThisOption = optionProductSelected.findIndex((op) => op.option === parentOption);

            // if (checkIfAlreadySelectedFromThisOption !== -1) {
            //   copyAlreadySelected[checkIfAlreadySelectedFromThisOption] = {
            //     option: parentOption,
            //     value: childrenOption,
            //     price: product?.options?.find((optFind) => optFind.token === parentOption)?.values?.find((valFind) => valFind.token === childrenOption)?.price,
            //   };
            //   setOptionProductSelected(copyAlreadySelected);
            //   return;
            // }

            // setOptionProductSelected([
            //   ...optionProductSelected,
            //   {
            //     option: parentOption,
            //     value: childrenOption,
            //     price: product?.options?.find((optFind) => optFind.token === parentOption)?.values?.find((valFind) => valFind.token === childrenOption)?.price,
            //   },
            // ]);
          }}
          defaultValue={""}
        >
          <option key={0} value="" disabled>
            {trans("website.select")}
          </option>
          {product?.options?.map((option, index) => {
            return (
              <optgroup label={option.title} key={index}>
                {option?.values?.map((value, idx) => {
                  return (
                    <option
                      value={option.token + "@" + value.token}
                      data-title={value.title}
                      data-price={`${value.price} ${currencySymbol}`}
                      data-description={value.price_description}
                      data-float={option.quantity_float ? 1 : 0}
                      data-min={option.min_quantity ? option.min_quantity : option.quantity_float ? 0.01 : 1}
                      data-max={option.max_quantity || 9999999}
                      key={idx}
                    >
                      {`${value.title} - ${value.price_description}`}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        </select>
      </div>
    );
  };

  return renderOptions();
}

function PriceDiscount({ product, appSettings, trans }) {
  return <div className="align-items-center">{product?.discount_to_show && <del className="">{product?.discount_to_show}</del>}</div>;
}

function ProductRental({
  product,
  filteredSlots,
  defaultStartAt,
  defaultStartAtTime,
  defaultEndAt,
  defaultEndAtTime,
  appSettings,
  shiftSelected,
  setShiftSelected,
  selectedRentalStartAt,
  setSelectedRentalStartAt,
  rentalStartEndDateTime,
  setRentalStartEndDateTime,
  selectedShift,
  setSelectedShift,
  children,
  noPhoto,
  trans,
}) {
  function handleChangeShiftTime(e, t = null) {
    setShiftSelected(Object.values(product?.settings?.shiftTimes)?.find((sft) => sft.token === (t ? t : e?.target?.value)));
  }

  const filterDays = (date, start = null) => {
    const now = new Date();
    if (date.getTime() < now.setHours(0, 0, 0, 0)) {
      return false;
    }
    if (start && date.getTime() < start.setHours(0, 0, 0, 0)) {
      return false;
    }
    return true;
  };

  const renderRental = () => {
    switch (product?.rental_type) {
      case "shift_time":
        return (
          <>
            {product?.settings?.shiftTimes && Object.values(product?.settings?.shiftTimes).length > 1 ? (
              <div className="form-group mb-2 d-none" date-range="false">
                <label for="datepicker" className="form-label text-bold-600 font-medium-2 fw-bold ms-2 mb-2">
                  <i className="fas fa-arrow-left text-success me-1"></i>
                  <i className="fas fa-arrow-right text-danger me-3"></i>
                  {trans("website.rent-entity-shift-time")}:
                </label>
                {/* <select
                  name="shift_time"
                  id="shift_time"
                  className="select2 form-control d-none"
                  onChange={handleChangeShiftTime}
                  defaultValue={""}
                >
                  {Object.values(product?.settings?.shiftTimes)?.map(
                    (st, index) => {
                      return (
                        <option key={index} value={st.token}>
                          {st.name}
                        </option>
                      );
                    }
                  )}
                </select> */}
              </div>
            ) : (
              <></>
            )}
            <div className="form-group" date-range="false">
              <label for="datepicker" className="form-label text-bold-600 font-medium-2 fw-bold ms-2 mb-2">
                <i className="fas fa-arrow-down text-success me-1"></i>
                <i className="fas fa-arrow-up text-danger me-3"></i>
                {trans("common.date")}:
              </label>
              <DateSingledata
                product={product}
                handleChangeShiftTime={handleChangeShiftTime}
                setSelectedRentalStartAt={setSelectedRentalStartAt}
                selectedRentalStartAt={selectedRentalStartAt}
                // filter={(date) => {
                //   if (
                //     shiftSelected?.week_day &&
                //     shiftSelected?.week_day?.split(",")?.length !== 7
                //   ) {
                //     if (
                //       shiftSelected?.week_day
                //         ?.split(",")
                //         ?.includes(String(date.getDay()))
                //     ) {
                //       console.log("Passed conditional");
                //       return true;
                //     } else {
                //       console.log("Not passed conditional");
                //       return false;
                //     }
                //   } else {
                //     console.log("Different than seven");
                //     return true;
                //   }
                // }}
              />
            </div>
          </>
        );
      case "hourly":
        return (
          <>
            {product?.entity_rent_minutes_fraction ? (
              <div id="slot_hour">
                <div className="row" id="date_shift_time">
                  <div className="form-group" date-range="true">
                    <label for="datepicker" className="form-label text-bold-600 font-medium-2 fw-bold ms-2 mb-2">
                      <i className="fas fa-arrow-down text-success me-1"></i>
                      <i className="fas fa-arrow-up text-danger me-3"></i>
                      {trans("common.date")}:
                    </label>
                    <DateSingledata setSelectedRentalStartAt={setSelectedRentalStartAt} selectedRentalStartAt={selectedRentalStartAt} />
                  </div>

                  <div className={noPhoto ? "col-12" : "col-12"}>
                    <div className="form-group">
                      <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">
                        {trans("website.entity-hour-fraction-start") + "/" + trans("website.entity-hour-fraction-end")}
                      </div>
                      <div className="row ms-1 me-1" style={{ flexWrap: "wrap" }}>
                        {!product?.entity_rent_minutes_fraction_break || product?.entity_rent_minutes_fraction_break === 0 ? (
                          selectedRentalStartAt ? (
                            Object.values(product?.settings?.slots)?.map((time, index, times) => {
                              let unavailables = filteredSlots
                                ? filteredSlots
                                    ?.map((v) => {
                                      return Object.values(v).map((slt) => slt.split(" ")[1]);
                                    })
                                    ?.flat()
                                : null;

                              let actualUnavailable = unavailables ? unavailables[0] === time && unavailables[1] === times[index + 1] : false;

                              if (index < times.length - 1) {
                                return (
                                  <div
                                    onClick={() => {
                                      if (actualUnavailable) {
                                        return;
                                      }

                                      setSelectedShift({
                                        hour_start_at: time,
                                        hour_end_at: times[index + 1],
                                      });
                                    }}
                                    className={`col-4 col-md-3 rounded-2 p-2 text-center shadow-sm ${actualUnavailable ? "text-gray bg-gray" : "text-black"}`}
                                    style={
                                      actualUnavailable
                                        ? CSS({
                                            cursor: "default",
                                            color: "#a9a9a9",
                                          })
                                        : selectedShift["hour_start_at"] === time && selectedShift["hour_end_at"] === times[index + 1]
                                        ? CSS({
                                            cursor: "pointer",
                                            border: "1px solid #9bc63f",
                                          })
                                        : CSS({
                                            cursor: "pointer",
                                            border: "1px solid #cccccc25",
                                          })
                                    }
                                  >
                                    <div className="m-1">
                                      {time} até {times[index + 1]}
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })
                          ) : (
                            <></>
                          )
                        ) : (
                          Object.values(product?.settings?.slots)?.map((time, index, times) => {
                            return (
                              <div
                                onClick={() => {
                                  setSelectedShift({
                                    hour_start_at: time,
                                    hour_end_at: Object.values(product?.settings?.slotsEnd)[index],
                                  });
                                }}
                                className="col-4 col-md-3 rounded-2 p-2 text-center shadow-sm text-black"
                                style={
                                  selectedShift["hour_start_at"] === time && selectedShift["hour_end_at"] === Object.values(product?.settings?.slotsEnd)[index]
                                    ? CSS({
                                        cursor: "pointer",
                                        border: "1px solid #9bc63f",
                                      })
                                    : CSS({
                                        cursor: "pointer",
                                        border: "1px solid #cccccc25",
                                      })
                                }
                              >
                                <div className="m-1">
                                  {time} até {Object.values(product?.settings?.slotsEnd)[index]}
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                      {true ? (
                        <></>
                      ) : (
                        <select name="hour_start_at" id="hour_start_at" className="select2 form-control" defaultValue={""}>
                          {/* <option key={0} value="">
                            {trans("website.select")}
                          </option>
                          {Object.values(product?.settings?.slots).map((sl, index) => (
                            <option value={sl} key={index}>
                              {sl}
                            </option>
                          ))} */}
                        </select>
                      )}
                    </div>
                  </div>
                  {true ? (
                    <></>
                  ) : (
                    <div className="col-md-6 col-12 mt-3 mt-md-0">
                      {/* {<div className="form-group">
                        <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{trans("website.entity-hour-fraction-end")}</div>
                        <div className="row gap-3 ms-3">
                          {Object.values(product?.settings?.slots).map((sl, index) => (
                            <div className="col-4 col-md-3 rounded-2 p-2 text-center shadow-sm text-black" key={index}>
                              {sl}
                            </div>
                          ))}
                        </div>
                        <select name="hour_end_at" id="hour_end_at" className="select2 form-control d-none" defaultValue={""}>
                          <option key={0} value="">
                            {trans("website.select")}
                          </option>
                          {Object.values(product?.settings?.slots).map((sl, index) => (
                            <option value={sl} key={index} selected={index === 1}>
                              {sl}
                            </option>
                          ))}
                        </select>
                      </div>} */}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-xs-8 col-sm-9 col-md-6">
                  <FormGroup className="row">
                    <Label className="col-form-label">
                      <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{trans("website.rental_start_at")}</div>
                    </Label>
                    <Col>
                      <DatePicker
                        timeIntervals={60}
                        className="form-control digits"
                        startDate={new Date()}
                        showPopperArrow={false}
                        selected={
                          rentalStartEndDateTime.rental_start_at && rentalStartEndDateTime.rental_start_at_time
                            ? new Date(rentalStartEndDateTime.rental_start_at + " " + rentalStartEndDateTime.rental_start_at_time)
                            : new Date()
                        }
                        showTimeSelect
                        dateFormat="Pp"
                        id="rental_start_at"
                        name="rental_start_at"
                        filterTime={(t: Date) => {
                          let timeNow = new Date();

                          if (t.getTime() > timeNow.getTime()) {
                            return true;
                          }

                          if (t.getHours() < timeNow.getHours() || (t.getHours() === timeNow.getHours() && t.getMinutes() < timeNow.getMinutes())) {
                            return false;
                          }

                          return true;
                        }}
                        filterDate={(d) => filterDays(d)}
                        onKeyDown={(e) => {
                          e.preventDefault();
                          return;
                        }}
                        onChange={(e: Date) => {
                          document.querySelector("#rental_end_at").selected = null;
                          document.querySelector("#rental_end_at").value = null;
                          let dateStartRent = convertDateFormat(e).split(" ");
                          setRentalStartEndDateTime({
                            rental_start_at: dateStartRent[0],
                            rental_start_at_time: dateStartRent[1],
                            rental_end_at: dateStartRent[0],
                            rental_end_at_time: dateStartRent[1],
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </div>
                <div className="col-xs-8 col-sm-9 col-md-6">
                  <FormGroup className="row">
                    <Label className="col-form-label">
                      <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{trans("website.rental_end_at")}</div>
                    </Label>
                    <Col>
                      <DatePicker
                        timeIntervals={60}
                        className="form-control digits"
                        startDate={
                          rentalStartEndDateTime.rental_start_at && rentalStartEndDateTime.rental_start_at_time
                            ? new Date(rentalStartEndDateTime.rental_start_at + " " + rentalStartEndDateTime.rental_start_at_time)
                            : null
                        }
                        showPopperArrow={false}
                        selected={
                          rentalStartEndDateTime.rental_end_at && rentalStartEndDateTime.rental_end_at_time
                            ? new Date(rentalStartEndDateTime.rental_end_at + " " + rentalStartEndDateTime.rental_end_at_time)
                            : null
                        }
                        showTimeSelect
                        dateFormat="Pp"
                        id="rental_end_at"
                        name="rental_end_at"
                        filterTime={(t: Date) => {
                          const checkInDate =
                            rentalStartEndDateTime.rental_start_at && rentalStartEndDateTime.rental_start_at_time
                              ? new Date(rentalStartEndDateTime.rental_start_at + " " + rentalStartEndDateTime.rental_start_at_time)
                              : new Date();

                          const isSameDay = t.toDateString() === checkInDate.toDateString();

                          // Allow same day but ensure checkout time is later
                          if (isSameDay && t.getTime() <= checkInDate.getTime()) {
                            return false;
                          }

                          return true;
                        }}
                        filterDate={(d) => {
                          const checkInDate =
                            rentalStartEndDateTime.rental_start_at && rentalStartEndDateTime.rental_start_at_time
                              ? new Date(rentalStartEndDateTime.rental_start_at + " " + rentalStartEndDateTime.rental_start_at_time)
                              : new Date();

                          return filterDays(d, checkInDate);
                        }}
                        onKeyDown={(e) => {
                          e.preventDefault();
                          return;
                        }}
                        onChange={(e: Date) => {
                          const dateEndRent = convertDateFormat(e).split(" ");
                          setRentalStartEndDateTime({
                            ...rentalStartEndDateTime,
                            rental_end_at: dateEndRent[0],
                            rental_end_at_time: dateEndRent[1],
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </div>
              </div>
            )}
          </>
        );
      case "daily":
        return (
          <div className="form-group">
            <label for="datepicker" className="form-label text-bold-600 font-medium-2 fw-bold ms-2 mb-2">
              <i className="fas fa-arrow-down text-success me-1"></i>
              <i className="fas fa-arrow-up text-danger me-3"></i>
              {trans("common.date")}:
            </label>
            <DateRangedata setSelectedRentalStartAt={setSelectedRentalStartAt} selectedRentalStartAt={selectedRentalStartAt} shiftSelected={shiftSelected} />
          </div>
        );
      default:
        return null;
    }
  };

  return product?.type === "entities" ? renderRental() : null;
}

function EntitySelection({ product, appSettings, setSelectedEntity, selectedEntity, selectedRentalStartAt, trans }) {
  const [entitiesAvailable, setEntitiesAvailable] = useState([]);

  // const checkDayAvailablity = async () => {
  //   const res = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), `/crm/v2/template/onecheckout/availability_entities`)
  //     .api()
  //     .post({
  //       data: {
  //         product_id: product?.id,
  //         entity_token: selectedEntity,
  //         rental_date: selectedRentalStartAt,
  //       },
  //     });

  //   console.log(res);

  //   // if (res?.data) {
  //   //   setMeoLink(res?.data?.url);
  //   // } else {
  //   //   toast(trans("website.unexpected-error"));
  //   // }
  // };

  // useEffect(() => {
  //   if (selectedEntity && selectedRentalStartAt) {
  //     checkDayAvailablity();
  //   }
  // }, [selectedEntity, selectedRentalStartAt]);

  useEffect(() => {
    if (product?.settings?.entitiesAvailable?.length > 0 && product?.entity_selection) {
      const availableEntities = product?.settings?.entitiesAvailable?.filter((entity) => entity.status === "for-rent" && String(entity.is_active) === "1");
      setEntitiesAvailable(availableEntities);
    }
  }, [product?.settings?.entitiesAvailable, product?.entity_selection]);

  useEffect(() => {
    if (entitiesAvailable?.length > 0) {
      setSelectedEntity(entitiesAvailable[0]?.token);
    }
  }, [entitiesAvailable]);

  if (entitiesAvailable?.length <= 1) {
    return null;
  }

  return (
    <div className="form-group">
      <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{trans("website.product-select-entity")}</div>
      <select
        name="entity"
        id="entity"
        className="select2 form-control"
        value={selectedEntity}
        onChange={(e) => {
          setSelectedEntity(e.target.value);
        }}
      >
        <option key={0} value="" disabled>
          {trans("website.select")}
        </option>
        {entitiesAvailable?.map((entity) => (
          <option key={entity.token} value={entity.token} selected={entity.token}>
            {entity.name} {entity.code}
          </option>
        ))}
      </select>
    </div>
  );
}

function ProductAgeRanges({ product, appSettings, selectedAgeGroups, setSelectedAgeGroups, trans }) {
  return (
    <div className="row" style={CSS({ flexWrap: "wrap" })}>
      {product?.settings.productAgeRanges?.map((prodAgeRange, index) => (
        <div
          key={index}
          className={
            product?.rental_type === "daily" && product?.calendar_type && appSettings?.entity_product_catalog_show_availability_calendar
              ? "col-12 col-sm-6 col-md-4"
              : "col-md-4"
          }
        >
          <div className="form-group">
            <label className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{prodAgeRange.age_range.name}</label>
            <div className="input-group d-flex flex-row" id="quantity-container-age-range" style={{ maxWidth: "380px" }}>
              <button
                onClick={() => {
                  setSelectedAgeGroups((v) => {
                    let newVal = Math.ceil(v[`quantity_${prodAgeRange.age_range.uuid}`] ?? prodAgeRange.participants_min);
                    if (newVal <= prodAgeRange.participants_min) {
                      return {
                        ...selectedAgeGroups,
                        [`quantity_${prodAgeRange.age_range.uuid}`]: prodAgeRange.participants_min,
                      };
                    }
                    return {
                      ...selectedAgeGroups,
                      [`quantity_${prodAgeRange.age_range.uuid}`]: newVal - 1,
                    };
                  });
                }}
                className="btn bg-transparent shadow-sm d-flex align-items-center justify-content-center"
                style={CSS({ width: 10, border: "1px solid #00000025" })}
              >
                <i className="fa fa-minus text-muted"></i>
              </button>
              <input
                type="number"
                step={0}
                onChange={(e) => {
                  setSelectedAgeGroups(() => {
                    let newVal = Math.ceil(Number(e.target.value));
                    if (newVal <= prodAgeRange.participants_min) {
                      return {
                        ...selectedAgeGroups,
                        [`quantity_${prodAgeRange.age_range.uuid}`]: prodAgeRange.participants_min,
                      };
                    }
                    if (newVal >= prodAgeRange.participants_max) {
                      return {
                        ...selectedAgeGroups,
                        [`quantity_${prodAgeRange.age_range.uuid}`]: prodAgeRange.participants_max,
                      };
                    }
                    return {
                      ...selectedAgeGroups,
                      [`quantity_${prodAgeRange.age_range.uuid}`]: newVal,
                    };
                  });
                }}
                value={selectedAgeGroups[`quantity_${prodAgeRange.age_range.uuid}`] ?? (prodAgeRange.participants_min || 0)}
                id={`quantity_${prodAgeRange.age_range.uuid}`}
                className="text-center form-control touchspinAgeRange"
                data-min={prodAgeRange.participants_min || 0}
                data-max={prodAgeRange.participants_max || 999999}
                name={`quantity_${prodAgeRange.age_range.uuid}`}
                min="0"
              />
              <button
                onClick={() => {
                  setSelectedAgeGroups((v) => {
                    let newVal = Math.ceil(v[`quantity_${prodAgeRange.age_range.uuid}`] ?? prodAgeRange.participants_min);
                    if (newVal >= prodAgeRange.participants_max) {
                      return {
                        ...selectedAgeGroups,
                        [`quantity_${prodAgeRange.age_range.uuid}`]: prodAgeRange.participants_max,
                      };
                    }
                    return {
                      ...selectedAgeGroups,
                      [`quantity_${prodAgeRange.age_range.uuid}`]: newVal + 1,
                    };
                  });
                }}
                className="btn bg-transparent shadow-sm d-flex align-items-center justify-content-center"
                style={CSS({ width: 10, border: "1px solid #00000025" })}
              >
                <i className="fa fa-plus text-primary"></i>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function CustomFieldsComponent({ product, customFields, fileCustomField, setFileCustomField, setCustomFieldsAnswers, customFieldsAnswers, trans }) {
  const [excludedOptionsCustomFields, setExcludedOptionsCustomFields] = useState([]);
  const [includedOptionsCustomFields, setIncludedOptionsCustomFields] = useState([]);
  const [cmsProductCustomField, setCmsProductCustomField] = useState(null);

  useEffect(() => {
    try {
      setExcludedOptionsCustomFields(JSON.parse(product?.custom_fields_excluded_options));
    } catch (e) {
      setExcludedOptionsCustomFields([]);
    }

    try {
      setIncludedOptionsCustomFields(JSON.parse(product?.custom_fields_included_options));
    } catch (e) {
      setIncludedOptionsCustomFields([]);
    }

    // const cmsField = sessionStorage.getItem('cms_product_custom_field');
    // if (cmsField) {
    //   setCmsProductCustomField(JSON.parse(cmsField));
    // }
  }, [product?.id]);

  const renderField = (field) => {
    if (!field || !field?.id) return;

    let customFieldID = field.id;
    let excludedOptions = [];
    let includedOptions = [];

    // if (
    //   Array.isArray(excludedOptionsCustomFields) &&
    //   excludedOptionsCustomFields[customFieldID]
    // ) {
    //   excludedOptions = excludedOptionsCustomFields[customFieldID];
    // } else if (
    //   typeof excludedOptionsCustomFields === "object" &&
    //   excludedOptionsCustomFields[customFieldID]
    // ) {
    //   excludedOptions = excludedOptionsCustomFields[customFieldID];
    // }

    // if (
    //   Array.isArray(includedOptionsCustomFields) &&
    //   includedOptionsCustomFields[customFieldID]
    // ) {
    //   includedOptions = includedOptionsCustomFields[customFieldID];
    // } else if (
    //   typeof includedOptionsCustomFields === "object" &&
    //   includedOptionsCustomFields[customFieldID]
    // ) {
    //   includedOptions = includedOptionsCustomFields[customFieldID];
    // }

    let selectedIndexCMS = null;
    // if (
    //   cmsProductCustomField &&
    //   product?.token === cmsProductCustomField.product &&
    //   field.id === cmsProductCustomField.custom_field
    // ) {
    //   selectedIndexCMS = cmsProductCustomField.value;
    // }

    const renderInputField = () => {
      if (!field || !field?.id) return;

      switch (field.type) {
        case "information_contract":
          return (
            <input
              type="text"
              class="form-control mb-2"
              name={`custom_fields_data[${field.id}]`}
              id={`custom-fields-data-${product.id}-${field.id}`}
              placeholder={field.label}
              required={field.required === "yes"}
              onChange={(e) => {
                setCustomFieldsAnswers({
                  ...customFieldsAnswers,
                  [`${field.id}`]: e.target.value,
                });
              }}
            />
          );
        case "subdomain_ebsss":
          return (
            <input
              type="url"
              required={field.required === "yes"}
              name={`custom_fields_data[${field.id}]`}
              id={`custom-fields-data-${product.id}-${field.id}`}
              placeholder="https://exemplo.com"
              pattern="https?://.*"
              className="form-control mb-2"
              onChange={(e) => {
                setCustomFieldsAnswers({
                  ...customFieldsAnswers,
                  [`${field.id}`]: e.target.value,
                });
              }}
            />
          );
        case "text":
          return (
            <input
              type="text"
              required={field.required === "yes"}
              name={`custom_fields_data[${field.id}]`}
              id={`custom-fields-data-${product.id}-${field.id}`}
              className="form-control mb-2"
              placeholder={field.label}
              onChange={(e) => {
                setCustomFieldsAnswers({
                  ...customFieldsAnswers,
                  [`${field.id}`]: e.target.value,
                });
              }}
            />
          );
        case "password":
          return (
            <input
              type="password"
              required={field.required === "yes"}
              name={`custom_fields_data[${field.id}]`}
              id={`custom-fields-data-${product.id}-${field.id}`}
              className="form-control mb-2"
              placeholder={field.label}
              onChange={(e) => {
                setCustomFieldsAnswers({
                  ...customFieldsAnswers,
                  [`${field.id}`]: e.target.value,
                });
              }}
            />
          );
        case "file":
          return (
            <>
              <input
                type="file"
                required={field.required === "yes"}
                name={`file_${field.id}`}
                id={`custom-fields-data-${product.id}-${field.id}`}
                className="form-control mb-2"
                placeholder={field.label}
                onChange={(e) => {
                  setFileCustomField(e.target.files[0]);
                  setCustomFieldsAnswers({
                    ...customFieldsAnswers,
                    [`${field.id}`]: 1,
                  });
                }}
              />
              <input type="hidden" name={`custom_fields_data[${field.id}]`} value="1" />
            </>
          );
        case "number":
          return (
            <input
              type="number"
              required={field.required === "yes"}
              name={`custom_fields_data[${field.id}]`}
              id={`custom-fields-data-${product.id}-${field.id}`}
              className="form-control mb-2"
              placeholder={field.label}
              onChange={(e) => {
                setCustomFieldsAnswers({
                  ...customFieldsAnswers,
                  [`${field.id}`]: e.target.value,
                });
              }}
            />
          );
        case "textarea":
          return (
            <textarea
              name={`custom_fields_data[${field.id}]`}
              id={`custom-fields-data-${product.id}-${field.id}`}
              required={field.required === "yes"}
              className="form-control mb-2"
              cols={3}
              onChange={(e) => {
                setCustomFieldsAnswers({
                  ...customFieldsAnswers,
                  [`${field.id}`]: e.target.value,
                });
              }}
            ></textarea>
          );
        case "radio":
          const radioValues = parseSelectValues(field, includedOptions, excludedOptions);
          return (
            <div className="radio-list d-flex flex-column ms-4 mb-2" id={`custom-fields-data-${product.id}-${field.id}`} asradio="1">
              {radioValues.map((value, key) => (
                <label
                  className="radio-inline mb-0"
                  key={key}
                  onClick={(e) => {
                    setCustomFieldsAnswers({
                      ...customFieldsAnswers,
                      [`${field.id}`]: value,
                    });
                  }}
                >
                  <div className="radio radio-info">
                    <input
                      type="radio"
                      required={field.required === "yes"}
                      name={`custom_fields_data[${field.id}]`}
                      value={value}
                      className="icheck d-none"
                      checked={customFieldsAnswers[field.id] === value}
                    />
                    <label htmlFor={`custom-fields-data-${product.id}-${field.id}`}>{value}</label>
                  </div>
                </label>
              ))}
            </div>
          );
        case "select":
          const selectValues = parseSelectValues(field, includedOptions, excludedOptions);
          return (
            <select
              id={`custom-fields-data-${product.id}-${field.id}`}
              name={`custom_fields_data[${field.id}]`}
              className="form-control gender mb-2"
              defaultValue={selectedIndexCMS}
              onChange={(e) => {
                setCustomFieldsAnswers({
                  ...customFieldsAnswers,
                  [`${field.id}`]: e.target.value,
                });
              }}
            >
              {selectValues.map((value, key) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          );
        case "checkbox":
          return (
            <div className="mt-checkbox-inline mb-2" id={`custom-fields-data-${product.id}-${field.id}`} ascheckbox="1">
              {field.values.map((value, key) => (
                <label
                  className="mt-checkbox mt-checkbox-outline"
                  key={key}
                  onClick={(e) => {
                    setCustomFieldsAnswers({
                      ...customFieldsAnswers,
                      [`${field.id}`]: key,
                    });
                  }}
                >
                  <input
                    required={field.required === "yes"}
                    name={`custom_fields_data[${field.id}][]`}
                    id={`custom-fields-data-${product.id}-${field.id}`}
                    type="checkbox"
                    value={key}
                    checked={customFieldsAnswers[field.id] === key}
                  />{" "}
                  {value}
                  <span></span>
                </label>
              ))}
            </div>
          );
        case "date":
          return (
            <input
              id={`custom-fields-data-${product.id}-${field.id}`}
              type="date"
              required={field.required === "yes"}
              className="form-control date-picker mb-2"
              size={16}
              name={`custom_fields_data[${field.id}]`}
              onChange={(e) => {
                setCustomFieldsAnswers({
                  ...customFieldsAnswers,
                  [`${field.id}`]: e.target.value,
                });
              }}
            />
          );
        default:
          return null;
      }
    };

    return (
      <div className="form-group" key={field.id}>
        <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{field.label}</div>
        {renderInputField()}
        <div id={`invalid-${field.id}-feedback-validation`}></div>
      </div>
    );
  };

  const parseSelectValues = (field, includedOptions, excludedOptions) => {
    let values = [];
    try {
      values = JSON.parse(field.values);
    } catch (e) {
      values = [];
    }

    // if (includedOptions.length > 0) {
    //   values = values.filter((value, key) =>
    //     includedOptions.includes(`val_${key}`)
    //   );
    // }

    // if (excludedOptions.length > 0) {
    //   values = values.filter(
    //     (value, key) => !excludedOptions.includes(`val_${key}`)
    //   );
    // }

    //values.sort();
    return values;
  };

  return <>{customFields && Array.isArray(customFields) && customFields?.length > 0 ? customFields?.map((field) => renderField(field)) : <></>}</>;
}

function PhysicalProduct({
  product,
  setProduct,
  setVariationSelected,
  appSettings,
  trans,
  setQuantity,
  decrementQuantity,
  quantity,
  incrementQuantity,
  variationChain,
  setVariationChain,
  currentlyVariations,
  setCurrentlyVariations,
}) {
  return (
    <>
      {!document.querySelector(".iclient-cart-quantity") && product?.type === "physical" && (
        <>
          {product?.product_type === "variation" || currentlyVariations ? (
            <>
              <input type="hidden" name="child" id="child" />
              <div className="form-group">
                <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{trans("website.child_products")}</div>

                <select
                  name="child1"
                  id="child1"
                  className="select2 form-control"
                  defaultValue={""}
                  onChange={(e) => {
                    setVariationSelected(e.target.value);
                    setVariationChain([]);
                  }}
                  defaultValue={""}
                >
                  <option key={0} value="">
                    {appSettings?.groupNameFirstLevel ? appSettings?.groupNameFirstLevel : trans("website.select")}
                  </option>
                  {currentlyVariations?.map((cc) => (
                    <option key={cc.id} value={cc.id}>
                      {cc.name}
                    </option>
                  ))}
                </select>

                {variationChain?.map((vch, i) => {
                  // let chkGroupName =
                  //    i > 0
                  //      ? variationChain[i - 1]?.value?.find(
                  //          (vGn) => vGn?.group_name
                  //        )?.group_name
                  //        ? variationChain[i - 1]?.value?.find(
                  //            (vGn) => vGn?.group_name
                  //          )?.group_name
                  //        : trans("website.select")
                  //      : vch?.group_name
                  //      ? vch?.group_name
                  //      : trans("website.select");
                  let chkGroupName = vch?.value?.find((vGn) => vGn?.group_name)?.group_name;
                  return (
                    <select
                      onChange={(e) => {
                        if (!e.target.value) {
                          return;
                        }
                        if (vch?.type === "product") {
                          setProduct(vch.value.find((vchP) => Number(vchP.id) === Number(e.target.value)));
                          return;
                        }
                        setVariationSelected(e.target.value);
                        if (variationChain.length > i + 1) {
                          setVariationChain(variationChain.slice(0, i + 1));
                        }
                      }}
                      defaultValue={""}
                      key={i + 2}
                      name={`child${i + 2}`}
                      id={`child${i + 2}`}
                      className="select2 form-control mt-1"
                      defaultValue={""}
                    >
                      <option key={0} value="">
                        {chkGroupName ? chkGroupName : trans("website.select")}
                      </option>
                      {vch.value && vch.value?.length > 0 && !!vch.value[0] ? (
                        vch.value?.map((vchv) => {
                          return (
                            <option key={vchv?.id} value={vchv?.id}>
                              {vchv?.name}
                            </option>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </select>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="form-group mt-3">
            <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{trans("website.quantity")}</div>
            <div className="input-group d-flex flex-row" id="quantity-container-age-range" style={{ maxWidth: "180px" }}>
              <button
                className="btn bg-transparent shadow-sm d-flex align-items-center justify-content-center"
                style={CSS({ width: 10, border: "1px solid #00000025" })}
                onClick={decrementQuantity}
              >
                <i className="fa fa-minus text-muted"></i>
              </button>
              <input
                onChange={(e) => {
                  let val = parseInt(e.target.value);
                  if (val === 0 || val < 0) {
                    val = 1;
                  }
                  setQuantity(val || 1);
                }}
                style={{ width: "40px" }}
                value={quantity}
                type="text"
                className="text-center form-control touchspinAgeRange"
                min="1"
              />
              <button
                className="btn bg-transparent shadow-sm d-flex align-items-center justify-content-center"
                style={CSS({ width: 10, border: "1px solid #00000025" })}
                onClick={incrementQuantity}
              >
                <i className="fa fa-plus text-success"></i>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
function ProductPaymentTypes({ product, appSettings, setter, getter, trans }) {
  return product && product?.product_payment_types && product?.product_payment_types?.length > 1 ? (
    <div className="form-group mt-3">
      <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{trans("website.sale-payment-type")}</div>
      {product?.product_payment_types?.map((payType) => {
        return (
          <div
            className="d-flex align-items-center col-md-6"
            style={CSS({
              borderRadius: 15,
              padding: 10,
              margin: 10,
              border: "1px solid #e9e9e9",
            })}
          >
            <div
              key={payType.id}
              onClick={() => setter(payType.payment_type.token)}
              class="mt-2 ms-2 radio radio-primary align-self-center d-flex align-items-center"
            >
              <input
                id={payType.payment_type.id + "-radio-payment-type"}
                name={payType.payment_type.id + "-radio-payment-type"}
                type="radio"
                class="form-check-input"
                value={payType.payment_type.token}
                checked={getter === payType.payment_type.token}
              />
              <label for="radio1" class="form-label">
                {payType.payment_type.name}
                {/*appSettings?.currency_symbol + " " + payType.price},{" "}
                {trans("website." + payType.payment_type.frequency).toLowerCase()*/}
              </label>
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
}

function ProductHasPeopleLimitation({ product, trans, decrementQuantity, incrementQuantity, quantity }) {
  const [defaultPeople, setDefaultPeople] = useState([]);

  return (
    <div className="form-group mt-3">
      <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{trans("website.entity-people")}</div>
      <div className="input-group d-flex flex-row" id="quantity-container-age-range" style={{ maxWidth: "180px" }}>
        <button
          className="btn bg-transparent shadow-sm d-flex align-items-center justify-content-center"
          style={CSS({ width: 10, border: "1px solid #00000025" })}
          onClick={decrementQuantity}
        >
          <i className="fa fa-minus text-muted"></i>
        </button>
        <input
          onChange={(e) => {
            let val = parseInt(e.target.value);
            if (val === 0 || val < 0) {
              val = 1;
            }
            setQuantity(val || 1);
          }}
          style={{ width: "40px" }}
          value={quantity}
          type="text"
          className="text-center form-control touchspinAgeRange"
          min="1"
        />
        <button
          className="btn bg-transparent shadow-sm d-flex align-items-center justify-content-center"
          style={CSS({ width: 10, border: "1px solid #00000025" })}
          onClick={incrementQuantity}
        >
          <i className="fa fa-plus text-success"></i>
        </button>
      </div>
    </div>
  );
}

function ProductExtras({ selectedExtras, setSelectedExtras, product, profile, currencySymbol, appSettings, trans }) {
  console.log("Extras: ", selectedExtras);
  return (
    <>
      {product?.product_extras && product?.product_extras?.length > 0 && (
        <div className="form-group mt-3">
          <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{trans("website.product-extras")}</div>

          <CardBody className="megaoptions-border-space-sm p-0 ms-2">
            <div className="mega-inline">
              <div className="d-flex col-12 flex-wrap">
                {product?.product_extras?.map((extra, ie) => {
                  return (
                    <div className={product?.product_extras?.length < 3 ? "col-12 col-lg-6" : "col-12 col-lg-6 col-xl-4"}>
                      <Card
                        style={CSS({ width: "95%", cursor: "pointer" })}
                        onClick={() => {
                          let selectedExtrasBefore = [...selectedExtras];
                          if (selectedExtrasBefore.includes(extra.id)) {
                            setSelectedExtras(selectedExtrasBefore.filter((x) => x !== extra.id));
                            return;
                          }
                          setSelectedExtras([...selectedExtrasBefore, extra.id]);
                        }}
                      >
                        <Media className="p-20">
                          <div className="me-3">
                            <div>
                              <EBSSSCheckbox
                                label={<Label for={"extra-" + extra.id}></Label>}
                                id={"extra-" + extra.id}
                                selected={selectedExtras.includes(extra.id)}
                                action={() => {
                                  let selectedExtrasBefore = [...selectedExtras];
                                  if (selectedExtrasBefore.includes(extra.id)) {
                                    setSelectedExtras(selectedExtrasBefore.filter((x) => x !== extra.id));
                                    return;
                                  }
                                  setSelectedExtras([...selectedExtrasBefore, extra.id]);
                                }}
                              />
                            </div>
                          </div>
                          <Media body>
                            <H6 attrH6={{ className: "mt-0 mega-title-badge" }}>
                              {extra.title}
                              <span className="badge badge-primary pull-right digits">
                                {extra.price_to_show ? (
                                  <>{extra.price_to_show ?? 0}</>
                                ) : (
                                  product?.instant_payment &&
                                  extra.cost > 0 && (
                                    <>
                                      + {extra.cost} {currencySymbol}
                                    </>
                                  )
                                )}
                              </span>
                            </H6>
                            <div className="d-flex flex-row gap-2">
                              {extra?.photo ? (
                                <img width="50px" height="50px" src={`${extra?.photo ? remotePath.assetsUrl(extra?.photo) : ""}`} alt={extra.title} />
                              ) : (
                                <></>
                              )}
                              <P attrPara={{ className: "text-break" }}>{extra.description.trim() ? parseHTML(extra.description) : ""}</P>
                            </div>
                          </Media>
                        </Media>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          </CardBody>

          <div className="row gap-2 d-none" style={CSS({ margin: 1 })}>
            {product?.product_extras?.map((extra, ie) => {
              const colClass =
                product?.product_extras?.length > 3
                  ? "col-sm-12"
                  : product?.rental_type === "daily" && product?.calendar_type && appSettings.entity_product_catalog_show_availability_calendar
                  ? "col-sm-6 col-xs-12"
                  : "col-lg-4 col-md-6 col-sm-12";
              return (
                <Card
                  className={"w-auto mb-0 p-1"}
                  style={
                    selectedExtras?.includes(extra.id)
                      ? CSS({
                          border: "1px solid #9bc63f",
                        })
                      : CSS({
                          border: "1px solid #cccccc25",
                        })
                  }
                  onClick={() =>
                    setSelectedExtras((selectedExtrasBefore) => {
                      if (selectedExtrasBefore.includes(extra.id)) {
                        return selectedExtrasBefore.filter((x) => x.id === extra.id);
                      }
                      return [...selectedExtrasBefore, extra.id];
                    })
                  }
                >
                  <CardBody>
                    <div className={colClass} key={extra.id}>
                      <div>
                        {product?.product_extras?.length <= 3 && extra?.photo ? (
                          <img className="card-img-top img-fluid" src={`${extra?.photo ? remotePath.assetsUrl(extra?.photo) : ""}`} alt={extra.title} />
                        ) : (
                          <></>
                        )}
                        <div className="row">
                          {product?.product_extras?.length <= 3 ? (
                            <div className="d-flex col-md-9 col-sm-12">
                              <H4>{extra?.title}</H4>
                            </div>
                          ) : (
                            <>
                              {extra.photo && (
                                <div className="col-md-3 col-sm-12">
                                  <img
                                    className="card-img-top img-fluid"
                                    src={`${remotePath.assetsUrl(extra.photo)}`}
                                    style={{
                                      height: "60px",
                                      width: "auto",
                                    }}
                                    alt={extra.title}
                                  />
                                </div>
                              )}
                              <div className={`col-md-${extra.photo ? "6" : "9"} col-sm-12`}>
                                <h5>{extra.title}</h5>
                              </div>
                            </>
                          )}
                          {extra.description?.trim() ? (
                            <div className="col-sm-3">
                              <button type="button" className="btn btn-outline-info btn-sm" data-toggle="modal" data-target={`#extra_desc_${extra.token}`}>
                                <i className="la la-info-circle"></i>
                              </button>
                            </div>
                          ) : (
                            <div className="col-sm-12">
                              {extra.price_to_show ? (
                                <h5>{extra.price_to_show ?? 0}</h5>
                              ) : (
                                product?.instant_payment &&
                                extra.cost > 0 && (
                                  <h4>
                                    + {extra.cost} {currencySymbol}
                                  </h4>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {extra?.description?.trim() && (
                        <div className="modal fade text-left" id={`extra_desc_${extra.token}`} tabIndex={-1} role="dialog" aria-hidden="true">
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel1">
                                  {extra.title}
                                </h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label={trans("common.close")}>
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">{extra.description}</div>
                              <div className="modal-footer">
                                <button type="button" className="btn grey btn-outline-secondary" data-dismiss="modal">
                                  {trans("common.close")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

const ProductCardHeader = ({ product, trans }) => {
  const [rating, setRating] = useState(0);
  const changeRating = (newRating) => {
    setRating(newRating);
  };
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center">
        <div className="product-page-details mt-0">
          <H3>{product?.name}</H3>
        </div>
        {product?.seller ? (
          <div className="d-flex align-items-center gap-2">
            {trans("website.product-sold-by-seller") + " "}
            <Link to={`${process.env.PUBLIC_URL}/crm/seller/${product?.seller.username}`}> {product?.seller.fullName}</Link>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="pro-review d-flex flex-row-reverse justify-content-between align-items-center">
        <SocialIcons product={product} trans={trans} />
        <div className="d-flex gap-2 d-none">
          <Ratings rating={rating} widgetRatedColors="blue" widgetDimensions="20px" widgetSpacings="0px" changeRating={changeRating}>
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
          </Ratings>
        </div>
      </div>
    </Fragment>
  );
};

function generateTwitterShareLink(url, text = "", hashtags = []) {
  // Encode the URL and text for safety
  const encodedUrl = encodeURIComponent(url);
  const encodedText = encodeURIComponent(text);
  const encodedHashtags = hashtags.map(encodeURIComponent).join(",");

  // Construct the Twitter share URL
  let shareUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}`;
  if (text) {
    shareUrl += `&text=${encodedText}`;
  }
  if (hashtags.length > 0) {
    shareUrl += `&hashtags=${encodedHashtags}`;
  }

  return shareUrl;
}

// Dynamically create the share button (React JSX example)
const TwitterShareButton = ({ data }) => (
  <a target="_blank" href={data} className="twitter-share-button" rel="noreferrer">
    <i className="fa fa-x-twitter"></i>
  </a>
);

function generateWhatsAppShareLink(text, url) {
  // Encode the text and URL for safety
  const encodedText = encodeURIComponent(text);
  const encodedUrl = encodeURIComponent(url);

  // Construct the WhatsApp share URL
  return `https://wa.me/?text=${encodedText}%20${encodedUrl}`;
}

// Dynamically create the share button (React JSX example)
const WhatsAppShareButton = ({ data }) => (
  <a target="_blank" href={data} className="whatsapp-share-button" rel="noreferrer">
    <i className="fa fa-whatsapp"></i>
  </a>
);

const SocialIcons = ({ product, trans }) => {
  return (
    <Fragment>
      <div className="pro-group">
        <Row>
          <Col md="7 xl-50">
            <div className="product-icon">
              <UL
                attrUL={{
                  className: "simple-list d-flex flex-row product-social",
                }}
              >
                <LI>
                  <div
                    class="fb-share-button"
                    data-href={"https://" + window.location.host + process.env.PUBLIC_URL + "/crm/products/" + product?.slug}
                    data-layout=""
                    data-size=""
                  >
                    <a
                      target="_blank"
                      href={
                        "https://www.facebook.com/sharer/sharer.php?u=" +
                        encodeURIComponent("https://" + window.location.host + process.env.PUBLIC_URL + "/crm/products/" + product?.slug)
                      }
                      className="fb-xfbml-parse-ignore"
                      rel="noreferrer"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </div>
                </LI>
                <LI>
                  <TwitterShareButton
                    data={generateTwitterShareLink(
                      "https://" + window.location.host + process.env.PUBLIC_URL + "/crm/products/" + product?.slug,
                      trans("website.twitter-share-text"),
                      [trans("website.twitter-share-hashtag-1"), trans("website.twitter-share-hashtag-2"), trans("website.twitter-share-hashtag-3")]
                    )}
                  />
                </LI>
                <LI>
                  <WhatsAppShareButton
                    data={generateWhatsAppShareLink(
                      trans("website.whatsapp-share-text"),
                      "https://" + window.location.host + process.env.PUBLIC_URL + "/crm/products/" + product?.slug
                    )}
                  />
                </LI>
              </UL>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

const BrandShipping = () => {
  return (
    <Fragment>
      <Col xl="2" md="6" className="box-col-5 xl-50 proorder-lg-1">
        <Card>
          <CardBody>
            <div className="filter-block">
              <H4>{"Brand"}</H4>
              <UL attrUL={{ className: "simple-list" }}>
                <LI>
                  <div className="form-check">
                    <Input className="form-check-input" id="Raymond" type="checkbox" value="" />
                    <Label className="form-check-label" htmlFor="Raymond">
                      Raymond
                    </Label>
                  </div>
                </LI>
                <LI>
                  <div className="form-check">
                    <Input className="form-check-input" id="Pepe-Jeans" type="checkbox" value="" />
                    <Label className="form-check-label" htmlFor="Pepe-Jeans">
                      Pepe-Jeans
                    </Label>
                  </div>
                </LI>
                <LI>
                  <div className="form-check">
                    <Input className="form-check-input" id="Celio" type="checkbox" value="" />
                    <Label className="form-check-label" htmlFor="Celio">
                      Celio
                    </Label>
                  </div>
                </LI>
                <LI>
                  <div className="form-check">
                    <Input className="form-check-input" id="aime" type="checkbox" value="" />
                    <Label className="form-check-label" htmlFor="aime">
                      aime
                    </Label>
                  </div>
                </LI>
                <LI>
                  <div className="form-check">
                    <Input className="form-check-input" id="aliff" type="checkbox" value="" />
                    <Label className="form-check-label" htmlFor="aliff">
                      aliff
                    </Label>
                  </div>
                </LI>
              </UL>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="collection-filter-block">
              <UL attrUL={{ className: "simple-list pro-services" }}>
                <LI>
                  <Media>
                    <Truck />
                    <Media body>
                      <H5>{"Free Shipping"}</H5>
                      <P>{"Free Shipping World Wide"}</P>
                    </Media>
                  </Media>
                </LI>
                <LI>
                  <Media>
                    <Clock />
                    <Media body>
                      <H5>{"24 X 7 Service"}</H5>
                      <P>{"Online Service For New Customer"}</P>
                    </Media>
                  </Media>
                </LI>
              </UL>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

const TabProductDetails = ({ product, trans }) => {
  const [activeTab, setActiveTab] = useState("1");
  return (
    <Fragment>
      <Nav tabs className="border-tab flex-wrap">
        <NavItem id="myTab" role="tablist" className="w-100">
          <NavLink
            style={CSS({ cursor: "pointer" })}
            className={activeTab === "1" ? "active" : ""}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("1");
            }}
          >
            {trans("website.details")}
          </NavLink>
          <div className="material-border"></div>
        </NavItem>
        <NavItem className="d-none" id="myTab" role="tablist">
          <NavLink
            style={CSS({ cursor: "pointer" })}
            className={activeTab === "2" ? "active" : ""}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("2");
            }}
          >
            {trans("website.specifications")}
          </NavLink>
          <div className="material-border"></div>
        </NavItem>
        <NavItem className="d-none" id="myTab" role="tablist">
          <NavLink
            style={CSS({ cursor: "pointer" })}
            className={activeTab === "3" ? "active" : ""}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("3");
            }}
          >
            {trans("website.video")}
          </NavLink>
          <div className="material-border"></div>
        </NavItem>
        <NavItem className="d-none" id="myTab" role="tablist">
          <NavLink
            style={CSS({ cursor: "pointer" })}
            className={activeTab === "4" ? "active" : ""}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("4");
            }}
          >
            {trans("website.others")}
          </NavLink>
          <div className="material-border"></div>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <P attrPara={{ className: "mb-0 m-t-20" }}>
            {product?.description ? <div dangerouslySetInnerHTML={{ __html: product?.description }}></div> : trans("crn.no_description")}
          </P>
        </TabPane>
        <TabPane tabId="2">
          <P attrPara={{ className: "mb-0 m-t-20" }}>{product?.description ?? trans("crn.no_specifications")}</P>
        </TabPane>
        <TabPane tabId="3">
          <P attrPara={{ className: "mb-0 m-t-20" }}>{product?.description ?? trans("crn.no_videos")}</P>
        </TabPane>
        <TabPane tabId="4">
          <P attrPara={{ className: "mb-0 m-t-20" }}>{product?.description ?? trans("crn.no_more_data")}</P>
        </TabPane>
      </TabContent>
    </Fragment>
  );
};

const ImageSlider = ({ product }) => {
  return Array.isArray(product?.images) && product?.images?.length > 0 ? (
    <ProductSlider product={product} />
  ) : (
    <Col className="product-main">
      <div className="pro-slide-single">
        <Media src={remotePath.assetsUrl(product.photo)} alt="" className="img-fluid rounded-4 w-100" />
      </div>
    </Col>
  );
};

const ProductDetails = ({
  isCMS,
  cmsOption,
  cmsExtra,
  cmsVariation,
  cmsPaymentType,
  product,
  cmsStyleButtons,
  setProduct,
  appSettings,
  htmlPageSettings,
  trans,
  sessionNow,
  ebsssLayout,
  setQuantity,
  quantity,
  incrementQuantity,
  decrementQuantity,
  selectedExtras,
  setSelectedExtras,
  paymentTypeSelected,
  setPaymentTypeSelected,
  variationSelected,
  setVariationSelected,
  variationSelectedObject,
  setVariationSelectedObject,
  shiftSelected,
  setShiftSelected,
  datePicker,
  setDatePicker,
  datePickerRef,
  optionProductSelected,
  setOptionProductSelected,
  selectedAgeGroups,
  setSelectedAgeGroups,
  selectedRentalStartAt,
  setSelectedRentalStartAt,
  rentalStartEndDateTime,
  setRentalStartEndDateTime,
  selectedShift,
  setSelectedShift,
  setSelectedEntity,
  selectedEntity,
  variationChain,
  setVariationChain,
  currentlyVariations,
  setCurrentlyVariations,
  addToCart,
  addBookmarks,
  fileCustomField,
  setFileCustomField,
  setCustomFieldsAnswers,
  customFieldsAnswers,
  formValidation,
  filteredSlots,
}) => {
  const [modalTerms, setModalTerms] = useState(false);
  const toggleTerms = () => {
    setModalTerms(!modalTerms);
  };
  const history = useNavigate();
  const addWishList = (product) => {
    history(`${process.env.PUBLIC_URL}/ecommerce/wishlist`);
  };

  const TermsContract = ({ pd }) => {
    const parsedHTML = parseHTMLToReact(pd?.contract_terms_parsed?.content);

    return <div>{parsedHTML}</div>;
  };

  return (
    <Fragment>
      <Col md={product?.photo && !isCMS ? "7" : "12"} style={isCMS ? { marginTop: -25 } : {}}>
        <div style={isCMS ? { borderColor: "#fff" } : {}} className={isCMS ? "bg-transparent" : "card"}>
          <div className={!isCMS ? "card-body" : ""} style={isCMS ? { padding: 0 } : {}}>
            <div className="row text-muted">
              <div
                className={
                  "pro-group pt-0 border-0 " + !product?.photo && product?.settings?.slots && Object.values(product?.settings.slots)?.length ? "col-12" : ""
                }
              >
                {!isCMS ? <ProductCardHeader product={product} trans={trans} /> : <></>}
                {!isCMS || htmlPageSettings.type !== "crm_product" ? (
                  <div className="product-price pt-2 d-flex flex-row">
                    <ProductPrice
                      optionProductSelected={optionProductSelected}
                      product={product}
                      currencySymbol={appSettings?.currency_symbol}
                      appSettings={appSettings}
                      trans={trans}
                    />
                    <PriceDiscount product={product} appSettings={appSettings} trans={trans} />
                  </div>
                ) : (
                  <></>
                )}
                {!isCMS || htmlPageSettings.type !== "crm_product" ? (
                  <div className="pro-group mt-3">
                    <Row>
                      <ProductAttributes product={product} />
                    </Row>
                  </div>
                ) : (
                  <></>
                )}
                <RewardToWin appSettings={appSettings} trans={trans} product={variationSelectedObject ?? product} />
                {!cmsOption || !isCMS ? (
                  <ProductOptions
                    optionProductSelected={optionProductSelected}
                    setOptionProductSelected={setOptionProductSelected}
                    appSettings={appSettings}
                    trans={trans}
                    product={product}
                  />
                ) : (
                  <></>
                )}
                {product?.photo ? (
                  <ProductRental
                    filteredSlots={filteredSlots}
                    selectedShift={selectedShift}
                    setSelectedShift={setSelectedShift}
                    setSelectedRentalStartAt={setSelectedRentalStartAt}
                    selectedRentalStartAt={selectedRentalStartAt}
                    rentalStartEndDateTime={rentalStartEndDateTime}
                    setRentalStartEndDateTime={setRentalStartEndDateTime}
                    appSettings={appSettings}
                    trans={trans}
                    product={product}
                    setShiftSelected={setShiftSelected}
                    shiftSelected={shiftSelected}
                  />
                ) : (
                  <></>
                )}
                {htmlPageSettings?.type !== "crm_product" || !isCMS ? (
                  <ProductExtras
                    selectedExtras={selectedExtras}
                    setSelectedExtras={setSelectedExtras}
                    product={product}
                    appSettings={appSettings}
                    currencySymbol={appSettings?.currency_symbol}
                    profile={sessionNow?.user?.profile}
                    trans={trans}
                  />
                ) : (
                  <></>
                )}
                <EntitySelection
                  selectedRentalStartAt={setSelectedRentalStartAt}
                  setSelectedEntity={setSelectedEntity}
                  selectedEntity={selectedEntity}
                  appSettings={appSettings}
                  trans={trans}
                  product={product}
                />
                {product?.contract_id && product?.contract_validate ? (
                  <div className="form-group mt-3">
                    <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">{product?.contract_validate_translated}</div>
                  </div>
                ) : (
                  <></>
                )}
                {product?.contract_term_id ? (
                  <div className="form-group mt-3">
                    <div className="text-bold-600 font-medium-2 fw-bold ms-2 mb-2">
                      {trans("website.contract-terms")}:{" "}
                      <EBSSSModal
                        modalSize="modal-lg"
                        hideSubmitButton
                        modalTitle={trans("website.contract-terms")}
                        modal={modalTerms}
                        toggle={toggleTerms}
                        triggerEl={
                          <div style={CSS({ fontSize: 14 })} className="d-flex">
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                toggleTerms();
                              }}
                              className="link-primary text-decoration-none"
                              style={CSS({ cursor: "pointer" })}
                            >
                              <i className="fa fa-file"></i> {trans("website.see_contract")}
                            </a>
                          </div>
                        }
                      >
                        <div>
                          <h1>{product?.contract_terms_parsed?.version}</h1>
                          <TermsContract pd={product} />
                        </div>
                      </EBSSSModal>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {(!cmsPaymentType || !isCMS) && product?.product_payment_types && product?.product_payment_types?.length > 1 ? (
                  <ProductPaymentTypes setter={setPaymentTypeSelected} getter={paymentTypeSelected} appSettings={appSettings} trans={trans} product={product} />
                ) : (
                  <></>
                )}
                <div className="pro-group border-0 pb-0">
                  <div className="pro-shop d-flex align-items-center justify-content-end gap-3 cartbtn">
                    {product?.product_type !== "variation" ? (
                      <>
                        {!isCMS ? (
                          <div
                            onClick={async () => {
                              await addBookmarks(product?.token).then((r) => {
                                console.log("Result after bookmark: ", r);
                                let isntInFavs = !product?.settings?.favorited;
                                toast(
                                  isntInFavs
                                    ? product?.name + " " + trans("website.added-successfully-to-favorites")
                                    : product?.name + " " + trans("website.removed-successfully-from-favorites"),
                                  { type: isntInFavs ? "success" : "error" }
                                );
                                return;
                              });
                            }}
                            className="d-inline"
                            style={CSS({ cursor: "pointer" })}
                          >
                            <i className={`fa fa-heart ${!product?.settings?.favorited ? "text-secondary" : "text-danger"} fs-4`}></i>
                          </div>
                        ) : (
                          <></>
                        )}
                        <CatalogButtons
                          cmsStyleButtons={isCMS ? cmsStyleButtons : {}}
                          formValidation={formValidation}
                          trans={trans}
                          appSettings={appSettings}
                          product={product}
                          history={history}
                          addToCart={addToCart}
                          paymentTypeSelected={paymentTypeSelected}
                          sessionNow={sessionNow}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  "pro-group pt-0 border-0 " + !product?.photo && product?.settings?.slots && Object.values(product?.settings?.slots)?.length
                    ? "col-12 col-md-6 "
                    : ""
                }
              >
                {!product?.photo ? (
                  <ProductRental
                    filteredSlots={filteredSlots}
                    selectedShift={selectedShift}
                    setSelectedShift={setSelectedShift}
                    rentalStartEndDateTime={rentalStartEndDateTime}
                    setRentalStartEndDateTime={setRentalStartEndDateTime}
                    appSettings={appSettings}
                    trans={trans}
                    product={product}
                    setShiftSelected={setShiftSelected}
                    shiftSelected={shiftSelected}
                    selectedRentalStartAt={selectedRentalStartAt}
                    setSelectedRentalStartAt={setSelectedRentalStartAt}
                    noPhoto={true}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        {product?.product_payment_types?.length > 1 ||
        product?.settings?.customFields ||
        product?.settings?.productAgeRanges ||
        product?.entities?.length > 0 ||
        product?.type === "physical" ? (
          <div style={isCMS ? { borderColor: "#fff" } : {}} className={isCMS ? "bg-transparent" : "card"}>
            <div className={!isCMS ? "card-body" : ""}>
              <div className="pro-group pt-0 border-0 text-muted">
                {product?.settings?.customFields ? (
                  <CustomFieldsComponent
                    fileCustomField={fileCustomField}
                    setFileCustomField={setFileCustomField}
                    setCustomFieldsAnswers={setCustomFieldsAnswers}
                    customFieldsAnswers={customFieldsAnswers}
                    appSettings={appSettings}
                    trans={trans}
                    product={product}
                    customFields={product?.settings?.customFields}
                  />
                ) : (
                  <></>
                )}
                {product?.settings.productAgeRanges &&
                product?.entities?.length > 0 &&
                product?.entities[0]?.entity_type &&
                product?.entities[0]?.entity_type?.controlled_by_age ? (
                  <ProductAgeRanges
                    selectedAgeGroups={selectedAgeGroups}
                    setSelectedAgeGroups={setSelectedAgeGroups}
                    appSettings={appSettings}
                    trans={trans}
                    product={product}
                  />
                ) : product?.entities?.length > 0 && product?.entities[0]?.entity_type && product?.entities[0]?.entity_type?.has_people_limitation ? (
                  <ProductHasPeopleLimitation
                    quantity={quantity}
                    product={product}
                    trans={trans}
                    decrementQuantity={decrementQuantity}
                    incrementQuantity={incrementQuantity}
                  />
                ) : (
                  <></>
                )}
                {(!isCMS || !cmsVariation) && product?.type === "physical" ? (
                  <PhysicalProduct
                    setProduct={setProduct}
                    setQuantity={setQuantity}
                    decrementQuantity={decrementQuantity}
                    quantity={quantity}
                    incrementQuantity={incrementQuantity}
                    appSettings={appSettings}
                    trans={trans}
                    product={product}
                    setVariationSelected={setVariationSelected}
                    variationChain={variationChain}
                    setVariationChain={setVariationChain}
                    currentlyVariations={currentlyVariations}
                    setCurrentlyVariations={setCurrentlyVariations}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="pro-group border-0 pb-0 text-muted">
                <div className="pro-shop d-flex align-items-center justify-content-end gap-3 cartbtn">
                  {product?.product_type !== "variation" ? (
                    <>
                      {!isCMS ? (
                        <div
                          onClick={async () => {
                            await addBookmarks(product?.token).then((r) => {
                              console.log("Result after bookmark: ", r);
                              let isntInFavs = !product?.settings?.favorited;
                              toast(
                                isntInFavs
                                  ? product?.name + " " + trans("website.added-successfully-to-favorites")
                                  : product?.name + " " + trans("website.removed-successfully-from-favorites"),
                                { type: isntInFavs ? "success" : "error" }
                              );
                              return;
                            });
                          }}
                          className="d-inline"
                          style={CSS({ cursor: "pointer" })}
                        >
                          <i className={`fa fa-heart ${!product?.settings?.favorited ? "text-secondary" : "text-danger"} fs-4`}></i>
                        </div>
                      ) : (
                        <></>
                      )}
                      <CatalogButtons
                        cmsStyleButtons={isCMS ? cmsStyleButtons : {}}
                        formValidation={formValidation}
                        trans={trans}
                        appSettings={appSettings}
                        product={product}
                        history={history}
                        addToCart={addToCart}
                        paymentTypeSelected={paymentTypeSelected}
                        sessionNow={sessionNow}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Col>
    </Fragment>
  );
};

const ProductPage = ({
  isCMS,
  cmsExtra,
  cmsOption,
  cmsVariation,
  cmsPaymentType,
  cmsStyleButtons,
  openModal,
}: {
  isCMS?: boolean;
  cmsStyleButtons?: any;
  cmsExtra?: any;
  cmsOption?: any;
  cmsVariation?: any;
  cmsPaymentType?: any;
  openModal?: boolean;
}) => {
  const {
    apiErrors,
    loaded,
    product,
    appSettings,
    productVariation,
    htmlPageSettings,
    setLoaded,
    fetchProductVariation,
    fetchProduct,
    setProduct,
    setProductVariation,
    addItemToCart,
    getCart,
    addBookmarks,
    setAppSettings,
    checkDatesEntity,
  } = useEBSSS();
  const dispatch = useDispatch();
  const { trans, setCMSLayout } = useEBSSSLayout();
  const [formValidation] = useFormValidation(trans);
  const [toShowGrid, setToShowGrid] = useState(false);
  const [paymentTypeSelected, setPaymentTypeSelected] = useState(null);
  const [optionProductSelected, setOptionProductSelected] = useState(!!cmsOption ? cmsOption : null);
  const [variationSelected, setVariationSelected] = useState(null);
  const [variationChain, setVariationChain] = useState([]);
  const [currentlyVariations, setCurrentlyVariations] = useState(null);
  const [currentlyIdVariations, setCurrentlyIdVariations] = useState(null);
  const [rentalStartEndDateTime, setRentalStartEndDateTime] = useState({});
  const [variationSelectedObject, setVariationSelectedObject] = useState(null);
  const [selectedExtras, setSelectedExtras] = useState(!!cmsExtra ? cmsExtra : []);
  const [selectedAgeGroups, setSelectedAgeGroups] = useState({});
  const [selectedRentalStartAt, setSelectedRentalStartAt] = useState(null);
  const [selectedShift, setSelectedShift] = useState({});
  const [selectedEntity, setSelectedEntity] = useState("");
  const [shiftSelected, setShiftSelected] = useState({ week_day: "" });
  const [customFieldsAnswers, setCustomFieldsAnswers] = useState({});
  const [fileCustomField, setFileCustomField] = useState(null);
  const [datePicker, setDatePicker] = useState(null);
  const datePickerRef = useRef(document.createElement("div"));
  const [quantity, setQuantity] = useState(1);
  const [dateAvailabilityEntity, setDateAvailabilityEntity] = useState(null);
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const getProduct = useParams();
  let location = useLocation();
  const nav = useNavigate();

  const incrementQuantity = () => {
    setQuantity((v) => {
      if (v === 0 || v < 0) {
        v = 1;
      }
      return v + 1 || 1;
    });
  };
  const decrementQuantity = () => {
    setQuantity((v) => {
      if (v === 0 || v < 0) {
        v = 1;
      }
      return v - 1 || 1;
    });
  };

  function checkAvailabilityCart(item, entity, start, end) {
    if (item.rent_entity && item.rent_entity.id === entity.id) {
      if (
        (new Date(item.rent_start_at).getTime() <= new Date(start).getTime() && new Date(item.rent_end_at).getTime() >= new Date(end).getTime()) ||
        (new Date(item.rent_start_at).getTime() <= new Date(start).getTime() && new Date(item.rent_end_at).getTime() >= new Date(start).getTime()) ||
        (new Date(item.rent_start_at).getTime() <= new Date(end).getTime() && new Date(item.rent_end_at).getTime() >= new Date(end).getTime())
      ) {
        return false;
      }
    }

    return true;
  }

  const addToCart = async (token, payment, returnFormProduct = false) => {
    let qtt = quantity;

    if (isCMS && document.querySelector(".iclient-cart-quantity")) {
      qtt = Number(document.querySelector(".iclient-cart-quantity")?.value);
    }

    if (!product) {
      return;
    }

    //let isAvailableEntity = [];
    // if (product?.entities && product?.entities?.length > 0) {
    //   let pretendedDate = Array.isArray(selectedRentalStartAt)
    //     ? parseDateArray(selectedRentalStartAt)
    //     : selectedRentalStartAt;

    //   sessionNow?.session?.cart?.forEach((item) => {
    //     product?.entities?.forEach((ent) => {
    //       let startAt = pretendedDate;
    //       let endAt = "";

    //       if (startAt.includes(" - ")) {
    //         const startEnd = startAt.split(" - ");
    //         startAt = startEnd[0];
    //         endAt = startEnd[1];
    //       } else {
    //         if (pretendedDate.rent_end_at) {
    //           endAt = pretendedDate.rent_end_at;
    //         }
    //       }

    //       isAvailableEntity.push(
    //         checkAvailabilityCart(item, ent, startAt, endAt)
    //       );
    //     });
    //   });
    // }

    // if (isAvailableEntity.some((v) => !v)) {
    //   toast(trans("website.entity-already-reserved-on-this-cart-booking"));
    //   return;
    // }

    let cartAdditionalPayload = {
      ...selectedAgeGroups,
    };

    if (
      JSON.stringify(rentalStartEndDateTime) === "{}" &&
      (product?.rental_type === "shift_time" || product?.rental_type === "hourly" || product?.rental_type === "daily")
    ) {
      if (!selectedRentalStartAt) {
        toast(trans("website.select_the_date"));
        return;
      }
    }

    if (selectedRentalStartAt) {
      if (product?.entities?.length > 0 && !product?.entities[0]?.entity_type?.has_people_limitation) {
        qtt = null;
      } else {
        cartAdditionalPayload["quantity"] = qtt;
      }
      if (Array.isArray(selectedRentalStartAt)) {
        cartAdditionalPayload["rental_start_at"] = parseDateArray(selectedRentalStartAt);
      } else {
        cartAdditionalPayload["rental_start_at"] = selectedRentalStartAt;
      }
    }

    if (JSON.stringify(rentalStartEndDateTime) !== "{}") {
      cartAdditionalPayload = {
        ...cartAdditionalPayload,
        ...rentalStartEndDateTime,
      };
    }

    if (selectedExtras.length > 0) {
      cartAdditionalPayload["extra"] = selectedExtras;
    }

    if (product?.product_entities && product?.product_entities?.length === 1) {
      cartAdditionalPayload["temp_entity"] = product?.product_entities[0]?.id;
    }

    if (selectedEntity) {
      cartAdditionalPayload["entity"] = selectedEntity;
    }

    if (shiftSelected && shiftSelected?.token) {
      cartAdditionalPayload["shift_time"] = shiftSelected?.token;
    }

    if (optionProductSelected) {
      cartAdditionalPayload["option"] = optionProductSelected?.value;
    }

    if (
      !product?.entity_rent_minutes_fraction &&
      product?.rental_type === "hourly" &&
      isValidDate(rental_start_at) &&
      isValidTime(rental_start_at_time) &&
      isValidDate(rental_end_at) &&
      isValidTime(rental_end_at_time)
    ) {
      toast(trans("website.please-fill-all-the-dates"), { type: "error" });
      return;
    }

    if (Object.keys(rentalStartEndDateTime).length === 4) {
      qtt = null;
      cartAdditionalPayload = {
        ...cartAdditionalPayload,
        ...rentalStartEndDateTime,
      };
    }

    if (Object.keys(selectedShift).length === 2) {
      qtt = null;
      cartAdditionalPayload = {
        ...cartAdditionalPayload,
        ...selectedShift,
      };
    }

    if (product?.settings?.customFields?.length > 0) {
      formValidation(
        product?.settings?.customFields
          ?.filter((cfr) => cfr.required === "yes")
          ?.map((cfr) => {
            return {
              inputIdentifier: `custom-fields-data-${product.id}-${cfr.id}`,
              feedbackElement: cfr.id + "-feedback",
              rules: {
                required: true,
              },
            };
          })
      );
      //}

      if (!window["VALIDATION_SUCCESS"]) {
        return "custom_field_validation_fail";
      }
    }

    if (Object.keys(customFieldsAnswers).length > 0) {
      cartAdditionalPayload = {
        ...cartAdditionalPayload,
        custom_fields_data: customFieldsAnswers,
      };
    }

    if (fileCustomField) {
      cartAdditionalPayload = {
        ...cartAdditionalPayload,
        [`file_` + product?.settings?.customFields.find((cf) => cf.type === "file")?.id]: fileCustomField,
      };
    }

    if (returnFormProduct) {
      let pload = {
        product_token: token,
        ...cartAdditionalPayload,
      };

      if (qtt) {
        pload["quantity"] = qtt;
      }

      if (payment) {
        pload["payment_type"] = payment;
      }
      return pload;
    }

    await addItemToCart(token, payment, qtt, cartAdditionalPayload).then((r) => {
      if (r.data && !r.errors) {
        if (sessionNow?.session?._selected_seller) {
          dispatch(clearSelectedSeller());
        }
        toast(product?.name + " " + trans("website.added-successfully-to-cart"), { type: "success" });
      }
    });
  };

  useEffect(() => {
    if (isCMS) {
      setCMSLayout(true);
    }
  }, [isCMS]);

  useEffect(() => {
    if (product?.settings?.slots && selectedEntity) {
      let getEntityId = product?.settings?.entitiesAvailable?.find((ett) => ett.token === selectedEntity);
      if (getEntityId) {
        setSelectedShift({});
        console.log("getEntityId: ", getEntityId);
        if (!selectedRentalStartAt && !Array.isArray(selectedRentalStartAt)) {
          checkDatesEntity(product?.slug, getEntityId?.id, new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()).then(
            (r) => {
              setDateAvailabilityEntity(r);
            }
          );
        } else {
          checkDatesEntity(product?.slug, getEntityId?.id, selectedRentalStartAt).then((r) => {
            setDateAvailabilityEntity(r);
          });
        }
      }
    }
  }, [selectedRentalStartAt, selectedEntity]);

  useEffect(() => {
    console.log("cmsExtra: ", cmsExtra, "\n", "cmsOption: ", cmsOption, "\n", "cmsVariation: ", cmsVariation, "\n", "cmsPaymentType: ", cmsPaymentType, "\n");

    if (cmsExtra) {
      setSelectedExtras([...cmsExtra]);
    }
    if (cmsOption) {
      setOptionProductSelected(cmsOption);
    }
    if (cmsVariation) {
      setVariationSelected(cmsVariation);
    }
    if (cmsPaymentType) {
      setPaymentTypeSelected(cmsPaymentType);
    }
  }, [cmsExtra, cmsOption, cmsPaymentType, cmsVariation]);

  useEffect(() => {
    scrollUpPage();
    if (product?.settings?.productAgeRanges) {
      const initialAgeGroups = {};
      product.settings.productAgeRanges.forEach((prodAgeRange) => {
        const uuid = prodAgeRange.age_range.uuid;
        initialAgeGroups[`quantity_${uuid}`] = Math.ceil(selectedAgeGroups[`quantity_${uuid}`] ?? prodAgeRange.participants_min);
      });
      setSelectedAgeGroups((prev) => ({ ...prev, ...initialAgeGroups }));
    }
    if (product?.options?.length > 0 && !cmsOption) {
      product.options.forEach((opt) => {
        if (opt.values && opt?.values?.length > 0) {
          opt.values.forEach((opv, i) => {
            if (i === 0) {
              console.log(opv.product_option_id);
              setOptionProductSelected({
                option: opt.token,
                value: opv.token,
                product_option_id: opv.product_option_id,
                price: product?.options?.find((optFind) => optFind.token === opt.token)?.values?.find((valFind) => valFind.token === opv.token)?.price,
              });
            }
          });
        }
      });
    }
    if ((!isCMS || !cmsPaymentType) && product?.product_payment_types && product?.product_payment_types[0]) {
      setPaymentTypeSelected(product?.product_payment_types[0]?.payment_type?.token ?? "");
    }
    if (product?.settings?.shiftTimes && Object.values(product?.settings?.shiftTimes) && Object.values(product?.settings?.shiftTimes).length === 1) {
      setShiftSelected(Object.values(product?.settings?.shiftTimes)[0]);
    }
  }, [product?.id, shiftSelected, document]);

  useEffect(() => {
    if (product?.settings?.productsChild && product?.settings?.productsChild?.length > 0 && !currentlyVariations && !currentlyIdVariations) {
      setCurrentlyVariations(product?.settings?.productsChild);
      setCurrentlyIdVariations(product?.id);
    }
  }, [product?.id]);

  useEffect(() => {
    if ((!isCMS || !cmsVariation) && product && variationSelected && currentlyVariations && currentlyVariations?.length > 0) {
      fetchProductVariation(currentlyIdVariations, variationSelected);
    }
  }, [variationSelected]);

  useEffect(() => {
    if (productVariation) {
      setVariationChain((s) => [...s, productVariation]);
    }
  }, [productVariation]);

  useEffect(() => {
    if (optionProductSelected && !cmsOption) {
      let { price, photo, title } =
        product?.options
          ?.map((po) => po?.values)
          ?.flat()
          ?.find((op) => op?.token === optionProductSelected?.value) ?? {};

      setProduct({
        ...product,
        price,
        photo,
        title: product?.title + " " + title,
      });
    }
  }, [optionProductSelected]);

  useEffect(() => {
    if (!isCMS) {
      if (!location.state?.product) {
        fetchProduct(getProduct?.slug);
      } else {
        setProduct(location.state?.product);
      }
    }
  }, []);

  useEffect(() => {
    let checkDoubleCartBtns = setInterval(() => {
      let addCartBtns = document.querySelectorAll(".cartbtn");

      if (addCartBtns.length === 2) {
        addCartBtns[0].remove();
        clearInterval(checkDoubleCartBtns);
      } else if (addCartBtns.length === 1) {
        clearInterval(checkDoubleCartBtns);
      } else {
        return;
      }
    }, 10);
  }, [document, window]);

  useEffect(() => {
    setToShowGrid(!loaded);
  }, [loaded]);

  useEffect(() => {
    if (!toShowGrid) {
      let fadeGrid = setTimeout(() => {
        document.querySelector(".product-wrapper-unit")?.classList?.add("show");
        clearTimeout(fadeGrid);
      }, 150);
    }
  }, [toShowGrid]);

  useEffect(() => {
    if (!sessionNow?.user?.id && apiErrors && (apiErrors?.require_login || !apiErrors?.public_products)) {
      nav(`${process.env.PUBLIC_URL}/auth/sign-in`);
    }
  }, [apiErrors]);

  useEffect(() => {
    if (sessionNow.message.cart) {
      toast(sessionNow.message.cart);
    }
  }, [sessionNow.message]);

  console.log("OPTION: ,", optionProductSelected);

  useEffect(() => {
    dispatch(cleanErrorsAndMessages());

    return () => {
      setCurrentlyIdVariations(null);
      setCurrentlyVariations(null);
      setProductVariation(null);
      setPaymentTypeSelected(null);
      setOptionProductSelected(null);
      setVariationSelected(null);
      setSelectedExtras([]);
      dispatch(cleanErrorsAndMessages());
    };
  }, []);

  return (
    <Page
      hideTitle
      isCMS={isCMS}
      breads={[
        {
          name: trans("website.products"),
          active: false,
          url: "/crm/products?page=1",
        },
        {
          name: product?.name ? product?.name : "",
          active: true,
        },
      ]}
    >
      {product ? (
        <Fragment>
          {!loaded ? (
            !isCMS ? (
              <>
                <Row className="product-page-main p-0 d-flex flex-column flex-lg-row">
                  <Col>
                    <div className="w-100 skeleton" style={CSS({ height: 500, padding: 20 })}></div>
                  </Col>
                  <Col>
                    <div className="p-3 w-100 d-flex" style={CSS({ height: "100%" })}>
                      <div className="card w-100 my-2 shadow-2-strong">
                        <div className="card-body d-flex flex-column">
                          <h5 className="mb-1 me-1 fw-bold mb-2 skeleton"></h5>
                          <div className="d-flex flex-row mb-3 skeleton" style={CSS({ height: 30 })}>
                            <h5 className="mb-1 me-1"></h5>
                            <span className="text-danger">
                              <s></s>
                            </span>
                          </div>
                          <p className="card-text skeleton" style={CSS({ height: 70 })}></p>
                        </div>
                        <div className="card-body d-flex flex-column">
                          <h5 className="mb-1 me-1 fw-bold mb-2 skeleton"></h5>
                          <div className="d-flex flex-row mb-3 skeleton" style={CSS({ height: 30 })}>
                            <h5 className="mb-1 me-1"></h5>
                            <span className="text-danger">
                              <s></s>
                            </span>
                          </div>
                          <p className="card-text skeleton align-self-end" style={CSS({ height: 70, width: "50%" })}></p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )
          ) : (
            <div className="fade product-wrapper-unit">
              <Row className="product-page-main p-0">
                {!isCMS && (product?.photo || (Array.isArray(product?.images) && product?.images?.length)) ? (
                  <Col md="5" className="mb-4">
                    <ImageSlider product={product} />
                  </Col>
                ) : (
                  <></>
                )}
                <ProductDetails
                  isCMS={isCMS}
                  cmsOption={cmsOption}
                  cmsExtra={cmsExtra}
                  cmsVariation={cmsVariation}
                  cmsPaymentType={cmsPaymentType}
                  cmsStyleButtons={cmsStyleButtons}
                  filteredSlots={dateAvailabilityEntity}
                  htmlPageSettings={htmlPageSettings}
                  setSelectedEntity={setSelectedEntity}
                  selectedEntity={selectedEntity}
                  selectedShift={selectedShift}
                  setSelectedShift={setSelectedShift}
                  rentalStartEndDateTime={rentalStartEndDateTime}
                  setRentalStartEndDateTime={setRentalStartEndDateTime}
                  selectedRentalStartAt={selectedRentalStartAt}
                  setSelectedRentalStartAt={setSelectedRentalStartAt}
                  selectedAgeGroups={selectedAgeGroups}
                  setSelectedAgeGroups={setSelectedAgeGroups}
                  optionProductSelected={optionProductSelected}
                  setOptionProductSelected={setOptionProductSelected}
                  datePickerRef={datePickerRef}
                  paymentTypeSelected={paymentTypeSelected}
                  setPaymentTypeSelected={setPaymentTypeSelected}
                  variationSelected={variationSelected}
                  setVariationSelected={setVariationSelected}
                  variationSelectedObject={variationSelectedObject}
                  setVariationSelectedObject={setVariationSelectedObject}
                  shiftSelected={shiftSelected}
                  setShiftSelected={setShiftSelected}
                  datePicker={datePicker}
                  setDatePicker={setDatePicker}
                  incrementQuantity={incrementQuantity}
                  decrementQuantity={decrementQuantity}
                  setQuantity={setQuantity}
                  quantity={quantity}
                  product={product}
                  setProduct={setProduct}
                  appSettings={appSettings}
                  trans={trans}
                  sessionNow={sessionNow}
                  ebsssLayout={ebsssLayout}
                  selectedExtras={selectedExtras}
                  setSelectedExtras={setSelectedExtras}
                  variationChain={variationChain}
                  setVariationChain={setVariationChain}
                  currentlyVariations={currentlyVariations}
                  setCurrentlyVariations={setCurrentlyVariations}
                  addToCart={addToCart}
                  addBookmarks={addBookmarks}
                  setFileCustomField={setFileCustomField}
                  fileCustomField={fileCustomField}
                  setCustomFieldsAnswers={setCustomFieldsAnswers}
                  customFieldsAnswers={customFieldsAnswers}
                  formValidation={formValidation}
                />
              </Row>
              {!isCMS ? (
                <Card>
                  <Row className="product-page-main">
                    <Col sm="12">
                      <TabProductDetails product={product} trans={trans} />
                    </Col>
                  </Row>
                </Card>
              ) : (
                <></>
              )}
            </div>
          )}
        </Fragment>
      ) : (
        <></>
      )}
    </Page>
  );
};

export default ProductPage;
