import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { scrollUpPage } from "src/_utils/elements";
import { getQueryParams } from "src/_utils/urls";
import { EBSSSPagination } from "src/base/atoms/EBSSSPagination";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import { ContactCenterMailContain } from "./ContactCenterCommon";
import ContactCenterSidebar from "./ContactCenterSidebar";

const ContactCenterSearchPage = () => {
  const { fetchContactsMadeBySearch, contacts, appSettings } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const [filters, setFilters] = useState("ALL");
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const [page, setPage] = useState(null);
  const getSearchParam = useParams();

  useEffect(() => {
    let actualParams = getQueryParams(window.location.href);
    if (actualParams?.page) {
      setPage((p) => {
        if (p !== null) {
          return p;
        } else {
          return Number(actualParams?.page);
        }
      });
    }
  }, [window.location.search]);

  useEffect(() => {
    scrollUpPage();
    if (contacts) {
      if (page && page > 0) {
        fetchContactsMadeBySearch(getSearchParam?.keyword, page);
      }
    }
  }, [page]);

  useEffect(() => {
    scrollUpPage();
    let actualParams = getQueryParams(window.location.href);
    if (!contacts) {
      fetchContactsMadeBySearch(getSearchParam?.keyword, actualParams?.page);
    }
  }, []);

  return (
    <Page
      breads={[
        {
          name: trans("website.contacts") + " - " + getSearchParam?.keyword,
          active: true,
          url:
            "/crm/contacts/center/search/" +
            getSearchParam?.keyword +
            "?page=1",
        },
      ]}
    >
      <div className="email-wrap mb-3">
        <Row>
          <Col xl="3" md="6" className="xl-30 box-col-3">
            <ContactCenterSidebar
              trans={trans}
              filters={filters}
              setFilters={setFilters}
              contacts={contacts}
              appSettings={appSettings}
            />
          </Col>
          <Col xl="9" md="12" className="xl-70 box-col-9">
            <ContactCenterMailContain
              sessionNow={sessionNow}
              contacts={contacts}
              trans={trans}
            />
            <EBSSSPagination data={contacts} page={page} />
          </Col>
        </Row>
      </div>
    </Page>
  );
};

export default ContactCenterSearchPage;
