//@ts-nocheck
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import { FormsRepliedContain } from "./FormsRepliedCommon";
import FormsRepliedSidebar from "./FormsRepliedSidebar";
import { getQueryParams } from "src/_utils/urls";

const FormsReplied = () => {
  const { trans } = useEBSSSLayout();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const {
    repliedForms,
    repliedFormsAnswer,
    appSettings,
    loaded,
    fetchRepliedForms,
    fetchRepliedFormsAnswers,
  } = useEBSSS();
  const [sentForm, setSentForm] = useState(false);
  const getForm = useParams();

  useEffect(() => {
    if (Number(getForm?.id)) {
      let getQP = getQueryParams(window.location.href);
      fetchRepliedFormsAnswers(Number(getForm?.id), Number(getQP?.category));
    } else {
      fetchRepliedForms();
    }
  }, [getForm]);

  console.log("param form get: ", getForm);
  console.log("repliedFormsAnswer: ", repliedFormsAnswer);

  return (
    <Page
      breads={[
        {
          name: trans("website.form"),
          active: true,
          url: "/forms/replied/filter/all",
        },
      ]}
    >
      <div className="email-wrap mb-3">
        <Row>
          <Col xl="3" md="6" className="xl-30 box-col-3">
            <FormsRepliedSidebar
              trans={trans}
              repliedForms={repliedForms || repliedFormsAnswer}
              appSettings={appSettings}
              formId={Number(getForm?.id)}
            />
          </Col>
          <Col xl="9" md="12" className="xl-70 box-col-9">
            {!getForm?.id ? (
              repliedForms?.all ? (
                <FormsRepliedContain
                  defaultCategory={repliedForms?.default_category}
                  sessionNow={sessionNow}
                  answers={
                    repliedForms?.all.sort((a, b) => {
                      return new Date(a.date) - new Date(b.date); // Ascending order (oldest to newest)
                    }) ?? []
                  }
                  trans={trans}
                />
              ) : (
                <Card
                  className="text-center d-flex justify-content-center"
                  style={{ minHeight: 600 }}
                >
                  <span className="text-center">
                    {trans("website.no_replies_found")}
                  </span>
                </Card>
              )
            ) : repliedFormsAnswer && repliedFormsAnswer?.leads?.length ? (
              <FormsRepliedContain
                defaultCategory={repliedFormsAnswer?.category}
                sessionNow={sessionNow}
                answers={repliedFormsAnswer}
                trans={trans}
              />
            ) : (
              <Card
                className="text-center d-flex justify-content-center"
                style={{ minHeight: 600 }}
              >
                <span className="text-center">
                  {trans("website.no_replies_found")}
                </span>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </Page>
  );
};

export default FormsReplied;
