/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HelperHTTP from "src/_utils/http_module_handler";
import { isActualRoutePublic } from "src/_utils/urls";
import { parseSettingsToBooleanValues } from "src/_utils/validations";
import { remotePath } from "src/base/settings/Paths";
import {
  addItemToCartRequest,
  allowMarketing,
  cartFailure,
  cartItemRemoval,
  cartSuccess,
  countNotifications,
  endAddItemToCart,
  endPaymentProveUpload,
  endSendForm,
  endSendTicket,
  erasureRequesting,
  logout,
  refreshNotifications,
  sendFormRequest,
  sendPaymentProveUpload,
  sendTicketRequest,
  Session,
} from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import { IEBSSSContext } from "./types";
import { Layout } from "src/base/store/layoutSlice";
import { error } from "console";
import { CSS } from "src/_utils/styles";
import EBSSSModal from "src/base/atoms/EBSSSModal";
import { getTrackBackground, Range } from "react-range";
import { IRenderThumbParams, IRenderTrackParams } from "react-range/lib/types";
import { Accordion, Card } from "react-bootstrap";
import { CardBody, CardHeader, Col, Collapse, Form, Label, Row } from "reactstrap";
import { H5, Btn } from "src/AbstractElements";
import { useEBSSSLayout } from "../EBSSSLayoutContext/useEBSSSLayout";

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function cookieExists(name, currentCookiesLevel) {
  return document.cookie.split("; ").indexOf(name + "=" + currentCookiesLevel) !== -1;
}

export const EBSSSContext = createContext<IEBSSSContext>({} as IEBSSSContext);

export const EBSSSProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState<boolean>(true);
  const [loadedHtml, setLoadedHtml] = useState(true);
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const [dashboard, setDashboard] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const { trans, cmsLayout } = useEBSSSLayout();

  //Html Page
  const [htmlPage, setHtmlPage] = useState(null);
  const [htmlPageSettings, setHtmlPageSettings] = useState(null);

  //Order
  const [order, setOrder] = useState(null);
  const [orders, setOrders] = useState(null);
  //Seller
  const [seller, setSeller] = useState(null);
  //Product
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState(null);
  const [productVariation, setProductVariation] = useState(null);
  //Products Category
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [appSettings, setAppSettings] = useState(null);
  //Wallet
  const [wallet, setWallet] = useState({});
  //Reward Wallet
  const [rewardWallet, setRewardWallet] = useState({});
  //Reservations
  const [reservation, setReservation] = useState(null);
  const [reservations, setReservations] = useState(null);
  //Contract Payments
  const [contractPayment, setContractPayment] = useState(null);
  const [contractPayments, setContractPayments] = useState(null);
  //Contract Payments
  const [proposal, setProposal] = useState(null);
  const [proposals, setProposals] = useState(null);
  //Contract Payments
  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState(null);
  //Contract Payments
  const [event, setEvent] = useState(null);
  const [events, setEvents] = useState(null);
  //Contract Payments
  const [contract, setContract] = useState(null);
  const [contracts, setContracts] = useState(null);
  //Notifications
  const [notifications, setNotifications] = useState(null);
  //Terms
  const [TOS, setTOS] = useState(null);
  const [POS, setPOS] = useState(null);
  //FAQ
  const [faqHelpTopics, setFaqHelpTopics] = useState(null);
  //Bookmarks
  const [bookmarks, setBookmarks] = useState(null);
  //Custom Page
  const [customPage, setCustomPage] = useState(null);
  //Tickets
  const [tickets, setTickets] = useState(null);
  //Contacts
  const [contacts, setContacts] = useState(null);
  //Contact
  const [contact, setContact] = useState(null);
  //Forms
  const [forms, setForms] = useState(null);
  //Form
  const [form, setForm] = useState(null);
  //Sellers
  const [sellers, setSellers] = useState(null);
  //TourProducts
  const [tourProducts, setTourProducts] = useState(null);
  //Forms replied
  const [repliedForms, setRepliedForms] = useState(null);
  const [repliedFormsAnswer, setRepliedFormsAnswers] = useState(null);
  //Form replied
  const [repliedForm, setRepliedForm] = useState(null);

  const resetLoginState = () => {
    setOrder(null);
    setOrders(null);
    setProduct(null);
    setProducts(null);
    setProductVariation(null);
    setCategory(null);
    setCategories([]);
    setAppSettings(null);
    setWallet({});
    setReservation(null);
    setReservations(null);
    setContractPayment(null);
    setContractPayments(null);
    setProposal(null);
    setProposals(null);
    setProject(null);
    setProjects(null);
    setEvent(null);
    setEvents(null);
    setContract(null);
    setContracts(null);
    setNotifications(null);
    setTOS(null);
    setPOS(null);
    setFaqHelpTopics(null);
    setBookmarks(null);
    setCustomPage(null);
    setTickets(null);
    setContacts(null);
    setContact(null);
    setForms(null);
    setForm(null);
    setSellers(null);
    setTourProducts(null);
    setRepliedForms(null);
    setRepliedForm(null);
    setSeller(null);
  };

  /**
   * Fetches SIBS status given a payment request token and a transaction ID
   * @param prToken {string} - Payment request token
   * @param transId {string} - Transaction ID
   * @returns {Promise<any>} - The response
   */
  async function fetchSibsStatus(prToken: string, transId: string) {
    try {
      let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.SIBS_STATUS(prToken))
        .appendQuery({ id: transId })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api();

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const data = await beforeReq.get();

      return data;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Clears SIBS status given a payment request token
   * @param prToken {string} - Payment request token
   * @returns {Promise<any>} - The response
   */
  async function fetchSibsClear(prToken: string) {
    try {
      let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.SIBS_CLEAR(prToken))
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api();

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const data = await beforeReq.get();

      return data;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetches the FAQ help topics.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  async function fetchFAQ() {
    try {
      let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.FAQ_HELP_TOPICS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api();

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const { data } = await beforeReq.get();

      if (data) {
        setFaqHelpTopics(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetches the replied forms.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  async function fetchRepliedForms() {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.FORMS_REPLIED)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setRepliedForms(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Attaches a payment request to a contract.
   * @async
   * @function
   * @param {number[]} rpIds The IDs of the payment requests to attach.
   * @returns {Promise<Object|null>} The response data or null.
   */
  async function attachRequestPayments(rpIds: number[]) {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ATTACH_CONTRACT_PAYMENTS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .noSendSessionPayload()
        .api()
        .post({
          data: {
            payment_request_ids: rpIds,
          },
        });

      if (data) {
        return data;
      }

      return null;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetches the replied forms answers.
   * @async
   * @function
   * @param {number} form - The form ID.
   * @param {number} [category] - The category ID.
   * @returns {Promise<void>}
   */
  async function fetchRepliedFormsAnswers(form: number, category?: number) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.FORMS_REPLIED_ANSWERS + "/" + form)
        .authed({ tk: "Bearer " + sessionNow.token })
        .appendQuery({ category_id: category })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setRepliedFormsAnswers(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches the replied form.
   * @async
   * @function
   * @param {number} form - The form ID.
   * @param {boolean} [isDefault=false] - If it is a default form.
   * @returns {Promise<void>}
   */
  async function fetchRepliedForm(form: number, isDefault: boolean = false) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.FORMS_REPLIED_VIEW + "/" + form)
        .authed({ tk: "Bearer " + sessionNow.token })
        .appendQuery({ default: isDefault ? 1 : 0 })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        let { forms, tickets, ...rest } = data;
        setRepliedForms({ forms });
        setTickets(tickets);
        setRepliedForm(rest);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches the full dashboard.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  async function fetchFullDashboard() {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.FULL_DASHBOARD)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setDashboard(data);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function agreeWithTerms(termsToken: string) {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ACCEPT_TERMS + "/" + termsToken)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      return data;
    } catch (error) {
      console.log(error);
    }
  }

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Fetches the terms of service from the server
   * @async
   * @function
   * @returns {Promise<void>}
   */
  /******  af44fe1a-5faf-4dd4-9886-3e7f11b8a0cd  *******/
  async function fetchTOS() {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.TOS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setTOS(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetches the POS data from the server
   * @returns {Promise<void>}
   */
  async function fetchPOS() {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.POS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setPOS(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetches notifications from the server.
   *
   * @async
   * @function
   * @param {number} page - The page number for paginated notifications.
   * @returns {Promise<void>}
   *
   * This function makes an authenticated API request to fetch notifications.
   * It updates the global state with the total count of notifications and refreshes
   * the list of notifications with the latest data. It also sets the loaded state
   * accordingly to indicate loading status.
   *
   * The fetched notifications are limited to the first 4 items in the response.
   * Handles errors by logging them to the console.
   */
  async function fetchNotifications(page: number) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.NOTIFICATIONS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .paginated(page)
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        dispatch(countNotifications({ new_count: data.notifications?.total } as any));
        dispatch(
          refreshNotifications({
            newers: data.notifications?.data.slice(0, 4),
          } as any)
        );
        setNotifications(data.notifications);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }
  /**
   * Removes a specific notification by its token.
   * @async
   * @function
   * @param {string} notificationToken - The token of the notification to be removed.
   * @returns {Promise<void>}
   */
  async function removeNotifications(notificationToken: string) {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.NOTIFICATIONS_DELETE(notificationToken))
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        dispatch(countNotifications({ new_count: notifications?.total - 1 } as any));
        if (sessionNow.user.notifications?.find((notf) => notf.token === notificationToken)) {
          dispatch(
            refreshNotifications({
              newers: sessionNow.user.notifications?.filter((notf) => notf.token !== notificationToken),
            } as any)
          );
        }
        setNotifications({
          ...notifications,
          data: [...notifications.data].filter((ntf) => ntf.token !== notificationToken),
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Removes all notifications of the current user.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  async function removeAllNotifications() {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.NOTIFICATIONS_DELETE_ALL)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        dispatch(countNotifications({ new_count: 0 } as any));
        await fetchNotifications(1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches the seller data and products by category
   * @param sellerSlug The slug of the seller
   * @param page The page number
   * @param categoryToken The token of the category
   */
  async function fetchSeller(sellerSlug: string, page: number, categoryToken: string) {
    setLoaded(false);

    try {
      let beforeReq = HelperHTTP.customRequest(
        remotePath.clientInstanceUrl(),
        categoryToken ? remotePath.endPoints.SELLER + "/" + sellerSlug + "/category/" + categoryToken : remotePath.endPoints.SELLER + "/" + sellerSlug
      )
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .paginated(page)
        .api();

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const { data } = await beforeReq.get();

      console.log(data);

      if (data) {
        setSeller(data);
        setProducts(data?.products);
        setCategories(data?.categories);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches the seller data and products by category with search name
   * @param sellerSlug The slug of the seller
   * @param page The page number
   * @param categoryToken The token of the category
   * @param searchName The search name
   */
  async function fetchSellerSearch(sellerSlug: string, page: number, searchName: string, categoryToken: string) {
    setLoaded(false);

    try {
      let beforeReq = HelperHTTP.customRequest(
        remotePath.clientInstanceUrl(),
        categoryToken ? remotePath.endPoints.SELLER + "/" + sellerSlug + "/category/" + categoryToken : remotePath.endPoints.SELLER + "/" + sellerSlug
      )
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .paginated(page)
        .api();

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const { data } = await beforeReq.post({ data: { filter: { query: searchName }, query: searchName } });

      console.log(data);

      if (data) {
        setSeller(data);
        setProducts(data?.products);
        setCategories(data?.categories);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches the orders of the current user.
   * @param page The page number
   * @async
   * @function
   * @returns {Promise<void>}
   */
  async function fetchOrders(page: number) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ORDERS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .paginated(page)
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setOrders(data);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches the products of the current user.
   * @param page The page number
   * @async
   * @function
   * @returns {Promise<void>}
   */
  async function fetchProducts(page: number) {
    setLoaded(false);
    try {
      let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.PRODUCTS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .paginated(page)
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api();

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const { data, errors } = await beforeReq.get();

      console.log("from try: ", errors);

      if (data) {
        setCategories(data.categories);
        setProducts(data.products);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }

      if (errors) {
        setApiErrors(errors);
      }
    } catch (error) {
      console.log("from catch: ", error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches products by a given query name.
   * @param queryProductName The name of the product to search for.
   * @param page The page number for pagination.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  async function fetchProductsByName(queryProductName: string, page: number) {
    setLoaded(false);
    try {
      let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.PRODUCTS_SEARCH + "/" + queryProductName)
        .paginated(page)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api();

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const { data, errors } = await beforeReq.get();

      if (data) {
        setCategories(data.categories);
        setProducts({
          ...data.products,
          data: Object.values(data.products.data),
        });
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }

      if (errors) {
        setApiErrors(errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches products by a given category slug.
   * @param categorySlug The slug of the category to search for.
   * @param page The page number for pagination.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  async function fetchProductsByCategory(categorySlug: string, page: number) {
    setLoaded(false);
    try {
      let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.PRODUCTS_CATEGORY + "/" + categorySlug)
        .authed({ tk: "Bearer " + sessionNow.token })
        .paginated(page)
        .appendQuery({ slug: true })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api();

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const { data, errors } = await beforeReq.get();

      if (data) {
        if (!Array.isArray(data.products.data)) {
          data.products.data = Object.values(data.products.data);
        }
        setProducts(data.products);
        setCategories(data.categories);
        setCategory(data.categorySelected);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }

      if (errors) {
        setApiErrors(errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches product variation data by product and parent ids.
   * @param productId The id of the product.
   * @param parentId The id of the parent product.
   * @param cb A callback to be called after the data is fetched.
   * @returns {Promise<void>}
   */
  async function fetchProductVariation(productId: number | string, parentId: number | string, cb: () => void) {
    try {
      let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.PRODUCT_VARIATION)
        .authed({ tk: "Bearer " + sessionNow.token })
        .appendQuery({
          product: String(productId),
          parent: String(parentId),
        })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api();

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const { data } = await beforeReq.get();

      if (data) {
        setProductVariation(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetches product data by product slug.
   * @param productSlug The slug of the product to fetch.
   * @param silent If true, suppresses loading state and returns data directly.
   * @async
   * @function
   * @returns {Promise<{product: any, appSettings: any}>} The product data and app settings if silent is true, otherwise void.
   */
  async function fetchProduct(productSlug: number | string, silent = false) {
    if (!silent) {
      setLoaded(false);
    }
    try {
      let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.PRODUCT + "/" + productSlug)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api();

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const { data, errors } = await beforeReq.get();

      if (silent) {
        return { product: data, appSettings: parseSettingsToBooleanValues(data?.settings || data.product.settings) };
      }

      if (data) {
        setProduct(data.product);
        if (data?.settings || data.product.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings || data.product.settings));
        }
      }

      if (errors) {
        setApiErrors(errors);
      }

      if (silent) {
        return data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (!silent) {
        setLoaded(true);
      }
    }
  }

  /**
   * Fetches order data by order token.
   * @param orderToken The token of the order to fetch.
   * @param preventLoadingAnim If true, suppresses the loading animation during the request.
   * @async
   * @function
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to retrieve order details.
   * If the request is successful, it sets the order, app settings, and tickets state.
   * If an error occurs, it logs the error.
   */
  async function fetchOrder(orderToken: string, preventLoadingAnim = false) {
    if (!preventLoadingAnim) {
      setLoaded(false);
    }
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ORDER + "/" + orderToken)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        if (data?.order) {
          setOrder(data.order);
        }
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
        if (data?.tickets) {
          setTickets(data?.tickets);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches the wallet data of the current user.
   * @async
   * @function
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to retrieve wallet details.
   * If the request is successful, it sets the wallet and app settings state.
   * If an error occurs, it logs the error.
   */
  async function fetchWallet() {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.WALLET)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setWallet(data);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches the reward wallet data of the current user.
   * @async
   * @function
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to retrieve reward wallet details.
   * If the request is successful, it sets the reward wallet and app settings state.
   * If an error occurs, it logs the error.
   */
  async function fetchRewardWallet() {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.REWARD_WALLET)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setRewardWallet(data);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches the reservations of the current user.
   * @async
   * @function
   * @param {number} page The page number
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to retrieve reservations.
   * If the request is successful, it sets the reservations and app settings state.
   * If an error occurs, it logs the error.
   */
  async function fetchReservations(page: number) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.RESERVATIONS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .paginated(page)
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setReservations(data.reservations);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches a reservation by its token.
   * @async
   * @function
   * @param {string} reservationToken The token of the reservation to fetch.
   * @param {boolean} [preventLoadingAnim=false] If true, suppresses the loading animation during the request.
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to retrieve a reservation.
   * If the request is successful, it sets the reservation and app settings state.
   * If an error occurs, it logs the error.
   */
  async function fetchReservation(reservationToken: string, preventLoadingAnim = false) {
    if (!preventLoadingAnim) {
      setLoaded(false);
    }
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.RESERVATION + "/" + reservationToken)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setReservation(data.reservation);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
        if (data?.tickets) {
          setTickets(data?.tickets);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches the contract payments of the current user.
   * @async
   * @function
   * @param {number} page The page number
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to retrieve contract payments.
   * If the request is successful, it sets the contract payments and app settings state.
   * If an error occurs, it logs the error.
   */
  async function fetchContractPayments(page: number) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CONTRACT_PAYMENTS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .paginated(page)
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setContractPayments(data.paymentRequests);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches a specific contract payment using the provided token.
   * @async
   * @function
   * @param {string} paymentRequestToken - The token for the specific payment request to fetch.
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to retrieve the contract payment details.
   * If the request is successful, it sets the contract payment and app settings state.
   * If errors occur, they are set in the api errors state.
   * Logs any exceptions during the request.
   */
  async function fetchContractPayment(paymentRequestToken: string) {
    setLoaded(false);
    try {
      const { data, errors } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CONTRACT_PAYMENT + "/" + paymentRequestToken)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setContractPayment(data);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }

      if (errors) {
        setApiErrors(errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches the proposals for the current user.
   * @async
   * @function
   * @param {number} page - The page number for pagination.
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to retrieve proposals.
   * If the request is successful, it updates the proposals and app settings state.
   * If an error occurs, it logs the error.
   */
  async function fetchProposals(page: number) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.PROPOSALS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .paginated(page)
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setProposals(data.proposals);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches a specific proposal using the provided token.
   * @async
   * @function
   * @param {string} proposalToken - The token for the specific proposal to fetch.
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to retrieve the proposal details.
   * If the request is successful, it sets the proposal and app settings state.
   * Logs any exceptions during the request.
   */
  async function fetchProposal(proposalToken: string) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.PROPOSAL + "/" + proposalToken)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setProposal(data.proposal);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches a specific project using the provided token.
   * @async
   * @function
   * @param {string} projectToken - The token for the specific project to fetch.
   * @param {boolean} [preventLoadingAnim=false] - Prevents the loading animation from being shown.
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to retrieve the project details.
   * If the request is successful, it sets the project, app settings, and tickets state.
   * Logs any exceptions during the request.
   */
  async function fetchProject(projectToken: string, preventLoadingAnim = false) {
    if (!preventLoadingAnim) {
      setLoaded(false);
    }
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.PROJECT + "/" + projectToken)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        if (data?.project) {
          setProject(data.project);
        }
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
        if (data?.tickets) {
          setTickets(data?.tickets);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches a paginated list of projects.
   * @async
   * @function
   * @param {number} page - The page number to fetch.
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to the projects endpoint.
   * If the request is successful, it sets the projects and app settings state.
   * Logs any exceptions during the request.
   */
  async function fetchProjects(page: number) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.PROJECTS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .paginated(page)
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setProjects(data.projects);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches a specific event.
   * @async
   * @function
   * @param {string} projectToken - The project token of the event.
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to the specific event endpoint.
   * If the request is successful, it sets the event and app settings state.
   * Logs any exceptions during the request.
   */
  async function fetchEvent(projectToken: string) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.EVENT + "/" + projectToken)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setEvent(data.event);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches a paginated list of events.
   * @async
   * @function
   * @param {number} page - The page number to fetch.
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to the events endpoint.
   * If the request is successful, it sets the events and app settings state.
   * Logs any exceptions during the request.
   */
  async function fetchEvents(page: number) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.EVENTS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .paginated(page)
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setEvents(data.events);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches a paginated list of contracts.
   * @async
   * @function
   * @param {number} page - The page number to fetch.
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to the contracts endpoint.
   * If the request is successful, it sets the contracts and app settings state.
   * Logs any exceptions during the request.
   */
  async function fetchContracts(page: number) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CONTRACTS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .paginated(page)
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setContracts(data.contracts);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches a specific contract by token.
   * @async
   * @function
   * @param {string} contractToken - The contract token to fetch.
   * @param {boolean} [preventLoadingAnim=false] - If true, loading animation is not shown.
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to the contracts endpoint with the contract token.
   * If the request is successful, it sets the contract, app settings, and tickets state.
   * Logs any exceptions during the request.
   */
  async function fetchContract(contractToken: string, preventLoadingAnim = false) {
    if (!preventLoadingAnim) {
      setLoaded(false);
    }
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CONTRACTS + "/" + contractToken)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        if (data?.contract) {
          setContract(data.contract);
        }
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
        if (data?.tickets) {
          setTickets(data.tickets);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Handles a proposal by making an authenticated HTTP GET request to the proposal endpoint for the given type.
   * @async
   * @function
   * @param {string|number} proposalTokenOrId - The proposal token or id to handle.
   * @param {"decline"|"accept"|"reply"} type - The type of proposal handling to perform.
   * @returns {Promise<void>} No return value.
   *
   * The function makes an authenticated HTTP GET request to the proposal endpoint for the given type.
   * If the request is successful, it logs the response data.
   * Logs any exceptions during the request.
   */
  async function handleProposal(proposalTokenOrId: string | number, type: "decline" | "accept" | "reply") {
    setLoaded(false);
    try {
      let typesOfProposalHandling = {
        decline: remotePath.endPoints.PROPOSAL_DECLINE,
        accept: remotePath.endPoints.PROPOSAL_ACCEPT,
        reply: remotePath.endPoints.PROPOSAL_REPLY,
      };

      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), typesOfProposalHandling[type] + "/" + proposalTokenOrId)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Adds an item to the cart. If the user is logged in, uses the user's session token, otherwise uses the untokened session.
   * @async
   * @function
   * @param {string} productToken - The product token to add to the cart.
   * @param {string} [paymentToken] - The payment token to add to the cart.
   * @param {number} [qt] - The quantity to add to the cart.
   * @param {Record<any, any>} [additionalPayload] - Additional payload to add to the cart. If it contains a key that starts with "file_", it will be sent as a file.
   * @returns {Promise<{data: CartSuccessPayload}>} The response data.
   */
  async function addItemToCart(productToken: string, paymentToken: string, qt: number, additionalPayload: Record<any, any>) {
    dispatch(addItemToCartRequest());

    let pload = {
      product_token: productToken,
      ...additionalPayload,
    };

    if (qt) {
      pload["quantity"] = qt;
    }

    if (paymentToken) {
      pload["payment_type"] = paymentToken;
    }

    try {
      const beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_CART_ADD)
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api();

      let postPayload = {
        data: pload,
      };

      // if (Object.keys(additionalPayload).find((k) => k.includes("file_"))) {
      //   beforeReq.hasFile();
      // }

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const res = await beforeReq.post({
        data: pload,
      });

      if (!res.data) {
        dispatch(cartFailure(res));
      }

      dispatch(cartSuccess({ ...res.data }));

      return res;
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endAddItemToCart());
    }
  }

  /**
   * Removes a product from the cart.
   * @param {string} productHash Product hash.
   * @param {string} [token] Token.
   * @returns {Promise<void>}
   */
  async function removeItemFromCart(productHash: string, token = null) {
    if (!sessionNow?.user?.id) {
      dispatch(cartItemRemoval({ hash: productHash } as any));
    }
    try {
      const beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_CART_REMOVE)
        .appendQuery({
          item: productHash,
        })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api();

      let postPayload = {};

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const res = await beforeReq.post({
        data: postPayload,
      });

      dispatch(cartSuccess(res.data));
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetches the cart.
   * @returns {Promise<void>}
   */
  async function getCart() {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_CART)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Uploads the contract payment prove
   * @param {FormData} payload - The payload of the payment prove
   * @returns {Promise<unknown | null>} - The response of the api
   */
  async function handleContractPaymentProveUpload(payload: FormData) {
    dispatch(sendPaymentProveUpload());

    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CONTRACT_PAYMENT_PROVE)
        .noSendSessionPayload()
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .api()
        .post({
          data: payload,
        });

      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endPaymentProveUpload());
    }
  }

  /**
   * Handles the allow marketing emails request.
   * @param {number} allowMarketingEmails - If the user allows marketing emails.
   * @returns {Promise<void>}
   */
  async function handleAllowMarketing(allowMarketingEmails: number) {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ALLOW_MARKETING)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .noSendSessionPayload()
        .api()
        .post({
          data: { allow_marketing_emails: allowMarketingEmails },
        });

      dispatch(allowMarketing({ allowMarketingEmails, data } as any));
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Handles the request erasure request.
   * @param {number} confirm - If the user confirms the erasure.
   * @returns {Promise<void>}
   */
  async function handleRequestErasure(confirm: number) {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.REQUEST_ERASURE)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .noSendSessionPayload()
        .api()
        .post({
          data: { confirm },
        });

      dispatch(erasureRequesting({ confirm, data } as any));
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Downloads the user data.
   * @async
   * @function
   * @returns {Promise<void>}
   */
  async function handleDownloadData() {
    try {
      await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.DOWNLOAD_DATA)
        .authed({ tk: "Bearer " + sessionNow.token })
        .hasBlob()
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      // dispatch(cartSuccess(data));
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetches the user's bookmarks.
   * @async
   * @function
   * @param {number} page - The page number to fetch.
   * @returns {Promise<void>}
   */
  async function getBookmarks(page: number) {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.BOOKMARKS)
        .paginated(page)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setBookmarks(data.favorites);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Adds a bookmark for a given product.
   * @async
   * @function
   * @param {string} tokenProduct - The token identifier for the product to be bookmarked.
   * @returns {Promise<any>} - The response data from the bookmark addition request.
   * @throws Will log an error if the request fails.
   */
  async function addBookmarks(tokenProduct: string) {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.BOOKMARKS_ADD + "/" + tokenProduct)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .api()
        .get();

      if (product) {
        setProduct({
          ...product,
          settings: {
            ...product.settings,
            favorited: data?.message?.includes("added"),
          },
        });
        return;
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Removes a bookmark for a given product.
   * @async
   * @function
   * @param {string} tokenBookmark - The token identifier for the bookmark to be removed.
   * @returns {Promise<any>} - The response data from the bookmark removal request.
   * @throws Will log an error if the request fails.
   */
  async function removeBookmarks(tokenBookmark: string) {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.BOOKMARKS_DELETE + "/" + tokenBookmark)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetches a custom page by its slug.
   * @async
   * @function
   * @param {string} slugCustom - The slug of the custom page to be fetched.
   * @returns {Promise<void>} - Resolves when the request is complete.
   * @throws Will log an error if the request fails.
   */
  async function fetchCustomPage(slugCustom: string) {
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CUSTOM_PAGE + "/" + slugCustom)
        .authed({ tk: "Bearer " + sessionNow.token })
        .appendQuery({ slug: slugCustom })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setCustomPage(data);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetches the contacts made, applying optional filters.
   * @async
   * @function
   * @param {number} page - The page number for pagination.
   * @param {Record<string, any>} [filterBy={ category: "ALL" }] - Optional filters for the contacts query.
   * @returns {Promise<void>} - Resolves when the request is complete.
   * @throws Will log an error if the request fails.
   */
  async function fetchContactsMade(page: number, filterBy: Record<string, any> = { category: "ALL" }) {
    if (!filterBy || (filterBy?.category === "ALL" && !contacts)) {
      setLoaded(false);
    }
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CONTACTS)
        .paginated(page)
        .appendQuery(filterBy)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setContacts(data?.contacts);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (!filterBy || filterBy.category === "ALL") {
        setLoaded(true);
      }
    }
  }

  /**
   * Fetches the contacts made, applying an optional keyword filter.
   * @async
   * @function
   * @param {string} keyword - The keyword to search for.
   * @param {number} page - The page number for pagination.
   * @returns {Promise<void>} - Resolves when the request is complete.
   * @throws Will log an error if the request fails.
   */
  async function fetchContactsMadeBySearch(keyword: string, page: number) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CONTACTS_SEARCH + "/" + keyword)
        .paginated(page)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setContacts(data?.contacts);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches a contact made, either a chat or a ticket, applying an optional replier allowance.
   * @async
   * @function
   * @param {string} type - "chat" or "ticket".
   * @param {string} token - The token to identify the contact made.
   * @param {string} [replierToken] - The replier allowance token, if applicable.
   * @returns {Promise<void>} - Resolves when the request is complete.
   * @throws Will log an error if the request fails.
   */
  async function fetchContactMade(type: "chat" | "ticket", token: string, replierToken?: string) {
    setLoaded(false);

    try {
      const beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CONTACT + "/" + type + "/" + token)
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api();

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      if (replierToken) {
        beforeReq.appendQuery({ replier_allowance: replierToken });
      }

      let { data } = await beforeReq.get();

      if (data) {
        setContact({
          ...data?.contact,
          original: data?.original,
        });
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Sends a ticket to the server, applying an optional replier allowance, and an optional set of files.
   * @async
   * @function
   * @param {FormData} payload - The form data to send with the ticket.
   * @param {boolean} hasTicketFiles - If true, the payload has files to send.
   * @param {string} replierToken - The replier allowance token, if applicable.
   * @returns {Promise<Record<string, any>>} - Resolves with the response data from the server.
   * @throws Will log an error if the request fails.
   */
  async function sendTicket(payload: FormData, hasTicketFiles: boolean, replierToken: string) {
    try {
      dispatch(sendTicketRequest());

      const beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.TICKET_ANSWER)
        .withLanguage(ebsssLayout?.activeLanguage)
        .noSendSessionPayload()
        .api();

      if (sessionNow?.user.id) {
        beforeReq.withUser(sessionNow?.user.id);
        beforeReq.authed({ tk: "Bearer " + sessionNow?.token });
      }

      if (replierToken) {
        beforeReq.appendQuery({ replier_allowance: replierToken });
      }

      if (hasTicketFiles) {
        beforeReq.hasFile();
      }

      let { data } = await beforeReq.post({
        data: payload,
      });

      if (data) {
        return data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endSendTicket());
    }
  }

  /**
   * Fetches forms, sellers, and tour products from the server.
   * @async
   * @function
   * @returns {Promise<void>} - Resolves when the request is complete.
   * @throws Will log an error if the request fails.
   */
  async function fetchForms() {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.FORMS)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setForms(data?.forms);
        setSellers(data?.sellers);
        setTourProducts(data?.tour_products);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Fetches a form by its slug from the server.
   * @async
   * @function
   * @param {string} slugForm - The slug of the form to fetch.
   * @returns {Promise<void>} - Resolves when the request is complete.
   * @throws Will log an error if the request fails.
   */
  async function fetchForm(slugForm: string, lang?: string) {
    setLoaded(false);
    try {
      const { data } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.FORMS + "/" + slugForm)
        .authed({ tk: "Bearer " + sessionNow.token })
        .withLanguage(lang ? lang : ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .api()
        .get();

      if (data) {
        setForm(data);
        if (data?.settings) {
          setAppSettings(parseSettingsToBooleanValues(data?.settings));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  }

  /**
   * Sends a form with the given payload to the server.
   * @async
   * @function
   * @param {FormData} payload - The payload to send to the server.
   * @param {boolean} hasFormFile - Whether the form has a file to send.
   * @returns {Promise<Object | undefined>} - The response from the server, if any.
   * @throws Will log an error if the request fails.
   */
  async function sendForm(payload: FormData, hasFormFile: boolean) {
    try {
      dispatch(sendFormRequest());
      const beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.FORMS_SAVE(form?.form?.slug))
        .withLanguage(ebsssLayout?.activeLanguage)
        .noSendSessionPayload()
        .api();

      if (sessionNow.user.id) {
        // beforeReq.withUser(sessionNow.user.id);
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      if (hasFormFile) {
        beforeReq.hasFile();
      }

      let { data } = await beforeReq.post({
        data: payload,
      });

      if (data) {
        return data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endSendForm());
    }
  }

  /**
   * Sends a product proposal to the server.
   * @async
   * @function
   * @param {Record<string, any>} payload - The payload containing product proposal details.
   * @returns {Promise<Object | undefined>} - The response from the server, if any.
   * @throws Will log an error if the request fails.
   */
  async function sendProductProposal(payload: Record<string, any>) {
    try {
      const beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_PRODUCT_PROPOSAL)
        .withLanguage(ebsssLayout?.activeLanguage)
        .noSendSessionPayload()
        .api();

      let postPayload = { ...payload };

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      let { data } = await beforeReq.post({
        data: postPayload,
      });

      if (data) {
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Handles the state of the entity on the current page.
   * Depending on the type of the entity, it sets the product, app settings, or other necessary data.
   * @param {Object} data - The data about the entity.
   * @param {string} data.current_page_type - The type of the current page.
   * @param {Object} [data.current_page_entity_complete] - The complete entity data.
   */
  const handleEntityState = (data) => {
    if (!data.current_page_type) {
      return;
    }

    setHtmlPageSettings({ type: data.current_page_type });

    if (data.current_page_type === "tour_vehicle") {
    }
    if (data.current_page_type === "tour_product") {
    }
    if (data.current_page_type === "tour_category") {
    }
    if (data.current_page_type === "tour_product_results") {
    }
    if (data.current_page_type === "crm_product") {
      setProduct(data.current_page_entity_complete);
      setAppSettings(data.current_page_entity_complete?.settings);
    }
    if (data.current_page_type === "crm_product_category") {
    }
    if (data.current_page_type === "crm_product_list") {
    }
    if (data.current_page_type === "blog_post") {
    }
    if (data.current_page_type === "blog_author") {
    }
    if (data.current_page_type === "blog_tag") {
    }
    if (data.current_page_type === "blog_category") {
    }
    if (data.current_page_type === "blog_results") {
    }
    if (data.current_page_type === "event") {
    }
    if (data.current_page_type === "event_type") {
    }
    if (data.current_page_type === "event_category") {
    }
    if (data.current_page_type === "event_results") {
    }
    if (data.current_page_type === "seller_address") {
    }
    if (data.current_page_type === "seller_store") {
    }
    if (data.current_page_type === "seller_category") {
    }
    if (data.current_page_type === "seller_results") {
    }
    if (data.current_page_type === "crm_product_results") {
    }
  };

  /**
   * Fetches an HTML page from the server and updates the state accordingly.
   *
   * @async
   * @function
   * @param {Record<any, any>} params - The parameters for the request, including queryParams and page slug.
   * @param {boolean} [setNewState=true] - Determines whether to update the state with the fetched HTML page data.
   * @returns {Promise<string | undefined>} - Returns the HTML content if setNewState is false, otherwise updates the state.
   * @throws Will log an error if the request fails.
   */
  const fetchHtmlPage = async (params: Record<any, any>, setNewState = true) => {
    if (setNewState) {
      setLoadedHtml(false);
    }
    try {
      const beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.HTML_PAGE)
        .withLanguage(ebsssLayout?.activeLanguage)
        .addSessionState(sessionNow?.session ?? {})
        .appendQuery({ ...params.queryParams, slug: params.page })
        .api();

      let postPayload = {};

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      let { data } = await beforeReq.post({
        data: postPayload,
      });

      console.log(data);

      if (!setNewState) {
        return data.html;
      }

      if (data) {
        setHtmlPage(data.html);
        handleEntityState(data);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  /**
   * Checks the availability of an entity for a specific date.
   *
   * @param {string} slug - The slug identifier for the entity.
   * @param {number} entity - The entity ID to check availability for.
   * @param {string} date - The date for which to check the entity's availability.
   * @returns {Promise<any | null>} - Returns the availability data if successful, otherwise null.
   * @throws Will log an error if the request fails.
   */
  const checkDatesEntity = async (slug: string, entity: number, date: string) => {
    try {
      const beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CHECK_DATES_ENTITY_AVAILABILITY).api().appendQuery({
        slug,
        entity,
      });

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      let { data } = await beforeReq.post({
        data: {
          date,
        },
      });

      return data ? data?.unavailable : null;
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    if (!sessionNow?.user?.id || !loaded) {
      if (!isActualRoutePublic(ebsssLayout?.template?.pages)) {
        resetLoginState();
      }
    }
  }, [loaded, sessionNow.user.id]);

  useEffect(() => {
    console.log("Check expiring session...");
    let timeNow = new Date().getTime();
    let timeExp = sessionNow?.exp;

    if (sessionNow?.user?.id && timeNow > timeExp) {
      console.log("Session expiring...");
      dispatch(logout());
      resetLoginState();
      window.location.href = window.location.host + process.env.PUBLIC_URL;
    }
  }, [window.location.href]);

  const [modal, setModal] = useState(false);
  const [values1, setValues1] = useState([4]);
  const [isOpen, setIsOpen] = useState(1);
  const [currentCookiesLevel, setCurrentCookiesLevel] = useState(4);
  const [hideCookiesTooltip, setHideCookiesTooltip] = useState(false);
  console.log(ebsssLayout?.commonAppSettings);

  let cookieName = `${ebsssLayout?.commonAppSettings?.cookie_consent_prefix ?? ""}${ebsssLayout?.commonAppSettings?.tenant ?? ""}_level`;

  function consentWithCookies(toggle = false) {
    setCookie(cookieName, currentCookiesLevel, 365 * 20);
    setHideCookiesTooltip(true);
    if (toggle) {
      toggleModal();
    }
  }
  function consentCookie() {
    if (cookieExists(cookieName, currentCookiesLevel)) {
      toggleModal();
      setHideCookiesTooltip(true);
    }

    consentWithCookies(true);
  }
  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleAccordion = (index: number) => {
    setIsOpen(index);
  };

  useEffect(() => {
    if (getCookie(cookieName)) {
      setHideCookiesTooltip(true);
    }
  }, []);

  useEffect(() => {
    let currLevel = Number(getCookie(cookieName));
    if (!currLevel || currLevel < 1 || currLevel > 4) {
      setCurrentCookiesLevel(4);
      return;
    }
    setCurrentCookiesLevel(currLevel);
  }, []);

  console.log(
    "is cms: ",
    ebsssLayout?.template?.pages?.some((p) => window.location.pathname.startsWith(p.slug))
  );

  const modalCookies = (trigger) => (
    <EBSSSModal
      modalSize={ebsssLayout?.template?.pages?.some((p) => window.location.pathname.startsWith(process.env.PUBLIC_URL + "/" + p.slug)) ? null : "modal-lg"}
      customConfirmButtonText={trans("common.accept")}
      customConfirmAction={consentCookie}
      modalTitle="Cookies"
      modal={modal}
      toggle={toggleModal}
      triggerEl={trigger}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          margin: "1em",
        }}
      >
        <div className="d-flex flex-wrap w-100 flex-column align-items-center">
          <Range
            values={values1}
            step={1}
            min={1}
            max={4}
            onChange={(values1) => {
              setCookie(cookieName, parseInt(String(values1[0])), 365);
              setValues1(values1);
            }}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "36px",
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <output style={{ marginRight: 25 }}>1</output>
                <div
                  ref={props.ref}
                  style={{
                    height: "5px",
                    width: "100%",
                    borderRadius: "4px",
                    background: getTrackBackground({
                      values: values1,
                      colors: ["#4d8aff", "#ccc"],
                      min: 1,
                      max: 4,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
                <output style={{ marginLeft: 25 }}>4</output>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "35px",
                  width: "35px",
                  borderRadius: "30px",
                  backgroundColor: "#FFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 2px 6px #AAA",
                }}
              >
                <div
                  style={{
                    height: "16px",
                    width: "5px",
                    backgroundColor: isDragged ? "#4d8aff" : "#CCC",
                  }}
                />
              </div>
            )}
          />
          <div className="d-flex flex-row align-items-center justify-content-between mt-3" style={CSS({ width: "95%", margin: "auto" })}>
            <div style={CSS({ borderBottom: values1[0] === 1 ? "1px solid green" : "1px solid white", width: "20%" })}>{trans("cms.cookie-level-1")}</div>
            <div style={CSS({ borderBottom: values1[0] === 2 ? "1px solid green" : "1px solid white", width: "35%", textAlign: "center" })}>
              {trans("cms.cookie-level-2")}
            </div>
            <div style={CSS({ borderBottom: values1[0] === 3 ? "1px solid green" : "1px solid white", width: "35%", textAlign: "center" })}>
              {trans("cms.cookie-level-3")}
            </div>
            <div style={CSS({ borderBottom: values1[0] === 4 ? "1px solid green" : "1px solid white", width: "20%", textAlign: "end" })}>
              {trans("cms.cookie-level-4")}
            </div>
          </div>
          <div className="mt-3 w-100">
            <Accordion defaultActiveKey="0">
              <Card className="border-0 m-0">
                <CardHeader className="pb-0">
                  <H5>Cookies</H5>
                  <span>{trans("cms.cookie-consent-informations")}</span>
                </CardHeader>
                <CardBody>
                  <div className="default-according" id="accordion">
                    <Card>
                      <CardHeader>
                        <H5 attrH5={{ className: "mb-0" }}>
                          <Btn attrBtn={{ as: Card.Header, className: "btn btn-link", color: "default", onClick: () => toggleAccordion(1) }}>
                            <span>{trans("cms.cookie-info-title-1")}</span>
                          </Btn>
                        </H5>
                      </CardHeader>
                      <Collapse isOpen={isOpen === 1}>
                        <CardBody>{trans("cms.cookie-info-text-1")}</CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <H5 attrH5={{ className: "mb-0" }}>
                          <Btn attrBtn={{ as: Card.Header, className: "btn btn-link", color: "default", onClick: () => toggleAccordion(2) }}>
                            <span>{trans("cms.cookie-info-title-2")}</span>
                          </Btn>
                        </H5>
                      </CardHeader>
                      <Collapse isOpen={isOpen === 2}>
                        <CardBody>{trans("cms.cookie-info-text-2")}</CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <H5 attrH5={{ className: "mb-0" }}>
                          <Btn attrBtn={{ as: Card.Header, className: "btn btn-link", color: "default", onClick: () => toggleAccordion(3) }}>
                            <span>{trans("cms.cookie-info-title-3")}</span>
                          </Btn>
                        </H5>
                      </CardHeader>
                      <Collapse isOpen={isOpen === 3}>
                        <CardBody>{trans("cms.cookie-info-text-3")}</CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <H5 attrH5={{ className: "mb-0" }}>
                          <Btn attrBtn={{ as: Card.Header, className: "btn btn-link", color: "default", onClick: () => toggleAccordion(4) }}>
                            <span>{trans("cms.cookie-info-title-4")}</span>
                          </Btn>
                        </H5>
                      </CardHeader>
                      <Collapse isOpen={isOpen === 4}>
                        <CardBody>{trans("cms.cookie-info-text-4")}</CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <H5 attrH5={{ className: "mb-0" }}>
                          <Btn attrBtn={{ as: Card.Header, className: "btn btn-link", color: "default", onClick: () => toggleAccordion(5) }}>
                            <span>{trans("cms.cookie-info-title-5")}</span>
                          </Btn>
                        </H5>
                      </CardHeader>
                      <Collapse isOpen={isOpen === 5}>
                        <CardBody>{trans("cms.cookie-info-text-5")}</CardBody>
                      </Collapse>
                    </Card>
                  </div>
                </CardBody>
              </Card>
            </Accordion>
          </div>
        </div>
      </div>
    </EBSSSModal>
  );

  return (
    <EBSSSContext.Provider
      children={
        <div className="relative">
          {children}
          {hideCookiesTooltip ? (
            <div
              style={{
                zIndex: 999,
                position: "fixed",
                left: 0,
                bottom: 0,
                display: "flex",
                borderTopRightRadius: "10px",
                backgroundColor: "white", // Optional: for visibility
                padding: "5px",
                fontSize: 10,
                width: "auto",
                height: 35,
                boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)", // Optional: for style
              }}
            >
              {modalCookies(
                <span style={{ cursor: "pointer" }} onClick={toggleModal}>
                  Cookies
                </span>
              )}
            </div>
          ) : (
            <div
              style={{
                zIndex: 999,
                position: "fixed",
                left: 0,
                bottom: 0,
                display: "flex",
                borderTopRightRadius: "10px",
                backgroundColor: "white", // Optional: for visibility
                padding: "5px",
                fontSize: 10,
                width: 350,
                height: 150,
                boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)", // Optional: for style
              }}
            >
              <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center gap-3">
                <div>
                  <p>{trans("cms.cookie_message")}</p>
                </div>
                <div className="d-flex flex-row align-items-center gap-3">
                  <button className="btn btn-sm btn-primary" type="button" onClick={() => consentWithCookies()}>
                    {trans("cms.cookie_agree")}
                  </button>
                  {modalCookies(
                    <button onClick={toggleModal} className="btn btn-sm btn-outline-primary">
                      {trans("cms.cookie_info")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      }
      value={{
        apiErrors,
        appSettings,
        loaded,
        dashboard,
        category,
        categories,
        seller,
        product,
        productVariation,
        products,
        order,
        orders,
        wallet,
        rewardWallet,
        reservation,
        reservations,
        contractPayments,
        contractPayment,
        proposals,
        proposal,
        project,
        projects,
        event,
        events,
        contract,
        contracts,
        notifications,
        TOS,
        POS,
        faqHelpTopics,
        bookmarks,
        customPage,
        tickets,
        contacts,
        contact,
        form,
        forms,
        sellers,
        tourProducts,
        repliedForm,
        repliedForms,
        repliedFormsAnswer,
        htmlPage,
        htmlPageSettings,
        loadedHtml,
        setLoadedHtml,
        fetchHtmlPage,
        sendProductProposal,
        fetchRepliedForm,
        fetchRepliedForms,
        fetchRepliedFormsAnswers,
        resetLoginState,
        fetchFullDashboard,
        fetchForm,
        fetchForms,
        sendForm,
        fetchContactMade,
        fetchContactsMade,
        fetchContactsMadeBySearch,
        sendTicket,
        fetchCustomPage,
        getBookmarks,
        removeBookmarks,
        addBookmarks,
        handleAllowMarketing,
        handleRequestErasure,
        handleDownloadData,
        fetchFAQ,
        fetchTOS,
        fetchPOS,
        agreeWithTerms,
        fetchNotifications,
        removeNotifications,
        removeAllNotifications,
        fetchSeller,
        fetchSellerSearch,
        fetchContracts,
        fetchContract,
        fetchProject,
        fetchProjects,
        fetchEvent,
        fetchEvents,
        fetchOrders,
        fetchProducts,
        fetchProductVariation,
        fetchProductsByCategory,
        fetchProductsByName,
        fetchProduct,
        fetchOrder,
        fetchWallet,
        fetchRewardWallet,
        fetchReservations,
        fetchReservation,
        fetchContractPayments,
        fetchContractPayment,
        attachRequestPayments,
        fetchSibsStatus,
        fetchSibsClear,
        handleContractPaymentProveUpload,
        fetchProposals,
        fetchProposal,
        handleProposal,
        addItemToCart,
        removeItemFromCart,
        getCart,
        setLoaded,
        setProduct,
        setOrder,
        setReservation,
        setBookmarks,
        setProductVariation,
        setTickets,
        setHtmlPage,
        setAppSettings,
        checkDatesEntity,
      }}
    />
  );
};
