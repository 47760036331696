import { Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { Btn } from "src/AbstractElements";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { remotePath } from "src/base/settings/Paths";
import TooltipToggle from "src/base/TooltipToggle";

const ProposalOrders = ({ proposal, appSettings, trans }) => {
  const handleOpenOrder = (token) => {
    window.open(process.env.PUBLIC_URL + "/crm/orders/" + token);
  };

  const handlePaymentProve = (token) => {};

  return (
    <>
      {proposal?.status === "accepted" && proposal?.orders?.length > 0 && (
        <div className="table-responsive">
          <table id="invoices-list" className="table table-white-space table-responsive table-bordered row-grouping display no-wrap icheck table-middle">
            <thead>
              <tr>
                <th>#</th>
                <th className="hidden-sm">{trans("common.created_at")}</th>
                <th>{trans("website.order-items")}</th>
                {!appSettings?.sys_billing && (appSettings?.ifthenpay_key || appSettings?.invoice_transfer_iban) && <th>{trans("common.status")}</th>}
                <th>{trans("website.total-amount")}</th>
                <th>{trans("common.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {proposal?.orders?.map((order) => (
                <tr key={order?.id}>
                  <td>{order?.id}</td>
                  <td className="hidden-sm">{order?.created_at}</td>
                  <td>
                    <ul className="list-unstyled users-list m-0">
                      {order?.items
                        ?.filter((item) => item.type === "item" || item.type === "product")
                        ?.slice(0, 3)
                        ?.map((item, ii) => (
                          <TooltipToggle title={item.product.name} id={item.id} style={CSS({ display: "inline-grid" })}>
                            <img style={CSS({ borderRadius: 50 })} src={remotePath.assetsUrl(item.product.photo)} width="40" height="auto" />
                            {ii === order?.items?.length - 2 && order?.items?.length > 3 ? (
                              <small style={CSS({ marginLeft: 2.5 })}> + {order?.items?.length - 3}</small>
                            ) : (
                              <></>
                            )}
                          </TooltipToggle>
                        ))}

                      {order?.items?.length > 3 && (
                        <li className="avatar avatar-sm">
                          <span className="badge badge-info">
                            {order?.items?.length} {trans("website.total-qtd-products")}
                          </span>
                        </li>
                      )}
                    </ul>
                  </td>
                  {!appSettings?.sys_billing && (appSettings?.ifthenpay_key || appSettings?.invoice_transfer_iban) && (
                    <td>
                      <span
                        className={`badge badge-default badge-${
                          order?.status === "paid" ? "success" : order?.status === "processing" ? "warning" : "danger"
                        } badge-lg`}
                      >
                        {trans("website.order-status-" + order?.status)}
                      </span>
                    </td>
                  )}
                  <td>
                    {order?.total} {appSettings?.currency_symbol}
                  </td>
                  <td>
                    <button onClick={() => handleOpenOrder(order?.token)} className="btn btn-info">
                      <i className="la la-folder-open"></i> {trans("common.open")}
                    </button>
                    {order?.status === "open" && !appSettings?.sys_billing && (appSettings?.ifthenpay_key || appSettings?.invoice_transfer_iban) && (
                      <button onClick={() => handlePaymentProve(order?.token)} className="btn btn-info">
                        <i className="la la-upload"></i> {trans("website.payment-prove")}
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

const ProposalDetails = ({ proposal, trans }) => {
  const renderStatus = (status) => {
    switch (status) {
      case "accepted":
        return <p className="text-success">{trans("website.proposal-status-accepted")}</p>;
      case "declined":
        return <p className="text-danger">{trans("website.proposal-status-declined")}</p>;
      case "waiting":
        return <p className="text-warning">{trans("website.proposal-status-waiting")}</p>;
      default:
        return null;
    }
  };

  return (
    <div className="text-end">
      {renderStatus(proposal?.status)}
      <ul className="px-0 list-unstyled">
        <li>{trans("website.total-amount")}</li>
        <li className="lead text-bold-800">
          {proposal?.total} {proposal?.currency?.symbol}
        </li>
        {proposal?.shipping_cost && (
          <li>
            <small>
              + {proposal?.shipping_cost} {proposal?.currency?.symbol} {trans("website.proposal-shipping-cost")}
            </small>
          </li>
        )}
      </ul>
    </div>
  );
};

const ProposalItemsTable = ({ proposal, appSettings, trans }) => {
  const renderItemDetails = (item) => {
    switch (item?.product?.type) {
      case "physical":
        return (
          <>
            <td>{item?.quantity}</td>
            <td>{item?.productOption?.productOption?.title || ""}</td>
            <td>
              {item?.unit_price} {proposal?.symbol}
            </td>
          </>
        );
      case "contract":
        return (
          <>
            <td>{item?.paymentType?.name || ""}</td>
            <td>
              {item?.unit_price} {proposal?.symbol}
            </td>
          </>
        );
      case "entities":
        return (
          <>
            <td>
              {item?.rental_start_at && (
                <>
                  {trans("common.start_at")}: <b>{item?.rental_start_at}</b>
                  <br />
                </>
              )}
              {item?.rental_end_at && (
                <>
                  {trans("common.end_at")}: <b>{item?.rental_end_at}</b>
                </>
              )}
            </td>
            <td>
              {item?.rental_people && (
                <b>
                  <i className="la la-users"></i> {item?.rental_people}
                </b>
              )}
              {item?.shiftTime && (
                <>
                  <br />
                  <b>
                    <i className="la la-clock-o"></i> {numberFormat(item?.unit_price, 2)} {proposal?.symbol} {item?.shiftTime.name}
                  </b>
                </>
              )}
            </td>
          </>
        );
      default:
        return null;
    }
  };

  const renderItems = (items) => {
    return items?.map((item, i) => (
      <table className="table" key={i}>
        <thead>
          <tr>
            <th>#</th>
            <th>{trans("website.modules_invoices_type")}</th>
            <th>{trans("website.modules_invoices_item")}</th>
            {item?.product?.type === "physical" && (
              <>
                <th>{trans("website.modules_invoices_qty")}</th>
                <th>{trans("website.proposal-product-option")}</th>
                <th>{trans("website.modules_invoices_unitPrice")}</th>
              </>
            )}
            {item?.product?.type === "contract" && (
              <>
                <th>{trans("website.proposal-product-payment-type")}</th>
                <th>{trans("website.proposal-product-amount-recorrent")}</th>
              </>
            )}
            {item?.product?.type === "entities" && (
              <>
                <th>{trans("website.proposal-rental_period")}</th>
                <th>{trans("website.proposal-rental_info")}</th>
              </>
            )}
            <th>{trans("website.modules_invoices_amount")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">{i + 1}</td>
            <td>{trans("website.modules_invoices_" + item?.type)}</td>
            <td>
              <p>{item?.product?.name}</p>
            </td>
            {renderItemDetails(item)}
            <td className="text-right">
              {item?.amount} {proposal?.currency?.symbol}
            </td>
          </tr>
          {item?.extras?.length > 0 && (
            <tr>
              <td colSpan={10}>
                {item?.extras?.map((extra, index) => (
                  <span key={index}>
                    + {extra.title}
                    <br />
                  </span>
                ))}
              </td>
            </tr>
          )}
          {item?.childrens.length > 0 && (
            <tr>
              <td colSpan={10} className="text-right bg-gray-light">
                {item?.childrens.map((child, index) => (
                  <span key={index}>
                    {child.item_name}
                    {child.amount > 0 && ` (${child.amount} ${proposal?.currency?.symbol})`}
                    {child.feeTax?.included && ` ${trans("website.fee-included")}`}
                  </span>
                ))}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    ));
  };

  const items = proposal?.items?.filter((item) => item?.parent_id === null);

  return (
    <div className="row">
      <div className="table-responsive col-sm-12">{renderItems(items)}</div>
    </div>
  );
};

const numberFormat = (number, decimals) => {
  return number.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

const ProposalShippingInfo = ({ proposal, trans }) => {
  return (
    (proposal?.shipping_cost > 0 || proposal?.shipping_address) && (
      <div className="row mt3">
        <div className="col-md-12">
          <div className="bs-callout-warning callout-border-left callout-bordered mt-1 p-1">
            <h4 className="warning">{trans("website.proposal-shipping-info-title")}</h4>
            <p>{trans("website.proposal-shipping-info-text")}</p>
            <hr />
            {proposal?.shipping_cost && (
              <>
                <b>{trans("website.shipping_name")}</b> {proposal?.shipping_name}
                <br />
              </>
            )}
            {proposal?.billing_vat && (
              <>
                <b>{trans("website.billing_vat")}</b> {proposal?.billing_vat}
                <br />
              </>
            )}
            {proposal?.shipping_address && (
              <>
                <b>{trans("website.shipping_address")}</b>
                {proposal?.shipping_address}
                <br />
                {proposal?.shipping_address_2}
                <br />
                {proposal?.shipping_postal_code} - {proposal?.shipping_city}
                <br />
                {proposal?.shipping_state} - {proposal?.shipping_country}
                <br />
              </>
            )}
            {proposal?.shipping_cost && (
              <>
                <b>{trans("website.shipping-cost")}</b> {proposal?.shipping_cost} {proposal?.currency.symbol}
                <br />
              </>
            )}
          </div>
          <br />
        </div>
      </div>
    )
  );
};

const ProposalActions = ({ proposal, trans, handleProposal, fetchProposal }) => {
  const currentDate = new Date().getTime();
  const navigate = useNavigate();

  const handleAccept = async () => {
    await handleProposal(proposal?.id, "accept");
    await fetchProposal(proposal?.token);
  };

  const handleDecline = async () => {
    await handleProposal(proposal?.id, "decline");
    await fetchProposal(proposal?.token);
  };

  const tillDateEnd = new Date(proposal?.valid_till);
  tillDateEnd.setHours(23, 59, 59, 999);
  console.log("tillDateEnd: ", tillDateEnd);

  return (
    <div className="row mt-3">
      {proposal?.status === "waiting" && currentDate <= tillDateEnd.getTime() ? (
        <>
          <div className="col-md-6">
            <button onClick={handleAccept} className="btn w-100 btn-success btn-block mb-1">
              <i className="ft-check"></i> {trans("common.accept")}
            </button>
          </div>
          <div className="col-md-6">
            <button onClick={handleDecline} className="btn w-100 btn-danger btn-block">
              <i className="ft-x"></i> {trans("common.decline")}
            </button>
          </div>
        </>
      ) : (
        <div className="col-md-12">
          {proposal?.status === "waiting" && currentDate > tillDateEnd.getTime() && (
            <div className="d-flex flex-row gap-3 col-12 justify-content-between">
              <div className="alert alert-warning rounded mb-2" role="alert" style={CSS({ height: 50 })}>
                <span>{trans("website.proposal-out-of-date-validate-until")} </span>
                <strong>{proposal?.valid_till.substr(0, 10)}</strong>.
              </div>
              <Btn
                attrBtn={{
                  color: "primary",
                  className: "col-2 d-flex align-items-center gap-2",
                  style: CSS({ height: 50 }),
                  onClick: () => navigate(process.env.PUBLIC_URL + "/forms/replied/default/" + proposal?.lead_id),
                }}
              >
                <i className="la la-user"></i>
                {trans("website.go-to-lead")}
              </Btn>
            </div>
          )}
          {proposal?.status === "accepted" && proposal?.accepted_at && (
            <div className="alert alert-success rounded mb-2" role="alert">
              {trans("website.proposal-accepted-at-alert")} <strong>{proposal?.accepted_at}</strong>.
            </div>
          )}
          {proposal?.status === "declined" && proposal?.rejected_at && (
            <div className="alert alert-danger rounded mb-2" role="alert">
              {trans("website.proposal-rejected-at-alert")} <strong>{proposal?.rejected_at}</strong>.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ProposalPage = () => {
  const { proposal, appSettings, fetchProposal, handleProposal } = useEBSSS();
  const { trans } = useEBSSSLayout();

  const getOrder = useParams();

  useEffect(() => {
    fetchProposal(getOrder?.token);
  }, []);

  return (
    <Page
      breads={[
        {
          name: trans("website.proposal"),
          active: false,
          url: "/crm/proposals",
        },
        {
          name: trans("website.proposal") + " #" + (proposal?.id ? proposal?.id : ""),
          active: true,
        },
      ]}
    >
      <Fragment>
        <Container>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  {appSettings?.sys_multi_sellers ? (
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="media">
                          <div className="media-left">
                            <a href="/zeta/dashboard/default">
                              {proposal?.seller.profile?.avatar ? (
                                <img src={proposal?.seller.profile.avatar} alt="company logo" width="80px" className="media-object img-60 media" />
                              ) : (
                                <img
                                  src={remotePath.assetsUrl(appSettings?.invoice_logo)}
                                  alt="company logo"
                                  width="80px"
                                  className="media-object img-60 media"
                                  style={{ marginLeft: 20 }}
                                />
                              )}
                            </a>
                          </div>
                          <div className="m-l-20 media-body text-muted">
                            <h4 className="media-heading">{proposal?.seller?.name}</h4>
                            <p>
                              {" "}
                              {proposal?.seller.profile.address_line_1}, {proposal?.seller.profile.address_line_2}, {proposal?.seller.profile.locality} -{" "}
                              {proposal?.seller.profile.administrative_area}
                              <br />
                              <span>{proposal?.seller.profile.postal_code}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="text-md-end text-xs-center text-muted">
                          <h3>
                            {trans("website.proposal")} #<span className="counter">{proposal?.id}</span>
                          </h3>
                          <p>
                            {trans("common.created_at")}: <span>{new Date(proposal?.created_at).toLocaleDateString()}</span>
                            <br />
                            <ProposalDetails proposal={proposal} trans={trans} />
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="media">
                          <div className="media-left">
                            <a href="/zeta/dashboard/default">
                              {appSettings?.invoice_logo && (
                                <img
                                  src={remotePath.assetsUrl(appSettings?.invoice_logo)}
                                  alt="company logo"
                                  width="80px"
                                  className="media-object img-60 media"
                                />
                              )}
                            </a>
                          </div>
                          <div className="m-l-20 media-body text-muted">
                            <h4 className="media-heading">{appSettings?.invoice_company_name ? appSettings?.invoice_company_name : ""}</h4>
                            <p>
                              {" "}
                              {appSettings?.invoice_company_address}, {appSettings?.invoice_company_city_state}, {appSettings?.invoice_company_country}
                              <br />
                              <span>289-335-6503</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="text-md-end text-xs-center text-muted">
                          <h3>
                            {trans("website.proposal")} #<span className="counter">{proposal?.id}</span>
                          </h3>
                          <p>
                            {trans("common.created_at")}: <span>{new Date(proposal?.created_at).toLocaleDateString()}</span>
                            <br />
                            <ProposalDetails proposal={proposal} trans={trans} />
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <ProposalItemsTable proposal={proposal} trans={trans} appSettings={appSettings} />
                  <div id="invoice-items-details" className="pt-2">
                    {proposal?.footer_note ?? ""}
                  </div>
                  <div id="invoice-footer">
                    <div className="row">
                      {proposal?.file ? (
                        <div className="col-md-3 col-sm-12 text-center">
                          <button type="button" onClick={() => window.open(remotePath.assetsUrl("/" + proposal.file))} className="btn btn-info btn-lg my-1">
                            <i className="la la-paperclip"></i> {trans("common.download")}
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <ProposalShippingInfo proposal={proposal} trans={trans} />
                  <ProposalActions proposal={proposal} trans={trans} handleProposal={handleProposal} fetchProposal={fetchProposal} />
                  <ProposalOrders proposal={proposal} trans={trans} appSettings={appSettings} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </Page>
  );
};

export default ProposalPage;
