import { Dispatch, Fragment } from "react";
import DataTable from "react-data-table-component";
import ResetTable from "src/base/ResetTable";

const TableData = ({
  data,
  columns,
  actions,
  topAction,
  pageHandler,
  onRowClicked,
}: {
  data: any;
  columns: any[];
  actions?: JSX.Element | JSX.Element[];
  topAction?: React.ReactNode | React.ReactNode[];
  pageHandler?: Dispatch<number>;
  onRowClicked?: (row: any) => void;
}) => {
  return (
    <Fragment>
      <div className="table-responsive product-table">
        <DataTable
          onRowClicked={onRowClicked}
          subHeaderComponent={topAction}
          customStyles={{
            subHeader: { style: { backgroundColor: "#00000000" } },
          }}
          subHeader={!!topAction}
          noHeader={!!!topAction}
          pagination
          paginationServer
          onChangePage={pageHandler}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          paginationPerPage={data?.per_page}
          paginationTotalRows={data?.total}
          paginationDefaultPage={data?.current_page}
          columns={columns}
          data={data?.data ?? []}
          actions={actions}
          noDataComponent={<ResetTable />}
        />
      </div>
    </Fragment>
  );
};
export default TableData;
