import BookmarksPage from "src/Pages/Bookmarked/BookmarkedPage";
import ContactCenterCategoryPage from "src/Pages/ContactCenter/ContactCenterCategoryPage";
import ContactCenterMessagePage from "src/Pages/ContactCenter/ContactCenterMessagePage";
import ContactCenterPage from "src/Pages/ContactCenter/ContactCenterPage";
import ContactCenterSearchPage from "src/Pages/ContactCenter/ContactCenterSearchPage";
import ContractPaymentPage from "src/Pages/ContractPayments/ContractPaymentPage";
import ContractPaymentsPage from "src/Pages/ContractPayments/ContractPaymentsPage";
import ContractPage from "src/Pages/Contracts/ContractPage";
import ContractsPage from "src/Pages/Contracts/ContractsPage";
import CustomPage from "src/Pages/Custom/CustomPage";
import DashboardPage from "src/Pages/DashBoard/DashboardPage";
import EntityReservationPage from "src/Pages/EntityReservations/EntityReservationPage";
import EntityReservationsPage from "src/Pages/EntityReservations/EntityReservationsPage";
import EventPage from "src/Pages/Events/EventPage";
import EventsPage from "src/Pages/Events/EventsPage";
import FAQPage from "src/Pages/FAQ/FAQPage";
import FormPage from "src/Pages/Forms/FormPage";
import FormsPage from "src/Pages/Forms/FormsPage";
import FormsReplied from "src/Pages/Forms/Replied/FormsReplied";
import FormsRepliedView from "src/Pages/Forms/Replied/FormsRepliedView";
import GDPRPage from "src/Pages/GDPR/GDPRPage";
import NotificationsPage from "src/Pages/Notifications/NotificationsPage";
import OrderPage from "src/Pages/Orders/OrderPage";
import OrdersPage from "src/Pages/Orders/OrdersPage";
import ProductPage from "src/Pages/Products/ProductPage";
import ProductsCategoriesPage from "src/Pages/Products/ProductsCategoriesPage";
import ProductsPage from "src/Pages/Products/ProductsPage";
import ProductsSearchPage from "src/Pages/Products/ProductsSearchPage";
import ProjectPage from "src/Pages/Projects/ProjectPage";
import ProjectsPage from "src/Pages/Projects/ProjectsPage";
import ProposalPage from "src/Pages/Proposals/ProposalPage";
import ProposalsPage from "src/Pages/Proposals/ProposalsPage";
import ReservationsPage from "src/Pages/Reservations/ReservationsPage";
import PolicyConditionPage from "src/Pages/Terms/PolicyConditionPage";
import TermsConditionPage from "src/Pages/Terms/TermsConditionPage";
import UserProfilePage from "src/Pages/Users/UserProfilePage";
import WalletPage from "src/Pages/Wallet/WalletPage";
import CreatePwd from "../Component/Pages/Auth/CreatePwd";
import ForgetPwd from "../Component/Pages/Auth/ForgetPwd";
import Maintenance from "../Component/Pages/Auth/Maintenance";
import UnlockUser from "../Component/Pages/Auth/UnlockUser";
import ErrorPage1 from "../Component/Pages/ErrorPages/error-page1";
import RewardWalletPage from "src/Pages/Wallet/RewardWalletPage";
import FormsRepliedDefaultView from "src/Pages/Forms/Replied/FormsRepliedDefaultView";

export const authRoutes = [
  //Dashboard
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <DashboardPage /> },

  //Account
  {
    path: `${process.env.PUBLIC_URL}/users/userprofile`,
    Component: <UserProfilePage />,
  },

  //Forms
  {
    path: `${process.env.PUBLIC_URL}/forms`,
    Component: <FormsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/replied/:id`,
    Component: <FormsRepliedView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/replied/default/:id`,
    Component: <FormsRepliedDefaultView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/replied/filter/all`,
    Component: <FormsReplied />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/replied/filter/:id?`,
    Component: <FormsReplied />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/:slug`,
    Component: <FormPage />,
  },

  //GDPR
  { path: `${process.env.PUBLIC_URL}/gdpr`, Component: <GDPRPage /> },

  //Bookmarks
  { path: `${process.env.PUBLIC_URL}/favorites`, Component: <BookmarksPage /> },

  //Entity Reservation
  {
    path: `${process.env.PUBLIC_URL}/entity-reservations/:token`,
    Component: <EntityReservationPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/entity-reservations`,
    Component: <EntityReservationsPage />,
  },

  //Reservation
  {
    path: `${process.env.PUBLIC_URL}/reservations/:token`,
    Component: <ReservationsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/reservations`,
    Component: <ReservationsPage />,
  },

  //ContactCenter
  {
    path: `${process.env.PUBLIC_URL}/crm/contacts/center`,
    Component: <ContactCenterPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/contacts/center/search/:keyword`,
    Component: <ContactCenterSearchPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/contacts/center/category/:category`,
    Component: <ContactCenterCategoryPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/contacts/center/:type/:token/:parent_entity?`,
    Component: <ContactCenterMessagePage />,
  },

  //Order
  {
    path: `${process.env.PUBLIC_URL}/crm/orders/:token`,
    Component: <OrderPage />,
  },
  { path: `${process.env.PUBLIC_URL}/crm/orders`, Component: <OrdersPage /> },

  //Subscriptions
  {
    path: `${process.env.PUBLIC_URL}/crm/contracts`,
    Component: <ContractsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/contracts/:token`,
    Component: <ContractPage />,
  },

  //Event
  {
    path: `${process.env.PUBLIC_URL}/events/:token`,
    Component: <EventPage />,
  },
  { path: `${process.env.PUBLIC_URL}/events/calendar/interno`, Component: <EventsPage /> },

  //Project
  {
    path: `${process.env.PUBLIC_URL}/crm/projects/:token`,
    Component: <ProjectPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/projects`,
    Component: <ProjectsPage />,
  },

  //Wallet
  {
    path: `${process.env.PUBLIC_URL}/crm/wallet-historic`,
    Component: <WalletPage />,
  },

  //Reward Wallet
  {
    path: `${process.env.PUBLIC_URL}/crm/rwd-wallet-historic`,
    Component: <RewardWalletPage />,
  },

  //Contract Payment
  {
    path: `${process.env.PUBLIC_URL}/crm/contract-payments`,
    Component: <ContractPaymentsPage />,
  },

  //Proposals
  {
    path: `${process.env.PUBLIC_URL}/crm/proposals`,
    Component: <ProposalsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/crm/proposals/:token`,
    Component: <ProposalPage />,
  },

  //Notifications
  {
    path: `${process.env.PUBLIC_URL}/crm/notifications`,
    Component: <NotificationsPage />,
  },
];
