//@ts-nocheck
import { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row } from "reactstrap";
import { Session } from "src/base/store/authSlice";
import { Layout } from "src/base/store/layoutSlice";
import { ReduxDataKey } from "src/base/store/store";
import CheckContext from "../../_helper/customizer/index";
import { Image } from "../../AbstractElements";
import { ToggleIconss } from "../../Data/svgIcons";
import HeaderContain from "./HeaderContain";
import SearchBar from "./SearchBar";
import { Link, useNavigate } from "react-router-dom";
import { CSS } from "src/_utils/styles";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";

const Header = (
  { strictLayout }: { strictLayout?: boolean } = {
    strictLayout: false,
  }
) => {
  const sidebar_types = localStorage.getItem("sidebar_types");
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const { trans } = useEBSSSLayout();
  const { toggleSidebar } = useContext<any>(CheckContext);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const goBackNav = () => {
    navigate(-1);
  };

  const openCloseSidebar = () => {
    setToggle(window.innerWidth > 1016 ? !toggle : toggle);
    toggleSidebar(window.innerWidth > 1016 ? !toggle : toggle);
  };

  useEffect(() => {
    if (strictLayout) {
      setToggle(true);
      toggleSidebar(true);
    }
  }, [strictLayout]);

  return (
    <Fragment>
      <Row className="header-wrapper m-0 no-print-ebsss">
        <div className="header-logo-wrapper col-auto p-0" id="out_side_click">
          <div className="logo-wrapper">
            {sidebar_types === "horizontal-wrapper" ? (
              <div className="d-flex flex-row gap-3 align-items-center mr-3">
                <Link to={process.env.PUBLIC_URL}>
                  <Image
                    attrImage={{
                      width: 40,
                      height: 40,
                      className: "img-fluid",
                      src: `${ebsssLayout?.logo}`,
                      alt: ebsssLayout?.title,
                    }}
                  />
                </Link>
                {window.location.href.includes("v2/checkout") ? (
                  <div className="d-flex flex-row gap-2 align-items-center" style={CSS({ cursor: "pointer" })} onClick={() => goBackNav()}>
                    <i className="la la-arrow-left"></i>
                    <span>{trans("website.back")}</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          {(strictLayout || !sessionNow?.user?.id) && sidebar_types !== "horizontal-wrapper" ? (
            <div className="d-flex flex-row gap-3 align-items-center">
              <Link to={process.env.PUBLIC_URL}>
                <Image
                  attrImage={{
                    width: 40,
                    height: 40,
                    className: "img-fluid",
                    src: `${ebsssLayout?.logo}`,
                    alt: ebsssLayout?.title,
                  }}
                />
              </Link>
              {window.location.href.includes("v2/checkout") ? (
                <div className="d-flex flex-row gap-2 align-items-center" style={CSS({ cursor: "pointer" })} onClick={() => goBackNav()}>
                  <i className="la la-arrow-left"></i>
                  <span>{trans("website.back")}</span>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : window.location.href.includes("v2/checkout") && sidebar_types !== "horizontal-wrapper" ? (
            <div className="d-flex flex-row gap-3 align-items-center">
              <Link to={process.env.PUBLIC_URL}>
                <Image
                  attrImage={{
                    width: 40,
                    height: 40,
                    className: "img-fluid",
                    src: `${ebsssLayout?.logo}`,
                    alt: ebsssLayout?.title,
                  }}
                />
              </Link>
              <div className="d-flex flex-row gap-2 align-items-center" style={CSS({ cursor: "pointer" })} onClick={() => goBackNav()}>
                <i className="la la-arrow-left"></i>
                <span>{trans("website.back")}</span>
              </div>
            </div>
          ) : (
            <div className="toggle-sidebar" onClick={() => openCloseSidebar()} id="sidebar-toggle">
              <i className="status_toggle sidebar-toggle d-flex">
                <ToggleIconss />
              </i>
            </div>
          )}
        </div>
        <SearchBar strictLayout={strictLayout} />
        <HeaderContain sessionNow={sessionNow} activeLang={ebsssLayout?.activeLanguage} strictLayout={strictLayout} />
      </Row>
    </Fragment>
  );
};
export default Header;
