import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { remotePath } from "src/base/settings/Paths";
import { Session } from "src/base/store/authSlice";
import { fetchLangs, Layout } from "src/base/store/layoutSlice";
import { ReduxDataKey } from "src/base/store/store";
import { IEBSSSLayoutContext } from "./types";
import { getQueryParams } from "src/_utils/urls";
import EBSSSLoading from "src/base/atoms/EBSSSLoading";

export const EBSSSLayoutContext = createContext<IEBSSSLayoutContext>({} as IEBSSSLayoutContext);

export const TranslateProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const [cmsLayout, setCMSLayout] = useState(false);
  const [cmsSnapshot, setCMSSnapshot] = useState("");
  const dispatcher = useDispatch<any>();

  const trans = (key: string) => {
    let splittedKey = key.split(".");
    let group = splittedKey[0];
    let name = splittedKey[1];

    if (splittedKey.length > 2) {
      name = splittedKey.slice(1).join(".");
    }

    if (ebsssLayout[`${group}`] && ebsssLayout[`${group}`][`${name}`]) {
      return ebsssLayout[group][name];
    }

    return `_${key}_`;
  };

  const changeLanguage = (key: string, silent = false) => {
    dispatcher(
      fetchLangs({
        activeLanguage: key,
        token: "Bearer " + sessionNow.token,
      })
    ).then(() => {
      if (!silent) {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    document.title = ebsssLayout?.title;
    let applyFavicon = setTimeout(() => {
      var element = document.createElement("link");
      element.setAttribute("rel", "shortcut icon");
      element.setAttribute("href", remotePath.assetsUrl(ebsssLayout?.favicon));
      document.head.append(element);
      clearTimeout(applyFavicon);
    }, 1);
    if (!ebsssLayout?.exp || ebsssLayout?.exp < new Date().getTime()) {
      dispatcher(
        fetchLangs({
          activeLanguage: ebsssLayout?.activeLanguage,
          token: "Bearer " + sessionNow.token,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      sessionNow?.user?.id &&
      (window.location.href.includes("dashboard") ||
        (Number(getQueryParams(window.location.href)?.refreshLayout) === 1 && !getQueryParams(window.location.href)?.newUserActivationRequired))
    ) {
      console.log("Refreshing menus due to sessionNow?...");
      dispatcher(
        fetchLangs({
          activeLanguage: ebsssLayout?.activeLanguage,
          token: "Bearer " + sessionNow.token,
        })
      );
    }
  }, [sessionNow?.user?.id]);

  return (
    <EBSSSLayoutContext.Provider
      children={!ebsssLayout?.loading ? children : <EBSSSLoading />}
      value={{
        trans,
        changeLanguage,
        setCMSLayout,
        setCMSSnapshot,
        cmsLayout,
        cmsSnapshot,
      }}
    />
  );
};
