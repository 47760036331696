import React, { useEffect } from "react";
import { useEBSSSLayout } from "../context/EBSSSLayoutContext/useEBSSSLayout";
import { ReduxDataKey } from "../store/store";
import { useSelector } from "react-redux";
import { Session } from "../store/authSlice";
import { remotePath } from "../settings/Paths";
import { Layout } from "../store/layoutSlice";
import { useNavigate } from "react-router-dom";
import { CSS } from "src/_utils/styles";
import { useEBSSS } from "../context/EBSSSContext/useEBSSS";

const CartItem = ({ item, remotePath, removeItem, ebsssLayout }) => {
  const navigate = useNavigate();

  const handleTitleClick = () => {
    const productPageSlug = ebsssLayout.template.pages.find((p) => p.type === "crm_product")?.slug;
    if (productPageSlug) {
      navigate(`${process.env.PUBLIC_URL}/${productPageSlug}/${item?.p?.slug}`);
    }
  };

  return (
    <div
      id={item?.p?.token}
      className="cart-item-react shadow-sm p-3"
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "10px",
        gap: "15px",
      }}
    >
      <img
        src={remotePath.assetsUrl(item?.p?.photo) || ""}
        alt={item?.p?.name || "Product Image"}
        className="cart-item-photo"
        style={{
          width: "25%",
          height: "100%",
          marginTop: "5px",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p className="cart-item-title" style={{ cursor: "pointer" }} onClick={handleTitleClick}>
          {item?.p?.name || "Product Title"}
        </p>
        <div className="d-flex flex-row justify-content-between">
          <p className="cart-item-price">{`${Number(item?.price ?? 0).toFixed(2)} ${ebsssLayout?.commonAppSettings?.currency_symbol}`}</p>
          <a href="#!" style={CSS({ color: "#cecece" })} onClick={async (e) => removeItem(e, item?.hash)}>
            <i className="fas fa-trash-alt text-danger"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

const EBSSSRightCart = ({ showRightCart, setShowRightCart }) => {
  const { trans } = useEBSSSLayout();
  const navigate = useNavigate();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const { removeItemFromCart } = useEBSSS();

  console.log(sessionNow?.session?.cart);

  const removeItem = async (e, hash) => {
    e.preventDefault();
    try {
      await removeItemFromCart(hash);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={showRightCart ? "right-sidebar show col-12 col-md-4 col-xl-3" : "right-sidebar col-12 col-md-4 col-xl-3"}
      style={CSS({
        maxHeight: "100%",
        height: "100%",
      })}
      role="document"
    >
      <div
        className="modal-content w-100 text-break d-flex flex-column"
        style={CSS({
          height: "100%",
          maxHeight: "100%",
        })}
      >
        <div className="modal-header d-flex flex-row gap-3 position-sticky">
          <h4 className="modal-title" id="myModalLabel">
            {trans("website.cart")}
          </h4>
          <button
            type="button"
            className="close btn btn-light"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setShowRightCart(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          className="modal-body d-flex flex-column"
          style={CSS({
            height: "100%",
            maxHeight: "100%",
            overflowX: "hidden",
            overflowY: "scroll",
          })}
        >
          <div>
            {sessionNow?.session?.cart?.map((i) => {
              return <CartItem item={i} remotePath={remotePath} ebsssLayout={ebsssLayout} removeItem={removeItem} />;
            })}
          </div>
        </div>
        <div className="modal-footer">
          <div className="w-100  lead d-flex flex-row justify-content-between">
            <p>{trans("website.total")}:</p>
            <p>
              {(Number(sessionNow?.session?.cart?.map((i) => Number(i.price ?? 0))?.reduce((a, b) => a + b, 0) ?? 0) ?? 0).toFixed(2) +
                " " +
                ebsssLayout?.commonAppSettings?.currency_symbol}
            </p>
          </div>
          {sessionNow?.session?.cart?.length ? (
            sessionNow?.session?.cart?.some((item) => {
              if (item.p.instant_payment !== 1 || (item.p.type === "physical" && item.p.stock - item.quantity < 0)) {
                if (item.p && item.p.instant_payment !== 1) {
                  return true;
                }
                return false;
              }
              return false;
            }) ? (
              <button className="btn btn-light btn-sm w-100" type="button" onClick={() => navigate(process.env.PUBLIC_URL + "/checkout/pre")}>
                <div className="d-flex flex-row gap-3 align-items-center justify-content-center">
                  <i className="la la-shopping-cart"></i>
                  <span>{trans("website.go-to-cart")}</span>
                </div>
              </button>
            ) : sessionNow?.session?.sellers && Object.values(sessionNow?.session?.sellers)?.length > 0 ? (
              <button
                className="btn btn-light btn-sm w-100"
                type="button"
                onClick={() =>
                  navigate(`${process.env.PUBLIC_URL}/checkout/${!sessionNow?.session?._selected_seller ? "multi" : sessionNow?.session?._selected_seller}`)
                }
              >
                <div className="d-flex flex-row gap-3 align-items-center justify-content-center">
                  <i className="la la-shopping-cart"></i>
                  <span>{trans("website.go-to-cart")}</span>
                </div>
              </button>
            ) : (
              <button className="btn btn-light btn-sm w-100" type="button" onClick={() => navigate(process.env.PUBLIC_URL + "/checkout")}>
                <div className="d-flex flex-row gap-3 align-items-center justify-content-center">
                  <i className="la la-shopping-cart"></i>
                  <span>{trans("website.go-to-cart")}</span>
                </div>
              </button>
            )
          ) : (
            <p className="text-center">{trans("website.empty-cart")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EBSSSRightCart;
