import React, { useEffect, useState } from "react";
import { Lock, Mail } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { CSS } from "src/_utils/styles";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import EBSSSCheckbox from "src/base/atoms/EBSSSCheckbox";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import FeedbackMessages from "src/base/FeedbackMessage";
import { remotePath } from "src/base/settings/Paths";
import { cleanErrorsAndMessages, fetchSignin, Session } from "src/base/store/authSlice";
import { fetchLangs, Layout } from "src/base/store/layoutSlice";
import { ReduxDataAuthKey, ReduxDataKey } from "src/base/store/store";

const LoginForm = ({ outOriginalPage }: { outOriginalPage?: boolean }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { trans } = useEBSSSLayout();
  const [isPasswordVisible, setisPasswordVisible] = useState(false);
  const dispatcher = useDispatch<any>();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const [remember, setRemember] = useState(false);
  const [refreshLayout, setRefreshLayout] = useState(false);
  const toggle = () => {
    setisPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatcher(
        fetchSignin({
          email,
          password,
          actualSession: sessionNow?.session ? sessionNow?.session : null,
          activeLanguage: ebsssLayout?.activeLanguage,
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshLayout(true);
    }
  };

  useEffect(() => {
    dispatcher(cleanErrorsAndMessages());
  }, []);

  useEffect(() => {
    if (!outOriginalPage) {
      let checkIfRemember = localStorage.getItem(remotePath.localStorageKey() + "_REMEMBER_ME");
      if (checkIfRemember) {
        setEmail(checkIfRemember);
        setRemember(true);
      }
    }
  }, []);

  useEffect(() => {
    if (remember) {
      localStorage.setItem(remotePath.localStorageKey() + "_REMEMBER_ME", email);
    } else {
      localStorage.removeItem(remotePath.localStorageKey() + "_REMEMBER_ME");
    }
  }, [email, remember]);

  useEffect(() => {
    if (sessionNow?.user?.id && refreshLayout && outOriginalPage) {
      dispatcher(
        fetchLangs({
          activeLanguage: ebsssLayout?.activeLanguage,
          token: "Bearer " + sessionNow?.token,
        })
      );
    }
  }, [sessionNow?.user?.id, refreshLayout]);

  return (
    <>
      <div className={`login-header text-center ${outOriginalPage ? "p-4" : ""}`}>
        <h4>{trans("auth.sign-in-title")}</h4>
        <h6>{trans("auth.sign-in-description")}</h6>
      </div>

      <div className={`${outOriginalPage ? "p-4" : ""}`}>
        <FormGroup>
          <Label>{trans("auth.email")}</Label>
          <InputGroup>
            <InputGroupText>
              <Mail />
            </InputGroupText>
            <Input type="email" required placeholder="you@gmail.com" defaultValue={email} onChange={(event) => setEmail(event.target.value)} />
          </InputGroup>
          <FeedbackMessages messages={sessionNow?.errors?.signin["email"]} />
        </FormGroup>
        <FormGroup>
          <Label>{trans("auth.password")}</Label>
          <InputGroup style={{ position: "relative" }}>
            <InputGroupText>
              <Lock />
            </InputGroupText>
            <Input type={isPasswordVisible ? "text" : "password"} required defaultValue={password} onChange={(event) => setPassword(event.target.value)} />

            <div className="text-primary small show-hide-toggle" onClick={toggle}>
              <span>{!isPasswordVisible ? trans("website.show") : trans("website.hide")}</span>
            </div>
          </InputGroup>
          <FeedbackMessages messages={sessionNow?.errors?.signin["password"]} />
        </FormGroup>

        {outOriginalPage ? (
          <></>
        ) : (
          <>
            <div className="my-3 pt-2">
              <EBSSSCheckbox
                selected={remember}
                label={trans("auth.remember_me")}
                id="checkbox1"
                action={(e) => {
                  setRemember(!remember);
                }}
              />
            </div>
            <Link to={`${process.env.PUBLIC_URL}/auth/sign-in/forgot`} className="link">
              {trans("auth.password-forgot-title")}
            </Link>
          </>
        )}
        <FormGroup>
          <EBSSSButton
            style={
              outOriginalPage
                ? CSS({
                    width: "100%",
                    textAlign: "center",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  })
                : {}
            }
            loadingKey={ReduxDataAuthKey.signin}
            onClick={handleSubmit}
          >
            {trans("auth.sign_in")}
          </EBSSSButton>
        </FormGroup>
      </div>
    </>
  );
};

export default LoginForm;
