import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isActualRouteProtected, isActualRoutePublic } from "src/_utils/urls";
import { Session } from "src/base/store/authSlice";
import { Layout } from "src/base/store/layoutSlice";
import { ReduxDataKey } from "src/base/store/store";

const MiddlewareAuthRoute = () => {
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const location = useLocation();

  if (location.pathname === "/" || location.pathname === process.env.PUBLIC_URL || location.pathname === process.env.PUBLIC_URL + "/") {
    let hasDefinedInitialPage = ebsssLayout?.template?.pages?.find((lg: { type: string }) => lg.type === "ini_page");
    return <Navigate to={process.env.PUBLIC_URL + "/" + (hasDefinedInitialPage && hasDefinedInitialPage.slug !== "/" ? hasDefinedInitialPage?.slug : "")} />;
  }

  if (isActualRouteProtected()) {
    return sessionNow?.user?.id ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/auth/sign-in`} />;
  }

  return <Outlet />;
};
export default MiddlewareAuthRoute;
