import React from "react";

const EBSSSInitialNameProfile = ({
  user,
  fontSize,
  width,
  height,
  onClick,
  firstName,
  lastName,
  ...pps
}: {
  user: Record<any, any>;
  fontSize?: number;
  width?: number;
  height?: number;
  onClick?: any;
  firstName?: string;
  lastName?: string;
}) => {
  return (
    <div
      className="d-flex"
      onClick={onClick ? onClick : () => {}}
      style={{
        cursor: onClick ? "pointer" : "default",
        backgroundColor: "#f0ca4d",
        width: width ? width : "auto",
        height: height ? height : "100%",
        borderRadius: 500,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
      }}
      {...pps}
    >
      <span style={{ fontSize: fontSize ? fontSize : 25, color: "#fff" }}>
        {firstName ? firstName : user?.profile?.first_name ? user?.profile?.first_name[0]?.toUpperCase() : ""}
      </span>
      <span style={{ fontSize: fontSize ? fontSize : 25, color: "#fff" }}>
        {lastName ? lastName : user?.profile?.last_name ? user?.profile?.last_name[0]?.toUpperCase() : ""}
      </span>
    </div>
  );
};

export default EBSSSInitialNameProfile;
