//@ts-nocheck
import { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import { P } from "../../AbstractElements";
import { Layout } from "src/base/store/layoutSlice";
import { ReduxDataKey } from "src/base/store/store";
import { useSelector } from "react-redux";

const Footer = ({ strictLayout }) => {
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  return (
    <Fragment>
      <footer className={`footer no-print-ebsss ${strictLayout ? "ml-0" : ""}`} style={strictLayout ? { marginLeft: 0 } : {}}>
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <P attrPara={{ className: "mb-0 text-muted" }}>
                © 2016 - {new Date().getFullYear()} <span className="fw-bold">EBSSS e-business software</span> v
                {ebsssLayout?.commonAppSettings?.application_version}
              </P>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};
export default Footer;
