import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { Card, Col } from "reactstrap";
import { convertDateFormat } from "../FormCommon";

export const FormsRepliedContain = ({ answers, defaultCategory, trans }) => {
  return (
    <Fragment>
      <div className="email-right-aside d-flex flex-row flex-wrap">
        {(answers?.leads ? answers?.leads : answers)
          ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
          ?.map((item) => {
            return (
              <Col xs="12" xl="4" md="6" key={item.id}>
                <Card className="shadow-sm o-hidden p-3 me-md-3 d-flex">
                  <div className={`d-flex flex-row align-items-center gap-2 ${item.leadId ? "mb-1" : ""}`}>
                    {item.leadId ? <span className="badge bg-light text-black">D</span> : <></>}
                    {item.leadId ? <span>{defaultCategory?.title}</span> : <span>{item.title}</span>}
                  </div>
                  <span className="text-muted">{convertDateFormat(item.created_at, true)}</span>
                  <Link
                    to={process.env.PUBLIC_URL + "/forms/replied/" + (item.leadId ? "default/" + item.leadId : item.formLeadId)}
                    className="btn btn-outline-primary m-auto mt-3"
                  >
                    <div className="d-flex flex-row align-items-center">
                      <i className="me-1 la la-mail-bulk" />
                      {trans("website.see")}
                    </div>
                  </Link>
                </Card>
              </Col>
            );
          })}
      </div>
    </Fragment>
  );
};
