import { createSlice } from "@reduxjs/toolkit";
import HelperHTTP from "src/_utils/http_module_handler";
import { remotePath } from "../settings/Paths";
import { parseSettingsToBooleanValues } from "src/_utils/validations";
import { menuLayout } from "../constants/layout";

export type Cache = {
  headEl: {
    content: {
      styles: string;
    };
    exp: number;
  };
  bodyEl: {
    content: string;
    exp: number;
  };
};

const initialState: Cache = {
  headEl: {
    content: {
      styles: "",
    },
    exp: 0,
  },
  bodyEl: {
    content: "",
    exp: 0,
  },
};

export const cacheSlice = createSlice({
  name: "cache",
  initialState,
  reducers: {
    storeHeadInCache(state: Cache, action: Record<string, any>) {
      state.headEl.exp = new Date().getTime() + 600000;
      state.headEl.content = action.payload.content;
    },
    storeBodyInCache(state: Cache, action: Record<string, any>) {
      state.bodyEl.exp = new Date().getTime() + 600000;
      state.bodyEl.content = action.payload.content;
    },
    cleanAllCache(state: Cache) {
      state.bodyEl.exp = 0;
      state.bodyEl.content = "";
      state.headEl.exp = 0;
      state.headEl.content = { styles: "" };
    },
  },
});

export const { storeHeadInCache, storeBodyInCache, cleanAllCache } = cacheSlice.actions;

export default cacheSlice.reducer;
