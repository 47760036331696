import { Dispatch } from "react";
import { Calendar, Globe, Lock, Mail, Map, MapPin, Phone, User } from "react-feather";
import { FaCity, FaGlobe, FaIdCard, FaTransgender } from "react-icons/fa";
import { Card, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { CSS } from "src/_utils/styles";
import FeedbackMessages from "src/base/FeedbackMessage";

type FieldType = {
  id: string;
  type?: any;
  icon: React.ReactNode;
  key: string;
  transKey: string;
  validationElement?: React.ReactNode;
  customEl?: React.ReactNode;
  className?: string;
  helper?: string | React.ReactNode;
  onChange?: (e: any) => any;
  onPaste?: (e: any) => any;
};

type InputType = {
  type: "row" | "col";
  fields: FieldType[];
};

type EBSSSFormBuilderProps = {
  inputs: InputType[];
  setter: Dispatch<any>;
  data: Record<string, any>;
  trans: (key: string) => string;
  submitHandler?: (e: any) => any;
  submitEl?: React.ReactNode;
  labelsPosition?: "start" | "center" | "end";
};

export const EBSSSFormBuilder: React.FC<EBSSSFormBuilderProps> = ({ inputs, submitEl, labelsPosition, setter, data, trans, submitHandler }) => {
  return (
    <Form className="theme-form login-form bg-transparent text-muted" onSubmit={submitHandler}>
      <>
        {inputs?.map((i, index) => {
          return i.type === "row" ? (
            <div className={`row d-flex text-${labelsPosition ?? "left"}`} key={index}>
              {i.fields?.map((f, fieldIndex) => (
                <div
                  className="col-sm-6"
                  style={CSS({
                    textAlign: labelsPosition ?? "start",
                  })}
                >
                  <FormGroup key={fieldIndex}>
                    <Label className={`text-${labelsPosition ?? "left"}`}>{trans(f.transKey)}: *</Label>
                    <InputGroup>
                      {!f?.customEl ? <InputGroupText>{f.icon}</InputGroupText> : <></>}
                      {!f?.customEl ? (
                        <Input
                          id={f.id}
                          type={f.type ? f.type : "text"}
                          required
                          defaultValue={data[f.key]}
                          className={f.className}
                          onPaste={f.onPaste}
                          onChange={(event) =>
                            f.onChange
                              ? f.onChange(event)
                              : setter({
                                  ...data,
                                  [f.key]: event.target.value,
                                })
                          }
                        />
                      ) : (
                        <div className="d-flex flex-row w-100">
                          <InputGroupText>{f.icon}</InputGroupText>
                          {f?.customEl}
                        </div>
                      )}
                    </InputGroup>
                    <div className="w-100">{f.validationElement}</div>
                  </FormGroup>
                  {f?.helper}
                </div>
              ))}
            </div>
          ) : (
            i.fields?.map((f, fieldIndex) => (
              <div
                className={`col-12 text-${labelsPosition ?? "left"}`}
                key={fieldIndex}
                style={CSS({
                  textAlign: labelsPosition ?? "start",
                })}
              >
                <FormGroup>
                  <Label className={`text-${labelsPosition ?? "left"}`}>{trans(f.transKey)}: *</Label>
                  <InputGroup>
                    {!f?.customEl ? <InputGroupText>{f.icon}</InputGroupText> : <></>}
                    {!f?.customEl ? (
                      <Input
                        id={f.id}
                        type={f.type ? f.type : "text"}
                        required
                        defaultValue={data[f.key]}
                        onPaste={f.onPaste}
                        className={f.className}
                        onChange={(event) =>
                          f.onChange
                            ? f.onChange(event)
                            : setter({
                                ...data,
                                [f.key]: event.target.value,
                              })
                        }
                      />
                    ) : (
                      <div className="d-flex flex-row w-100">
                        <InputGroupText>{f.icon}</InputGroupText>
                        {f?.customEl}
                      </div>
                    )}
                  </InputGroup>
                  <div className="w-100">{f.validationElement}</div>
                </FormGroup>
                {f?.helper}
              </div>
            ))
          );
        })}
      </>
      {submitEl}
    </Form>
  );
};
