//@ts-nocheck
import React, { Fragment } from "react";
import { PhoneInput } from "react-international-phone";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import HelperHTTP from "src/_utils/http_module_handler";
import { CSS, parseHTML } from "src/_utils/styles";
import { Btn } from "src/AbstractElements";
import EBSSSModal from "src/base/atoms/EBSSSModal";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { remotePath } from "src/base/settings/Paths";
import { cartSuccess, clearCheckout, newOrderSessionShipping, Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import Header from "src/Layout/Header";
import { default as CheckContext, default as CustomContext } from "../../_helper/customizer";
import { CartItem } from "./CheckoutCommon";
import EBSSSLoading from "src/base/atoms/EBSSSLoading";
import { Layout } from "src/base/store/layoutSlice";

function injectCSSLinks() {
  const cssLinks = [
    {
      rel: "stylesheet",
      href: "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css",
    },
    {
      rel: "stylesheet",
      href: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.7/build/css/intlTelInput.css",
    },
    {
      rel: "stylesheet",
      href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css",
    },
  ];

  cssLinks.forEach((linkInfo) => {
    const linkElement = document.createElement("link");
    linkElement.rel = linkInfo.rel;
    linkElement.href = linkInfo.href;
    document.head.appendChild(linkElement);
  });
}

const DAY_IN_MS = 86400000;
const css = (cssObj) => Object.assign({}, cssObj);

const isTruthy = (param) => param !== "" && param !== "0" && param !== 0 && param !== "false" && param === true;

const filterKeysWithPrefix = (obj, prefix = null, cb = null, inverted = false) => {
  let filteredObj = {};
  let toInvertObj = obj;
  for (const key in obj) {
    let valuedCb = obj[key];
    if (cb) {
      valuedCb = cb(valuedCb);
    }
    if ((prefix && key.startsWith(prefix)) || !prefix) {
      if (!inverted) {
        filteredObj[key] = valuedCb;
      } else {
        delete toInvertObj[key];
      }
    }
  }
  return !inverted ? filteredObj : toInvertObj;
};

const formatTimer = (ms) => {
  // Calculate total seconds
  let totalSeconds = Math.floor(ms / 1000);

  // Calculate hours, minutes, and seconds
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);
  let seconds = totalSeconds % 60;

  // Format hours, minutes, and seconds to be always two digits
  let formattedHours = String(hours).padStart(2, "0");
  let formattedMinutes = String(minutes).padStart(2, "0");
  let formattedSeconds = String(seconds).padStart(2, "0");

  // Return the formatted time string
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

const getFurthestDay = (daysArray) => {
  // Ordered array of week days for comparison
  const weekDaysOrder = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

  // Initialize the furthest day and its index
  let furthestDay = null;
  let maxIndex = -1;

  // Loop through the days array
  for (let day of daysArray) {
    // Find the index of the current day's week_day in the ordered array
    const currentIndex = weekDaysOrder.indexOf(day.week_day.toLowerCase());

    // Update furthest day if the current day is further in the week
    if (currentIndex > maxIndex) {
      maxIndex = currentIndex;
      furthestDay = day;
    }
  }

  return furthestDay;
};

const formatToYYYYMMDD = (dateObj) => {
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObj.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const filterRepeatedObjects = (array) => {
  const seen = new Set();
  return array
    .filter((item) => {
      const key = JSON.stringify(item);
      if (seen.has(key)) {
        return false;
      }
      seen.add(key);
      return true;
    })
    .filter((v) => !!v);
};

const focusOnFirstEmptyInput = (formId) => {
  const form = document.getElementById(formId);
  if (!form) return;

  const inputs = form.querySelectorAll("input");
  for (let i = 0; i < inputs.length; i++) {
    if (!inputs[i].value.trim()) {
      inputs[i].focus();
      break;
    }
  }
};

const calculateRouteDistance = (lat1, lng1, lat2, lng2) => {
  let directionsDisplay = new google.maps.DirectionsRenderer();
  let directionsService = new google.maps.DirectionsService();
  console.log("calculating route: ", lat1, lng1, "|", lat2, lng2);

  let start = lat1 + " " + lng1;
  let end = lat2 + " " + lng2;
  let request = {
    origin: start,
    destination: end,
    travelMode: "DRIVING",
  };
  directionsService.route(request, function (result, status) {
    console.log("directions service result status", result, status);

    if (status == "OK") {
      if (result.routes.length > 0) {
        let route = result.routes[0].legs[0];
        console.log(route.distance.value / 1000);
      }
    }
  });
};
const getCountryCode = () => {
  const language = navigator.language || navigator["userLanguage"];
  const countryCode = language.split("-")[1] || language.split("_")[1];
  return countryCode ? countryCode : "pt";
};
export const emitSwal = ({ type, message, configSwal }) => {
  let settingsSwal = {
    title: "",
    text: message,
    icon: type !== "e" ? (type === "s" ? "success" : "info") : "error",
  };

  if (configSwal) {
    settingsSwal = {
      ...settingsSwal,
      ...configSwal,
    };
    configSwal.callback(swal(settingsSwal));
    return;
  }

  swal(settingsSwal);
  document.querySelector(".sweet-alert")?.classList?.remove("visible");
  document.querySelector(".sweet-alert")?.classList?.remove("showSweetAlert");
};
const standardizeSwal = (forLogin) => {
  document.querySelector(".swal-footer").classList.add("d-flex");
  document.querySelector(".swal-footer").classList.add("justify-content-center");
  document.querySelectorAll(".swal-button").forEach((el) => el.classList.add("rounded-0"));
  document.querySelector(".swal-text").classList.add("text-center");
  if (forLogin) {
    document.querySelector(".swal-modal").children[1].after(document.querySelector(".swal-modal").children[0]);
    document.querySelector(".swal-modal").children[1].classList.add("mb-2", "mt-4");
  }
};
const capitalizeWords = (str, option = "all") => {
  // Helper function to capitalize the first letter of a word
  function capitalize(word) {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1);
  }

  if (!str) {
    return "";
  }

  // Based on the option, perform the corresponding capitalization
  switch (option.toLowerCase()) {
    case "all":
      return str?.split(" ")?.map(capitalize)?.join(" ");
    case "first":
      return capitalize(str?.split(" ")[0]) + str?.slice(str?.indexOf(" "));
    case "none":
      return str;
    default:
      throw new Error("Invalid option. Use 'all', 'first', or 'none'.");
  }
};
const { createContext, useState, useEffect, useContext, useRef } = React;

const LoadingIndicator = () => {
  return (
    <div className="loading-container">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

const FallBackMessage = ({ message }) => {
  return (
    <div className="loading-container">
      <div className="d-flex flex-column align-items-center gap-5">
        <span>{message}</span>
        <a
          href={window.location.origin + (window["TEMP_REDIRECT_LOGIN"] ? process.env.PUBLIC_URL + "/auth/sign-in" : process.env.PUBLIC_URL + "/crm/products")}
        >
          <i className="fa-solid fa-person-walking-arrow-loop-left" style={CSS({ fontSize: "xx-large" })}></i>
        </a>
      </div>
    </div>
  );
};

const CartContext = createContext({} as any);
const CartProvider = ({ children }) => {
  const { removeItemFromCart } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const dispatcher = useDispatch();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const [redirectLinkFinishedOrder, setRedirectLinkFinishedOrder] = useState("");

  const [cartLoaded, setCartLoaded] = useState(false);
  const [availableShippings, setAvailableShippings] = useState([]);
  const [translatedShippings, setTranslatedShippings] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cartState, setCartState] = useState([]);
  const [authed, setAuthed] = useState({});
  const [districts, setDistricts] = useState([]);
  const [onlyTakeAway, setOnlyTakeAway] = useState(false);
  const [allowTakeAway, setAllowTakeAway] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const [sysMultiSellers, setSysMultiSellers] = useState(false);
  const [payNow, setPayNow] = useState(0);
  const [total, setTotal] = useState(0);
  const [billingInformation, setBillingInformation] = useState({});
  const [availableCoupons, setAvailableCoupons] = useState(0);
  const [requirePaymentMethodBeforeGenerateOrder, setRequirePaymentMethodBeforeGenerateOrder] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [translatedPaymentMethods, setTranslatedPaymentMethods] = useState([]);
  const [paymentMethodsSetted, setPaymentMethodsSetted] = useState({});
  const [paymentDirectToSeller, setPaymentDirectToSeller] = useState(false);
  const [cartItemTypes, setCartItemTypes] = useState({});
  const [toursLat, setToursLat] = useState("");
  const [toursLon, setToursLon] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [shippingCost, setShippingCost] = useState("");
  const [errs, setErrs] = useState(null);
  const [validations, setValidations] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [municipalities, setMunicipalities] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [steps, setSteps] = useState([]);
  const [ttlToExpire, setTTLToExpire] = useState(null);
  const [reasonable, setReasonable] = useState("");
  const [showCartImages, setShowCartImages] = useState(true);
  const [tenantConfig, setTenantConfig] = useState({
    hiddenLogo: false,
    tenant: "",
    title: "",
    logo: "",
  });

  const [requiredInputs, setRequiredInputs] = useState({
    vat: false,
    email: false,
    mobile_number: false,
  });

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const fetchCartState = async (handleLoader = true) => {
    try {
      const response = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT)
        .authed({ tk: "Bearer " + sessionNow.token })
        .api()
        .get();

      if (!response?.data?.cart) {
        //setErrs(response.message);
        return;
      }
      setCartState(response?.data?.cart ? (Array.isArray(response?.data?.cart) ? response?.data?.cart : Object.values(response?.data?.cart)) : []);
      setShowShipping(response?.data?.show_shipping);
      setPayNow(response?.data?.pay_now);
      setTotal(response?.data?.total);
      setAvailableCoupons(response?.data?.available_coupons);
      setCartItemTypes(response?.data?.cart_item_types);
      setOnlyTakeAway(isTruthy(response?.data?.only_take_away));
      setAllowTakeAway(isTruthy(response?.data?.allow_take_away));
      setSysMultiSellers(isTruthy(response?.data?.sys_multi_sellers));
      setPaymentMethods(response?.data?.translated_payment_methods);
      setTranslatedPaymentMethods(response?.data?.translated_payment_methods);
      setPaymentMethodsSetted(filterKeysWithPrefix(response?.data?.payment_methods_setted, null, (pm) => (typeof pm === "string" ? isTruthy(pm) : pm)));
      setTranslatedShippings(response?.data?.translated_shipping_methods);
      setAvailableShippings(response?.data?.available_shipping_methods);
      setToursLat(response?.data?.tours_latitude);
      setToursLon(response?.data?.tours_longitude);

      if (!handleLoader) {
        return;
      }

      setSteps([trans("website.address"), trans("website.finish")]);
      setCountries(response?.data?.countries);
      setAuthed(response?.data?.auth);
      setDistricts(response?.data?.districts);
      setPaymentDirectToSeller(isTruthy(response?.data?.payment_direct_to_seller));
      setRequirePaymentMethodBeforeGenerateOrder(isTruthy(response?.data?.require_payment_method_before_generate_order));
      setBillingInformation(filterKeysWithPrefix(response.data, "billing_"));
      setCurrencySymbol(response?.data?.currency_symbol);
      setTTLToExpire(response?.data?.cart_expiration);
      setRequiredInputs(response?.data?.requires);
      setShowCartImages(response?.data?.show_cart_images);
      setTenantConfig({
        hiddenLogo: response?.data?.tenant_configuration.hidden_logo ?? false,
        tenant: response?.data?.tenant_configuration.tenant ?? "",
        title: response?.data?.tenant_configuration.title ?? "",
        logo: response?.data?.tenant_configuration.logo ?? "",
      });
      let applyFavicon = setTimeout(() => {
        var element = document.createElement("link");
        element.setAttribute("rel", "shortcut icon");
        element.setAttribute("href", window.location.origin + "/files/" + response?.data?.tenant_configuration.favicon);
        document.head.append(element);
        clearTimeout(applyFavicon);
      }, 1);
      let applyCSS = setTimeout(() => {
        var element = document.createElement("style");
        element.setAttribute("type", "text/css");
        element.innerHTML = response?.data?.tenant_configuration.website_custom_css;
        document.head.append(element);
        clearTimeout(applyCSS);
      }, 1);
    } catch (e: Error) {
      if (e?.response?.data?.errors && e?.response?.data?.errors?.flag === "LOGIN") {
        window["TEMP_REDIRECT_LOGIN"] = true;
      }
      console.log("An error occurs: ", e);
      if (!handleLoader) {
        window.location.reload();
      }
    } finally {
      if (handleLoader) {
        setCartLoaded(!cartLoaded);
      }
    }
  };

  const incrementQuantity = (id) => {
    setCartState(sessionNow?.session?.cart?.map((item) => (item?.p?.id === id ? { ...item, quantity: Number(item?.quantity) + 1 } : item)));
  };

  const decrementQuantity = (id) => {
    setCartState(
      sessionNow?.session?.cart?.map((item) => (item?.p?.id === id && item?.quantity > 1 ? { ...item, quantity: Number(item?.quantity) - 1 } : item))
    );
  };

  const removeItem = async (e, token) => {
    e.preventDefault();
    try {
      await removeItemFromCart(token);
      setCartState(cartState?.filter((item) => item?.p?.token !== token));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCartState();
  }, []);

  useEffect(() => {
    fetchCartState(false);
  }, [sessionNow.token]);

  useEffect(() => {
    if (cartState.length === 0 && reasonable === "EMPTY_CART_AFTER_DELETE_ITEM") {
      console.log("refreshing due to no items in cart...");
      window.location.reload();
    }
  }, [cartState, reasonable]);

  async function setOrderSession(data) {
    console.log("Setting session...");
    const response = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_ORDER_SESSION)
      .authed({ tk: "Bearer " + sessionNow.token })
      .api()
      .post({ data });

    if (response.success) {
      if (response.data.session_shipping) {
        dispatcher(newOrderSessionShipping({ data: response.data }));
        let shCost = parseFloat(Number(parseFloat(response.data.session_shipping.cost)).toFixed(2));
        console.log("Shipping cost: ", shCost, ", Total: ", total, " Paynow: ", payNow);
        if (String(shCost) === String(shippingCost)) {
          return response;
        }
        setShippingCost(String(shCost));
      }
    } else {
      setValidations((s) => {
        return { ...s, ...response.errors };
      });
    }
    return response;
  }

  async function saveOrder(data) {
    console.log("Setting session...");
    const response = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_ORDER_SAVE)
      .authed({ tk: "Bearer " + sessionNow.token })
      .api()
      .post({ data });

    console.log("saveOrder: ", response);

    if (!response.success) {
      let configErrorFeedback = { configSwal: {} };
      if (response?.flag && response?.flag === "LOGIN") {
        let createIconContainer = document.createElement("div");
        createIconContainer.innerHTML = '<i className="fa fa-right-to-bracket h1 mt-3"></i>';
        createIconContainer = createIconContainer.firstChild;

        configErrorFeedback.configSwal["icon"] = null;
        configErrorFeedback.configSwal["content"] = createIconContainer;
        configErrorFeedback.configSwal["buttons"] = {
          login: {
            text: trans("website.sign-in"),
            className: "bg-success",
            closeModal: true,
          },
          forgot: {
            text: trans("website.sign-up"),
            closeModal: true,
          },
        };
        configErrorFeedback.configSwal["callback"] = async (result) => {
          console.log("swal result: ", await result);
          if ((await result) === "login") {
            setRedirectLinkFinishedOrder(process.env.PUBLIC_URL + "/auth/sign-in");
            return;
          }
          if ((await result) === "forgot") {
            setRedirectLinkFinishedOrder(process.env.PUBLIC_URL + "/auth/sign-in/forgot");
            return;
          }
        };
      }
      console.log({ message: response.message, ...configErrorFeedback });
      emitSwal({ message: response.message, ...configErrorFeedback });
      standardizeSwal(true);
      return;
    }

    dispatcher(clearCheckout({ data: response.data }));
    let tokenToRedirect = response.data.url.split("/")[3];

    setRedirectLinkFinishedOrder(
      `${process.env.PUBLIC_URL}/crm/${response.data.url.includes("contract-payments") ? "contract-payments" : "orders"}/${tokenToRedirect}`
    );
  }

  async function getShippingValidation(data) {
    console.log("getShippingValidation...");
    const response = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_SHIPPING_VALIDATION)
      .authed({ tk: "Bearer " + sessionNow.token })
      .api()
      .post({ data });
    if (!response.success) {
      setValidations((s) => {
        return { ...s, ...response.errors };
      });
    }
    return response;
  }

  async function getMunicipalities(districtId) {
    console.log("getMunicipalities...");
    const response = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_MUNICIPALITIES_LIST)
      .authed({ tk: "Bearer " + sessionNow.token })
      .appendQuery({
        district_id: districtId,
      })
      .api()
      .get();

    setMunicipalities([]);
    setParishes([]);
    setMunicipalities(response.data);
  }

  async function getParishes(municipalityId) {
    console.log("getParishes...");
    const response = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_PARISHES_LIST)
      .authed({ tk: "Bearer " + sessionNow.token })
      .appendQuery({
        municipality_id: municipalityId,
      })
      .api()
      .get();
    setParishes([]);
    setParishes(response.data);
  }

  async function applyCoupon(data, couponActual) {
    console.log("Setting coupon...");
    let copyOldCouponValue;
    if (couponActual?.id) {
      setPayNow(payNow + couponActual?.value);
    }
    const response = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_COUPON)
      .authed({ tk: "Bearer " + sessionNow.token })
      .api()
      .post({ data });
    if (response.success) {
      setPayNow(Number(parseFloat(response.data.totalValue)));
    }
    return response;
  }

  // if (redirectLinkFinishedOrder) {
  //   return <Navigate to={redirectLinkFinishedOrder} />;
  // }

  return (
    <CartContext.Provider
      value={Object.assign(
        {},
        {
          availableShippings,
          translatedShippings,
          countries,
          cartState,
          authed,
          districts,
          onlyTakeAway,
          allowTakeAway,
          showShipping,
          sysMultiSellers,
          payNow,
          total,
          billingInformation,
          availableCoupons,
          requirePaymentMethodBeforeGenerateOrder,
          paymentMethods,
          translatedPaymentMethods,
          paymentMethodsSetted,
          paymentDirectToSeller,
          cartItemTypes,
          toursLat,
          toursLon,
          currencySymbol,
          shippingCost,
          errs,
          steps,
          validations,
          setValidations,
          municipalities,
          parishes,
          getMunicipalities,
          getParishes,
          setOrderSession,
          getShippingValidation,
          handleNext,
          handlePrevious,
          handleStepClick,
          currentStep,
          applyCoupon,
          saveOrder,
          ttlToExpire,
          removeItem,
          tenantConfig,
          fetchCartState,
          setShippingCost,
          setReasonable,
          requiredInputs,
          showCartImages,
        }
      )}
    >
      {cartLoaded ? errs ? <FallBackMessage message={errs} /> : children : <EBSSSLoading />}
    </CartContext.Provider>
  );
};

/////////

const CartTimer = () => {
  const { trans } = useEBSSSLayout();

  const { ttlToExpire, fetchCartState } = useContext(CartContext);
  const [expTimer, setExpTimer] = useState(ttlToExpire);

  useEffect(() => {
    if (expTimer !== null) {
      const interval = setInterval(() => {
        setExpTimer((prevTime) => {
          if (prevTime <= 1000) {
            clearInterval(interval);
            return 0;
          }
          return prevTime - 1000;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (expTimer <= 0) {
      window.location.reload();
    }
  }, [expTimer]);

  return <span>{formatTimer(expTimer)}</span>;
};

const GEOAutoCompleteInput = ({
  onPlaceSelected,
  billingDetails,
  setBillingDetails,
  setOrderSession,
  validateShipping,
  setValidateShipping,
  typedSearch,
  setTypedSearch,
}) => {
  const { trans } = useEBSSSLayout();

  function initMap() {
    let inputLocationSearch = document.getElementById("location-search");
    let map = new google.maps.Map(document.getElementById("map"));
    let autocomplete = new google.maps.places.Autocomplete(inputLocationSearch);

    autocomplete.addListener("place_changed", function () {
      let place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      setTypedSearch(place.formatted_address);
      map.setCenter(place.geometry.location);
      map.setZoom(15);

      let marker = new google.maps.Marker({
        map: map,
        position: place.geometry.location,
        draggable: true,
      });

      google.maps.event.addListener(marker, "dragend", function () {
        let geocoder = new google.maps.Geocoder();
        let lat = marker.getPosition().lat();
        let lng = marker.getPosition().lng();

        let latlng = new google.maps.LatLng(lat, lng);

        geocoder.geocode({ location: latlng }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              let copyBillDetails = { ...billingDetails };
              let copyShippingDetails = { ...validateShipping };
              const newData = {} as any;

              results[0].address_components.forEach((object) => {
                object.types.forEach((name) => {
                  newData[name] = object.long_name;
                  newData[name + "_code"] = object.short_name;
                });
              });

              copyBillDetails["identifier"] = new Date().getTime();
              copyBillDetails["customer_latitude"] = lat;
              copyBillDetails["customer_longitude"] = lng;

              if (newData.country_code) {
                copyBillDetails["customer_country_code"] = newData.country_code;
                copyBillDetails["billing_country"] = newData.country_code;
                copyShippingDetails["country_code"] = newData.country_code;
              }

              if (newData.locality) {
                copyBillDetails["customer_locality"] = newData.locality;
                copyShippingDetails["locality"] = newData.locality;
              }

              if (newData.administrative_area_level_1) {
                copyBillDetails["customer_state"] = newData.administrative_area_level_1;
                copyShippingDetails["state_code"] = newData.administrative_area_level_1;
              }

              if (newData.route) {
                copyBillDetails["billing_address"] = newData.route;
              }

              if (newData.street_address) {
                copyBillDetails["billing_address"] = newData.street_address;
              }

              if (newData.street_number) {
                copyBillDetails["billing_address"] = copyBillDetails["billing_address"] + " " + newData.street_number;
              }

              if (newData.sublocality) {
                copyBillDetails["billing_address_2"] = newData.sublocality;
              }

              if (newData.administrative_area_level_2) {
                copyBillDetails["billing_city"] = newData.administrative_area_level_2;
              }

              if (newData.postal_code) {
                copyBillDetails["billing_postal_code"] = newData.postal_code;
                copyBillDetails["billing_postal_code_fixed"] = newData.postal_code;
              }

              if (newData.administrative_area_level_1) {
                copyBillDetails["billing_state"] = newData.administrative_area_level_1;
              }

              if (newData.administrative_area_level_1) {
                copyBillDetails["billing_state"] = newData.administrative_area_level_1;
              }

              if (newData.administrative_area_level_1_code) {
                copyBillDetails["billing_state"] = newData.administrative_area_level_1_code + " - " + newData.administrative_area_level_1;
              }

              setBillingDetails(copyBillDetails);
              setValidateShipping(copyShippingDetails);
            } else {
              console.log("No results found");
            }
          } else {
            console.log("Geocoder failed due to: " + status);
          }
        });
      });
    });
  }

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    focusOnFirstEmptyInput("address-form-global");
  }, [billingDetails.identifier]);

  return (
    <div>
      <input type="text" id="location-search" placeholder={trans("website.customer_location")} defaultValue={typedSearch ?? ""} className="form-control" />
      <div
        id="map"
        style={CSS({
          width: "100%",
          height: 300,
          marginTop: 5,
          borderRadius: 5,
        })}
      ></div>
      <form className="container mt-4" id="address-form-global">
        <div className="d-flex col-12 gap-3">
          <div className="mb-3 w-100">
            <label htmlFor="billing_address" className="form-label">
              {trans("website.billing_address")}: *
            </label>
            <input
              type="text"
              className="form-control"
              id="billing_address"
              value={billingDetails?.billing_address}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  billing_address: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex col-12 gap-3">
          <div className="mb-3 col-5">
            <label htmlFor="billing_address_2" className="form-label">
              {trans("website.billing_address_2")}: *
            </label>
            <input
              type="text"
              className="form-control"
              id="billing_address_2"
              value={billingDetails?.billing_address_2}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  billing_address_2: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-3 w-100">
            <label htmlFor="billing_city" className="form-label">
              {trans("website.billing_city")}: *
            </label>
            <input
              type="text"
              className="form-control"
              id="billing_city"
              value={billingDetails?.billing_city}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  billing_city: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex col-12 gap-3">
          <div className="mb-3 col-4">
            <label htmlFor="billing_postal_code" className="form-label">
              {trans("website.billing_postal_code")}: *
            </label>
            <input
              type="text"
              className="form-control"
              id="billing_postal_code"
              value={billingDetails?.billing_postal_code}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  billing_postal_code: e.target.value,
                  billing_postal_code_fixed: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-3 col-4">
            <label htmlFor="billing_state" className="form-label">
              {trans("website.billing_state")}: *
            </label>
            <input
              type="text"
              className="form-control"
              id="billing_state"
              value={billingDetails?.billing_state}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  billing_state: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-3 w-100">
            <label htmlFor="billing_country" className="form-label">
              {trans("website.billing_country")}: *
            </label>
            <input
              type="text"
              className="form-control"
              id="billing_country"
              value={billingDetails?.billing_country}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  billing_country: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex col-12 gap-3">
          <div className="mb-3 w-100">
            <label className="form-label" htmlFor="notes">
              {trans("website.order-special-notes")}:
            </label>
            <textarea
              id="notes"
              rows={5}
              className="form-control border-primary"
              name="notes"
              placeholder=""
              style={CSS({ resize: "none" })}
              value={billingDetails?.notes}
              onChange={(e) => setBillingDetails({ ...billingDetails, notes: e.target.value })}
            ></textarea>
          </div>
        </div>
      </form>
    </div>
  );
};

const DatePickerInput = ({ message, callback, daysCanBePicked, setDaysCanBePicked, checkPossibleDays }) => {
  const [pickerState, setPickerState] = useState(null);
  const [pickUpDays, setPickUpDays] = useState(null);

  const weekdaysByOrder = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  const getDaysToPickUp = async () => {
    let daysPick = await checkPossibleDays();
    if (daysPick?.length > 0) {
      if (pickUpDays === null) {
        setPickUpDays(daysPick);
        let possibleWeekDays = daysPick?.map((d) => weekdaysByOrder[d.week_day]);
        let minDatePick = new Date();
        let maxDatePick = new Date(new Date().getTime() + getFurthestDay(daysPick).max_days_later * DAY_IN_MS);

        let picker = new Pikaday({
          field: document.getElementById("datepicker"),
          disableDayFn: (d) => {
            return new Date().toDateString() === d.toDateString() || !possibleWeekDays.includes(d.getDay());
          },
        });

        picker.setMinDate(minDatePick);
        picker.setMaxDate(maxDatePick);
        picker.show();
        setPickerState(picker);
      } else {
        pickerState.show();
      }
    }
  };

  useEffect(() => {
    document.getElementById("datepicker").addEventListener("change", (e) => {
      callback(formatToYYYYMMDD(new Date(e.target.value)));
      //document.querySelector('#check-shipping-value').focus()
    });
  }, []);

  return (
    <div
      style={CSS({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "relative",
      })}
    >
      <input
        spellCheck="false"
        autoComplete="off"
        autoCorrect="off"
        style={CSS({
          width: "0%",
          outline: "none",
          border: "none",
          height: "0%",
          position: "absolute",
          backgroundColor: "transparent",
          caretColor: "transparent",
        })}
        type="text"
        id="datepicker"
      />
      <button
        id="days-pickup"
        className="btn btn-outline-primary btn-sm w-100 btn-wait-animated"
        onClick={async () => {
          if (document.querySelector("#days-pickup").className.includes("animate-wait")) {
            return;
          }
          document.querySelector("#days-pickup").disabled = true;
          document.querySelector("#days-pickup").classList.add("animate-wait");
          document.querySelector("#days-pickup").classList.add("btn-primary");
          document.querySelector("#days-pickup").classList.remove("btn-outline-primary");
          await getDaysToPickUp().then(async (r) => {
            document.querySelector("#days-pickup").disabled = false;
            document.querySelector("#days-pickup").classList.remove("animate-wait");
            document.querySelector("#days-pickup").classList.remove("btn-primary");
            document.querySelector("#days-pickup").classList.add("btn-outline-primary");
          });
        }}
      >
        <i className="fas fa-calendar me-3"></i> {message}
      </button>
    </div>
  );
};

const InvalidInputMessage = ({ message }) => {
  return (
    <p style={CSS({ fontSize: 12 })} className="text-danger">
      {message}
    </p>
  );
};

const ShoppingCart = () => {
  const { trans } = useEBSSSLayout();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const navigate = useNavigate();
  const { sendProductProposal, emptyCartAfterSentProductProposal } = useEBSSS();
  const dispatch = useDispatch();
  const {
    tenantConfig,
    fetchCartState,
    showCartImages,
    requiredInputs,
    setShippingCost,
    setReasonable,
    saveOrder,
    ttlToExpire,
    availableCoupons,
    steps,
    handleNext,
    handlePrevious,
    handleStepClick,
    currentStep,
    setOrderSession,
    applyCoupon,
    getShippingValidation,
    municipalities,
    parishes,
    getMunicipalities,
    getParishes,
    validations,
    setValidations,
    shippingCost,
    cartState,
    setCartState,
    authed,
    currencySymbol,
    showShipping,
    billingInformation,
    payNow,
    sysMultiSellers,
    total,
    countries,
    districts,
    allowTakeAway,
    cartItemTypes,
    onlyTakeAway,
    incrementQuantity,
    decrementQuantity,
    removeItem,
    availableShippings,
    translatedShippings,
    requirePaymentMethodBeforeGenerateOrder,
    paymentMethods,
    paymentMethodsSetted,
    translatedPaymentMethods,
    toursLat,
    toursLon,
  } = useContext(CartContext);
  const [selectedShippingId, setSelectedShippingId] = useState(0);
  const [selectedShippingObj, setSelectedShippingObj] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [municipalityId, setMunicipalityId] = useState(null);
  const [parishId, setParishId] = useState(null);
  const [districtId, setDistrictId] = useState(null);
  const [phone, setPhone] = useState("");
  const [isShippingValid, setIsShippingValid] = useState(false);
  const [pickUpDay, setPickUpDay] = useState(null);
  const [daysCanBePicked, setDaysCanBePicked] = useState(null);
  const [spinnerFinish, setSpinnerFinish] = useState(false);
  const [spinnerProceed, setSpinnerProceed] = useState(false);
  const [typedSearch, setTypedSearch] = useState("");
  const [resultValidatedShippingMethodChosen, setResultValidatedShippingMethodChosen] = useState(null);
  const [paymentMethodsOptions, setPaymentMethodsOptions] = useState([]);
  const [couponText, setCouponText] = useState("");
  const [alreadyFilledAutoShippingMethod, setAlreadyFilledAutoShippingMethod] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    paymentMethod: "",
    district_id: "",
    mobile_number: "",
    chosen_shipping_type_global_cost: "",
    notes: "",
    "same-address": "1",
    "take-away": "0",
    code: "",
    customer_location: "",
    customer_latitude: "",
    customer_longitude: "",
    customer_distance: "0",
    customer_country_code: "",
    customer_locality: "",
    customer_state: "",
    billing_country: "",
    billing_name: "",
    billing_email: "",
    billing_vat: "",
    billing_postal_code_fixed: "",
    billing_address: "",
    billing_address_2: "",
    billing_state: "",
    billing_city: "",
    billing_postal_code: "",
    shipping_name: "",
    shipping_address: "",
    shipping_address_2: "",
    shipping_country: "",
    shipping_city: "",
    shipping_postal_code: "",
    shipping_state: "",
    shipping_method: "",
    shipping_method_type: "",
  });
  const [validateShipping, setValidateShipping] = useState({
    shipping_methods: JSON.stringify(availableShippings.map((avsh) => avsh.id)),
    cost_type: "",
    country_code: "",
    state_code: "",
    locality: "",
  });
  const [couponDiscount, setCouponDiscount] = useState({
    id: null,
    discount: null,
    value: 0,
    text: "",
  });
  const [formDataProposal, setFormDataProposal] = useState({
    proposal_name: "",
    proposal_email: "",
    proposal_mobile_number: "",
    proposal_user_request: "",
    proposal_product: "",
    proposal_index: "",
    proposal_indexes: [],
  });
  const [modalProposal, setModalProposal] = useState(false);
  const toggleModalProposal = () => {
    setModalProposal(!modalProposal);
  };

  const handleInputChange = (event, v = null, k = null) => {
    setFormDataProposal({
      ...formDataProposal,
      [k ? k : event.target.name]: v ? v : event.target.value,
    });
  };

  useEffect(() => {
    let initialObjFormData = {
      proposal_name: sessionNow?.user?.id ? sessionNow?.user?.profile?.first_name + " " + sessionNow?.user?.profile?.last_name : "",
      proposal_email: sessionNow?.user?.id ? sessionNow?.user?.email : "",
      proposal_mobile_number: sessionNow?.user?.id ? sessionNow?.user?.mobile_number : "",
      proposal_user_request: "",
      proposal_product: "",
      proposal_index: "",
      proposal_indexes: sessionNow?.session?.cart
        ?.map((item) => {
          return item?.hash;
        })
        .join("#"),
    };

    setFormDataProposal(initialObjFormData);
  }, [sessionNow?.session?.cart]);

  const handleCheckoutForm = (input, val) => {
    let copyState = { ...billingDetails };
    copyState[input] = val;
    setBillingDetails({ ...copyState });
  };

  const checkPossibleDays = async () => {
    try {
      setValidations({});
      let val = await getShippingValidation(validateShipping);
      return val?.shipping.find((sv) => sv.id === selectedShippingObj.id)?.days ?? [];
    } catch (e) {
      console.log(e);
    }
  };

  const checkLocalValidations = () => {
    let instantValidations = [];
    let objectValidation = {};
    if (requiredInputs.vat && !billingDetails.billing_vat) {
      instantValidations.push(trans("website.form-field-required-vat"));
      objectValidation["billing_vat"] = [trans("website.attribute-required")];
    }
    if (requiredInputs.email && !billingDetails.billing_email) {
      instantValidations.push(trans("website.form-field-required-email"));
      objectValidation["billing_email"] = [trans("website.attribute-required")];
    }
    if (requiredInputs.mobile_number && !billingDetails.mobile_number) {
      instantValidations.push(trans("website.form-field-required-phone"));
      objectValidation["mobile_number"] = [trans("website.attribute-required")];
    }
    if (requiredInputs.mobile_number && !billingDetails.mobile_number.includes("+")) {
      instantValidations.push(trans("website.form-field-require-country-phone-code"));
      objectValidation["mobile_number"] = [trans("website.attribute-required")];
      setPhone("");
      setBillingDetails({ ...billingDetails, mobile_number: "" });
    }
    if (!billingDetails.billing_name) {
      instantValidations.push(trans("website.form-field-required-name"));
      objectValidation["billing_name"] = [trans("website.attribute-required")];
    }

    if (instantValidations.length) {
      setValidations({ ...objectValidation });
      emitSwal({ type: "e", message: instantValidations.join("\n") });
      standardizeSwal();
      if (document.querySelector("#headingOne").querySelector("button").className.includes("collapsed")) {
        document.querySelector("#headingOne").querySelector("button").click();
      }
    }

    return instantValidations.length === 0;
  };

  const checkShippingValidation = async (useNextHandler = false, closeAddress = false) => {
    try {
      setValidations({});

      if (!checkLocalValidations()) {
        return;
      }

      let val;
      if (showShipping) {
        val = await getShippingValidation(validateShipping);
        if (val?.data?.shipping?.length === 0) {
          emitSwal({
            type: "i",
            message: trans("website.no-shipping-available-for-this-location-and-type"),
          });
          standardizeSwal();
          return;
        }

        if (val.success) {
          setResultValidatedShippingMethodChosen(val?.data?.shipping);

          if (closeAddress && validateShipping.cost_type !== "point") {
            document.querySelector("#accordionAddressCollapsable").click();
          }

          if (useNextHandler) {
            await saveOrder({ ...billingDetails });
            return;
          }

          setIsShippingValid(val.success);
          return val;
        }
      } else {
        val = await setOrderSession(billingDetails);

        if (useNextHandler) {
          await saveOrder({ ...billingDetails });
          return;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAppliedCoupon = async () => {
    let invalidCoupon = () => emitSwal({ type: "i", message: trans("website.sale-invalid-coupon") });

    if (!couponText) {
      invalidCoupon();
      standardizeSwal();
      return;
    }

    setCouponDiscount({
      id: null,
      discount: null,
      value: 0,
      text: "",
    });

    if (!availableCoupons) {
      invalidCoupon();
      standardizeSwal();
      return;
    }

    let res = await applyCoupon(
      {
        code: couponText,
      },
      couponDiscount
    );

    if (!res.success) {
      invalidCoupon();
      standardizeSwal();
      return;
    }

    setCouponDiscount({
      text: couponText,
      id: res.id,
      discount: res.valueOff,
      value: Number(parseFloat(res.base)) - Number(parseFloat(res.totalValue)),
    });

    emitSwal({ type: "s", message: trans("website.sale-coupon-applied") });
    standardizeSwal();
  };

  // useEffect(() => {
  //   if (
  //     !sessionNow?.session?.cart?.some((item) => {
  //       if (item.p.instant_payment !== 1 || (item.p.type === "physical" && item.p.stock - item.quantity < 0)) {
  //         if (item.p && item.p.instant_payment !== 1) {
  //           return true;
  //         }
  //       }
  //     })
  //   ) {
  //     navigate(process.env.PUBLIC_URL + "/checkout");
  //   }
  // }, [sessionNow?.session?.cart]);

  useEffect(() => {
    let copyShippingDetails = { ...validateShipping };

    if (parishId) {
      copyShippingDetails.civil_parish_id = parishId;
    }
    if (billingDetails.customer_latitude && billingDetails.customer_longitude) {
      copyShippingDetails.latitude = billingDetails.customer_latitude.toString();
      copyShippingDetails.longitude = billingDetails.customer_longitude.toString();
    }

    setValidateShipping(copyShippingDetails);
  }, [parishId, billingDetails]);

  //Handler to check non-point based shippings changings
  useEffect(() => {
    let copyBillingDetails = { ...billingDetails };

    if (validateShipping.cost_type === "point" && selectedShippingId) {
      copyBillingDetails.billing_country = "181";
      copyBillingDetails.shipping_country = "181";
    }

    if (validateShipping.cost_type !== "point" && selectedShippingId) {
      if (validateShipping.cost_type === "location") {
        //copyBillingDetails.shipping_country = 'PT'
        copyBillingDetails.billing_country = "181";
        copyBillingDetails.shipping_country = "181";
      }

      if (validateShipping.cost_type === "kilometer_route") {
        calculateRouteDistance(
          billingDetails.customer_latitude,
          billingDetails.customer_longitude,
          validateShipping.shipping_latitude,
          validateShipping.shipping_longitude
        );
      }
    }

    if (phone) {
      copyBillingDetails.mobile_number = phone;
    }

    if (showShipping && !onlyTakeAway && allowTakeAway && !sysMultiSellers) {
      copyBillingDetails["take-away"] = "0";
    }

    if (onlyTakeAway || !showShipping) {
      copyBillingDetails["take-away"] = "0";
      copyBillingDetails["same-address"] = "1";
    }

    if (authed) {
      copyBillingDetails.billing_vat = !copyBillingDetails?.billing_vat ? authed?.profile?.vat_number ?? "" : copyBillingDetails?.billing_vat;
      copyBillingDetails.billing_name = !copyBillingDetails?.billing_name
        ? (authed?.profile?.first_name ?? "") + " " + (authed?.profile?.last_name ?? "")
        : copyBillingDetails?.billing_name;
      copyBillingDetails.billing_address = !copyBillingDetails?.billing_address
        ? (authed?.profile?.address_line_1 ?? "") + " " + (authed?.profile?.address_line_2 ?? "")
        : copyBillingDetails?.billing_address;
      copyBillingDetails.billing_address_2 = !copyBillingDetails?.billing_address_2
        ? authed?.profile?.administrative_area ?? ""
        : copyBillingDetails?.billing_address_2;
      copyBillingDetails.billing_postal_code = !copyBillingDetails?.billing_postal_code
        ? authed?.profile?.postal_code ?? ""
        : copyBillingDetails?.billing_postal_code;
      copyBillingDetails.billing_postal_code_fixed = !copyBillingDetails?.billing_postal_code_fixed
        ? authed?.profile?.postal_code ?? ""
        : copyBillingDetails?.billing_postal_code_fixed;
      copyBillingDetails.billing_city = !copyBillingDetails?.billing_city ? authed?.profile?.locality ?? "" : copyBillingDetails?.billing_city;
      copyBillingDetails.billing_email = !copyBillingDetails?.billing_email ? authed?.email ?? "" : copyBillingDetails?.billing_email;
      setPhone(!copyBillingDetails?.mobile_number ? authed?.mobile_number ?? "" : copyBillingDetails?.mobile_number);
    }

    if (pickUpDay) {
      copyBillingDetails = filterKeysWithPrefix(copyBillingDetails, "shipping_method_day_", null, true);
      copyBillingDetails["shipping_method_day_" + selectedShippingObj?.slug] = [
        String(formatToYYYYMMDD(new Date(new Date().getTime() + DAY_IN_MS))),
        String(pickUpDay),
      ];
      setBillingDetails(copyBillingDetails);
      setOrderSession(copyBillingDetails);
    }

    if (couponDiscount.id) {
      copyBillingDetails.code = couponDiscount.text;
    } else {
      copyBillingDetails.code = "";
    }

    setBillingDetails({ ...copyBillingDetails });

    if (validateShipping.cost_type !== "point" && selectedShippingObj && resultValidatedShippingMethodChosen) {
      setBillingDetails({
        ...billingDetails,
        chosen_shipping_type_global_cost: resultValidatedShippingMethodChosen.find((rvsmc) => rvsmc.id === selectedShippingObj?.id)?.location_cost,
      });
      console.log(
        "FINAL SELECTION SHIPPING DETAILED: ",
        resultValidatedShippingMethodChosen.find((rvsmc) => rvsmc.id === selectedShippingObj?.id)
      );
      if (resultValidatedShippingMethodChosen.find((rvsmc) => rvsmc.id === selectedShippingObj.id)?.days?.length > 0) {
        console.log("It has days!!!");
        return;
      }
      setOrderSession({
        ...copyBillingDetails,
        chosen_shipping_type_global_cost: resultValidatedShippingMethodChosen.find((rvsmc) => rvsmc.id === selectedShippingObj?.id)?.location_cost,
      });
    } else {
      if (selectedPoint) {
        setOrderSession({ ...copyBillingDetails });
      }
    }
  }, [
    window.intlTelInput,
    couponDiscount.id,
    pickUpDay,
    showShipping,
    onlyTakeAway,
    allowTakeAway,
    sysMultiSellers,
    phone,
    authed,
    selectedPoint,
    selectedShippingId,
    billingDetails?.identifier,
    selectedShippingObj,
    resultValidatedShippingMethodChosen,
  ]);

  useEffect(() => {
    let copyBillingDetails = { ...billingDetails };

    if (districtId) {
      setParishId(null);
      setMunicipalityId(null);
      getMunicipalities(districtId);
      copyBillingDetails.district_id = districtId;
      copyBillingDetails.billing_state = Object.entries(districts)?.find((d) => Number(d[0]) === Number(districtId))[1] ?? "";
      setBillingDetails({ ...copyBillingDetails });
    }
  }, [districtId]);

  useEffect(() => {
    let copyBillingDetails = { ...billingDetails };

    if (municipalityId) {
      setParishId(null);
      getParishes(municipalityId);
      copyBillingDetails.municipality_id = municipalityId;
      copyBillingDetails.billing_city = municipalities?.find((m) => Number(m.id) === Number(municipalityId))?.name ?? "";
      setBillingDetails({ ...copyBillingDetails });
    }
  }, [municipalityId]);

  useEffect(() => {
    let copyBillingDetails = { ...billingDetails };

    if (parishId) {
      copyBillingDetails.civil_parish_id = parishId;
      copyBillingDetails.billing_city = (billingDetails.billing_city ?? "") + " " + (parishes?.find((p) => Number(p.id) === Number(parishId))?.name ?? "");
      setBillingDetails({ ...copyBillingDetails });
    }
  }, [parishId]);

  //Validations
  useEffect(() => {
    console.log("validations: ", validations);
    let unvalidKeys = Object.entries(validations);
    let onlyUnvalidKeys = unvalidKeys.map((k) => k[0]);

    if (onlyUnvalidKeys.some((v) => v === "mobile_number" || v === "billing_name" || v === "billing_email" || v === "billing_vat")) {
      if (document.querySelector("#headingOne").querySelector("button").className.includes("collapsed")) {
        document.querySelector("#headingOne").querySelector("button").click();
      }
    }

    const feedbackElements = document.querySelectorAll('[id^="feedback-"]');
    if (feedbackElements.length > 0) {
      feedbackElements.forEach((ell) => ell.remove());
    }

    unvalidKeys.map((k, ik) => {
      let inputsOfThisKey = document.querySelectorAll("#" + k[0]);
      if (inputsOfThisKey?.length > 0) {
        inputsOfThisKey.forEach((el) => {
          k[1].map((msgK) => {
            let newInvMsg = document.createElement("p");
            newInvMsg.style.fontSize = "12px";
            newInvMsg.style.color = "red";
            newInvMsg.textContent = msgK;
            newInvMsg.id = "feedback-" + k[0] + "-" + msgK;
            if (k[0] === "mobile_number") {
              el.parentNode.after(newInvMsg);
            } else {
              el.after(newInvMsg);
            }
          });
        });
      }
    });
  }, [validations]);

  useEffect(() => {
    if (paymentMethods && paymentMethods?.length > 0) {
      let getAvailablePaymentOptions = paymentMethods?.filter((pMethod) => {
        let code = pMethod.code;
        return (
          (code === "wallet" && paymentMethodsSetted.wlt_wallet) ||
          (code === "rwd_wallet" && paymentMethodsSetted.rewards) ||
          (code === "delivery" && paymentMethodsSetted.payment_delivery) ||
          (code === "sibs" &&
            paymentMethodsSetted.sibs_terminal_id &&
            paymentMethodsSetted.sibs_client_id &&
            paymentMethodsSetted.sibs_bearer_token &&
            paymentMethodsSetted.sibs_entity) ||
          (code === "mbway" && paymentMethodsSetted.ifthenpay_entity) ||
          (code === "transf" &&
            (paymentMethodsSetted.invoice_transfer_bank || paymentMethodsSetted.invoice_transfer_iban || paymentMethodsSetted.invoice_transfer_swift)) ||
          (code === "mypos" && paymentMethodsSetted.mypos_virtual_production_url) ||
          (code === "paypal" && paymentMethodsSetted.paypal_live_api_username) ||
          (code === "meowallet" && paymentMethodsSetted.meo_api_key) ||
          (code === "pagseg" && paymentMethodsSetted.pagseg_token && paymentMethodsSetted.pagseg_email) ||
          (code === "easypay" && paymentMethodsSetted.easypay_apikey) ||
          (authed && authed?.id && code === "stripe" && paymentMethodsSetted.stripe_key)
        );
      });

      setPaymentMethodsOptions(getAvailablePaymentOptions);

      if (getAvailablePaymentOptions.length === 1) {
        setBillingDetails({
          ...billingDetails,
          paymentMethod: getAvailablePaymentOptions[0].token,
        });
      }
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (availableShippings && availableShippings?.length > 0) {
      let getAvailableShippings = filterRepeatedObjects(
        availableShippings.map((avs) => {
          if ((avs.custom_delivery === "address" && avs.cost_type) || avs.custom_delivery === "point") {
            return {
              custom_delivery: avs.custom_delivery,
              cost_type: avs.cost_type,
            };
          }
        })
      );

      if (getAvailableShippings.length === 1) {
        setShippingCost("");
        setSelectedShippingObj(null);
        setSelectedShippingId(0);
        setMunicipalityId(null);
        setParishId(null);
        setDistrictId(null);
        setIsShippingValid(false);
        setTypedSearch("");
        setResultValidatedShippingMethodChosen(null);
        setSelectedPoint(null);
        setPickUpDay(null);
        setDaysCanBePicked(null);
        setCouponDiscount({
          id: null,
          discount: null,
          value: 0,
          text: "",
        });
        setValidateShipping({
          ...validateShipping,
          cost_type: getAvailableShippings[0].cost_type === null ? "point" : getAvailableShippings[0].cost_type,
        });
      }
    }
  }, [availableShippings]);

  useEffect(() => {
    if (
      !alreadyFilledAutoShippingMethod &&
      resultValidatedShippingMethodChosen &&
      availableShippings?.filter((avs) => resultValidatedShippingMethodChosen?.find((rvsmc) => rvsmc.id === avs.id))?.length === 1
    ) {
      let getValidatedShipping = availableShippings?.filter((avs) => resultValidatedShippingMethodChosen?.find((rvsmc) => rvsmc.id === avs.id));

      console.log("getValidatedShipping: > ", getValidatedShipping);

      let shippId = getValidatedShipping[0].id;
      let copyBillingDetails = { ...billingDetails };
      copyBillingDetails.shipping_method_type = getValidatedShipping[0].custom_delivery;
      copyBillingDetails.shipping_method = getValidatedShipping[0].slug;
      setSelectedShippingId(shippId);
      setSelectedShippingObj(getValidatedShipping[0]);
      setBillingDetails(copyBillingDetails);
      setPickUpDay(null);
      setAlreadyFilledAutoShippingMethod(true);
      checkShippingValidation();
    }
  }, [resultValidatedShippingMethodChosen]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      let actualWidthClient = document.body.clientWidth;

      window.addEventListener("resize", () => {
        actualWidthClient = document.body.clientWidth;
      });

      let stickyElem = document.querySelector("#resume-checkout-1");
      let stickyElem2 = document.querySelector("#resume-checkout-2");
      let containerStickElem = document.querySelector("#resume-checkout-container-1");
      let containerStickElem2 = document.querySelector("#resume-checkout-container-2");
      let currStickyPos;

      if (stickyElem && containerStickElem) {
        currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset;
      } else {
        currStickyPos = stickyElem2.getBoundingClientRect().top + window.pageYOffset;
      }

      window.onscroll = function () {
        if (stickyElem && containerStickElem) {
          if (actualWidthClient < 973) {
            stickyElem.style.width = "100%";
            stickyElem.style.position = "relative";
            return;
          }
          if (window.pageYOffset > currStickyPos) {
            stickyElem.style.position = "sticky";
            stickyElem.style.top = "105px";
          } else {
            stickyElem.style.position = "relative";
            stickyElem.style.top = "0px";
            stickyElem.style.width = "100%";
          }
        } else {
          if (actualWidthClient < 973) {
            stickyElem2.style.width = "100%";
            stickyElem2.style.position = "relative";
            return;
          }
          if (window.pageYOffset > currStickyPos) {
            stickyElem2.style.position = "sticky";
            stickyElem2.style.top = "105px";
          } else {
            stickyElem2.style.position = "relative";
            stickyElem2.style.top = "0px";
            stickyElem2.style.width = "100%";
          }
        }
      };
    }
  }, [window]);

  const { sidebar_types, settings, defaultClass, setDefaultClass } = useContext<any>(CheckContext);
  const { setIsVertical } = useContext<any>(CustomContext);
  const settings1 = localStorage.getItem("sidebar_Settings") || settings;
  const sidebar_types1 = localStorage.getItem("sidebar_types") || sidebar_types;
  const [loadingSubmitProposal, setLoadingSubmitProposal] = useState(false);
  const [selectedCart, setSelectedCart] = useState("");
  const [selectedCartStyle, setSelectedCartStyle] = useState("closed");
  const location = useLocation();

  console.log("FULL DATA CART: ", sessionNow?.session?.cart, sessionNow?.session?.carts, sessionNow?.session?.sellers);

  const handleChangeCartTab = (tkn) => {
    setSelectedCartStyle("closed");
    let changeCart = setTimeout(() => {
      setSelectedCartStyle("open");
      setSelectedCart(tkn);
      clearTimeout(changeCart);
    }, 500);
  };

  useEffect(() => {
    console.log("SELLERS: ", sessionNow?.session?.sellers);
    if (Object.values(sessionNow?.session?.sellers)?.length > 0) {
      setSelectedCart(Object.values(sessionNow?.session?.sellers)[0]?.token);
      setSelectedCartStyle("open");
    }
  }, []);

  return (
    <div className={`page-wrapper ${!defaultClass ? sidebar_types1 : "compact-wrapper"} ${settings1}`} id="pageWrapper">
      <div className={`page-header`} style={CSS({ width: "100%", marginLeft: 0 })}>
        <Header isCheckoutPage={true} />
      </div>
      <div className="page-body-wrapper">
        <main
          style={CSS({
            backgroundColor: "#F4F5FA",
            minHeight: "100vh",
            flexDirection: "column",
            gap: 20,
            display: "flex",
            alignItems: "center",
            marginTop: 50,
          })}
        >
          <section style={CSS({ width: "90%" })}>
            <div className="pb-5 h-100">
              <div className="d-block py-3 m-auto">
                <Stepper currentStep={currentStep} steps={steps} onStepClick={handleStepClick} />
              </div>
              <div className="row d-flex justify-content-center h-100">
                <div className="col">
                  {currentStep === 1 ? (
                    <div className="card border-0 shadow-sm" style={CSS({ borderRadius: 5, minHeight: 600 })}>
                      <div className="card-body p-4">
                        <div className="row">
                          <div className="col-12 col-lg-7" id="data-checkout-1">
                            {sessionNow?.session?.cart?.some((item) => item.p.seller) ? (
                              <div className="d-flex align-items-center justify-content-center mb-3">
                                <div
                                  class="w-100 alert-dismissible alert alert-warning outline fade show d-flex rounded rounded-2"
                                  target="Alert-6"
                                  role="alert"
                                >
                                  <div className="d-flex flex-column">
                                    <div className="d-flex flex-row align-items-center">
                                      <i className="la la-exclamation me-1"></i>
                                      <span>{trans("website.choose-only-one-sellers-cart")}</span>
                                    </div>
                                    <div className="d-flex flex-row align-items-center flex-wrap mt-3">
                                      {Object.values(sessionNow?.session?.sellers)?.map((s) => {
                                        return (
                                          <div className="me-1 mb-1">
                                            <div
                                              style={CSS({ cursor: "pointer" })}
                                              className={`p-3 rounded rounded-2 ${selectedCart === s.token ? "bg-success" : "bg-secondary"}`}
                                              onClick={() => {
                                                if (selectedCart === s.token) {
                                                  handleChangeCartTab("");
                                                  return;
                                                }
                                                handleChangeCartTab(s.token);
                                              }}
                                            >
                                              <i className={`me-1 fs-5 ${selectedCart === s.token ? "la la-check" : "la la-shopping-cart"}`}></i>
                                              {s.fullName}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div
                              className="d-flex justify-content-between align-items-center mb-4 p-3 shadow-sm"
                              style={CSS({
                                borderRadius: 5,
                                backgroundColor: "#e9e9e950",
                              })}
                            >
                              <div>
                                <p className="mb-1 text-start">{trans("website.checkout")}</p>
                                <p className="mb-0 text-muted">
                                  {trans("website.total-qtd-products")}:{" "}
                                  {selectedCart ? Object.entries(sessionNow?.session?.carts)?.find((c) => c[0] === selectedCart)[1]?.length : ""}
                                </p>
                              </div>
                            </div>
                            <div
                              style={CSS({
                                overflowY: "auto",
                                maxHeight: "500px",
                                minHeight: "150px",
                                paddingRight: 20,
                              })}
                            >
                              {selectedCart ? (
                                <div className={`cart-multi-slide-div ${selectedCartStyle}`}>
                                  <div className="d-flex flex-row gap-2 align-items-center">
                                    <span>
                                      {trans("website.cart-multi-sellers-buying-from")}{" "}
                                      <span className="fw-bold">
                                        {Object.values(sessionNow?.session?.sellers)?.find((s) => s.token === selectedCart)?.fullName}
                                      </span>
                                    </span>
                                  </div>
                                  {sessionNow?.session?.cart
                                    ?.filter((iS) => iS.p.seller?.token === selectedCart)
                                    ?.map((item) => {
                                      return (
                                        <Fragment key={item?.p?.id}>
                                          <CartItem
                                            item={item}
                                            removeItem={removeItem}
                                            showCartImages={showCartImages}
                                            setReasonable={setReasonable}
                                            currencySymbol={currencySymbol}
                                            incrementQuantity={incrementQuantity}
                                            decrementQuantity={decrementQuantity}
                                            fetchCartState={fetchCartState}
                                            isPreCheckout={true}
                                            isOnlyProposalItems={sessionNow?.session?.cart?.every((i) => {
                                              if (i.p.instant_payment !== 1 || (i.p.type === "physical" && i.p.stock - i.quantity < 0)) {
                                                if (i.p && i.p.instant_payment !== 1) {
                                                  return true;
                                                }
                                              }
                                            })}
                                          />
                                        </Fragment>
                                      );
                                    })}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-lg-5" id="resume-checkout-container-1">
                            <div id="resume-checkout-1" style={CSS({ position: "relative" })}>
                              {ttlToExpire !== null ? (
                                <div className="card bg-light text-black rounded-3 border-0 shadow-sm mb-3" style={CSS({ borderRadius: 5 })}>
                                  <div className="card-body">
                                    <div className="d-flex justify-content-center align-items-center">
                                      <h5 className="mb-0 text-muted" style={CSS({ fontSize: 16 })}>
                                        <i className="fa fa-clock text-muted"></i> {trans("website.cart-expire-in")} <CartTimer />
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="card bg-light text-black rounded-3 border-0 shadow-sm" style={CSS({ borderRadius: 5 })}>
                                <div className="card-body">
                                  <div className="d-flex justify-content-between align-items-center mb-4">
                                    <h5 className="mb-0 text-muted">{trans("website.dashboard_details")}</h5>
                                  </div>
                                  {availableCoupons > 0 ? (
                                    <div className="border-2 mb-5" style={CSS({ borderRadius: 5 })}>
                                      <p className="mb-2" style={CSS({ fontWeight: 400 })}>
                                        <i className="fa fa-ticket text-muted"></i> {trans("website.coupon")}:
                                      </p>
                                      <input
                                        disabled
                                        className="border-0 w-100 shadow-sm p-3 mb-2"
                                        style={CSS({ borderRadius: 5 })}
                                        value={couponText}
                                        onChange={(e) => setCouponText(e.target.value)}
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="d-flex justify-content-between mb-3">
                                    <p
                                      className="mb-2"
                                      style={CSS({
                                        fontWeight: 400,
                                        fontSize: 12,
                                      })}
                                    >
                                      {trans("website.partial-order")}:{" "}
                                    </p>
                                    {selectedCart ? (
                                      <p
                                        className="mb-2"
                                        style={CSS({
                                          fontWeight: 400,
                                          fontSize: 12,
                                        })}
                                      >
                                        {ebsssLayout?.commonAppSettings?.currency_symbol +
                                          " " +
                                          Number(
                                            Object.entries(sessionNow?.session?.carts)
                                              ?.find((c) => c[0] === selectedCart)[1]
                                              ?.map((i) => Number(i.price))
                                              ?.reduce((a, b) => a + b, 0)
                                          ).toFixed(2)}
                                      </p>
                                    ) : (
                                      <p
                                        className="mb-2"
                                        style={CSS({
                                          fontWeight: 400,
                                          fontSize: 12,
                                        })}
                                      >
                                        {trans("website.not-selected")}
                                      </p>
                                    )}
                                  </div>
                                  {selectedCart ? (
                                    <button
                                      type="button"
                                      id="proceed-cart"
                                      className="btn btn-info btn-block btn-lg d-flex w-100 text-white btn-wait-animated"
                                      onClick={async () => {
                                        // let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT)
                                        //   .api()
                                        //   .appendQuery({ seller: selectedCart });

                                        // let postPayload = {};
                                        // if (sessionNow?.user?.id) {
                                        //   beforeReq.authed({ tk: "Bearer " + sessionNow.token });
                                        // }

                                        // const response = await beforeReq.post({ data: postPayload });

                                        // console.log(response);

                                        navigate(process.env.PUBLIC_URL + "/checkout/" + selectedCart);
                                      }}
                                    >
                                      {spinnerProceed ? (
                                        <div className="d-flex justify-content-center m-auto">
                                          <div className="spinner-border m-auto" role="status">
                                            <span className="sr-only">Loading...</span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="d-flex justify-content-between">
                                          <span>
                                            {trans("website.select")}{" "}
                                            {Object.values(sessionNow?.session?.sellers)?.find((s) => s.token === selectedCart)?.fullName}
                                            <i className="fas fa-long-arrow-alt-right ms-2"></i>
                                          </span>
                                        </div>
                                      )}
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                  {(validateShipping.cost_type &&
                                    ((validateShipping.cost_type === "point" && selectedPoint) ||
                                      (validateShipping.cost_type !== "point" && selectedShippingId)) &&
                                    billingDetails.shipping_method !== "" &&
                                    isShippingValid &&
                                    billingDetails.paymentMethod &&
                                    Object.keys(validations)?.length === 0) ||
                                  (!showShipping &&
                                    billingDetails.billing_name &&
                                    (requiredInputs.vat ? !!billingDetails.billing_vat : true) &&
                                    (requiredInputs.mobile_number ? !!billingDetails.mobile_number && billingDetails.mobile_number.includes("+") : true) &&
                                    (requiredInputs.email ? !!billingDetails.billing_email : true) &&
                                    billingDetails.paymentMethod &&
                                    Object.keys(validations)?.length === 0) ? (
                                    <button
                                      type="button"
                                      id="proceed-cart"
                                      className="btn btn-info btn-block btn-lg d-flex w-100 text-white btn-wait-animated"
                                      onClick={async () => {
                                        // await setOrderSession(billingDetails).then(handleNext)
                                        if (document.querySelector("#proceed-cart").className.includes("animate-wait")) {
                                          return;
                                        }
                                        setSpinnerProceed(true);
                                        document.querySelector("#proceed-cart").classList.add("animate-wait");
                                        await checkShippingValidation(true)
                                          .then(() => {
                                            document?.querySelector("#proceed-cart")?.classList?.remove("animate-wait");
                                            setSpinnerProceed(false);
                                          })
                                          .catch(() => {
                                            if (typeof document !== "undefined") {
                                              document?.querySelector("#proceed-cart")?.classList?.remove("animate-wait");
                                            }
                                            setSpinnerProceed(false);
                                          });
                                      }}
                                    >
                                      {spinnerProceed ? (
                                        <div className="d-flex justify-content-center m-auto">
                                          <div className="spinner-border m-auto" role="status">
                                            <span className="sr-only">Loading...</span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="d-flex justify-content-between">
                                          <span>
                                            {trans("website.payment")}
                                            <i className="fas fa-long-arrow-alt-right ms-2"></i>
                                          </span>
                                        </div>
                                      )}
                                    </button>
                                  ) : sessionNow?.session?.cart?.some((item) => {
                                      if (item.p.instant_payment !== 1 || (item.p.type === "physical" && item.p.stock - item.quantity < 0)) {
                                        if (item.p && item.p.instant_payment !== 1) {
                                          return true;
                                        }
                                      }
                                    }) ? (
                                    <div className="d-flex w-100 text-danger">
                                      <div className="d-flex justify-content-between">
                                        <span>
                                          <i className="la la-exclamation-circle me-2"></i>
                                          {trans("website.remove-outstock-itens-place-order")}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="card border-0 shadow-sm" style={CSS({ borderRadius: 5, minHeight: 600 })}>
                      <div className="card-body p-4">
                        <div className="row">
                          <div className="col-12 col-lg-7" id="data-checkout-2">
                            <div
                              className="d-flex justify-content-between align-items-center mb-4 p-3 shadow-sm"
                              style={CSS({
                                borderRadius: 5,
                                backgroundColor: "#e9e9e950",
                              })}
                            >
                              <div>
                                <p className="mb-1 text-start">{trans("website.cart")}</p>
                                <p className="mb-0 text-muted">{trans("website.order-items")}</p>
                              </div>
                            </div>
                            <div
                              style={CSS({
                                overflowY: "auto",
                                maxHeight: "500px",
                                minHeight: "150px",
                                paddingRight: 20,
                              })}
                            >
                              {sessionNow?.session?.cart?.map((item) => (
                                <div key={item?.p?.id}>
                                  <CartItem
                                    removeItem={removeItem}
                                    showCartImages={showCartImages}
                                    setReasonable={setReasonable}
                                    item={item}
                                    currencySymbol={currencySymbol}
                                    incrementQuantity={incrementQuantity}
                                    decrementQuantity={decrementQuantity}
                                    removeItem={removeItem}
                                    isPaymentStage={true}
                                    isPreCheckout={true}
                                    isOnlyProposalItems={sessionNow?.session?.cart?.every((item) => {
                                      if (item.p.instant_payment !== 1 || (item.p.type === "physical" && item.p.stock - item.quantity < 0)) {
                                        if (item.p && item.p.instant_payment !== 1) {
                                          return true;
                                        }
                                      }
                                    })}
                                  />
                                </div>
                              ))}
                            </div>
                            <div></div>
                          </div>
                          <div className="col-12 col-lg-5" id="resume-checkout-container-2">
                            <div id="resume-checkout-2" style={CSS({ position: "relative" })}>
                              {ttlToExpire !== null ? (
                                <div className="card bg-light text-black rounded-3 border-0 shadow-sm mb-3" style={CSS({ borderRadius: 5 })}>
                                  <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                      <h5 className="mb-0 text-muted">
                                        {trans("website.cart-expire-in")} <CartTimer />
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="card bg-light text-black rounded-3 border-0 shadow-sm" style={CSS({ borderRadius: 5 })}>
                                <div className="card-body">
                                  <div className="d-flex justify-content-between align-items-center mb-4">
                                    <h5 className="mb-0 text-muted">{trans("website.dashboard_details")}</h5>
                                  </div>
                                  <hr className="my-4" />
                                  <div className="d-flex justify-content-between">
                                    <p className="mb-2" style={CSS({ fontWeight: 400 })}>
                                      <i className="fa fa-truck text-muted"></i> {trans("website.shipping-cost")}{" "}
                                      {pickUpDay ? `(${new Date(pickUpDay).toLocaleDateString("en-GB")})` : <></>}:
                                    </p>
                                    {shippingCost ? (
                                      <p className="mb-2">
                                        {currencySymbol} {Number(shippingCost).toFixed(2)}
                                      </p>
                                    ) : typeof shippingCost === "number" ? (
                                      <p className="mb-2 text-success">{trans("website.free")}</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <p
                                      className="mb-2"
                                      style={CSS({
                                        fontWeight: 400,
                                        fontSize: 12,
                                      })}
                                    >
                                      {trans("website.total-order")}:{" "}
                                    </p>
                                    <p
                                      className="mb-2"
                                      style={CSS({
                                        fontWeight: 400,
                                        fontSize: 12,
                                      })}
                                    >
                                      {currencySymbol} {Number(Number(total) + Number(shippingCost)).toFixed(2)}
                                    </p>
                                  </div>
                                  {couponDiscount?.value ? (
                                    <div className="d-flex justify-content-between">
                                      <p
                                        className="mb-2"
                                        style={CSS({
                                          fontWeight: 400,
                                          fontSize: 12,
                                        })}
                                      >
                                        <i className="fa fa-percent text-muted"></i> {trans("website.sale-coupon-applied")} {couponDiscount?.text}:{" "}
                                      </p>
                                      <p
                                        className="mb-2 text-success"
                                        style={CSS({
                                          fontWeight: 400,
                                          fontSize: 12,
                                        })}
                                      >
                                        - {currencySymbol} {Number(couponDiscount?.value).toFixed(2)}
                                      </p>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="d-flex justify-content-between mb-4">
                                    <p className="mb-2" style={CSS({ fontWeight: 400 })}>
                                      <i className="fa fa-receipt text-muted"></i> {trans("website.total-payment-now")}:{" "}
                                    </p>
                                    <p className="mb-2 text-success">
                                      {currencySymbol} {Number(Number(payNow) + Number(shippingCost)).toFixed(2)}
                                    </p>
                                  </div>
                                  <button
                                    type="button"
                                    id="finish-cart"
                                    className="btn btn-success btn-block btn-lg d-flex w-100 text-white btn-wait-animated"
                                    onClick={async () => {
                                      if (document.querySelector("#finish-cart").className.includes("animate-wait")) {
                                        return;
                                      }
                                      if (!checkLocalValidations()) {
                                        return;
                                      }
                                      setSpinnerFinish(true);
                                      document.querySelector("#finish-cart").classList.add("animate-wait");
                                      await setOrderSession(billingDetails).then(async () => {
                                        await saveOrder({ ...billingDetails })
                                          .then(() => {
                                            document.querySelector("#finish-cart").classList.remove("animate-wait");
                                            setSpinnerFinish(false);
                                          })
                                          .catch(() => {
                                            document.querySelector("#finish-cart").classList.remove("animate-wait");
                                            setSpinnerFinish(false);
                                          });
                                      });
                                    }}
                                  >
                                    {spinnerFinish ? (
                                      <div className="d-flex justify-content-center m-auto">
                                        <div className="spinner-border m-auto" role="status">
                                          <span className="sr-only">Loading...</span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-between">
                                        <span>
                                          {trans("website.dashboard-sale-confirm-order")}
                                          <i className="fas fa-long-arrow-alt-right ms-2"></i>
                                        </span>
                                      </div>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {tenantConfig.hiddenLogo ? (
                <></>
              ) : (
                <div
                  style={CSS({
                    display: "flex",
                    justifyContent: "end",
                    marginTop: 30,
                  })}
                >
                  <a href="https://ebsss.com" target="_blank" rel="noreferrer">
                    <img src="https://web.iclient.app/logo.png" width="125px" height="auto" />
                  </a>
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

const Stepper = ({ currentStep, steps, onStepClick }) => {
  const { trans } = useEBSSSLayout();

  return (
    <div
      className="col-12 d-flex flex-row px-5 mt-3 py-3 align-items-center justify-content-between shadow-sm"
      style={CSS({ backgroundColor: "#fff", borderRadius: 5 })}
    >
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          <div
            onClick={() => (index === 0 ? onStepClick(index + 1) : {})}
            key={step}
            className={`step-top justify-content-center ${currentStep >= index + 1 ? "active" : ""}`}
          >
            <div className="circle">{index + 1}</div>
            <div className="label">{step}</div>
          </div>
          {index === 0 ? (
            <div
              className="col"
              style={CSS({
                height: 2.5,
                backgroundColor: currentStep === 2 || (currentStep === 3 && index === 0) ? "green" : "gray",
                borderRadius: 25,
                opacity: 0.5,
              })}
            ></div>
          ) : (
            <></>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

function MultiCheckoutPage() {
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionNow?.session?._selected_seller) {
      navigate(process.env.PUBLIC_URL + "/crm/products?page=1");
    }
  }, []);

  return (
    <CartProvider>
      <ShoppingCart />
    </CartProvider>
  );
}

export default MultiCheckoutPage;
