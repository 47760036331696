import React from "react";
import { Layout } from "../store/layoutSlice";
import { useSelector } from "react-redux";
import { ReduxDataKey } from "../store/store";
import { remotePath } from "../settings/Paths";
import { CSS } from "src/_utils/styles";

const EBSSSLoading = () => {
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);

  return ebsssLayout?.loader ? (
    <div dangerouslySetInnerHTML={{ __html: ebsssLayout.loader }}></div>
  ) : (
    <div className="loading-container">
      {ebsssLayout.commonAppSettings.customer_area_logo ? (
        <img
          alt="..."
          className="ebsss-load-breath-effect"
          src={remotePath.assetsUrl(ebsssLayout.commonAppSettings.customer_area_logo)}
          width={100}
          height="auto"
        />
      ) : (
        <div className="ebsss-load-breath-effect skeleton-text" style={{ width: 100, height: 100 }}></div>
      )}
    </div>
  );
};

export default EBSSSLoading;
