import { remotePath } from "src/base/settings/Paths";

export const renderLoading = (ebsssLayout) => {
  const loadingContainer = document.createElement("div");
  loadingContainer.className = "loading-container-child";

  if (ebsssLayout.commonAppSettings.customer_area_logo) {
    const img = document.createElement("img");
    img.alt = "...";
    img.className = "ebsss-load-breath-effect";
    img.src = remotePath.assetsUrl(ebsssLayout.commonAppSettings.customer_area_logo);
    img.width = 100;
    img.loading = "lazy";
    img.style.height = "auto";

    loadingContainer.appendChild(img);
  } else {
    const skeletonDiv = document.createElement("div");
    skeletonDiv.className = "ebsss-load-breath-effect skeleton-text";
    skeletonDiv.style.width = "100px";
    skeletonDiv.style.height = "100px";

    loadingContainer.appendChild(skeletonDiv);
  }

  return loadingContainer;
};
