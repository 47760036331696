//@ts-nocheck
import { Fragment, useEffect } from "react";
import { ShoppingBag, Trash2 } from "react-feather";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Media } from "reactstrap";
import EBSSSItemAvailability from "src/base/atoms/EBSSSItemAvailability";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { remotePath } from "src/base/settings/Paths";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import { H3, H4, H5, Image, LI, P, UL } from "../../../AbstractElements";
import { CheckOut } from "../../../Constant";
import { CartBoxSvg } from "../../../Data/svgIcons";
import { Layout } from "src/base/store/layoutSlice";
import { CSS } from "src/_utils/styles";

const ItemCart = () => {
  const { trans, settings } = useEBSSSLayout();
  const { removeItemFromCart, appSettings } = useEBSSS();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);

  useEffect(() => {
    console.log("sessionNow?.session: ", sessionNow?.session);
  }, []);

  const removeItem = async (e, hash) => {
    e.preventDefault();
    try {
      await removeItemFromCart(hash);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <LI attrLI={{ className: "cart-nav onhover-dropdown" }}>
        <div className="cart-box">
          <i>
            <CartBoxSvg />
          </i>
          <span className="badge rounded-pill badge-primary">{sessionNow?.session?.cart?.length}</span>
        </div>
        <div className={`cart-dropdown onhover-show-div`}>
          <div className="dropdown-title">
            <H3>{trans("website.cart")}</H3>
            <a className="f-right" href="#javascript">
              <ShoppingBag />
            </a>
          </div>
          <UL attrUL={{ className: `simple-list border-top custom-scrollbar`, style: CSS({ height: "auto", maxHeight: 310 }) }}>
            {sessionNow?.session?.cart?.map((item) => {
              return (
                <LI key={item.id}>
                  <Media>
                    {item?.p?.photo ? (
                      <Image
                        attrImage={{
                          src: `${remotePath.assetsUrl(item.p.photo)}`,
                          alt: "",
                          className: "img-fluid b-r-5 me-3 img-50",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    &nbsp;
                    <Media body>
                      <H5 attrH5={{ className: "mb-4" }}>
                        <Link to={`${process.env.PUBLIC_URL}/crm/products/${item.p.slug}`}>{item.p.name}</Link>
                        {item.p && (item.p.instant_payment !== 1 || (item.p.type === "physical" && item.p.stock - item.quantity < 0)) ? (
                          <EBSSSItemAvailability item={item} />
                        ) : (
                          <></>
                        )}
                      </H5>
                      <P attrPara={{ className: "f-w-700" }}>
                        {Number(item.price).toFixed(2)} {ebsssLayout?.commonAppSettings?.currency_symbol}
                      </P>
                      {/* <div className="cart-quantity">
                        <span className="plus">
                          <i className="fa fa-plus"></i>
                        </span>
                        <Input
                          className="count"
                          type="text"
                          name="qty"
                          defaultValue={item.quantity}
                        />
                        <span className="minus">
                          <i className="fa fa-minus"></i>
                        </span>
                      </div> */}
                    </Media>
                    <div className="close-circle">
                      {/* <a href="#javascript">
                        <Edit className="me-1" />
                      </a> */}
                      <a onClick={(e) => removeItem(e, item?.hash)}>
                        <Trash2 />
                      </a>
                    </div>
                  </Media>
                </LI>
              );
            })}
          </UL>
          {sessionNow?.session?.cart?.length > 0 ? (
            <div className="order-total p-3">
              <H4 attrH4={{ className: "mb-0 f-w-700" }}>
                {trans("website.total")} :{" "}
                <span>
                  {sessionNow?.session?.cart?.length > 0
                    ? Number(sessionNow?.session?.cart?.map((i) => Number(i.price ?? 0))?.reduce((a, b) => a + b, 0))?.toFixed(2)
                    : 0}{" "}
                  {ebsssLayout?.commonAppSettings?.currency_symbol}
                </span>
              </H4>
              {sessionNow?.session?.cart?.some((item) => {
                if (item.p.instant_payment !== 1 || (item.p.type === "physical" && item.p.stock - item.quantity < 0)) {
                  if (item.p && item.p.instant_payment !== 1) {
                    return true;
                  }
                }
              }) ? (
                <Link className="btn btn-primary view-checkout" to={`${process.env.PUBLIC_URL}/checkout/pre`}>
                  <i className="la la-shopping-cart"></i>
                </Link>
              ) : sessionNow?.session?.sellers && Object.values(sessionNow?.session?.sellers)?.length > 0 ? (
                <Link
                  className="btn btn-primary view-checkout"
                  to={`${process.env.PUBLIC_URL}/checkout/${!sessionNow?.session?._selected_seller ? "multi" : sessionNow?.session?._selected_seller}`}
                >
                  <i className="la la-shopping-cart"></i>
                </Link>
              ) : (
                <Link className="btn btn-primary view-checkout" to={`${process.env.PUBLIC_URL}/checkout`}>
                  <i className="la la-shopping-cart"></i>
                </Link>
              )}
            </div>
          ) : (
            <div className="order-total p-3">{trans("website.empty-cart")}</div>
          )}
        </div>
      </LI>
    </Fragment>
  );
};
export default ItemCart;
