import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";

const WalletPage = () => {
  const { fetchWallet, wallet, appSettings } = useEBSSS();
  const { trans } = useEBSSSLayout();

  useEffect(() => {
    fetchWallet();
  }, []);

  return (
    <Page
      breads={[
        {
          name: trans("website.historic-wallets"),
          active: true,
          url: "/crm/wallet-historic",
        },
      ]}
    >
      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-header row">
            <div className="content-header-right col-12 mb-2 d-flex align-items-end justify-content-end">
              <h3 className="mb-0 d-inline-block">
                {appSettings?.wlt_allows_charging === 1 && (
                  <a
                    href={`${appSettings?.current_locale}/create/deposit`}
                    className="btn btn-outline-info btn-min-width btn-block mr-1 mb-1"
                  >
                    {trans("common.load_wallet")}
                  </a>
                )}
              </h3>
            </div>
          </div>
          <div className="content-body text-muted">
            <section className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-content">
                    <div className="card">
                      <div className="card-content">
                        <div className="row justify-center d-flex flex-row justify-content-between pb-5">
                          <div className="col border-right-blue-grey border-right-lighten-4 text-center">
                            <div className="card-body text-center d-flex align-items-center justify-content-evenly">
                              <i
                                className="fa fa-chevron-up fs-2 text-muted"
                                text-muted
                              ></i>
                              <h1 className="display-4 text-muted">
                                {wallet?.current_balance_user}
                              </h1>
                            </div>
                            <span className="text-muted">
                              {trans("common.balance")}
                            </span>
                          </div>
                          <div className="col border-right-blue-grey border-right-lighten-4 text-center">
                            <div className="card-body text-center d-flex align-items-center justify-content-evenly">
                              <i className="fa fa-chevron-up fs-2 text-success"></i>
                              <h1 className="display-4 text-muted">
                                {wallet?.in_points} {wallet?.default_currency}
                              </h1>
                            </div>
                            <span className="text-muted">
                              {trans("website.in_wallet")}
                            </span>
                          </div>
                          <div className="col border-right-blue-grey border-right-lighten-4 text-center">
                            <div className="card-body text-center d-flex align-items-center justify-content-evenly">
                              <i className="fa fa-chevron-up fs-2 text-danger"></i>
                              <h1 className="display-4 text-muted">
                                {wallet?.out_points} {wallet?.default_currency}
                              </h1>
                            </div>
                            <span className="text-muted">
                              {trans("website.out_wallet")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      {wallet?.data && wallet?.data?.length > 0 ? (
                        <div className="table-responsive">
                          <table
                            id="invoices-list"
                            className="table table-white-space table-responsive table-bordered row-grouping display no-wrap icheck table-middle"
                            style={{ display: "inline-table" }}
                          >
                            <thead>
                              <tr>
                                <th>{trans("common.id")}</th>
                                <th>
                                  <b>{trans("common.type")}:</b>
                                </th>
                                <th>
                                  <b>{trans("common.date")}:</b>
                                </th>
                                <th>
                                  <b>{trans("common.value")}:</b>
                                </th>
                                <th>
                                  <b>{trans("common.balance")}:</b>
                                </th>
                                <th>
                                  <b>{trans("common.provenance")}:</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {wallet?.data?.map((invoice) => (
                                <tr key={invoice?.id}>
                                  <td>
                                    <span className="badge bg-gray text-muted">
                                      {invoice?.id}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`badge bg-${
                                        invoice?.type_operation === "Entrada"
                                          ? "success"
                                          : "danger"
                                      }`}
                                    >
                                      {invoice?.type_operation}
                                    </span>
                                  </td>
                                  <td>
                                    {new Date(
                                      invoice?.created_at
                                    ).toLocaleString()}
                                  </td>
                                  <td>
                                    {invoice?.value} {invoice?.currency}
                                  </td>
                                  <td>
                                    {invoice?.balance_after_operation}{" "}
                                    {appSettings?.currencySymbol}
                                  </td>
                                  <td>
                                    {invoice?.provenance_type === "gp" &&
                                    invoice?.type_operation === "Entrada" ? (
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/crm/contract-payment/${invoice?.payment_request.token}`}
                                      >
                                        {invoice?.payment_request.id}
                                        {trans("common._gp-in")}
                                      </Link>
                                    ) : invoice?.provenance_type === "award" &&
                                      invoice?.type_operation === "Entrada" ? (
                                      trans("common.award")
                                    ) : invoice?.provenance_type === "" &&
                                      invoice?.type_operation === "Entrada" ? (
                                      trans("common.charged")
                                    ) : invoice?.provenance_type === "gp" &&
                                      invoice?.type_operation === "Saída" &&
                                      invoice?.payment_request &&
                                      invoice?.payment_request.token ? (
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/crm/contract-payment/${invoice?.payment_request.token}`}
                                      >
                                        {invoice?.payment_request.id}
                                        {trans("common._gp-out")}
                                      </Link>
                                    ) : null}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p>{trans("website.historic-wallets-empty")}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default WalletPage;
