import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import HelperHTTP from "src/_utils/http_module_handler";
import { remotePath } from "src/base/settings/Paths";
import { renderSpinner } from "../Renders/renderSpinner";

export const handleFormSubmit = async (
  event: { preventDefault: () => void; target: any },
  target = null,
  el = null,
  ebsssLayout: {
    exp?: number;
    loading?: boolean;
    message?: string;
    menu?: Record<string, any>[];
    countries?: Record<string, any>[];
    languages?: Record<string, any>[];
    activeLanguage: any;
    logo?: string;
    title?: string;
    favicon?: string;
    balance_wallet?: number;
    commonAppSettings?: Record<string, any>;
    styles?: { primary_color: string; secondary_color: string };
    megaMenu?: { linkList: Array<Record<string, any>>; customPages: Array<Record<string, any>>; fixedPages: Array<Record<string, any>> };
    template: any;
  },
  navigate: NavigateFunction
) => {
  if (event) {
    event.preventDefault();
  }
  const form = el ? el : event.target;
  if (form.tagName === "FORM") {
    let checkIfIsAQuery = form.querySelector('[name="query"]');
    let checkIfIsAQueryOnCategory = form.querySelector('[name="filter_category"]');

    if (checkIfIsAQuery) {
      let getNeedle = form.getAttribute("needle");
      let needle = "crm_product_results";

      if (getNeedle && getNeedle.includes("tour")) {
        needle = "tour_product_results";
      }
      if (getNeedle && getNeedle.includes("blog")) {
        needle = "blog_results";
      }
      if (getNeedle && getNeedle.includes("event")) {
        needle = "event_results";
      }
      if (getNeedle && getNeedle.includes("product")) {
        needle = "crm_product_results";
      }

      let getSearchSlug = ebsssLayout.template.pages.find((pg: { type: string }) => pg.type === needle);
      if (checkIfIsAQueryOnCategory) {
        navigate(process.env.PUBLIC_URL + `/${getSearchSlug.slug}?query=${checkIfIsAQuery["value"]}&filter_category=${checkIfIsAQueryOnCategory["value"]}`);
        return;
      }
      navigate(process.env.PUBLIC_URL + `/${getSearchSlug.slug}?query=${checkIfIsAQuery["value"]}`);
      return;
    }

    const action = form.getAttribute("action");
    const method: "post" | "get" | "put" | "patch" = form.getAttribute("method") || "get";
    const inputs = Array.from(form.querySelectorAll("input:not([type='hidden'])"))
      .concat(Array.from(form.querySelectorAll("textarea:not([type='hidden'])")))
      .concat(Array.from(form.querySelectorAll("select")))
      .concat(Array.from(form.querySelectorAll("input[type='checkbox']")))
      .concat(Array.from(form.querySelectorAll("input[type='radio']")));

    const splitUrl = action.replace(remotePath.clientInstanceUrl(), "");

    let formData = {};
    inputs?.forEach((input) => {
      formData[`${input["name"]}`] = input["value"];
    });

    let { spin, removeSpinner } = renderSpinner();
    target.classList.add("d-flex");
    target.classList.add("gap-3");
    target.classList.add("align-items-center");
    target.prepend(spin);

    const { data, errors } = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), "/cms/v2" + splitUrl)
      .withLanguage(ebsssLayout?.activeLanguage)
      .api()
      [method]({
        data: formData,
      });

    console.log(data, errors);

    if (errors) {
      toast(Object.values(errors).join("\n"), { type: "error" });
      removeSpinner();
      target.classList.remove("d-flex");
      target.classList.remove("gap-3");
      target.classList.remove("align-items-center");
      return;
    }

    if (data) {
      removeSpinner();
      target.classList.remove("d-flex");
      target.classList.remove("gap-3");
      target.classList.remove("align-items-center");
      inputs?.forEach((el) => {
        if (["select", "input", "textarea"].includes(el["tagName"]?.toLowerCase())) {
          el["value"] = "";
        }
      });
    }

    toast(data.contact, { type: "success" });
  }
};
