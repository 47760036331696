import { useState } from "react";

const EBSSSToggle = ({ label, toggled, onClick }) => {
  const [isToggled, toggle] = useState(toggled);

  const callback = () => {
    toggle(!isToggled);
    onClick(!isToggled);
  };

  return (
    <label className="ebsss-toggle-label">
      <input
        className="ebsss-toggle-input"
        type="checkbox"
        onClick={callback}
        value={toggled}
        checked={toggled}
      />
      <span className="ebsss-toggle-span" />
      <strong className="ebsss-toggle-strong">{label}</strong>
    </label>
  );
};

export default EBSSSToggle;
