import { PagesEntity } from "src/base/store/layoutSlice";
import CMSRender from "src/Pages/CMS/CMSRender";

const UNALLOWED_TO_ACCESS = ["header", "footer", "menu"];

export const cmsRoutes = (pages: PagesEntity[]) =>
  pages
    ?.filter((pg) => !UNALLOWED_TO_ACCESS.includes(pg.type))
    ?.map((page: PagesEntity) => {
      return {
        path: `${process.env.PUBLIC_URL}/${page.slug}/:slug?`,
        Component: <CMSRender />,
      };
    });
