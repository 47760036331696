import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { remotePath } from "../settings/Paths";
import authReducer from "./authSlice";
import dataReducer from "./dataSlice";
import langsReducer from "./layoutSlice";
import cacheSlice from "./cacheSlice";

export enum ReduxDataKey {
  AUTH = "auth",
  LAYOUT = "langs",
  DATA = "data",
  CACHE = "cache",
}

export enum ReduxDataAuthKey {
  signin = "signin",
  signup = "signup",
  signupToken = "signupToken",
  signupPassword = "signupPassword",
  forgotPassword = "forgotPassword",
  profile = "profile",
  cart = "cart",
}

const rootReducer = combineReducers({
  auth: authReducer,
  data: dataReducer,
  langs: langsReducer,
  cache: cacheSlice,
});

const persistConfig = {
  key: remotePath.localStorageKey(),
  version: 1,
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export default store;
