import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardBody } from "reactstrap";
import { reverseDate } from "src/_utils/elements";
import { Btn } from "src/AbstractElements";
import EBSSSModal from "src/base/atoms/EBSSSModal";
import EBSSSToggle from "src/base/atoms/EBSSSToggle";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { cleanErrorsAndMessages, Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";

const GDPRPage = () => {
  const { handleAllowMarketing, handleDownloadData, handleRequestErasure } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const dispatch = useDispatch();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const [emailToggle, setEmailToggle] = useState(false);
  const [forgetToggle, setForgetToggle] = useState(false);
  const [tempSigned, setTempSigned] = useState(null);

  const handleToggleForgetModal = (p) => {
    setForgetToggle(!p);
  };

  const handleToggleEmail = (p) => {
    setEmailToggle(!p);
    handleAllowMarketing(p ? 1 : 0);
  };

  const handleRequestData = () => {
    handleDownloadData();
  };

  useEffect(() => {
    dispatch(cleanErrorsAndMessages());
    setEmailToggle(!!sessionNow?.user?.profile?.allow_marketing_emails);
    setForgetToggle(!!sessionNow?.user?.profile?.requested_erasure);

    return () => {
      dispatch(cleanErrorsAndMessages());
    };
  }, []);

  useEffect(() => {
    if (sessionNow?.message?.profile) {
      toast(sessionNow?.message?.profile);
    }
  }, [sessionNow.message.profile]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("TEMP_TERM_SIGNED")) {
        setTempSigned(JSON.parse(localStorage.getItem("TEMP_TERM_SIGNED")));
      }
    }
  }, [window]);

  console.log(" - forgetToggle: ", forgetToggle);
  console.log("Signed terms: ", sessionNow?.user?.user_service_term_conditions);
  console.log("Signed recently: ", sessionNow?.user?.newly_accepted_terms);

  return (
    <Page breads={[{ name: trans("profile.top-title-gdpr"), active: false, url: "/gdpr" }]}>
      <Card>
        <CardBody>
          <div className="d-flex flex-column gap-5">
            <div className="d-flex flex-column p-4 shadow-sm rounded-2">
              <h4 className="text-muted">{trans("profile.title-accepted-terms")}</h4>
              <div>
                {(sessionNow?.user?.user_service_term_conditions.every((v) => typeof v === "object") ? sessionNow?.user?.user_service_term_conditions : [])
                  .concat(tempSigned ? tempSigned : [])
                  ?.map((usp) => {
                    return usp.recent ? (
                      <div>
                        <p className="text-muted">
                          {usp?.term?.type === "terms_conditions" ? trans("profile.terms_conditions") : trans("profile.privacy_policy")} -{" "}
                          {trans("profile.version_number")}: {usp?.term?.version_no} - {trans("profile.when")}:{" "}
                          {new Date(usp?.updated_at).toLocaleDateString() + " " + new Date(usp?.updated_at).toLocaleTimeString()}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <p className="text-muted">
                          {usp?.term?.type === "terms_conditions" ? trans("profile.terms_conditions") : trans("profile.privacy_policy")} -{" "}
                          {trans("profile.version_number")}: {usp?.term?.version_no} - {trans("profile.when")}: {reverseDate(usp?.updated_at?.split(" ")[0])}{" "}
                          {usp?.updated_at?.split(" ")[1]}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="d-flex flex-row p-4 shadow-sm rounded-2 align-items-center gap-3">
              <div>
                <EBSSSToggle label={""} toggled={emailToggle} onClick={handleToggleEmail} />
              </div>
              <h4 className="text-muted">{trans("profile.email_marketing")}</h4>
            </div>
            <div className="d-flex flex-row p-4 shadow-sm rounded-2 align-items-center gap-3">
              {!sessionNow?.user?.profile?.requested_erasure_at ? (
                <div className="d-flex flex-row gap-3">
                  <div>
                    <h4 className="text-muted">{trans("profile.forget_solicitation")}</h4>
                    <EBSSSModal
                      modalSize="modal-lg"
                      modalTitle={trans("profile.request_erasure")}
                      modal={forgetToggle}
                      toggle={handleToggleForgetModal}
                      customConfirmAction={() => handleRequestErasure(1)}
                      triggerEl={
                        <Btn
                          attrBtn={{
                            color: "info",
                            onClick: setForgetToggle,
                          }}
                        >
                          {trans("profile.request_erasure")}
                        </Btn>
                      }
                    >
                      <div className="dz-message needsclick text-center ">
                        <p className="text-muted">{trans("profile.erasure_request_text")}</p>
                      </div>
                    </EBSSSModal>
                  </div>
                </div>
              ) : (
                <div>
                  <h4 className="text-muted">{trans("profile.forget_solicitation")}</h4>
                  <p>
                    {trans("profile.requested_erasure")}: {new Date(sessionNow?.user?.profile?.requested_erasure_at).toLocaleDateString()} -{" "}
                    {new Date(sessionNow?.user?.profile?.requested_erasure_at).toLocaleTimeString()}
                  </p>
                  <p className="text-muted">{trans("profile.popup_erasure_requested_text")}</p>
                </div>
              )}
            </div>
            <div className="d-flex flex-column p-4 shadow-sm rounded-2">
              <h4 className="text-muted">{trans("profile.data_transfer")}</h4>
              <div>
                <Btn
                  attrBtn={{
                    color: "info",
                    onClick: handleRequestData,
                  }}
                >
                  {trans("profile.download-personal-data")}
                </Btn>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Page>
  );
};

export default GDPRPage;
