import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { Card, Col, Media, Row } from "reactstrap";
import { scrollUpPage } from "src/_utils/elements";
import { CSS } from "src/_utils/styles";
import { Btn, H6, Image, LI, P, UL } from "src/AbstractElements";
import EBSSSModal from "src/base/atoms/EBSSSModal";
import EBSSSTicket, { PreviewTicketFiles } from "src/base/atoms/EBSSSTicket";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { remotePath } from "src/base/settings/Paths";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import ContactCenterSidebar from "./ContactCenterSidebar";
import EBSSSInitialNameProfile from "src/base/atoms/EBSSSInitialNameProfile";

const MailContain = ({ contact, trans, loaded, typeContact, params, original, userAuth }: any) => {
  const [modal, setModal] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const toggle = () => {
    setModal(!modal);
  };
  const typeFrom =
    typeContact === "ticket"
      ? contact?.order_id && !contact?.reservation_id
        ? "order"
        : contact?.reservation_id
        ? "reservation"
        : contact?.lead_id
        ? "lead"
        : contact?.contract_id
        ? "contract"
        : "project"
      : "chat";

  console.log(typeFrom, typeContact === "ticket" ? contact[typeFrom + "_id"] : null);

  return (
    <Fragment>
      <div className="email-right-aside">
        <Card className="email-body">
          <div className="email-profile">
            <div className="email-right-aside">
              <div className="email-body">
                <div className="email-content">
                  <div className="email-top">
                    <Row>
                      <Col xl="12">
                        <Media>
                          {contact?.user?.profile?.avatar ? (
                            <Image
                              attrImage={{
                                width: "50px",
                                height: "100%",
                                className: "me-3 rounded-circle",
                                src: `${
                                  contact?.user?.profile?.avatar
                                    ? remotePath.assetsUrl(contact?.user?.profile?.avatar)
                                    : "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"
                                }`,
                                alt: "",
                              }}
                            />
                          ) : (
                            <div className="me-3">
                              <EBSSSInitialNameProfile user={contact?.user} width={50} height={50} />
                            </div>
                          )}
                          <Media body>
                            <H6 attrH6={{ className: "d-block" }}>{contact?.user?.profile?.first_name + " " + contact?.user?.profile?.last_name}</H6>
                            <P>{contact?.created_at}</P>
                          </Media>
                          <Link
                            className="d-flex"
                            to={
                              contact?.project_id
                                ? process.env.PUBLIC_URL + "/crm/projects/" + contact.project_token
                                : contact?.lead_id
                                ? process.env.PUBLIC_URL + "/forms/replied/" + contact?.form_lead_reference
                                : contact?.contract_id
                                ? process.env.PUBLIC_URL + "/crm/contracts/" + contact.contract_token
                                : contact?.order_id && !contact?.reservation_id
                                ? process.env.PUBLIC_URL + "/crm/orders/" + contact.order_token
                                : process.env.PUBLIC_URL + "/entity-reservations/" + contact.reservation_token
                            }
                          >
                            <Btn
                              attrBtn={{
                                color: "primary",
                                className: "info me-1",
                              }}
                            >
                              <i className="la la-binoculars me-1"></i>+
                            </Btn>
                          </Link>

                          <EBSSSModal
                            hideSubmitButton
                            modalTitle={trans("website.original")}
                            modal={modal}
                            toggle={toggle}
                            triggerEl={
                              <Btn attrBtn={{ onClick: toggle, color: "light" }}>
                                <i className="la la-history"></i>
                              </Btn>
                            }
                          >
                            <div
                              style={CSS({
                                maxHeight: 400,
                                overflowX: "hidden",
                                overflowY: "auto",
                              })}
                            >
                              <VerticalTimeline layout={"1-column"}>
                                {contact?.original?.map((olds, oldsIdx, arr) => {
                                  return (
                                    <VerticalTimelineElement
                                      key={olds.id}
                                      className="vertical-timeline-element--work"
                                      animate={true}
                                      date={
                                        olds.created_at === contact.created_at ? (
                                          <small className="text-success">{trans("website.this-ticket")}</small>
                                        ) : oldsIdx === 0 ? (
                                          <small className="text-warning">{trans("website.latest")}</small>
                                        ) : oldsIdx === arr.length - 1 ? (
                                          <small className="text-secondary">{trans("website.first")}</small>
                                        ) : (
                                          ""
                                        )
                                      }
                                      icon={
                                        <div className="d-flex justify-content-center align-items-center">
                                          <i className="mt-1 las la-user m-auto fs-3"></i>
                                        </div>
                                      }
                                    >
                                      <div className="email-body">
                                        <div className="email-content">
                                          <div className="email-top">
                                            <Row>
                                              <Col xl="12">
                                                <Media>
                                                  {olds?.user?.profile?.avatar ? (
                                                    <Image
                                                      attrImage={{
                                                        width: "50px",
                                                        height: "100%",
                                                        className: "me-3 rounded-circle",
                                                        src: `${
                                                          olds?.user?.profile?.avatar
                                                            ? remotePath.assetsUrl(olds?.user?.profile?.avatar)
                                                            : "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"
                                                        }`,
                                                        alt: "",
                                                      }}
                                                    />
                                                  ) : (
                                                    <div className="me-3">
                                                      <EBSSSInitialNameProfile user={olds?.user} width={50} height={50} />
                                                    </div>
                                                  )}
                                                  <Media body>
                                                    <H6
                                                      attrH6={{
                                                        className: "d-block",
                                                      }}
                                                    >
                                                      <span className={`me-2 badge ${olds?.user?.user_roles[0]?.role_id < 2 ? "bg-danger" : "bg-secondary"}`}>
                                                        {olds?.user?.user_roles[0]?.role_id < 2
                                                          ? remotePath.instance.slice(0, 1).toUpperCase() + remotePath.instance.slice(1)
                                                          : trans("website.user")}
                                                      </span>
                                                      {olds?.user?.profile?.first_name + " " + olds?.user?.profile?.last_name}
                                                    </H6>
                                                    <P>{olds?.created_at}</P>
                                                  </Media>
                                                </Media>
                                              </Col>
                                            </Row>
                                          </div>
                                          <div className="email-wrapper">
                                            <div className="emailread-group">
                                              <div className="read-group">
                                                <P>{olds?.message}</P>
                                              </div>
                                            </div>
                                            {olds?.files?.length > 0 ? (
                                              <div className="emailread-group mt-2">
                                                <H6
                                                  attrH6={{
                                                    className: "text-muted mb-0 small",
                                                  }}
                                                >
                                                  <i className="icofont icofont-clip"></i> {trans("website.attachments")}
                                                </H6>
                                                <div className="attachment">
                                                  <PreviewTicketFiles outModal fromTickets={olds?.files} />
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </VerticalTimelineElement>
                                  );
                                })}
                              </VerticalTimeline>
                            </div>
                          </EBSSSModal>
                        </Media>
                      </Col>
                    </Row>
                  </div>
                  <div className="email-wrapper">
                    <div className="emailread-group">
                      <div className="read-group">
                        <P>{contact?.message}</P>
                      </div>
                    </div>
                    {contact?.files?.length > 0 ? (
                      <div className="emailread-group">
                        <H6 attrH6={{ className: "text-muted mb-0" }}>
                          <i className="icofont icofont-clip"></i> ATTACHMENTS
                        </H6>
                        <a className="text-muted text-end right-download font-primary f-w-600" href="#javascript">
                          <i className="fa fa-long-arrow-down me-2"></i>
                          Download All
                        </a>
                        <div className="clearfix"></div>

                        <div className="attachment">
                          <PreviewTicketFiles outModal fromTickets={contact.files} />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="emailread-group">
                      <textarea className="form-control d-none" rows={4} cols={50} placeholder="write about your nots"></textarea>
                      <div className="action-wrapper d-none">
                        <UL
                          attrUL={{
                            className: "simple-list actions flex-row",
                          }}
                        >
                          <LI>
                            <a className="btn btn-primary" href="#javascript">
                              <i className="fa fa-reply me-2"></i>Reply
                            </a>
                          </LI>
                          <LI>
                            <a className="btn btn-light" href="#javascript">
                              <i className="fa fa-reply-all me-2"></i>Reply All
                            </a>
                          </LI>
                          <LI>
                            <a className="btn btn-danger" href="#javascript">
                              <i className="fa fa-share me-2"></i>Forward
                            </a>
                          </LI>
                        </UL>
                      </div>
                      <EBSSSTicket
                        ownTicket={contact?.user_id === userAuth?.id}
                        contactCenter={true}
                        data={[]}
                        dataType={typeFrom}
                        answerTo={contact?.user_id}
                        dataId={typeContact === "ticket" ? contact[typeFrom + "_id"] : 0}
                        secondaryDataId={typeFrom === "reservation" ? params?.parent_entity : null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

const ContactCenterMessagePage = () => {
  const { contact, loaded, appSettings, fetchContactMade } = useEBSSS();
  const getContactCenterMessage = useParams();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { trans } = useEBSSSLayout();
  const [filters, setFilters] = useState("ALL");

  useEffect(() => {
    scrollUpPage();
    fetchContactMade(getContactCenterMessage?.type as "chat" | "ticket", getContactCenterMessage?.token);
  }, []);

  return (
    <Page
      breads={[
        {
          name: trans("website.contacts"),
          active: false,
          url: "/crm/contacts/center",
        },
        {
          name: trans("website.contact"),
          active: true,
        },
      ]}
    >
      <div className="email-wrap">
        <Row>
          <Col xl="3" md="6" className="xl-30 box-col-3">
            <ContactCenterSidebar trans={trans} filters={filters} setFilters={setFilters} contacts={{}} appSettings={appSettings} />
          </Col>
          <Col xl="9" md="12" className="xl-70 box-col-9">
            {loaded ? (
              <MailContain
                userAuth={sessionNow?.user}
                contact={contact ?? {}}
                trans={trans}
                loaded={loaded}
                typeContact={getContactCenterMessage?.type}
                params={getContactCenterMessage}
              />
            ) : (
              <Card className="email-body">
                <Row className="p-0 d-flex flex-column flex-lg-row">
                  <Col>
                    <div className="p-3 w-100 d-flex" style={CSS({ height: "100%" })}>
                      <div className="w-100 my-2 shadow-2-strong">
                        <div className="d-flex flex-column">
                          <h5 className="mb-1 me-1 fw-bold mb-2 skeleton"></h5>
                          <div className="d-flex flex-row mb-3 skeleton" style={CSS({ height: 40, width: "50%" })}>
                            <h5 className="mb-1 me-1"></h5>
                            <span className="text-danger">
                              <s></s>
                            </span>
                          </div>
                          <p className="card-text skeleton" style={CSS({ height: 70 })}></p>
                        </div>
                        <div className="d-flex flex-column">
                          <h5 className="mb-1 mt-3 me-1 fw-bold mb-2 skeleton"></h5>
                          <div className="d-flex flex-row mb-3 skeleton" style={CSS({ height: 75 })}>
                            <h5 className="mb-1 me-1"></h5>
                            <span className="text-danger">
                              <s></s>
                            </span>
                          </div>
                          <p className="card-text skeleton align-self-end" style={CSS({ height: 70, width: "50%" })}></p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </Page>
  );
};

export default ContactCenterMessagePage;
