import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";

const TermsConditionPage = ({ startOn }: { startOn?: number }) => {
  const { TOS, fetchTOS } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);

  useEffect(() => {
    fetchTOS();
  }, []);

  let checkTOS = sessionNow?.user?.user_service_term_conditions?.filter(
    (uspTOS) =>
      typeof uspTOS === "object" &&
      uspTOS?.term &&
      uspTOS?.term?.type === "terms_conditions"
  );
  let checkLastTOSAssign = checkTOS[checkTOS?.length - 1];

  return (
    <Page
      breads={[{ name: trans("website.menu-terms-conditions"), active: true }]}
    >
      <div className="d-flex flex-column">
        <div className="d-flex flex-column p-3">
          <span className="">{trans("website.menu-terms-conditions")}</span>
          <span className="">{TOS?.terms?.published_at}</span>
          {sessionNow?.user?.id ? (
            <span className="">
              {trans("website.title-terms-conditions-accepted-at")}{" "}
              {typeof checkLastTOSAssign === "object"
                ? checkLastTOSAssign?.updated_at
                : new Date().toLocaleDateString()}
            </span>
          ) : (
            <></>
          )}
        </div>
        <div
          className="p-3 ebsss-terms-points"
          dangerouslySetInnerHTML={{
            __html: TOS?.termTranslations?.content,
          }}
        ></div>
      </div>
    </Page>
  );
};

export default TermsConditionPage;
