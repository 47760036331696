import { createSlice } from "@reduxjs/toolkit";
import HelperHTTP from "src/_utils/http_module_handler";
import { remotePath } from "../settings/Paths";
import { parseSettingsToBooleanValues } from "src/_utils/validations";
import { menuLayout } from "../constants/layout";

export interface Template {
  id: number;
  template_id: number;
  name: string;
  slug: string;
  category: string;
  initial_page: string;
  lock_user?: null;
  locked_at?: null;
  subdomain: string;
  external_domain: string;
  created_at: string;
  updated_at: string;
  deleted_at?: null;
  white_watermark: number;
  bottom_html?: null;
  company_email?: null;
  company_phone?: null;
  company_whatsapp?: null;
  company_twitter?: null;
  company_linkedin?: null;
  company_youtube?: null;
  company_facebook?: null;
  company_instagram?: null;
  languages: string;
  crm_products?: null;
  crm_categories: string;
  tour_products?: null;
  tour_categories: string;
  crm_attributes: string;
  preview: string;
  page_speed?: null;
  template: TemplateSelected;
  redirects?: null[] | null;
  pages?: PagesEntity[] | null;
}
export interface TemplateSelected {
  id: number;
  name: string;
  slug: string;
  template_dir: string;
  template_zip: string;
  created_at: string;
  updated_at: string;
  deleted_at?: null;
}
export interface PagesEntity {
  id: number;
  website_id: number;
  name: string;
  slug: string;
  html: string;
  created_at: string;
  updated_at: string;
  deleted_at?: null;
  title?: null;
  description?: null;
  keywords?: null;
  type: string;
  order_no: number;
  per_page?: number | null;
  company_category_id?: null;
  seller_edit: number;
  contents?: string | null;
  html_content: string;
}

export type Layout = {
  exp: number;
  loading: boolean;
  message: string;
  menu: Array<Record<string, any>>;
  countries: Array<Record<string, any>>;
  languages: Array<Record<string, any>>;
  activeLanguage: string;
  logo: string;
  title: string;
  favicon: string;
  balance_wallet: number;
  loader: string;
  commonAppSettings: Record<string, any>;
  styles: {
    primary_color: string;
    secondary_color: string;
  };
  megaMenu: {
    linkList: Array<Record<string, any>>;
    customPages: Array<Record<string, any>>;
    fixedPages: Array<Record<string, any>>;
  };
  template: Template;
};

const initialState: Layout = {
  exp: 0,
  loading: false,
  message: "",
  menu: [],
  countries: [],
  languages: [],
  activeLanguage: "pt",
  logo: "",
  title: "",
  favicon: "",
  balance_wallet: 0,
  loader: "",
  commonAppSettings: {},
  styles: {
    primary_color: "",
    secondary_color: "",
  },
  megaMenu: {
    linkList: [],
    customPages: [],
    fixedPages: [],
  },
  template: {} as Template,
};

export const fetchLangs = ({ activeLanguage = "pt", token = "" }: { activeLanguage: string; token: string }) => {
  return async (dispatch: (arg0: { payload: any; type: "langs/langsRequest" | "langs/langsSuccess" | "langs/langsFailure" | "langs/endLoading" }) => void) => {
    dispatch(langsRequest());
    try {
      const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.LAYOUT)
        .authed({ tk: token })
        .appendQuery({
          code: activeLanguage,
        })
        .api()
        .get();

      console.log("Res Translate Fetch: ", fetch);

      const pageableGridRoutes = ["crm/products"];

      let menuIcons = fetch?.data?.menu?.map((m) => {
        return {
          ...m,
          path: pageableGridRoutes.some((route) => m.path.includes(route))
            ? `${process.env.PUBLIC_URL}${remotePath.replaceMenuURL(m.path)}?page=1`
            : `${process.env.PUBLIC_URL}${remotePath.replaceMenuURL(m.path)}`,

          Items: m.Items.map((mI) => {
            return { ...mI };
          }),
        };
      });
      if (fetch.errors) {
        dispatch(langsFailure({ ...fetch, menu: menuIcons }));
        return;
      }
      dispatch(
        langsSuccess({
          ...fetch.data,
          menu: menuIcons,
          activeLanguage,
        })
      );
    } catch (err: any) {
      dispatch(langsFailure(err));
    } finally {
      dispatch(endLoading());
    }
  };
};

export const layoutSlice = createSlice({
  name: "langs",
  initialState,
  reducers: {
    endLoading(state: Layout) {
      state.loading = false;
    },
    langsRequest(state: Layout) {
      state.loading = true;
      state.message = "";
    },
    cacheLoader(state: Layout, action: Record<string, any>) {
      state.loader = action.payload.loader;
    },
    langsSuccess(state: Layout, action: Record<string, any>) {
      state.exp = new Date().getTime() + 600000;
      state.message = "";
      state.logo = action.payload.logo;
      state.title = action.payload.title;
      state.favicon = action.payload.favicon;
      state.countries = action.payload.countries;
      state.languages = action.payload.languages;
      state.activeLanguage = action.payload.activeLanguage;
      state.balance_wallet = action.payload.balance_wallet;
      state.template = action.payload.template;
      state.loader = action.payload.loader;
      state.commonAppSettings = parseSettingsToBooleanValues(action.payload.settings);
      state.megaMenu = {
        linkList: action.payload.link_list,
        customPages: action.payload.custom_pages,
        fixedPages: action.payload.fixed_pages,
      };
      state.styles = action.payload.styles;
      localStorage.setItem("default_color", action.payload.styles.primary_color);
      localStorage.setItem("secondary_color", action.payload.styles.secondary_color);
      localStorage.setItem("sidebar_types", menuLayout[action.payload.styles.menu]);
      state.menu = action.payload.menu;
      let allLangGroups = action.payload.translates;
      Object.keys(allLangGroups).forEach((groupLangKey) => {
        state[`${groupLangKey}`] = allLangGroups[groupLangKey];
      });
    },
    langsFailure(state: Layout, action: Record<string, any>) {
      state.message = action.payload.message ?? "";
    },
  },
});

export const { endLoading, langsRequest, langsSuccess, langsFailure, cacheLoader } = layoutSlice.actions;

export default layoutSlice.reducer;
