import { ConfigPaths } from "./IPaths";

let buildMode = "PRO";
let configPaths: ConfigPaths = {} as ConfigPaths;

window["BUILD_MODE"] = buildMode;

let REGISTERED_DEV_INSTANCE_VARS = {
  micaela: {
    instance: "micaela",
    domain: "micaela.3.ebsss.dev",
  },
  micaelaweb: {
    instance: "micaela",
    domain: "web.micaela.3.ebsss.dev",
  },
  micaelapaulo: {
    instance: "micaela",
    domain: "micaela.ebsss.dev",
  },
  micaelademo: {
    instance: "demo",
    domain: "micaela.demo.ebsss.info",
  },
  pontadelgada: {
    instance: "demo",
    domain: "pontadelgada.demo.ebsss.info",
  },
  marketingdigital: {
    instance: "marketingdigital",
    domain: "marketingdigital.demo.ebsss.info",
  },
  turismo: {
    instance: "turismo",
    domain: "turismo.demo.ebsss.info",
  },
  funerarias: {
    instance: "demo",
    domain: "funerarias.demo.ebsss.info",
  },
  micaela2: {
    instance: "micaela",
    domain: "micaela.2.ebsss.dev",
  },

  // \/ Register's alias: marketingdigital, micaela, webmicaela, turismo, donuts, dont need to be exactly the same as the uuid instance, its just an identifier.
  // 'marketingdigital': {
  //  \/ Instance's uuid
  //   instance: 'marketingdigital',
  //  \/ Website's domain
  //   domain: "marketingdigital.demo.ebsss.info"
  // }
};

if (buildMode === "DEV") {
  configPaths = {
    ...configPaths,
    //Set the alias here between the brackets, and automatically will take the correct registered vars:
    ...REGISTERED_DEV_INSTANCE_VARS["micaelademo"],
  };
}
if (buildMode === "PRO") {
  configPaths = {
    ...configPaths,
    ...window["MAIN_INSTANCE_PATHS"],
  };
}

configPaths = {
  ...configPaths,
  prefixAssets: "/files",
  endPoints: {
    EXCEPTIONS: "/crm/v2/exceptions",
    HTML_PAGE: "/cms/v2/html/page",
    FULL_DASHBOARD: "/crm/v2/template/dashboard/full",
    FORMS: "/crm/v2/template/form",
    FORMS_SAVE(param) {
      return this.FORMS + "/" + param + "/save";
    },
    FORMS_REPLIED: "/crm/v2/template/replied-forms",
    FORMS_REPLIED_ANSWERS: "/crm/v2/template/lead/getAnswers",
    FORMS_REPLIED_VIEW: "/crm/v2/template/replied-forms/view",
    CONTACTS: "/crm/v2/template/contacts/all",
    CONTACTS_SEARCH: "/crm/v2/template/contacts/search",
    CONTACT: "/crm/v2/template/contacts/find",
    TICKET_ANSWER: "/crm/v2/template/ticket/answer",
    CUSTOM_PAGE: "/crm/v2/template/page",
    BOOKMARKS: "/crm/v2/template/favorites",
    BOOKMARKS_DELETE: "/crm/v2/template/favorites/remove",
    BOOKMARKS_ADD: "/crm/v2/template/favorites/product",
    FAQ_HELP_TOPICS: "/crm/v2/template/faq/help-topics",
    TOS: "/crm/v2/template/terms-conditions",
    POS: "/crm/v2/template/privacy-policy",
    ACCEPT_TERMS: "/crm/v2/template/accept-terms",
    NOTIFICATIONS: "/crm/v2/template/notifications",
    NOTIFICATIONS_DELETE_ALL: "/crm/v2/template/notifications/deleteAll",
    NOTIFICATIONS_DELETE(param) {
      return this.NOTIFICATIONS + "/" + param + "/delete";
    },
    DOWNLOAD_DATA: "/auth/v2/user/gdpr/download_data",
    REQUEST_ERASURE: "/auth/v2/user/gdpr/request_erasure",
    ALLOW_MARKETING: "/auth/v2/user/gdpr/allow_marketing",
    CHECK_EMAIL: "/auth/v2/signup/email_check",
    LOGIN: "/auth/v2/signin",
    REGISTER: "/auth/v2/signup",
    REGISTER_TOKEN: "/auth/v2/signup/token",
    REGISTER_PASSWORD: "/auth/v2/signup/password",
    FORGOT_PASSWORD_VERIFY: "/auth/v2/signin/forgot_verify",
    FORGOT_PASSWORD_MODIFY: "/auth/v2/signin/forgot_modify",
    UPDATE_PROFILE: "/auth/v2/user/profile",
    UPDATE_AVATAR: "/auth/v2/user/avatar",
    UPDATE_PASS: "/auth/v2/user/password",
    ORDER: "/crm/v2/template/orders",
    ORDERS: "/crm/v2/template/orders",
    PRODUCT: "/crm/v2/template/products",
    PRODUCTS: "/crm/v2/template/products",
    PRODUCTS_SEARCH: "/crm/v2/template/products/search",
    PRODUCT_VARIATION: "/crm/v2/template/products/variation",
    PRODUCTS_CATEGORY: "/crm/v2/template/products/category",
    LAYOUT: "/crm/v2/template/layout",
    WALLET: "/crm/v2/template/wallet",
    REWARD_WALLET: "/crm/v2/template/reward_wallet",
    RESERVATION: "/crm/v2/template/entity-reservations",
    RESERVATIONS: "/crm/v2/template/entity-reservations",
    CONTRACT_PAYMENTS: "/crm/v2/template/contract-payments",
    CONTRACT_PAYMENT: "/crm/v2/template/contract-payments",
    CONTRACT_PAYMENT_PROVE: "/crm/v2/template/contract-payments/public/prove",
    ATTACH_CONTRACT_PAYMENTS: "/crm/v2/template/contract-payments/create-master-payment-request",
    ONE_CHECKOUT: "/crm/v2/template/onecheckout",
    ONE_CHECKOUT_CART: "/crm/v2/template/onecheckout/cart",
    ONE_CHECKOUT_CART_ADD: "/crm/v2/template/onecheckout/add",
    ONE_CHECKOUT_CART_REMOVE: "/crm/v2/template/onecheckout/remove_item",
    ONE_CHECKOUT_ORDER_SESSION: "/crm/v2/template/onecheckout/set_order_session",
    ONE_CHECKOUT_ORDER_SAVE: "/crm/v2/template/onecheckout/save",
    ONE_CHECKOUT_SHIPPING_VALIDATION: "/crm/v2/template/onecheckout/validate",
    ONE_CHECKOUT_MUNICIPALITIES_LIST: "/crm/v2/template/lists/municipalities",
    ONE_CHECKOUT_PARISHES_LIST: "/crm/v2/template/lists/civil_parishes",
    ONE_CHECKOUT_COUPON: "/crm/v2/template/onecheckout/coupon",
    ONE_CHECKOUT_PRODUCT_PROPOSAL: "/crm/v2/template/onecheckout/proposal",
    PROPOSALS: "/crm/v2/template/proposals",
    PROPOSAL: "/crm/v2/template/proposals",
    PROPOSAL_ACCEPT: "/crm/v2/template/proposals/accept",
    PROPOSAL_DECLINE: "/crm/v2/template/proposals/decline",
    PROPOSAL_REPLY: "/crm/v2/template/proposals/reply",
    PROJECTS: "/crm/v2/template/projects",
    PROJECT: "/crm/v2/template/projects",
    EVENTS: "/crm/v2/template/events",
    EVENT: "/crm/v2/template/events",
    CONTRACTS: "/crm/v2/template/contracts",
    CONTRACT: "/crm/v2/template/contracts",
    SELLER: "/crm/v2/template/seller",
    CHECK_DATES_ENTITY_AVAILABILITY: "/crm/v2/template/products/check_dates",
    SIBS_STATUS: (param) => `/crm/v2/template/contract-payments/public/sibs/${param}/status`,
    SIBS_CLEAR: (param) => `/crm/v2/template/contract-payments/public/sibs/${param}/clear`,
  },
  paymentGateways(code, overrideProMode = false) {
    if (code === "sibs") {
      return buildMode === "DEV" || overrideProMode ? "https://spg.qly.site1.sibs.pt" : "https://api.sibspayments.com";
    }

    return "";
  },
  localStorageKey() {
    return this.domain.toUpperCase() + "_AUTH_KEY";
  },
  clientInstanceUrl() {
    return `https://${this.domain}`;
  },
  replaceMenuURL(url: string, activeLanguage = "pt") {
    return url.replace(this.clientInstanceUrl() + `/${activeLanguage}`, "");
  },
  assetsUrl(url?: string) {
    let needSlashStart = "";
    if (url && url[0] !== "/") {
      needSlashStart = "/";
    }
    return url ? this.clientInstanceUrl() + this.prefixAssets + needSlashStart + url : this.clientInstanceUrl() + this.prefixAssets;
  },
  staticsUrl(dinamic: string) {
    return "https://chatwidget.3.ebsss.dev/crmv2_images/" + dinamic;
  },
};

export const remotePath = configPaths;
