import React, { CSSProperties } from "react";

export const CSS = (stylesObj: CSSProperties) => Object.assign({}, stylesObj);
export const parseHTML = (
  htmlString,
  returnElementsInContainerDiv = false,
  returnBodied = false
): any => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const div = document.createElement("div");

  if (returnElementsInContainerDiv) {
    doc.body.childNodes.forEach((nd) => {
      div.appendChild(nd);
    });

    return div;
  }

  div.appendChild(doc.body);
  console.log(div);

  return returnBodied ? div : doc?.body?.textContent ?? "";
};

export const parseHTMLToReact = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Recursive function to convert DOM nodes to React elements
  const convertNodeToReactElement = (node) => {
    // If it's a text node, return the text content
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent;
    }

    // Get the tag name
    const tagName = node.tagName.toLowerCase();

    // Get the attributes and convert them to React props
    const attributes = {};
    Array.from(node.attributes).forEach((attr) => {
      attributes[attr["name"]] = attr["value"];
    });

    // Convert child nodes recursively
    const children = Array.from(node.childNodes).map(convertNodeToReactElement);

    // Return React element
    return React.createElement(tagName, attributes, ...children);
  };

  // Start conversion from the body of the parsed document
  return Array.from(doc.body.childNodes).map(convertNodeToReactElement);
};
