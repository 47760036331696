//@ts-nocheck
import { useState } from "react";
import { Key, MapPin, User } from "react-feather";
import { Card, CardBody, CardHeader, Col, Collapse } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { Btn, H5, LI, P, UL } from "src/AbstractElements";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";

const EBSSSProfileOptions = ({ user, selectedSection, setSelectedSection }) => {
  const { trans } = useEBSSSLayout();
  const [aboutme, setisAboutme] = useState(true);
  const toggleClick = () => {
    setisAboutme(!aboutme);
  };

  const AboutMeData = [
    {
      id: "user",
      icon: <User color={selectedSection === "user" ? "#8786ED" : "#a9a9a9"} />,
      designation: trans("common.user"),
      place: trans("common.your_main_data"),
    },
    {
      id: "address",
      icon: (
        <MapPin color={selectedSection === "address" ? "#8786ED" : "#a9a9a9"} />
      ),
      designation: trans("common.address"),
      place: trans("common.your_main_address"),
    },
    {
      id: "password",
      icon: (
        <Key color={selectedSection === "password" ? "#8786ED" : "#a9a9a9"} />
      ),
      designation: trans("common.password"),
      place: trans("common.your_security_data"),
    },
  ];

  return (
    <div style={{ position: "sticky", top: 50 }}>
      <Col xl="12">
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: "p-0" }}>
              <Btn
                attrBtn={{
                  className: "btn btn-link",
                  databstoggle: "collapse",
                  databstarget: "#collapseicon4",
                  ariaexpanded: "true",
                  ariacontrols: "collapseicon4",
                  color: "transperant",
                  onClick: toggleClick,
                }}
              >
                Informações
              </Btn>
            </H5>
          </CardHeader>
          <Collapse isOpen={aboutme}>
            <CardBody className="post-about">
              <UL attrUL={{ as: "ul", className: "simple-list" }}>
                {AboutMeData.map((item) => (
                  <LI
                    attrLI={{
                      onClick: () => setSelectedSection(item.id),
                      style: CSS({ cursor: "pointer" }),
                    }}
                    key={item.id}
                  >
                    <div className="icon">{item.icon}</div>
                    <div>
                      <H5>{item.designation}</H5>
                      <P>{item.place}</P>
                    </div>
                  </LI>
                ))}
              </UL>
            </CardBody>
          </Collapse>
        </Card>
      </Col>
    </div>
  );
};
export default EBSSSProfileOptions;
