import { useEBSSSLayout } from "../context/EBSSSLayoutContext/useEBSSSLayout";

const EBSSSItemAvailability = ({ item }) => {
  const { trans } = useEBSSSLayout();
  return (
    <div>
      {/* Check for instant payment */}
      {item.p && item.p.instant_payment !== 1 && (
        <small>
          <i className="ft ft-alert-triangle"></i>{" "}
          {trans("website.require-request-proposal-text")}
        </small>
      )}

      {/* Check if it's a physical product and stock is less than quantity */}
      {item.p &&
        item.p.type === "physical" &&
        item.p.stock - item.quantity < 0 && (
          <small>
            <i className="ft ft-alert-triangle"></i>{" "}
            {trans("website.out-of-stock-product")}
          </small>
        )}
    </div>
  );
};

export default EBSSSItemAvailability;
