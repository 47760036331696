import { printContent } from "src/_utils/elements";

const EBSSSPrintButton = () => {
  return (
    <div className="ms-2 no-print-ebsss">
      <button className="btn btn-light btn-xs fs-6" onClick={printContent}>
        <i className="fa fa-print"></i>
      </button>
    </div>
  );
};

export default EBSSSPrintButton;
