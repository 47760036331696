import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: {
    data: [],
    errors: null,
    message: "",
    loading: false,
  },
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    endLoading(state: any, action: any) {
      state = {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          loading: false,
        },
      };
    },
    dataRequest(state: any, action: any) {
      state = {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          loading: true,
          errors: [],
          message: "",
        },
      };
    },
    dataSuccess(state: any, action: any) {
      state = {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          data: action.payload.data,
          errors: [],
          exp: new Date().getTime() + 10800000,
        },
      };
    },
    dataFailure(state: any, action: any) {
      state = {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          errors: action.payload.errors ?? {},
          message: action.payload.message ?? "",
        },
      };
    },
    clean(state: any) {
      state = {};
    },
  },
});

export const { endLoading, dataRequest, dataSuccess, dataFailure, clean } =
  dataSlice.actions;

export default dataSlice.reducer;
