import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import { ReactI18NextChild } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { CSS, parseHTML } from "src/_utils/styles";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import parse from "html-react-parser";

const FAQPage = () => {
  const { fetchFAQ, faqHelpTopics } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const getOrder = useParams();
  const location = useLocation();
  const [IsOpen, setIsOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [filterFAQ, setFilterFAQ] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchFAQ();
  }, []);

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.querySelector("#faq-response").addEventListener("click", (e) => {
        const target = e.target as HTMLElement;
        if (target.tagName === "A") {
          e.preventDefault();
          let path = target.getAttribute("href");
          console.log(path);
          if (path && (!path.startsWith("#") || path.startsWith("http"))) {
            navigate(process.env.PUBLIC_URL + path);
          }
        }
      });
    }
  }, []);

  return (
    <Page breads={[{ name: trans("website.faq"), active: false, url: "/faq" }]}>
      <Card>
        <CardBody>
          <div className="d-flex flex-md-row flex-column">
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <div className="mb-3" style={CSS({ height: 75 })}>
                  <label className="form-label fs-7" htmlFor="query-faq">
                    <i className="fa fa-magnifying-glass text-muted"></i> <small className="text-muted">{trans("website.faq-search")}</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="query-faq"
                    onChange={(e) => {
                      setSelectedArticle(null);
                      setFilterFAQ(e.target.value);
                    }}
                  />
                </div>
                <div
                  className="d-flex flex-column gap-3"
                  style={CSS({
                    maxHeight: 600,
                    overflowX: "hidden",
                    overflowY: "auto",
                  })}
                >
                  {faqHelpTopics?.help_topics?.map(
                    (ht: {
                      title:
                        | string
                        | number
                        | boolean
                        | ReactElement<any, string | JSXElementConstructor<any>>
                        | Iterable<ReactNode>
                        | ReactPortal
                        | Iterable<ReactI18NextChild>;
                      articles: any[];
                    }) => {
                      return (
                        <div className="shadow-sm">
                          <h4 className="text-muted lead">{ht.title}</h4>
                          <div className="d-flex flex-column ">
                            {ht?.articles
                              ?.filter((hta) => {
                                return hta?.title?.toLowerCase()?.includes(filterFAQ?.toLowerCase());
                              })
                              ?.map((article) => {
                                return (
                                  <h5
                                    style={CSS({
                                      cursor: "pointer",
                                      color: article.slug === selectedArticle?.slug ? "#6362E7" : "",
                                    })}
                                    className={`ms-2 p-2 text-muted`}
                                    onClick={() => {
                                      setSelectedArticle({
                                        slug: article.slug,
                                        html: parse(article.description),
                                      });
                                      document?.getElementById("faq-content")?.scrollIntoView({
                                        behavior: "smooth",
                                      });
                                    }}
                                  >
                                    {article.title}
                                  </h5>
                                );
                              })}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-3">
              <div className="d-flex flex-column" id="faq-content">
                <div className="mb-3" style={CSS({ height: 75 })}></div>
              </div>
              <h4 className="text-muted" style={CSS({ fontWeight: 300 })}>
                {trans("website.faq_answer")}:
              </h4>
              <Card className="text-muted" id="faq-response">
                <CardBody>{selectedArticle?.html ? selectedArticle?.html : <></>}</CardBody>
              </Card>
            </div>
          </div>
        </CardBody>
      </Card>
    </Page>
  );
};

export default FAQPage;
