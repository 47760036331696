import { useEffect, useState } from "react";
import { PhoneInput } from "react-international-phone";
import { useDispatch, useSelector } from "react-redux";
import { CSS, parseHTML } from "src/_utils/styles";
import { getCountryCode } from "src/_utils/validations";
import { Btn } from "src/AbstractElements";
import EBSSSItemAvailability from "src/base/atoms/EBSSSItemAvailability";
import EBSSSModal from "src/base/atoms/EBSSSModal";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { remotePath } from "src/base/settings/Paths";
import { cartSuccess, Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import { emitSwal } from "./CheckoutPage";

const formatNumber = (number) => Number(number).toFixed(2);

const getDuration = (validate) => {
  let matches = validate.match(/\d+/g);
  let number = matches.join("");
  let freq = validate.replace(number, "").trim();
  return freq;
};
const PriceDetail = ({ item, currencySymbol }) => {
  const { trans } = useEBSSSLayout();

  return (
    <div className="price-detail">
      {item?.rent_end_at && item?.rent_start_at ? (
        <div className="d-flex flex-column mb-2">
          <span style={CSS({ fontSize: 14 })}>
            <i className="fas fa-arrow-down text-success"></i> {trans("website.rent-start-at")}: <b>{item?.rent_start_at.substring(0, 16)}</b>
          </span>
          <span style={CSS({ fontSize: 14 })}>
            <i className="fas fa-arrow-up text-danger"></i> {trans("website.rent-end-at")}: <b>{item?.rent_end_at.substring(0, 16)}</b>
          </span>
        </div>
      ) : (
        <></>
      )}

      {item?.rent_people && item?.rent_people > 1 ? (
        <div className="d-flex flex-column mb-2">
          {item?.rent_object?.participants && Array.isArray(item?.rent_object.participants) && item?.rent_object.participants.length > 0 ? (
            item?.rent_object.participants.map((participant, index) => (
              <span key={index} style={CSS({ fontSize: 14 })}>
                <i className="fas fa-user text-secondary"></i> {participant.ageRangeParentName}: <b>{participant.quantity}</b>
              </span>
            ))
          ) : (
            <span style={CSS({ fontSize: 14 })}>
              <i className="fas fa-user text-secondary"></i> {trans("website.rent-people-count")}: <b>{item?.rent_people}</b>
            </span>
          )}
        </div>
      ) : (
        <></>
      )}

      {item?.extras &&
        item?.extras.map((extra, index) => (
          <div style={CSS({ fontSize: 14 })} key={index} className="d-flex flex-column">
            <div className="d-flex flex-row">
              <span>
                <i className="fas fa-plus text-primary"></i> {extra.title}
                :&nbsp;
              </span>
              {Number(extra.cost) ? (
                <div className="d-flex flex-row">
                  <span>
                    <strong>&nbsp;{extra.price_to_show}&nbsp;</strong>
                  </span>{" "}
                  {extra.charge_per_quantity ? (
                    <span className="h-100 badge rounded-pill bg-light text-dark" style={CSS({ border: "0.5px solid green" })}>
                      {trans("website.entity-price-per-" + item?.p?.rental_type)}
                    </span>
                  ) : (
                    ""
                  )}
                  &nbsp;
                  {extra.charge_per_person ? (
                    <span className="h-100 badge rounded-pill bg-light text-dark" style={CSS({ border: "0.5px solid green" })}>
                      {trans("website.entity-extra-price-per-person")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <span className="h-100 badge bg-success rounded-pill text-dark" style={CSS({ border: "0.5px solid green" })}>
                  {trans("website.free")}
                </span>
              )}
            </div>
          </div>
        ))}

      {item?.installation_fee && item?.installation_fee > 0 ? (
        <div style={CSS({ fontSize: 14 })} className="d-flex">
          <span>
            <i className="fas fa-plus text-primary"></i> {trans("website.installation_fee")}:&nbsp;
          </span>
          <span>
            <strong>
              {currencySymbol}&nbsp;{item?.installation_fee}&nbsp;
            </strong>
          </span>
        </div>
      ) : (
        <></>
      )}

      {item?.p?.contract_term_id ? (
        <div style={CSS({ fontSize: 14 })} className="d-flex">
          <a
            target="_blank"
            href={window.location.origin + "/crm/product/contract-term/" + item?.p?.token}
            className="link-primary text-decoration-none"
            rel="noreferrer"
          >
            <i className="fa fa-file"></i> {trans("website.see_contract")}
          </a>
        </div>
      ) : (
        <></>
      )}

      {item?.contract_validate ? (
        <div style={CSS({ fontSize: 14 })} className="d-flex">
          <span>
            <i className="fas fa-plus text-primary"></i> {trans("website.contract-vigency")}:&nbsp;
          </span>
          <span>
            <strong>
              &nbsp;
              {trans("website.calendar." + getDuration(item?.contract_validate))}
              &nbsp;
            </strong>
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export const CartItem = ({
  item,
  fetchCartState,
  incrementQuantity,
  decrementQuantity,
  removeItem,
  currencySymbol,
  isPaymentStage,
  setReasonable,
  showCartImages,
  paymentRequestTypeTrans,
  activeLang,
  isPreCheckout = false,
  isOnlyProposalItems = false,
}) => {
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { sendProductProposal } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const dispatch = useDispatch();
  const [formDataProposal, setFormDataProposal] = useState({
    proposal_name: "",
    proposal_email: "",
    proposal_mobile_number: "",
    proposal_user_request: "",
    proposal_product: "",
    proposal_index: "",
    proposal_indexes: [],
  });
  const [loadingSubmitProposal, setLoadingSubmitProposal] = useState(false);
  const [modal, setModal] = useState("");
  const toggle = (id) => {
    setModal(id);
  };

  const handleInputChange = (event, v = null, k = null) => {
    setFormDataProposal({
      ...formDataProposal,
      [k ? k : event.target.name]: v ? v : event.target.value,
    });
  };

  useEffect(() => {
    if (isPreCheckout && item) {
      let initialObjFormData = {
        proposal_name: sessionNow?.user?.id ? sessionNow?.user?.profile?.first_name + " " + sessionNow?.user?.profile?.last_name : "",
        proposal_email: sessionNow?.user?.id ? sessionNow?.user?.email : "",
        proposal_mobile_number: sessionNow?.user?.id ? sessionNow?.user?.mobile_number : "",
        proposal_user_request: "",
        proposal_product: "",
        proposal_index: "",
        proposal_indexes: [],
      };

      setFormDataProposal(initialObjFormData);
    }
  }, [isPreCheckout, item]);

  return (
    <div className="card mb-3 border-0 shadow-sm">
      <div className="card-body">
        <div className="d-flex flex-md-row flex-column justify-content-between">
          <div className="d-flex flex-row align-items-center">
            {showCartImages ? (
              item?.p?.photo ? (
                <div>
                  <img
                    src={item?.p?.photo ? remotePath.assetsUrl(item?.p?.photo) : ""}
                    className="img-fluid rounded-3"
                    alt="Shopping item"
                    style={CSS({ width: "65px" })}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center" style={CSS({ width: 65, color: "#a9a9a9" })}>
                  <i style={CSS({ fontSize: 20 })} className="fas fa-box"></i>
                </div>
              )
            ) : (
              <></>
            )}
            <div className="text-start ms-3" style={CSS({ maxWidth: "100%" })}>
              <h6 style={CSS({ lineHeight: 1.5 })}>
                {item?.payment_type && (
                  <>
                    <small className="badge bg-warning" style={CSS({ marginRight: 5 })}>
                      {item?.payment_type?.payment_type?.payment_modality === "recorrent" ? (
                        <>
                          {item?.unit_price}&nbsp;{currencySymbol} /{" "}
                          <>
                            {paymentRequestTypeTrans && activeLang !== "PT"
                              ? paymentRequestTypeTrans[activeLang]?.name
                              : item?.payment_type?.payment_type?.name}
                          </>
                          {trans("website.sales-plus-fees-taxes")}
                        </>
                      ) : (
                        <>
                          {paymentRequestTypeTrans && activeLang !== "PT" ? paymentRequestTypeTrans[activeLang]?.name : item?.payment_type?.payment_type?.name}
                        </>
                      )}
                    </small>

                    {item?.payment_type?.payment_type?.discount > 0 && (
                      <div className="badge badge-info round m-1 pull-right" style={CSS({ marginRight: 5 })}>
                        {item?.payment_type?.payment_type?.discount < 1 ? (
                          <>{item?.payment_type?.payment_type?.discount * 100}%</>
                        ) : (
                          <>
                            - {item?.payment_type?.payment_type?.discount}&nbsp;
                            {currencySymbol}
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
                {item?.p?.name}
              </h6>
              <p className="small mb-0 text-muted"></p>
              <PriceDetail item={item} currencySymbol={currencySymbol} />
              {isPreCheckout && !isOnlyProposalItems ? (
                <div className="w-75">
                  {item.p && (item.p.instant_payment !== 1 || (item.p.type === "physical" && item.p.stock - item.quantity < 0)) ? (
                    <>
                      <EBSSSItemAvailability item={item} />
                      <div>
                        <EBSSSModal
                          asyncToggle
                          loadingSubmitKey={loadingSubmitProposal}
                          customConfirmAction={async () => {
                            setLoadingSubmitProposal(true);
                            await sendProductProposal({
                              ...formDataProposal,
                              proposal_product: item?.p?.token,
                              proposal_index: item?.hash,
                              proposal_indexes: [],
                            })
                              .then((d) => {
                                let proposalModalSuccess = {
                                  type: "s",
                                  message: trans("website.proposal-requested"),
                                  configSwal: {},
                                };
                                proposalModalSuccess.configSwal["buttons"] = {
                                  confirm: {
                                    text: "OK",
                                    className: "bg-success",
                                    closeModal: true,
                                  },
                                };
                                proposalModalSuccess.configSwal["callback"] = async () => {
                                  dispatch(cartSuccess(d));
                                  return;
                                };

                                emitSwal({
                                  ...proposalModalSuccess,
                                });
                                return;
                              })
                              .catch(() => {
                                setLoadingSubmitProposal(false);
                              })
                              .finally(() => {
                                setLoadingSubmitProposal(false);
                              });
                          }}
                          modalTitle={trans("website.request-proposal-modal")}
                          modal={modal === item?.p?.id}
                          toggle={() => toggle("")}
                          triggerEl={
                            <Btn
                              attrBtn={{
                                onClick: () => toggle(item?.p?.id),
                                color: "light",
                                className: "mt-2 btn btn-sm text-primary",
                              }}
                            >
                              <span>{trans("website.request-proposal-modal")}</span>
                            </Btn>
                          }
                        >
                          <div>
                            {parseHTML(trans("website.request-proposal-text"))}
                            {!sessionNow?.user?.id ? (
                              <>
                                <hr />
                                <p>{trans("website.request-proposal-form-title")}</p>
                                <div className="mb-3">
                                  <label className="form-label" htmlFor="proposal_name">
                                    {trans("common.name")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="proposal_name"
                                    name="proposal_name"
                                    autoComplete="off"
                                    spellCheck="false"
                                    maxLength={255}
                                    value={formDataProposal.proposal_name}
                                    onChange={handleInputChange}
                                  />
                                  <div id={`invalid-proposal_name-feedback-validation`}></div>
                                </div>
                                <div className="mb-3">
                                  <label className="form-label" htmlFor="proposal_email">
                                    {trans("common.email")}
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="proposal_email"
                                    name="proposal_email"
                                    autoComplete="off"
                                    spellCheck="false"
                                    maxLength={255}
                                    value={formDataProposal.proposal_email}
                                    onChange={handleInputChange}
                                  />
                                  <div id={`invalid-proposal_email-feedback-validation`}></div>
                                </div>
                                <div className="mb-3">
                                  <label className="form-label" htmlFor="proposal_mobile_number">
                                    {trans("common.mobile_number")}
                                  </label>
                                  <PhoneInput
                                    inputStyle={CSS({
                                      width: "100%",
                                      display: "flex",
                                      borderEndEndRadius: 5,
                                      borderStartEndRadius: 5,
                                    })}
                                    countrySelectorStyleProps={{
                                      dropdownStyleProps: {
                                        style: CSS({
                                          zIndex: 999999,
                                        }),
                                      },
                                      buttonStyle: CSS({
                                        backgroundColor: "#EFEFFD",
                                        border: 0,
                                      }),
                                    }}
                                    required
                                    name="proposal_mobile_number"
                                    defaultCountry={getCountryCode()}
                                    value={formDataProposal.proposal_mobile_number}
                                    onChange={(v) => handleInputChange(null, v.replace("+", ""), "proposal_mobile_number")}
                                  />

                                  <div id={`invalid-proposal_mobile_number-feedback-validation`}></div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <textarea
                              placeholder={trans("website.request-proposal-user-requirements")}
                              value={formDataProposal.proposal_user_request}
                              name="proposal_user_request"
                              onChange={handleInputChange}
                              className="form-control"
                              rows={3}
                              cols={3}
                            ></textarea>
                          </div>
                        </EBSSSModal>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <hr className="d-block d-md-none" />
          <div className="d-flex flex-row align-items-center justify-content-end">
            <div className="d-flex gap-1" style={CSS({ width: "50px" })}>
              {isPaymentStage ? (
                <></>
              ) : (
                <a href="#!" style={CSS({ color: "blue", opacity: 0.75, display: "none" })} onClick={() => incrementQuantity(item?.p?.id)}>
                  <i className="fas fa-chevron-up"></i>
                </a>
              )}
              <small className="fw-normal mb-0 text-center">{item?.quantity}x</small>
              {isPaymentStage ? (
                <></>
              ) : (
                <a href="#!" style={CSS({ color: "red", opacity: 0.5, display: "none" })} onClick={() => decrementQuantity(item?.p?.id)}>
                  <i className="fas fa-chevron-down"></i>
                </a>
              )}
            </div>
            <div style={CSS({ width: "80px" })}>
              <h5 style={CSS({ fontSize: 14 })} className="mb-0">
                {Number(item?.price).toFixed(2)} {currencySymbol}
              </h5>
              <small style={CSS({ fontSize: 10 })} className="text-muted">
                {Number(item?.price / item?.quantity).toFixed(2)} {currencySymbol} un.
              </small>
            </div>
            {isPaymentStage ? (
              <></>
            ) : (
              <a href="#!" style={CSS({ color: "#cecece" })} onClick={async (e) => removeItem(e, item?.hash)}>
                <i className="fas fa-trash-alt"></i>
              </a>
            )}
          </div>
        </div>
        <div className="flex-col mt-3 text-muted text-end text-md-start">
          {item.p.contract_id ? (
            <div className="mb-3">
              <div>
                {item.payment_type && item.payment_type.paymentType && (
                  <small>
                    {item.payment_type.paymentType.name}: {currencySymbol}
                    {item.unit_price}
                  </small>
                )}
              </div>
              <div>
                <small>
                  {trans("website.sales-payment-now")}: {currencySymbol}
                  {item.price}
                </small>
              </div>
              <div>
                <small>{item.p.contract_validate_translated}</small>
              </div>
            </div>
          ) : (
            <></>
          )}

          {item?.tax_object || item?.fee_object ? (
            <>
              {item?.fee_object?.show_on_cart && item?.fee_amount > 0 ? (
                <p className="text-muted" style={CSS({ fontSize: 12 })}>
                  {formatNumber(item?.fee_amount)} {currencySymbol} (
                  {activeLang && item?.fee_object[activeLang.toUpperCase()] && item?.fee_object[activeLang]?.title
                    ? item?.fee_object[activeLang]?.title
                    : item?.fee_object.title}
                  )
                </p>
              ) : (
                <></>
              )}

              {item?.tax_object?.show_on_cart && item?.tax_amount > 0 ? (
                <p className="text-muted" style={CSS({ fontSize: 12 })}>
                  + {formatNumber(item?.tax_amount)} {currencySymbol} (
                  {activeLang && item?.tax_object[activeLang.toUpperCase()] && item?.tax_object[activeLang]?.title
                    ? item?.tax_object[activeLang]?.title
                    : item?.tax_object.title}
                  )
                </p>
              ) : (
                <></>
              )}

              {item?.fee_object?.show_on_cart && item?.fee_included > 0 ? (
                <p className="text-muted" style={CSS({ fontSize: 12 })}>
                  {trans("website.cart-fee-included")} {formatNumber(item?.fee_included)} {currencySymbol}{" "}
                  {activeLang && item?.fee_object[activeLang.toUpperCase()] && item?.fee_object[activeLang]?.title
                    ? item?.fee_object[activeLang]?.title
                    : item?.fee_object.title}
                </p>
              ) : (
                <></>
              )}

              {item?.tax_object?.show_on_cart && item?.tax_included > 0 ? (
                <p className="text-muted" style={CSS({ fontSize: 12 })}>
                  {trans("website.cart-tax-included")} {formatNumber(item?.tax_included)} {currencySymbol}{" "}
                  {activeLang && item?.tax_object[activeLang.toUpperCase()] && item?.tax_object[activeLang]?.title
                    ? item?.tax_object[activeLang]?.title
                    : item?.tax_object.title}
                </p>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
