import { Fragment, useContext, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AnimationThemeContext from "../_helper/AnimationTheme";
import { default as CheckContext, default as CustomContext } from "../_helper/customizer";
import ConfigDB from "../Config/Theme-Config";
import Footer from "./Footer/index";
import Header from "./Header";
import SideBarLayout from "./SideBar-Layout";
import TapTop from "./TapTop/index";
import Themecustomizer from "./ThemeCustomizer";
import { Session } from "src/base/store/authSlice";
import { useSelector } from "react-redux";
import { ReduxDataKey } from "src/base/store/store";

const Layout = ({ hideLayout, children, classNames, ...rest }) => {
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);

  const { sidebar_types, settings, toggleIcon, setToggleIcon, defaultClass, setDefaultClass } = useContext<any>(CheckContext);
  const { setIsVertical } = useContext<any>(CustomContext);
  const settings1 = localStorage.getItem("sidebar_Settings") || settings;
  const sidebar_types1 = localStorage.getItem("sidebar_types") || sidebar_types;
  const location = useLocation();
  const { animation } = useContext<any>(AnimationThemeContext);
  const animationTheme = localStorage.getItem("animation") || animation || ConfigDB.data.router_animation;
  const nodeRef = useRef(null);
  window.addEventListener("resize", () => {
    if (window.innerWidth - 440 <= 575) {
      setToggleIcon(true);
    } else {
      setToggleIcon(false);
    }
    if (window.innerWidth <= 1200) {
      setIsVertical(true);
    } else {
      setIsVertical(false);
    }
    if (window.innerWidth <= 992) {
      setDefaultClass(true);
    } else setDefaultClass(false);
  });

  const error = console.error;
  console.error = (...args) => {
    if (/defaultProps/.test(args[0])) return;
    error(...args);
  };

  const strictLayout = window.location.href.includes("v2/checkout") || window.location.href.includes("v2/answer");

  return hideLayout ? (
    <Fragment>
      <div className="page-body no-margin-print-ebsss" ref={nodeRef}>
        <TransitionGroup {...rest}>
          <CSSTransition key={location.key} timeout={100} classNames={animationTheme} nodeRef={nodeRef} unmountOnExit>
            <div>
              <Outlet />
            </div>
          </CSSTransition>
        </TransitionGroup>
        <ToastContainer className="no-print-ebsss" />
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <TapTop />
      <div className={`page-wrapper ${!defaultClass ? sidebar_types1 : "compact-wrapper"} ${settings1}`} id="pageWrapper">
        <div
          className={`page-header ${toggleIcon ? "close_icon" : ""} no-print-ebsss ${
            strictLayout || !sessionNow?.user?.id ? "ebsss-hide-margin-left-unauthed-top" : ""
          }`}
        >
          <Header strictLayout={strictLayout} />
        </div>
        <div className={`page-body-wrapper ${!sessionNow?.user?.id ? "mt-5" : ""}`}>
          {strictLayout || !sessionNow?.user?.id ? (
            <></>
          ) : (
            <div className={`sidebar-wrapper ${toggleIcon ? "close_icon" : ""} no-print-ebsss`}>
              <div>
                <SideBarLayout />
              </div>
            </div>
          )}
          <div
            className={`page-body no-margin-print-ebsss ${strictLayout || !sessionNow?.user?.id ? "ml-0" : ""}`}
            style={strictLayout || !sessionNow?.user?.id ? { marginLeft: 0 } : {}}
            ref={nodeRef}
          >
            <TransitionGroup {...rest}>
              <CSSTransition key={location.key} timeout={100} classNames={animationTheme} nodeRef={nodeRef} unmountOnExit>
                <div>
                  <Outlet />
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
          <Footer strictLayout={strictLayout || !sessionNow?.user?.id} />
        </div>
      </div>
      {/* <Themecustomizer /> */}
      <ToastContainer className="no-print-ebsss" />
    </Fragment>
  );
};
export default Layout;
