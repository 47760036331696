import { ApexOptions } from "apexcharts";
import { Fragment, useEffect, useState } from "react";
import { default as ApexChart, default as ReactApexChart } from "react-apexcharts";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Card, CardBody, CardHeader, Col, Media, Row, Table } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { H1, H3, H4, H5, H6, Image, P } from "src/AbstractElements";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { remotePath } from "src/base/settings/Paths";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import TooltipToggle from "src/base/TooltipToggle";
import dashImg from "../../assets/images/images.svg";
import { ProposalItems, ProposalStatus, ProposalTotal } from "../Proposals/ProposalsPage";
import EBSSSInitialNameProfile from "src/base/atoms/EBSSSInitialNameProfile";

let primaryColor = "#6362e7";
let secondaryColor = "#ADFA44";

const getReversedMonthsForOrders = (
  trans: any,
  orders: {
    month_of_year: number;
    year: number;
    orders: number;
    total_spent: string;
  }[]
): string[] => {
  const monthsOfYear = [
    trans("website.january"),
    trans("website.february"),
    trans("website.march"),
    trans("website.april"),
    trans("website.may"),
    trans("website.june"),
    trans("website.july"),
    trans("website.august"),
    trans("website.september"),
    trans("website.october"),
    trans("website.november"),
    trans("website.december"),
  ];

  const currentMonth = new Date().getMonth(); // Get the current month (0 = January, 11 = December)

  const reversedMonths = [];

  // Loop through orders and assign the months in reverse order
  for (let i = 0; i < orders.length; i++) {
    const monthIndex = (currentMonth - i + 12) % 12; // Calculate the month index in reverse, wrapping around if necessary
    reversedMonths.push(monthsOfYear[monthIndex] + " " + orders[i].year); // Push the correct month name from monthsOfYear
  }

  return reversedMonths;
};

const getReversedDaysForOrders = (trans: any, orders: { day: number; orders: number; total_spent: number; index: number }[]): string[] => {
  const daysOfWeek = [
    trans("website.sunday"),
    trans("website.monday"),
    trans("website.tuesday"),
    trans("website.wednesday"),
    trans("website.thursday"),
    trans("website.friday"),
    trans("website.saturday"),
  ];
  const today = new Date().getDay();

  const reversedDays = [];

  for (let i = 0; i < orders.length; i++) {
    const dayIndex = (today - i + 7) % 7;
    reversedDays.push(daysOfWeek[dayIndex] + " " + "(" + (i === 0 ? trans("website.today") : i + " " + trans("website.days_ago")) + ")");
  }

  return reversedDays;
};

let chartOrdersOptions: (trans: any, typeNames: any, orders: any) => ApexOptions = (trans, typeNames, orders) => {
  return {
    chart: {
      height: 145,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: [primaryColor, secondaryColor],
    stroke: {
      curve: "smooth",
      colors: [primaryColor, secondaryColor],
      lineCap: "round",
      width: 3,
    },
    fill: {
      type: "gradient",
      opacity: 0.1,
      gradient: {
        type: "vertical",
        opacityFrom: 0.65,
        opacityTo: 0.3,
        stops: [0, 100],
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      categories:
        typeNames === "weekdays"
          ? getReversedDaysForOrders(
              trans,
              orders.map((o) => {
                return { ...o, day: o.day - 1 };
              })
            )
          : getReversedMonthsForOrders(
              trans,
              orders.map((o) => {
                return { ...o, month_of_year: o.month_of_year - 1 };
              })
            ),
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
      padding: {
        left: -10,
        right: 0,
        bottom: 0,
        top: -35,
      },
    },
    responsive: [
      {
        breakpoint: 1600,
        options: {
          chart: {
            height: 105,
          },
        },
      },
    ],
  };
};

const chartWalletsOptions: ApexOptions = {
  chart: {
    height: 142,
    type: "line",
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      left: 10,
      blur: 6,
      color: "#000",
      opacity: 0.35,
    },
  },
  colors: ["#fff"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  tooltip: {
    enabled: false,
  },
  grid: {
    yaxis: {
      lines: {
        show: false,
      },
    },
    padding: {
      left: -10,
      right: 0,
      bottom: -30,
      top: -30,
    },
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
};

function fillMissingWeekdays(data) {
  const result = [];
  const currentDate = new Date();
  const existingDays = new Set(data.map((item) => item.day));

  for (let i = 6; i >= 0; i--) {
    const date = new Date();
    date.setDate(currentDate.getDate() - i);

    const dayOfWeek = date.getDay();

    if (existingDays.has(dayOfWeek)) {
      const existingDayData = data.find((item) => item.day === dayOfWeek);
      result.push({ index: 6 - i, ...existingDayData });
    } else {
      result.push({
        index: 6 - i,
        day: dayOfWeek,
        orders: 0,
        total_spent: "0.00",
      });
    }
  }

  return result.reverse();
}

function fillMissingTwelveMonths(data) {
  const result = [];
  const monthsInYear = 12;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const existingMonths = new Set(data.map((item) => `${item.year}-${item.month_of_year}`));

  for (let i = 11; i >= 0; i--) {
    let month = currentMonth - i;
    let year = currentYear;

    if (month <= 0) {
      month += monthsInYear;
      year -= 1;
    }

    const monthKey = `${year}-${month}`;

    // Now we can use existingMonths.has() like existingDays.has()
    if (existingMonths.has(monthKey)) {
      const existingMonthData = data.find((item) => item.year === year && item.month_of_year === month);
      result.push({ index: 11 - i, ...existingMonthData });
    } else {
      result.push({
        index: 11 - i,
        month_of_year: month,
        year: year,
        orders: 0,
        total_spent: "0.00",
      });
    }
  }

  return result;
}

const handleIfHasChildrenRoute = (item) => {
  if (item.reservation_id) {
    return "/" + item.order_id;
  }

  return "";
};

const formatDate = (dt, showSeconds = false) => {
  return (
    new Date(dt).toLocaleDateString() +
    " " +
    new Date(dt)
      .toLocaleTimeString()
      .split(":")
      .slice(0, showSeconds ? 3 : 2)
      .join(":")
  );
};

function secondsSince(dateString) {
  const inputDate = new Date(dateString).getTime();
  const currentDate = new Date().getTime();
  const differenceInMillis = currentDate - inputDate;
  const seconds = Math.floor(differenceInMillis / 1000);
  return Math.abs(seconds);
}

function timeAgo(date) {
  const seconds = secondsSince(date);

  console.log(seconds);

  if (seconds > 10 && seconds < 60) {
    return seconds + "s";
  }

  if (seconds > 60 && seconds < 3600) {
    return Math.floor(seconds / 60) + "m";
  }

  if (seconds > 3600 && seconds < 86400) {
    return Math.floor(seconds / 3600) + "h";
  }

  if (seconds > 86400 && seconds < 604800) {
    return Math.floor(seconds / 86400) + "d";
  }

  if (seconds > 604800 && seconds < 2628000) {
    return Math.floor(seconds / 604800) + "w";
  }

  if (seconds > 2628000 && seconds < 31536000) {
    return Math.floor(seconds / 2628000) + "m";
  }

  if (seconds > 31536000) {
    return Math.floor(seconds / 31536000) + "y";
  }

  return "just now";
}

const getDifferenceTime = (start, end) => {
  const YEAR_DAYS = 365;

  let ending = new Date(end).getTime();
  let now = new Date().getTime();

  let daysLasting = Math.ceil((ending - now) / 1000 / 3600 / 24);

  return 100 - Math.ceil(daysLasting / (YEAR_DAYS / 100));
};

function fillMissingMonths(array) {
  const monthsOfYear = Array.from({ length: 12 }, (_, i) => i);

  const filledArray = monthsOfYear.map((month) => {
    const existingMonth = array.find((entry) => entry.month_of_year === month);
    return existingMonth || { month_of_year: month, data: [] };
  });

  return filledArray;
}

function groupByMonth(dataArray) {
  const groupedData = {};

  dataArray?.forEach((item) => {
    const date = new Date(item.created_at);
    const month = date.getMonth() + 1;

    if (!groupedData[month]) {
      groupedData[month] = {
        month_of_year: month,
        data: [],
      };
    }

    groupedData[month].data.push(item);
  });

  return Object.values(groupedData);
}

const NextReservation = ({ reservation, trans }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Fragment>
      <Col xl="5" md="6" className="dash-lgorder-1 dash-xl-50 dash-39 box-col-6">
        <Card className="special-discount">
          <CardBody>
            <Slider {...settings}>
              <Fragment>
                <div className="item">
                  <Row className="discount-block">
                    <Col xl="7" lg="12" className="dash-xl-100 box-col-12">
                      <div className="discount-detail">
                        <div>
                          <H5>
                            {trans("website.booked_in")}
                            <span className="font-primary f-w-500"> {reservation?.created_at}</span>
                          </H5>
                          <div className="d-flex flex-row gap-2 align-items-center">
                            <H3 attrH3={{ className: "text-muted" }}>
                              {trans("website.last_reservation")}
                              {":"}
                            </H3>
                            <span className="h3 font-primary">{reservation?.reference}</span>
                          </div>
                          <Link
                            to={`${process.env.PUBLIC_URL}` + "/entity-reservations/" + reservation?.token}
                            className="btn btn-outline-primary shadow-sm mt-4"
                          >
                            {trans("website.see_reservation")}
                          </Link>
                        </div>
                      </div>
                    </Col>
                    <Col xl="5" lg="12" className="dash-xl-100 box-col-12">
                      <div className="img-wrraper">
                        <Image
                          attrImage={{
                            className: "img-fluid rounded-circle pe-2",
                            src: `${
                              reservation?.order_item?.product?.photo
                                ? remotePath.assetsUrl(reservation?.order_item?.product?.photo)
                                : remotePath.staticsUrl("calendarplaceholder.png")
                            }`,
                            alt: "",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Fragment>
            </Slider>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

const Wallet = ({ wallet, trans }) => {
  const formattedChartData: {
    series: ApexAxisChartSeries | ApexNonAxisChartSeries;
    options: ApexOptions;
  } = {
    series: [
      {
        name: trans("website.wallet_balance"),
        data: fillMissingMonths(groupByMonth(wallet?.data)).map((montly) => {
          return montly?.data?.length > 0 ? montly?.data?.pop()?.balance_after_operation : 0;
        }),
      },
    ],
    options: {
      ...chartWalletsOptions,
    },
  };
  return (
    <Fragment>
      <Col xl="3" md="6" className="dash-xl-33 dash-lg-50 box-col-6">
        <Card className="sales-state">
          <Row className="m-0">
            <Col xs="12" className="p-0">
              <Card className="bg-primary">
                <CardHeader className="card-no-border bg-primary">
                  <Media className="media-dashboard">
                    <Media body>
                      <H5 attrH5={{ className: "mb-0 text-light" }}>{trans("website.wallet")}</H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <ReactApexChart options={formattedChartData.options} series={formattedChartData.series} height="142" type="line" id="sales-state-chart" />
                </CardBody>
              </Card>
            </Col>
            <Fragment>
              <Col xs="12" className="p-0">
                <div className="sales-small-chart">
                  <CardBody className="p-0 m-auto">
                    <H6 attrH6={{ className: "text-muted" }}>{wallet?.current_balance_user}</H6>
                    <span>{trans("website.current_balance_user")}</span>
                  </CardBody>
                </div>
              </Col>
            </Fragment>
          </Row>
        </Card>
      </Col>
    </Fragment>
  );
};

const WalletPoints = ({ wallet, trans }) => {
  const formattedChartData: {
    series: ApexAxisChartSeries | ApexNonAxisChartSeries;
    options: ApexOptions;
  } = {
    series: [
      {
        name: trans("website.wallet_points"),
        data: fillMissingMonths(groupByMonth(wallet?.data)).map((montly) => {
          return montly?.data?.length > 0 ? montly?.data?.pop()?.balance_after_operation : 0;
        }),
      },
    ],
    options: {
      ...chartWalletsOptions,
    },
  };
  return (
    <Fragment>
      <Col xl="3" md="6" className="dash-xl-33 dash-lg-50 box-col-6">
        <Card className="sales-state">
          <Row className="m-0">
            <Col xs="12" className="p-0">
              <Card className="bg-primary">
                <CardHeader className="card-no-border bg-primary">
                  <Media className="media-dashboard">
                    <Media body>
                      <H5 attrH5={{ className: "mb-0 text-light" }}>{trans("website.wallet_points")}</H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <ReactApexChart options={formattedChartData.options} series={formattedChartData.series} height="142" type="line" id="sales-state-chart" />
                </CardBody>
              </Card>
            </Col>
            <Fragment>
              <Col xs="6" className="p-0">
                <div className="sales-small-chart">
                  <CardBody className="p-0 m-auto">
                    <H6 attrH6={{ className: "text-muted" }}>{wallet?.in_points}</H6>
                    <span>{trans("website.in_points")}</span>
                  </CardBody>
                </div>
              </Col>
              <Fragment>
                <Col xs="6" className="p-0">
                  <div className="sales-small-chart">
                    <CardBody className="p-0 m-auto">
                      <H6 attrH6={{ className: "text-muted" }}>{wallet?.out_points}</H6>
                      <span>{trans("website.out_points")}</span>
                    </CardBody>
                  </div>
                </Col>
              </Fragment>
            </Fragment>
          </Row>
        </Card>
      </Col>
    </Fragment>
  );
};

const ActiveSubscriptionContract = ({ subscriptionContract, appSettings, trans }) => {
  return (
    <Fragment>
      <Col xl="3" md="6" className="dash-xl-50 box-col-6">
        <Card
          className="premium-access text-white"
          style={CSS({
            backgroundColor: "rgba(2,0,36,1)",
            backgroundImage: `url(${"https://react.pixelstrap.com/zeta/static/media/premium-ace.b9b756891449c9768ed8.png"})`,
          })}
        >
          <CardBody>
            <Link className="text-white link-primary" to={process.env.PUBLIC_URL + "/crm/contracts/" + subscriptionContract?.token}>
              <H6 attrH6={{ className: "f-22" }}>
                {trans("website.subscription")} {subscriptionContract?.order_item?.item_name}
              </H6>
            </Link>
            <P attrPara={{ style: CSS({ color: "white", fontWeight: 600 }) }}>
              {subscriptionContract?.value} {appSettings?.currency_symbol} ({trans("website." + subscriptionContract?.frequency)})
            </P>
            <div className="mt-3 mb-2">
              <P>
                <i className="la la-calendar"></i> {formatDate(subscriptionContract?.start_at)} - {formatDate(subscriptionContract?.end_at)}
              </P>
            </div>
            <div className="progress">
              <div
                className="progress-bar progress-bar-animated bg-primary progress-bar-striped"
                role="progressbar"
                aria-valuenow={getDifferenceTime(subscriptionContract?.start_at, subscriptionContract?.end_at)}
                aria-valuemin={0}
                aria-valuemax={100}
                style={CSS({
                  width: getDifferenceTime(subscriptionContract?.start_at, subscriptionContract?.end_at),
                })}
              ></div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

const ActiveProject = ({ project, trans }) => {
  return (
    <Fragment>
      <Col xl="3" md="6" className="dash-xl-50 box-col-6">
        <Card
          className="premium-access bg-info"
          style={CSS({
            backgroundImage: `url(${"https://react.pixelstrap.com/zeta/static/media/premium-ace.b9b756891449c9768ed8.png"})`,
          })}
        >
          <CardBody>
            <Link className="text-white link-primary" to={process.env.PUBLIC_URL + "/crm/projects/" + project?.token}>
              <H6 attrH6={{ className: "f-22" }}>
                {trans("website.project")} {project?.name}
              </H6>
            </Link>
            <div className="mt-3 mb-2">
              <P>
                <i className="la la-calendar"></i> {formatDate(project?.start_at)} - {formatDate(project?.end_at)}
              </P>
            </div>
            <div className="progress">
              <div
                className="progress-bar progress-bar-animated bg-primary progress-bar-striped"
                role="progressbar"
                aria-valuenow={getDifferenceTime(project?.start_at, project?.end_at)}
                aria-valuemin={0}
                aria-valuemax={100}
                style={CSS({
                  width: getDifferenceTime(project?.start_at, project?.end_at),
                })}
              ></div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

const RequestPaymentOpen = ({ requestPaymentOpen, appSettings, trans }) => {
  return (
    <Fragment>
      <Col xl="3" md="6" className="dash-xl-50 box-col-6">
        <Card
          className="premium-access bg-warning"
          style={CSS({
            backgroundImage: `url(${"https://react.pixelstrap.com/zeta/static/media/premium-ace.b9b756891449c9768ed8.png"})`,
          })}
        >
          <CardBody>
            <H6 attrH6={{ className: "f-22" }}>{trans("website.pending-payment-request")}</H6>
            <P attrPara={{ style: CSS({ color: "white", fontWeight: 600 }) }}>
              {requestPaymentOpen?.amount} {appSettings?.currency_symbol}
            </P>

            <Link to={`${process.env.PUBLIC_URL}` + "/crm/contract-payment/" + requestPaymentOpen?.token} className="btn btn-outline-white_color">
              {trans("website.see_payment_request")}
            </Link>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

const Greeting = ({ userName, greeting }) => {
  return (
    <Fragment>
      <Col xl="6" md="6" className="dash-xl-50 box-col-12">
        <Card className="profile-greeting">
          <CardBody>
            <Media>
              <Media body>
                <div className="greeting-user text-capitalize">
                  <H1>{userName}</H1>
                  <P>{greeting}</P>
                  <Link to={`${process.env.PUBLIC_URL}/blog/blogsingle`} className="btn btn-outline-white_color d-none">
                    Get Started<i className="icon-arrow-right"></i>
                  </Link>
                </div>
              </Media>
            </Media>
            <div className="cartoon-img">
              <Image
                attrImage={{
                  className: "img-fluid",
                  src: `${dashImg}`,
                  alt: "",
                }}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

const OrdersLastTwelveMonths = ({ orders, avatar, setShowOrdersChart, appSettings, trans }) => {
  console.log(orders);
  let formattedChartData: {
    series: ApexAxisChartSeries | ApexNonAxisChartSeries;
    options: ApexOptions;
  } = {
    series: [
      {
        name: trans("website.orders"),
        data: orders?.map((o) => {
          return o.orders;
        }),
      },
      {
        name: trans("website.spent"),
        data: orders?.map((o) => {
          return o.total_spent;
        }),
      },
    ],
    options: {
      ...chartOrdersOptions(trans, "months", orders),
    },
  };
  return (
    <Fragment>
      <Col xl="3" md="6" className="dash-xl-50 box-col-6">
        <Card className="pb-0 o-hidden earning-card">
          <CardHeader
            className="earning-back d-flex justify-content-end"
            style={CSS({
              backgroundImage: `url(${"https://th.bing.com/th/id/R.78a774016b2d3f2fe93aa10859f47189?rik=XV3QbCs17nyIyw&pid=ImgRaw&r=0"})`,
            })}
          >
            <div className="icon-box onhover-dropdown m-auto" style={CSS({ height: 10 })} onClick={() => setShowOrdersChart("7d")}>
              <div className="btn btn-outline-light text-light d-flex align-items-center gap-1" style={CSS({ fontSize: 12, fontWeight: 700 })}>
                <i className="la la-chart-bar text-white"></i> <span>{trans("website.see_weekly")}</span>
              </div>
            </div>
          </CardHeader>
          <CardBody className="p-0">
            <div className="earning-content" style={{ position: "relative" }}>
              {typeof avatar === "string" ? (
                <Image
                  attrImage={{
                    width: 50,
                    height: "auto",
                    className: "img-fluid",
                    src: `${avatar}`,
                    alt: "",
                  }}
                />
              ) : (
                avatar
              )}
              <Link to={`${process.env.PUBLIC_URL}/crm/orders`}>
                <H4>{trans("website.orders_last_twelve_months")}</H4>
              </Link>
              <span>
                {new Date(new Date().getTime() - 86400000 * 365).toLocaleDateString()} - {new Date().toLocaleDateString()}
              </span>
              <H6>
                {Number(orders?.map((o) => Number(o.total_spent)).reduce((a, b) => a + b, 0)).toFixed(2)} {appSettings?.currency_symbol}
              </H6>
              <ApexChart id="earning-chart" options={formattedChartData.options} series={formattedChartData.series} type="area" height={145} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

const OrdersLastWeek = ({ orders, avatar, setShowOrdersChart, appSettings, trans }) => {
  console.log(orders);
  let formattedChartData: {
    series: ApexAxisChartSeries | ApexNonAxisChartSeries;
    options: ApexOptions;
  } = {
    series: [
      {
        name: trans("website.orders"),
        data: orders?.map((o) => {
          return o.orders;
        }),
      },
      {
        name: trans("website.spent"),
        data: orders?.map((o) => {
          return o.total_spent;
        }),
      },
    ],
    options: {
      ...chartOrdersOptions(trans, "weekdays", orders),
    },
  };
  return (
    <Fragment>
      <Col xl="3" md="6" className="dash-xl-50 box-col-6">
        <Card className="pb-0 o-hidden earning-card">
          <CardHeader
            className="earning-back d-flex justify-content-end"
            style={CSS({
              backgroundImage: `url(${"https://th.bing.com/th/id/R.78a774016b2d3f2fe93aa10859f47189?rik=XV3QbCs17nyIyw&pid=ImgRaw&r=0"})`,
            })}
          >
            <div className="icon-box onhover-dropdown m-auto" style={CSS({ height: 10 })} onClick={() => setShowOrdersChart("12m")}>
              <button className="btn btn-outline-light text-light d-flex align-items-center gap-1" style={CSS({ fontSize: 12 })}>
                <i className="la la-chart-bar text-white"></i> <span>{trans("website.see_yearly")}</span>
              </button>
            </div>
          </CardHeader>
          <CardBody className="p-0">
            <div className="earning-content" style={{ position: "relative" }}>
              {typeof avatar === "string" ? (
                <Image
                  attrImage={{
                    width: 50,
                    height: "auto",
                    className: "img-fluid",
                    src: `${avatar}`,
                    alt: "",
                  }}
                />
              ) : (
                avatar
              )}
              <Link to={`${process.env.PUBLIC_URL}/crm/orders`}>
                <H4>{trans("website.orders_last_seven_days")}</H4>
              </Link>
              <span>
                {new Date(new Date().getTime() - 86400000 * 7).toLocaleDateString()} - {new Date().toLocaleDateString()}
              </span>
              <H6>
                {Number(orders?.map((o) => Number(o.total_spent)).reduce((a, b) => a + b, 0)).toFixed(2)} {appSettings?.currency_symbol}
              </H6>
              <ApexChart id="earning-chart" options={formattedChartData.options} series={formattedChartData.series} type="area" height={145} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

const Tickets = ({ tickets, trans, sessionNow }) => {
  return (
    <Fragment>
      <Col xl="6">
        <Card className="recent-activity">
          <CardHeader className="card-no-border">
            <Media className="media-dashboard">
              <Media body>
                <H5 attrH5={{ className: "mb-0" }}>{trans("website.last_tickets")}</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody className="pt-0">
            <div className="table-responsive">
              <Table className="table-bordernone">
                <tbody>
                  {tickets?.map((item) => {
                    return (
                      <tr>
                        <td>
                          <Media key={item.id} className="d-flex align-items-center">
                            <div className="media-size-email d-flex align-items-center">
                              <i className={item.user_id === sessionNow?.user?.id ? "me-2 la la-mail-reply" : "me-2 la la-mail-forward"}></i>
                              <span
                                className={`d-none text-capitalize badge rounded-2 p-2 m-2 ${
                                  item.contact_type === "ticket" ? "bg-light text-dark" : item.contact_type === "chat" ? "bg-dark" : "bg-secondary"
                                }`}
                              >
                                {item.contact_type}
                              </span>
                              <span
                                style={CSS({ fontSize: 20 })}
                                className={`ms-3 me-3 text-center ${
                                  item.order_id && !item.reservation_id
                                    ? "text-danger"
                                    : item.reservation_id
                                    ? "text-warning"
                                    : item.contract_id
                                    ? "text-success"
                                    : item.lead_id
                                    ? "text-black"
                                    : "text-info"
                                }`}
                              >
                                {"•"}
                              </span>
                              {item?.reservation_id ? <span className="badge rounded-2 p-2 m-2 bg-light text-dark">{item?.reservation_reference}</span> : <></>}
                              {item?.user?.profile?.avatar ? (
                                <Image
                                  attrImage={{
                                    width: 25,
                                    height: "auto",
                                    className: "me-3 rounded-circle",
                                    src: `${
                                      item?.user?.profile?.avatar
                                        ? remotePath.assetsUrl(item?.user?.profile?.avatar)
                                        : "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"
                                    }`,
                                    alt: "",
                                  }}
                                />
                              ) : (
                                <div className="me-1">
                                  <EBSSSInitialNameProfile user={item?.user} width={25} height={25} fontSize={12.5} />
                                </div>
                              )}
                              <Link
                                to={{
                                  pathname: `${process.env.PUBLIC_URL}/crm/contacts/center/${item.contact_type}/${item?.token}${handleIfHasChildrenRoute(
                                    item
                                  )}`,
                                }}
                                className="d-flex"
                                state={item}
                              >
                                <Media body>
                                  <H6
                                    attrH6={{
                                      style: CSS({
                                        fontSize: 14,
                                        marginTop: 5,
                                      }),
                                    }}
                                  >
                                    {item.user.profile.first_name + " " + item.user.profile.last_name}
                                  </H6>
                                </Media>
                              </Link>
                            </div>
                          </Media>
                        </td>
                        <td>
                          <span className="badge badge-light-theme-light font-theme-light">{timeAgo(item.created_at)}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

const GenericTable = ({ title, data, colsAndRows, link }) => {
  return data?.length ? (
    <Fragment>
      <Col xl="6" className={``}>
        <Card className="ongoing-project">
          <CardHeader className="card-no-border">
            <Media className="media-dashboard">
              <Media body>
                <H5 attrH5={{ className: "mb-0" }}>{title}</H5>
              </Media>
            </Media>
          </CardHeader>
          <CardBody className="pt-0">
            <div className="table-responsive">
              <Table className="table-bordernone">
                <thead>
                  <tr>
                    {colsAndRows?.map((colName) => {
                      return (
                        <th>
                          <span>{colName.label as string}</span>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="d-none">
                          <Media>
                            <div className="square-box me-2">
                              <Image
                                attrImage={{
                                  className: "img-fluid b-r-5",
                                  src: `${""}`,
                                  alt: "",
                                }}
                              />
                            </div>
                            <Media body className="ps-2">
                              <div className="avatar-details">
                                <Link to={`${process.env.PUBLIC_URL}/ecommerce/productlist`}>
                                  <H6>{data[item.name]}</H6>
                                </Link>
                                <span>{}</span>
                              </div>
                            </Media>
                          </Media>
                        </td>
                        {colsAndRows?.map((c) => {
                          return (
                            <td>
                              <Link className="d-flex text-secondary" to={process.env.PUBLIC_URL + link + item?.token}>
                                <div>{c.value(item)}</div>
                              </Link>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  ) : (
    <></>
  );
};

const DashboardPage = () => {
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { fetchFullDashboard, dashboard, appSettings } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const [showOrdersChart, setShowOrdersChart] = useState("7d");

  console.log(dashboard);

  useEffect(() => {
    fetchFullDashboard();
  }, []);

  function getGreeting() {
    const now = new Date();
    const hours = now.getHours(); // Get the current hour (0-23)

    if (hours >= 5 && hours < 12) {
      return "website.good_morning";
    } else if (hours >= 12 && hours < 18) {
      return "website.good_afternoon!";
    } else {
      return "website.good_evening!";
    }
  }

  useEffect(() => {
    let dettachCMSTemplate = Array.from(document.querySelectorAll('[cms="true"], [href*="cms=true"], [src*="cms=true"]'));
    console.log("dettachCMSTemplate: ", dettachCMSTemplate);
    dettachCMSTemplate.forEach((el) => el.remove());
    return () => {
      let dettachCMSTemplate = Array.from(document.querySelectorAll('[cms="true"], [href*="cms=true"], [src*="cms=true"]'));
      console.log("dettachCMSTemplate: ", dettachCMSTemplate);
      dettachCMSTemplate.forEach((el) => el.remove());
    };
  }, []);

  return (
    <Page breads={[{ name: "Dashboard", active: true }]}>
      <Row>
        <Greeting userName={trans(getGreeting()) + ", " + sessionNow.user?.profile?.first_name} greeting={trans("website.welcome")} />
        {dashboard?.orders_actual_week && dashboard?.orders_last_months ? (
          showOrdersChart === "7d" ? (
            <OrdersLastWeek
              appSettings={appSettings}
              setShowOrdersChart={setShowOrdersChart}
              avatar={
                sessionNow?.user?.profile?.avatar ? (
                  remotePath.assetsUrl(sessionNow.user?.profile?.avatar)
                ) : (
                  <div className="m-1">
                    <EBSSSInitialNameProfile user={sessionNow?.user} width={25} height={25} fontSize={12.5} />
                  </div>
                )
              }
              orders={fillMissingWeekdays(dashboard?.orders_actual_week ?? [])}
              trans={trans}
            />
          ) : (
            <OrdersLastTwelveMonths
              appSettings={appSettings}
              setShowOrdersChart={setShowOrdersChart}
              avatar={
                sessionNow?.user?.profile?.avatar ? (
                  remotePath.assetsUrl(sessionNow?.user?.profile?.avatar)
                ) : (
                  <div className="m-1">
                    <EBSSSInitialNameProfile user={sessionNow?.user} width={25} height={25} fontSize={12.5} />
                  </div>
                )
              }
              orders={fillMissingTwelveMonths(dashboard?.orders_last_months ?? []).reverse()}
              trans={trans}
            />
          )
        ) : (
          <></>
        )}
        {dashboard?.last_open_payment_request ? (
          <RequestPaymentOpen appSettings={appSettings} requestPaymentOpen={dashboard?.last_open_payment_request} trans={trans} />
        ) : (
          <></>
        )}
        {dashboard?.wallet_balance?.data?.length ? <Wallet wallet={dashboard?.wallet_balance} trans={trans} /> : <></>}
        {dashboard?.reward_wallet_balance?.data?.length > 0 ? <WalletPoints wallet={dashboard?.reward_wallet_balance} trans={trans} /> : <></>}
        {dashboard?.next_entity_reservation_confirmed ? <NextReservation reservation={dashboard?.next_entity_reservation_confirmed} trans={trans} /> : <></>}
        {dashboard?.active_subscriptions ? (
          dashboard?.active_subscriptions?.map((subs) => {
            return <ActiveSubscriptionContract appSettings={appSettings} subscriptionContract={subs} trans={trans} />;
          })
        ) : (
          <></>
        )}
        {dashboard?.last_projects ? <ActiveProject project={dashboard?.last_projects} trans={trans} /> : <></>}
        {dashboard?.tickets?.length ? <Tickets tickets={dashboard?.tickets} sessionNow={sessionNow} trans={trans} /> : <></>}
        {dashboard?.last_five_payment_request ? (
          <GenericTable
            title={trans("website.last_request_payments")}
            data={dashboard?.last_five_payment_request}
            link="/crm/contract-payment/"
            colsAndRows={
              [
                {
                  name: "created_at",
                  label: trans("common.created_at"),
                  value: (r) => r["created_at"],
                },
                {
                  name: "status",
                  label: trans("common.status"),
                  value: (r) =>
                    r ? (
                      <>
                        {!appSettings?.billing_allow_payments_on_expired_requests && r["expected_date"] < new Date().toISOString() && r["status"] === "open" ? (
                          <span className="badge bg-danger badge-lg text-white">{trans("billing.payment-request-status-expired")}</span>
                        ) : (
                          <span className={`badge bg-${r["statusColorBootStrap"]} badge-lg text-white`}>
                            {trans(`billing.payment-request-status-${r["status"]}`)}
                          </span>
                        )}

                        {r.payment_proof && (
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to={`${process.env.PUBLIC_URL}/files/${r["payment_proof"]}`}
                            className="badge badge-sm bg-success"
                          >
                            <i className="fa fa-paperclip"></i>
                          </Link>
                        )}
                        {r["stripe_receipt_url"] && (
                          <Link to={r["stripe_receipt_url"]} target="_blank" rel="noopener noreferrer" className="badge badge-sm bg-success">
                            <i className="fa fa-cc-stripe"></i>
                          </Link>
                        )}
                      </>
                    ) : (
                      <></>
                    ),
                },
                {
                  name: "expected_date",
                  label: trans("billing.expected-payment-date"),
                  value: (r) => r["expected_date"],
                },
                {
                  name: "amount",
                  label: trans("website.total-amount"),
                  value: (r) => r["amount"] + " " + appSettings?.currency_symbol,
                },
              ] as any
            }
          />
        ) : (
          <></>
        )}
        {dashboard?.last_proposals ? (
          <GenericTable
            title={trans("website.last_proposals")}
            data={dashboard?.last_proposals}
            link="/crm/proposals/"
            colsAndRows={
              [
                {
                  name: "created_at",
                  label: trans("common.created_at"),
                  value: (r: any) => r["created_at"],
                },
                {
                  name: "valid_till",
                  label: trans("website.validate-untill"),
                  value: (r: any) => r["valid_till"],
                },
                {
                  label: trans("website.product"),
                  value: (r: any) => <ProposalItems proposal={r} trans={trans} appSettings={{}} />,
                },
                {
                  label: trans("common.status"),
                  value: (r: any) => <ProposalStatus proposal={r} trans={trans} />,
                },
                {
                  label: trans("website.total-amount"),
                  value: (r: any) => <ProposalTotal proposal={r} />,
                },
              ] as any
            }
          />
        ) : (
          <></>
        )}
        {dashboard?.last_orders ? (
          <GenericTable
            title={trans("website.last_orders")}
            data={dashboard?.last_orders}
            link="/crm/orders/"
            colsAndRows={
              [
                {
                  name: "created_at",
                  label: trans("website.created_at"),
                  value: (r: { created_at: any }) => r["created_at"],
                },
                {
                  name: "billing_name",
                  label: trans("website.to"),
                  value: (r: { billing_name: any }) => r["billing_name"],
                },
                {
                  label: trans("website.items"),
                  value: (r: any) =>
                    r["items"]?.slice(0, 3).map(
                      (
                        i: {
                          product: {
                            photo: string;
                            name: string | JSX.Element;
                          };
                          id: number;
                        },
                        idx: number
                      ) => {
                        return i?.product?.photo ? (
                          <TooltipToggle title={i.product?.name} id={i.id} style={CSS({ display: "inline-grid" })}>
                            <img style={CSS({ borderRadius: 50 })} src={remotePath.assetsUrl(i?.product?.photo)} width="30" height="auto" />
                            {idx === r?.items?.length - 2 && r?.items?.length > 3 ? (
                              <small style={CSS({ marginLeft: 2.5 })}> + {r?.items?.length - 3}</small>
                            ) : (
                              <></>
                            )}
                          </TooltipToggle>
                        ) : (
                          <></>
                        );
                      }
                    ),
                },
                {
                  name: "total",
                  label: trans("website.value"),
                  value: (r: { total: any }) =>
                    (Number(r["total"]) - Number(r["discount_coupon_value"] ?? "0")).toFixed(2) + " " + appSettings?.currency_symbol,
                },
              ] as any
            }
          />
        ) : (
          <></>
        )}
      </Row>
    </Page>
  );
};
export default DashboardPage;
