//@ts-nocheck
import { Fragment } from "react";
import { FileText, LogIn, Mail, Settings, User } from "react-feather";
import { FaBook, FaBookmark, FaQuestion, FaHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Media } from "reactstrap";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { signOut } from "src/base/store/authSlice";
import { H6, LI, UL } from "../../AbstractElements";
import { Account, Inbox, LogOut, Taskboard } from "../../Constant";
import Bookmark from "./Bookmark/index";
import ItemCart from "./ItemCart";
import Language from "./Langauge";
import MaxMiniSize from "./MaxMiniSize";
import MoonLight from "./MoonLight";
import Notification from "./Notification";
import EBSSSMegaMenu from "src/base/atoms/EBSSSMegaMenu";
import { CSS } from "src/_utils/styles";
import { Layout } from "src/base/store/layoutSlice";
import { ReduxDataKey } from "src/base/store/store";

const HeaderContain = ({ activeLang, sessionNow }) => {
  const dispatcher = useDispatch<any>();
  const { resetLoginState } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const navigate = useNavigate();

  const logout = () => {
    resetLoginState();
    dispatcher(signOut());
    navigate(process.env.PUBLIC_URL);
  };

  return (
    <Fragment>
      <div className="nav-right col-10 col-sm-6 pull-right right-header p-0 dash-76 no-print-ebsss">
        <UL
          attrUL={{
            className: `simple-list flex-row nav-menus`,
          }}
        >
          <LI attrLI={{ className: "onhover-dropdown" }}>
            <H6 attrH6={{ className: "txt-dark mb-0 mt-1" }}>{activeLang?.toUpperCase() ?? "PT"}</H6>
            <Language />
          </LI>
          <MoonLight />
          <ItemCart />
          <MaxMiniSize />
          {sessionNow?.user?.id ? <Notification /> : <></>}
          {sessionNow?.user?.id ? (
            <>
              <LI
                attrLI={{
                  className: "profile-nav onhover-dropdown",
                }}
              >
                <Media className="profile-media">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <g>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.55851 21.4562C5.88651 21.4562 2.74951 20.9012 2.74951 18.6772C2.74951 16.4532 5.86651 14.4492 9.55851 14.4492C13.2305 14.4492 16.3665 16.4342 16.3665 18.6572C16.3665 20.8802 13.2505 21.4562 9.55851 21.4562Z"
                          stroke="#130F26"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.55849 11.2776C11.9685 11.2776 13.9225 9.32356 13.9225 6.91356C13.9225 4.50356 11.9685 2.54956 9.55849 2.54956C7.14849 2.54956 5.19449 4.50356 5.19449 6.91356C5.18549 9.31556 7.12649 11.2696 9.52749 11.2776H9.55849Z"
                          stroke="#130F26"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M16.8013 10.0789C18.2043 9.70388 19.2383 8.42488 19.2383 6.90288C19.2393 5.31488 18.1123 3.98888 16.6143 3.68188"
                          stroke="#130F26"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M17.4608 13.6536C19.4488 13.6536 21.1468 15.0016 21.1468 16.2046C21.1468 16.9136 20.5618 17.6416 19.6718 17.8506"
                          stroke="#130F26"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </Media>
                <UL
                  attrUL={{
                    className: `simple-list profile-dropdown onhover-show-div`,
                    style: CSS({ width: 300 }),
                  }}
                >
                  <LI>
                    <Link className="d-flex align-items-center" to={`${process.env.PUBLIC_URL}/users/userprofile`}>
                      <i className="me-3 las la-user icon"></i>
                      <span>{trans("profile.my-profile")} </span>
                    </Link>
                  </LI>
                  <LI>
                    <Link className="d-flex align-items-center" to={`${process.env.PUBLIC_URL}/crm/contacts/center`}>
                      <i className="me-3 las la-mail-bulk icon"></i>
                      <span>{trans("website.contacts")} </span>
                    </Link>
                  </LI>
                  {ebsssLayout?.commonAppSettings?.hidden_faq ? (
                    <></>
                  ) : (
                    <LI>
                      <Link className="d-flex align-items-center" to={`${process.env.PUBLIC_URL}/faq`}>
                        <i className="me-3 lar la-question-circle icon"></i>
                        <span>{trans("website.faq")} </span>
                      </Link>
                    </LI>
                  )}
                  <LI>
                    <Link className="d-flex align-items-center" to={`${process.env.PUBLIC_URL}/favorites?page=1`}>
                      <i className="me-3 lar la-heart mb-1 icon"></i>
                      <span>{trans("website.favorites")} </span>
                    </Link>
                  </LI>
                  <LI>
                    <Link className="d-flex align-items-center" to={`${process.env.PUBLIC_URL}/gdpr`}>
                      <i className="me-3 las la-user-shield icon"></i>
                      <span>{trans("website.gdpr")} </span>
                    </Link>
                  </LI>
                  <LI>
                    <Link className="d-flex align-items-center" to={`${process.env.PUBLIC_URL}/terms-conditions`}>
                      <i className="me-3 las la-file-signature icon"></i>
                      <span>{trans("website.menu-terms-conditions")} </span>
                    </Link>
                  </LI>
                  <LI>
                    <Link className="d-flex align-items-center" to={`${process.env.PUBLIC_URL}/privacy-policy`}>
                      <i className="me-3 las la-file-signature icon"></i>
                      <span>{trans("website.menu-privacy-policy")} </span>
                    </Link>
                  </LI>
                  <LI
                    attrLI={{
                      className: "onhover-dropdown p-0 d-flex align-items-center",
                      onClick: logout,
                    }}
                  >
                    <i className="me-3 las la-sign-out-alt icon"></i>
                    <span>{trans("auth.log_out")}</span>
                  </LI>
                </UL>
              </LI>
            </>
          ) : (
            <></>
          )}
          <EBSSSMegaMenu />
        </UL>
      </div>
    </Fragment>
  );
};
export default HeaderContain;
