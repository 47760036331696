import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Card, CardBody, Table } from "reactstrap";
import { CSS } from "src/_utils/styles";
import EBSSSTicket from "src/base/atoms/EBSSSTicket";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import TableHeaderItem from "src/base/TableHeaderItem";
import { Image } from "../../AbstractElements";

const PaymentDetails = ({ order }) => {
  const { appSettings } = useEBSSS();
  const { trans } = useEBSSSLayout();

  return (
    <div className="row">
      {!appSettings?.sys_billing ? (
        <div className="col-lg-12 col-md-12 col-sm-12 text-center text-md-left">
          {order?.reference?.entity && (
            <>
              <p className="lead">{trans("website.payment-method-multibank")}:</p>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-borderless table-sm">
                      <tbody>
                        <tr>
                          <td rowSpan={4} className="text-center">
                            <img src={"assets/images/multibanco.png"} style={{ width: "100px" }} alt="Multibanco" />
                          </td>
                        </tr>
                        <tr>
                          <td>{trans("website.payment-entity")}:</td>
                          <td className="text-right">{order?.reference?.entity}</td>
                        </tr>
                        <tr>
                          <td>{trans("website.payment-reference")}:</td>
                          <td className="text-right">{order?.reference?.reference}</td>
                        </tr>
                        <tr>
                          <td>{trans("website.payment-value")}:</td>
                          <td className="text-right">{order?.reference?.value}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
          {(appSettings?.invoice_transfer_iban || appSettings?.invoice_transfer_swift) && (
            <>
              <p className="lead">{trans("website.payment-method-bank-transfer")}:</p>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-borderless table-sm">
                      <tbody>
                        {appSettings?.invoice_transfer_bank && (
                          <tr>
                            <td className="text-right">{trans("website.payment-bank")}:</td>
                            <td>{appSettings?.invoice_transfer_bank}</td>
                          </tr>
                        )}
                        {appSettings?.invoice_transfer_account && (
                          <tr>
                            <td className="text-right">{trans("website.payment-account")}:</td>
                            <td>{appSettings?.invoice_transfer_account}</td>
                          </tr>
                        )}
                        {appSettings?.invoice_transfer_iban && (
                          <tr>
                            <td className="text-right">{trans("website.payment-iban")}:</td>
                            <td>{appSettings?.invoice_transfer_iban}</td>
                          </tr>
                        )}
                        {appSettings?.invoice_transfer_swift && (
                          <tr>
                            <td className="text-right">{trans("website.payment-swift")}:</td>
                            <td>{appSettings?.invoice_transfer_swift}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        appSettings?.sys_billing &&
        order?.payment_requests.length > 0 && (
          <div className="col-lg-12 col-md-12 col-sm-12 text-center text-md-left d-none">
            {order?.payment_requests.map((paymentRequest) => (
              <Link
                key={paymentRequest.id}
                to={`${process.env.PUBLIC_URL}/crm/contract-payment/${paymentRequest.token}`}
                className="btn btn-primary btn-block mt-1 mb-1"
              >
                {paymentRequest.paymentMomment && (
                  <>
                    {paymentRequest.paymentMomment.name} ({paymentRequest.percentage * 100}%)
                  </>
                )}
                {trans("website.order-pay-payment-request")} #{paymentRequest.id}
              </Link>
            ))}
          </div>
        )
      )}
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="table-responsive">
          <table className="table">
            <tbody>
              {order?.installation_fee && order?.installation_fee > 0 && (
                <tr>
                  <td>{trans("billing.installation_fee")}:</td>
                  <td className="text-end">
                    {order?.installation_fee} {appSettings?.currency_symbol}
                  </td>
                </tr>
              )}
              <tr>
                <td>{trans("website.sub-total-amount")}:</td>
                <td className="text-end">
                  {order?.items
                    .map((i) => Number(i?.amount))
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)}{" "}
                  {appSettings?.currency_symbol}
                </td>
              </tr>
              {order?.items.map((item, i) => (
                <React.Fragment key={i}>
                  {item.type === "product" && item.product && (
                    <>
                      {item.fee && (
                        <tr>
                          <td>
                            {item.fee.title} #{i + 1}:
                          </td>
                          <td className="text-end">
                            {item.fee_amount > 0
                              ? `${item.fee_amount} ${appSettings?.currency_symbol}`
                              : `${item.fee_included} ${appSettings?.currency_symbol} (${trans("website.cart-fee-included")})`}
                          </td>
                        </tr>
                      )}
                      {item.tax && (
                        <tr>
                          <td>
                            {item.tax.title} #{i + 1}:
                          </td>
                          <td className="text-end">
                            {item.tax_amount > 0
                              ? `${item.tax_amount} ${appSettings?.currency_symbol}`
                              : `${item.tax_included} ${appSettings?.currency_symbol} (${trans("website.cart-tax-included")})`}
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
              {order?.discountCoupon && order?.discount_coupon_value > 0 && (
                <tr>
                  <td className="text-success">{order?.discountCoupon.name}:</td>
                  <td className="text-end text-success">
                    -{order?.discount_coupon_value} {appSettings?.currency_symbol}
                  </td>
                </tr>
              )}
              {order?.shipping_cost && (
                <tr>
                  <td>
                    {order?.shippingMethod ? (
                      <div className="d-flex flex-column text-start">
                        <span>{order?.shippingMethod.name}:</span>
                        <span>{order?.delivery_point && ` ${order?.delivery_point.name}`}</span>
                        <small>{order?.delivery_point && <span dangerouslySetInnerHTML={{ __html: order?.delivery_point.description }}></span>}</small>
                      </div>
                    ) : (
                      trans("website.sale-shipping-cost")
                    )}
                  </td>
                  <td className="text-end">
                    {order?.shipping_cost} {appSettings?.currency_symbol}
                  </td>
                </tr>
              )}
              {order?.discount_coupon_value && Number(order?.discount_coupon_value) > 0 && (
                <tr>
                  <td className="text-success">{trans("website.sub-total-discount")}:</td>
                  <td className="text-end text-success">
                    -{order?.discount_coupon_value} {appSettings?.currency_symbol}
                  </td>
                </tr>
              )}
              <tr>
                <td>{trans("website.total-amount")}:</td>
                <td className="text-end">
                  {(Number(order?.total) - Number(order?.discount_coupon_value ?? "0")).toFixed(2)} {appSettings?.currency_symbol}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const TableHeaders = ({ item }) => {
  const renderHeaders = () => {
    if (item.product) {
      switch (item.product.type) {
        case "physical":
          return (
            <>
              <TableHeaderItem translationKey="website.cart-product" />
              <TableHeaderItem translationKey="website.cart-product-details" />
              <TableHeaderItem translationKey="website.cart-quantity" />
              <TableHeaderItem translationKey="website.unit-price" />
              <TableHeaderItem translationKey="website.sub-price" />
            </>
          );
        case "service":
          return (
            <>
              <TableHeaderItem translationKey="website.cart-service" />
              <TableHeaderItem translationKey="website.cart-product-details" />
              <TableHeaderItem translationKey="website.cart-start-date" />
              <TableHeaderItem translationKey="website.unit-price" />
              <TableHeaderItem translationKey="website.sub-price" />
            </>
          );
        case "contract":
          return (
            <>
              <TableHeaderItem translationKey="website.cart-service" />
              <TableHeaderItem translationKey="website.cart-product-details" />
              <TableHeaderItem translationKey="website.contract-information" />
              <TableHeaderItem translationKey="website.recorrent-price" />
              <TableHeaderItem translationKey="website.sub-price" />
            </>
          );
        case "entities":
          return (
            <>
              <TableHeaderItem translationKey="website.cart-product" />
              <TableHeaderItem translationKey="website.order-rental-period" />
              <TableHeaderItem translationKey="website.order-rental-info" />
              <TableHeaderItem translationKey="website.sub-price" />
            </>
          );
        default:
          return (
            <>
              <TableHeaderItem translationKey="website.cart-product" />
              <TableHeaderItem translationKey="website.cart-product-details" />
              <TableHeaderItem translationKey="website.cart-quantity" />
              <TableHeaderItem translationKey="website.unit-price" />
              <TableHeaderItem translationKey="website.sub-price" />
            </>
          );
      }
    } else {
      switch (item.type) {
        case "fee":
        case "tax":
          return (
            <>
              <TableHeaderItem translationKey="website.order-table-fee-tax" />
              <TableHeaderItem translationKey="common.type" />
              <TableHeaderItem translationKey="website.sub-price" />
            </>
          );
        case "discount":
          return (
            <>
              <TableHeaderItem translationKey="website.order-table-discount" />
              <TableHeaderItem translationKey="common.type" />
              <TableHeaderItem translationKey="website.sub-price" />
            </>
          );
        default:
          return (
            <>
              <TableHeaderItem translationKey="website.cart-product" />
              <TableHeaderItem translationKey="website.cart-product-details" />
              <TableHeaderItem translationKey="website.cart-quantity" />
              <TableHeaderItem translationKey="website.unit-price" />
              <TableHeaderItem translationKey="website.sub-price" />
            </>
          );
      }
    }
  };

  return (
    <tr>
      <TableHeaderItem translationKey="#" />
      {renderHeaders()}
    </tr>
  );
};

const OrderPage = () => {
  const { appSettings, order, fetchOrder, setOrder } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const getOrder = useParams();
  const location = useLocation();
  const [IsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchOrder(getOrder?.token);
  }, []);
  return (
    <Page
      breads={[
        { name: trans("website.orders"), active: false, url: "/crm/orders" },
        {
          name: trans("website.order") + " #" + (order?.id ? order?.id : ""),
          active: true,
        },
      ]}
    >
      {!!order ? (
        <Card>
          <CardBody>
            <div className="content-body text-muted row text-muted">
              <div className="col-lg-6 pl-0 pr-0">
                <div>
                  {appSettings?.sys_multi_sellers && order?.seller ? (
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="media">
                          <div className="media-left">
                            <a href="/zeta/dashboard/default">
                              {order?.seller.profile?.avatar ? (
                                <img src={order?.seller.profile.avatar} alt="company logo" width="80px" className="media-object img-60 media" />
                              ) : (
                                <img
                                  src={appSettings?.invoice_logo_handle}
                                  alt="company logo"
                                  width="80px"
                                  className="media-object img-60 media"
                                  style={{ marginLeft: 20 }}
                                />
                              )}
                            </a>
                          </div>
                          <div className="m-l-20 media-body text-muted">
                            <h4 className="media-heading">{order?.seller?.name}</h4>
                            <p>
                              {" "}
                              {order?.seller.profile.address_line_1}, {order?.seller.profile.address_line_2}, {order?.seller.profile.locality} -{" "}
                              {order?.seller.profile.administrative_area}
                              <br />
                              <span>{order?.seller.profile.postal_code}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="text-md-end text-xs-center text-muted">
                          <h3>
                            {trans("website.order") + " #"}
                            <span className="counter">{order?.id}</span>
                          </h3>
                          <p>
                            {trans("common.created_at")} <span>{new Date(order.created_at).toLocaleDateString()}</span>
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="media">
                          <div className="media-left">
                            <a href="/zeta/dashboard/default">
                              {appSettings?.invoice_logo_handle && (
                                <img src={appSettings?.invoice_logo_handle} alt="company logo" width="80px" className="media-object img-60 media" />
                              )}
                            </a>
                          </div>
                          <div className="m-l-20 media-body text-muted">
                            <h4 className="media-heading">{appSettings?.invoice_company_name ? appSettings?.invoice_company_name : ""}</h4>
                            <p>
                              {" "}
                              {appSettings?.invoice_company_address}, {appSettings?.invoice_company_city_state}, {appSettings?.invoice_company_country}
                              <br />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="text-md-end text-xs-center text-muted">
                          <h3>
                            {trans("website.order") + " #"}
                            <span className="counter">{order?.id}</span>
                          </h3>
                          <p>
                            {trans("common.created_at")} <span>{new Date(order.created_at).toLocaleDateString()}</span>
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="invo-profile row">
                    <div className="col-xl-4">
                      <div className="media">
                        <div className="media-left">
                          <Image
                            attrImage={{
                              className: "media-object rounded-circle img-60",
                              src: `${"https://www.bing.com/th?id=OIP.mEma0ZcipymPAHIYoIuFiAHaJa&w=150&h=150&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2"}`,
                              alt: "",
                            }}
                          />
                        </div>
                        <div className="m-l-20 media-body text-muted">
                          <h4 className="media-heading">{order?.billing_name}</h4>
                          <p>
                            {order?.billing_email}
                            <br />
                            <span>{order?.billing_mobile}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8">
                      <div className="text-xl-end" id="project">
                        <h6>{trans("common.value")}</h6>
                        <p>{(Number(order?.total) - Number(order?.discount_coupon_value ?? "0")).toFixed(2) + " " + appSettings?.currency_symbol}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex-column gap-5 my-3 align-items-center w-100 border-2 shadow-sm p-3" style={CSS({ borderRadius: 10 })}>
                    <span className="my-3">Reservas:</span>
                    <div className="d-flex flex-row gap-3 mt-2">
                      {order?.items?.map((item) =>
                        item?.entity_reservations?.map((er) => (
                          <div key={er.token}>
                            <Link state={{ reservation: er }} to={`${process.env.PUBLIC_URL}/entity-reservations/${er.token}`} className="btn btn-outline-info">
                              <i className="fa fa-calendar-o"></i> {er.reference}
                            </Link>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                  <div className="flex-column gap-5 my-3 align-items-center w-100 border-2 shadow-sm p-3" style={CSS({ borderRadius: 10 })}>
                    <span className="my-3">{trans("website.payment-request")}:</span>
                    <div className="d-flex flex-row gap-3 mt-2">
                      {order?.payment_requests?.length > 0 &&
                        order?.payment_requests?.map((paymentRequest) => (
                          <div key={paymentRequest.token}>
                            <Link
                              to={`${process.env.PUBLIC_URL}/crm/contract-payment/${paymentRequest.token}`}
                              className={`btn btn-outline-${
                                paymentRequest.status === "paid"
                                  ? "primary"
                                  : ["cancel", "cancelled", "canceled"].includes(paymentRequest.status) ||
                                    paymentRequest.expected_date < new Date().toISOString()
                                  ? "danger"
                                  : "warning"
                              }`}
                            >
                              <i className="fa fa-credit-card"></i> {paymentRequest.id}
                            </Link>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="flex-column gap-5 my-3 align-items-center w-100 border-2 shadow-sm p-3" style={CSS({ borderRadius: 10 })}>
                    <span className="my-3">Produtos:</span>
                    <div className="d-flex flex-row gap-3 mt-2">
                      <div className="invoice table-responsive invoice-table w-100" id="table">
                        <Table className="table-bordered">
                          {order &&
                            order?.items.map((item, idxItem) => {
                              return (
                                <tbody>
                                  {idxItem === 0 ? <TableHeaders item={item} /> : <></>}
                                  <tr>
                                    <td scope="row">{idxItem + 1}</td>
                                    <td>
                                      <p>{item.item_name}</p>
                                    </td>
                                    {item.product && item.product.type === "entities" ? (
                                      <>
                                        <td>
                                          {item.rental_start_at} - {item.rental_end_at}
                                        </td>
                                        <td>
                                          {item.rental_people && (
                                            <>
                                              <i className="la la-users"></i> {item.rental_people}
                                            </>
                                          )}
                                        </td>
                                      </>
                                    ) : item.type !== "fee" && item.type !== "tax" && item.type !== "discount" ? (
                                      <>
                                        <td>
                                          <p>{item.item_option}</p>
                                        </td>
                                        <td>
                                          {item.product && item.product.type === "contract"
                                            ? item.contracts &&
                                              item.contracts[0] && (
                                                <Link
                                                  target="_blank"
                                                  to={`${process.env.PUBLIC_URL}/crm/contract/${item.contracts[0].token}`}
                                                  className="btn btn-outline-info btn-min-width btn-block mr-1 mb-1"
                                                >
                                                  <i className="la la-file-o"></i> {trans("website.order-contract")} #{item.contracts[0].id}
                                                </Link>
                                              )
                                            : item.product && item.product.type === "service"
                                            ? item.date_start
                                            : item.quantity}
                                        </td>
                                        <td className="text-right">
                                          {item.paymentType && item.product && item.product.type === "contract" ? (
                                            <>
                                              {item.recorrent_price} {appSettings?.currency_symbol}
                                              {item.paymentType.name}
                                            </>
                                          ) : (
                                            <>
                                              {item.unit_price} {appSettings?.currency_symbol}
                                            </>
                                          )}
                                        </td>
                                      </>
                                    ) : (
                                      <td>{trans(`website.order-item-type-${item.type}`)}</td>
                                    )}
                                    <td className="text-right">
                                      {item.amount} {appSettings?.currency_symbol}
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            })}
                        </Table>
                      </div>
                    </div>
                  </div>
                  <PaymentDetails order={order} />
                </div>
              </div>
              <EBSSSTicket data={order} dataType={"order"} callbackRefresh={() => fetchOrder(getOrder?.token)} />
            </div>
          </CardBody>
        </Card>
      ) : (
        <></>
      )}
    </Page>
  );
};
export default OrderPage;
