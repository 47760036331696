import React from "react";
import { CSS } from "src/_utils/styles";

const EBSSSCheckbox = ({ id, action, label, selected }) => {
  return (
    <div className={`d-flex flex-row ebsss-custom-checkbox align-items-center gap-2 ${selected ? "checked" : ""}`} onClick={action}>
      <div onClick={action} className="ebsss-checkbox-icon border rounded bg-white" style={CSS({ width: 17.5 })}>
        {selected ? <i className="la la-check text-success"></i> : ""}
      </div>
      <label className="ebsss-checkbox-label">{label}</label>
    </div>
  );
};

export default EBSSSCheckbox;
