import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { scrollUpPage } from "src/_utils/elements";
import { CSS } from "src/_utils/styles";
import { getQueryParams } from "src/_utils/urls";
import { Btn } from "src/AbstractElements";
import EBSSSTable from "src/base/atoms/EBSSSTable";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import TablePage from "src/base/TablePage";

const ProjectsPage = () => {
  const { trans } = useEBSSSLayout();
  const { fetchProjects, projects, appSettings } = useEBSSS();
  const [page, setPage] = useState(null);

  useEffect(() => {
    setPage((p) => {
      if (p !== null) {
        return p;
      } else {
        return Number(getQueryParams(window.location.href)?.page);
      }
    });
  }, [window.location.search]);

  useEffect(() => {
    scrollUpPage();
    if (!projects) {
      fetchProjects(1);
      return;
    }
    if (page) {
      fetchProjects(page);
    }
  }, [page]);

  console.log(projects);

  let showTable = true;

  return showTable ? (
    <TablePage
      breads={[
        { name: trans("website.projects"), active: true, url: "/crm/projects" },
      ]}
      title=""
      description=""
    >
      <EBSSSTable
        pageHandler={setPage}
        data={projects}
        cols={[
          {
            name: "#",
            selector: (row: { id: any }) => <span>{row.id}</span>,
            sortable: true,
            center: true,
          },
          {
            name: trans("website.responsible"),
            selector: (row: { responsible: any }) =>
              row.responsible ? JSON.stringify(row.responsible) : "-",
            sortable: true,
            center: false,
          },
          {
            name: trans("website.project"),
            selector: (row: { name: any }) => row.name,
            sortable: true,
            center: false,
          },
          {
            name: trans("website.start_at"),
            selector: (row: { start_at: any }) => row.start_at,
            sortable: true,
            center: false,
          },
          {
            name: trans("website.end_at"),
            selector: (row: { end_at: any }) => row.end_at,
            sortable: true,
            center: false,
          },
          {
            name: "",
            selector: (row: any): any => {
              return (
                <div style={CSS({ gap: 5, display: "flex" })}>
                  <Link
                    to={process.env.PUBLIC_URL + "/crm/projects/" + row.token}
                    state={{
                      order: { ...row, settings: appSettings },
                    }}
                  >
                    <Btn
                      className="text-white btn-pill"
                      attrBtn={{
                        color: "info",
                        className: "info",
                      }}
                    >
                      {trans("common.see")}
                    </Btn>
                  </Link>
                </div>
              );
            },
            sortable: true,
            center: true,
          },
        ]}
      />
    </TablePage>
  ) : (
    <Page breads={[{ name: trans("website.projects"), active: true }]}>
      <></>
    </Page>
  );
};
export default ProjectsPage;
