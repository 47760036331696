import { Fragment, useState } from "react";
import { Key } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import FeedbackMessages from "src/base/FeedbackMessage";
import { fetchToken, Session } from "src/base/store/authSlice";
import { ReduxDataAuthKey, ReduxDataKey } from "src/base/store/store";

const RegisterPageToken = () => {
  const { trans } = useEBSSSLayout();
  const [token, setToken] = useState("");
  const dispatcher = useDispatch<any>();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);

  console.log("Redux: ", sessionNow);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatcher(fetchToken({ token, activeLanguage: "pt" }));
  };

  return sessionNow?.user?.id ? (
    <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
  ) : sessionNow?.redirect?.signupPassword ? (
    <Navigate to={`${process.env.PUBLIC_URL}/auth/sign-up/password`} />
  ) : (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col
            xl="7 order-1"
            style={{
              backgroundImage: `url("https://micaela.3.ebsss.dev/platform-images/settings/customer_area_background/7679a69f-f07c-4c7c-9af4-f43d771cee18.jpg?p=slider&s=09962273dea6f982797368e0cd6b4f6b")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "block",
            }}
          ></Col>
          <Col xl="5 p-0">
            <div className="login-card1">
              <Form className="theme-form login-form">
                <div className="login-header text-center">
                  <h4>{trans("website.verify-your-token")}</h4>
                  <h6>{trans("website.text-the-code-from-your-email-that-we-provided")}</h6>
                </div>
                <FormGroup>
                  <Label>{trans("website.token")}</Label>
                  <InputGroup>
                    <InputGroupText>
                      <Key />
                    </InputGroupText>
                    <Input type="text" required defaultValue={token} onChange={(event) => setToken(event.target.value)} />
                  </InputGroup>
                  <FeedbackMessages messages={sessionNow?.errors?.signupToken["token"]} />
                </FormGroup>
                <FormGroup>
                  <EBSSSButton loadingKey={ReduxDataAuthKey.signupToken} onClick={handleSubmit}>
                    Verificar
                  </EBSSSButton>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RegisterPageToken;
