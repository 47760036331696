import { Fragment } from "react";
import { CSS } from "src/_utils/styles";
import { Breadcrumbs } from "src/AbstractElements";
import ListContain from "../Component/Ecommerce/List";
import { useEBSSS } from "./context/EBSSSContext/useEBSSS";

const TablePage = ({ children, title, description, breads }) => {
  const { loaded } = useEBSSS();

  return (
    <Fragment>
      <Breadcrumbs breads={breads} title={breads[breads.length - 1].name} />
      <ListContain title={title} description={description}>
        {loaded ? (
          children
        ) : (
          <div
            className="w-100 justify-center justify-content-center d-flex"
            style={CSS({ height: "50vh" })}
          >
            <div className="loader d-flex align-center justify-center m-auto text-center">
              <div className="loader-bar"></div>
              <div className="loader-bar"></div>
              <div className="loader-bar"></div>
              <div className="loader-bar"></div>
              <div className="loader-bar"></div>
              <div className="loader-ball"></div>
            </div>
          </div>
        )}
      </ListContain>
    </Fragment>
  );
};
export default TablePage;
