import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { scrollUpPage } from "src/_utils/elements";
import { CSS } from "src/_utils/styles";
import { getQueryParams } from "src/_utils/urls";
import { Btn } from "src/AbstractElements";
import EBSSSModal from "src/base/atoms/EBSSSModal";
import EBSSSTable from "src/base/atoms/EBSSSTable";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import TablePage from "src/base/TablePage";

const NotificationsPage = () => {
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { fetchNotifications, removeNotifications, removeAllNotifications, notifications } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const [page, setPage] = useState(null);
  const [modal, setModal] = useState("");

  const toggle = (mdltkn) => {
    setModal(mdltkn);
  };

  useEffect(() => {
    setPage((p) => {
      if (p !== null) {
        return p;
      } else {
        return Number(getQueryParams(window.location.href)?.page);
      }
    });
  }, [window.location.search]);

  useEffect(() => {
    scrollUpPage();
    if (!page) {
      fetchNotifications(1);
      return;
    }
    if (page) {
      fetchNotifications(page);
    }
  }, [page]);

  return (
    <TablePage
      breads={[
        {
          name: trans("website.notifications"),
          active: true,
          url: "/crm/notifications",
        },
      ]}
      title=""
      description=""
    >
      <EBSSSTable
        topAction={
          notifications?.data?.length > 0 ? (
            <EBSSSModal
              modalSize="modal-sm"
              modalTitle={trans("common.confirm")}
              modal={modal === "delete_all"}
              toggle={toggle}
              customConfirmButtonText={trans("common.confirm")}
              customConfirmAction={removeAllNotifications}
              triggerEl={
                <Btn
                  attrBtn={{
                    color: "transparent",
                    className: "btn btn-sm bg-transparent btn-outline-danger",
                    onClick: () => toggle("delete_all"),
                  }}
                >
                  <i className="fa fa-trash-arrow-up"></i> {trans("website.delete_all")}
                </Btn>
              }
            >
              <div className="dz-message needsclick">
                <div className="text-center">
                  {trans("website.youre_about_to_delete")} {notifications?.total} {trans("website.notifications")}. {trans("website.are_you_sure")}?
                </div>
              </div>
            </EBSSSModal>
          ) : (
            <></>
          )
        }
        pageHandler={setPage}
        data={notifications}
        cols={[
          {
            name: "#",
            selector: (row) => <span>{row.id}</span>,
            sortable: true,
            center: true,
          },
          {
            name: trans("website.created_at"),
            selector: (row: { created_at: any }) => row.created_at,
            sortable: true,
            center: false,
          },
          {
            name: trans("website.subject"),
            selector: (row: { subject: any }) => row.subject,
            sortable: true,
            center: false,
          },
          {
            name: "",
            selector: (row: { body: string; token: string }): any => {
              return (
                <div style={CSS({ gap: 5, display: "flex" })}>
                  <EBSSSModal
                    modalSize="modal-lg"
                    modalTitle={trans("website.notification")}
                    modal={modal === row.token}
                    toggle={toggle}
                    hideSubmitButton
                    triggerEl={
                      <Btn
                        className="text-white btn-pill"
                        attrBtn={{
                          color: "info",
                          onClick: () => toggle(row.token),
                          style: CSS({ cursor: "pointer" }),
                        }}
                      >
                        <i className="fa fa-eye"></i>
                      </Btn>
                    }
                  >
                    <div className="dz-message needsclick p-5">
                      <div dangerouslySetInnerHTML={{ __html: row.body }}></div>
                    </div>
                  </EBSSSModal>
                  <Btn
                    className="text-white btn-pill "
                    attrBtn={{
                      type: "button",
                      color: "danger",
                      onClick: () => removeNotifications(row.token),
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </Btn>
                </div>
              );
            },
            sortable: true,
            center: true,
          },
        ]}
      />
    </TablePage>
  );
};

export default NotificationsPage;
