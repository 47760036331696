//@ts-nocheck
import React, { Fragment } from "react";
import { PhoneInput } from "react-international-phone";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import HelperHTTP from "src/_utils/http_module_handler";
import { CSS } from "src/_utils/styles";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { remotePath } from "src/base/settings/Paths";
import { clearCartAndOrderSessionSimple, clearCheckout, clearShippingSession, newOrderSessionShipping, replaceToken, Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import Header from "src/Layout/Header";
import { default as CheckContext, default as CustomContext } from "../../_helper/customizer";
import { CartItem } from "./CheckoutCommon";
import { Btn, H6, Image } from "src/AbstractElements";
import { fetchLangs, Layout } from "src/base/store/layoutSlice";
import EBSSSModal from "src/base/atoms/EBSSSModal";
import LoginForm from "../Auth/LoginForm";
import RegisterForm from "../Auth/RegisterForm";
import { toast, ToastContainer } from "react-toastify";
import { countCharacters, suggestEmailCorrection, updatePostCodeMask, validateEmail } from "src/_utils/validations";
import EBSSSTooltip from "src/base/atoms/EBSSSTooltip";
import PasswordForm from "../Auth/PasswordForm";
import Select from "react-select";
import { Card, Input, Label, Media } from "reactstrap";
import FeedbackMessages from "src/base/FeedbackMessage";
import EBSSSInitialNameProfile from "src/base/atoms/EBSSSInitialNameProfile";
import InputMask from "react-input-mask";
import { FaBuilding, FaIdCard } from "react-icons/fa";
import { Globe, Mail, Map, Phone, User } from "react-feather";
import { EBSSSFormBuilder } from "src/base/atoms/EBSSSFormBuilder";
import EBSSSLoading from "src/base/atoms/EBSSSLoading";
import { getQueryParams } from "src/_utils/urls";
import EBSSSCheckbox from "src/base/atoms/EBSSSCheckbox";

function injectCSSLinks() {
  const cssLinks = [
    {
      rel: "stylesheet",
      href: "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css",
    },
    {
      rel: "stylesheet",
      href: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.7/build/css/intlTelInput.css",
    },
    {
      rel: "stylesheet",
      href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css",
    },
  ];

  cssLinks.forEach((linkInfo) => {
    const linkElement = document.createElement("link");
    linkElement.rel = linkInfo.rel;
    linkElement.href = linkInfo.href;
    document.head.appendChild(linkElement);
  });
}

const DAY_IN_MS = 86400000;
const css = (cssObj) => Object.assign({}, cssObj);

const isTruthy = (param) => param !== "" && param !== "0" && param !== 0 && param !== "false" && param === true;

const filterKeysWithPrefix = (obj, prefix = null, cb = null, inverted = false) => {
  let filteredObj = {};
  let toInvertObj = obj;
  for (const key in obj) {
    let valuedCb = obj[key];
    if (cb) {
      valuedCb = cb(valuedCb);
    }
    if ((prefix && key.startsWith(prefix)) || !prefix) {
      if (!inverted) {
        filteredObj[key] = valuedCb;
      } else {
        delete toInvertObj[key];
      }
    }
  }
  return !inverted ? filteredObj : toInvertObj;
};

const formatTimer = (ms) => {
  // Calculate total seconds
  let totalSeconds = Math.floor(ms / 1000);

  // Calculate hours, minutes, and seconds
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);
  let seconds = totalSeconds % 60;

  // Format hours, minutes, and seconds to be always two digits
  let formattedHours = String(hours).padStart(2, "0");
  let formattedMinutes = String(minutes).padStart(2, "0");
  let formattedSeconds = String(seconds).padStart(2, "0");

  // Return the formatted time string
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

const getFurthestDay = (daysArray) => {
  // Ordered array of week days for comparison
  const weekDaysOrder = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

  // Initialize the furthest day and its index
  let furthestDay = null;
  let maxIndex = -1;

  // Loop through the days array
  for (let day of daysArray) {
    // Find the index of the current day's week_day in the ordered array
    const currentIndex = weekDaysOrder.indexOf(day.week_day.toLowerCase());

    // Update furthest day if the current day is further in the week
    if (currentIndex > maxIndex) {
      maxIndex = currentIndex;
      furthestDay = day;
    }
  }

  return furthestDay;
};

const formatToYYYYMMDD = (dateObj) => {
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObj.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const filterRepeatedObjects = (array) => {
  const seen = new Set();
  return array
    .filter((item) => {
      const key = JSON.stringify(item);
      if (seen.has(key)) {
        return false;
      }
      seen.add(key);
      return true;
    })
    .filter((v) => !!v);
};

const focusOnFirstEmptyInput = (formId) => {
  const form = document.getElementById(formId);
  if (!form) return;

  const inputs = form.querySelectorAll("input");
  for (let i = 0; i < inputs.length; i++) {
    if (!inputs[i].value.trim()) {
      inputs[i].focus();
      break;
    }
  }
};

const calculateRouteDistance = (lat1, lng1, lat2, lng2) => {
  let directionsDisplay = new google.maps.DirectionsRenderer();
  let directionsService = new google.maps.DirectionsService();

  let start = lat1 + " " + lng1;
  let end = lat2 + " " + lng2;
  let request = {
    origin: start,
    destination: end,
    travelMode: "DRIVING",
  };
  directionsService.route(request, function (result, status) {
    if (status == "OK") {
      if (result.routes.length > 0) {
        let route = result.routes[0].legs[0];
        console.log(route.distance.value / 1000);
      }
    }
  });
};
const getCountryCode = () => {
  const language = navigator.language || navigator["userLanguage"];
  const countryCode = language.split("-")[1] || language.split("_")[1];
  return countryCode ? countryCode : "pt";
};
export const emitSwal = ({ type, message, configSwal }) => {
  let settingsSwal = {
    title: "",
    text: message,
    icon: type !== "e" ? (type === "s" ? "success" : "info") : "error",
  };

  if (configSwal) {
    settingsSwal = {
      ...settingsSwal,
      ...configSwal,
    };
    configSwal.callback(swal(settingsSwal));
    return;
  }

  swal(settingsSwal);
  document.querySelector(".sweet-alert")?.classList?.remove("visible");
  document.querySelector(".sweet-alert")?.classList?.remove("showSweetAlert");
};
const standardizeSwal = (forLogin) => {
  document.querySelector(".swal-footer").classList.add("d-flex");
  document.querySelector(".swal-footer").classList.add("justify-content-center");
  document.querySelectorAll(".swal-button").forEach((el) => el.classList.add("rounded-0"));
  document.querySelector(".swal-text").classList.add("text-center");
  if (forLogin) {
    document.querySelector(".swal-modal").children[1].after(document.querySelector(".swal-modal").children[0]);
    document.querySelector(".swal-modal").children[1].classList.add("mb-2", "mt-4");
  }
};
const capitalizeWords = (str, option = "all") => {
  // Helper function to capitalize the first letter of a word
  function capitalize(word) {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1);
  }

  if (!str) {
    return "";
  }

  // Based on the option, perform the corresponding capitalization
  switch (option.toLowerCase()) {
    case "all":
      return str?.split(" ")?.map(capitalize)?.join(" ");
    case "first":
      return capitalize(str?.split(" ")[0]) + str?.slice(str?.indexOf(" "));
    case "none":
      return str;
    default:
      throw new Error("Invalid option. Use 'all', 'first', or 'none'.");
  }
};
const { createContext, useState, useEffect, useContext, useRef } = React;

const LoadingIndicator = () => {
  return (
    <div className="loading-container">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

const FallBackMessage = ({ message }) => {
  return (
    <div className="loading-container">
      <div className="d-flex flex-column align-items-center gap-5">
        <span>{message}</span>
        <a
          href={window.location.origin + (window["TEMP_REDIRECT_LOGIN"] ? process.env.PUBLIC_URL + "/auth/sign-in" : process.env.PUBLIC_URL + "/crm/products")}
        >
          <i className="fa-solid fa-person-walking-arrow-loop-left" style={CSS({ fontSize: "xx-large" })}></i>
        </a>
      </div>
    </div>
  );
};

const CartContext = createContext({} as any);
const CartProvider = ({ children }) => {
  const { removeItemFromCart } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const getSeller = useParams();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const [redirectLinkFinishedOrder, setRedirectLinkFinishedOrder] = useState("");
  const [cartLoaded, setCartLoaded] = useState(false);
  const [availableShippings, setAvailableShippings] = useState([]);
  const [translatedShippings, setTranslatedShippings] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cartState, setCartState] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [onlyTakeAway, setOnlyTakeAway] = useState(false);
  const [allowTakeAway, setAllowTakeAway] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const [sysMultiSellers, setSysMultiSellers] = useState(false);
  const [payNow, setPayNow] = useState(0);
  const [total, setTotal] = useState(0);
  const [billingInformation, setBillingInformation] = useState({});
  const [availableCoupons, setAvailableCoupons] = useState(0);
  const [requirePaymentMethodBeforeGenerateOrder, setRequirePaymentMethodBeforeGenerateOrder] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [translatedPaymentMethods, setTranslatedPaymentMethods] = useState([]);
  const [paymentMethodsSetted, setPaymentMethodsSetted] = useState({});
  const [paymentDirectToSeller, setPaymentDirectToSeller] = useState(false);
  const [cartItemTypes, setCartItemTypes] = useState({});
  const [toursLat, setToursLat] = useState("");
  const [toursLon, setToursLon] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [shippingCost, setShippingCost] = useState("");
  const [errs, setErrs] = useState(null);
  const [validations, setValidations] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [municipalities, setMunicipalities] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [steps, setSteps] = useState([]);
  const [ttlToExpire, setTTLToExpire] = useState(null);
  const [reasonable, setReasonable] = useState("");
  const [showCartImages, setShowCartImages] = useState(true);
  const [canOrderWithoutLogin, setCanOrderWithoutLogin] = useState(null);
  const [defaultPayType, setDefaultPayType] = useState(null);
  const [redirectEmptyCart, setRedirectEmptyCart] = useState(false);
  const [createAccountAfterPurchase, setCreateAccountAfterPurchase] = useState(false);
  const [translatedPaymentRequestTypes, setTranslatedPaymentRequestTypes] = useState([]);
  const [alreadySellerDataFetched, setAlreadySellerDataFetched] = useState(false);

  const [tenantConfig, setTenantConfig] = useState({
    hiddenLogo: false,
    tenant: "",
    title: "",
    logo: "",
  });

  const [requiredInputs, setRequiredInputs] = useState({
    vat: false,
    email: false,
    mobile_number: false,
  });

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const fetchCartState = async (handleLoader = true) => {
    try {
      let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT)
        .addSessionState(sessionNow?.session ?? {})
        .api();

      if (getSeller?.seller) {
        beforeReq = beforeReq.appendQuery({ seller: getSeller.seller });
      }

      if (sessionNow?.user?.id) {
        beforeReq.authed({ tk: "Bearer " + sessionNow.token });
      }

      const response = await beforeReq.post({});

      if (!response?.data?.cart) {
        console.log("errs above, ", response);
        if (response?.errors && response?.errors?.flag === "EMPTY_CART") {
          setRedirectEmptyCart(true);
          return;
        }
        if (response?.errors && response?.errors?.flag === "NO_INSTANT_PAYMENT") {
          navigate(process.env.PUBLIC_URL + "/checkout/pre");
          return;
        }
        setErrs(response.message);
        return;
      }
      setCartState(response?.data?.cart ? (Array.isArray(response?.data?.cart) ? response?.data?.cart : Object.values(response?.data?.cart)) : []);
      setShowShipping(response?.data?.show_shipping);
      setPayNow(response?.data?.pay_now);
      setTotal(response?.data?.total);
      setAvailableCoupons(response?.data?.available_coupons);
      setCartItemTypes(response?.data?.cart_item_types);
      setOnlyTakeAway(isTruthy(response?.data?.only_take_away));
      setAllowTakeAway(isTruthy(response?.data?.allow_take_away));
      setSysMultiSellers(isTruthy(response?.data?.sys_multi_sellers));
      setPaymentMethods(response?.data?.translated_payment_methods);
      setTranslatedPaymentRequestTypes(response?.data?.ptypes);
      setTranslatedPaymentMethods(response?.data?.translated_payment_methods);
      setCanOrderWithoutLogin(response?.data?.crm_sale_without_login);
      setCreateAccountAfterPurchase(response?.data?.generate_user_purchase === "1");
      setPaymentMethodsSetted(filterKeysWithPrefix(response?.data?.payment_methods_setted, null, (pm) => (typeof pm === "string" ? isTruthy(pm) : pm)));
      setTranslatedShippings(response?.data?.translated_shipping_methods);
      setAvailableShippings(response?.data?.available_shipping_methods);
      setToursLat(response?.data?.tours_latitude);
      setToursLon(response?.data?.tours_longitude);

      if (!handleLoader) {
        return;
      }

      setDefaultPayType(response?.data?.default_payment_type);
      setSteps([trans("website.address"), trans("website.finish")]);
      setCountries(response?.data?.countries);
      setDistricts(response?.data?.districts);
      setPaymentDirectToSeller(isTruthy(response?.data?.payment_direct_to_seller));
      setRequirePaymentMethodBeforeGenerateOrder(isTruthy(response?.data?.require_payment_method_before_generate_order));
      setBillingInformation(filterKeysWithPrefix(response.data, "billing_"));
      setCurrencySymbol(response?.data?.currency_symbol);
      setTTLToExpire(response?.data?.cart_expiration);
      setRequiredInputs(response?.data?.requires);
      setShowCartImages(response?.data?.show_cart_images);
      setTenantConfig({
        hiddenLogo: response?.data?.tenant_configuration.hidden_logo ?? false,
        tenant: response?.data?.tenant_configuration.tenant ?? "",
        title: response?.data?.tenant_configuration.title ?? "",
        logo: response?.data?.tenant_configuration.logo ?? "",
      });
      let applyFavicon = setTimeout(() => {
        var element = document.createElement("link");
        element.setAttribute("rel", "shortcut icon");
        element.setAttribute("href", window.location.origin + "/files/" + response?.data?.tenant_configuration.favicon);
        document.head.append(element);
        clearTimeout(applyFavicon);
      }, 1);
      let applyCSS = setTimeout(() => {
        var element = document.createElement("style");
        element.setAttribute("type", "text/css");
        element.innerHTML = response?.data?.tenant_configuration.website_custom_css;
        document.head.append(element);
        clearTimeout(applyCSS);
      }, 1);
    } catch (e: Error) {
      console.log("errs", e);
      if (e?.response?.data?.errors && e?.response?.data?.errors?.flag === "NO_INSTANT_PAYMENT") {
        navigate(process.env.PUBLIC_URL + "/checkout/pre");
        return;
      }

      if (e?.response?.data?.errors && e?.response?.data?.errors?.flag === "LOGIN") {
        window["TEMP_REDIRECT_LOGIN"] = true;
      }
      console.log("An error occurs: ", e);
      if (!handleLoader) {
        window.location.reload();
      }
    } finally {
      if (handleLoader) {
        setCartLoaded(!cartLoaded);
      }
    }
  };

  const incrementQuantity = (id) => {
    setCartState(cartState?.map((item) => (item?.p?.id === id ? { ...item, quantity: Number(item?.quantity) + 1 } : item)));
  };

  const decrementQuantity = (id) => {
    setCartState(cartState?.map((item) => (item?.p?.id === id && item?.quantity > 1 ? { ...item, quantity: Number(item?.quantity) - 1 } : item)));
  };

  const removeItem = async (e, hash) => {
    e.preventDefault();
    try {
      await removeItemFromCart(hash);
      setCartState(cartState?.filter((item) => item?.p?.hash !== hash));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCartState();
  }, []);

  useEffect(() => {
    fetchCartState(false);
  }, [sessionNow.session?.cart]);

  console.log("CART: ", sessionNow?.session?.cart);

  useEffect(() => {
    if (cartState.length === 0 && reasonable === "EMPTY_CART_AFTER_DELETE_ITEM") {
      console.log("refreshing due to no items in cart...");
      setRedirectEmptyCart(true);
    }
  }, [cartState, reasonable]);

  async function setOrderSession(data, loading = false) {
    if (loading) {
      setCartLoaded(false);
    }
    console.log("Setting session...");
    let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_ORDER_SESSION)
      .addSessionState(sessionNow?.session ?? {})
      .api();

    if (getSeller?.seller) {
      beforeReq = beforeReq.appendQuery({ seller: getSeller.seller });
    }

    if (sessionNow?.user?.id) {
      beforeReq.authed({ tk: "Bearer " + sessionNow.token });
    }

    const response = await beforeReq.post({ data });
    console.log("SET ORDER SESSION RESPONSE: ", response);

    if (response?.success) {
      if (response.data?.user_session?.shipping) {
        dispatcher(newOrderSessionShipping({ data: response.data }));
        let shCost = parseFloat(Number(parseFloat(response.data?.user_session?.shipping?.cost ?? 0)).toFixed(2));
        console.log("Shipping cost: ", shCost, ", Total: ", total, " Paynow: ", payNow);
        if (String(shCost) === String(shippingCost)) {
          return response;
        }
        setShippingCost(String(shCost));
      }
      setCartLoaded(true);
    } else {
      setValidations((s) => {
        return { ...s, ...response?.errors };
      });
      setCartLoaded(true);
    }
    return response;
  }

  async function saveOrder(dataPayload, cb, cbNewUser) {
    console.log("Setting session...");
    let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_ORDER_SAVE)
      .addSessionState(sessionNow?.session ?? {})
      .api();

    if (getSeller?.seller) {
      beforeReq = beforeReq.appendQuery({ seller: getSeller.seller });
    }

    if (sessionNow?.user?.id) {
      beforeReq.authed({ tk: "Bearer " + sessionNow.token });
    }

    let { data, errors, ...responseReturn } = await beforeReq.post({
      data: dataPayload,
    });

    console.log("saveOrder: ", { data, errors, responseReturn });

    if (errors) {
      let configErrorFeedback = { configSwal: {} };
      if (errors?.flag && errors?.flag === "LOGIN") {
        let createIconContainer = document.createElement("div");
        createIconContainer.innerHTML = '<i className="fa fa-right-to-bracket h1 mt-3"></i>';
        createIconContainer = createIconContainer.firstChild;

        configErrorFeedback.configSwal["icon"] = null;
        configErrorFeedback.configSwal["content"] = createIconContainer;
        configErrorFeedback.configSwal["buttons"] = {
          login: {
            text: trans("website.sign-in"),
            className: "bg-success",
            closeModal: true,
          },
          forgot: {
            text: trans("website.reset-password"),
            closeModal: true,
          },
        };
        configErrorFeedback.configSwal["callback"] = async (result) => {
          console.log("swal result: ", await result);
          if ((await result) === "login") {
            cb("login");
            //setRedirectLinkFinishedOrder("/auth/sign-in");
            return;
          }
          if ((await result) === "forgot") {
            cb("forgot");
            //setRedirectLinkFinishedOrder("/auth/sign-in/forgot");
            return;
          }
        };
      }
      emitSwal({ message: responseReturn?.message, ...configErrorFeedback });
      standardizeSwal(true);
      return;
    }

    dispatcher(clearCheckout({ data }));

    // if (data.flag_secondary === "NEW_USER") {
    //   cbNewUser(true);
    //   return;

    if (data.url.includes("contract-payment")) {
      let tokenToRedirect = data.url.split("/")[1];

      setRedirectLinkFinishedOrder(`/crm/contract-payment/${tokenToRedirect}`);
    }

    if (data.url.includes("orders")) {
      console.log("going to orders list...");
      let tokenToRedirect = data.url.split("/")[2];

      setRedirectLinkFinishedOrder(`/crm/orders/${tokenToRedirect}`);
    }
    if (data.url.includes("email")) {
    }
  }

  async function getShippingValidation(data) {
    console.log("getShippingValidation...");
    let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_SHIPPING_VALIDATION)
      .addSessionState(sessionNow?.session ?? {})
      .api();

    let copyToBeValidated = { ...data };
    if (!copyToBeValidated?.shipping_methods) {
      copyToBeValidated["shipping_methods"] = JSON.stringify(availableShippings?.map((avsh) => avsh.id));
    }

    if (getSeller?.seller) {
      beforeReq = beforeReq.appendQuery({ seller: getSeller.seller });
    }

    if (sessionNow?.user?.id) {
      beforeReq.authed({ tk: "Bearer " + sessionNow.token });
    }

    const response = await beforeReq.post({ data: copyToBeValidated });

    if (!response.success) {
      setValidations((s) => {
        return { ...s, ...response.errors };
      });
    }
    return response;
  }

  async function getMunicipalities(districtId) {
    console.log("getMunicipalities...");
    const response = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_MUNICIPALITIES_LIST)
      .authed({ tk: "Bearer " + sessionNow.token })
      .appendQuery({
        district_id: districtId,
      })
      .api()
      .get();

    setMunicipalities([]);
    setParishes([]);
    setMunicipalities(response.data);
  }

  async function getParishes(municipalityId) {
    console.log("getParishes...");
    const response = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_PARISHES_LIST)
      .authed({ tk: "Bearer " + sessionNow.token })
      .appendQuery({
        municipality_id: municipalityId,
      })
      .api()
      .get();
    setParishes([]);
    setParishes(response.data);
  }

  async function applyCoupon(data, couponActual) {
    console.log("Setting coupon...");
    let copyOldCouponValue;

    if (couponActual?.id) {
      setPayNow(payNow + couponActual?.value);
    }

    let beforeReq = HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.ONE_CHECKOUT_COUPON)
      .authed({ tk: "Bearer " + sessionNow.token })
      .addSessionState(sessionNow?.session ?? {})
      .withLanguage(ebsssLayout?.activeLanguage)
      .api();

    if (getSeller?.seller) {
      beforeReq = beforeReq.appendQuery({ seller: getSeller.seller });
    }

    if (sessionNow?.user?.id) {
      beforeReq.authed({ tk: "Bearer " + sessionNow.token });
    }

    const response = await beforeReq.post({ data });

    console.log("after apply coupon: ", response);

    if (response?.data?.success) {
      setPayNow(Number(parseFloat(response?.data?.totalValue)));
    }
    return response;
  }

  if (redirectLinkFinishedOrder) {
    console.log("REDIRECTING TO: " + process.env.PUBLIC_URL + redirectLinkFinishedOrder);
    return <Navigate to={process.env.PUBLIC_URL + redirectLinkFinishedOrder} />;
  }

  if (redirectEmptyCart) {
    console.log(sessionNow);
    return <Navigate to={process.env.PUBLIC_URL + "/crm/products?page=1&resetCart=1"} />;
  }

  return (
    <CartContext.Provider
      value={Object.assign(
        {},
        {
          availableShippings,
          translatedShippings,
          countries,
          cartState,
          districts,
          onlyTakeAway,
          allowTakeAway,
          showShipping,
          sysMultiSellers,
          payNow,
          total,
          billingInformation,
          availableCoupons,
          requirePaymentMethodBeforeGenerateOrder,
          translatedPaymentRequestTypes,
          paymentMethods,
          translatedPaymentMethods,
          paymentMethodsSetted,
          paymentDirectToSeller,
          cartItemTypes,
          toursLat,
          toursLon,
          currencySymbol,
          shippingCost,
          errs,
          steps,
          validations,
          municipalities,
          parishes,
          canOrderWithoutLogin,
          createAccountAfterPurchase,
          setValidations,
          getMunicipalities,
          getParishes,
          setOrderSession,
          getShippingValidation,
          handleNext,
          handlePrevious,
          handleStepClick,
          currentStep,
          applyCoupon,
          saveOrder,
          ttlToExpire,
          removeItem,
          tenantConfig,
          fetchCartState,
          setShippingCost,
          setReasonable,
          requiredInputs,
          showCartImages,
          alreadySellerDataFetched,
          setAlreadySellerDataFetched,
        }
      )}
    >
      {cartLoaded ? errs ? <FallBackMessage message={errs} /> : children : <EBSSSLoading />}
    </CartContext.Provider>
  );
};

/////////

const CartTimer = () => {
  const { trans } = useEBSSSLayout();

  const { ttlToExpire, fetchCartState } = useContext(CartContext);
  const [expTimer, setExpTimer] = useState(ttlToExpire);

  useEffect(() => {
    if (expTimer !== null) {
      const interval = setInterval(() => {
        setExpTimer((prevTime) => {
          if (prevTime <= 1000) {
            clearInterval(interval);
            return 0;
          }
          return prevTime - 1000;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (expTimer <= 0) {
      window.location.reload();
    }
  }, [expTimer]);

  return <span>{formatTimer(expTimer)}</span>;
};

const formatNumber = (number) => Number(number).toFixed(2);
const getDuration = (validate) => {
  let matches = validate.match(/\d+/g);
  let number = matches.join("");
  let freq = validate.replace(number, "").trim();
  return freq;
};

const PriceDetail = ({ item, currencySymbol }) => {
  const { trans } = useEBSSSLayout();

  return (
    <div className="price-detail">
      {item?.rent_end_at && item?.rent_start_at ? (
        <div className="d-flex flex-column mb-2">
          <span style={CSS({ fontSize: 14 })}>
            <i className="fas fa-arrow-down text-success"></i> {trans("website.rent-start-at")}: <b>{item?.rent_start_at.substring(0, 16)}</b>
          </span>
          <span style={CSS({ fontSize: 14 })}>
            <i className="fas fa-arrow-up text-danger"></i> {trans("website.rent-end-at")}: <b>{item?.rent_end_at.substring(0, 16)}</b>
          </span>
        </div>
      ) : (
        <></>
      )}

      {item?.rent_people && item?.rent_people > 1 ? (
        <div className="d-flex flex-column mb-2">
          {item?.rent_object?.participants && Array.isArray(item?.rent_object.participants) && item?.rent_object.participants.length > 0 ? (
            item?.rent_object.participants.map((participant, index) => (
              <span key={index} style={CSS({ fontSize: 14 })}>
                <i className="fas fa-user text-secondary"></i> {participant.ageRangeParentName}: <b>{participant.quantity}</b>
              </span>
            ))
          ) : (
            <span style={CSS({ fontSize: 14 })}>
              <i className="fas fa-user text-secondary"></i> {trans("website.rent-people-count")}: <b>{item?.rent_people}</b>
            </span>
          )}
        </div>
      ) : (
        <></>
      )}

      {item?.extras &&
        item?.extras.map((extra, index) => (
          <div style={CSS({ fontSize: 14 })} key={index} className="d-flex flex-column">
            <div className="d-flex flex-row">
              <span>
                <i className="fas fa-plus text-primary"></i> {extra.title}
                :&nbsp;
              </span>
              {Number(extra.cost) ? (
                <div className="d-flex flex-row">
                  <span>
                    <strong>&nbsp;{extra.price_to_show}&nbsp;</strong>
                  </span>{" "}
                  {extra.charge_per_quantity ? (
                    <span className="h-100 badge rounded-pill bg-light text-dark" style={CSS({ border: "0.5px solid green" })}>
                      {trans("website.entity-price-per-" + item?.p?.rental_type)}
                    </span>
                  ) : (
                    ""
                  )}
                  &nbsp;
                  {extra.charge_per_person ? (
                    <span className="h-100 badge rounded-pill bg-light text-dark" style={CSS({ border: "0.5px solid green" })}>
                      {trans("website.entity-extra-price-per-person")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <span className="h-100 badge bg-success rounded-pill text-dark" style={CSS({ border: "0.5px solid green" })}>
                  {trans("website.free")}
                </span>
              )}
            </div>
          </div>
        ))}

      {item?.installation_fee && item?.installation_fee > 0 ? (
        <div style={CSS({ fontSize: 14 })} className="d-flex">
          <span>
            <i className="fas fa-plus text-primary"></i> {trans("website.installation_fee")}:&nbsp;
          </span>
          <span>
            <strong>
              {currencySymbol}&nbsp;{item?.installation_fee}&nbsp;
            </strong>
          </span>
        </div>
      ) : (
        <></>
      )}

      {item?.p?.contract_term_id ? (
        <div style={CSS({ fontSize: 14 })} className="d-flex">
          <a
            target="_blank"
            href={window.location.origin + "/crm/product/contract-term/" + item?.p?.token}
            className="link-primary text-decoration-none"
            rel="noreferrer"
          >
            <i className="fa fa-file"></i> {trans("website.see_contract")}
          </a>
        </div>
      ) : (
        <></>
      )}

      {item?.contract_validate ? (
        <div style={CSS({ fontSize: 14 })} className="d-flex">
          <span>
            <i className="fas fa-plus text-primary"></i> {trans("website.contract-vigency")}:&nbsp;
          </span>
          <span>
            <strong>
              &nbsp;
              {trans("website.calendar." + getDuration(item?.contract_validate))}
              &nbsp;
            </strong>
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const GEOAutoCompleteInput = ({
  onPlaceSelected,
  billingDetails,
  setBillingDetails,
  setOrderSession,
  validateShipping,
  setValidateShipping,
  typedSearch,
  setTypedSearch,
}) => {
  const { trans } = useEBSSSLayout();

  function initMap() {
    let inputLocationSearch = document.getElementById("location-search");
    let map = new google.maps.Map(document.getElementById("map"));
    let autocomplete = new google.maps.places.Autocomplete(inputLocationSearch);

    autocomplete.addListener("place_changed", function () {
      let place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      setTypedSearch(place.formatted_address);
      map.setCenter(place.geometry.location);
      map.setZoom(15);

      let marker = new google.maps.Marker({
        map: map,
        position: place.geometry.location,
        draggable: true,
      });

      google.maps.event.addListener(marker, "dragend", function () {
        let geocoder = new google.maps.Geocoder();
        let lat = marker.getPosition().lat();
        let lng = marker.getPosition().lng();

        let latlng = new google.maps.LatLng(lat, lng);

        geocoder.geocode({ location: latlng }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              let copyBillDetails = { ...billingDetails };
              let copyShippingDetails = { ...validateShipping };
              const newData = {} as any;

              results[0].address_components.forEach((object) => {
                object.types.forEach((name) => {
                  newData[name] = object.long_name;
                  newData[name + "_code"] = object.short_name;
                });
              });

              copyBillDetails["identifier"] = new Date().getTime();
              copyBillDetails["customer_latitude"] = lat;
              copyBillDetails["customer_longitude"] = lng;

              if (newData.country_code) {
                copyBillDetails["customer_country_code"] = newData.country_code;
                copyBillDetails["billing_country"] = newData.country_code;
                copyShippingDetails["country_code"] = newData.country_code;
              }

              if (newData.locality) {
                copyBillDetails["customer_locality"] = newData.locality;
                copyShippingDetails["locality"] = newData.locality;
              }

              if (newData.administrative_area_level_1) {
                copyBillDetails["customer_state"] = newData.administrative_area_level_1;
                copyShippingDetails["state_code"] = newData.administrative_area_level_1;
              }

              if (newData.route) {
                copyBillDetails["billing_address"] = newData.route;
              }

              if (newData.street_address) {
                copyBillDetails["billing_address"] = newData.street_address;
              }

              if (newData.street_number) {
                copyBillDetails["billing_address"] = copyBillDetails["billing_address"] + " " + newData.street_number;
              }

              if (newData.sublocality) {
                copyBillDetails["billing_address_2"] = newData.sublocality;
              }

              if (newData.administrative_area_level_2) {
                copyBillDetails["billing_city"] = newData.administrative_area_level_2;
              }

              if (newData.postal_code) {
                copyBillDetails["billing_postal_code"] = newData.postal_code;
                copyBillDetails["billing_postal_code_fixed"] = newData.postal_code;
              }

              if (newData.administrative_area_level_1) {
                copyBillDetails["billing_state"] = newData.administrative_area_level_1;
              }

              if (newData.administrative_area_level_1) {
                copyBillDetails["billing_state"] = newData.administrative_area_level_1;
              }

              if (newData.administrative_area_level_1_code) {
                copyBillDetails["billing_state"] = newData.administrative_area_level_1_code + " - " + newData.administrative_area_level_1;
              }

              setBillingDetails(copyBillDetails);
              setValidateShipping(copyShippingDetails);
            } else {
              console.log("No results found");
            }
          } else {
            console.log("Geocoder failed due to: " + status);
          }
        });
      });
    });
  }

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    focusOnFirstEmptyInput("address-form-global");
  }, [billingDetails.identifier]);

  return (
    <div>
      <input type="text" id="location-search" placeholder={trans("website.customer_location")} defaultValue={typedSearch ?? ""} className="form-control" />
      <div
        id="map"
        style={CSS({
          width: "100%",
          height: 300,
          marginTop: 5,
          borderRadius: 5,
        })}
      ></div>
      <form className="container mt-4" id="address-form-global">
        <div className="d-flex col-12 gap-3">
          <div className="mb-3 w-100">
            <label htmlFor="billing_address" className="form-label">
              {trans("website.billing_address")}: *
            </label>
            <input
              type="text"
              className="form-control"
              id="billing_address"
              value={billingDetails?.billing_address}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  billing_address: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex col-12 gap-3">
          <div className="mb-3 col-5">
            <label htmlFor="billing_address_2" className="form-label">
              {trans("website.billing_address_2")}: *
            </label>
            <input
              type="text"
              className="form-control"
              id="billing_address_2"
              value={billingDetails?.billing_address_2}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  billing_address_2: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-3 w-100">
            <label htmlFor="billing_city" className="form-label">
              {trans("website.billing_city")}: *
            </label>
            <input
              type="text"
              className="form-control"
              id="billing_city"
              value={billingDetails?.billing_city}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  billing_city: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex col-12 gap-3">
          <div className="mb-3 col-4">
            <label htmlFor="billing_postal_code" className="form-label">
              {trans("website.billing_postal_code")}: *
            </label>
            <input
              type="text"
              className="form-control"
              id="billing_postal_code"
              value={billingDetails?.billing_postal_code}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  billing_postal_code: e.target.value,
                  billing_postal_code_fixed: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-3 col-4">
            <label htmlFor="billing_state" className="form-label">
              {trans("website.billing_state")}: *
            </label>
            <input
              type="text"
              className="form-control"
              id="billing_state"
              value={billingDetails?.billing_state}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  billing_state: e.target.value,
                })
              }
            />
          </div>
          <div className="mb-3 w-100">
            <label htmlFor="billing_country" className="form-label">
              {trans("website.billing_country")}: *
            </label>
            <input
              type="text"
              className="form-control"
              id="billing_country"
              value={billingDetails?.billing_country}
              onChange={(e) =>
                setBillingDetails({
                  ...billingDetails,
                  billing_country: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex col-12 gap-3">
          <div className="mb-3 w-100">
            <label className="form-label" htmlFor="notes">
              {trans("website.order-special-notes")}:
            </label>
            <textarea
              id="notes"
              rows={5}
              className="form-control border-primary"
              name="notes"
              placeholder=""
              style={CSS({ resize: "none" })}
              value={billingDetails?.notes}
              onChange={(e) => setBillingDetails({ ...billingDetails, notes: e.target.value })}
            ></textarea>
          </div>
        </div>
      </form>
    </div>
  );
};

const DatePickerInput = ({ message, callback, daysCanBePicked, setDaysCanBePicked, checkPossibleDays }) => {
  const [pickerState, setPickerState] = useState(null);
  const [pickUpDays, setPickUpDays] = useState(null);

  const weekdaysByOrder = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  const getDaysToPickUp = async () => {
    let daysPick = await checkPossibleDays();
    if (daysPick?.length > 0) {
      if (pickUpDays === null) {
        setPickUpDays(daysPick);
        let possibleWeekDays = daysPick?.map((d) => weekdaysByOrder[d.week_day]);
        let minDatePick = new Date();
        let maxDatePick = new Date(new Date().getTime() + getFurthestDay(daysPick).max_days_later * DAY_IN_MS);

        let picker = new Pikaday({
          field: document.getElementById("datepicker"),
          disableDayFn: (d) => {
            return new Date().toDateString() === d.toDateString() || !possibleWeekDays.includes(d.getDay());
          },
        });

        picker.setMinDate(minDatePick);
        picker.setMaxDate(maxDatePick);
        picker.show();
        setPickerState(picker);
      } else {
        pickerState.show();
      }
    }
  };

  useEffect(() => {
    document.getElementById("datepicker").addEventListener("change", (e) => {
      callback(formatToYYYYMMDD(new Date(e.target.value)));
      //document.querySelector('#check-shipping-value').focus()
    });
  }, []);

  return (
    <div
      style={CSS({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "relative",
      })}
    >
      <input
        spellCheck="false"
        autoComplete="off"
        autoCorrect="off"
        style={CSS({
          width: "0%",
          outline: "none",
          border: "none",
          height: "0%",
          position: "absolute",
          backgroundColor: "transparent",
          caretColor: "transparent",
        })}
        type="text"
        id="datepicker"
      />
      <button
        id="days-pickup"
        className="btn btn-outline-primary btn-sm w-100 btn-wait-animated"
        onClick={async () => {
          if (document.querySelector("#days-pickup").className.includes("animate-wait")) {
            return;
          }
          document.querySelector("#days-pickup").disabled = true;
          document.querySelector("#days-pickup").classList.add("animate-wait");
          document.querySelector("#days-pickup").classList.add("btn-primary");
          document.querySelector("#days-pickup").classList.remove("btn-outline-primary");
          await getDaysToPickUp().then(async (r) => {
            document.querySelector("#days-pickup").disabled = false;
            document.querySelector("#days-pickup").classList.remove("animate-wait");
            document.querySelector("#days-pickup").classList.remove("btn-primary");
            document.querySelector("#days-pickup").classList.add("btn-outline-primary");
          });
        }}
      >
        <i className="fas fa-calendar me-3"></i> {message}
      </button>
    </div>
  );
};

const InvalidInputMessage = ({ message }) => {
  return (
    <p style={CSS({ fontSize: 12 })} className="text-danger">
      {message}
    </p>
  );
};

const ShoppingCart = () => {
  const { trans } = useEBSSSLayout();
  const {
    tenantConfig,
    fetchCartState,
    showCartImages,
    requiredInputs,
    setShippingCost,
    setReasonable,
    saveOrder,
    ttlToExpire,
    availableCoupons,
    steps,
    handleNext,
    handlePrevious,
    handleStepClick,
    currentStep,
    setOrderSession,
    applyCoupon,
    getShippingValidation,
    municipalities,
    parishes,
    getMunicipalities,
    getParishes,
    validations,
    setValidations,
    shippingCost,
    cartState,
    setCartState,
    currencySymbol,
    showShipping,
    billingInformation,
    payNow,
    sysMultiSellers,
    total,
    countries,
    districts,
    allowTakeAway,
    cartItemTypes,
    onlyTakeAway,
    incrementQuantity,
    decrementQuantity,
    removeItem,
    availableShippings,
    translatedShippings,
    requirePaymentMethodBeforeGenerateOrder,
    paymentMethods,
    paymentMethodsSetted,
    translatedPaymentMethods,
    toursLat,
    toursLon,
    translatedPaymentRequestTypes,
    canOrderWithoutLogin,
    createAccountAfterPurchase,
    alreadySellerDataFetched,
    setAlreadySellerDataFetched,
  } = useContext(CartContext);
  const [selectedShippingId, setSelectedShippingId] = useState(0);
  const [selectedShippingObj, setSelectedShippingObj] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [municipalityId, setMunicipalityId] = useState(null);
  const [parishId, setParishId] = useState(null);
  const [districtId, setDistrictId] = useState(null);
  const [phone, setPhone] = useState("");
  const [isShippingValid, setIsShippingValid] = useState(false);
  const [pickUpDay, setPickUpDay] = useState(null);
  const [daysCanBePicked, setDaysCanBePicked] = useState(null);
  const [spinnerFinish, setSpinnerFinish] = useState(false);
  const [spinnerProceed, setSpinnerProceed] = useState(false);
  const [typedSearch, setTypedSearch] = useState("");
  const [paymentMethodsOptions, setPaymentMethodsOptions] = useState([]);
  const [couponText, setCouponText] = useState("");
  const { setIsVertical } = useContext<any>(CustomContext);
  const location = useLocation();
  const [agreement, setAgreement] = useState(null);
  const [showAddressStepUnauthed, setShowAddressStepUnauthed] = useState(false);
  const [hideSuggestionEmail, setHideSuggestionEmail] = useState(false);
  const [endRegistrationCheckoutModal, setEndRegistrationCheckoutModal] = useState(false);
  const [alreadyFilledAutoShippingMethod, setAlreadyFilledAutoShippingMethod] = useState(false);
  const { sidebar_types, settings, defaultClass, setDefaultClass } = useContext<any>(CheckContext);
  const sidebarTypes1 = localStorage.getItem("sidebar_types") || sidebar_types;
  const settings1 = localStorage.getItem("sidebar_Settings") || settings;
  const [resultValidatedShippingMethodChosen, setResultValidatedShippingMethodChosen] = useState(null);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey["AUTH"]]);
  const dispatcher = useDispatch();
  const [billingDetails, setBillingDetails] = useState({
    paymentMethod: "",
    district_id: "",
    mobile_number: "",
    chosen_shipping_type_global_cost: "",
    notes: "",
    "same-address": "1",
    "take-away": "0",
    code: "",
    customer_location: "",
    customer_latitude: "",
    customer_longitude: "",
    customer_distance: "0",
    customer_country_code: "",
    customer_locality: "",
    customer_state: "",
    billing_country: "",
    billing_name: "",
    billing_email: "",
    billing_vat: "",
    billing_postal_code_fixed: "",
    billing_address: "",
    billing_address_2: "",
    billing_state: "",
    billing_city: "",
    billing_postal_code: "",
    shipping_name: "",
    shipping_address: "",
    shipping_address_2: "",
    shipping_country: "",
    shipping_city: "",
    shipping_postal_code: "",
    shipping_state: "",
    shipping_method: "",
    shipping_method_type: "",
  });
  const [lastClickedShippingMethod, setLastClickedShippingMethod] = useState(null);
  const [validateShipping, setValidateShipping] = useState({});
  const [couponDiscount, setCouponDiscount] = useState({
    id: null,
    discount: null,
    value: 0,
    text: "",
  });

  const [authModal, setAuthModal] = useState("");
  const [authModalAlreadyRegistered, setAuthModalAlreadyRegistered] = useState("");
  const [authModalVerifyAccount, setAuthModalVerifyAccount] = useState(false);
  const [priceLoadedMultiseller, setPriceLoadedMultiseller] = useState(true);

  const toggleAuthModal = (type = "login" | "register") => {
    setAuthModal(type);
  };
  const toggleAuthModalAlreadyRegistered = (type = "login" | "forgot") => {
    setAuthModalAlreadyRegistered(type);
  };
  const toggleAuthModalVerifyAccount = (open: boolean) => {
    setAuthModalVerifyAccount(open);
  };

  const handleCheckoutForm = (input, val) => {
    let copyState = { ...billingDetails };
    copyState[input] = val;
    setBillingDetails({ ...copyState });
  };

  const checkPossibleDays = async () => {
    try {
      setValidations({});
      let val = await getShippingValidation(validateShipping);
      return val?.shipping.find((sv) => sv.id === selectedShippingObj.id)?.days ?? [];
    } catch (e) {
      console.log(e);
    }
  };

  const checkLocalValidations = () => {
    let instantValidations = [];
    let objectValidation = {};

    if (!sessionNow?.user?.id && createAccountAfterPurchase && !billingDetails?.create_account_first_name) {
      instantValidations.push(trans("website.form-field-required-first-name"));
      objectValidation["create_account_first_name"] = [trans("website.attribute-required")];
    }

    if (!sessionNow?.user?.id && createAccountAfterPurchase && !billingDetails?.create_account_last_name) {
      instantValidations.push(trans("website.form-field-required-last-name"));
      objectValidation["create_account_last_name"] = [trans("website.attribute-required")];
    }

    if (requiredInputs.vat && !billingDetails.billing_vat) {
      instantValidations.push(trans("website.form-field-required-vat"));
      objectValidation["billing_vat"] = [trans("website.attribute-required")];
    }
    if (requiredInputs.email && !billingDetails.billing_email) {
      instantValidations.push(trans("website.form-field-required-email"));
      objectValidation["billing_email"] = [trans("website.attribute-required")];
    }
    if (requiredInputs.mobile_number && !billingDetails.mobile_number) {
      instantValidations.push(trans("website.form-field-required-phone"));
      objectValidation["mobile_number"] = [trans("website.attribute-required")];
    }
    if (requiredInputs.mobile_number && !billingDetails.mobile_number.includes("+")) {
      instantValidations.push(trans("website.form-field-require-country-phone-code"));
      objectValidation["mobile_number"] = [trans("website.attribute-required")];
      setPhone("");
      setBillingDetails({ ...billingDetails, mobile_number: "" });
    }
    if (!billingDetails.billing_name) {
      instantValidations.push(trans("website.form-field-required-name"));
      objectValidation["billing_name"] = [trans("website.attribute-required")];
    }

    if (instantValidations.length) {
      setValidations({ ...objectValidation });
      toast(instantValidations.join("\n"), { type: "error" });
      if (document.querySelector("#headingOne").querySelector("button").className.includes("collapsed")) {
        document.querySelector("#headingOne").querySelector("button").click();
      }
    }

    return instantValidations.length === 0;
  };

  const checkShippingValidation = async (useNextHandler = false, closeAddress = false) => {
    try {
      setValidations({});

      if (!checkLocalValidations()) {
        return;
      }

      let val;
      if (showShipping) {
        val = await getShippingValidation(validateShipping);
        if (val?.data?.shipping?.length === 0) {
          toast(trans("website.no-shipping-available-for-this-location-and-type"), { type: "error" });
          return;
        }

        if (val.success) {
          setResultValidatedShippingMethodChosen(val?.data?.shipping);

          if (closeAddress && validateShipping.cost_type !== "point") {
            document.querySelector("#accordionAddressCollapsable").click();
          }

          if (useNextHandler) {
            await saveOrder({ ...billingDetails }, toggleAuthModalAlreadyRegistered, toggleAuthModalVerifyAccount);
            return;
          }

          setIsShippingValid(val.success);
          return val;
        }
      } else {
        val = await setOrderSession(billingDetails);

        if (useNextHandler) {
          await saveOrder({ ...billingDetails }, toggleAuthModalAlreadyRegistered, toggleAuthModalVerifyAccount);
          return;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAppliedCoupon = async () => {
    let invalidCoupon = (message) => {
      toast(message ? message : trans("website.sale-invalid-coupon"), {
        type: "error",
      });
    };
    if (!couponText) {
      invalidCoupon();
      return;
    }

    setCouponDiscount({
      id: null,
      discount: null,
      value: 0,
      text: "",
    });

    if (!availableCoupons) {
      invalidCoupon();
      return;
    }

    let res = await applyCoupon(
      {
        ...billingDetails,
        code: couponText,
      },
      couponDiscount
    );

    if (!res?.data?.success) {
      invalidCoupon(res?.message);
      return;
    }

    setBillingDetails({
      ...billingDetails,
      code: couponText,
    });

    setCouponDiscount({
      text: couponText,
      id: res?.data?.id,
      discount: res?.data?.valueOff,
      value: Number(parseFloat(res?.data?.base)) - Number(parseFloat(res?.data?.totalValue)),
    });

    toast(trans("website.sale-coupon-applied"), { type: "success" });
  };

  const getSeller = useParams();

  useEffect(() => {
    console.log("Available Shipping methods: ", availableShippings);
    setValidateShipping({
      shipping_methods: JSON.stringify(availableShippings?.map((avsh) => avsh.id)),
      cost_type: "",
      country_code: "",
      state_code: "",
      locality: "",
      ...validateShipping,
    });
  }, [availableShippings]);

  useEffect(() => {
    if (!alreadySellerDataFetched && billingDetails?.billing_email && getSeller?.seller) {
      setOrderSession(billingDetails, true).then(() => {
        setAlreadySellerDataFetched(true);
      });
    }
  }, [billingDetails]);

  useEffect(() => {
    let copyShippingDetails = { ...validateShipping };

    if (parishId) {
      copyShippingDetails.civil_parish_id = parishId;
    }
    if (billingDetails.customer_latitude && billingDetails.customer_longitude) {
      copyShippingDetails.latitude = billingDetails.customer_latitude.toString();
      copyShippingDetails.longitude = billingDetails.customer_longitude.toString();
    }

    setValidateShipping(copyShippingDetails);
  }, [parishId, billingDetails.customer_latitude, billingDetails.customer_longitude]);

  useEffect(() => {
    let fillAuthedData = setInterval(() => {
      if (sessionNow?.user?.id) {
        let copyBillingDetails = { ...billingDetails };

        copyBillingDetails.billing_vat = !copyBillingDetails?.billing_vat ? sessionNow?.user?.profile?.vat_number ?? "" : copyBillingDetails?.billing_vat;
        copyBillingDetails.billing_name = !copyBillingDetails?.billing_name
          ? (sessionNow?.user?.profile?.first_name ?? "") + " " + (sessionNow?.user?.profile?.last_name ?? "")
          : copyBillingDetails?.billing_name;
        copyBillingDetails.billing_address = !copyBillingDetails?.billing_address
          ? (sessionNow?.user?.profile?.address_line_1 ?? "") + " " + (sessionNow?.user?.profile?.address_line_2 ?? "")
          : copyBillingDetails?.billing_address;
        copyBillingDetails.billing_address_2 = !copyBillingDetails?.billing_address_2
          ? sessionNow?.user?.profile?.administrative_area ?? ""
          : copyBillingDetails?.billing_address_2;
        copyBillingDetails.billing_postal_code = !copyBillingDetails?.billing_postal_code
          ? sessionNow?.user?.profile?.postal_code ?? ""
          : copyBillingDetails?.billing_postal_code;
        copyBillingDetails.billing_postal_code_fixed = !copyBillingDetails?.billing_postal_code_fixed
          ? sessionNow?.user?.profile?.postal_code ?? ""
          : copyBillingDetails?.billing_postal_code_fixed;
        copyBillingDetails.billing_city = !copyBillingDetails?.billing_city ? sessionNow?.user?.profile?.locality ?? "" : copyBillingDetails?.billing_city;
        copyBillingDetails.billing_email = !copyBillingDetails?.billing_email ? sessionNow?.user?.email ?? "" : copyBillingDetails?.billing_email;
        setPhone(!copyBillingDetails?.mobile_number ? sessionNow?.user?.mobile_number ?? "" : copyBillingDetails?.mobile_number);
        setBillingDetails(copyBillingDetails);

        clearInterval(fillAuthedData);
      }
    }, 250);
  }, []);

  useEffect(() => {
    if (billingDetails?.create_account_first_name && billingDetails?.create_account_last_name) {
      setBillingDetails({
        ...billingDetails,
        billing_name: billingDetails?.create_account_first_name + " " + billingDetails?.create_account_last_name,
      });
    }
  }, [billingDetails?.create_account_first_name, billingDetails?.create_account_last_name]);

  useEffect(() => {
    if (phone) {
      setBillingDetails({
        ...billingDetails,
        mobile_number: phone,
      });
    }
  }, [phone]);

  useEffect(() => {
    let copyBillingDetails = { ...billingDetails };

    if (validateShipping.cost_type === "point" && selectedShippingId) {
      copyBillingDetails.billing_country = "181";
      copyBillingDetails.shipping_country = "181";
    }

    if (validateShipping.cost_type !== "point" && selectedShippingId) {
      if (validateShipping.cost_type === "location") {
        //copyBillingDetails.shipping_country = 'PT'
        copyBillingDetails.billing_country = "181";
        copyBillingDetails.shipping_country = "181";
      }

      if (validateShipping.cost_type === "kilometer_route") {
        calculateRouteDistance(
          billingDetails.customer_latitude,
          billingDetails.customer_longitude,
          validateShipping.shipping_latitude,
          validateShipping.shipping_longitude
        );
      }
    }

    if (showShipping && !onlyTakeAway && allowTakeAway && !sysMultiSellers) {
      copyBillingDetails["take-away"] = "0";
    }

    if (onlyTakeAway || !showShipping) {
      copyBillingDetails["take-away"] = "0";
      copyBillingDetails["same-address"] = "1";
    }

    if (pickUpDay) {
      copyBillingDetails = filterKeysWithPrefix(copyBillingDetails, "shipping_method_day_", null, true);
      copyBillingDetails["shipping_method_day_" + selectedShippingObj?.slug] = [
        String(formatToYYYYMMDD(new Date(new Date().getTime() + DAY_IN_MS))),
        String(pickUpDay),
      ];
      setBillingDetails(copyBillingDetails);
      setOrderSession(copyBillingDetails);
    }

    if (couponDiscount.id) {
      copyBillingDetails.code = couponDiscount.text;
    } else {
      copyBillingDetails.code = "";
    }
    setBillingDetails({ ...copyBillingDetails });

    if (validateShipping.cost_type !== "point" && selectedShippingObj && resultValidatedShippingMethodChosen) {
      setBillingDetails({
        ...billingDetails,
        chosen_shipping_type_global_cost: resultValidatedShippingMethodChosen.find((rvsmc) => rvsmc.id === selectedShippingObj?.id)?.location_cost,
      });
      console.log(
        "FINAL SELECTION SHIPPING DETAILED: ",
        resultValidatedShippingMethodChosen.find((rvsmc) => rvsmc.id === selectedShippingObj?.id)
      );
      if (resultValidatedShippingMethodChosen.find((rvsmc) => rvsmc.id === selectedShippingObj.id)?.days?.length > 0) {
        console.log("It has days!!!");
        return;
      }
      setOrderSession({
        ...copyBillingDetails,
        chosen_shipping_type_global_cost: resultValidatedShippingMethodChosen.find((rvsmc) => rvsmc.id === selectedShippingObj?.id)?.location_cost,
      });
    } else {
      if (selectedPoint) {
        setOrderSession({ ...copyBillingDetails });
      }
    }
  }, [
    window.intlTelInput,
    //couponDiscount.id,
    pickUpDay,
    showShipping,
    onlyTakeAway,
    allowTakeAway,
    sysMultiSellers,
    selectedPoint,
    selectedShippingId,
    billingDetails?.identifier,
    selectedShippingObj,
    resultValidatedShippingMethodChosen,
    sessionNow?.user?.id,
  ]);

  useEffect(() => {
    let copyBillingDetails = { ...billingDetails };

    if (districtId) {
      setParishId(null);
      setMunicipalityId(null);
      getMunicipalities(districtId);
      copyBillingDetails.district_id = districtId;
      copyBillingDetails.billing_state = Object.entries(districts)?.find((d) => Number(d[0]) === Number(districtId))[1] ?? "";
      setBillingDetails({ ...copyBillingDetails });
    }
  }, [districtId]);

  useEffect(() => {
    let copyBillingDetails = { ...billingDetails };

    if (municipalityId) {
      setParishId(null);
      getParishes(municipalityId);
      copyBillingDetails.municipality_id = municipalityId;
      copyBillingDetails.billing_city = municipalities?.find((m) => Number(m.id) === Number(municipalityId))?.name ?? "";
      setBillingDetails({ ...copyBillingDetails });
    }
  }, [municipalityId]);

  useEffect(() => {
    let copyBillingDetails = { ...billingDetails };

    if (parishId) {
      copyBillingDetails.civil_parish_id = parishId;
      copyBillingDetails.billing_city = (billingDetails.billing_city ?? "") + " " + (parishes?.find((p) => Number(p.id) === Number(parishId))?.name ?? "");
      setBillingDetails({ ...copyBillingDetails });
    }
  }, [parishId]);

  //Validations
  useEffect(() => {
    console.log("validations: ", validations);
    let unvalidKeys = Object.entries(validations);
    let onlyUnvalidKeys = unvalidKeys.map((k) => k[0]);

    if (onlyUnvalidKeys.some((v) => v === "mobile_number" || v === "billing_name" || v === "billing_email" || v === "billing_vat")) {
      if (document.querySelector("#headingOne").querySelector("button").className.includes("collapsed")) {
        document.querySelector("#headingOne").querySelector("button").click();
      }
    }

    const feedbackElements = document.querySelectorAll('[id^="feedback-"]');
    if (feedbackElements.length > 0) {
      feedbackElements.forEach((ell) => ell.remove());
    }

    unvalidKeys.map((k, ik) => {
      let inputsOfThisKey = document.querySelectorAll("#" + k[0]);
      if (inputsOfThisKey?.length > 0) {
        inputsOfThisKey.forEach((el) => {
          k[1].map((msgK) => {
            let newInvMsg = document.createElement("p");
            newInvMsg.style.fontSize = "12px";
            newInvMsg.style.color = "red";
            newInvMsg.textContent = msgK;
            newInvMsg.id = "feedback-" + k[0] + "-" + msgK;
            if (k[0] === "mobile_number") {
              el.parentNode.after(newInvMsg);
            } else {
              el.after(newInvMsg);
            }
          });
        });
      }
    });
  }, [validations]);

  console.log("Billing Details: ", billingDetails);
  console.log("Billing Coupon: ", couponText, couponDiscount);
  console.log("--------------------------------------------------------");

  useEffect(() => {
    if (paymentMethods && paymentMethods?.length > 0) {
      let getAvailablePaymentOptions = paymentMethods?.filter((pMethod) => {
        let code = pMethod.code;
        return (
          (code === "wallet" && paymentMethodsSetted.wlt_wallet) ||
          (code === "rwd_wallet" && paymentMethodsSetted.rewards) ||
          (code === "delivery" && paymentMethodsSetted.payment_delivery) ||
          (code === "sibs" &&
            paymentMethodsSetted.sibs_terminal_id &&
            paymentMethodsSetted.sibs_client_id &&
            paymentMethodsSetted.sibs_bearer_token &&
            paymentMethodsSetted.sibs_entity) ||
          (code === "mbway" && paymentMethodsSetted.ifthenpay_entity) ||
          (code === "transf" &&
            (paymentMethodsSetted.invoice_transfer_bank || paymentMethodsSetted.invoice_transfer_iban || paymentMethodsSetted.invoice_transfer_swift)) ||
          (code === "mypos" && paymentMethodsSetted.mypos_virtual_production_url) ||
          (code === "paypal" && paymentMethodsSetted.paypal_live_api_username) ||
          (code === "meowallet" && paymentMethodsSetted.meo_api_key) ||
          (code === "pagseg" && paymentMethodsSetted.pagseg_token && paymentMethodsSetted.pagseg_email) ||
          (code === "easypay" && paymentMethodsSetted.easypay_apikey) ||
          (sessionNow?.user?.id && code === "stripe" && paymentMethodsSetted.stripe_key)
        );
      });

      setPaymentMethodsOptions(getAvailablePaymentOptions);

      if (getAvailablePaymentOptions.length === 1) {
        setBillingDetails({
          ...billingDetails,
          paymentMethod: getAvailablePaymentOptions[0].token,
        });
      }
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (availableShippings && availableShippings?.length > 0) {
      let getAvailableShippings = filterRepeatedObjects(
        availableShippings?.map((avs) => {
          if ((avs.custom_delivery === "address" && avs.cost_type) || avs.custom_delivery === "point") {
            return {
              custom_delivery: avs.custom_delivery,
              cost_type: avs.cost_type,
            };
          }
        })
      );

      if (getAvailableShippings.length === 1) {
        console.log("Cleaning it all in available shippings refresher... In \n", selectedShippingObj, "\nbut ", availableShippings);

        if (JSON.stringify([selectedShippingObj]) === JSON.stringify(availableShippings)) {
          console.log("Its equal, stopping reset shippings...");
          return;
        }

        setShippingCost("");
        setSelectedShippingObj(null);
        setSelectedShippingId(0);
        setMunicipalityId(null);
        setParishId(null);
        setDistrictId(null);
        setIsShippingValid(false);
        setTypedSearch("");
        setResultValidatedShippingMethodChosen(null);
        setSelectedPoint(null);
        setPickUpDay(null);
        setDaysCanBePicked(null);
        setCouponDiscount({
          id: null,
          discount: null,
          value: 0,
          text: "",
        });
        setValidateShipping({
          ...validateShipping,
          cost_type: getAvailableShippings[0].cost_type === null ? "point" : getAvailableShippings[0].cost_type,
        });
      }
    }
  }, [availableShippings]);

  useEffect(() => {
    if (
      !alreadyFilledAutoShippingMethod &&
      resultValidatedShippingMethodChosen &&
      availableShippings?.filter((avs) => resultValidatedShippingMethodChosen?.find((rvsmc) => rvsmc.id === avs.id))?.length === 1
    ) {
      let getValidatedShipping = availableShippings?.filter((avs) => resultValidatedShippingMethodChosen?.find((rvsmc) => rvsmc.id === avs.id));

      console.log("getValidatedShipping: > ", getValidatedShipping);

      let shippId = getValidatedShipping[0].id;
      let copyBillingDetails = { ...billingDetails };
      copyBillingDetails.shipping_method_type = getValidatedShipping[0].custom_delivery;
      copyBillingDetails.shipping_method = getValidatedShipping[0].slug;
      setSelectedShippingId(shippId);
      setSelectedShippingObj(getValidatedShipping[0]);
      setBillingDetails(copyBillingDetails);
      setPickUpDay(null);
      setAlreadyFilledAutoShippingMethod(true);
      checkShippingValidation();
    }
  }, [resultValidatedShippingMethodChosen]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      let actualWidthClient = document.body.clientWidth;

      window.addEventListener("resize", () => {
        actualWidthClient = document.body.clientWidth;
      });

      let stickyElem = document.querySelector("#resume-checkout-1");
      let stickyElem2 = document.querySelector("#resume-checkout-2");
      let containerStickElem = document.querySelector("#resume-checkout-container-1");
      let containerStickElem2 = document.querySelector("#resume-checkout-container-2");
      let currStickyPos;

      if (stickyElem && containerStickElem) {
        currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset;
      } else {
        currStickyPos = stickyElem2.getBoundingClientRect().top + window.pageYOffset;
      }

      window.onscroll = function () {
        if (stickyElem && containerStickElem) {
          if (actualWidthClient < 973) {
            stickyElem.style.width = "100%";
            stickyElem.style.position = "relative";
            return;
          }
          if (window.pageYOffset > currStickyPos) {
            stickyElem.style.position = "sticky";
            stickyElem.style.top = "105px";
          } else {
            stickyElem.style.position = "relative";
            stickyElem.style.top = "0px";
            stickyElem.style.width = "100%";
          }
        } else {
          if (actualWidthClient < 973) {
            stickyElem2.style.width = "100%";
            stickyElem2.style.position = "relative";
            return;
          }
          if (window.pageYOffset > currStickyPos) {
            stickyElem2.style.position = "sticky";
            stickyElem2.style.top = "105px";
          } else {
            stickyElem2.style.position = "relative";
            stickyElem2.style.top = "0px";
            stickyElem2.style.width = "100%";
          }
        }
      };
    }
  }, [window]);

  useEffect(() => {
    if (sessionNow?.user?.id && authModal === "login") {
      console.log("CHECKING TO RELOAD...");
      window.location.reload();
      // dispatcher(
      //   fetchLangs({
      //     activeLanguage: ebsssLayout?.activeLanguage,
      //     token: "Bearer " + sessionNow?.token,
      //   })
      // );
    }
  }, [sessionNow?.user?.id]);

  const [availableEmail, setAvailableEmail] = useState(null);

  const [loadingEmailCheck, setLoadingEmailCheck] = useState(false);

  const [emailConfirmation, setEmailConfirmation] = useState("");

  const debounceTimeoutRef = useRef(null);

  const handleEmailCheck = async (emailToCheck) => {
    setLoadingEmailCheck(true);
    const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CHECK_EMAIL)
      .api()
      .post({
        data: { email: emailToCheck },
      });

    console.log(fetch);
    if (fetch?.data || fetch?.errors) {
      setLoadingEmailCheck(false);
      setAvailableEmail(fetch?.data ? fetch?.data?.available : fetch?.errors.available);
    }
  };
  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  const handleChangeBouncer = (value: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(async () => {
      await handleEmailCheck(billingDetails.billing_email);
    }, 2500);
  };

  useEffect(() => {
    if (billingDetails.billing_email && billingDetails.billing_email.includes("@") && billingDetails.billing_email.includes(".")) {
      handleChangeBouncer(billingDetails.billing_email);
    }
  }, [billingDetails.billing_email]);

  const [mask, setMask] = useState("*********"); // Default mask

  useEffect(() => {
    updatePostCodeMask(countries.find((c) => c.id === billingDetails.billing_country)?.code?.toUpperCase(), setMask);
  }, [billingDetails.billing_country]);

  return (
    <div className={`page-wrapper ${!defaultClass ? sidebarTypes1 : "compact-wrapper"} ${settings1}`} id="pageWrapper">
      <div className={`page-header`} style={CSS({ width: "100%", marginLeft: 0 })}>
        <Header isCheckoutPage={true} />
      </div>
      <div className="page-body-wrapper">
        <main
          style={CSS({
            backgroundColor: "#F4F5FA",
            minHeight: "100vh",
            flexDirection: "column",
            gap: 20,
            display: "flex",
            alignItems: "center",
            marginTop: 50,
          })}
        >
          <section style={CSS({ width: "90%" })}>
            <div className="pb-5 h-100">
              <div className="d-block py-3 m-auto">
                <Stepper currentStep={currentStep} steps={steps} onStepClick={handleStepClick} />
              </div>
              <div className="row d-flex justify-content-center h-100">
                <div className="col">
                  {currentStep === 1 ? (
                    <div className="card border-0 shadow-sm" style={CSS({ borderRadius: 5, minHeight: 600 })}>
                      <div className="card-body p-4">
                        <div className="row">
                          <div className="col-12 col-lg-7" id="data-checkout-1">
                            <div
                              className="d-flex justify-content-between align-items-center mb-4 p-3 shadow-sm"
                              style={CSS({
                                borderRadius: 5,
                                backgroundColor: "#e9e9e950",
                              })}
                            >
                              <div>
                                <p className="mb-1 text-start">{trans("website.checkout")}</p>
                                <p className="mb-0 text-muted">
                                  {trans("website.total-qtd-products")}: {cartState.length}
                                </p>
                              </div>
                            </div>
                            <div
                              style={CSS({
                                overflowY: "auto",
                                maxHeight: "500px",
                                minHeight: "150px",
                                paddingRight: 20,
                              })}
                            >
                              {cartState?.map((item) => (
                                <Fragment key={item?.p?.id}>
                                  <CartItem
                                    removeItem={removeItem}
                                    showCartImages={showCartImages}
                                    setReasonable={setReasonable}
                                    item={item}
                                    currencySymbol={currencySymbol}
                                    incrementQuantity={incrementQuantity}
                                    decrementQuantity={decrementQuantity}
                                    removeItem={removeItem}
                                    fetchCartState={fetchCartState}
                                    isPreCheckout={false}
                                    activeLang={ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT"}
                                    paymentRequestTypeTrans={translatedPaymentRequestTypes.find((tprt) => tprt.hash === item.hash)?.pay_type}
                                  />
                                </Fragment>
                              ))}
                            </div>
                            <div
                              className="d-flex justify-content-between align-items-center mt-4 p-3 shadow-sm"
                              style={CSS({
                                borderRadius: 5,
                                backgroundColor: "#e9e9e950",
                              })}
                            >
                              <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                <div>
                                  <p className="mb-1 text-start">{trans("website.billing-address")}</p>
                                  <p className="mb-0 text-muted">{trans("website.order-shipping-address")}</p>
                                </div>
                                {(sessionNow?.user?.id &&
                                  sessionNow?.registeredFromCheckout &&
                                  !endRegistrationCheckoutModal &&
                                  !sessionNow?.user?.newly_accepted_terms) ||
                                !sessionNow?.user?.id ? (
                                  <div className="d-flex flex-row align-items-center justify-content-center gap-3 mb-3 mt-2">
                                    <div>
                                      <EBSSSModal
                                        hideFooter
                                        modalTitle={trans("website.login")}
                                        modal={authModal === "login"}
                                        toggle={() => toggleAuthModal("")}
                                        triggerEl={
                                          <Btn
                                            attrBtn={{
                                              onClick: () => toggleAuthModal("login"),
                                              color: "light",
                                              className: "btn text-primary bg-light border-2 border-primary",
                                            }}
                                          >
                                            <span>{trans("website.login")}</span>
                                          </Btn>
                                        }
                                      >
                                        <LoginForm outOriginalPage={true} />
                                      </EBSSSModal>
                                    </div>
                                    <div>
                                      <EBSSSModal
                                        modalSize={sessionNow?.registeredFromCheckout && !sessionNow?.user?.newly_accepted_terms ? "modal-lg" : "modal-md"}
                                        hideFooter
                                        dismissable={
                                          (!sessionNow?.redirect?.signupToken && sessionNow?.redirect?.signupPassword) ||
                                          (!sessionNow?.redirect?.signupToken && !sessionNow?.redirect?.signupPassword && !sessionNow?.registeredFromCheckout)
                                        }
                                        customConfirmAction={async () => {}}
                                        modalTitle={trans("website.register")}
                                        modal={authModal === "register"}
                                        toggle={() => toggleAuthModal("")}
                                        triggerEl={
                                          <Btn
                                            attrBtn={{
                                              onClick: () => toggleAuthModal("register"),
                                              color: "primary",
                                              className: "btn btn-primary",
                                            }}
                                          >
                                            <span>{trans("website.register")}</span>
                                          </Btn>
                                        }
                                      >
                                        <RegisterForm
                                          outOriginalPage={true}
                                          setEndRegistrationCheckoutModal={setEndRegistrationCheckoutModal}
                                          callbackForm={(d) => {
                                            setBillingDetails({
                                              ...billingDetails,
                                              billing_email: d.email,
                                              billing_name: d.first_name + " " + d.last_name,
                                              mobile_number: d.mobile_number,
                                            });
                                          }}
                                        />
                                      </EBSSSModal>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {!sessionNow?.user?.id ? (
                                  authModalAlreadyRegistered === "login" ? (
                                    <EBSSSModal
                                      hideFooter
                                      modalTitle={trans("website.login")}
                                      modal={authModalAlreadyRegistered === "login"}
                                      toggle={() => toggleAuthModalAlreadyRegistered("")}
                                    >
                                      <LoginForm outOriginalPage={true} />
                                    </EBSSSModal>
                                  ) : authModalAlreadyRegistered === "forgot" ? (
                                    <EBSSSModal
                                      hideFooter
                                      modalTitle={trans("website.forgot")}
                                      modal={authModalAlreadyRegistered === "forgot"}
                                      toggle={() => toggleAuthModalAlreadyRegistered("")}
                                    >
                                      <PasswordForm outOriginalPage={true} emailRecovery={billingDetails?.billing_email} />
                                    </EBSSSModal>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                                {authModalVerifyAccount ? (
                                  <EBSSSModal
                                    hideFooter
                                    dismissable={false}
                                    modalTitle={trans("website.verify")}
                                    modal={authModalVerifyAccount}
                                    toggle={() => toggleAuthModalVerifyAccount(false)}
                                  >
                                    <RegisterForm outOriginalPage={true} startAtTokenVerify={true} />
                                  </EBSSSModal>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div
                              className="mt-3 stepper"
                              style={CSS({
                                width: "90%",
                                margin: "auto",
                                marginLeft: 50,
                              })}
                            >
                              {sessionNow?.user?.id || showAddressStepUnauthed ? (
                                <div className="accordion" id="accordionExample">
                                  <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                        data-step="1"
                                      >
                                        {trans("website.tab-order-billing")}
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseOne"
                                      className="accordion-collapse collapse show"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#accordionExample"
                                    >
                                      {sessionNow?.user?.id ? (
                                        <div className="accordion-body">
                                          <div class="card">
                                            <h5 class="card-header pb-0 mb-0">
                                              <div className="d-flex justify-content-between">
                                                {sessionNow?.user.profile.avatar ? (
                                                  <Image
                                                    attrImage={{
                                                      width: "50px",
                                                      height: "50px",
                                                      className: "img-fluid",
                                                      style: CSS({
                                                        borderRadius: 500,
                                                      }),
                                                      alt: "",
                                                      src: `${remotePath.assetsUrl(sessionNow?.user.profile.avatar)}`,
                                                    }}
                                                  />
                                                ) : (
                                                  <EBSSSInitialNameProfile user={sessionNow?.user} width={50} height={50} />
                                                )}
                                                <Link target="_blank" to={process.env.PUBLIC_URL + "/users/userprofile"}>
                                                  <i className="la la-pencil text-danger fs-5"></i>
                                                </Link>
                                              </div>
                                            </h5>
                                            <div class="card-body">
                                              <p>
                                                {trans("website.billing_name") +
                                                  " " +
                                                  sessionNow?.user?.profile?.first_name +
                                                  " " +
                                                  sessionNow?.user?.profile?.last_name}
                                              </p>
                                              <p>{trans("website.billing_email") + " " + sessionNow?.user?.email}</p>
                                              <p>{trans("website.mobile_number") + " " + sessionNow?.user?.mobile_number}</p>
                                              <p>{trans("website.billing_vat") + ": " + sessionNow?.user?.profile?.vat_number}</p>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="accordion-body">
                                          <div className="d-flex flex-column">
                                            <form>
                                              {sessionNow?.user?.id ? (
                                                <EBSSSFormBuilder
                                                  setter={setBillingDetails}
                                                  inputs={[
                                                    {
                                                      type: "row",
                                                      fields: [
                                                        {
                                                          id: "billing_name",
                                                          transKey: "website.billing_name",
                                                          icon: <User />,
                                                          key: "billing_name",
                                                        },
                                                      ],
                                                    },
                                                  ]}
                                                  data={billingDetails}
                                                  trans={trans}
                                                />
                                              ) : // <div className="mb-3">
                                              //   <label htmlFor="billing_name" className="form-label">
                                              //     {trans("website.billing_name")} <span className="text-danger">*</span>
                                              //   </label>
                                              //   <input
                                              //     type="text"
                                              //     className="form-control"
                                              //     value={billingDetails.billing_name}
                                              //     id="billing_name"
                                              //     onChange={(e) => handleCheckoutForm("billing_name", e.target.value)}
                                              //   />
                                              // </div>
                                              createAccountAfterPurchase ? (
                                                <>
                                                  <EBSSSFormBuilder
                                                    setter={setBillingDetails}
                                                    inputs={[
                                                      {
                                                        type: "row",
                                                        fields: [
                                                          {
                                                            id: "create_account_first_name",
                                                            transKey: "auth.first_name",
                                                            icon: <User />,
                                                            key: "create_account_first_name",
                                                          },
                                                          {
                                                            id: "create_account_last_name",
                                                            transKey: "auth.last_name",
                                                            icon: <User />,
                                                            key: "create_account_last_name",
                                                          },
                                                        ],
                                                      },
                                                    ]}
                                                    data={billingDetails}
                                                    trans={trans}
                                                  />
                                                  <div className="row d-none">
                                                    <div className="mb-3 col-md-6">
                                                      <label htmlFor="create_account_first_name" className="form-label">
                                                        {trans("auth.first_name")} <span className="text-danger">*</span>
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        value={billingDetails.create_account_first_name}
                                                        id="create_account_first_name"
                                                        onChange={(e) => handleCheckoutForm("create_account_first_name", e.target.value)}
                                                      />
                                                    </div>
                                                    <div className="mb-3 col-md-6">
                                                      <label htmlFor="create_account_last_name" className="form-label">
                                                        {trans("auth.last_name")} <span className="text-danger">*</span>
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        value={billingDetails.create_account_last_name}
                                                        id="create_account_last_name"
                                                        onChange={(e) => handleCheckoutForm("create_account_last_name", e.target.value)}
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <EBSSSFormBuilder
                                                    setter={setBillingDetails}
                                                    inputs={[
                                                      {
                                                        type: "col",
                                                        fields: [
                                                          {
                                                            id: "billing_name",
                                                            transKey: "website.billing_name",
                                                            icon: <User />,
                                                            key: "billing_name",
                                                          },
                                                        ],
                                                      },
                                                    ]}
                                                    data={billingDetails}
                                                    trans={trans}
                                                  />
                                                  <div className="mb-3 d-none">
                                                    <label htmlFor="billing_name" className="form-label">
                                                      {trans("website.billing_name")} <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={billingDetails.billing_name}
                                                      id="billing_name"
                                                      onChange={(e) => handleCheckoutForm("billing_name", e.target.value)}
                                                    />
                                                  </div>
                                                </>
                                              )}
                                              {sessionNow?.user?.id && sessionNow?.user?.email && billingDetails?.billing_email ? (
                                                <></>
                                              ) : createAccountAfterPurchase ? (
                                                <>
                                                  <EBSSSFormBuilder
                                                    setter={setBillingDetails}
                                                    inputs={[
                                                      {
                                                        type: "row",
                                                        fields: [
                                                          {
                                                            type: "email",
                                                            id: "billing_email",
                                                            transKey: "website.billing_email",
                                                            icon: <Mail />,
                                                            key: "billing_email",
                                                            className:
                                                              availableEmail === false
                                                                ? "form-control is-invalid"
                                                                : availableEmail === true
                                                                ? "form-control is-valid"
                                                                : "form-control",
                                                            helper:
                                                              !hideSuggestionEmail &&
                                                              suggestEmailCorrection(billingDetails?.billing_email) &&
                                                              validateEmail(billingDetails?.billing_email) ? (
                                                                <div className="d-flex flex-row align-items-center gap-1">
                                                                  <small>{trans("website.did_you_mean")}</small>
                                                                  <small className="me-1">{suggestEmailCorrection(billingDetails.billing_email ?? "")}?</small>
                                                                  <div className="d-flex flex-row align-items-center gap-3">
                                                                    <i
                                                                      onClick={() => {
                                                                        setBillingDetails({
                                                                          ...billingDetails,
                                                                          billing_email: suggestEmailCorrection(billingDetails.billing_email ?? ""),
                                                                        });
                                                                        setHideSuggestionEmail(true);
                                                                      }}
                                                                      className="la la-check text-success border-bottom border-bottom-2 border-success"
                                                                      style={CSS({
                                                                        cursor: "pointer",
                                                                      })}
                                                                    ></i>
                                                                    <i
                                                                      onClick={() => setHideSuggestionEmail(true)}
                                                                      className="la la-close text-danger border-bottom border-bottom-2 border-danger"
                                                                      style={CSS({
                                                                        cursor: "pointer",
                                                                      })}
                                                                    ></i>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <FeedbackMessages
                                                                  messages={availableEmail === false ? [trans("website.already-registered-email")] : []}
                                                                />
                                                              ),
                                                          },
                                                          {
                                                            type: "email",
                                                            id: "email-confirmation",
                                                            transKey: "website.email-confirmation-repeat",
                                                            icon: <Mail />,
                                                            key: "email-confirmation",
                                                            className:
                                                              emailConfirmation.length > 5 && emailConfirmation.includes("@") && emailConfirmation.includes(".")
                                                                ? emailConfirmation !== billingDetails.billing_email
                                                                  ? "form-control is-invalid"
                                                                  : "form-control is-valid"
                                                                : "form-control",
                                                            onChange: (e) => setEmailConfirmation(e.target.value),
                                                            onPaste: (e) => e.preventDefault(),
                                                          },
                                                        ],
                                                      },

                                                      {
                                                        type: "col",
                                                        fields: [
                                                          {
                                                            transKey: "website.mobile_number",
                                                            icon: <Phone />,
                                                            customEl: (
                                                              <PhoneInput
                                                                className="col"
                                                                inputStyle={CSS({
                                                                  width: "100%",
                                                                  height: 38,
                                                                  display: "flex",
                                                                })}
                                                                countrySelectorStyleProps={{
                                                                  dropdownStyleProps: {
                                                                    style: CSS({
                                                                      zIndex: 999999,
                                                                    }),
                                                                  },
                                                                  buttonStyle: CSS({
                                                                    height: 38,
                                                                  }),
                                                                }}
                                                                id="mobile_number"
                                                                defaultCountry={getCountryCode()}
                                                                value={billingDetails.mobile_number}
                                                                onChange={(phone) => {
                                                                  setPhone(phone.replace("+", ""));
                                                                  setBillingDetails({
                                                                    ...billingDetails,
                                                                    mobile_number: phone.replace("+", ""),
                                                                  });
                                                                }}
                                                              />
                                                            ),
                                                          },
                                                        ],
                                                      },
                                                    ]}
                                                    data={billingDetails}
                                                    trans={trans}
                                                  />
                                                  <div className="row d-none">
                                                    <div className="mb-3 col-12 col-md-6">
                                                      <label htmlFor="billing_email" className="form-label">
                                                        {trans("website.billing_email")} <span className="text-danger">{requiredInputs.email ? "*" : ""}</span>
                                                      </label>
                                                      <input
                                                        type="email"
                                                        className={
                                                          availableEmail === false
                                                            ? "form-control is-invalid"
                                                            : availableEmail === true
                                                            ? "form-control is-valid"
                                                            : "form-control"
                                                        }
                                                        value={billingDetails.billing_email}
                                                        id="billing_email"
                                                        onChange={(e) => handleCheckoutForm("billing_email", e.target.value)}
                                                      />
                                                      {!hideSuggestionEmail &&
                                                      suggestEmailCorrection(billingDetails?.billing_email) &&
                                                      validateEmail(billingDetails?.billing_email) ? (
                                                        <div className="d-flex flex-row align-items-center gap-1">
                                                          <small>{trans("website.did_you_mean")}</small>
                                                          <small className="me-1">{suggestEmailCorrection(billingDetails.billing_email ?? "")}?</small>
                                                          <div className="d-flex flex-row align-items-center gap-3">
                                                            <i
                                                              onClick={() => {
                                                                setBillingDetails({
                                                                  ...billingDetails,
                                                                  billing_email: suggestEmailCorrection(billingDetails.billing_email ?? ""),
                                                                });
                                                                setHideSuggestionEmail(true);
                                                              }}
                                                              className="la la-check text-success border-bottom border-bottom-2 border-success"
                                                              style={CSS({
                                                                cursor: "pointer",
                                                              })}
                                                            ></i>
                                                            <i
                                                              onClick={() => setHideSuggestionEmail(true)}
                                                              className="la la-close text-danger border-bottom border-bottom-2 border-danger"
                                                              style={CSS({
                                                                cursor: "pointer",
                                                              })}
                                                            ></i>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <FeedbackMessages
                                                          messages={availableEmail === false ? [trans("website.already-registered-email")] : []}
                                                        />
                                                      )}
                                                    </div>
                                                    <div className="mb-3 col-12 col-md-6">
                                                      <label htmlFor="billing_email" className="form-label">
                                                        {trans("website.email-confirmation-repeat")}{" "}
                                                        <span className="text-danger">{requiredInputs.email ? "*" : ""}</span>
                                                      </label>
                                                      <input
                                                        className={
                                                          emailConfirmation.length > 5 && emailConfirmation.includes("@") && emailConfirmation.includes(".")
                                                            ? emailConfirmation !== billingDetails.billing_email
                                                              ? "form-control is-invalid"
                                                              : "form-control is-valid"
                                                            : "form-control"
                                                        }
                                                        type="email"
                                                        onPaste={(e) => {
                                                          e.preventDefault();
                                                        }}
                                                        value={emailConfirmation}
                                                        id="email-confirmation"
                                                        onChange={(e) => setEmailConfirmation(e.target.value)}
                                                      />
                                                      {
                                                        <FeedbackMessages
                                                          messages={
                                                            emailConfirmation.length > 5 && emailConfirmation.includes("@") && emailConfirmation.includes(".")
                                                              ? emailConfirmation !== billingDetails.billing_email
                                                                ? [trans("website.email-are-not-equal")]
                                                                : []
                                                              : ""
                                                          }
                                                        />
                                                      }
                                                    </div>
                                                    <div className="mb-3 col-12 col-lg-6">
                                                      <label htmlFor="mobile_number" className="form-label">
                                                        {trans("website.mobile_number")}{" "}
                                                        <span className="text-danger">{requiredInputs.mobile_number ? "*" : ""}</span>
                                                      </label>
                                                      <PhoneInput
                                                        className="col"
                                                        inputStyle={CSS({
                                                          width: "100%",
                                                          height: 38,
                                                          display: "flex",
                                                        })}
                                                        countrySelectorStyleProps={{
                                                          dropdownStyleProps: {
                                                            style: CSS({
                                                              zIndex: 999999,
                                                            }),
                                                          },
                                                          buttonStyle: CSS({
                                                            height: 38,
                                                          }),
                                                        }}
                                                        id="mobile_number"
                                                        defaultCountry={getCountryCode()}
                                                        value={billingDetails.mobile_number}
                                                        onChange={(phone) => {
                                                          setPhone(phone.replace("+", ""));
                                                          setBillingDetails({
                                                            ...billingDetails,
                                                            mobile_number: phone.replace("+", ""),
                                                          });
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <div></div>
                                              )}
                                              <div className="d-flex row">
                                                {createAccountAfterPurchase ? (
                                                  <></>
                                                ) : (
                                                  <div className="mb-3 col-12 col-md-6">
                                                    <label htmlFor="billing_email" className="form-label">
                                                      {trans("website.billing_email")} <span className="text-danger">{requiredInputs.email ? "*" : ""}</span>
                                                    </label>
                                                    <input
                                                      type="email"
                                                      className="form-control"
                                                      value={billingDetails.billing_email}
                                                      id="billing_email"
                                                      onChange={(e) => handleCheckoutForm("billing_email", e.target.value)}
                                                    />
                                                    {!hideSuggestionEmail &&
                                                    suggestEmailCorrection(billingDetails?.billing_email) &&
                                                    validateEmail(billingDetails?.billing_email) ? (
                                                      <div className="d-flex flex-row align-items-center gap-1">
                                                        <small>{trans("website.did_you_mean")}</small>
                                                        <small className="me-1">{suggestEmailCorrection(billingDetails.billing_email ?? "")}?</small>
                                                        <div className="d-flex flex-row align-items-center gap-3">
                                                          <i
                                                            onClick={() => {
                                                              setBillingDetails({
                                                                ...billingDetails,
                                                                billing_email: suggestEmailCorrection(billingDetails.billing_email ?? ""),
                                                              });
                                                              setHideSuggestionEmail(true);
                                                            }}
                                                            className="la la-check text-success border-bottom border-bottom-2 border-success"
                                                            style={CSS({
                                                              cursor: "pointer",
                                                            })}
                                                          ></i>
                                                          <i
                                                            onClick={() => setHideSuggestionEmail(true)}
                                                            className="la la-close text-danger border-bottom border-bottom-2 border-danger"
                                                            style={CSS({
                                                              cursor: "pointer",
                                                            })}
                                                          ></i>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                )}
                                                {!createAccountAfterPurchase ? (
                                                  <div className="mb-3 col-12 col-lg-6">
                                                    <label htmlFor="mobile_number" className="form-label">
                                                      {trans("website.mobile_number")}{" "}
                                                      <span className="text-danger">{requiredInputs.mobile_number ? "*" : ""}</span>
                                                    </label>
                                                    <PhoneInput
                                                      className="col"
                                                      inputStyle={CSS({
                                                        width: "100%",
                                                        height: 38,
                                                        display: "flex",
                                                      })}
                                                      countrySelectorStyleProps={{
                                                        dropdownStyleProps: {
                                                          style: CSS({
                                                            zIndex: 999999,
                                                          }),
                                                        },
                                                        buttonStyle: CSS({
                                                          height: 38,
                                                        }),
                                                      }}
                                                      id="mobile_number"
                                                      defaultCountry={getCountryCode()}
                                                      value={billingDetails.mobile_number}
                                                      onChange={(phone) => {
                                                        setPhone(phone.replace("+", ""));
                                                        setBillingDetails({
                                                          ...billingDetails,
                                                          mobile_number: phone.replace("+", ""),
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                            </form>
                                            <div className="mb-3 form-check d-none">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={billingDetails["same-address"] === "on"}
                                                id="same-address"
                                                onChange={(e) => handleCheckoutForm("same-address", e.target.checked ? "on" : "")}
                                              />
                                              <label className="form-check-label" htmlFor="same-address">
                                                {[...trans("website.address")]?.map((c, i, a) => (i === 0 && c !== "_" ? c?.toUpperCase() : c))?.join("") +
                                                  " (" +
                                                  trans("website.same_address_to_ship").toLowerCase() +
                                                  ")"}
                                              </label>
                                            </div>
                                            {!billingDetails["same-address"] ? (
                                              <form>
                                                <div className="d-flex col-12 gap-3">
                                                  <div className="mb-3 w-100">
                                                    <label htmlFor="billing_address" className="form-label">
                                                      {trans("website.billing_address")}: *
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id="billing_address"
                                                      value={billingDetails?.billing_address}
                                                      onChange={(e) =>
                                                        setBillingDetails({
                                                          ...billingDetails,
                                                          billing_address: e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="d-flex col-12 gap-3">
                                                  <div className="mb-3 col-5">
                                                    <label htmlFor="billing_address_2" className="form-label">
                                                      {trans("website.billing_address_2")}: *
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id="billing_address_2"
                                                      value={billingDetails?.billing_address_2}
                                                      onChange={(e) =>
                                                        setBillingDetails({
                                                          ...billingDetails,
                                                          billing_address_2: e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-3 w-100">
                                                    <label htmlFor="billing_city" className="form-label">
                                                      {trans("website.billing_city")}: *
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id="billing_city"
                                                      value={billingDetails?.billing_city}
                                                      onChange={(e) =>
                                                        setBillingDetails({
                                                          ...billingDetails,
                                                          billing_city: e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="d-flex col-12 gap-3">
                                                  <div className="mb-3 col-4">
                                                    <label htmlFor="billing_postal_code" className="form-label">
                                                      {trans("website.billing_postal_code")}: *
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id="billing_postal_code"
                                                      value={billingDetails?.billing_postal_code}
                                                      onChange={(e) =>
                                                        setBillingDetails({
                                                          ...billingDetails,
                                                          billing_postal_code: e.target.value,
                                                          billing_postal_code_fixed: e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-3 col-4">
                                                    <label htmlFor="billing_state" className="form-label">
                                                      {trans("website.billing_state")}: *
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id="billing_state"
                                                      value={billingDetails?.billing_state}
                                                      onChange={(e) =>
                                                        setBillingDetails({
                                                          ...billingDetails,
                                                          billing_state: e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                  <div className="mb-3 w-100">
                                                    <label htmlFor="billing_country" className="form-label">
                                                      {trans("website.billing_country")}: *
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id="billing_country"
                                                      value={billingDetails?.billing_country}
                                                      onChange={(e) =>
                                                        setBillingDetails({
                                                          ...billingDetails,
                                                          billing_country: e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </form>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {showShipping ? (
                                    <div className="accordion-item border-0">
                                      <h2 className="accordion-header" id="headingTwo">
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseTwo"
                                          aria-expanded="false"
                                          aria-controls="collapseTwo"
                                          data-step="2"
                                        >
                                          {trans("website.tab-order-shipping")}
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className="accordion-body">
                                          <div className="mb-3 col-12">
                                            {availableShippings?.length > 1 ? (
                                              <div className="mb-3 col-12">
                                                <label htmlFor="shipping_method" className="form-label d-flex flex-row">
                                                  <div className="col-1 align-items-center justify-content-center d-flex">
                                                    <i className="fas fa-truck"></i>
                                                  </div>
                                                  {trans("website.shipping-method")}
                                                </label>
                                                <div className="mega-inline">
                                                  <div className="d-flex col-12 flex-wrap" id="shipping_method">
                                                    {filterRepeatedObjects(
                                                      availableShippings?.map((avs) => {
                                                        if ((avs.custom_delivery === "address" && avs.cost_type) || avs.custom_delivery === "point") {
                                                          return {
                                                            custom_delivery: avs.custom_delivery,
                                                            cost_type: avs.cost_type,
                                                          };
                                                        }
                                                      })
                                                    )?.map((shipM) => {
                                                      if (shipM.custom_delivery === "point") {
                                                        return (
                                                          <div
                                                            key={shipM.custom_delivery}
                                                            className={"col-12 col-lg-6"}
                                                            onClick={() => {
                                                              console.log("CLICKED HERE POINT");
                                                              setLastClickedShippingMethod(JSON.stringify(shipM));
                                                              let e = JSON.stringify(shipM);
                                                              setShippingCost("");
                                                              setSelectedShippingObj(null);
                                                              setSelectedShippingId(0);
                                                              setMunicipalityId(null);
                                                              setParishId(null);
                                                              setDistrictId(null);
                                                              setIsShippingValid(false);
                                                              setTypedSearch("");
                                                              setResultValidatedShippingMethodChosen(null);
                                                              setAlreadyFilledAutoShippingMethod(false);
                                                              setSelectedPoint(null);
                                                              setPickUpDay(null);
                                                              setDaysCanBePicked(null);
                                                              setCouponDiscount({
                                                                id: null,
                                                                discount: null,
                                                                value: 0,
                                                                text: "",
                                                              });
                                                              setValidateShipping({
                                                                ...validateShipping,
                                                                cost_type: JSON.parse(e).cost_type === null ? "point" : JSON.parse(e).cost_type,
                                                              });
                                                            }}
                                                          >
                                                            <Card
                                                              style={CSS({
                                                                width: "95%",
                                                                cursor: "pointer",
                                                              })}
                                                            >
                                                              <Media className="p-20">
                                                                <div className="radio radio-primary me-3">
                                                                  <Input
                                                                    type="radio"
                                                                    id={shipM.token + "-radio-shipping"}
                                                                    name={shipM.token + "-radio-shipping"}
                                                                    checked={lastClickedShippingMethod === JSON.stringify(shipM)}
                                                                  />
                                                                  <Label for={"payment-" + shipM.id}></Label>
                                                                </div>
                                                                <Media body>
                                                                  <H6
                                                                    attrH6={{
                                                                      className: "mt-0 mega-title-badge text-break",
                                                                    }}
                                                                  >
                                                                    {trans("website.shipping-type.point")}
                                                                  </H6>
                                                                </Media>
                                                              </Media>
                                                            </Card>
                                                          </div>
                                                        );
                                                      }
                                                      return (
                                                        <div
                                                          key={shipM.custom_delivery + shipM.cost_type}
                                                          className={"col-12 col-lg-6"}
                                                          onClick={() => {
                                                            console.log("CLICKED HERE OTHER");
                                                            setLastClickedShippingMethod(JSON.stringify(shipM));
                                                            let e = JSON.stringify(shipM);
                                                            setShippingCost("");
                                                            setSelectedShippingObj(null);
                                                            setSelectedShippingId(0);
                                                            setMunicipalityId(null);
                                                            setParishId(null);
                                                            setDistrictId(null);
                                                            setIsShippingValid(false);
                                                            setTypedSearch("");
                                                            setResultValidatedShippingMethodChosen(null);
                                                            setAlreadyFilledAutoShippingMethod(false);
                                                            setSelectedPoint(null);
                                                            setPickUpDay(null);
                                                            setDaysCanBePicked(null);
                                                            setCouponDiscount({
                                                              id: null,
                                                              discount: null,
                                                              value: 0,
                                                              text: "",
                                                            });
                                                            setValidateShipping({
                                                              ...validateShipping,
                                                              cost_type: JSON.parse(e).cost_type === null ? "point" : JSON.parse(e).cost_type,
                                                            });
                                                          }}
                                                        >
                                                          <Card
                                                            style={CSS({
                                                              width: "95%",
                                                              cursor: "pointer",
                                                            })}
                                                          >
                                                            <Media className="p-20">
                                                              <div className="radio radio-primary me-3">
                                                                <Input
                                                                  type="radio"
                                                                  id={shipM.token + "-radio-shipping"}
                                                                  name={shipM.token + "-radio-shipping"}
                                                                  checked={lastClickedShippingMethod === JSON.stringify(shipM)}
                                                                />
                                                                <Label for={"payment-" + shipM.id}></Label>
                                                              </div>
                                                              <Media body>
                                                                <H6
                                                                  attrH6={{
                                                                    className: "mt-0 mega-title-badge text-break",
                                                                  }}
                                                                >
                                                                  {trans("website.shipping-type." + shipM.cost_type ?? "point")}
                                                                </H6>
                                                              </Media>
                                                            </Media>
                                                          </Card>
                                                        </div>
                                                      );
                                                    })}
                                                  </div>
                                                </div>
                                                {/* <select
                                                  className="form-select"
                                                  aria-label="shipping_method"
                                                  id="shipping_method"
                                                  defaultValue={
                                                    validateShipping.cost_type ??
                                                    ""
                                                  }
                                                  onChange={(e) => {
                                                    setShippingCost("");
                                                    setSelectedShippingObj(
                                                      null
                                                    );
                                                    setSelectedShippingId(0);
                                                    setMunicipalityId(null);
                                                    setParishId(null);
                                                    setDistrictId(null);
                                                    setIsShippingValid(false);
                                                    setTypedSearch("");
                                                    setResultValidatedShippingMethodChosen(
                                                      null
                                                    );
                                                    setAlreadyFilledAutoShippingMethod(
                                                      false
                                                    );
                                                    setSelectedPoint(null);
                                                    setPickUpDay(null);
                                                    setDaysCanBePicked(null);
                                                    setCouponDiscount({
                                                      id: null,
                                                      discount: null,
                                                      value: 0,
                                                      text: "",
                                                    });
                                                    setValidateShipping({
                                                      ...validateShipping,
                                                      cost_type:
                                                        JSON.parse(
                                                          e.target.value
                                                        ).cost_type === null
                                                          ? "point"
                                                          : JSON.parse(
                                                              e.target.value
                                                            ).cost_type,
                                                    });
                                                  }}
                                                >
                                                  <option
                                                    key={0}
                                                    value={""}
                                                    disabled
                                                  >
                                                    ---
                                                  </option>
                                                  {filterRepeatedObjects(
                                                    availableShippings?.map(
                                                      (avs) => {
                                                        if (
                                                          (avs.custom_delivery ===
                                                            "address" &&
                                                            avs.cost_type) ||
                                                          avs.custom_delivery ===
                                                            "point"
                                                        ) {
                                                          return {
                                                            custom_delivery:
                                                              avs.custom_delivery,
                                                            cost_type:
                                                              avs.cost_type,
                                                          };
                                                        }
                                                      }
                                                    )
                                                  )?.map((shipM) => {
                                                    if (
                                                      shipM.custom_delivery ===
                                                      "point"
                                                    ) {
                                                      return (
                                                        <option
                                                          key={
                                                            shipM.custom_delivery
                                                          }
                                                          value={JSON.stringify(
                                                            shipM
                                                          )}
                                                        >
                                                          {trans(
                                                            "website.shipping-type.point"
                                                          )}
                                                        </option>
                                                      );
                                                    }
                                                    return (
                                                      <option
                                                        key={
                                                          shipM.custom_delivery +
                                                          shipM.cost_type
                                                        }
                                                        value={JSON.stringify(
                                                          shipM
                                                        )}
                                                      >
                                                        {trans(
                                                          "website.shipping-type." +
                                                            shipM.cost_type ??
                                                            "point"
                                                        )}
                                                      </option>
                                                    );
                                                  })}
                                                </select> */}
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {validateShipping.cost_type && validateShipping.cost_type !== "point" ? (
                                              <div className="accordion" id="accordionAddressElement">
                                                <div className="accordion-item">
                                                  <h2 className="accordion-header">
                                                    <button
                                                      id="accordionAddressCollapsable"
                                                      className="w-100 btn p-0"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#accordionAddressCollapse"
                                                      aria-expanded="true"
                                                      aria-controls="accordionAddressCollapse"
                                                      data-step="📍"
                                                    >
                                                      <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                                                        <div className="d-flex flex-row w-100" htmlFor="paymentMethod">
                                                          <label className="form-label d-flex flex-row w-100" htmlFor="paymentMethod">
                                                            <div className="col-1 align-items-center justify-content-center d-flex">
                                                              <i className="fas fa-map"></i>
                                                            </div>
                                                            {trans("website.insert-data-shipping-address")}:
                                                          </label>
                                                        </div>
                                                        <i
                                                          className="fas fa-chevron-down text-secondary"
                                                          style={CSS({
                                                            fontSize: 12,
                                                            marginRight: 12.5,
                                                          })}
                                                        ></i>
                                                      </div>
                                                    </button>
                                                  </h2>
                                                  <div id="accordionAddressCollapse" className="accordion-collapse collapse show">
                                                    <div>
                                                      {validateShipping?.cost_type === "location" ? (
                                                        <div className="col-lg-12 pl-0 pr-0 mt-3">
                                                          <div className="row address_form address_fixed">
                                                            <div className="col-md-12 mb-1">
                                                              <label htmlFor="billing_country">
                                                                {trans("website.billing_country")}:<small className="red"> *</small>
                                                              </label>
                                                              <select
                                                                className="form-control custom-select d-block w-100"
                                                                id="billing_country"
                                                                name="billing_country"
                                                                defaultValue={"181"}
                                                                disabled
                                                                readOnly
                                                              >
                                                                <option value="181">Portugal</option>
                                                              </select>
                                                            </div>
                                                          </div>

                                                          <div className="address_form address_fixed">
                                                            <div className="row">
                                                              <div className="col-md-5 mb-1">
                                                                <label htmlFor="billing_state">
                                                                  {trans("website.billing_state")}:<small className="red"> *</small>
                                                                </label>
                                                                <select
                                                                  className="form-control"
                                                                  id="district_id"
                                                                  name="district_id"
                                                                  defaultValue={billingDetails.district_id ?? null}
                                                                  onChange={(e) => setDistrictId(e.target.value)}
                                                                >
                                                                  <option key={0} value={null}></option>
                                                                  {Object.entries(districts)?.map((dists) => {
                                                                    return (
                                                                      <option key={JSON.stringify(dists)} value={dists[0]}>
                                                                        {dists[1]}
                                                                      </option>
                                                                    );
                                                                  })}
                                                                </select>
                                                              </div>

                                                              <div className="col-md-7 mb-1">
                                                                <label htmlFor="billing_city">
                                                                  {trans("website.billing_city")}:<small className="red"> *</small>
                                                                </label>
                                                                <select
                                                                  className="form-control"
                                                                  id="municipality_id"
                                                                  name="municipality_id"
                                                                  defaultValue={billingDetails.municipality_id ?? null}
                                                                  onChange={(e) => setMunicipalityId(e.target.value)}
                                                                >
                                                                  <option key={0} value={null}></option>
                                                                  {municipalities?.map((mun) => {
                                                                    return (
                                                                      <option key={JSON.stringify(mun)} value={mun.id}>
                                                                        {mun.name}
                                                                      </option>
                                                                    );
                                                                  })}
                                                                </select>
                                                              </div>
                                                            </div>

                                                            <div className="row">
                                                              <div className="col-md-7 mb-1">
                                                                <label htmlFor="billing_civil_parish">
                                                                  {trans("website.billing_civil_parish")}:<small className="red"> *</small>
                                                                </label>
                                                                <select
                                                                  className="form-control"
                                                                  id="civil_parish_id"
                                                                  name="civil_parish_id"
                                                                  defaultValue={billingDetails.civil_parish_id ?? null}
                                                                  onChange={(e) => setParishId(e.target.value)}
                                                                >
                                                                  <option key={0} value={null}></option>
                                                                  {parishes?.map((par) => {
                                                                    return (
                                                                      <option key={JSON.stringify(par)} value={par.id}>
                                                                        {par.name}
                                                                      </option>
                                                                    );
                                                                  })}
                                                                </select>
                                                              </div>

                                                              <div className="col-md-5 mb-1">
                                                                <label htmlFor="billing_postal_code">
                                                                  {trans("website.billing_postal_code")}:<small className="red"> *</small>
                                                                </label>
                                                                <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  id="billing_postal_code_fixed"
                                                                  name="billing_postal_code_fixed"
                                                                  onChange={(e) => {
                                                                    setBillingDetails({
                                                                      ...billingDetails,
                                                                      billing_postal_code_fixed: e.target.value,
                                                                      billing_postal_code: e.target.value,
                                                                    });
                                                                  }}
                                                                  value={billingDetails.billing_postal_code_fixed}
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="address_form address_fixed address_map address_flex pt-1">
                                                            <div className="mb-1">
                                                              <label htmlFor="billing_address">
                                                                {trans("website.billing_address")}:<small className="red"> *</small>
                                                              </label>
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                id="billing_address"
                                                                name="billing_address"
                                                                maxLength="255"
                                                                onChange={(e) => handleCheckoutForm("billing_address", e.target.value)}
                                                                value={billingDetails.billing_address}
                                                              />
                                                            </div>

                                                            <div className="mb-1">
                                                              <label htmlFor="billing_address_2">{trans("website.billing_address_2")}:</label>
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                id="billing_address_2"
                                                                name="billing_address_2"
                                                                maxLength="255"
                                                                onChange={(e) => handleCheckoutForm("billing_address_2", e.target.value)}
                                                                value={billingDetails.billing_address_2}
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="address_form address_fixed">
                                                            <label htmlFor="notes">{trans("order-special-notes")}:</label>
                                                            <textarea
                                                              id="notes"
                                                              rows={5}
                                                              className="form-control border-primary"
                                                              name="notes"
                                                              placeholder=""
                                                              style={CSS({
                                                                resize: "none",
                                                              })}
                                                              value={billingDetails?.notes}
                                                              onChange={(e) =>
                                                                setBillingDetails({
                                                                  ...billingDetails,
                                                                  notes: e.target.value,
                                                                })
                                                              }
                                                            ></textarea>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <div className="address_form address_map">
                                                          <GEOAutoCompleteInput
                                                            typedSearch={typedSearch}
                                                            setTypedSearch={setTypedSearch}
                                                            onPlaceSelected={(p) => console.log(p)}
                                                            billingDetails={billingDetails}
                                                            setBillingDetails={setBillingDetails}
                                                            setOrderSession={setOrderSession}
                                                            validateShipping={validateShipping}
                                                            setValidateShipping={setValidateShipping}
                                                          />
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {resultValidatedShippingMethodChosen &&
                                            availableShippings?.filter((avs) => resultValidatedShippingMethodChosen?.find((rvsmc) => rvsmc.id === avs.id))
                                              ?.length > 0 ? (
                                              <div className={"mb-3 col-12 " + (validateShipping.cost_type === "point" ? "" : "mt-4")}>
                                                <label className="form-label d-flex flex-row" htmlFor="paymentMethod">
                                                  <div className="col-1 align-items-center justify-content-center d-flex">
                                                    <i className="fas fa-map-pin"></i>
                                                  </div>
                                                  {trans("website.cart-shipping-method-options")}:
                                                </label>
                                                <div className="mega-inline">
                                                  <div className="d-flex col-12 flex-wrap" onClick={() => console.log("youre in location multi selection")}>
                                                    {(validateShipping.cost_type === "point"
                                                      ? availableShippings.filter((avs) =>
                                                          resultValidatedShippingMethodChosen.find((rvsmc) => rvsmc.id === avs.id)
                                                        )
                                                      : resultValidatedShippingMethodChosen
                                                    )?.map((dp) => {
                                                      console.log("DP: ", dp);
                                                      return (
                                                        <div
                                                          key={dp?.id}
                                                          className={"col-12 col-lg-6"}
                                                          onClick={async (e) => {
                                                            let shippId = Number(dp?.id);
                                                            console.log("here clicked: ", dp);
                                                            console.log(resultValidatedShippingMethodChosen.find((avs) => avs.id === shippId));
                                                            let copyBillingDetails = {
                                                              ...billingDetails,
                                                            };
                                                            copyBillingDetails.shipping_method_type = dp?.custom_delivery;
                                                            copyBillingDetails.shipping_method = dp?.slug;
                                                            setSelectedShippingId(shippId);
                                                            setSelectedShippingObj(resultValidatedShippingMethodChosen.find((avs) => avs.id === shippId));
                                                            setBillingDetails(copyBillingDetails);
                                                            setPickUpDay(null);
                                                            await checkShippingValidation();
                                                          }}
                                                        >
                                                          <Card
                                                            style={CSS({
                                                              width: "95%",
                                                              cursor: "pointer",
                                                            })}
                                                          >
                                                            <Media className="p-20">
                                                              <div className="radio radio-primary me-3">
                                                                <Input
                                                                  type="radio"
                                                                  id={dp?.id + "-radio-shipping"}
                                                                  name={dp?.id + "-radio-shipping"}
                                                                  checked={dp?.id === selectedShippingId}
                                                                  key={String(dp?.id)}
                                                                  value={String(dp?.id)}
                                                                />
                                                                <Label for={"shipping-" + dp?.id}></Label>
                                                              </div>
                                                              <Media body>
                                                                <H6
                                                                  attrH6={{
                                                                    className: "mt-0 mega-title-badge text-break",
                                                                  }}
                                                                >
                                                                  {dp.name}
                                                                </H6>
                                                              </Media>
                                                            </Media>
                                                          </Card>
                                                        </div>
                                                      );
                                                    })}
                                                  </div>
                                                </div>
                                                {/* <select
                                                  className="form-select"
                                                  id="paymentMethod"
                                                  name="paymentMethod"
                                                  value={String(
                                                    selectedShippingObj?.id ??
                                                      ""
                                                  )}
                                                  onChange={async (e) => {
                                                    let shippId = Number(
                                                      e.target.value
                                                    );
                                                    let copyBillingDetails = {
                                                      ...billingDetails,
                                                    };
                                                    copyBillingDetails.shipping_method_type =
                                                      availableShippings.find(
                                                        (avs) =>
                                                          avs.id === shippId
                                                      ).custom_delivery;
                                                    copyBillingDetails.shipping_method =
                                                      availableShippings.find(
                                                        (avs) =>
                                                          avs.id === shippId
                                                      ).slug;
                                                    setSelectedShippingId(
                                                      shippId
                                                    );
                                                    setSelectedShippingObj(
                                                      availableShippings.find(
                                                        (avs) =>
                                                          avs.id === shippId
                                                      )
                                                    );
                                                    setBillingDetails(
                                                      copyBillingDetails
                                                    );
                                                    setPickUpDay(null);
                                                    await checkShippingValidation();
                                                  }}
                                                >
                                                  <option
                                                    key={0}
                                                    value={""}
                                                    disabled
                                                  >
                                                    ---
                                                  </option>
                                                  {(validateShipping.cost_type ===
                                                  "point"
                                                    ? availableShippings.filter(
                                                        (avs) =>
                                                          resultValidatedShippingMethodChosen.find(
                                                            (rvsmc) =>
                                                              rvsmc.id ===
                                                              avs.id
                                                          )
                                                      )
                                                    : resultValidatedShippingMethodChosen
                                                  )?.map((dp) => {
                                                    return (
                                                      <option
                                                        key={String(dp.id)}
                                                        value={String(dp.id)}
                                                      >
                                                        {dp.name}
                                                      </option>
                                                    );
                                                  })}
                                                </select> */}
                                                {resultValidatedShippingMethodChosen.find((rvsmc) => rvsmc?.id === selectedShippingObj?.id)?.days?.length >
                                                0 ? (
                                                  <div
                                                    className="address_form address_fixed pt-3"
                                                    style={CSS({
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      gap: 5,
                                                      alignItems: "center",
                                                    })}
                                                  >
                                                    <DatePickerInput
                                                      checkPossibleDays={checkPossibleDays}
                                                      message={trans("website.cart-shipping-method-delivery-day")}
                                                      callback={(t) => setPickUpDay(t)}
                                                      daysCanBePicked={daysCanBePicked}
                                                      setDaysCanBePicked={setDaysCanBePicked}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {resultValidatedShippingMethodChosen && validateShipping.cost_type === "point" && selectedShippingId ? (
                                              <div className="mb-3 col-12 mt-4">
                                                <label className="form-label d-flex flex-row" htmlFor="paymentMethod">
                                                  <div className="col-1 align-items-center justify-content-center d-flex">
                                                    <i className="fas fa-map"></i>
                                                  </div>
                                                  {trans("website.cart-shipping-method-delivery-point")}:
                                                </label>
                                                <div
                                                  className="flex flex-col"
                                                  style={CSS({
                                                    overflowY: "auto",
                                                    maxHeight: 200,
                                                    minHeight: 200,
                                                    border: "1px solid gray",
                                                    borderRadius: 5,
                                                  })}
                                                >
                                                  {selectedShippingObj?.delivery_points?.map((dp) => {
                                                    return (
                                                      <div
                                                        className="w-100 d-flex flex-row"
                                                        style={CSS({
                                                          height: 67,
                                                          cursor: "pointer",
                                                        })}
                                                        key={dp.id}
                                                        onClick={async () => {
                                                          if (!checkLocalValidations()) {
                                                            return;
                                                          }
                                                          let copyBillingDetails = {
                                                            ...billingDetails,
                                                          };

                                                          copyBillingDetails = filterKeysWithPrefix(copyBillingDetails, "shipping_method_point_", null, true);
                                                          copyBillingDetails["shipping_method_point_" + selectedShippingObj.slug] = dp.token;

                                                          setSelectedPoint(dp);
                                                          setBillingDetails(copyBillingDetails);

                                                          await setOrderSession(copyBillingDetails);
                                                        }}
                                                      >
                                                        <div
                                                          className={
                                                            "col-1 ps-2 align-items-center justify-content-center d-flex " +
                                                            `${selectedPoint?.id === dp.id ? "text-success" : "text-secondary"}`
                                                          }
                                                        >
                                                          <i className={selectedPoint?.id === dp.id ? "fas fa-check-circle" : "fas fa-circle"}></i>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                          <span
                                                            className="align-self-center"
                                                            style={CSS({
                                                              fontSize: 12,
                                                            })}
                                                          >
                                                            {new DOMParser().parseFromString(dp.description, "text/html").activeElement.textContent}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {(validateShipping.cost_type && !resultValidatedShippingMethodChosen) || Object.keys(validations).length > 0 ? (
                                              <div className="mb-3 col-12">
                                                <button
                                                  type="button"
                                                  className="m-auto btn btn-sm btn-success btn-block d-flex text-center mb-2 mt-3 shadow-sm"
                                                  style={CSS({
                                                    borderRadius: 5,
                                                    textAlign: "center",
                                                    backgroundColor: "#2d6f44",
                                                    width: "95%",
                                                  })}
                                                  onClick={async () => await checkShippingValidation(false, true)}
                                                >
                                                  <span className="text-center w-100">{trans("website.shipping-methods-options")}</span>
                                                </button>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                          {(() => {
                                            if (showShipping) {
                                            } else if (
                                              !showShipping &&
                                              !onlyTakeAway &&
                                              (cartItemTypes.contract > 0 ||
                                                cartItemTypes.service > 0 ||
                                                cartItemTypes.entities > 0 ||
                                                cartItemTypes.product_service)
                                            ) {
                                              return <hr />;
                                            } else {
                                              return (
                                                <div className="col-sm-12 mt-3 font-large-1 d-none">
                                                  <p>{trans("website.no-shipping-method-in-common-available-for-products-in-cart")}</p>
                                                </div>
                                              );
                                            }
                                          })()}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingThree">
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                        data-step={showShipping ? "3" : "2"}
                                      >
                                        {trans("website.billing-data")}
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseThree"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingThree"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <EBSSSFormBuilder
                                          setter={setBillingDetails}
                                          inputs={[
                                            createAccountAfterPurchase
                                              ? {
                                                  type: "col",
                                                  fields: [
                                                    {
                                                      id: "billing_name",
                                                      transKey: "website.billing_name",
                                                      icon: <User />,
                                                      key: "billing_name",
                                                    },
                                                  ],
                                                }
                                              : {},
                                            {
                                              type: "row",
                                              fields: [
                                                {
                                                  id: "billing_country",
                                                  transKey: "website.billing_country",
                                                  icon: <Globe />,
                                                  key: "billing_country",
                                                  customEl: (
                                                    <Select
                                                      id="billing_country"
                                                      name="billing_country"
                                                      onChange={(e) => {
                                                        console.log(e);
                                                        setBillingDetails({
                                                          ...billingDetails,
                                                          billing_country: e.value,
                                                        });
                                                      }}
                                                      options={countries?.map((c) => {
                                                        return {
                                                          label: c?.country,
                                                          value: c?.id,
                                                        };
                                                      })}
                                                      className="js-example-basic-single"
                                                      styles={{
                                                        input(base, props) {
                                                          return { flex: "1 1 auto" };
                                                        },
                                                      }}
                                                    />
                                                  ),
                                                },
                                                {
                                                  id: "billing_vat",
                                                  transKey: "website.billing_vat",
                                                  icon: <FaIdCard />,
                                                  key: "billing_vat",
                                                },
                                              ],
                                            },
                                          ]}
                                          data={billingDetails}
                                          trans={trans}
                                        />
                                        {/* { <form>
                                            {createAccountAfterPurchase ? (
                                              <div className="mb-3">
                                                <label htmlFor="billing_name" className="form-label">
                                                  {trans("website.billing_name")} <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={billingDetails.billing_name}
                                                  id="billing_name"
                                                  onChange={(e) => handleCheckoutForm("billing_name", e.target.value)}
                                                />
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            <div className="row">
                                              <div className="mb-3 col-md-6">
                                                <label htmlFor="billing_country">
                                                  {trans("website.billing_country")}:<small className="red"> *</small>
                                                </label>
                                                <Select
                                                  id="billing_country"
                                                  name="billing_country"
                                                  onChange={(e) => {
                                                    console.log(e);
                                                    setBillingDetails({
                                                      ...billingDetails,
                                                      billing_country: e.value,
                                                    });
                                                  }}
                                                  options={countries?.map((c) => {
                                                    return {
                                                      label: c?.country,
                                                      value: c?.id,
                                                    };
                                                  })}
                                                  className="js-example-basic-single col-sm-12"
                                                />
                                              </div>
                                              <div className="mb-3 col-md-6">
                                                <label htmlFor="billing_vat" className="form-label">
                                                  {trans("website.billing_vat")}: <span className="text-danger">{requiredInputs.vat ? "*" : ""}</span>
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={billingDetails.billing_vat}
                                                  id="billing_vat"
                                                  onChange={(e) => handleCheckoutForm("billing_vat", e.target.value)}
                                                />
                                              </div>
                                            </div>
                                          </form>} */}
                                        {showShipping ? (
                                          <div className="mb-3">
                                            <div class="checkbox">
                                              <input
                                                id="same-addr"
                                                type="checkbox"
                                                class="form-check-input"
                                                checked={billingDetails["same-address"] === "1"}
                                                onChange={(e) => {
                                                  setBillingDetails({
                                                    ...billingDetails,
                                                    "same-address": e.target.checked ? "1" : "0",
                                                  });
                                                }}
                                              />
                                              <label for="same-addr" class="form-label">
                                                {trans("website.same-address-to-ship").toLowerCase()}
                                              </label>
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {billingDetails["same-address"] === "0" || (createAccountAfterPurchase && !showShipping) ? (
                                          <EBSSSFormBuilder
                                            setter={setBillingDetails}
                                            inputs={[
                                              {
                                                type: "row",
                                                fields: [
                                                  {
                                                    id: "billing_address",
                                                    transKey: "website.billing_address",
                                                    icon: <Map />,
                                                    key: "billing_address",
                                                  },
                                                  {
                                                    id: "billing_address_2",
                                                    transKey: "website.cart-billing_address_2",
                                                    icon: <Map />,
                                                    key: "billing_address_2",
                                                  },
                                                ],
                                              },
                                              {
                                                type: "row",
                                                fields: [
                                                  {
                                                    id: "billing_city",
                                                    transKey: "website.billing_city",
                                                    icon: <FaBuilding />,
                                                    key: "billing_city",
                                                  },
                                                  {
                                                    id: "billing_postal_code",
                                                    transKey: "website.billing_postal_code",
                                                    icon: <FaBuilding />,
                                                    key: "billing_postal_code",
                                                    customEl: (
                                                      <InputMask
                                                        mask={mask}
                                                        maskChar={null}
                                                        type="text"
                                                        class="form-control"
                                                        id="billing_postal_code"
                                                        value={billingDetails?.billing_postal_code}
                                                        onChange={(e) =>
                                                          setBillingDetails({
                                                            ...billingDetails,
                                                            billing_postal_code: e.target.value,
                                                          })
                                                        }
                                                      >
                                                        {(inputProps) => <input {...inputProps} />}
                                                      </InputMask>
                                                    ),
                                                  },
                                                ],
                                              },

                                              {
                                                type: "row",
                                                fields: [
                                                  {
                                                    id: "billing_state",
                                                    transKey: "website.billing_state",
                                                    icon: <FaBuilding />,
                                                    key: "billing_state",
                                                  },
                                                ],
                                              },
                                            ]}
                                            data={billingDetails}
                                            trans={trans}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                        {/*<div>
                                            <div class="row">
                                              <div class="col-md-6 mb-3">
                                                <label for="morada" class="form-label">
                                                  {trans("website.billing_address")}
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  id="morada"
                                                  value={billingDetails?.billing_address}
                                                  onChange={(e) =>
                                                    setBillingDetails({
                                                      ...billingDetails,
                                                      billing_address: e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                              <div class="col-md-6 mb-3">
                                                <label for="lugar" class="form-label">
                                                  {trans("website.billing_address_2")}
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  id="lugar"
                                                  value={billingDetails?.billing_address_2}
                                                  onChange={(e) =>
                                                    setBillingDetails({
                                                      ...billingDetails,
                                                      billing_address_2: e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div class="col-md-6 mb-3">
                                                <label for="municipio" class="form-label">
                                                  {trans("website.billing_city")}
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  id="municipio"
                                                  value={billingDetails?.billing_city}
                                                  onChange={(e) =>
                                                    setBillingDetails({
                                                      ...billingDetails,
                                                      billing_city: e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                              <div class="col-md-6">
                                                <label for="codigoPostal" class="form-label">
                                                  {trans("website.billing_postal_code")}
                                                </label>
                                                <InputMask
                                                  mask={mask}
                                                  maskChar={null}
                                                  type="text"
                                                  class="form-control"
                                                  id="codigoPostal"
                                                  value={billingDetails?.billing_postal_code}
                                                  onChange={(e) =>
                                                    setBillingDetails({
                                                      ...billingDetails,
                                                      billing_postal_code: e.target.value,
                                                    })
                                                  }
                                                >
                                                  {/*(inputProps) => <input {...inputProps} />}
                                                </InputMask>
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div class="col-md-6 mb-3">
                                                <label for="distrito" class="form-label">
                                                  {trans("website.billing_state")}
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  id="distrito"
                                                  value={billingDetails?.billing_state}
                                                  onChange={(e) =>
                                                    setBillingDetails({
                                                      ...billingDetails,
                                                      billing_state: e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>*/}

                                        <div className="address_form address_fixed">
                                          <label htmlFor="notes">{trans("order-special-notes")}:</label>
                                          <textarea
                                            id="notes"
                                            rows={5}
                                            className="form-control border-primary"
                                            name="notes"
                                            placeholder=""
                                            style={CSS({
                                              resize: "none",
                                            })}
                                            value={billingDetails?.notes}
                                            onChange={(e) =>
                                              setBillingDetails({
                                                ...billingDetails,
                                                notes: e.target.value,
                                              })
                                            }
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {false ? (
                                    <div className="accordion-item border-0 d-none">
                                      <h2 className="accordion-header" id="headingThree">
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseThree"
                                          aria-expanded="false"
                                          aria-controls="collapseThree"
                                          data-step={showShipping ? "3" : "2"}
                                        >
                                          {trans("website.tab-order-payment-methods")}
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className="accordion-body">
                                          <div className="d-flex flex-column">
                                            <div className="mb-3 col-12">
                                              {requirePaymentMethodBeforeGenerateOrder ? (
                                                paymentMethodsOptions.length > 0 ? (
                                                  <div>
                                                    <label className="form-label" htmlFor="paymentMethod">
                                                      {trans("website.order-payment-method")}:
                                                    </label>
                                                    <select
                                                      className="form-select"
                                                      id="paymentMethod"
                                                      name="paymentMethod"
                                                      defaultValue={billingDetails.paymentMethod ?? ""}
                                                      onChange={(e) =>
                                                        setBillingDetails({
                                                          ...billingDetails,
                                                          paymentMethod: e.target.value,
                                                        })
                                                      }
                                                    >
                                                      <option key={0} value={""} disabled>
                                                        ---
                                                      </option>
                                                      {paymentMethodsOptions.map((paymentMethod) => (
                                                        <option key={paymentMethod.token} value={paymentMethod.token}>
                                                          {ebsssLayout?.activeLanguage.toUpperCase() !== "PT" &&
                                                          paymentMethod[ebsssLayout?.activeLanguage?.toUpperCase()]?.name
                                                            ? paymentMethod[ebsssLayout?.activeLanguage?.toUpperCase()]?.name
                                                            : paymentMethod?.name}
                                                        </option>
                                                      ))}
                                                    </select>
                                                  </div>
                                                ) : (
                                                  <div className="col-sm-12 font-large-1 mt-3 mb-3">
                                                    {trans("website.no-payment-method-in-common-available-for-products-in-cart")}
                                                  </div>
                                                )
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ) : !sessionNow?.user?.id && canOrderWithoutLogin === "1" ? (
                                <div className="d-flex flex-column w-100 h-100 text-center justify-content-center align-items-center">
                                  <button
                                    onClick={() => setShowAddressStepUnauthed(true)}
                                    type="button"
                                    className="btn btn-outline-primary lead fs-5"
                                    style={CSS({
                                      marginTop: 100,
                                      marginBottom: 100,
                                    })}
                                  >
                                    {trans("website.start-fast-first-order-without-auth")}
                                  </button>
                                </div>
                              ) : (
                                <div className="d-flex flex-column w-100 h-100 text-center justify-content-center align-items-center">
                                  <p
                                    className="lead fs-5"
                                    style={CSS({
                                      marginTop: 100,
                                      marginBottom: 100,
                                    })}
                                  >
                                    {trans("website.you-have-to-be-authed-to-order")}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-lg-5" id="resume-checkout-container-1">
                            <div id="resume-checkout-1" style={CSS({ position: "relative" })}>
                              {ttlToExpire !== null ? (
                                <div className="card bg-light text-black rounded-3 border-0 shadow-sm mb-3" style={CSS({ borderRadius: 5 })}>
                                  <div className="card-body">
                                    <div className="d-flex justify-content-center align-items-center">
                                      <h5 className="mb-0 text-muted" style={CSS({ fontSize: 16 })}>
                                        <i className="fa fa-clock text-muted"></i> {trans("website.cart-expire-in")} <CartTimer />
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="card bg-light text-black rounded-3 border-0 shadow-sm" style={CSS({ borderRadius: 5 })}>
                                <div className="card-body">
                                  <div className="d-flex justify-content-between align-items-center mb-4">
                                    <h5 className="mb-0 text-muted">{trans("website.dashboard_details")}</h5>
                                  </div>
                                  {availableCoupons > 0 &&
                                  billingDetails?.billing_name &&
                                  (showShipping ? resultValidatedShippingMethodChosen : true) &&
                                  (requiredInputs.email ? validateEmail(billingDetails?.billing_email) : true) &&
                                  (requiredInputs.mobile_number ? !!billingDetails.mobile_number : true) &&
                                  (requiredInputs.vat ? !!billingDetails.billing_vat : true) &&
                                  (showShipping ? (JSON.stringify(sessionNow?.session?.shipping) !== "{}" ? true : false) : true) ? (
                                    <div className="border-2 mb-5" style={CSS({ borderRadius: 5 })}>
                                      <p className="mb-2 d-flex flex-row gap-1" style={CSS({ fontWeight: 400 })}>
                                        <i className="fa fa-ticket text-muted"></i> {trans("website.coupon")}
                                        <EBSSSTooltip
                                          title={
                                            trans("website.to-use-coupons-please-fill-at-least") +
                                            ": " +
                                            trans("website.your-name") +
                                            ", " +
                                            (requiredInputs.email ? trans("website.your-email") + ", " : "") +
                                            (requiredInputs.mobile_number ? trans("website.your-phone") + ", " : "") +
                                            (requiredInputs.vat ? trans("website.your-vat") + ", " : "") +
                                            trans("website.shipping-method") +
                                            "."
                                          }
                                        />
                                      </p>
                                      <input
                                        className="border-0 w-100 shadow-sm p-3 mb-2"
                                        style={CSS({ borderRadius: 5 })}
                                        value={couponText}
                                        onChange={(e) => setCouponText(e.target.value)}
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-success btn-block d-flex w-100 text-center mb-2 shadow-sm"
                                        style={CSS({
                                          borderRadius: 5,
                                          textAlign: "center",
                                          backgroundColor: "#2d6f44",
                                        })}
                                        onClick={handleAppliedCoupon}
                                      >
                                        <span className="text-center w-100">{trans("website.apply")}</span>
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="border-2 mb-5" style={CSS({ borderRadius: 5 })}>
                                      <p className="mb-2 d-flex flex-row gap-1" style={CSS({ fontWeight: 400 })}>
                                        <i className="fa fa-ticket text-muted"></i> {trans("website.coupon")}
                                        <EBSSSTooltip
                                          title={
                                            trans("website.to-use-coupons-please-fill-at-least") +
                                            ": " +
                                            trans("website.your-name") +
                                            ", " +
                                            (requiredInputs.email ? trans("website.your-email") + ", " : "") +
                                            (requiredInputs.mobile_number ? trans("website.your-phone") + ", " : "") +
                                            (requiredInputs.vat ? trans("website.your-vat") + ", " : "") +
                                            trans("website.shipping-method") +
                                            "."
                                          }
                                        />
                                        :
                                      </p>
                                      <input
                                        disabled
                                        className="border-0 w-100 shadow-sm p-3 mb-2"
                                        style={CSS({ borderRadius: 5 })}
                                        value={couponText}
                                        onChange={(e) => setCouponText(e.target.value)}
                                      />
                                      <button
                                        disabled
                                        type="button"
                                        className="btn btn-sm btn-success btn-block d-flex w-100 text-center mb-2 shadow-sm"
                                        style={CSS({
                                          borderRadius: 5,
                                          textAlign: "center",
                                          backgroundColor: "#2d6f44",
                                        })}
                                      >
                                        <span className="text-center w-100">{trans("website.apply")}</span>
                                      </button>
                                    </div>
                                  )}
                                  <div className="mb-5">
                                    <div className="accordion" id="accordionResume">
                                      <div className="accordion-item border-0">
                                        <h2 className="accordion-header" id="headingOneResume">
                                          <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOneResume"
                                            aria-expanded="true"
                                            aria-controls="collapseOneResume"
                                            data-step="1"
                                          >
                                            {trans("website.order-total")}
                                          </button>
                                        </h2>
                                        <div
                                          id="collapseOneResume"
                                          className="accordion-collapse collapse show"
                                          aria-labelledby="headingOneResume"
                                          data-bs-parent="#accordionResume"
                                        >
                                          <div className="accordion-body">
                                            {cartState?.map((items) => {
                                              return (
                                                <div
                                                  className="d-flex flex-column mb-2 pb-2"
                                                  style={CSS({ borderBottomStyle: "solid", borderBottomWidth: 1, borderBottomColor: "#00000033" })}
                                                >
                                                  <div className="row justify-content-between">
                                                    <span className="col-12 col-md-8">
                                                      {items?.quantity}x {items?.p?.name}
                                                    </span>
                                                    <span className="col-12 col-md-4 text-end">
                                                      {Number(items?.price).toFixed(2)} {ebsssLayout?.commonAppSettings?.currency_symbol}
                                                    </span>
                                                  </div>
                                                  <h6 style={CSS({ lineHeight: 1.5 })}>
                                                    {items?.payment_type && (
                                                      <>
                                                        <small className="badge bg-warning" style={CSS({ marginRight: 5 })}>
                                                          {items?.payment_type?.payment_type?.payment_modality === "recorrent" ? (
                                                            <>
                                                              {items?.unit_price}&nbsp;{currencySymbol} /{" "}
                                                              <>
                                                                {translatedPaymentRequestTypes.find((tprt) => tprt.hash === items.hash)?.pay_type &&
                                                                (ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT") !== "PT"
                                                                  ? translatedPaymentRequestTypes.find((tprt) => tprt.hash === items.hash)?.pay_type[
                                                                      ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT"
                                                                    ]?.name
                                                                  : items?.payment_type?.payment_type?.name}
                                                              </>
                                                              {trans("website.sales-plus-fees-taxes")}
                                                            </>
                                                          ) : (
                                                            <>
                                                              {translatedPaymentRequestTypes.find((tprt) => tprt.hash === items.hash)?.pay_type &&
                                                              (ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT") !== "PT"
                                                                ? translatedPaymentRequestTypes.find((tprt) => tprt.hash === items.hash)?.pay_type[
                                                                    ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT"
                                                                  ]?.name
                                                                : items?.payment_type?.payment_type?.name}
                                                            </>
                                                          )}
                                                        </small>

                                                        {items?.payment_type?.payment_type?.discount > 0 && (
                                                          <div className="badge badge-info round m-1 pull-right" style={CSS({ marginRight: 5 })}>
                                                            {items?.payment_type?.payment_type?.discount < 1 ? (
                                                              <>{items?.payment_type?.payment_type?.discount * 100}%</>
                                                            ) : (
                                                              <>
                                                                - {items?.payment_type?.payment_type?.discount}&nbsp;
                                                                {currencySymbol}
                                                              </>
                                                            )}
                                                          </div>
                                                        )}
                                                      </>
                                                    )}
                                                  </h6>
                                                  <PriceDetail item={items} currencySymbol={currencySymbol} />
                                                  {items?.tax_object || items?.fee_object ? (
                                                    <>
                                                      {items?.fee_object?.show_on_cart && items?.fee_amount > 0 ? (
                                                        <p className="text-muted" style={CSS({ fontSize: 12 })}>
                                                          {formatNumber(items?.fee_amount)} {currencySymbol} (
                                                          {(ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT") &&
                                                          items?.fee_object[(ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT").toUpperCase()] &&
                                                          items?.fee_object[ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT"]?.title
                                                            ? items?.fee_object[ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT"]?.title
                                                            : items?.fee_object.title}
                                                          )
                                                        </p>
                                                      ) : (
                                                        <></>
                                                      )}

                                                      {items?.tax_object?.show_on_cart && items?.tax_amount > 0 ? (
                                                        <p className="text-muted" style={CSS({ fontSize: 12 })}>
                                                          + {formatNumber(items?.tax_amount)} {currencySymbol} (
                                                          {(ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT") &&
                                                          items?.tax_object[(ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT").toUpperCase()] &&
                                                          items?.tax_object[ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT"]?.title
                                                            ? items?.tax_object[ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT"]?.title
                                                            : items?.tax_object.title}
                                                          )
                                                        </p>
                                                      ) : (
                                                        <></>
                                                      )}

                                                      {items?.fee_object?.show_on_cart && items?.fee_included > 0 ? (
                                                        <p className="text-muted" style={CSS({ fontSize: 12 })}>
                                                          {trans("website.cart-fee-included")} {formatNumber(items?.fee_included)} {currencySymbol}{" "}
                                                          {(ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT") &&
                                                          items?.fee_object[(ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT").toUpperCase()] &&
                                                          items?.fee_object[ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT"]?.title
                                                            ? items?.fee_object[ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT"]?.title
                                                            : items?.fee_object.title}
                                                        </p>
                                                      ) : (
                                                        <></>
                                                      )}

                                                      {items?.tax_object?.show_on_cart && items?.tax_included > 0 ? (
                                                        <p className="text-muted" style={CSS({ fontSize: 12 })}>
                                                          {trans("website.cart-tax-included")} {formatNumber(items?.tax_included)} {currencySymbol}{" "}
                                                          {(ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT") &&
                                                          items?.tax_object[(ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT").toUpperCase()] &&
                                                          items?.tax_object[ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT"]?.title
                                                            ? items?.tax_object[ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT"]?.title
                                                            : items?.tax_object.title}
                                                        </p>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {items?.payment_request_type ? (
                                                    <span className="badge bg-light text-dark">{items?.payment_request_type?.name}</span>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {(!getSeller?.seller && showShipping) || (getSeller?.seller && priceLoadedMultiseller) ? (
                                    <div className="d-flex justify-content-between">
                                      <p className="mb-2" style={CSS({ fontWeight: 400 })}>
                                        <i className="fa fa-truck text-muted"></i> {trans("website.shipping-cost")}{" "}
                                        {pickUpDay ? `(${new Date(pickUpDay).toLocaleDateString("en-GB")})` : <></>}:
                                      </p>
                                      {shippingCost && Number(shippingCost) > 0 ? (
                                        <p className="mb-2">
                                          {currencySymbol} {Number(shippingCost).toFixed(2)}
                                        </p>
                                      ) : typeof shippingCost === "number" ? (
                                        <p className="mb-2 text-success">{trans("website.free")}</p>
                                      ) : billingDetails?.shipping_method ? (
                                        <button
                                          type="button"
                                          id="check-shipping-value"
                                          className="d-none btn btn-info btn-block btn-sm d-flex text-white mb-2"
                                          onClick={async () => await checkShippingValidation()}
                                        >
                                          <div className="d-flex justify-content-between">
                                            <span>
                                              <i className="fas fa-search me-2"></i>
                                              {trans("website.order-shipping-cost")}
                                            </span>
                                          </div>
                                        </button>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {!getSeller?.seller || (getSeller?.seller && priceLoadedMultiseller) ? (
                                    <div className="d-flex justify-content-between">
                                      <p
                                        className="mb-2"
                                        style={CSS({
                                          fontWeight: 400,
                                          fontSize: 12,
                                        })}
                                      >
                                        {trans("website.total-order")}:{" "}
                                      </p>
                                      <p
                                        className="mb-2"
                                        style={CSS({
                                          fontWeight: 400,
                                          fontSize: 12,
                                        })}
                                      >
                                        {currencySymbol} {Number(Number(total) + Number(shippingCost)).toFixed(2)}
                                      </p>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {couponDiscount?.value ? (
                                    <div className="d-flex justify-content-between">
                                      <p
                                        className="mb-2"
                                        style={CSS({
                                          fontWeight: 400,
                                          fontSize: 12,
                                        })}
                                      >
                                        <i className="fa fa-percent text-muted"></i> {trans("website.sale-coupon-applied")} {couponDiscount?.text}:{" "}
                                      </p>
                                      <p
                                        className="mb-2 text-success"
                                        style={CSS({
                                          fontWeight: 400,
                                          fontSize: 12,
                                        })}
                                      >
                                        - {currencySymbol} {Number(couponDiscount?.value).toFixed(2)}
                                      </p>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {!getSeller?.seller || (getSeller?.seller && priceLoadedMultiseller) ? (
                                    <div className="d-flex justify-content-between mb-4">
                                      <p className="mb-2" style={CSS({ fontWeight: 400 })}>
                                        <i className="fa fa-receipt text-muted"></i> {trans("website.total-payment-now")}:{" "}
                                      </p>
                                      <p className="mb-2 text-success">
                                        {currencySymbol} {Number(Number(payNow) + Number(shippingCost ?? 0)).toFixed(2)}
                                      </p>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="d-flex flex-column">
                                    <div className="mb-3 col-12">
                                      {requirePaymentMethodBeforeGenerateOrder ? (
                                        paymentMethodsOptions.length > 0 ? (
                                          <div>
                                            <label className="form-label" htmlFor="paymentMethod">
                                              {trans("website.order-payment-method")}:
                                            </label>
                                            <div className="mega-inline">
                                              <div className="d-flex col-12 flex-wrap">
                                                {paymentMethodsOptions.map((paymentMethod) => (
                                                  <div
                                                    key={paymentMethod.token}
                                                    className={paymentMethodsOptions?.length < 3 ? "col-12 col-lg-6" : "col-12 col-lg-6"}
                                                  >
                                                    <Card
                                                      style={CSS({
                                                        width: "95%",
                                                        cursor: "pointer",
                                                      })}
                                                      onClick={() =>
                                                        setBillingDetails({
                                                          ...billingDetails,
                                                          paymentMethod: paymentMethod.token,
                                                        })
                                                      }
                                                    >
                                                      <Media className="p-20">
                                                        <div className="radio radio-primary me-3">
                                                          <Input
                                                            type="radio"
                                                            checked={billingDetails.paymentMethod === paymentMethod.token}
                                                            id={paymentMethod.token + "-radio-payment"}
                                                            name={paymentMethod.token + "-radio-payment"}
                                                            checked={billingDetails.paymentMethod === paymentMethod.token}
                                                            value={paymentMethod.token}
                                                          />
                                                          <Label for={"payment-" + paymentMethod.id}></Label>
                                                        </div>
                                                        <Media body>
                                                          <H6
                                                            attrH6={{
                                                              className: "mt-0 mega-title-badge text-break",
                                                            }}
                                                          >
                                                            {ebsssLayout?.activeLanguage?.toUpperCase() !== "PT" &&
                                                            paymentMethod[ebsssLayout?.activeLanguage?.toUpperCase()]?.name
                                                              ? paymentMethod[ebsssLayout?.activeLanguage?.toUpperCase()]?.name
                                                              : paymentMethod?.name}
                                                          </H6>
                                                          <div className="d-flex flex-row gap-2">
                                                            {paymentMethod?.photo ? (
                                                              <img
                                                                width="50px"
                                                                height="50px"
                                                                src={`${paymentMethod?.photo ? remotePath.assetsUrl(paymentMethod?.photo) : ""}`}
                                                                alt={paymentMethod.title}
                                                              />
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </div>
                                                        </Media>
                                                      </Media>
                                                    </Card>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                            {/* <select
                                              className="form-select"
                                              id="paymentMethod"
                                              name="paymentMethod"
                                              defaultValue={
                                                billingDetails.paymentMethod ??
                                                ""
                                              }
                                              onChange={(e) =>
                                                setBillingDetails({
                                                  ...billingDetails,
                                                  paymentMethod: e.target.value,
                                                })
                                              }
                                            >
                                              <option
                                                key={0}
                                                value={""}
                                                disabled
                                              >
                                                ---
                                              </option>
                                              {paymentMethodsOptions.map(
                                                (paymentMethod) => (
                                                  <option
                                                    key={paymentMethod.token}
                                                    value={paymentMethod.token}
                                                  >
                                                    {ebsssLayout?.activeLanguage?.toUpperCase() !==
                                                      "PT" &&
                                                    paymentMethod[
                                                      ebsssLayout?.activeLanguage?.toUpperCase()
                                                    ]?.name
                                                      ? paymentMethod[
                                                          ebsssLayout?.activeLanguage?.toUpperCase()
                                                        ]?.name
                                                      : paymentMethod?.name}
                                                  </option>
                                                )
                                              )}
                                            </select> */}
                                          </div>
                                        ) : (
                                          <div className="col-sm-12 font-large-1 mt-3 mb-3">
                                            {trans("website.no-payment-method-in-common-available-for-products-in-cart")}
                                          </div>
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>

                                  {(validateShipping.cost_type &&
                                    ((validateShipping.cost_type === "point" && selectedPoint) ||
                                      (validateShipping.cost_type !== "point" && selectedShippingId)) &&
                                    billingDetails.shipping_method !== "" &&
                                    isShippingValid &&
                                    billingDetails.paymentMethod &&
                                    Object.keys(validations)?.length === 0) ||
                                  (!showShipping &&
                                    billingDetails.billing_name &&
                                    (requiredInputs.vat ? !!billingDetails.billing_vat : true) &&
                                    (requiredInputs.mobile_number ? !!billingDetails.mobile_number && billingDetails.mobile_number.includes("+") : true) &&
                                    (requiredInputs.email ? !!billingDetails.billing_email : true) &&
                                    billingDetails.paymentMethod &&
                                    Object.keys(validations)?.length === 0) ? (
                                    <>
                                      {sessionNow?.user?.id ? (
                                        <div>
                                          <EBSSSCheckbox
                                            id="agreement"
                                            selected={agreement}
                                            label={
                                              <span>
                                                {trans("website.form-agree-to")}{" "}
                                                <Link target="_blank" to={process.env.PUBLIC_URL + "/terms-conditions"}>
                                                  {trans("common.terms-link")}
                                                </Link>{" "}
                                                {trans("common.and")}{" "}
                                                <Link target="_blank" to={process.env.PUBLIC_URL + "/privacy-policy"}>
                                                  {trans("common.privacy-policy-link")}
                                                </Link>
                                              </span>
                                            }
                                            action={(e) => {
                                              console.log("agreement box");
                                              setAgreement(!agreement);
                                            }}
                                          />
                                        </div>
                                      ) : createAccountAfterPurchase ? (
                                        emailConfirmation === billingDetails.billing_email && availableEmail ? (
                                          <div>
                                            <EBSSSCheckbox
                                              id="agreement"
                                              selected={agreement}
                                              label={
                                                <span>
                                                  {trans("website.form-agree-to")}{" "}
                                                  <Link target="_blank" to={process.env.PUBLIC_URL + "/terms-conditions"}>
                                                    {trans("common.terms-link")}
                                                  </Link>{" "}
                                                  {trans("common.and")}{" "}
                                                  <Link target="_blank" to={process.env.PUBLIC_URL + "/privacy-policy"}>
                                                    {trans("common.privacy-policy-link")}
                                                  </Link>
                                                </span>
                                              }
                                              action={(e) => {
                                                console.log("agreement box");
                                                setAgreement(!agreement);
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <></>
                                        )
                                      ) : (
                                        <div>
                                          <EBSSSCheckbox
                                            id="agreement"
                                            selected={agreement}
                                            label={
                                              <span>
                                                {trans("website.form-agree-to")}{" "}
                                                <Link target="_blank" to={process.env.PUBLIC_URL + "/terms-conditions"}>
                                                  {trans("common.terms-link")}
                                                </Link>{" "}
                                                {trans("common.and")}{" "}
                                                <Link target="_blank" to={process.env.PUBLIC_URL + "/privacy-policy"}>
                                                  {trans("common.privacy-policy-link")}
                                                </Link>
                                              </span>
                                            }
                                            action={(e) => {
                                              console.log("agreement box");
                                              setAgreement(!agreement);
                                            }}
                                          />
                                        </div>
                                      )}

                                      {agreement ? (
                                        <button
                                          type="button"
                                          id="proceed-cart"
                                          className="btn btn-info btn-block btn-lg d-flex w-100 text-white btn-wait-animated"
                                          onClick={async () => {
                                            // await setOrderSession(billingDetails).then(handleNext)
                                            if (document.querySelector("#proceed-cart").className.includes("animate-wait")) {
                                              return;
                                            }
                                            setSpinnerProceed(true);
                                            document.querySelector("#proceed-cart").classList.add("animate-wait");
                                            await checkShippingValidation(true)
                                              .then(() => {
                                                document?.querySelector("#proceed-cart")?.classList?.remove("animate-wait");
                                                setSpinnerProceed(false);
                                              })
                                              .catch(() => {
                                                if (typeof document !== "undefined") {
                                                  document?.querySelector("#proceed-cart")?.classList?.remove("animate-wait");
                                                }
                                                setSpinnerProceed(false);
                                              });
                                          }}
                                        >
                                          {spinnerProceed ? (
                                            <div className="d-flex justify-content-center m-auto">
                                              <div className="spinner-border m-auto" role="status">
                                                <span className="sr-only">Loading...</span>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="d-flex justify-content-between">
                                              <span>
                                                {trans("website.payment")}
                                                <i className="fas fa-long-arrow-alt-right ms-2"></i>
                                              </span>
                                            </div>
                                          )}
                                        </button>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="card border-0 shadow-sm" style={CSS({ borderRadius: 5, minHeight: 600 })}>
                      <div className="card-body p-4">
                        <div className="row">
                          <div className="col-12 col-lg-7" id="data-checkout-2">
                            <div
                              className="d-flex justify-content-between align-items-center mb-4 p-3 shadow-sm"
                              style={CSS({
                                borderRadius: 5,
                                backgroundColor: "#e9e9e950",
                              })}
                            >
                              <div>
                                <p className="mb-1 text-start">{trans("website.cart")}</p>
                                <p className="mb-0 text-muted">{trans("website.order-items")}</p>
                              </div>
                            </div>
                            <div
                              style={CSS({
                                overflowY: "auto",
                                maxHeight: "500px",
                                minHeight: "150px",
                                paddingRight: 20,
                              })}
                            >
                              {cartState?.map((item) => (
                                <div key={item?.p?.id}>
                                  <CartItem
                                    removeItem={removeItem}
                                    showCartImages={showCartImages}
                                    setReasonable={setReasonable}
                                    item={item}
                                    currencySymbol={currencySymbol}
                                    incrementQuantity={incrementQuantity}
                                    decrementQuantity={decrementQuantity}
                                    removeItem={removeItem}
                                    isPaymentStage={true}
                                    isPreCheckout={false}
                                    activeLang={ebsssLayout?.activeLanguage?.toUpperCase() ?? "PT"}
                                    paymentRequestTypeTrans={translatedPaymentRequestTypes.find((tprt) => tprt.hash === item.hash)?.pay_type}
                                  />
                                </div>
                              ))}
                            </div>
                            <div
                              className="d-flex justify-content-between align-items-center mt-4 p-3 shadow-sm d-none"
                              style={CSS({
                                borderRadius: 5,
                                backgroundColor: "#e9e9e950",
                              })}
                            >
                              <div>
                                <p className="mb-1 text-start">Checkout</p>
                                <p className="mb-0 text-muted">Finalize sua compra</p>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-12 col-lg-5" id="resume-checkout-container-2">
                            <div id="resume-checkout-2" style={CSS({ position: "relative" })}>
                              {ttlToExpire !== null ? (
                                <div className="card bg-light text-black rounded-3 border-0 shadow-sm mb-3" style={CSS({ borderRadius: 5 })}>
                                  <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                      <h5 className="mb-0 text-muted">
                                        {trans("website.cart-expire-in")} <CartTimer />
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="card bg-light text-black rounded-3 border-0 shadow-sm" style={CSS({ borderRadius: 5 })}>
                                <div className="card-body">
                                  <div className="d-flex justify-content-between align-items-center mb-4">
                                    <h5 className="mb-0 text-muted">{trans("website.dashboard_details")}</h5>
                                  </div>
                                  <hr className="my-4" />
                                  <div className="d-flex justify-content-between">
                                    <p className="mb-2" style={CSS({ fontWeight: 400 })}>
                                      <i className="fa fa-truck text-muted"></i> {trans("website.shipping-cost")}{" "}
                                      {pickUpDay ? `(${new Date(pickUpDay).toLocaleDateString("en-GB")})` : <></>}:
                                    </p>
                                    {shippingCost && Number(shippingCost) > 0 ? (
                                      <p className="mb-2">
                                        {currencySymbol} {Number(shippingCost).toFixed(2)}
                                      </p>
                                    ) : typeof shippingCost === "number" ? (
                                      <p className="mb-2 text-success">{trans("website.free")}</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <p
                                      className="mb-2"
                                      style={CSS({
                                        fontWeight: 400,
                                        fontSize: 12,
                                      })}
                                    >
                                      {trans("website.total-order")}:{" "}
                                    </p>
                                    <p
                                      className="mb-2"
                                      style={CSS({
                                        fontWeight: 400,
                                        fontSize: 12,
                                      })}
                                    >
                                      {currencySymbol} {Number(Number(total) + Number(shippingCost)).toFixed(2)}
                                    </p>
                                  </div>
                                  {couponDiscount?.value ? (
                                    <div className="d-flex justify-content-between">
                                      <p
                                        className="mb-2"
                                        style={CSS({
                                          fontWeight: 400,
                                          fontSize: 12,
                                        })}
                                      >
                                        <i className="fa fa-percent text-muted"></i> {trans("website.sale-coupon-applied")} {couponDiscount?.text}:{" "}
                                      </p>
                                      <p
                                        className="mb-2 text-success"
                                        style={CSS({
                                          fontWeight: 400,
                                          fontSize: 12,
                                        })}
                                      >
                                        - {currencySymbol} {Number(couponDiscount?.value).toFixed(2)}
                                      </p>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="d-flex justify-content-between mb-4">
                                    <p className="mb-2" style={CSS({ fontWeight: 400 })}>
                                      <i className="fa fa-receipt text-muted"></i> {trans("website.total-payment-now")}:{" "}
                                    </p>
                                    <p className="mb-2 text-success">
                                      {currencySymbol} {Number(Number(payNow) + Number(shippingCost ?? 0)).toFixed(2)}
                                    </p>
                                  </div>
                                  <button
                                    type="button"
                                    id="finish-cart"
                                    className="btn btn-success btn-block btn-lg d-flex w-100 text-white btn-wait-animated"
                                    onClick={async () => {
                                      if (document.querySelector("#finish-cart").className.includes("animate-wait")) {
                                        return;
                                      }
                                      if (!checkLocalValidations()) {
                                        return;
                                      }
                                      setSpinnerFinish(true);
                                      document.querySelector("#finish-cart").classList.add("animate-wait");
                                      await setOrderSession(billingDetails).then(async () => {
                                        await saveOrder({ ...billingDetails }, toggleAuthModalAlreadyRegistered, toggleAuthModalVerifyAccount)
                                          .then(() => {
                                            document.querySelector("#finish-cart").classList.remove("animate-wait");
                                            setSpinnerFinish(false);
                                          })
                                          .catch(() => {
                                            document.querySelector("#finish-cart").classList.remove("animate-wait");
                                            setSpinnerFinish(false);
                                          });
                                      });
                                    }}
                                  >
                                    {spinnerFinish ? (
                                      <div className="d-flex justify-content-center m-auto">
                                        <div className="spinner-border m-auto" role="status">
                                          <span className="sr-only">Loading...</span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-between">
                                        <span>
                                          {trans("website.dashboard-sale-confirm-order")}
                                          <i className="fas fa-long-arrow-alt-right ms-2"></i>
                                        </span>
                                      </div>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {tenantConfig.hiddenLogo ? (
                <></>
              ) : (
                <div
                  style={CSS({
                    display: "flex",
                    justifyContent: "end",
                    marginTop: 30,
                  })}
                >
                  <a href="https://ebsss.com" target="_blank" rel="noreferrer">
                    <img src="https://web.iclient.app/logo.png" width="125px" height="auto" />
                  </a>
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

const Stepper = ({ currentStep, steps, onStepClick }) => {
  const { trans } = useEBSSSLayout();

  return (
    <div
      className="col-12 d-flex flex-row px-5 mt-3 py-3 align-items-center justify-content-between shadow-sm"
      style={CSS({ backgroundColor: "#fff", borderRadius: 5 })}
    >
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          <div
            onClick={() => (index === 0 ? onStepClick(index + 1) : {})}
            key={step}
            className={`step-top justify-content-center ${currentStep >= index + 1 ? "active" : ""}`}
          >
            <div className="circle">{index + 1}</div>
            <div className="label">{step}</div>
          </div>
          {index === 0 ? (
            <div
              className="col"
              style={CSS({
                height: 2.5,
                backgroundColor: currentStep === 2 || (currentStep === 3 && index === 0) ? "green" : "gray",
                borderRadius: 25,
                opacity: 0.5,
              })}
            ></div>
          ) : (
            <></>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

function CheckoutPage() {
  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher(clearShippingSession());
  }, []);

  return (
    <CartProvider>
      <ShoppingCart />
      <ToastContainer />
    </CartProvider>
  );
}

export default CheckoutPage;
