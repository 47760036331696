import { Fragment, useEffect, useState } from "react";
import { Mail } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";

import { Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import FeedbackMessages from "src/base/FeedbackMessage";
import { cleanErrorsAndMessages, fetchForgotEmail, Session } from "src/base/store/authSlice";
import { ReduxDataAuthKey, ReduxDataKey } from "src/base/store/store";
import { EmailAddress, Recovery } from "src/Constant";

const ForgotPasswordVerifyPage = () => {
  const [email, setEmail] = useState("");
  const { trans } = useEBSSSLayout();
  const dispatcher = useDispatch<any>();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatcher(fetchForgotEmail({ email, activeLanguage: "pt" }));
  };

  useEffect(() => {
    dispatcher(cleanErrorsAndMessages());
  }, []);

  return sessionNow?.user?.id ? (
    <Navigate to={`${process.env.PUBLIC_URL}/auth/sign-in`} />
  ) : sessionNow?.message?.forgotPassword === "SENT" ? (
    <Navigate to={`${process.env.PUBLIC_URL}/auth/sign-in/forgot/modify`} />
  ) : (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col
            xl="7 order-1"
            style={{
              backgroundImage: `url("https://micaela.3.ebsss.dev/platform-images/settings/customer_area_background/7679a69f-f07c-4c7c-9af4-f43d771cee18.jpg?p=slider&s=09962273dea6f982797368e0cd6b4f6b")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "block",
            }}
          ></Col>
          <Col xl="5 p-0">
            <div className="login-card1">
              <Form className="theme-form login-form">
                <div className="login-header text-center">
                  <h4>{trans("auth.password-forgot-title")}</h4>
                  <h6>{trans("auth.password-forgot-description")}</h6>
                </div>

                <FormGroup>
                  <Label>{trans("auth.email")}</Label>
                  <InputGroup>
                    <InputGroupText>
                      <Mail />
                    </InputGroupText>
                    <Input type="email" required placeholder="you@gmail.com" defaultValue={email} onChange={(event) => setEmail(event.target.value)} />
                  </InputGroup>
                  <FeedbackMessages messages={sessionNow?.errors?.forgotPassword["email"]} />
                </FormGroup>
                <FormGroup>
                  <EBSSSButton loadingKey={ReduxDataAuthKey.forgotPassword} onClick={handleSubmit}>
                    {trans("auth.password_reset_button")}
                  </EBSSSButton>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ForgotPasswordVerifyPage;
