import React, { Dispatch, Fragment, useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { FaBuilding, FaUpload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CSS } from "src/_utils/styles";
import { getQueryParams } from "src/_utils/urls";
import { Btn, Image } from "src/AbstractElements";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import EBSSSModal from "src/base/atoms/EBSSSModal";
import EBSSSPrintButton from "src/base/atoms/EBSSSPrintButton";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { remotePath } from "src/base/settings/Paths";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import LoginForm from "../Auth/LoginForm";
import RegisterForm from "../Auth/RegisterForm";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement, Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { formatToStripeAmount } from "src/_utils/money";
import Select from "react-select";
import HelperHTTP from "src/_utils/http_module_handler";
import { useFormValidation } from "src/base/hooks/form_validator_handler";
import InputMask from "react-input-mask";
import { updatePostCodeMask } from "src/_utils/validations";
import { Calendar, Globe, Lock, Mail, Map, MapPin, Phone, User } from "react-feather";
import { FaCity, FaGlobe, FaIdCard, FaTransgender } from "react-icons/fa";
import { Card, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import FeedbackMessages from "src/base/FeedbackMessage";
import { EBSSSFormBuilder } from "src/base/atoms/EBSSSFormBuilder";
import { Layout } from "src/base/store/layoutSlice";

const removeQueryParam = (paramName, searchParams) => {
  const newParams = new URLSearchParams(searchParams);
  newParams.delete(paramName);

  // Update the URL without refreshing the page or adding a new history entry
  const newUrl = window.location.pathname + "?" + newParams.toString();
  window.history.pushState({}, "", newUrl);
};

function extractData(htmlString) {
  // Define regular expressions to match each field
  const entidadeRegex = /Entidade: <b>(\d+)<\/b>/;
  const referenciaRegex = /Refer\u00eancia: <b>(\d+)<\/b>/;
  const valorRegex = /Valor: <b>([\d.,]+ EUR)<\/b>/;
  const expiraRegex = /Expira a: <b>\n(\d{2}\/\d{2}\/\d{4} \d{2}:\d{2})<\/b>/;

  // Use the regex to extract the values
  const entidade = htmlString.match(entidadeRegex)?.[1];
  const referencia = htmlString.match(referenciaRegex)?.[1];
  const valor = htmlString.match(valorRegex)?.[1];
  const expira = htmlString.match(expiraRegex)?.[1];

  // Return an object with the extracted values
  return {
    ref_entity: entidade,
    ref_number: referencia,
    ref_value: valor,
    ref_expiry_at: expira,
  };
}

const emitSwal = ({ type, message, configSwal, detail }: any) => {
  let settingsSwal = {
    title: message ? message : "",
    text: detail ? detail : message,
    icon: type !== "e" ? (type === "s" ? "success" : "info") : "error",
  };

  if (configSwal) {
    settingsSwal = {
      ...settingsSwal,
      ...configSwal,
    };
    //@ts-ignore
    configSwal.callback(swal(settingsSwal));
    return;
  }
  //@ts-ignore

  swal(settingsSwal);
  document.querySelector(".sweet-alert")?.classList?.remove("visible");
  document.querySelector(".sweet-alert")?.classList?.remove("showSweetAlert");
};

const MBWayTab = ({ selectedPaymentMethod, setSelectedPaymentMethod, trans }) => {
  return (
    <PaymentMethodsGatewaysContent selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans}>
      MBWayTab
    </PaymentMethodsGatewaysContent>
  );
};
const WalletTab = ({ selectedPaymentMethod, setSelectedPaymentMethod, trans }) => {
  return (
    <PaymentMethodsGatewaysContent selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans}>
      WalletTab
    </PaymentMethodsGatewaysContent>
  );
};
const RwdWalletTab = ({ selectedPaymentMethod, setSelectedPaymentMethod, trans }) => {
  return (
    <PaymentMethodsGatewaysContent selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans}>
      RwdWalletTab
    </PaymentMethodsGatewaysContent>
  );
};
const SibsTab = ({ selectedPaymentMethod, setSelectedPaymentMethod, trans }) => {
  const navigate = useNavigate();
  const [formValidation] = useFormValidation(trans);
  const { contractPayment } = useEBSSS();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const [sibsObject, setSibsObject] = useState(null);
  const [sibsLoading, setSibsLoading] = useState(false);
  const [postSibsRequestPayment, setPostSibsRequestPayment] = useState(null);
  const [addressData, setAddressData] = useState({
    "card-holder-name": "",
    "card-address-line-1": "",
    "card-address-line-2": "",
    "card-address-locality": "",
    "card-address-postal-code": "",
    "card-country": "",
  });

  const handleCardForm = (e: any) => {
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    window["VALIDATION_SUCCESS"] = true;
    if (sibsLoading) {
      return;
    }

    formValidation([
      {
        inputIdentifier: "card-holder-name",
        feedbackElement: "card-holder-name-feedback",
        rules: {
          required: true,
          between: "5-50",
        },
      },
      {
        inputIdentifier: "card-address-line-1",
        feedbackElement: "card-address-line-1-feedback",
        rules: {
          required: true,
          between: "5-50",
        },
      },
      {
        inputIdentifier: "card-address-line-2",
        feedbackElement: "card-address-line-2-feedback",
        rules: {
          required: true,
          between: "5-50",
        },
      },
      {
        inputIdentifier: "card-address-locality",
        feedbackElement: "card-address-locality-feedback",
        rules: {
          required: true,
        },
      },
      {
        inputIdentifier: "card-address-postal-code",
        feedbackElement: "card-address-postal-code-feedback",
        rules: {
          required: true,
        },
      },
      {
        inputIdentifier: "card-country",
        feedbackElement: "card-country-feedback",
        rules: {
          required: true,
        },
      },
    ]);

    if (!window["VALIDATION_SUCCESS"]) {
      return;
    }

    setSibsLoading(true);

    try {
      const res = await HelperHTTP.customRequest(
        remotePath.clientInstanceUrl(),
        `/crm/v2/template/contract-payments/public/sibs/${contractPayment?.paymentRequest?.token}`
      )
        .withLanguage(ebsssLayout?.activeLanguage)
        .api()
        .post({
          data: { ...addressData, build_mode: window["BUILD_MODE"] },
        });

      console.log("RES: ", res);

      if (res?.data?.payment_request?.sibs_object && res?.data?.payment_request?.sibs_object?.length > 0) {
        let parsedSibsObj = JSON.parse(res?.data?.payment_request?.sibs_object);
        console.log("parsedSibsObj in fetch function: ", parsedSibsObj);

        if (parsedSibsObj?.transactionID) {
          console.log("parsedSibsObj ID : ", parsedSibsObj?.transactionID);
          setPostSibsRequestPayment(res?.data?.payment_request);
          navigate({ search: "?nextSibsStage=true" }, { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSibsLoading(false);
    }
  };

  useEffect(() => {
    if (sessionNow?.user?.id || contractPayment?.paymentRequest) {
      setAddressData({
        "card-holder-name":
          sessionNow?.user?.profile?.first_name && sessionNow?.user?.profile?.last_name
            ? sessionNow?.user?.profile?.first_name + " " + sessionNow?.user?.profile?.last_name
            : contractPayment?.paymentRequest?.order?.billing_name || "",
        "card-address-line-1": sessionNow?.user?.profile?.address_line_1 || "",
        "card-address-line-2": sessionNow?.user?.profile?.address_line_2 || "",
        "card-address-locality": sessionNow?.user?.profile?.locality || "",
        "card-address-postal-code": sessionNow?.user?.profile?.postal_code || "",
        "card-country": "",
      });
    }
  }, []);

  useEffect(() => {
    let rp = postSibsRequestPayment ? postSibsRequestPayment : contractPayment?.paymentRequest;
    console.log("fetched: ", postSibsRequestPayment, "\n\nstarted: ", contractPayment?.paymentRequest);
    if (rp && typeof document !== "undefined" /*&& !document.querySelector("script[sibsscript='true']")*/) {
      const parsedSibsObject = JSON.parse(rp?.sibs_object);

      console.log("DETECTED SIBS OBJECT WITH Transaction ID: ", parsedSibsObject?.transactionID);

      setSibsObject(parsedSibsObject);

      if (parsedSibsObject?.transactionID && (rp.status !== "paid" || rp.sibs_status !== "Success")) {
        console.log("Rendering sibs element...");
        const script = document.createElement("script");
        script.setAttribute("sibsscript", "true");
        script.src = `${remotePath.paymentGateways("sibs", true)}/assets/js/widget.js?id=${parsedSibsObject.transactionID}`;
        script.async = true;
        document.body.appendChild(script);
      }
    }
  }, [contractPayment?.paymentRequest, postSibsRequestPayment]);

  const [mask, setMask] = useState("*********"); // Default mask

  useEffect(() => {
    updatePostCodeMask(addressData["card-country"], setMask);
  }, [addressData["card-country"]]);

  console.log("Sibs object: ", sibsObject);

  return (
    <PaymentMethodsGatewaysContent selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans}>
      <div className="card-body p-0" id="sibs-form">
        <div className="row">
          <div className="col-sm-12 form-group">
            {sibsObject?.transactionID ? (
              <form
                className="paymentSPG"
                spg-context={sibsObject?.spgContext}
                spg-config={JSON.stringify(sibsObject?.spgConfig)}
                spg-style={JSON.stringify(sibsObject?.spgStyle)}
              ></form>
            ) : (
              <div className="container">
                <EBSSSFormBuilder
                  submitEl={
                    <EBSSSButton type="submit" loadingKey={sibsLoading} customClassName="w-100">
                      <span>
                        <i className="la la-credit-card me-2 ms-2 "></i>
                        {trans("website.proceed-to-payment-sibs")}
                      </span>
                    </EBSSSButton>
                  }
                  submitHandler={handleSubmit}
                  setter={setAddressData}
                  inputs={[
                    {
                      type: "row",
                      fields: [
                        {
                          id: "card-holder-name",
                          transKey: "website.cart-card-holder-name",
                          icon: <User />,
                          key: "card-holder-name",
                          validationElement: <div id={`invalid-card-holder-name-feedback-validation`}></div>,
                        },
                        {
                          id: "card-address-line-1",
                          transKey: "website.cart-card-address-line-1",
                          icon: <Map />,
                          key: "card-address-line-1",
                          validationElement: <div id={`invalid-card-address-line-1-feedback-validationn`}></div>,
                        },
                      ],
                    },
                    {
                      type: "row",
                      fields: [
                        {
                          id: "card-address-line-2",
                          transKey: "website.cart-card-address-line-1",
                          icon: <Map />,
                          key: "card-address-line-2",
                          validationElement: <div id={`invalid-card-address-line-2-feedback-validation`}></div>,
                        },
                        {
                          id: "card-country",
                          transKey: "website.cart-card-country",
                          icon: <FaGlobe />,
                          key: "card-country",
                          validationElement: <div id={`invalid-card-country-feedback-validation`}></div>,
                          customEl: (
                            <>
                              <Select
                                name="card-country"
                                id="holderCountry-select"
                                onChange={(v: any) => {
                                  console.log("sel ", v);
                                  setAddressData({
                                    ...addressData,
                                    "card-country": v.value,
                                  });
                                }}
                                options={contractPayment?.countries?.map((c) => {
                                  return {
                                    label: c?.country,
                                    value: c?.code,
                                  };
                                })}
                                className="js-example-basic-single"
                                styles={{
                                  input(base, props) {
                                    return { flex: "1 1 auto" };
                                  },
                                }}
                              />
                              <input type="hidden" id="card-country" value={addressData["card-country"]} />
                            </>
                          ),
                        },
                      ],
                    },

                    {
                      type: "row",
                      fields: [
                        {
                          id: "card-address-locality",
                          transKey: "website.cart-card-address-locality",
                          icon: <FaBuilding />,
                          key: "card-address-locality",
                          validationElement: <div id={`invalid-card-address-locality-feedback-validation`}></div>,
                        },
                        {
                          id: "card-address-postal-code",
                          transKey: "website.cart-card-address-postal-code",
                          icon: <Mail />,
                          key: "card-address-postal-code",
                          validationElement: <div id={`invalid-card-address-postal-code-feedback-validation`}></div>,
                          customEl: (
                            <InputMask
                              mask={mask}
                              maskChar={null}
                              id="card-address-postal-code"
                              name="card-address-postal-code"
                              type="text"
                              className="form-control"
                              placeholder={trans("website.cart-card-address-postal-code")}
                              onChange={handleCardForm}
                              value={addressData["card-address-line-postal-code"]}
                            >
                              {/* @ts-ignore */}
                              {(inputProps) => <input {...inputProps} />}
                            </InputMask>
                          ),
                        },
                      ],
                    },
                  ]}
                  data={addressData}
                  trans={trans}
                />
                {/* <form onSubmit={handleSubmit} className="form-horizontal" id="confirmationForm">
                  <div id="card-element-holder" className="row form-group" style={{ marginBottom: "40px" }}>
                    <hr />
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group mb-3 text-start">
                        <label htmlFor="card-holder-name">{trans("website.cart-card-holder-name")}:</label>
                        <input
                          id="card-holder-name"
                          name="card-holder-name"
                          type="text"
                          className="form-control"
                          placeholder={trans("website.cart-card-holder-name")}
                          onChange={handleCardForm}
                          value={addressData["card-holder-name"]}
                          required
                        />
                        <span className="error text-danger" id="invalid-card-holder-name-feedback-validation"></span>
                      </div>

                      <div className="form-group mb-3 text-start">
                        <label htmlFor="card-address-line-1">{trans("website.cart-card-address-line-1")}:</label>
                        <input
                          id="card-address-line-1"
                          name="card-address-line-1"
                          type="text"
                          className="form-control"
                          placeholder={trans("website.cart-card-address-line-1")}
                          onChange={handleCardForm}
                          value={addressData["card-address-line-1"]}
                          required
                        />
                        <span className="error text-danger" id="invalid-card-address-line-1-feedback-validation"></span>
                      </div>

                      <div className="form-group mb-3 text-start">
                        <label htmlFor="card-address-line-2">{trans("website.cart-card-address-line-2")}:</label>
                        <input
                          id="card-address-line-2"
                          name="card-address-line-2"
                          type="text"
                          className="form-control"
                          placeholder={trans("website.cart-card-address-line-2")}
                          onChange={handleCardForm}
                          value={addressData["card-address-line-2"]}
                        />
                        <span className="error text-danger" id="invalid-card-address-line-2-feedback-validation"></span>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group mb-3 text-start">
                        <label htmlFor="card-address-locality">{trans("website.cart-card-address-locality")}:</label>
                        <input
                          id="card-address-locality"
                          name="card-address-locality"
                          type="text"
                          className="form-control"
                          placeholder={trans("website.cart-card-address-locality")}
                          onChange={handleCardForm}
                          value={addressData["card-address-locality"]}
                        />
                        <span className="error text-danger" id="invalid-card-address-locality-feedback-validation"></span>
                      </div>

                      <div className="form-group mb-3 text-start">
                        <label htmlFor="card-country">{trans("website.cart-card-country")}:</label>
                        <Select
                          name="card-country"
                          id="holderCountry-select"
                          onChange={(v: any) => {
                            console.log("sel ", v);
                            setAddressData({
                              ...addressData,
                              ["card-country"]: v.value,
                            });
                          }}
                          options={contractPayment?.countries?.map((c) => {
                            return {
                              label: c?.country,
                              value: c?.code,
                            };
                          })}
                          className="js-example-basic-single col-sm-12"
                        />
                        <input type="hidden" id="card-country" value={addressData["card-country"]} />
                        <span className="error text-danger" id="invalid-card-country-feedback-validation"></span>
                      </div>

                      <div className="form-group mb-3 text-start">
                        <label htmlFor="card-address-postal-code">{trans("website.cart-card-address-postal-code")}:</label>
                        <InputMask
                          mask={mask}
                          maskChar={null}
                          id="card-address-postal-code"
                          name="card-address-postal-code"
                          type="text"
                          className="form-control"
                          placeholder={trans("website.cart-card-address-postal-code")}
                          onChange={handleCardForm}
                          value={addressData["card-address-line-postal-code"]}
                        >
                          {/* @ts-ignore */}
                {/*(inputProps) => <input {...inputProps} />}
                        </InputMask>
                        <span className="error text-danger" id="invalid-card-address-postal-code-feedback-validation"></span>
                      </div>
                    </div>
                  </div>

                  <EBSSSButton type="submit" loadingKey={sibsLoading} customClassName="w-100">
                    <span>
                      <i className="la la-credit-card me-2 ms-2 "></i>
                      {trans("website.proceed-to-payment-sibs")}
                    </span>
                  </EBSSSButton>
                </form> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </PaymentMethodsGatewaysContent>
  );
};
const TransfTab = ({ paymentMethod, paymentRequest, appSettings, selectedPaymentMethod, setSelectedPaymentMethod, trans }) => {
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { fetchContractPayment, handleContractPaymentProveUpload } = useEBSSS();
  const [modal, setModal] = useState(false);
  const [sentPayProve, setSentPayProve] = useState(null);
  const getPaymentRequest = useParams();
  const [attachmentPaymentProveFile, setAttachmentPaymentProveFile] = useState(null);
  const toggle = () => {
    setModal(!modal);
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(meta, file, status);
    setAttachmentPaymentProveFile(file);
  };

  const handleSubmit = async (paymentRequestToken) => {
    let newFormData = new FormData();
    newFormData.append("file", attachmentPaymentProveFile);
    newFormData.append("payment-request-token", paymentRequestToken);
    await handleContractPaymentProveUpload(newFormData).then(async (r) => {
      if (r) {
        setSentPayProve(r);
        toast(trans("website.payment-prove-upload-successfully"), {
          type: "success",
        });
        //await fetchContractPayment(getPaymentRequest?.token);
      } else {
        toast(trans("website.payment-prove-upload-error"), {
          type: "error",
        });
      }
    });
  };

  return (
    <PaymentMethodsGatewaysContent selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans}>
      <div className="w-100">
        <div className="mb-5">
          {appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller && paymentRequest?.order?.seller_id ? (
            <div className="d-flex flex-row">
              <div>
                <div className="text-right">{trans("website.payment-iban")}:</div>
                <div>{paymentMethod.invoice_transfer_iban}</div>
              </div>
              <div>
                <div className="text-right">{trans("website.payment-swift")}:</div>
                <div>{paymentMethod.invoice_transfer_swift}</div>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column flex-md-row justify-content-evenly">
              {appSettings?.invoice_transfer_bank ? (
                <div className="col-auto">
                  <div className="text-right">{trans("website.payment-bank")}:</div>
                  <div>{appSettings?.invoice_transfer_bank}</div>
                </div>
              ) : (
                <></>
              )}
              {appSettings?.invoice_transfer_account ? (
                <div className="col-auto">
                  <div className="text-right">{trans("website.payment-account")}:</div>
                  <div>{appSettings?.invoice_transfer_account}</div>
                </div>
              ) : (
                <></>
              )}
              {appSettings?.invoice_transfer_iban ? (
                <div className="col-auto">
                  <div className="text-right">{trans("website.payment-iban")}:</div>
                  <div>{appSettings?.invoice_transfer_iban}</div>
                </div>
              ) : (
                <></>
              )}
              {appSettings?.invoice_transfer_swift ? (
                <div className="col-auto">
                  <div className="text-right">{trans("website.payment-swift")}:</div>
                  <div>{appSettings?.invoice_transfer_swift}</div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        {paymentRequest?.payment_proof || sentPayProve?.file ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={remotePath.assetsUrl(sentPayProve ? sentPayProve?.file : "/" + paymentRequest?.payment_proof)}
            className="btn btn-success btn-block btn-min-width mr-1 mb-1"
          >
            <i className="la la-paperclip"></i> {trans("website.download-payment-prove")}
          </a>
        ) : ["open", "attach-open"].includes(paymentRequest?.status) ? (
          <EBSSSModal
            customConfirmAction={() => handleSubmit(paymentRequest.token)}
            modalTitle={trans("website.payment-prove")}
            modal={modal}
            toggle={toggle}
            triggerEl={
              <EBSSSButton
                onClick={toggle}
                customClassName="btn btn-primary btn-min-width mb-1 d-flex m-auto align-items-center gap-3"
                loadingKey="payment_prove_upload"
              >
                {trans("website.upload-payment-prove")}
              </EBSSSButton>
            }
          >
            <Form>
              <div className="dz-message needsclick">
                <Dropzone
                  onChangeStatus={handleChangeStatus}
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                  inputContent={<FaUpload />}
                  styles={{
                    dropzoneActive: { borderColor: "green" },
                  }}
                />
              </div>
            </Form>
          </EBSSSModal>
        ) : null}
      </div>
    </PaymentMethodsGatewaysContent>
  );
};
const MyPosTab = ({ selectedPaymentMethod, setSelectedPaymentMethod, trans }) => {
  return (
    <PaymentMethodsGatewaysContent selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans}>
      MyPosTab
    </PaymentMethodsGatewaysContent>
  );
};
const MeoWalletTab = ({ paymentMethod, paymentRequest, appSettings, selectedPaymentMethod, setSelectedPaymentMethod, trans }) => {
  const [showed, setShowed] = useState(0);
  const [loadingMeo, setLoadingMeo] = useState(false);
  const [meoLink, setMeoLink] = useState("");

  useEffect(() => {
    if (showed === 0) {
      setShowed(1);
    }
  }, [showed]);

  const isActive = paymentMethod.code === selectedPaymentMethod || showed === 0;
  const isAriaExpanded = paymentMethod.code === selectedPaymentMethod ? "true" : "false";

  useEffect(() => {
    if (meoLink) {
      let startRedirectCountdown = setTimeout(() => {
        window.location.href = meoLink;
        setLoadingMeo(false);
        clearTimeout(startRedirectCountdown);
      }, 3000);
    }
  }, [meoLink]);

  return (
    <PaymentMethodsGatewaysContent selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans}>
      <div
        role="tabpanel"
        className={`tab-pane ${isActive ? "active" : ""}`}
        id={`tab_${paymentMethod.code}`}
        aria-expanded={isAriaExpanded}
        aria-labelledby={paymentMethod.code}
      >
        <div className="card-body">
          <div className="d-flex flex-column">
            <div className="col-sm-12 form-group">
              <EBSSSButton
                loadingKey={loadingMeo}
                color={loadingMeo ? "light" : "success"}
                style={CSS(
                  Object.assign(
                    {
                      color: "white",
                      width: "100%",
                      marginTop: 25,
                    },
                    loadingMeo ? { borderWidth: 1, colorColor: "#a9a9a9", color: "#a9a9a9" } : {}
                  )
                )}
                onClick={async () => {
                  setLoadingMeo(true);
                  const res = await HelperHTTP.customRequest(
                    remotePath.clientInstanceUrl(),
                    `/crm/v2/template/contract-payments/public/meo/${paymentRequest.token}`
                  )
                    .api()
                    .post({
                      data: {},
                    });

                  if (res?.data) {
                    setMeoLink(res?.data?.url);
                  } else {
                    toast(trans("website.unexpected-error"));
                  }
                }}
              >
                <div className="w-100">
                  <i className="la la-credit-card"></i>{" "}
                  {loadingMeo ? trans("website.you-will-be-redirect-to-meo") : trans("website.proceed-to-payment-meowallet")}
                </div>
              </EBSSSButton>
            </div>
          </div>
        </div>
      </div>
    </PaymentMethodsGatewaysContent>
  );
};
const PayPalTab = ({ paymentRequest, selectedPaymentMethod, setSelectedPaymentMethod, trans }) => {
  const nav = useNavigate();
  return (
    <PaymentMethodsGatewaysContent selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans}>
      <div className="card-body">
        <div className="row">
          <div className="col-sm-12 form-group">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                nav("/crm/contract-payment/" + paymentRequest.token + "/paypal");
              }}
              className="btn btn-danger btn-lg pull-right btn-block"
            >
              <i className="la la-paypal"></i> {trans("website.proceed-to-payment-paypal")}
            </button>
          </div>
        </div>
      </div>
    </PaymentMethodsGatewaysContent>
  );
};
const PagSegTab = ({ selectedPaymentMethod, setSelectedPaymentMethod, trans }) => {
  return (
    <PaymentMethodsGatewaysContent selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans}>
      PagSegTab
    </PaymentMethodsGatewaysContent>
  );
};
const StripeTab = ({ selectedPaymentMethod, setSelectedPaymentMethod, trans }) => {
  const { contractPayment } = useEBSSS();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);

  const CheckoutForm = () => {
    const stripe = useStripe();
    const navigate = useNavigate();
    const elements = useElements();
    const [formValidation] = useFormValidation(trans);
    const [errorMessage, setErrorMessage] = useState(null);
    const [stripeLoading, setStripeLoading] = useState(false);
    const [stripePaid, setStripePaid] = useState(false);
    const [cardPayload, setCartPayload] = useState({
      holderName: "",
      holderAddress: "",
      holderCity: "",
      holderCountry: "",
      cardNumber: "",
      cardExp: "",
      cardCode: "",
      cardCountry: "",
    });

    console.log("els: ", elements);

    const handleCardForm = (e: any) => {
      setCartPayload({
        ...cardPayload,
        [e.target.name]: e.target.value,
      });
    };

    const handleSubmit = async (event) => {
      event.preventDefault();

      try {
        setErrorMessage(null);
        window["VALIDATION_SUCCESS"] = true;
        if (stripeLoading) {
          return;
        }

        formValidation([
          {
            inputIdentifier: "holderName",
            feedbackElement: "holderName-feedback",
            rules: {
              required: true,
              between: "5-50",
            },
          },
          {
            inputIdentifier: "holderAddress",
            feedbackElement: "holderAddress-feedback",
            rules: {
              required: true,
              between: "5-50",
            },
          },
          {
            inputIdentifier: "holderCity",
            feedbackElement: "holderCity-feedback",
            rules: {
              required: true,
              between: "5-50",
            },
          },
          {
            inputIdentifier: "holderCountry",
            feedbackElement: "holderCountry-feedback",
            rules: {
              required: true,
            },
          },
        ]);

        if (elements === null || !window["VALIDATION_SUCCESS"]) {
          return;
        }

        const { error: submitError } = await elements.submit();

        if (submitError) {
          console.log("error elements: ", submitError);
          setErrorMessage(submitError.message);
          return;
        }

        setStripeLoading(true);

        const { setupIntent, error } = await stripe.confirmCardSetup(contractPayment?.intent?.client_secret, {
          payment_method: {
            card: elements.getElement("card"),
            billing_details: {
              name: cardPayload.holderName,
              email: sessionNow?.user?.email,
              address: {
                country: cardPayload.holderCountry,
                line1: cardPayload.holderAddress,
                line2: cardPayload.holderCity,
              },
            },
          },
        });

        if (error) {
          setStripeLoading(false);
          setErrorMessage(error.message);
        } else {
          if (setupIntent && setupIntent.status === "succeeded") {
            const res = await HelperHTTP.customRequest(
              remotePath.clientInstanceUrl(),
              `/crm/v2/template/contract-payments/public/stripe/${contractPayment?.paymentRequest.token}`
            )
              .api()
              .post({
                data: {
                  paymentMethodStripe: setupIntent.payment_method,
                  validCard: 1,
                  currentPaymentMethod: "new",
                  cardHolderName: cardPayload.holderName,
                  addressLine1: cardPayload.holderAddress,
                  addressLine2: cardPayload.holderCity,
                  cardCountry: cardPayload.cardCountry,
                },
              });

            console.log(res);

            if (res?.success && res?.message === "SUCCESS") {
              setStripeLoading(false);
              setStripePaid(true);
              toast(trans("website.success-payment"), {
                type: "success",
              });
              let refreshDelayAfterPay = setTimeout(() => {
                navigate(process.env.PUBLIC_URL + window.location.pathname.replace("/v2", ""), { replace: true });
                clearTimeout(refreshDelayAfterPay);
              }, 5000);
            } else {
              setStripeLoading(false);
            }
          }
        }
      } catch (error) {
        setStripeLoading(false);
      }
    };

    return (
      <EBSSSFormBuilder
        submitEl={
          <>
            <CardElement />
            {!stripePaid && contractPayment?.intent?.client_secret && stripe && elements ? (
              <EBSSSButton
                loadingKey={stripeLoading}
                onClick={() => {}}
                color={stripeLoading ? "light" : "success"}
                type="submit"
                style={CSS(
                  Object.assign(
                    {
                      color: "white",
                      width: "100%",
                      marginTop: 25,
                    },
                    stripeLoading ? { borderWidth: 1, colorColor: "#a9a9a9", color: "#a9a9a9" } : {}
                  )
                )}
              >
                <div className="d-flex flex-row align-items-center gap-3">
                  <i className="la la-credit-card"></i>
                  {trans("website.pay-now")}
                </div>
              </EBSSSButton>
            ) : (
              <></>
            )}
            {errorMessage && <div className="mt-2 alert alert-danger rounded-2">{errorMessage}</div>}
          </>
        }
        submitHandler={handleSubmit}
        setter={setCartPayload}
        inputs={[
          {
            type: "col",
            fields: [
              {
                id: "holderName",
                transKey: "website.cart-card-holder-name",
                icon: <User />,
                key: "holderName",
                validationElement: <div id={`invalid-holderName-feedback-validation`}></div>,
              },
            ],
          },
          {
            type: "row",
            fields: [
              {
                id: "holderAddress",
                transKey: "website.cart-card-address-line-1",
                icon: <Map />,
                key: "holderAddress",
                validationElement: <div id={`invalid-holderAddress-feedback-validation`}></div>,
              },
              {
                id: "holderCity",
                transKey: "website.cart-card-address-line-2",
                icon: <FaBuilding />,
                key: "holderCity",
                validationElement: <div id={`invalid-holderCity-feedback-validation`}></div>,
              },
            ],
          },

          {
            type: "col",
            fields: [
              {
                id: "holderCity",
                transKey: "website.cart-card-country",
                icon: <Globe />,
                key: "holderCity",
                validationElement: <div id={`invalid-holderCity-feedback-validation`}></div>,
                customEl: (
                  <>
                    <Select
                      name="holderCountry"
                      id="holderCountry-select"
                      onChange={(v: any) => {
                        console.log("sel ", v);
                        setCartPayload({
                          ...cardPayload,
                          holderCountry: v.value,
                        });
                      }}
                      options={contractPayment?.countries?.map((c) => {
                        return {
                          label: c?.country,
                          value: c?.code,
                        };
                      })}
                      className="js-example-basic-single"
                      styles={{
                        input(base, props) {
                          return { flex: "1 1 auto" };
                        },
                      }}
                    />
                    <input type="hidden" id="holderCountry" value={cardPayload.holderCountry} />
                  </>
                ),
              },
            ],
          },
        ]}
        data={cardPayload}
        trans={trans}
      />
      /*<form onSubmit={handleSubmit}>
        <div className="">
          <div className="mb-3">
            <label htmlFor="holderName" className="form-label">
              {trans("website.cart-card-holder-name")}
              <small className="text-danger"> *</small>
            </label>
            <input
              type="text"
              id="holderName"
              name="holderName"
              className="form-control"
              placeholder="John Jackson"
              required
              onChange={handleCardForm}
              value={cardPayload.holderName}
            />
            <div id={`invalid-holderName-feedback-validation`}></div>
          </div>

          <div className="mb-3">
            <label htmlFor="holderAddress" className="form-label">
              {trans("website.cart-card-address-line-1")}
              <small className="text-danger"> *</small>
            </label>
            <input
              type="text"
              id="holderAddress"
              name="holderAddress"
              className="form-control"
              placeholder="123 Main St"
              required
              onChange={handleCardForm}
              value={cardPayload.holderAddress}
            />
            <div id={`invalid-holderAddress-feedback-validation`}></div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="holderCity" className="form-label">
                {trans("website.cart-card-address-line-2")}
                <small className="text-danger"> *</small>
              </label>
              <input
                type="text"
                id="holderCity"
                name="holderCity"
                className="form-control"
                placeholder="New York"
                required
                onChange={handleCardForm}
                value={cardPayload.holderCity}
              />
              <div id={`invalid-holderCity-feedback-validation`}></div>
            </div>

            <div className="col-md-6 mb-3">
              <label htmlFor="holderCountry">
                {trans("website.cart-card-country")}
                <small className="text-danger"> *</small>
              </label>
              <Select
                name="holderCountry"
                id="holderCountry-select"
                onChange={(v: any) => {
                  console.log("sel ", v);
                  setCartPayload({
                    ...cardPayload,
                    holderCountry: v.value,
                  });
                }}
                options={contractPayment?.countries?.map((c) => {
                  return {
                    label: c?.country,
                    value: c?.code,
                  };
                })}
                className="js-example-basic-single col-sm-12"
              />
              <input type="hidden" id="holderCountry" value={cardPayload.holderCountry} />
              <div id={`invalid-holderCountry-feedback-validation`}></div>
            </div>
          </div>
        </div>
        <div id="card-test"></div>
        <CardElement />
        {!stripePaid && contractPayment?.intent?.client_secret && stripe && elements ? (
          <EBSSSButton
            loadingKey={stripeLoading}
            onClick={() => {}}
            color={stripeLoading ? "light" : "success"}
            type="submit"
            style={CSS(
              Object.assign(
                {
                  color: "white",
                  width: "100%",
                  marginTop: 25,
                },
                stripeLoading ? { borderWidth: 1, colorColor: "#a9a9a9", color: "#a9a9a9" } : {}
              )
            )}
          >
            <div className="d-flex flex-row align-items-center gap-3">
              <i className="la la-credit-card"></i>
              {trans("website.pay-now")}
            </div>
          </EBSSSButton>
        ) : (
          <></>
        )}
        {errorMessage && <div className="mt-2 alert alert-danger rounded-2">{errorMessage}</div>}
      </form>*/
    );
  };

  console.log("Setted currency now from payment request: ", contractPayment?.paymentRequest?.currency?.code?.toLowerCase());

  return (
    <PaymentMethodsGatewaysContent selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans}>
      <Elements
        stripe={(async () => await loadStripe("pk_test_PbOTNNjwsSyzQjRmv3ls4qhb00Q9J4NDjz"))()}
        options={{
          mode: "payment",
          amount: formatToStripeAmount(contractPayment?.paymentRequest?.amount),
          currency: contractPayment?.paymentRequest?.currency?.code?.toLowerCase(),
          paymentMethodOptions: contractPayment?.intent?.payment_method_options,
          paymentMethodTypes: contractPayment?.intent?.payment_method_types,
        }}
      >
        <CheckoutForm />
      </Elements>
    </PaymentMethodsGatewaysContent>
  );
};
const EasyPayTab = ({ selectedPaymentMethod, setSelectedPaymentMethod, trans }) => {
  return (
    <PaymentMethodsGatewaysContent selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans}>
      EasyPayTab
    </PaymentMethodsGatewaysContent>
  );
};
const DeliveryTab = ({ selectedPaymentMethod, setSelectedPaymentMethod, trans }) => {
  return (
    <PaymentMethodsGatewaysContent selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans}>
      DeliveryTab
    </PaymentMethodsGatewaysContent>
  );
};

const PaymentMethodsGatewaysContent = ({ children, setSelectedPaymentMethod, selectedPaymentMethod, trans }) => {
  const { contractPayment } = useEBSSS();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);

  return (
    <>
      <div className="col-12">
        <div className="card text-muted">
          <div style={CSS({ margin: 20 })}>
            <h3 className="text-muted" style={CSS({ fontSize: 18 })}>
              <i className="fa fa-arrow-left ms-1 me-3" style={CSS({ cursor: "pointer" })} onClick={() => setSelectedPaymentMethod({ code: "", name: "" })}></i>
              {selectedPaymentMethod.name}
            </h3>
          </div>
          <div className="product-box learning-box text-muted">
            <div className="product-img"></div>
            <div className="details-main text-center">
              <div className="border border-2 rounded p-2 mb-4">
                {trans("website.greeting")}, {sessionNow?.user?.id ? sessionNow?.user?.profile?.first_name + ", " : ""}
                {trans("website.pay_your_payment_request_until") + " " + contractPayment?.paymentRequest?.expected_date}
              </div>
              <div className="bottom-details">{children}</div>
              <p>
                <button className="btn btn-primary d-none">{trans("common.send")}</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PaymentMethodsCard = ({ paymentMethod, setSelectedPaymentMethod, isSelected }) => {
  const { trans } = useEBSSSLayout();
  return (
    <div key={paymentMethod?.code} className={`xl-50 col-12 col-md-6 ${isSelected ? "active" : ""}`} id={paymentMethod?.code}>
      <div className="card">
        <div className="product-box learning-box">
          <div className="product-img ebsss-request-payment-method-image-container">
            <img
              width="100%"
              height="100%"
              loading="lazy"
              src={paymentMethod?.icon ? remotePath.assetsUrl(paymentMethod?.icon) : "https://cdn-icons-png.flaticon.com/512/671/671517.png"}
              alt={paymentMethod.name}
            />
          </div>
          <div className="details-main text-center">
            <a href="#javascript">
              <div className="bottom-details">
                <h6>{paymentMethod?.name}</h6>
              </div>
            </a>
            <p>
              <button
                className="btn btn-primary"
                onClick={() => {
                  setSelectedPaymentMethod(paymentMethod);
                }}
              >
                {trans("common.select")}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const PaymentMethodsContentComponent = ({
  paymentMethods,
  paymentRequest,
  appSettings,
  sessionNow,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  trans,
}) => {
  let showed = 0;
  if (!paymentRequest.paymentMethod) {
    showed = 0;
  }

  return (
    <div className="w-100">
      {paymentMethods.map((paymentMethod, i) => {
        if (
          selectedPaymentMethod?.code === paymentMethod?.code &&
          paymentMethod?.code === "mbway" &&
          (appSettings?.ifthenpay_entity || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return <MBWayTab key={i} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans} />;
        } else if (selectedPaymentMethod?.code === paymentMethod?.code && paymentMethod?.code === "wallet" && sessionNow.user.id) {
          return <WalletTab key={i} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans} />;
        } else if (selectedPaymentMethod?.code === paymentMethod?.code && paymentMethod?.code === "rwd_wallet" && sessionNow.user.id) {
          return <RwdWalletTab key={i} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans} />;
        } else if (
          selectedPaymentMethod?.code === paymentMethod?.code &&
          paymentMethod?.code === "sibs"
          //  &&
          // appSettings?.sibs_terminal_id &&
          // appSettings?.sibs_client_id &&
          // appSettings?.sibs_bearer_token &&
          // appSettings?.sibs_entity
        ) {
          return <SibsTab key={i} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans} />;
        } else if (
          selectedPaymentMethod?.code === paymentMethod?.code &&
          paymentMethod?.code === "transf" &&
          (appSettings?.invoice_transfer_bank ||
            appSettings?.invoice_transfer_iban ||
            appSettings?.invoice_transfer_swift ||
            (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return (
            <TransfTab
              paymentMethod={selectedPaymentMethod}
              paymentRequest={paymentRequest}
              appSettings={appSettings}
              key={i}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              trans={trans}
            />
          );
        } else if (
          selectedPaymentMethod?.code === paymentMethod?.code &&
          paymentMethod?.code === "mypos" &&
          (appSettings?.mypos_virtual_production_url || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return <MyPosTab key={i} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans} />;
        } else if (
          selectedPaymentMethod?.code === paymentMethod?.code &&
          paymentMethod?.code === "meowallet" &&
          (appSettings?.meo_api_key || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return (
            <MeoWalletTab
              key={i}
              paymentMethod={selectedPaymentMethod}
              paymentRequest={paymentRequest}
              appSettings={appSettings}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              trans={trans}
            />
          );
        } else if (
          selectedPaymentMethod?.code === paymentMethod?.code &&
          paymentMethod?.code === "paypal" &&
          (appSettings?.paypal_live_api_username || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return (
            <PayPalTab
              key={i}
              paymentRequest={paymentRequest}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              trans={trans}
            />
          );
        } else if (
          selectedPaymentMethod?.code === paymentMethod?.code &&
          paymentMethod?.code === "pagseg" &&
          ((appSettings?.pagseg_token && appSettings?.pagseg_email) || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return <PagSegTab key={i} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans} />;
        } else if (
          sessionNow.user.id &&
          selectedPaymentMethod?.code === paymentMethod?.code &&
          paymentMethod?.code === "stripe" &&
          (appSettings?.stripe_key || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return <StripeTab key={i} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans} />;
        } else if (
          selectedPaymentMethod?.code === paymentMethod?.code &&
          paymentMethod?.code === "easypay" &&
          (appSettings?.easypay_apikey || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return <EasyPayTab key={i} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans} />;
        } else if (selectedPaymentMethod?.code === paymentMethod?.code && paymentMethod?.code === "delivery" && appSettings?.payment_delivery) {
          return <DeliveryTab key={i} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} trans={trans} />;
        } else {
          return null;
        }
      })}
    </div>
  );
};

const PaymentMethodsCardComponent = ({ paymentMethods, selectedCode, trans, appSettings, sessionNow, setSelectedPaymentMethod }) => {
  return (
    <div className="row d-flex">
      {paymentMethods?.map((paymentMethod, i) => {
        if (!paymentMethod.name) {
          paymentMethod.name = trans(`website.pay-with-method-${paymentMethod?.code}`);
        }

        if (paymentMethod?.code === "mbway" && (appSettings?.ifthenpay_entity || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))) {
          return (
            <Fragment key={i}>
              <PaymentMethodsCard
                paymentMethod={paymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                isSelected={paymentMethod?.code === selectedCode}
              />
              <a
                className={`nav-link d-none ${paymentMethod?.code === selectedCode ? "active" : ""}`}
                id={paymentMethod?.code}
                data-toggle="tab"
                aria-controls={`tab_${paymentMethod?.code}`}
                href={`#tab_${paymentMethod?.code}`}
                aria-expanded={i === 0 ? "true" : "false"}
              >
                {paymentMethod.icon ? (
                  <img
                    src={paymentMethod.icon ? remotePath.assetsUrl(paymentMethod.icon) : "https://th.bing.com/th/id/OIG4.0hxoV0bn4kkZAQaoviY4?pid=ImgGn"}
                    alt={paymentMethod.name}
                  />
                ) : (
                  <i className="la la-arrows-h"></i>
                )}
                {paymentMethod.name}
              </a>
            </Fragment>
          );
        } else if (
          paymentMethod?.code === "paypal" &&
          (appSettings?.paypal_live_api_username || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return (
            <Fragment key={i}>
              <PaymentMethodsCard
                paymentMethod={paymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                isSelected={paymentMethod?.code === selectedCode}
              />
              <a
                className={`nav-link d-none ${paymentMethod?.code === selectedCode ? "active" : ""}`}
                id={paymentMethod?.code}
                data-toggle="tab"
                aria-controls={`tab_${paymentMethod?.code}`}
                href={`#tab_${paymentMethod?.code}`}
                aria-expanded={i === 0 ? "true" : "false"}
              >
                {paymentMethod.icon ? (
                  <img
                    src={paymentMethod.icon ? remotePath.assetsUrl(paymentMethod.icon) : "https://th.bing.com/th/id/OIG4.0hxoV0bn4kkZAQaoviY4?pid=ImgGn"}
                    alt={paymentMethod.name}
                  />
                ) : (
                  <i className="la la-paypal"></i>
                )}
                {paymentMethod.name}
              </a>
            </Fragment>
          );
        } else if (
          paymentMethod?.code === "mypos" &&
          (appSettings?.mypos_virtual_production_url || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return (
            <Fragment key={i}>
              <PaymentMethodsCard
                paymentMethod={paymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                isSelected={paymentMethod?.code === selectedCode}
              />
              <a
                className={`nav-link d-none ${paymentMethod?.code === selectedCode ? "active" : ""}`}
                id={paymentMethod?.code}
                data-toggle="tab"
                aria-controls={`tab_${paymentMethod?.code}`}
                href={`#tab_${paymentMethod?.code}`}
                aria-expanded={i === 0 ? "true" : "false"}
              >
                {paymentMethod.icon ? (
                  <img
                    src={paymentMethod.icon ? remotePath.assetsUrl(paymentMethod.icon) : "https://th.bing.com/th/id/OIG4.0hxoV0bn4kkZAQaoviY4?pid=ImgGn"}
                    alt={paymentMethod.name}
                  />
                ) : (
                  <i className="la la-credit-card"></i>
                )}
                {paymentMethod.name}
              </a>
            </Fragment>
          );
        } else if (
          paymentMethod?.code === "meowallet" &&
          (appSettings?.meo_api_key || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return (
            <Fragment key={i}>
              <PaymentMethodsCard
                paymentMethod={paymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                isSelected={paymentMethod?.code === selectedCode}
              />
              <a
                className={`nav-link d-none ${paymentMethod?.code === selectedCode ? "active" : ""}`}
                id={paymentMethod?.code}
                data-toggle="tab"
                aria-controls={`tab_${paymentMethod?.code}`}
                href={`#tab_${paymentMethod?.code}`}
                aria-expanded={i === 0 ? "true" : "false"}
              >
                {paymentMethod.icon ? (
                  <img
                    src={paymentMethod.icon ? remotePath.assetsUrl(paymentMethod.icon) : "https://th.bing.com/th/id/OIG4.0hxoV0bn4kkZAQaoviY4?pid=ImgGn"}
                    alt={paymentMethod.name}
                  />
                ) : (
                  <i className="la la-credit-card"></i>
                )}
                {paymentMethod.name}
              </a>
            </Fragment>
          );
        } else if (
          paymentMethod?.code === "sibs" &&
          ((appSettings?.sibs_terminal_id && appSettings?.sibs_client_id && appSettings?.sibs_bearer_token && appSettings?.sibs_entity) ||
            (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return (
            <Fragment key={i}>
              <PaymentMethodsCard
                paymentMethod={paymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                isSelected={paymentMethod?.code === selectedCode}
              />
              <a
                className={`nav-link d-none ${paymentMethod?.code === selectedCode ? "active" : ""}`}
                id={paymentMethod?.code}
                data-toggle="tab"
                aria-controls={`tab_${paymentMethod?.code}`}
                href={`#tab_${paymentMethod?.code}`}
                aria-expanded={i === 0 ? "true" : "false"}
              >
                {paymentMethod.icon ? (
                  <img
                    src={paymentMethod.icon ? remotePath.assetsUrl(paymentMethod.icon) : "https://th.bing.com/th/id/OIG4.0hxoV0bn4kkZAQaoviY4?pid=ImgGn"}
                    alt={paymentMethod.name}
                  />
                ) : (
                  <i className="la la-credit-card"></i>
                )}
                {paymentMethod.name}
              </a>
            </Fragment>
          );
        } else if (
          paymentMethod?.code === "stripe" &&
          sessionNow.user.id &&
          (appSettings?.stripe_key || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return (
            <Fragment key={i}>
              <PaymentMethodsCard
                paymentMethod={paymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                isSelected={paymentMethod?.code === selectedCode}
              />
              <a
                className={`nav-link d-none ${paymentMethod?.code === selectedCode ? "active" : ""}`}
                id={paymentMethod?.code}
                data-toggle="tab"
                aria-controls={`tab_${paymentMethod?.code}`}
                href={`#tab_${paymentMethod?.code}`}
                aria-expanded={i === 0 ? "true" : "false"}
              >
                {paymentMethod.icon ? (
                  <img
                    src={paymentMethod.icon ? remotePath.assetsUrl(paymentMethod.icon) : "https://th.bing.com/th/id/OIG4.0hxoV0bn4kkZAQaoviY4?pid=ImgGn"}
                    alt={paymentMethod.name}
                  />
                ) : (
                  <i className="la la-cc-stripe"></i>
                )}
                {paymentMethod.name}
              </a>
            </Fragment>
          );
        } else if (
          paymentMethod?.code === "easypay" &&
          (appSettings?.easypay_apikey || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return (
            <Fragment key={i}>
              <PaymentMethodsCard
                paymentMethod={paymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                isSelected={paymentMethod?.code === selectedCode}
              />
              <a
                className={`nav-link d-none ${paymentMethod?.code === selectedCode ? "active" : ""}`}
                id={paymentMethod?.code}
                data-toggle="tab"
                aria-controls={`tab_${paymentMethod?.code}`}
                href={`#tab_${paymentMethod?.code}`}
                aria-expanded={i === 0 ? "true" : "false"}
              >
                {paymentMethod.icon ? (
                  <img
                    src={paymentMethod.icon ? remotePath.assetsUrl(paymentMethod.icon) : "https://th.bing.com/th/id/OIG4.0hxoV0bn4kkZAQaoviY4?pid=ImgGn"}
                    alt={paymentMethod.name}
                  />
                ) : (
                  <i className="la la-credit-card"></i>
                )}
                {paymentMethod.name}
              </a>
            </Fragment>
          );
        } else if (
          paymentMethod?.code === "pagseg" &&
          ((appSettings?.pagseg_token && appSettings?.pagseg_email) || (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return (
            <Fragment key={i}>
              <PaymentMethodsCard
                paymentMethod={paymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                isSelected={paymentMethod?.code === selectedCode}
              />
              <a
                className={`nav-link d-none ${paymentMethod?.code === selectedCode ? "active" : ""}`}
                id={paymentMethod?.code}
                data-toggle="tab"
                aria-controls={`tab_${paymentMethod?.code}`}
                href={`#tab_${paymentMethod?.code}`}
                aria-expanded={i === 0 ? "true" : "false"}
              >
                {paymentMethod.icon ? (
                  <img
                    src={paymentMethod.icon ? remotePath.assetsUrl(paymentMethod.icon) : "https://th.bing.com/th/id/OIG4.0hxoV0bn4kkZAQaoviY4?pid=ImgGn"}
                    alt={paymentMethod.name}
                  />
                ) : (
                  <i className="la la-calculator"></i>
                )}
                {paymentMethod.name}
              </a>
            </Fragment>
          );
        } else if (
          paymentMethod?.code === "transf" &&
          (appSettings?.invoice_transfer_iban ||
            appSettings?.invoice_transfer_swift ||
            (appSettings?.sys_multi_sellers && appSettings?.payment_direct_to_seller))
        ) {
          return (
            <Fragment key={i}>
              <PaymentMethodsCard
                paymentMethod={paymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                isSelected={paymentMethod?.code === selectedCode}
              />
              <a
                className={`nav-link d-none ${paymentMethod?.code === selectedCode ? "active" : ""}`}
                id={paymentMethod?.code}
                data-toggle="tab"
                aria-controls={`tab_${paymentMethod?.code}`}
                href={`#tab_${paymentMethod?.code}`}
                aria-expanded={i === 0 ? "true" : "false"}
              >
                {paymentMethod.icon ? (
                  <img
                    src={paymentMethod.icon ? remotePath.assetsUrl(paymentMethod.icon) : "https://th.bing.com/th/id/OIG4.0hxoV0bn4kkZAQaoviY4?pid=ImgGn"}
                    alt={paymentMethod.name}
                  />
                ) : (
                  <i className="la la-arrows-h"></i>
                )}
                {paymentMethod.name}
              </a>
            </Fragment>
          );
        } else if (paymentMethod?.code === "free") {
          return (
            <Fragment key={i}>
              <PaymentMethodsCard
                paymentMethod={paymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                isSelected={paymentMethod?.code === selectedCode}
              />
              <a
                className={`nav-link d-none ${paymentMethod?.code === selectedCode ? "active" : ""}`}
                id={paymentMethod?.code}
                data-toggle="tab"
                aria-controls={`tab_${paymentMethod?.code}`}
                href={`#tab_${paymentMethod?.code}`}
                aria-expanded={i === 0 ? "true" : "false"}
              >
                {paymentMethod.icon ? (
                  <img
                    src={paymentMethod.icon ? remotePath.assetsUrl(paymentMethod.icon) : "https://th.bing.com/th/id/OIG4.0hxoV0bn4kkZAQaoviY4?pid=ImgGn"}
                    alt={paymentMethod.name}
                  />
                ) : (
                  <i className="la la-arrows-h"></i>
                )}
                {paymentMethod.name}
              </a>
            </Fragment>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

const PriceDetails = ({ order, paymentRequest, trans, appSettings }) => {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12">
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {order?.installation_fee && order?.installation_fee > 0 && (
              <tr>
                <td>{trans("billing.installation_fee")}:</td>
                <td className="text-end">
                  {order?.installation_fee} {appSettings?.currency_symbol}
                </td>
              </tr>
            )}
            <tr>
              <td>{trans("website.sub-total-amount")}:</td>
              <td className="text-end">
                {order
                  ? order?.items
                      .map((i) => Number(i?.amount))
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2)
                  : paymentRequest?.amount}{" "}
                {appSettings?.currency_symbol}
              </td>
            </tr>
            {order?.items.map((item, i) => (
              <React.Fragment key={i}>
                {item.type === "product" && item.product && (
                  <>
                    {item.fee && (
                      <tr>
                        <td>
                          {item.fee.title} #{i + 1}:
                        </td>
                        <td className="text-end">
                          {item.fee_amount > 0
                            ? `${item.fee_amount} ${appSettings?.currency_symbol}`
                            : `${item.fee_included} ${appSettings?.currency_symbol} (${trans("website.cart-fee-included")})`}
                        </td>
                      </tr>
                    )}
                    {item.tax && (
                      <tr>
                        <td>
                          {item.tax.title} #{i + 1}:
                        </td>
                        <td className="text-end">
                          {item.tax_amount > 0
                            ? `${item.tax_amount} ${appSettings?.currency_symbol}`
                            : `${item.tax_included} ${appSettings?.currency_symbol} (${trans("website.cart-tax-included")})`}
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
            {order?.discount_coupon_value && Number(order?.discount_coupon_value) > 0 && (
              <tr>
                <td className="text-success">{trans("website.sub-total-discount")}:</td>
                <td className="text-end text-success">
                  -{order?.discount_coupon_value} {appSettings?.currency_symbol}
                </td>
              </tr>
            )}
            {order?.shipping_cost && (
              <tr>
                <td>
                  {order?.shippingMethod ? (
                    <div className="d-flex flex-column text-start">
                      <span>{order?.shippingMethod.name}:</span>
                      <span>{order?.delivery_point && ` ${order?.delivery_point.name}`}</span>
                      <small>{order?.delivery_point && <span dangerouslySetInnerHTML={{ __html: order?.delivery_point.description }}></span>}</small>
                    </div>
                  ) : (
                    trans("website.sale-shipping-cost")
                  )}
                </td>
                <td className="text-end">
                  {order?.shipping_cost} {appSettings?.currency_symbol}
                </td>
              </tr>
            )}
            <tr>
              <td>{trans("website.total-amount")}:</td>
              <td className="text-end">
                {order?.discount_coupon_value && Number(order?.discount_coupon_value) > 0
                  ? (Number(order ? order?.total : paymentRequest?.amount) - Number(order?.discount_coupon_value)).toFixed(2)
                  : Number(order ? order?.total : paymentRequest?.amount).toFixed(2)}{" "}
                {appSettings?.currency_symbol}
              </td>
            </tr>
          </tbody>
        </table>
        <span className="badge badge-info fs-6 w-100 my-3">
          {paymentRequest?.payment_group || paymentRequest?.status === "attach-cancel" ? paymentRequest?.description : ""}
        </span>
      </div>
    </div>
  );
};

const PaymentDetails = ({ order, paymentRequest }) => {
  const { appSettings } = useEBSSS();
  const { trans } = useEBSSSLayout();

  return (
    <div className="row">
      {!appSettings?.sys_billing ? (
        <div className="col-lg-12 col-md-12 col-sm-12 text-center text-md-left">
          {order?.reference?.entity && (
            <>
              <p className="lead">{trans("website.payment-method-multibank")}:</p>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-borderless table-sm">
                      <tbody>
                        <tr>
                          <td rowSpan={4} className="text-center">
                            <img src={"assets/images/multibanco.png"} style={{ width: "100px" }} alt="Multibanco" />
                          </td>
                        </tr>
                        <tr>
                          <td>{trans("website.payment-entity")}:</td>
                          <td className="text-right">{order?.reference?.entity}</td>
                        </tr>
                        <tr>
                          <td>{trans("website.payment-reference")}:</td>
                          <td className="text-right">{order?.reference?.reference}</td>
                        </tr>
                        <tr>
                          <td>{trans("website.payment-value")}:</td>
                          <td className="text-right">{order?.reference?.value}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
          {(appSettings?.invoice_transfer_iban || appSettings?.invoice_transfer_swift) && (
            <>
              <p className="lead">{trans("website.payment-method-bank-transfer")}:</p>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-borderless table-sm">
                      <tbody>
                        {appSettings?.invoice_transfer_bank && (
                          <tr>
                            <td className="text-right">{trans("website.payment-bank")}:</td>
                            <td>{appSettings?.invoice_transfer_bank}</td>
                          </tr>
                        )}
                        {appSettings?.invoice_transfer_account && (
                          <tr>
                            <td className="text-right">{trans("website.payment-account")}:</td>
                            <td>{appSettings?.invoice_transfer_account}</td>
                          </tr>
                        )}
                        {appSettings?.invoice_transfer_iban && (
                          <tr>
                            <td className="text-right">{trans("website.payment-iban")}:</td>
                            <td>{appSettings?.invoice_transfer_iban}</td>
                          </tr>
                        )}
                        {appSettings?.invoice_transfer_swift && (
                          <tr>
                            <td className="text-right">{trans("website.payment-swift")}:</td>
                            <td>{appSettings?.invoice_transfer_swift}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        appSettings?.sys_billing &&
        order?.payment_requests?.length > 0 && (
          <div className="col-lg-12 col-md-12 col-sm-12 text-center text-md-left d-none">
            {order?.payment_requests.map((paymentRequest) => (
              <Link
                key={paymentRequest.id}
                to={`${process.env.PUBLIC_URL}/crm/contract-payment/${paymentRequest.token}`}
                className="btn btn-primary btn-block mt-1 mb-1"
              >
                {paymentRequest.paymentMomment && (
                  <>
                    {paymentRequest.paymentMomment.name} ({paymentRequest.percentage * 100}%)
                  </>
                )}
                {trans("website.order-pay-payment-request")} #{paymentRequest.id}
              </Link>
            ))}
          </div>
        )
      )}
    </div>
  );
};

const ProductDefault = ({ item, index, appSettings }) => {
  const { trans } = useEBSSSLayout();
  console.log(item);

  return !item ? (
    <></>
  ) : (
    <div className="table-responsive col-sm-12">
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>{trans("website.cart-product")}</th>
            <th>{trans("website.cart-product-details")}</th>
            <th>{trans("website.cart-quantity")}</th>
            <th>{trans("website.unit-price")}</th>
            <th>{trans("website.sub-price")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">{index + 1}</td>
            <td>
              <p>{item?.item_name}</p>
            </td>
            <td>
              <p>{item?.item_option}</p>
            </td>
            <td>{parseFloat(item?.quantity)?.toFixed(2)}</td>
            <td className="text-right">
              {!Number(item?.unit_price) && Number(item?.amount) && item?.quantity
                ? (Number(item?.amount) / item?.quantity)?.toFixed(2)
                : Number(item?.unit_price)}{" "}
              {appSettings?.currency_symbol}
            </td>
            <td className="text-right">
              {(item?.paymentType && item?.paymentType?.discount
                ? Number(item?.amount) - item?.paymentType?.discount * Number(item?.amount)
                : Number(item?.amount)
              )?.toFixed(2)}{" "}
              {appSettings?.currency_symbol}{" "}
              {item?.paymentType && item?.paymentType?.discount ? (
                <span className="badge badge-warning">
                  {item?.paymentType?.discount > 0 && `${(item?.paymentType?.discount * 100)?.toFixed(2)}% ${item?.paymentType?.name}`}
                </span>
              ) : null}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const ProductRental = ({ item, index, appSettings }) => {
  const { trans } = useEBSSSLayout();

  return !item ? (
    <></>
  ) : (
    <div className="table-responsive col-sm-12">
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>{trans("website.cart-product")}</th>
            <th>{trans("website.rent-start-at")}</th>
            <th>{trans("website.unit-price")}</th>
            <th>{trans("website.sub-price")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">{index + 1}</td>
            <td>
              <p>{item?.item_name}</p>
              {item?.item_option && <p>{item?.item_option}</p>}
            </td>
            <td>
              <small>{trans("website.rent-start-at")}: </small>
              {item?.rental_start_at}
              <br />
              <small>{trans("website.rent-end-at")}: </small>
              {item?.rental_end_at}
            </td>
            <td>
              <div className="product-price">
                {`${item?.quantity}x ${item?.unit_price > 0 ? `${Number(item?.unit_price)?.toFixed(2)} ${appSettings?.currency_symbol}` : ""}`}
                <br />
                {trans(item?.product ? `website.entity-price-per-${item?.product.rental_type}` : "")}
              </div>
              {item?.rental_price_factor !== 1 && item?.rental_price_factor > 0 && (
                <small>
                  <strong>{trans("website.entity-rent-availability-price-factor")}: </strong>
                  {Number(item?.rental_price_factor)?.toFixed(2)}
                </small>
              )}
              {item?.rental_people && (
                <small>
                  <strong>{trans("website.entity-rental-people")}: </strong>
                  {item?.rental_people}
                </small>
              )}
            </td>
            <td className="text-right">{`${Number(item?.amount)?.toFixed(2)} ${appSettings?.currency_symbol}`}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const ProductContract = ({ item, appSettings }) => {
  const { trans } = useEBSSSLayout();

  const recorrentPayment = null;
  const showProductImages = null;
  const contractTemplate =
    item?.product && item?.product.contract_id
      ? {
          ...item?.product,
        }
      : item?.product;

  const validate = contractTemplate ? contractTemplate.contract_validate : "";
  const matches = validate.match(/\d+/g);
  const number = matches ? matches.join("") : "";
  const freq = matches ? validate.replace(number, "").trim() : "";

  return !item ? (
    <></>
  ) : (
    <div className="table-responsive">
      <table className="table table-borderless mb-0">
        <thead>
          <tr>
            <th>{trans("website.cart-service")}</th>
            <th>{trans("website.contract-information")}</th>
            <th>{trans("website.recorrent-price")}</th>
            {!recorrentPayment && <th>{trans("website.sign-up-price")}</th>}
          </tr>
        </thead>
        <tbody>
          <tr
            className={
              item?.product && (item?.product.instant_payment !== 1 || (item?.product.type === "physical" && item?.product.stock - item.quantity < 0))
                ? "pending-approval"
                : ""
            }
          >
            <td>
              {showProductImages && item?.product && item?.product.photo && (
                <div className="product-img d-flex align-items-center">
                  <Link to={`${process.env.PUBLIC_URL}/crm/products/${item?.product.slug}`}>
                    <img className="img-fluid" src={item?.product.photo} alt={item?.product.name} />
                  </Link>
                </div>
              )}
              {item?.product && item?.product.name && (
                <div className="product-title">
                  <Link to={`${process.env.PUBLIC_URL}/crm/products/${item?.product.slug}`}>{item?.product.name}</Link>
                </div>
              )}
              {appSettings?.paymentRequest_contract_information_label ? appSettings?.paymentRequest_contract_information_label : <></>}
              {item?.option && item?.option.title && item?.option.productOption && (
                <div className="product-title">
                  <strong>{item?.option.productitem?.Option.title}:</strong> {item?.option.title}
                </div>
              )}
              {!item?.option && item?.item_option && <div className="product-title">{item?.item_option}</div>}
              {item?.product && item?.product.instant_payment !== 1 && (
                <small>
                  <i className="ft ft-alert-triangle"></i> {trans("website.require-request-proposal-text")}
                </small>
              )}
              {item?.product && item?.product.type === "physical" && item?.product.stock - item.quantity < 0 && (
                <small>
                  <i className="ft ft-alert-triangle"></i> {trans("website.out-of-stock-product")}
                </small>
              )}
            </td>
            <td>
              {item?.product && item?.product.term && (
                <>
                  <br />
                  <strong>{item?.product.term.version}</strong>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`${process.env.PUBLIC_URL}/crm/products/contract-term/${item?.product.token}`}
                    className="btn btn-sm btn-outline-info"
                  >
                    <i className="la la-file-text-o"></i>
                  </Link>
                </>
              )}
              <br />
              <strong>{trans("website.contract-vigency")}</strong> {number} {trans(`calendar.${freq}`)}
            </td>
            <td>
              <div className="product-price">
                {item?.unit_price} {appSettings?.currency_symbol}
                {item?.paymentType && <small> {item?.paymentType.name}</small>}
              </div>
            </td>
            {!recorrentPayment && (
              <td>
                {item?.paymentType && (
                  <div className="product-price">
                    {item?.paymentType.instant_payment === 1 && (
                      <big className="text-success">
                        {item?.unit_price} {appSettings?.currency_symbol}
                      </big>
                    )}
                    {item?.installation_fee && item?.installation_fee > 0 && (
                      <>
                        <br />+ {item?.installation_fee.toFixed(2)} {appSettings?.currency_symbol} <small>({trans("website.sales-installation-fee")})</small>
                      </>
                    )}
                  </div>
                )}
              </td>
            )}
          </tr>
          {item?.ordem_item_extra &&
            item?.ordem_item_extra?.map((oie, index) => {
              const extra = oie.extra || {};
              return (
                <tr
                  key={index}
                  className={
                    item?.product && (item?.product.instant_payment !== 1 || (item?.product.type === "physical" && item?.product.stock - item.quantity < 0))
                      ? "pending-approval"
                      : ""
                  }
                >
                  <td>
                    {showProductImages && extra.photo && (
                      <div className="product-img d-flex align-items-center">
                        <img className="img-fluid" src={extra.photo} alt={extra.title} />
                      </div>
                    )}
                    + {extra.title}
                  </td>
                  <td>{extra.one_payment && trans("website.sales-extra-unique-payment")}</td>
                  <td>
                    {oie.recorrent_amount > 0 && (
                      <>
                        {oie.recorrent_amount} {appSettings?.currency_symbol}
                      </>
                    )}
                  </td>
                  {!recorrentPayment && (
                    <td>
                      {oie.amount > 0 ? (
                        <>
                          {oie.amount} {appSettings?.currency_symbol}
                        </>
                      ) : extra.instant_payment ? (
                        <>
                          {oie.recorrent_amount} {appSettings?.currency_symbol}
                        </>
                      ) : null}
                      {oie.installation_fee && oie.installation_fee > 0 && (
                        <>
                          <br />
                          {"+"} {oie.installation_fee} {appSettings?.currency_symbol} <small>({trans("website.sales-installation-fee")})</small>
                        </>
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={2}>
              <strong>{trans("website.sale-contract-product-total")}</strong>
            </th>
            <th>
              {item?.recorrent_price > 0 && (
                <>
                  {item?.recorrent_price} {appSettings?.currency_symbol}
                  {item?.paymentType && (
                    <>
                      <small>{item?.paymentType.name}</small>
                      <br />
                      <small>{trans("website.sales.plus-fees-taxes")}</small>
                    </>
                  )}
                </>
              )}
            </th>
            {!recorrentPayment && (
              <th>
                {item?.amount} {appSettings?.currency_symbol} <small>{trans("website.sales-payment-now")}</small>
                <br />
                <small>{trans("website.sales.plus-fees-taxes")}</small>
              </th>
            )}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const InvoiceCustomerDetails = ({ paymentRequest }) => {
  const { trans } = useEBSSSLayout();
  const { order, contract, created_at } = paymentRequest;

  return (
    <div className="">
      <div id="invoice-customer-details" className="row pt-2 p-2">
        <div className="col-sm-12 text-start text-md-start">
          <p className="text-muted">{trans("website.bill-to")}</p>
        </div>
        <div className="col-md-12 col-sm-12 text-start text-md-start">
          <ul className="px-0 list-unstyled">
            {order ? (
              <>
                <li className="text-bold-700 text-muted">
                  {order.billing_name} {order.billing_vat ? `(${order.billing_vat})` : ""}
                </li>
                <li className="text-muted">
                  {order.billing_address}
                  {order.billing_address_2 ? `, ${order.billing_address_2}` : ""}
                </li>
                <li className="text-muted">
                  {order.billing_state} {order.billing_city}
                </li>
                <li className="text-muted">{order.billing_country}</li>
              </>
            ) : contract ? (
              <li className="text-bold-700 text-muted">{contract?.user.getFullName}</li>
            ) : null}
          </ul>
        </div>
        <div className="col-md-6 col-sm-12 text-center text-md-right d-none">
          <p>
            <span className="text-muted">{trans("common.created_at")} :</span> {created_at}
          </p>
          {order && order.custom_time && (
            <p>
              {trans("website.custom_time")}: {new Date(order.custom_time).toLocaleDateString()}
            </p>
          )}
          <a onClick={() => window.print()} className="no-print btn btn-outline-info my-1 d-none">
            <i className="la la-file-pdf-o"></i> {trans("website.request-payment-print-pdf")}
          </a>
        </div>
      </div>
    </div>
  );
};

const InvoiceCompanyDetails = ({ paymentRequest, appSettings, trans }) => {
  return (
    <div id="invoice-company-details text-muted" className="row">
      <div className="col-md-6 col-sm-12 text-md-right">
        <h2>&nbsp;</h2>
        {paymentRequest?.order && (
          <div className="d-flex flex-column p-2">
            <p className="">
              <Link
                to={`${process.env.PUBLIC_URL}/crm/orders/${paymentRequest?.order?.token}`}
                className="btn btn-outline-primary"
                style={{ width: "164px", marginTop: "20px" }}
              >
                {trans("website.sale_order_button")} #{paymentRequest?.order?.id}
              </Link>
            </p>
            {new Date(paymentRequest?.expected_date) < new Date() && (paymentRequest?.status === "open" || paymentRequest?.status === "attach-open") ? (
              <></>
            ) : (
              <div className="d-flex flex-row gap-3">
                {paymentRequest?.order?.items?.map((item) =>
                  item.entity_reservations?.map((er) => (
                    <div key={er.token} className="d-flex flex-column align-items-center">
                      <Link
                        to={`${process.env.PUBLIC_URL}/entity-reservations/${er.token}`}
                        className="btn btn-outline-primary h-100"
                        style={{ width: "164px" }}
                      >
                        {trans("website.order-entity-reservation")} {er.reference}
                      </Link>
                      <img
                        style={{ width: "164px" }}
                        src={remotePath.clientInstanceUrl() + "/" + `entity-reservation/qrcode/${er.token}`}
                        className="img-fluid"
                      />
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        )}

        {
          /*paymentRequest?.order?.items?.filter((p) =>
          p?.entity_reservations.find((ett) => ett.reference)
        ).length === 0 */
          paymentRequest?.contract?.token && paymentRequest?.contract_id && (
            <p className="p-2">
              <Link to={`${process.env.PUBLIC_URL}/crm/contracts/${paymentRequest?.contract?.token}`} className="btn btn-outline-primary">
                {trans("website.contract_number")}
                {" #"}
                {paymentRequest?.contract_id}
              </Link>
            </p>
          )
        }
      </div>
    </div>
  );
};

const InvoiceItemsDetails = ({ paymentRequest, appSettings }) => {
  const [fees, setFees] = React.useState({});
  const [taxes, setTaxes] = React.useState({});
  const [total, setTotal] = React.useState(0);

  const handleItemCalculations = (item) => {
    let newFees = { ...fees };
    let newTaxes = { ...taxes };
    let newTotal = total;

    if (item.fee) {
      if (newFees[item.fee.token]) {
        newFees[item.fee.token].amount += item.fee_amount;
      } else {
        newFees[item.fee.token] = {
          amount: item.fee_amount,
          name: item.fee.title,
        };
      }
    }

    if (item.tax) {
      if (newTaxes[item.tax.token]) {
        newTaxes[item.tax.token].amount += item.tax_amount;
      } else {
        newTaxes[item.tax.token] = {
          amount: item.tax_amount,
          name: item.tax.title,
        };
      }
    }

    newTotal += item.amount;

    setFees(newFees);
    setTaxes(newTaxes);
    setTotal(newTotal);
  };

  React.useEffect(() => {
    if (paymentRequest?.order) {
      paymentRequest?.order.items.forEach((item) => {
        handleItemCalculations(item);
      });
    } else if (paymentRequest?.contract && paymentRequest?.contract?.orderItem) {
      handleItemCalculations(paymentRequest?.contract?.orderItem);
    }
  }, [paymentRequest]);

  return (
    <div id="invoice-items-details" className="pt-2">
      <div className="row">
        {paymentRequest?.type === "single" && paymentRequest?.reservation_id
          ? null
          : paymentRequest?.order
          ? paymentRequest?.order?.items?.map((item, i) => (
              <React.Fragment key={i}>
                {item.product && item?.product?.type === "entities" ? (
                  <ProductRental item={item} index={i} appSettings={appSettings} />
                ) : item?.product && item?.product.type === "contract" ? (
                  <ProductContract item={item} appSettings={appSettings} />
                ) : (
                  <ProductDefault item={item} index={i} appSettings={appSettings} />
                )}
              </React.Fragment>
            ))
          : paymentRequest?.type !== "single" && paymentRequest?.contract && paymentRequest?.contract?.orderItem
          ? paymentRequest?.contract?.orderItem?.product &&
            paymentRequest?.contract?.orderItem?.product?.type === "contract" && (
              <ProductContract item={paymentRequest?.contract?.orderItem} appSettings={appSettings} />
            )
          : null}
      </div>
    </div>
  );
};

const ContractPaymentPage = () => {
  const { apiErrors, contractPayment, appSettings, fetchContractPayment, fetchSibsStatus, fetchSibsClear } = useEBSSS();
  const { trans } = useEBSSSLayout();
  const [toasted, setToasted] = useState(false);
  const [toastedExpireWarn, setToastedExpireWarn] = useState(false);
  const navigate = useNavigate();
  const getPaymentRequest = useParams();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showAlertOrderModal, setShowAlertOrderModal] = useState(false);
  const [refreshPayment, setRefreshPayment] = useState(false);
  const [getPermissionParams, setGetPermissionParams] = useState<Record<string, any>>({});
  const [cancelReference, setCancelReference] = useState(false);
  const [sibsElementModal, setSibsElementModal] = useState({ body: null });
  const [startMBWAYTimer, setStartMBWAYTimer] = useState(false);
  const [alreadyVerified, setAlreadyVerified] = useState(false);
  const [inAgreement, setInAgreement] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    code: "",
    name: "",
  });

  const toggle = () => {
    setShowVerifyModal(false);
  };
  const toggleSibsModal = () => {
    setShowVerifyModal(false);
  };

  console.log("appSettings: ", appSettings);

  useEffect(() => {
    //   if (["Declined", "Pending"].includes(r.errors.status)) {
    //     fetchSibsClear(getPaymentRequest?.token).then(() => {
    //       navigate(`${process.env.PUBLIC_URL}/crm/contract-payment/${getPaymentRequest?.token}`);
    //     });
    //     return;
    //   }
    const getCleanParam = getQueryParams(window.location.href);

    if (getPaymentRequest?.gateway && !cancelReference && getCleanParam?.alreadyCleanedSR !== "true") {
      if (getPaymentRequest?.gateway === "sibs" && getPaymentRequest?.gateway_param === "status") {
        let getIdParams = getQueryParams(window.location.href);
        fetchSibsStatus(getPaymentRequest?.token, getIdParams?.id)
          .then((res) => {
            console.log("Getting SIBS Status: ", res);

            if (res.errors) {
              if (res?.errors?.payment_request?.sibs_status === "Pending" && res?.errors?.payment_request?.sibs_pref_obj === "MBWAY") {
                console.log("Opening MBWAY MODAL");
                setSibsElementModal({
                  body: (
                    <div className="align-items-center justify-content-center text-center">
                      <h3>{trans("billing.payment-waiting-verification") + " MBWAY"}</h3>
                      <div className="spinner-border text-primary my-5" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      <p>{trans("billing.please-wait-payment-verification")}</p>
                      <p>{trans("billing.not-click-cancel-if-have paid")}</p>
                      <EBSSSButton
                        customClassName="m-auto"
                        color="danger"
                        onClick={() => {
                          setCancelReference(true);
                          fetchSibsClear(getPaymentRequest?.token).then(() =>
                            navigate(`${process.env.PUBLIC_URL}/crm/contract-payment/${getPaymentRequest?.token}`)
                          );
                          setSibsElementModal({ body: null });
                        }}
                      >
                        {trans("billing.clear-mbway")}
                      </EBSSSButton>
                    </div>
                  ),
                });
                setStartMBWAYTimer(true);
                return;
              }
              if (res?.errors?.status === "Declined") {
                setSibsElementModal({
                  body: (
                    <div className="align-items-center justify-content-center text-center">
                      <h3>{trans("billing.payment-declined-sibs")}</h3>
                      <p>{trans("billing.payment-declined-from-sibs-refresh-page-manual-when-done")}</p>
                      <EBSSSButton
                        customClassName="m-auto"
                        color="danger"
                        onClick={() => {
                          setSibsElementModal({ body: null });
                        }}
                      >
                        {trans("billing.clear-mbway")}
                      </EBSSSButton>
                    </div>
                  ),
                });
                return;
              }
            } else {
              let refData = extractData(JSON.parse(res?.data?.payment_request?.sibs_pref_obj)?.source);

              if (res.data?.ref_entity) {
                console.log("Opening MULTIBANCO modal...");
                setSibsElementModal({
                  body: (
                    <div className="align-items-center justify-content-center text-center">
                      <h3>{trans("website.payment-method-multibank")}</h3>
                      <p>
                        {trans("website.payment-entity")}: {refData.ref_entity}
                      </p>
                      <p>
                        {trans("website.payment-reference")}: {refData.ref_number}
                      </p>
                      <p>
                        {trans("website.payment-value")}: {refData.ref_value}
                      </p>
                      <p>
                        {trans("website.payment-expiry")}: {refData.ref_expiry_at}
                      </p>
                      <p>{trans("billing.reference-view-ref-descrition")}</p>
                      <div className="d-flex flex-row m-auto justify-content-center align-items-center">
                        <EBSSSButton
                          customClassName="m-auto"
                          color="danger"
                          onClick={() => {
                            setCancelReference(true);
                            fetchSibsClear(getPaymentRequest?.token).then(() =>
                              navigate(`${process.env.PUBLIC_URL}/crm/contract-payment/${getPaymentRequest?.token}`)
                            );
                            setSibsElementModal({ body: null });
                          }}
                        >
                          {trans("common.cancel")}
                        </EBSSSButton>
                        <EBSSSButton
                          customClassName="m-auto"
                          color="danger"
                          onClick={() => {
                            setCancelReference(true);
                            fetchSibsClear(getPaymentRequest?.token).then(() =>
                              navigate(`${process.env.PUBLIC_URL}/crm/contract-payment/${getPaymentRequest?.token}`)
                            );
                            setSibsElementModal({ body: null });
                          }}
                        >
                          {trans("common.close")}
                        </EBSSSButton>
                      </div>
                    </div>
                  ),
                });
              }
            }
          })
          .catch((r) => {
            console.log("status sibs error: ", r);
          });
      }
    }
  }, []);

  useEffect(() => {
    let timerGetSibsStatusMBWAY;
    if (startMBWAYTimer && getPaymentRequest?.gateway) {
      let getIdParams = getQueryParams(window.location.href);

      timerGetSibsStatusMBWAY = setInterval(() => {
        fetchSibsStatus(getPaymentRequest?.token, getIdParams?.id).then((res) => {
          console.log("Checked sibs status now: ", res);
          if (res?.data?.payment_request?.sibs_status === "Success") {
            setSibsElementModal({ body: null });
            clearInterval(timerGetSibsStatusMBWAY);
            fetchContractPayment(getPaymentRequest?.token);
          }
        });
      }, 2500);
    }
    return () => {
      clearInterval(timerGetSibsStatusMBWAY);
    };
  }, [startMBWAYTimer]);

  useEffect(() => {
    const getCleanParam = getQueryParams(window.location.href);

    if (
      !getPaymentRequest?.gateway &&
      getCleanParam?.nextSibsStage !== "true" &&
      getCleanParam?.alreadyCleanedSR !== "true" &&
      contractPayment?.paymentRequest &&
      !!contractPayment?.paymentRequest?.sibs_object &&
      !contractPayment?.paymentRequest?.sibs_status &&
      !contractPayment?.paymentRequest?.sibs_pref_obj
    ) {
      fetchSibsClear(getPaymentRequest?.token).then(() =>
        navigate(`${process.env.PUBLIC_URL}/crm/contract-payment/${getPaymentRequest?.token}?alreadyCleanedSR=true`)
      );
    }
  }, [contractPayment?.paymentRequest]);

  useEffect(() => {
    setGetPermissionParams(getQueryParams(window.location.href));
    fetchContractPayment(getPaymentRequest?.token);
  }, [refreshPayment]);

  useEffect(() => {
    if (contractPayment?.selectedCode && contractPayment?.paymentMethodsFinal?.length > 0) {
      let choosedPaymentMethod = contractPayment?.paymentMethodsFinal?.find((pm) => pm.code === contractPayment?.selectedCode);
      setSelectedPaymentMethod(choosedPaymentMethod);
    }

    const getAlertParam = getQueryParams(window.location.href);
    if (!toasted && contractPayment && Number(getAlertParam?.expirePaidEnabled) === 1) {
      toast(trans("website.payment-request-expired-but-allow-payment"), {
        type: "error",
      });
      setToasted((s) => !s);
    }
    if (contractPayment && showAlertOrderModal) {
      let orderAlert = {
        type: "s",
        detail: trans("website.order-completed-alert-title"),
        message: trans("website.order-completed-alert-text"),
        configSwal: {},
      };
      orderAlert.configSwal["buttons"] = {
        confirm: {
          text: trans("website.proceed"),
          className: "bg-success",
          closeModal: true,
        },
      };
      orderAlert.configSwal["callback"] = async () => {
        setShowAlertOrderModal(false);
        return;
      };

      emitSwal({
        ...orderAlert,
      });
    }
  }, [contractPayment]);

  useEffect(() => {
    const getAlertParam = getQueryParams(window.location.href);

    if (Number(getAlertParam?.orderAlert) === 1) {
      setShowAlertOrderModal(true);
    }
  }, []);

  useEffect(() => {
    if (!sessionNow?.user?.id && apiErrors && apiErrors?.billing_payment_request_verify_user_to_show) {
      if (Number(getPermissionParams?.newUserActivationRequired) === 1) {
        setShowVerifyModal(true);
        return;
      }
      console.log("Opening LOGIN modal...");
      setShowLoginModal(true);
    }
  }, [apiErrors]);

  useEffect(() => {
    if (!apiErrors && Number(getPermissionParams?.newUserActivationRequired) === 0) {
      setShowVerifyModal(true);
    }
  }, [getPermissionParams?.newUserActivationRequired]);

  useEffect(() => {
    if (appSettings?.alert_expire_message && !toastedExpireWarn) {
      setToastedExpireWarn(true);

      toast(appSettings?.alert_expire_message, {
        type: "warning",
      });
    }
  }, [contractPayment]);

  return !showLoginModal && !showVerifyModal ? (
    <Page
      breads={[
        {
          name: trans("website.payment-requests"),
          active: false,
          url: "/crm/contract-payments",
        },
        {
          name: trans("website.payment-request") + " #" + (contractPayment?.paymentRequest?.id ? contractPayment?.paymentRequest?.id : ""),
          active: true,
        },
      ]}
    >
      <EBSSSModal hideFooter dismissable={false} modalTitle={trans("website.sibs")} modal={!!sibsElementModal.body} toggle={toggleSibsModal}>
        {sibsElementModal.body}
      </EBSSSModal>
      <div className="d-flex flex-column flex-md-row">
        <div className="col-12 col-md-6">
          <div
            className={`no-print-ebsss p-1 ${
              contractPayment?.paymentRequest?.status === "paid" ||
              (!appSettings?.billing_allow_payments_on_expired_requests &&
                new Date(contractPayment?.paymentRequest?.expected_date) < new Date() &&
                (contractPayment?.paymentRequest?.status === "open" || contractPayment?.paymentRequest?.status === "attach-open")) ||
              contractPayment?.paymentRequest?.status === "expired" ||
              contractPayment?.paymentRequest?.status === "attach-paid"
                ? "h-100"
                : ""
            }`}
          >
            {(appSettings?.billing_allow_payments_on_expired_requests &&
              (contractPayment?.paymentRequest?.status === "open" || contractPayment?.paymentRequest?.status === "attach-open")) ||
            (new Date(contractPayment?.paymentRequest?.expected_date).getTime() > new Date().getTime() &&
              (contractPayment?.paymentRequest?.status === "open" || contractPayment?.paymentRequest?.status === "attach-open")) ? (
              <div className="d-flex flex-column text-muted">
                {!selectedPaymentMethod?.code ? (
                  <PaymentMethodsCardComponent
                    paymentMethods={contractPayment?.paymentMethodsFinal}
                    appSettings={appSettings}
                    trans={trans}
                    sessionNow={sessionNow}
                    selectedCode={contractPayment?.selectedCode}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                  />
                ) : (
                  <></>
                )}
                <PaymentMethodsContentComponent
                  paymentMethods={contractPayment?.paymentMethodsFinal}
                  paymentRequest={contractPayment?.paymentRequest}
                  appSettings={appSettings}
                  trans={trans}
                  sessionNow={sessionNow}
                  selectedPaymentMethod={selectedPaymentMethod}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                />
              </div>
            ) : (
              <></>
            )}
            {(!appSettings?.billing_allow_payments_on_expired_requests &&
              new Date(contractPayment?.paymentRequest?.expected_date) < new Date() &&
              (contractPayment?.paymentRequest?.status === "open" || contractPayment?.paymentRequest?.status === "attach-open")) ||
            contractPayment?.paymentRequest?.status === "expired" ? (
              <div className="d-flex flex-column text-muted w-100 h-100 text-center justify-content-center align-items-center card">
                <div
                  style={CSS({
                    display: "flex",
                    position: "relative",
                    width: 200,
                    height: "auto",
                  })}
                >
                  <div
                    style={CSS({
                      backgroundColor: "#fff",
                      position: "absolute",
                      width: 200,
                      height: 200,
                      borderRadius: 500,
                    })}
                  ></div>

                  <Image
                    attrImage={{
                      src: remotePath.staticsUrl("paymentexpiration.png"),
                      width: 200,
                      height: "auto",
                      style: CSS({ position: "absolute", zIndex: 999 }),
                    }}
                  />
                </div>
                <p className="lead fs-5 text-break pe-5 ps-5 text-danger" style={CSS({ marginTop: 220 })}>
                  {trans("website.expired_due_to_date")}
                </p>
              </div>
            ) : (
              <></>
            )}
            {contractPayment?.paymentRequest?.status === "paid" ? (
              <div className="d-flex flex-column text-muted w-100 h-100 text-center justify-content-center align-items-center card">
                <div
                  style={CSS({
                    display: "flex",
                    position: "relative",
                    width: 200,
                    height: "auto",
                  })}
                >
                  <div
                    style={CSS({
                      backgroundColor: "#fff",
                      position: "absolute",
                      width: 200,
                      height: 200,
                      borderRadius: 500,
                    })}
                  ></div>

                  <Image
                    attrImage={{
                      src: remotePath.staticsUrl("paymentconfirmation.png"),
                      width: 200,
                      height: "auto",
                      style: CSS({ position: "absolute", zIndex: 999 }),
                    }}
                  />
                </div>
                <p className="lead fs-5 text-break pe-5 ps-5 text-success" style={CSS({ marginTop: 220 })}>
                  {trans("website.already-paid")}
                </p>
              </div>
            ) : (
              <></>
            )}
            {contractPayment?.paymentRequest?.status === "cancel" ? (
              <div className="d-flex flex-column text-muted w-100 h-100 text-center justify-content-center align-items-center card">
                <div
                  style={CSS({
                    height: 500,
                    marginTop: 300,
                    alignItems: "center",
                    justifyItems: "center",
                    justifyContent: "center",
                  })}
                >
                  <i
                    className="la la-close text-danger m-auto text-center"
                    style={CSS({
                      zIndex: 999,
                      fontSize: 100,
                    })}
                  ></i>
                  <p className="lead fs-5 text-break pe-5 ps-5 text-danger" style={CSS({ marginTop: 20 })}>
                    {trans(`billing.payment-request-status-${contractPayment?.paymentRequest?.status}`)}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            {contractPayment?.paymentRequest?.status.includes("confirm-contract") ? (
              <div className="d-flex flex-column text-muted w-100 h-100 text-center justify-content-center align-items-center card">
                <div
                  style={CSS({
                    height: 500,
                    marginTop: 300,
                    alignItems: "center",
                    justifyItems: "center",
                    justifyContent: "center",
                  })}
                >
                  <i
                    className={`la la-info m-auto text-center text-${contractPayment?.paymentRequest?.statusColorBootStrap}`}
                    style={CSS({
                      zIndex: 999,
                      fontSize: 100,
                    })}
                  ></i>
                  <p className={`lead fs-5 text-break pe-5 ps-5 text-${contractPayment?.paymentRequest?.statusColorBootStrap}`} style={CSS({ marginTop: 20 })}>
                    {trans(`billing.payment-request-status-${contractPayment?.paymentRequest?.status}`)}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            {contractPayment?.paymentRequest?.status.includes("attach-") && contractPayment?.paymentRequest?.status !== "attach-open" ? (
              <div className="d-flex flex-column text-muted w-100 h-100 text-center justify-content-center align-items-center card">
                <div
                  style={CSS({
                    height: 500,
                    marginTop: 300,
                    alignItems: "center",
                    justifyItems: "center",
                    justifyContent: "center",
                  })}
                >
                  <i
                    className={`la ${
                      contractPayment?.paymentRequest?.status?.includes("paid")
                        ? "la-check"
                        : contractPayment?.paymentRequest?.status?.includes("grouped")
                        ? "la-layer-group"
                        : contractPayment?.paymentRequest?.status?.includes("expired")
                        ? "la-close"
                        : "la-archive"
                    } m-auto text-center text-${contractPayment?.paymentRequest?.statusColorBootStrap}`}
                    style={CSS({
                      zIndex: 999,
                      fontSize: 100,
                    })}
                  ></i>
                  <p className={`lead fs-5 text-break pe-5 ps-5 text-${contractPayment?.paymentRequest?.statusColorBootStrap}`} style={CSS({ marginTop: 20 })}>
                    {trans(`billing.payment-request-status-${contractPayment?.paymentRequest?.status}`)}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="p-1">
            {contractPayment?.paymentRequest ? (
              <div className="app-content content text-muted">
                <div className="content-body text-muted">
                  <section className="card">
                    <div id="invoice-template" className="card-body">
                      {appSettings?.sys_multi_sellers && contractPayment?.paymentRequest?.order?.seller ? (
                        <div className="row" style={CSS({ height: "auto" })}>
                          <div className="col-sm-6">
                            <div className="media">
                              <div className="media-left">
                                <Link to="/dashboard">
                                  {contractPayment?.paymentRequest?.order?.seller.profile?.avatar ? (
                                    <img
                                      src={remotePath.assetsUrl(contractPayment?.paymentRequest?.order?.seller.profile.avatar)}
                                      alt="company logo"
                                      width="80px"
                                      className="media-object img-60 media"
                                    />
                                  ) : (
                                    <img
                                      src={remotePath.assetsUrl(appSettings?.invoice_logo)}
                                      alt="company logo"
                                      width="80px"
                                      className="media-object img-60 media"
                                    />
                                  )}
                                </Link>
                              </div>
                              <div className="m-l-20 media-body text-muted">
                                <ul className="ml-2 px-0 list-unstyled">
                                  <li className="text-bold-800">{contractPayment?.paymentRequest?.order?.seller.getFullName}</li>
                                  <li>
                                    {contractPayment?.paymentRequest?.order?.seller.profile?.address_line_1 || ""}{" "}
                                    {contractPayment?.paymentRequest?.order?.seller.profile?.address_line_2 || ""}
                                  </li>
                                  <li>
                                    {contractPayment?.paymentRequest?.order?.seller.profile?.locality || ""} -{" "}
                                    {contractPayment?.paymentRequest?.order?.seller.profile?.administrative_area || ""}.
                                  </li>
                                  <li>{contractPayment?.paymentRequest?.order?.seller.profile?.postal_code || ""}.</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="text-md-end text-xs-center text-muted">
                              <h3>
                                {trans("website.invoice")}
                                {" #"}
                                <span className="counter">{contractPayment?.paymentRequest?.id}</span>
                              </h3>
                              <div>
                                {trans("common.created_at")} <span>{new Date(contractPayment?.paymentRequest?.created_at).toLocaleDateString()}</span>
                                {["open", "attach-open"].includes(contractPayment?.paymentRequest?.status) && (
                                  <span className="bs-callout-warning callout-border-left callout-bordered mt-1 p-1">
                                    {trans("billing.payment-expire-at")} {contractPayment?.paymentRequest?.expected_date}
                                  </span>
                                )}
                                <br />
                                <span className="fw-bold">
                                  {contractPayment?.paymentRequest?.type === "single" && contractPayment?.paymentRequest?.reservation_id ? (
                                    <label className="label label-warning">{trans("billing.single-payment-request")}</label>
                                  ) : contractPayment?.paymentRequest?.order ? (
                                    <ul className="px-0 list-unstyled">
                                      <li>{trans("website.total-amount")}</li>
                                      <li className="lead text-bold-800">
                                        {contractPayment?.paymentRequest?.order?.items?.length === 1 &&
                                          contractPayment?.paymentRequest?.percentage > 0 &&
                                          contractPayment?.paymentRequest?.percentage < 1 && (
                                            <>
                                              {contractPayment?.paymentRequest?.percentage * 100}% {trans("common.of")}
                                            </>
                                          )}
                                        {contractPayment?.paymentRequest?.partial_value > 0 && contractPayment?.paymentRequest?.status === "paid" && (
                                          <>
                                            <b>{trans("website.payment_partial")}:</b> {contractPayment?.paymentRequest?.partial_value?.toFixed(2)}{" "}
                                            {appSettings?.currency_symbol}
                                            <br />
                                          </>
                                        )}
                                        {contractPayment?.paymentRequest?.partial_value > 0 &&
                                          (contractPayment?.paymentRequest?.status === "open" || contractPayment?.paymentRequest?.status === "attach-open") && (
                                            <>
                                              {
                                                (contractPayment.requestPayment.amount_temporary =
                                                  contractPayment?.paymentRequest?.amount - contractPayment?.paymentRequest?.partial_value)
                                              }
                                              <b>{trans("website.payment_partial")}:</b> {contractPayment?.paymentRequest?.partial_value?.toFixed(2)}{" "}
                                              {appSettings?.currency_symbol}
                                              <br />
                                              <b>{trans("website.recalculated_total")}:</b> {contractPayment?.paymentRequest?.amount_temporary?.toFixed(2)}{" "}
                                              {appSettings?.currency_symbol}
                                              <br />
                                            </>
                                          )}
                                        {contractPayment?.paymentRequest?.order.price_in_points > 0 ? (
                                          <>
                                            {Number(contractPayment?.paymentRequest?.amount)?.toFixed(2)} {appSettings?.currency_symbol} <br />
                                            <b>{trans("website.will_pay_with")}: </b>
                                            {contractPayment?.paymentRequest?.order.price_in_points} {trans("website.type-points")}
                                            <br />
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            {Number(contractPayment?.paymentRequest?.amount)?.toFixed(2)} {appSettings?.currency_symbol}
                                          </>
                                        )}
                                        <h6>
                                          {contractPayment?.paymentRequest?.resume?.rewards > 0 &&
                                            `${trans("website.will_win")}: ${contractPayment?.paymentRequest?.resume?.rewards} ${trans("website.type-points")}`}
                                        </h6>
                                        <br />
                                      </li>

                                      <li>
                                        {(!appSettings?.billing_allow_payments_on_expired_requests &&
                                          new Date(contractPayment?.paymentRequest?.expected_date) < new Date() &&
                                          (contractPayment?.paymentRequest?.status === "open" || contractPayment?.paymentRequest?.status === "attach-open")) ||
                                        contractPayment?.paymentRequest?.status === "expired" ? (
                                          <span className="badge bg-danger badge-lg">{trans("billing.payment-request-status-expired")}</span>
                                        ) : (
                                          <span className={`badge bg-${contractPayment?.paymentRequest?.statusColorBootStrap} badge-lg`}>
                                            {trans(`billing.payment-request-status-${contractPayment?.paymentRequest?.status}`)}
                                          </span>
                                        )}
                                      </li>
                                    </ul>
                                  ) : (
                                    <ul className="px-0 list-unstyled">
                                      <li>{trans("website.total-amount")}</li>
                                      <li className="lead text-bold-800">
                                        {Number(contractPayment?.paymentRequest?.amount)?.toFixed(2)} {appSettings?.currency_symbol}
                                      </li>
                                      <li>
                                        {(!appSettings?.billing_allow_payments_on_expired_requests &&
                                          new Date(contractPayment?.paymentRequest?.expected_date) < new Date() &&
                                          (contractPayment?.paymentRequest?.status === "open" || contractPayment?.paymentRequest?.status === "attach-open")) ||
                                        contractPayment?.paymentRequest?.status === "expired" ? (
                                          <span className="badge badge-default badge-danger badge-lg">{trans("billing.payment-request-status-expired")}</span>
                                        ) : (
                                          <span className={`badge badge-default badge-${contractPayment?.paymentRequest?.statusColorBootStrap} badge-lg`}>
                                            {trans(`billing.payment-request-status-${contractPayment?.paymentRequest?.status}`)}
                                          </span>
                                        )}
                                      </li>
                                    </ul>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row" style={CSS({ height: "auto" })}>
                          <div className="col-sm-6">
                            <div className="media">
                              <div className="media-left">
                                <Link to="/dashboard">
                                  {appSettings?.invoice_logo && (
                                    <img
                                      src={remotePath.assetsUrl(appSettings?.invoice_logo)}
                                      alt="company logo"
                                      width="80px"
                                      className="media-object img-60 media"
                                    />
                                  )}
                                </Link>
                              </div>
                              <div className="m-l-20 media-body text-muted">
                                <h4 className="media-heading">{appSettings?.invoice_company_name ? appSettings?.invoice_company_name : ""}</h4>
                                <p>
                                  {" "}
                                  {appSettings?.invoice_company_address}, {appSettings?.invoice_company_city_state}, {appSettings?.invoice_company_country}
                                  <br />
                                  <span>289-335-6503</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="text-md-end text-xs-center text-muted">
                              <h3>
                                {trans("website.invoice")}
                                {" #"}
                                <span className="counter">{contractPayment?.paymentRequest?.id}</span>
                              </h3>
                              <div>
                                {trans("common.created_at")} <span>{contractPayment?.paymentRequest?.created_at}</span>
                                <br />
                                {["open", "attach-open"].includes(contractPayment?.paymentRequest?.status) && (
                                  <span className="bs-callout-warning callout-border-left callout-bordered mt-1 p-1">
                                    {trans("billing.payment-expire-at")} {contractPayment?.paymentRequest?.expected_date}
                                  </span>
                                )}
                                <br />
                                <span className="fw-bold">
                                  {contractPayment?.paymentRequest?.type === "single" && contractPayment?.paymentRequest?.reservation_id ? (
                                    <label className="label label-warning">{trans("billing.single-payment-request")}</label>
                                  ) : contractPayment?.paymentRequest?.order ? (
                                    <ul className="px-0 list-unstyled">
                                      <li>{trans("website.total-amount")}</li>
                                      <li className="lead text-bold-800">
                                        {contractPayment?.paymentRequest?.order?.items?.length === 1 &&
                                          contractPayment?.paymentRequest?.percentage > 0 &&
                                          contractPayment?.paymentRequest?.percentage < 1 && (
                                            <>
                                              {contractPayment?.paymentRequest?.percentage * 100}% {trans("common.of")}
                                            </>
                                          )}
                                        {contractPayment?.paymentRequest?.partial_value > 0 && contractPayment?.paymentRequest?.status === "paid" && (
                                          <>
                                            <b>{trans("website.payment_partial")}:</b> {contractPayment?.paymentRequest?.partial_value?.toFixed(2)}{" "}
                                            {appSettings?.currency_symbol}
                                            <br />
                                          </>
                                        )}
                                        {contractPayment?.paymentRequest?.partial_value > 0 &&
                                          (contractPayment?.paymentRequest?.status === "open" || contractPayment?.paymentRequest?.status === "attach-open") && (
                                            <>
                                              {
                                                (contractPayment.requestPayment.amount_temporary =
                                                  contractPayment?.paymentRequest?.amount - contractPayment?.paymentRequest?.partial_value)
                                              }
                                              <b>{trans("website.payment_partial")}:</b> {contractPayment?.paymentRequest?.partial_value?.toFixed(2)}{" "}
                                              {appSettings?.currency_symbol}
                                              <br />
                                              <b>{trans("website.recalculated_total")}:</b> {contractPayment?.paymentRequest?.amount_temporary?.toFixed(2)}{" "}
                                              {appSettings?.currency_symbol}
                                              <br />
                                            </>
                                          )}
                                        {contractPayment?.paymentRequest?.order.price_in_points > 0 ? (
                                          <>
                                            {Number(contractPayment?.paymentRequest?.amount)?.toFixed(2)} {appSettings?.currency_symbol} <br />
                                            <b>{trans("website.will_pay_with")}: </b>
                                            {contractPayment?.paymentRequest?.order.price_in_points} {trans("website.type-points")}
                                            <br />
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            {Number(contractPayment?.paymentRequest?.amount)?.toFixed(2)} {appSettings?.currency_symbol}
                                          </>
                                        )}
                                        <h6>
                                          {contractPayment?.paymentRequest?.resume?.rewards > 0 &&
                                            `${trans("website.will_win")}: ${contractPayment?.paymentRequest?.resume?.rewards} ${trans("website.type-points")}`}
                                        </h6>
                                        <br />
                                      </li>

                                      <li>
                                        {(!appSettings?.billing_allow_payments_on_expired_requests &&
                                          new Date(contractPayment?.paymentRequest?.expected_date) < new Date() &&
                                          (contractPayment?.paymentRequest?.status === "open" || contractPayment?.paymentRequest?.status === "attach-open")) ||
                                        contractPayment?.paymentRequest?.status === "expired" ? (
                                          <span className="badge badge-default badge-danger badge-lg">{trans("billing.payment-request-status-expired")}</span>
                                        ) : (
                                          <span className={`badge badge-default badge-${contractPayment?.paymentRequest?.statusColorBootStrap} badge-lg`}>
                                            {trans(`billing.payment-request-status-${contractPayment?.paymentRequest?.status}`)}
                                          </span>
                                        )}
                                      </li>
                                    </ul>
                                  ) : (
                                    <ul className="px-0 list-unstyled">
                                      <li>{trans("website.total-amount")}</li>
                                      <li className="lead text-bold-800">
                                        {Number(contractPayment?.paymentRequest?.amount)?.toFixed(2)} {appSettings?.currency_symbol}
                                      </li>
                                      <li>
                                        {(!appSettings?.billing_allow_payments_on_expired_requests &&
                                          new Date(contractPayment?.paymentRequest?.expected_date) < new Date() &&
                                          (contractPayment?.paymentRequest?.status === "open" || contractPayment?.paymentRequest?.status === "attach-open")) ||
                                        contractPayment?.paymentRequest?.status === "expired" ? (
                                          <span className="badge badge-default badge-danger badge-lg">{trans("billing.payment-request-status-expired")}</span>
                                        ) : (
                                          <span className={`badge badge-default badge-${contractPayment?.paymentRequest?.statusColorBootStrap} badge-lg`}>
                                            {trans(`billing.payment-request-status-${contractPayment?.paymentRequest?.status}`)}
                                          </span>
                                        )}
                                      </li>
                                    </ul>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {(appSettings?.billing_allow_payments_on_expired_requests &&
                        (contractPayment?.paymentRequest?.status === "open" || contractPayment?.paymentRequest?.status === "attach-open")) ||
                      new Date(contractPayment?.paymentRequest?.expected_date).getTime() > new Date().getTime() ? (
                        <div className="row">
                          <div>
                            <div>
                              <>
                                {contractPayment?.paymentRequest.status === "waiting-delivery" ? (
                                  <div className="card p-1 border-primary">
                                    <div className="card-content">
                                      <div className="card-body">
                                        <div className="float-left">
                                          <p className="mb-0">
                                            <strong>{trans("website.payment-delivery-you-had-choose")}</strong>
                                          </p>
                                          <p className="card-title">
                                            {trans(`website.payment-delivery-method-${contractPayment?.paymentRequest.payment_delivery_method}`)}
                                            {contractPayment?.paymentRequest.payment_delivery_method === "cash" &&
                                            contractPayment?.paymentRequest.payment_delivery_change ? (
                                              <>
                                                {trans("website.payment-delivery-with-change-for-cash")}{" "}
                                                {contractPayment?.paymentRequest.payment_delivery_change} {appSettings?.currency_symbol}
                                              </>
                                            ) : contractPayment?.paymentRequest.payment_delivery_method !== "cash" &&
                                              contractPayment?.paymentRequest.payment_delivery_card_brand ? (
                                              <>
                                                {trans("website.payment-delivery-with-card-brand")}{" "}
                                                {contractPayment?.paymentRequest.payment_delivery_card_brand}
                                              </>
                                            ) : null}
                                            .
                                            <br />
                                            {trans("website.payment-delivery-wait-message")}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : ["open", "attach-open"].includes(contractPayment?.paymentRequest.status) ? (
                                  <div className="d-none">
                                    {/* Hidden due top payment method selector on page header */}
                                    <h3>{trans("website.please-choose-payment-type")}</h3>
                                  </div>
                                ) : null}
                              </>
                              {/* Hidden due top payment method selector on page header */}
                              {contractPayment?.paymentRequest?.status === "open" || contractPayment?.paymentRequest?.status === "attach-open" ? (
                                <div className="d-flex flex-column d-none">
                                  {!selectedPaymentMethod?.code ? (
                                    <PaymentMethodsCardComponent
                                      paymentMethods={contractPayment?.paymentMethodsFinal}
                                      appSettings={appSettings}
                                      trans={trans}
                                      sessionNow={sessionNow}
                                      selectedCode={contractPayment?.selectedCode}
                                      setSelectedPaymentMethod={setSelectedPaymentMethod}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  <PaymentMethodsContentComponent
                                    paymentMethods={contractPayment?.paymentMethodsFinal}
                                    paymentRequest={contractPayment?.paymentRequest}
                                    appSettings={appSettings}
                                    trans={trans}
                                    sessionNow={sessionNow}
                                    selectedPaymentMethod={selectedPaymentMethod}
                                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                                  />
                                </div>
                              ) : ["paid", "attach-paid"].includes(contractPayment?.paymentRequest.status) ? (
                                <div className="p-2">
                                  <div className="d-flex flex-column">
                                    <div className="text-start text-md-start">
                                      <p className="text-muted">{trans("website.payment-data")}:</p>
                                    </div>
                                    <span className="text-muted">{trans("website.payment_made_at")}</span>
                                    <span className="text-muted mt-1">{contractPayment?.paymentRequest?.payment_made_at}</span>

                                    <br />
                                    {contractPayment?.paymentRequest?.stripe_receipt_url ? (
                                      <a
                                        href={contractPayment?.paymentRequest?.stripe_receipt_url}
                                        target="_blank"
                                        className="btn btn-sm btn-outline-success my-1"
                                        style={CSS({ width: "fit-content" })}
                                        rel="noreferrer"
                                      >
                                        <i className="la la-cc-stripe"></i>
                                        <span className="fs-7">{trans("website.payment-receipt-stripe")}</span>
                                      </a>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <InvoiceCustomerDetails paymentRequest={contractPayment?.paymentRequest} />
                      <EBSSSPrintButton />
                      <InvoiceCompanyDetails paymentRequest={contractPayment?.paymentRequest} appSettings={appSettings} trans={trans} />
                      <InvoiceItemsDetails paymentRequest={contractPayment?.paymentRequest} appSettings={appSettings} />
                      <PaymentDetails paymentRequest={contractPayment?.paymentRequest} order={contractPayment?.paymentRequest?.order} />
                      <PriceDetails
                        paymentRequest={contractPayment?.paymentRequest}
                        order={contractPayment?.paymentRequest?.order}
                        appSettings={appSettings}
                        trans={trans}
                      />
                    </div>
                  </section>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Page>
  ) : showLoginModal ? (
    <EBSSSModal hideFooter modalTitle={trans("website.login")} modal={showLoginModal} toggle={null}>
      <LoginForm outOriginalPage={true} />
    </EBSSSModal>
  ) : showVerifyModal ? (
    <EBSSSModal
      hideFooter
      modalSize={inAgreement ? "modal-xl" : "modal-md"}
      dismissable={Number(getPermissionParams?.newUserActivationRequired) === 0 && !alreadyVerified}
      modalTitle={trans("website.verify")}
      modal={showVerifyModal}
      toggle={toggle}
    >
      <RegisterForm
        setAsVerified={() => setAlreadyVerified(true)}
        setInTOS={() => setInAgreement(true)}
        outOriginalPage={true}
        startAtTokenVerify={true}
        verifyLaterButton={Number(getPermissionParams?.newUserActivationRequired) === 0}
        setEndRegistrationCheckoutModal={(p) => {
          setShowVerifyModal(!p);
          setRefreshPayment(true);
        }}
      />
    </EBSSSModal>
  ) : (
    <></>
  );
};

export default ContractPaymentPage;
