/* eslint-disable jsx-a11y/anchor-is-valid */
//@ts-nocheck
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ReCAPTCHA from "react-google-recaptcha";
import { PhoneInput } from "react-international-phone";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";
import { countryCodeToFlag } from "src/_utils/elements";
import { CSS, parseHTML } from "src/_utils/styles";
import { getCountryCode } from "src/_utils/validations";
import { Btn, H4, Image } from "src/AbstractElements";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { useFormValidation } from "src/base/hooks/form_validator_handler";
import { remotePath } from "src/base/settings/Paths";
import { editProfile, Session } from "src/base/store/authSlice";
import { Layout } from "src/base/store/layoutSlice";
import { ReduxDataKey } from "src/base/store/store";

export function combineFieldValues(inputArray) {
  const result = {};

  inputArray.forEach((item) => {
    if (!result[item.field]) {
      result[item.field] = [];
    }
    result[item.field].push(item.value);
  });

  return result;
}

export function convertDateFormat(dateString, hideSecs = false) {
  let date;
  if (typeof dateString === "string") {
    date = new Date(dateString);
  } else {
    date = dateString;
  }

  // Extract the date and time components
  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0");
  const day = String(date?.getDate()).padStart(2, "0");
  const hours = String(date?.getHours()).padStart(2, "0");
  const minutes = String(date?.getMinutes()).padStart(2, "0");
  const seconds = String(date?.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}${hideSecs ? "" : ":" + seconds}`;
}

export const SentFormFeedback = ({ trans, authUser }) => {
  const [submitted] = useState(true);
  const [animationComplete, setAnimationComplete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setAnimationComplete(true);
    }, 1000);
  }, []);

  return (
    <div>
      {submitted && (
        <div
          className={`d-flex flex-column text-center ebsss-sent-ticket-feedback-animated-div ebsss-sent-from-form ${
            animationComplete ? "ebsss-sent-ticket-feedback-complete" : ""
          }`}
          style={CSS({
            backgroundColor: window.location.href.includes("/forms/") ? "#fff" : "#00000090",
          })}
        >
          {animationComplete && (
            <>
              <Image
                attrImage={{
                  src: remotePath.staticsUrl("congratzform.svg"),
                  width: 300,
                  height: 300,
                  loading: "lazy",
                }}
              />
              <span className="lead">{trans("website.your-form-was-sent")}</span>
              <h6 className="fw-bold">{trans("website.we-will-reply-soon")}</h6>
              <hr />
              <div
                className="d-flex flex-row align-items-center gap-2"
                style={CSS({ cursor: "pointer" })}
                onClick={() => {
                  authUser?.id ? navigate(process.env.PUBLIC_URL + "/forms") : navigate(process.env.PUBLIC_URL + "/crm/home");
                }}
              >
                <button className="btn btn-success btn-sm">
                  <i className="fa fa-arrow-left fs-7"></i> {trans("common.back")}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export const DatePick = ({ onChange, name, value, ruleFilter }: {}) => {
  const startCount = new Date();

  const filterDays = (date, start) => {
    if (ruleFilter === "date-future" && date.getTime() < startCount.getTime()) {
      return false;
    }
    if (ruleFilter === "date-past" && date.getTime() > startCount.getTime()) {
      return false;
    }
    return true;
  };

  return (
    <div className="daterange-card">
      <div className="theme-form">
        <FormGroup>
          <DatePicker
            className="form-control digits"
            selected={new Date(!value ? new Date().getTime() : new Date(new Date(value).getTime() + 86400000))}
            onKeyDown={(e) => {
              e.preventDefault();
              return;
            }}
            onChange={(update: Date) => {
              console.log(update);
              if (!update) {
                return;
              }
              onChange(convertDateFormat(update).split(" ")[0], name);
            }}
            filterDate={(d) => filterDays(d, startCount)}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export const FieldHelper = ({ field, trans }) => {
  const togglerFn = () => {
    document.querySelector(`.help_toggler_${field.token}`).classList.toggle("d-none");
    document.querySelector(`.help_${field.token}`).classList.toggle("d-none");
  };
  return field.help ? (
    <div className="mt-1">
      <a className={`help-button help_toggler_${field.token} text-muted`} style={CSS({ cursor: "pointer" })} onClick={togglerFn}>
        <i className="la la-plus-circle me-1"></i>
        {trans("website.form-field-help-show")}
      </a>
      <div
        className={`help_${field.token} p-2 shadow-sm rounded-2 border-2 d-none d-flex justify-content-between flex-row align-items-center`}
        style={CSS({ border: "1px solid #e9e9e9" })}
      >
        <div>
          <i className="la la-info-circle me-1"></i>
          {parseHTML(field.help)}
        </div>
        <div onClick={togglerFn} style={CSS({ cursor: "pointer" })}>
          <i className="la la-close me-1"></i>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export const FormUser = ({ form, user, handleInputChange, formData, step, trans }) => {
  return !user?.id ? (
    <div className="mb-3 text-muted" data-step-id={step}>
      {form?.form?.crm_b2b !== 1 || form?.form?.type !== "b2b" ? (
        <>
          <div className="mb-3 text-muted">
            <label className="form-label text-muted" htmlFor="lead_name">
              {trans("common.name")}
            </label>
            <input
              type="text"
              className="form-control"
              id="lead_name"
              name="lead_name"
              autoComplete="off"
              spellCheck="false"
              maxLength="255"
              value={formData.lead_name}
              onChange={handleInputChange}
            />
            <div id={`invalid-lead_name-feedback-validation`}></div>
          </div>
          <div className="mb-3 text-muted">
            <label className="form-label text-muted" htmlFor="lead_email">
              {trans("common.email")}
            </label>
            <input
              type="email"
              className="form-control"
              id="lead_email"
              name="lead_email"
              autoComplete="off"
              spellCheck="false"
              maxLength="255"
              value={formData.lead_email}
              onChange={handleInputChange}
            />
            <div id={`invalid-lead_email-feedback-validation`}></div>
          </div>
          <div className="mb-3 text-muted">
            <label className="form-label text-muted" htmlFor="lead_phone">
              {trans("common.mobile_number")}
            </label>
            <PhoneInput
              inputStyle={CSS({
                width: "100%",
                display: "flex",
                borderEndEndRadius: 5,
                borderStartEndRadius: 5,
              })}
              countrySelectorStyleProps={{
                dropdownStyleProps: {
                  style: CSS({
                    zIndex: 999999,
                  }),
                },
                buttonStyle: CSS({
                  backgroundColor: "#EFEFFD",
                  border: 0,
                }),
              }}
              required
              id="lead_phone"
              name="lead_phone"
              defaultCountry={getCountryCode()}
              value={formData.lead_phone}
              onChange={(v) => handleInputChange(v.replace("+", ""), "lead_phone")}
            />

            <div id={`invalid-lead_phone-feedback-validation`}></div>
          </div>

          {form?.form?.vat_required ? (
            <>
              <div>
                <label className="form-label text-muted" htmlFor="lead_vat">
                  {trans("common.vat_number")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lead_vat"
                  name="lead_vat"
                  autoComplete="off"
                  spellCheck="false"
                  maxLength="30"
                  value={formData.lead_vat}
                  onChange={handleInputChange}
                />
                <div id={`invalid-lead_vat-feedback-validation`}></div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div className="form-label text-muted">{trans("website.company-select")}</div>
          <select id="company_token" name="company_token" className="selectpicker form-control" value={formData.company_token} onChange={handleInputChange}>
            {user.companies.map((company) => (
              <option key={company.token} value={company.token}>
                {company.getFullName()}
              </option>
            ))}
          </select>
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export const TextField = ({ field, identifier, handleInputChange, trans, value }) => {
  console.log("FIELD TEXT: ", field);
  return (
    <div className="mb-3 text-muted">
      <label className="form-label text-muted">{field.label}</label>
      <input onChange={handleInputChange} value={value} type="text" className="form-control" name={identifier} validable={field?.name} id={identifier} />

      <div id={`invalid-${field?.name}-feedback-validation`}></div>
      <FieldHelper trans={trans} field={field} />
    </div>
  );
};

export const CheckboxField = ({ field, identifier, values, trans, value, handleInputChange, formData }) => {
  useEffect(() => {
    if (!value) {
      if (values?.length > 0) {
        handleInputChange(values[0]?.token, identifier, true);
      }
    }
  }, [identifier]);
  return (
    <div className="mb-3 text-muted">
      <label className="form-label text-muted" htmlFor={identifier}>
        {field.label}
      </label>
      <div
        className="d-flex flex-column gap-2"
        style={CSS({
          paddingTop: 10,
          marginBottom: 10,
        })}
      >
        {values?.map((val, i) => {
          let itsIncluded = formData[identifier]?.includes(val.token);
          return (
            <div
              onClick={(e) => {
                if (!itsIncluded) {
                  handleInputChange(val.token, identifier, true);
                } else {
                  handleInputChange("@REMOVAL_" + val.token, identifier, true);
                }
              }}
              style={CSS({
                cursor: "pointer",
              })}
              className="gap-2 p-1 rounded-2 d-flex flex-row align-items-center justify-content-start"
            >
              {itsIncluded ? (
                <i style={CSS({ border: "1px solid #d9d9d9" })} className="border la la-check fs-6 text-success"></i>
              ) : (
                <i style={CSS({ border: "1px solid #d9d9d9", color: "#e9e9e9" })} className="border la la-check fs-6"></i>
              )}
              <span>{val.label}</span>
            </div>
          );
        })}
      </div>
      <div id={`invalid-${field?.name}-feedback-validation`}></div>
      <FieldHelper trans={trans} field={field} />
    </div>
  );
};

export const SelectField = ({ field, identifier, values, trans, value, handleInputChange }) => {
  useEffect(() => {
    if (!value) {
      if (!field.required) {
        handleInputChange("", identifier);
        return;
      }
      if (values?.length > 0) {
        handleInputChange(values[0]?.token, identifier);
      }
    }
  }, [identifier]);
  return (
    <div className="mb-3 text-muted">
      <label className="form-label text-muted">{field.label}</label>
      <select onChange={handleInputChange} value={value} id={identifier} name={identifier} validable={field?.name} className="selectpicker form-control">
        {!field.required ? <option value="">{trans("website.form-none-option")}</option> : <></>}
        {values?.map((val, i) => (
          <option key={i} value={val?.token ? val?.token : i}>
            {val.label}
          </option>
        ))}
      </select>
      <div id={`invalid-${field?.name}-feedback-validation`}></div>
      <FieldHelper trans={trans} field={field} />
    </div>
  );
};

export const FileField = ({ field, identifier, trans, value, handleInputChange }) => (
  <div className="mb-3 text-muted">
    <label className="form-label text-muted" htmlFor={identifier} style={{ position: "relative" }}>
      {field.label}
    </label>
    <input
      onChange={(e) => {
        console.log(e.target.files[0]);
        handleInputChange(e, null, false, null, true);
      }}
      type="file"
      className="form-control"
      name={identifier}
      validable={field?.name}
      id={identifier}
    />
    <div id={`invalid-${field?.name}-feedback-validation`}></div>
    <FieldHelper trans={trans} field={field} />
  </div>
);

export const BooleanField = ({ field, identifier, trans, value, handleInputChange }) => {
  useEffect(() => {
    if (!value) {
      handleInputChange("1", identifier);
    }
  }, [identifier]);

  return (
    <div className="mb-3 text-muted">
      <label className="form-label text-muted">{field.label}</label>
      <select onChange={handleInputChange} value={value} id={identifier} name={identifier} validable={field?.name} className="selectpicker form-control">
        <option value="1">{trans("website.form-field-boolean-yes")}</option>
        <option value="0">{trans("website.form-field-boolean-no")}</option>
      </select>
      <div id={`invalid-${field?.name}-feedback-validation`}></div>
      <FieldHelper trans={trans} field={field} />
    </div>
  );
};

export const DateField = ({ field, identifier, trans, value, handleInputChange, ruleFilter }) => (
  <div className="mb-3 text-muted">
    <label className="form-label text-muted">{field.label}</label>
    <div className="input-group">
      <DatePick ruleFilter={ruleFilter} name={identifier} validable={field?.name} onChange={handleInputChange} value={value} />
    </div>
    <div id={`invalid-${field?.name}-feedback-validation`}></div>
    <FieldHelper trans={trans} field={field} />
  </div>
);

export const TimeField = ({ field, identifier, trans, value, handleInputChange }) => (
  <div className="mb-3 text-muted">
    <label className="form-label text-muted">{field.label}</label>
    <div className="input-group time">
      <input
        onChange={handleInputChange}
        value={value}
        type="time"
        id={identifier}
        name={identifier}
        validable={field?.name}
        className="form-control formInput"
        min="00:00"
        max="23:00"
        required
      />
    </div>
    <div id={`invalid-${field?.name}-feedback-validation`}></div>
    <FieldHelper trans={trans} field={field} />
  </div>
);

export const RadioField = ({ field, identifier, trans, values, value, handleInputChange }) => {
  useEffect(() => {
    if (!value) {
      if (!field.required) {
        handleInputChange("", identifier);
        return;
      }
      if (values?.length > 0) {
        handleInputChange(values[0]?.token, identifier);
      }
    }
  }, [identifier]);
  return (
    <div className="mb-3 text-muted">
      <label className="form-label text-muted" htmlFor={identifier}>
        {field.label}
      </label>
      <div
        className="d-flex flex-column gap-2"
        style={CSS({
          paddingTop: 10,
          marginBottom: 10,
        })}
      >
        {values?.map((val, i) => {
          let itsIncluded = value === val.token;
          return (
            <div
              onClick={(e) => {
                if (!itsIncluded) {
                  handleInputChange(val.token, identifier);
                }
              }}
              style={CSS({
                cursor: "pointer",
              })}
              className="gap-2 p-1 rounded-2 d-flex flex-row align-items-center justify-content-start"
            >
              {itsIncluded ? (
                <i className="la la-check-circle fs-5 text-success"></i>
              ) : (
                <i className="la la-check-circle fs-5" style={CSS({ color: "#e9e9e9" })}></i>
              )}
              <span>{val.label}</span>
            </div>
          );
        })}
      </div>
      <div id={`invalid-${field?.name}-feedback-validation`}></div>
      <FieldHelper trans={trans} field={field} />
    </div>
  );
};

export const NumberField = ({ field, identifier, trans, value, handleInputChange, rule }) => (
  <div className="mb-3 text-muted">
    <label className="form-label text-muted" htmlFor={identifier}>
      {field.label}
    </label>
    <input
      minLength={field.type === "tour-participants" || field.type === "positive-number" ? "1" : "0"}
      onChange={(e) => {
        if ((field.type === "tour-participants" || field.type === "positive-number") && e.target.valueAsNumber < 1) {
          handleInputChange(1, identifier);
          return;
        }
        handleInputChange(e.target.valueAsNumber, identifier);
      }}
      value={value}
      type="number"
      className="form-control"
      id={identifier}
      name={identifier}
      validable={field?.name}
      min={field.type === "tour-participants" || field.type === "positive-number" ? 1 : undefined}
    />
    <div id={`invalid-${field?.name}-feedback-validation`}></div>
    <FieldHelper trans={trans} field={field} />
  </div>
);

export const ColorField = ({ handleInputChange, identifier, products, field, trans, formData, value }) => {
  useEffect(() => {
    if (!value) {
      handleInputChange("#000000", identifier);
    }
  }, [identifier]);

  return (
    <div className="mb-3 text-muted">
      <label className="col-form-label pt-0">{field?.label}</label>
      <Col>
        <Input
          value={value}
          id={identifier}
          name={identifier}
          validable={field?.name}
          onChange={handleInputChange}
          className="form-control-color"
          style={{ height: "40px" }}
          type="color"
          defaultValue="#000000"
        />
      </Col>
    </div>
  );
};

export const AttributesField = ({ handleInputChange, identifier, products, field, trans, formData, value }) => {
  useEffect(() => {
    if (!value && JSON.parse(field?.prd_attribute?.values)?.length) {
      handleInputChange(0, identifier);
    }
  }, []);
  return (
    <div className="mb-3 text-muted">
      <label className="col-form-label pt-0">{field?.label}</label>
      <select
        value={value}
        id={identifier}
        name={identifier}
        validable={field?.name}
        onChange={handleInputChange}
        className="selectpicker form-control"
        value={value}
      >
        {JSON.parse(field.prd_attribute.values)?.map((attr, i) => (
          <option key={attr} value={i}>
            {attr}
          </option>
        ))}
      </select>
    </div>
  );
};

export const ProductsField = ({ products, identifier, field, handleInputChange, formData, trans }) => {
  const [toFind, setToFind] = useState("");
  const findProduct = (arr) => {
    let copyProds = [...arr];
    if (toFind) {
      copyProds = copyProds.filter((x) => x[1].toLowerCase().includes(toFind.toLowerCase()));
    }
    return copyProds?.sort((a, b) => {
      const includesA = formData[identifier].includes(a[0]);
      const includesB = formData[identifier].includes(b[0]);
      return includesA === includesB ? 0 : includesA ? -1 : 1;
    });
  };
  console.log("PRODS> ", products);
  return (
    <div>
      <div className="mb-3 text-muted">
        <label className="form-label text-muted">{field?.label}</label>
        <small>{trans("website.search")}</small>
        <input onChange={(e) => setToFind(e.target.value)} value={toFind} type="text" className="form-control" />
        <div
          className="d-flex flex-column gap-2"
          style={CSS({
            minHeight: 100,
            maxHeight: 150,
            overflowX: "none",
            overflowY: "auto",
            paddingTop: 10,
            marginBottom: 10,
          })}
        >
          {findProduct(Object.entries(products))?.map((prd, iPrd) => {
            let itsIncluded = formData[identifier].includes(prd[0]);
            return (
              <div
                onClick={(e) => {
                  if (!itsIncluded) {
                    handleInputChange(prd[0], identifier, true);
                  }
                }}
                style={CSS({
                  border: !itsIncluded ? "1px solid #e9e9e9" : "1px solid green",
                  marginTop: iPrd === 0 ? 10 : 0,
                })}
                className="p-1 ps-3 pe-3 shadow-sm rounded-2 d-flex flex-row align-items-center justify-content-between"
              >
                <span>{prd[1]}</span>
                {itsIncluded ? <i className="la la-check-circle text-success"></i> : <></>}
              </div>
            );
          })}
        </div>
        <div id={`invalid-${field?.name}-feedback-validation`}></div>
        <FieldHelper trans={trans} field={field} />
      </div>
    </div>
  );
};

export const AddressField = ({ field, identifier, value, trans, valueLatitude, valueLongitude, handleInputChange, formData }) => {
  function initGoogleMapsAPI() {
    let inputLocationSearch = document.getElementById("location-search");
    let autocomplete = new google.maps.places.Autocomplete(inputLocationSearch);

    autocomplete.addListener("place_changed", function () {
      let place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      let lat = place.geometry.location.lat();
      let lng = place.geometry.location.lng();

      console.log("Coords without map: ", {
        [`${identifier}_latitude`]: lat,
        [`${identifier}_longitude`]: lng,
        [`${identifier}`]: place.formatted_address,
      });

      handleInputChange(
        {
          [`${identifier}_latitude`]: lat,
          [`${identifier}_longitude`]: lng,
          [`${identifier}`]: place.formatted_address,
        },
        null,
        false,
        true
      );
    });
  }

  useEffect(() => {
    initGoogleMapsAPI();
  }, []);

  return (
    <div className="mb-3 text-muted">
      <label className="form-label text-muted" htmlFor={identifier}>
        {field.label}
      </label>
      <input
        type="text"
        id="location-search"
        placeholder={trans("website.location")}
        className="form-control formatted_address formInput"
        name={identifier}
        validable={field?.name}
      />
      <div id={`invalid-${field?.name}-feedback-validation`}></div>
      <FieldHelper trans={trans} field={field} />
    </div>
  );
};

const AddressFieldMap = ({ field, identifier, value, trans, valueLatitude, valueLongitude, handleInputChange, formData }) => {
  function initGoogleMapsAPI() {
    let inputLocationSearch = document.getElementById("location-search-with-map");
    let map = new google.maps.Map(document.getElementById("map"));
    let autocomplete = new google.maps.places.Autocomplete(inputLocationSearch);

    autocomplete.addListener("place_changed", function () {
      let place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      map.setCenter(place.geometry.location);
      map.setZoom(15);

      let marker = new google.maps.Marker({
        map: map,
        position: place.geometry.location,
        draggable: true,
      });

      google.maps.event.addListener(marker, "dragend", function () {
        let geocoder = new google.maps.Geocoder();
        let lat = marker.getPosition().lat();
        let lng = marker.getPosition().lng();
        let latlng = new google.maps.LatLng(lat, lng);

        console.log("Coords with map: ", {
          [`${identifier}_latitude`]: lat,
          [`${identifier}_longitude`]: lng,
          [`${identifier}`]: place.formatted_address,
        });

        handleInputChange(
          {
            [`${identifier}_latitude`]: lat,
            [`${identifier}_longitude`]: lng,
            [`${identifier}`]: place.formatted_address,
          },
          null,
          false,
          true
        );
      });
    });
  }

  useEffect(() => {
    initGoogleMapsAPI();
  }, []);

  return (
    <div className="mb-3 text-muted">
      <label className="form-label text-muted" htmlFor={identifier}>
        {field.label}
      </label>
      <input
        type="text"
        id="location-search-with-map"
        placeholder={trans("website.location")}
        className="form-control formatted_address formInput"
        name={identifier}
        validable={field?.name}
      />
      <div
        id="map"
        style={CSS({
          width: "100%",
          height: 300,
          marginTop: 5,
          borderRadius: 5,
        })}
      ></div>
      <div id={`invalid-${field?.name}-feedback-validation`}></div>
      <FieldHelper trans={trans} field={field} />
    </div>
  );
};

export const Field = ({ field, identifier, products, values, trans, formVal, formData, formDataForAddress, handleInputChange }) => {
  return (
    <div className="form-group">
      {field.type === "checkbox" ? (
        <CheckboxField
          identifier={identifier}
          handleInputChange={handleInputChange}
          field={field}
          value={formVal}
          trans={trans}
          formData={formData}
          values={field?.json_values?.map((val, i) => {
            return {
              token: i,
              label: val,
            };
          })}
        />
      ) : field.type === "select" ? (
        <SelectField
          identifier={identifier}
          handleInputChange={handleInputChange}
          value={formVal}
          trans={trans}
          field={field}
          values={
            field?.tourProducts || field?.services
              ? Object.entries(field?.tourProducts ? field?.tourProducts : field?.services).map((tpOrSr) => {
                  return {
                    token: tpOrSr[0],
                    label: tpOrSr[1],
                  };
                })
              : field?.json_values?.map((val, i) => {
                  return {
                    token: i,
                    label: val,
                  };
                })
          }
          trans={trans}
        />
      ) : field.type === "file" ? (
        <FileField identifier={identifier} handleInputChange={handleInputChange} value={formVal} trans={trans} field={field} />
      ) : field.type === "boolean" ? (
        <BooleanField identifier={identifier} handleInputChange={handleInputChange} value={formVal} trans={trans} field={field} trans={trans} />
      ) : field.type === "date" || field.type === "date-future" || field.type === "date-past" ? (
        <DateField identifier={identifier} handleInputChange={handleInputChange} value={formVal} trans={trans} field={field} ruleFilter={field.type} />
      ) : field.type === "time" ? (
        <TimeField identifier={identifier} handleInputChange={handleInputChange} value={formVal} trans={trans} field={field} />
      ) : field.type === "radio" ? (
        <RadioField
          identifier={identifier}
          handleInputChange={handleInputChange}
          value={formVal}
          trans={trans}
          field={field}
          values={field?.json_values?.map((val, i) => {
            return {
              token: i,
              label: val,
            };
          })}
        />
      ) : field.type === "number" || field.type === "tour-participants" || field.type === "positive-number" ? (
        <NumberField identifier={identifier} handleInputChange={handleInputChange} value={formVal} trans={trans} field={field} rule={field.type} />
      ) : field.type === "address" ? (
        <AddressField
          identifier={identifier}
          handleInputChange={handleInputChange}
          value={formVal}
          trans={trans}
          valueLatitude={formDataForAddress[identifier + "_latitude"]}
          valueLongitude={formDataForAddress[identifier + "_longitude"]}
          field={field}
          formData={formData}
        />
      ) : field.type === "address-map" ? (
        <AddressFieldMap
          identifier={identifier}
          handleInputChange={handleInputChange}
          value={formVal}
          trans={trans}
          valueLatitude={formDataForAddress[identifier + "_latitude"]}
          valueLongitude={formDataForAddress[identifier + "_longitude"]}
          field={field}
          formData={formData}
        />
      ) : field.type === "products" ? (
        <ProductsField identifier={identifier} handleInputChange={handleInputChange} products={products} field={field} trans={trans} formData={formData} />
      ) : field.type === "color" ? (
        <ColorField
          identifier={identifier}
          handleInputChange={handleInputChange}
          products={products}
          field={field}
          trans={trans}
          formData={formData}
          value={formVal}
        />
      ) : field.type === "attribute" ? (
        <AttributesField identifier={identifier} handleInputChange={handleInputChange} value={formVal} trans={trans} field={field} />
      ) : (
        <TextField identifier={identifier} handleInputChange={handleInputChange} value={formVal} trans={trans} field={field} />
      )}
    </div>
  );
};

export const FormComponent = ({ form, trans, authUser, sendForm, appSettings, setSentForm }) => {
  const [step, setStep] = useState(0);
  const [formValidation] = useFormValidation(trans, "validable");
  const [formData, setFormData] = useState({});
  const [reachedFinish, setReachedFinish] = useState(false);
  const [reachedFinishSooner, setReachedFinishSooner] = useState(false);
  const [registrySteps, setRegistrySteps] = useState([]);

  useEffect(() => {
    if (!registrySteps.includes(step)) {
      console.log("Step dont included yet, adding...: ", [...registrySteps, step]);
      setRegistrySteps([...registrySteps, step]);
    }
    if (document.querySelectorAll('[id^="invalid-"]').length > 0) {
      document.querySelectorAll('[id^="invalid-"]').forEach((el) => el.remove());
    }
    if (document.querySelectorAll(".is-valid").length > 0) {
      document.querySelectorAll(".is-valid").forEach((el) => el.classList.remove("is-valid"));
    }
    if (document.querySelectorAll(".is-invalid").length > 0) {
      document.querySelectorAll(".is-invalid").forEach((el) => el.classList.remove("is-invalid"));
    }
  }, [step]);

  useEffect(() => {
    if (form) {
      let initialObjFormData = {
        form: form ? form?.form?.token : "",
        lead_name: authUser?.id ? authUser?.profile?.first_name + " " + authUser?.profile?.last_name : "",
        lead_email: authUser?.id ? authUser?.email : "",
        lead_phone: authUser?.id ? authUser?.mobile_number : "",
        lead_vat: authUser?.id && authUser?.profile ? authUser?.profile.vat_number : "",
        agreement: "off",
        employee_token: form?.employee ? form?.employee?.token : "",
        company_token: form?.companies?.length > 0 ? form?.companies[0].token : "",
      };

      form?.form_fields.forEach((f) => {
        initialObjFormData[f?.token /* old fieldName */] = "";
      });

      setFormData(initialObjFormData);
    }
  }, [form]);

  const handleInputChange = (e, k = "", isArray = false, manyOnce = null, isFile = false) => {
    let partialIncludingFormData = {
      ...formData,
    };

    // if (!manyOnce) {
    //   partialIncludingFormData = {
    //     ...partialIncludingFormData,
    //     [k ? k : e?.target?.name]: !isArray
    //       ? e?.target
    //         ? e.target.value
    //         : e
    //       : [...formData[k ? k : e?.target?.name], e],
    //   };
    // } else {
    //   partialIncludingFormData = {
    //     ...partialIncludingFormData,
    //     ...e,
    //   };
    // }

    if (isFile) {
      partialIncludingFormData = {
        ...partialIncludingFormData,
        [k ? k : e?.target?.name]: e?.target?.files[0],
      };
      setFormData({ ...partialIncludingFormData });
      return;
    }

    if (!manyOnce) {
      if (!isArray) {
        console.log("isnt array");
        partialIncludingFormData = {
          ...partialIncludingFormData,
          [k ? k : e?.target?.name]: e?.target ? e.target.value : e,
        };
      } else if (isArray && typeof e === "string" && e?.startsWith("@REMOVAL_")) {
        console.log("is array and must be removed");
        let toRemv = e.split("_").pop();
        partialIncludingFormData[k] = partialIncludingFormData[k].filter((vals) => Number(vals) !== Number(toRemv));
      } else {
        console.log("is array and must be included");
        partialIncludingFormData = {
          ...partialIncludingFormData,
          [k ? k : e?.target?.name]: [...formData[k ? k : e?.target?.name], e],
        };
      }
    } else {
      console.log("isnt array and must to include many at once");
      partialIncludingFormData = {
        ...partialIncludingFormData,
        ...e,
      };
    }

    setFormData({ ...partialIncludingFormData });
  };

  let checkStepConditionalsBackward = () => {
    let backInRegistryIndex = registrySteps.findIndex((v) => v === step);

    if (backInRegistryIndex !== -1) {
      if (registrySteps[backInRegistryIndex - 1]) {
        setStep(registrySteps[backInRegistryIndex - 1]);
      } else {
        setStep(0);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if ((!form?.form?.step_fields || reachedFinish || reachedFinishSooner) && formData.agreement !== "on") {
      toast(trans("website.form-please-accept-terms"), {
        type: "error",
      });
      return;
    }

    if ((!form?.form?.step_fields || reachedFinish || reachedFinishSooner) && appSettings?.crm_captcha && !formData["g-recaptcha-response"]) {
      toast(trans("website.form-please-confirm-captcha"), {
        type: "error",
      });
      return;
    }

    let requiredUser = []
      .concat(form?.form?.name_required ? [{ name: "lead_name" }] : [])
      .concat(form?.form?.email_required ? [{ name: "lead_email", rule: { email: true } }] : [])
      .concat(form?.form?.vat_required ? [{ name: "lead_vat", rule: { isVAT: true } }] : [])
      .concat(form?.form?.phone_required ? [{ name: "lead_phone" }] : []);

    formValidation(
      form?.form_fields
        ?.map((ff) => {
          let partialRules = {
            inputIdentifier: ff.name,
            feedbackElement: ff.name + "-feedback",
            rules: {},
          };

          if (ff.required) {
            partialRules.rules["required"] = true;
          }

          return partialRules;
        })
        .concat(
          requiredUser.map((ff) => {
            return {
              inputIdentifier: ff.name,
              feedbackElement: ff.name + "-feedback",
              rules: {
                ...(ff?.rule ?? {}),
                required: true,
              },
            };
          })
        )
        .filter((fds, idx) => (form?.form?.step_fields ? idx === step : true)),
      e,
      async () => {
        let checkStepConditionals = () => {
          let endChecking = false;
          for (let tempStep = step + 1; tempStep < form?.form_fields?.length; tempStep++) {
            if (!endChecking) {
              console.log("TEMP STEP: ", tempStep);
              if (!!form?.form_fields[tempStep]) {
                let allFields = form?.form_fields;
                let nextStep = allFields[tempStep];

                let actualStepName = allFields[step]?.token; /* old fieldName */
                let actualStepAnswer = String(formData[actualStepName]);

                console.log("CHECKING STEP BY ANSWER: \n", actualStepName, ": ", actualStepAnswer);

                if (nextStep.conditions) {
                  let conditionsNext = combineFieldValues(JSON.parse(nextStep.conditions));

                  if (
                    conditionsNext[allFields.find((f) => f.token === actualStepName)?.name] &&
                    conditionsNext[allFields.find((f) => f.token === actualStepName)?.name]?.includes(String(actualStepAnswer))
                  ) {
                    console.log("SHOW NEXT BASED ON CONDITIONAL");
                    setRegistrySteps(registrySteps.filter((v) => v < tempStep));
                    setStep((s) => {
                      return tempStep;
                    });
                    endChecking = true;
                    return;
                  }

                  if (tempStep + 1 === form?.form_fields?.length) {
                    //console.log("END STEPS AFTER CHECKING ALL CONDITIONALS");
                    setReachedFinish(true);
                    setReachedFinishSooner(true);
                    document.querySelectorAll(".u-pearl").forEach((el) => {
                      el.classList.add("done");
                    });
                    endChecking = true;
                    return;
                  }
                } else {
                  console.log("SHOW NEXT BASED ON LACK OF CONDITIONAL");
                  setRegistrySteps(registrySteps.filter((v) => v < tempStep));
                  setStep((s) => {
                    return tempStep;
                  });
                  endChecking = true;
                  return;
                }
              }
            }
          }
        };

        if (form?.form?.step_fields) {
          if (form?.form_fields?.length - step !== 1) {
            console.log("CONTINUE CHECKING...");
            checkStepConditionals();
          } else {
            console.log("END NOW");
            setReachedFinish(true);
            setReachedFinishSooner(true);
          }
        } else {
          console.log("HAS NO STEPS");
          await makeFormSubmit();
        }
      }
    );

    return;
  };

  console.log(formData);

  const makeFormSubmit = async () => {
    try {
      let fdt = new FormData();
      Object.entries(formData).forEach((t: [string, any]) => {
        fdt.append(t[0], t[1]);
      });

      let resFormSent = await sendForm(
        fdt,
        form?.form_fields?.some((ff) => ff.type === "file")
      );
      console.log("RES FORM SENT: ", resFormSent);
      if (resFormSent.slug) {
        setSentForm(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`container-child col-md-${form?.div_full_size ? "12" : "8"} col-sm-12`} style={{ paddingTop: "25px" }}>
      {form?.form?.step_fields ? (
        <Row className="u-pearls-xs mb-5 mt-5">
          {form?.form_fields?.map((ff, i) => {
            return (
              <div className={`u-pearl ${step === i ? "current" : step > i ? "done" : ""} ${"col"}`}>
                <span
                  style={CSS({
                    border: ff.conditions ? "1px dotted #6362e7" : "",
                  })}
                  className="u-pearl-number"
                  onClick={() => {
                    //setStep(i);
                  }}
                >
                  {i + 1}
                </span>
              </div>
            );
          })}
        </Row>
      ) : (
        <></>
      )}
      <Form onSubmit={handleSubmit}>
        <FormUser trans={trans} form={form} step={step} user={authUser} handleInputChange={handleInputChange} formData={formData} />
        {(form?.form?.step_fields ? [form?.form_fields[step]] : form?.form_fields)?.map((f) => {
          return (
            <Field
              formData={formData}
              form={form?.form}
              products={form?.products}
              field={f}
              trans={trans}
              formVal={formData[f?.token /* old fieldName */]}
              identifier={f?.token /* old fieldName */}
              formDataForAddress={formData}
              handleInputChange={handleInputChange}
            />
          );
        })}
        {(reachedFinish && step + 1 === form?.form_fields?.length) || reachedFinishSooner ? (
          <Card>
            <CardBody>
              <H4 attrH4={{ style: CSS({ fontWeight: 300 }) }}>{trans("website.your_data")}</H4>
              {Object.entries(formData).map((inputData) => {
                let getField = form?.form_fields?.find((ffs) => ffs?.token /* old fieldName */ === inputData[0]);

                return getField ? (
                  <div className="d-flex flex-column mb-2" key={inputData[0]}>
                    <span className="small text-muted">{getField?.label}:</span>
                    <span className="">
                      {Array.isArray(inputData[1]) ? (
                        inputData[1].map((ans) => (
                          <div className="p-1 d-flex flex-row align-items-center justify-content-start" key={ans}>
                            <i
                              style={CSS({ cursor: "pointer" })}
                              className="la la-trash text-danger me-2"
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  [getField?.token /* old fieldName */]: [...formData[getField?.token /* old fieldName */].filter((pds) => pds !== ans)],
                                });
                              }}
                            ></i>
                            <span>{Object.entries(form?.products)?.find((prd) => prd[0] === ans)?.[1]}</span>
                          </div>
                        ))
                      ) : inputData[1] instanceof File ? (
                        <div className="p-1 d-flex flex-row align-items-center justify-content-start">
                          <i
                            style={CSS({ cursor: "pointer" })}
                            className="la la-trash text-danger me-2"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                [getField?.token /* old fieldName */]: null,
                              });
                            }}
                          ></i>
                          <span>{inputData[1]?.name}</span>
                        </div>
                      ) : (
                        <span>{inputData[1]}</span>
                      )}
                    </span>
                  </div>
                ) : (
                  <></>
                );
              })}
            </CardBody>
          </Card>
        ) : (
          <></>
        )}
        <FormButtons
          form={form}
          makeFormSubmit={makeFormSubmit}
          handleSubmit={handleSubmit}
          handleBacking={checkStepConditionalsBackward}
          step={step}
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
          appSettings={appSettings}
          stepped={form?.form?.step_fields}
          stepCount={form?.form_fields?.length}
          reachedFinish={reachedFinish}
          reachedFinishSooner={reachedFinishSooner}
          trans={trans}
        />
      </Form>
    </div>
  );
};

export const FormButtons = ({
  form,
  trans,
  step,
  setStep,
  appSettings,
  stepped,
  stepCount,
  reachedFinish,
  reachedFinishSooner,
  formData,
  setFormData,
  handleSubmit,
  handleBacking,
  makeFormSubmit,
}) => {
  console.log("Stepped: ", stepped, "Count steps: ", stepCount);
  const handleCaptchaResponse = (v) => {
    console.log(v);
    setFormData({ ...formData, "g-recaptcha-response": v });
  };
  return (
    <div className="d-flex flex-column">
      {reachedFinish || reachedFinishSooner || !stepped ? (
        <div className="mb-3 mb-3 d-flex align-content-end justify-content-between flex-column flex-md-row gap-3">
          <div className="checkbox">
            <input
              id="agreement"
              type="checkbox"
              className="form-check-input"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  agreement: e.target.checked ? "on" : "off",
                });
              }}
            />
            <label for="agreement" className="form-label text-muted">
              {trans("website.form-agree-to")}{" "}
              <Link target="_blank" to={process.env.PUBLIC_URL + "/terms-conditions"}>
                {trans("common.terms-link")}
              </Link>{" "}
              {trans("common.and")}{" "}
              <Link target="_blank" to={process.env.PUBLIC_URL + "/privacy-policy"}>
                {trans("common.privacy-policy-link")}
              </Link>
            </label>
          </div>
          <div>
            {appSettings?.crm_captcha && appSettings?.crm_captcha_key ? (
              <ReCAPTCHA
                stoken
                sitekey={appSettings?.crm_captcha_key}
                onChange={handleCaptchaResponse}
                onExpired={() => {
                  toast("website.expired_captcha", { type: "error" });
                }}
                onErrored={() => {
                  toast("website.retry_captcha", { type: "error" });
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={`d-flex flex-row gap-3 ${step === 0 ? "justify-content-end" : "justify-content-between"}`}>
        {step > 0 ? (
          <Btn
            attrBtn={{
              type: "button",
              color: "warning",
              onClick: (e) => {
                e.preventDefault();
                handleBacking();
                //setStep((s) => (s === 1 ? 1 : s - 1));
              },
            }}
          >
            {trans("website.form-previous-step")}
          </Btn>
        ) : (
          <></>
        )}
        {!reachedFinishSooner || !reachedFinish ? (
          <EBSSSButton
            loadingKey="form"
            type={"submit"}
            children={
              <div className="d-flex flex-row gap-2 align-items-center">
                {!stepped ? (
                  <i className="la la-send fs-6"></i>
                ) : reachedFinish || reachedFinishSooner ? (
                  <i className="la la-send fs-6"></i>
                ) : (
                  <i className="la la-chevron-circle-right fs-6"></i>
                )}
                <span className="d-none d-lg-block mx-50">
                  {trans(!stepped ? "website.submit" : reachedFinish || reachedFinishSooner ? "website.submit" : "website.form-next-step")}
                </span>
              </div>
            }
          />
        ) : (
          <EBSSSButton
            loadingKey="form"
            type={"button"}
            onClick={async (e) => {
              e.preventDefault();
              if (reachedFinishSooner) {
                await makeFormSubmit();
              }
            }}
            children={
              <div className="d-flex flex-row gap-2 align-items-center">
                <i className="la la-paper-plane fs-6"></i>
                <span className="d-none d-lg-block mx-50">{trans("website.submit")}</span>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export const FormDescription = ({ description }) => {
  return <div className="text-muted col-8">{parseHTML(description)}</div>;
};

export const FormLangs = ({ availableLanguages, trans, fetcher }) => {
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { changeLanguage } = useEBSSSLayout();
  const dispatcher = useDispatch();

  return (
    <div className="text-end text-muted">
      <div className="dropdown">
        <button className="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          {trans("common.languages")}
        </button>
        <ul className="dropdown-menu">
          {ebsssLayout?.languages?.map((option) => (
            <li>
              <a
                style={CSS({ cursor: "pointer" })}
                className="dropdown-item"
                onClick={async (e) => {
                  e.preventDefault();
                  await fetcher(option?.code);
                  changeLanguage(option?.code?.toLowerCase(), true);
                  if (sessionNow?.user?.id) {
                    dispatcher(
                      editProfile({
                        id: sessionNow?.user.id,
                        form: {
                          //Address
                          language_id: option?.id,
                          address_line_1: sessionNow?.user?.profile?.address_line_1 ?? "",
                          address_line_2: sessionNow?.user?.profile?.address_line_2 ?? "",
                          locality: sessionNow?.user?.profile?.locality ?? "",
                          postal_code: sessionNow?.user?.profile?.postal_code ?? "",
                          country_code: sessionNow?.user?.profile?.country_code ?? "pt",
                          administrative_area: sessionNow?.user?.profile?.administrative_area ?? "",
                          //Profile
                          first_name: sessionNow?.user?.profile?.first_name ?? "",
                          last_name: sessionNow?.user?.profile?.last_name ?? "",
                          mobile_number: sessionNow?.user?.mobile_number ?? "",
                          gender: sessionNow?.user?.profile?.gender ?? "male",
                          vat_number: sessionNow?.user?.profile?.vat_number ?? "",
                          date_of_birth: sessionNow?.user?.profile?.date_of_birth?.substring(0, 10) ?? "",
                        },
                      })
                    );
                  }
                }}
              >
                <div className="d-flex flex-row align-items-center gap-2">
                  <i className={countryCodeToFlag((option.code === "EN" ? "US" : option.code).toUpperCase())}></i>
                  <span>{option.name}</span>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
