import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HelperHTTP from "src/_utils/http_module_handler";
import { remotePath } from "../settings/Paths";

const HOUR_IN_MS = 1000 * 60 * 60;
export type User = {
  notifications: Array<Record<string, any>>;
  notifications_count: number;
  id: string;
  name: string;
  email: string;
  avatar?: string;
  roles: string[];
  mobile_number: string;
  profile: Record<string, any>;
  user_service_term_conditions: Array<Record<string, any>> | string[];
  newly_accepted_terms: string;
  renew_accepted_terms: string;
  status: string;
};

export type Session = {
  user: User;
  token: string;
  exp: number;
  tempTokenActivated: string;
  registeredFromCheckout: boolean;
  session: Record<any, any>;
  redirect: {
    signup: boolean;
    signupToken: boolean;
    signupPassword: boolean;
  };
  loading: {
    signin: boolean;
    signup: boolean;
    signupToken: boolean;
    signupPassword: boolean;
    forgotPassword: boolean;
    profile: boolean;
    cart: boolean;
    notifications: boolean;
    ticket: boolean;
    form: boolean;
  };
  message: {
    signin: string;
    signup: string;
    signupToken: string;
    signupPassword: string;
    forgotPassword: string;
    profile: string;
    cart: string;
    notifications: string;
    ticket: string;
    form: string;
  };
  errors: {
    signin: Record<string, string>;
    signup: Record<string, string>;
    signupToken: Record<string, string>;
    signupPassword: Record<string, string>;
    forgotPassword: Record<string, string>;
    profile: Record<string, string>;
    cart: Record<string, string>;
    notifications: Record<string, string>;
    ticket: Record<string, string>;
    form: Record<string, string>;
  };
};

const initialState: Session = {
  token: "",
  exp: 0,
  tempTokenActivated: "",
  registeredFromCheckout: false,
  session: {},
  user: {
    notifications: [],
    notifications_count: 0,
    id: "",
    email: "",
    name: "",
    avatar: "",
    mobile_number: "",
    roles: [],
    profile: {},
    user_service_term_conditions: [],
    newly_accepted_terms: "",
    renew_accepted_terms: "",
    status: "",
  },
  redirect: {
    signup: false,
    signupToken: false,
    signupPassword: false,
  },
  loading: {
    signin: false,
    signup: false,
    signupToken: false,
    signupPassword: false,
    forgotPassword: false,
    profile: false,
    cart: false,
    notifications: false,
    ticket: false,
    form: false,
  },
  message: {
    signin: "",
    signup: "",
    signupToken: "",
    signupPassword: "",
    forgotPassword: "",
    profile: "",
    cart: "",
    notifications: "",
    ticket: "",
    form: "",
  },
  errors: {
    signin: {},
    signup: {},
    signupToken: {},
    signupPassword: {},
    forgotPassword: {},
    profile: {},
    cart: {},
    notifications: {},
    ticket: {},
    form: {},
  },
};

export const signOut = () => {
  return async (dispatch: (arg0: { payload: any; type: "auth/logout" }) => void) => {
    dispatch(logout());
  };
};

export const fetchSignin = ({ email, password, actualSession, activeLanguage, token }: any) => {
  return async (
    dispatch: (arg0: { payload: any; type: "auth/signInRequest" | "auth/signInSuccess" | "auth/signInFailure" | "auth/endLoadingSignIn" }) => void
  ) => {
    dispatch(signInRequest());
    try {
      const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.LOGIN)
        .api()
        .post({
          data: token ? { email, password, token, actualSession } : { email, password, actualSession },
        });

      console.log("Auth slice: ", fetch);
      if (!fetch.data) {
        dispatch(signInFailure(fetch));
        return;
      }
      dispatch(signInSuccess(fetch.data));
    } catch (err: any) {
      dispatch(signInFailure(err));
    } finally {
      dispatch(endLoadingSignIn());
    }
  };
};

export const fetchSignup = ({ email, first_name, last_name, mobile_number, activeLanguage }: any) => {
  return async (
    dispatch: (arg0: {
      payload: any;
      type: "auth/signupRequest" | "auth/signupSuccess" | "auth/signupFailure" | "auth/endLoadingSignUp" | "auth/resetSignUpStateRedirects";
    }) => void
  ) => {
    dispatch(resetSignUpStateRedirects());
    dispatch(signupRequest());
    try {
      const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.REGISTER).api().post({
        data: { email, first_name, last_name, mobile_number },
      });
      console.log(fetch);
      if (!fetch.data) {
        dispatch(signupFailure(fetch));
        return;
      }
      dispatch(signupSuccess(fetch));
    } catch (err: any) {
      dispatch(signupFailure(err));
    } finally {
      dispatch(endLoadingSignUp());
    }
  };
};

export const fetchToken = ({ token, activeLanguage }: any) => {
  return async (
    dispatch: (arg0: { payload: any; type: "auth/tokenRequest" | "auth/tokenSuccess" | "auth/tokenFailure" | "auth/endLoadingSignUp" }) => void
  ) => {
    dispatch(tokenRequest());
    try {
      console.log(token);
      const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.REGISTER_TOKEN).api().post({
        data: { token },
      });

      console.log(fetch);
      if (!fetch.data) {
        dispatch(tokenFailure(fetch));
        return;
      }
      dispatch(tokenSuccess({ ...fetch, tempTokenActivated: token }));
    } catch (err: any) {
      dispatch(tokenFailure(err));
    } finally {
      dispatch(endLoadingSignUp());
    }
  };
};

export const fetchPassword = ({ password, password_confirmation, token, activeLanguage, registeredFromCheckout }: any) => {
  return async (
    dispatch: (arg0: { payload: any; type: "auth/passwordRequest" | "auth/passwordSuccess" | "auth/passwordFailure" | "auth/endLoadingSignUp" }) => void
  ) => {
    dispatch(passwordRequest());
    try {
      const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.REGISTER_PASSWORD).api().post({
        data: { password, password_confirmation, token },
      });

      if (!fetch.data) {
        dispatch(passwordFailure(fetch));
        return;
      }
      dispatch(passwordSuccess({ ...fetch, registeredFromCheckout }));
    } catch (err: any) {
      dispatch(passwordFailure(err));
    } finally {
      dispatch(endLoadingSignUp());
    }
  };
};

export const fetchForgotEmail = ({ email, activeLanguage }: any) => {
  return async (
    dispatch: (arg0: {
      payload: any;
      type: "auth/forgotPasswordRequest" | "auth/forgotPasswordSuccess" | "auth/forgotPasswordFailure" | "auth/endLoadingForgotPassword";
    }) => void
  ) => {
    dispatch(forgotPasswordRequest());
    try {
      const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.FORGOT_PASSWORD_VERIFY).api().post({
        data: { email },
      });

      console.log(fetch);
      if (!fetch.data) {
        dispatch(forgotPasswordFailure(fetch));
        return;
      }
      dispatch(forgotPasswordSuccess(fetch));
    } catch (err: any) {
      dispatch(forgotPasswordFailure(err));
    } finally {
      dispatch(endLoadingForgotPassword());
    }
  };
};

export const fetchForgotReset = ({ token, password, password_confirmation, activeLanguage }: any) => {
  return async (
    dispatch: (arg0: {
      payload: any;
      type: "auth/forgotPasswordRequest" | "auth/forgotPasswordSuccess" | "auth/forgotPasswordFailure" | "auth/endLoadingForgotPassword";
    }) => void
  ) => {
    dispatch(forgotPasswordRequest());
    try {
      const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.FORGOT_PASSWORD_MODIFY).api().post({
        data: { token, password, password_confirmation },
      });
      console.log(fetch);
      if (!fetch.data) {
        dispatch(forgotPasswordFailure(fetch));
        return;
      }
      dispatch(forgotPasswordSuccess(fetch));
    } catch (err: any) {
      dispatch(forgotPasswordFailure(err));
    } finally {
      dispatch(endLoadingForgotPassword());
    }
  };
};

export const editProfile = ({ id, form }: any) => {
  return async (
    dispatch: (arg0: { payload: any; type: "auth/profileRequest" | "auth/profileSuccess" | "auth/endLoadingProfile" | "auth/profileFailure" }) => void
  ) => {
    dispatch(profileRequest());
    try {
      let authedSession = (await dispatch(sessionGetter() as any)) as unknown as {
        payload: { signin: Session };
      };

      console.log("Form profile edit: ", form);
      const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.UPDATE_PROFILE)
        .authed({
          tk: "Bearer " + authedSession.payload["auth"]["token"],
        })
        .api()
        .put({
          data: form,
        });
      console.log("Profile edit result: ", fetch);
      if (!fetch.data) {
        dispatch(profileFailure(fetch));
        return;
      }
      dispatch(profileSuccess(fetch));
    } catch (err: any) {
      console.log(err);
    } finally {
      dispatch(endLoadingProfile());
    }
  };
};

export const editPassword = ({ id, form }: any) => {
  return async (
    dispatch: (arg0: { payload: any; type: "auth/profileRequest" | "auth/profileSuccess" | "auth/endLoadingProfile" | "auth/profileFailure" }) => void
  ) => {
    dispatch(profileRequest());
    try {
      let authedSession = (await dispatch(sessionGetter() as any)) as unknown as {
        payload: { signin: Session };
      };

      const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.UPDATE_PASS)
        .authed({
          tk: "Bearer " + authedSession.payload["auth"]["token"],
        })
        .api()
        .put({
          data: form,
        });
      if (!fetch.data) {
        dispatch(profileFailure(fetch));
        return;
      }
      dispatch(profileSuccess(fetch));
    } catch (err: any) {
      console.log(err);
    } finally {
      dispatch(endLoadingProfile());
    }
  };
};

export const newToken = ({ token }: any) => {
  return async (dispatch: (arg0: { payload: any; type: "auth/replaceToken" }) => void) => {
    dispatch(replaceToken({ token } as any));
  };
};

export const newOrderSessionShipping = ({ data }: any) => {
  return async (dispatch: (arg0: { payload: any; type: "auth/orderSessionSuccess" }) => void) => {
    dispatch(orderSessionSuccess(data as any));
  };
};

export const clearCheckout = ({ data }: any) => {
  return async (dispatch: (arg0: { payload: any; type: "auth/clearCartAndOrderSession" }) => void) => {
    dispatch(clearCartAndOrderSession(data as any));
  };
};

export const editAvatar = ({ id, formData }: any) => {
  return async (
    dispatch: (arg0: { payload: any; type: "auth/profileRequest" | "auth/profileSuccess" | "auth/endLoadingProfile" | "auth/profileFailure" }) => void
  ) => {
    dispatch(profileRequest());
    try {
      let authedSession = (await dispatch(sessionGetter() as any)) as unknown as {
        payload: { signin: Session };
      };

      const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.UPDATE_AVATAR)
        .hasFile()
        .authed({
          tk: "Bearer " + authedSession.payload["auth"]["token"],
        })
        .api()
        .post({
          data: formData,
        });

      if (!fetch.data) {
        dispatch(profileFailure(fetch));
        return;
      }
      dispatch(profileSuccess(fetch));
    } catch (err) {
      console.error(err.response);
    } finally {
      dispatch(endLoadingProfile());
    }
  };
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    //Handlers
    allowMarketing(state: any, action: any) {
      console.log(action);
      state.message.profile = action.payload.data.marketing;
      state.user.profile.allow_marketing_emails = action.payload.allowMarketingEmails;
    },
    erasureRequesting(state: any, action: any) {
      state.message.profile = action.payload.data.erasure;
      state.user.profile.requested_erasure = action.payload.confirm;
      state.user.profile.requested_erasure_at = new Date().getTime();
    },
    proceedAfterAcceptTerms(state: any) {
      state.user.newly_accepted_terms = "true";
      state.user.user_service_term_conditions = ["signed", "signed"];
    },
    proceedAfterRenewTerms(state: any) {
      state.user.renew_accepted_terms = "true";
    },
    refreshNotifications(state: any, action: any) {
      console.log("Refreshing notifications: ", action.payload.newers);
      state.user.notifications = action.payload.newers;
    },
    countNotifications(state: any, action: any) {
      console.log("Count notifications: ", action.payload.new_count);
      state.user.notifications_count = action.payload.new_count;
    },
    replaceToken(state: any, action: any) {
      if (action.payload.token) {
        state.token = action.payload.token;
      }
      if (action.payload.user_session) {
        state.session = action.payload.user_session;
      }
    },
    cartSuccess(state: any, action: any) {
      if (action.payload.user_session) {
        state.session = action.payload.user_session;
      }
    },
    cartFailure(state: any, action: any) {
      state.message.cart = action.payload.message;
    },
    orderSessionSuccess(state: any, action: any) {
      if (action.payload.user_session) {
        state.session = action.payload.user_session;
      }
    },
    cartItemRemoval(state: any, action: any) {
      state.session.cart = state.session.cart.filter((i) => i.hash !== action.payload.hash);
    },
    clearSelectedSeller(state: any) {
      state.session._selected_seller = "";
    },
    clearCartAndOrderSessionSimple(state: any) {
      if (state.session.shipping) {
        state.session.shipping = {};
      }
      state.session.cart = [];
      state.session._cart = [];
      state.session.sellers = [];
      state.session._sellers = [];
      state.session._selected_seller = "";
      state.message.cart = "";
      state.loading.cart = false;
    },
    clearCartAndOrderSession(state: any, action: any) {
      state.session.cart = [];
      state.session._cart = [];
      state.session.sellers = [];
      state.session._sellers = [];
      state.session._selected_seller = "";
      state.message.cart = "";
      state.loading.cart = false;
      if (action.payload.user_session) {
        state.session = action.payload.user_session;
      }
    },
    clearShippingSession(state: any, action: any) {
      if (state.session.shipping) {
        state.session.shipping = {};
      }
      state.message.cart = "";
      state.loading.cart = false;
    },
    // -------- > View Starters
    cleanErrorsAndMessages(state: any) {
      state.loading = initialState.loading;
      state.errors = initialState.errors;
      state.message = initialState.message;
    },
    exitRecovery(state: any) {
      state.forgotPassword.message = "";
      state.errors.forgotPassword = {};
    },
    // -------- > Request Starters
    resetSignUpStateRedirects(state: any) {
      state.redirect.signup = false;
      state.redirect.signupToken = false;
      state.redirect.signupPassword = false;
    },
    signInRequest(state: any) {
      state.loading.signin = true;
      state.message.signin = "";
      state.errors.signin = {};
    },
    profileRequest(state: any) {
      state.loading.profile = true;
      state.message.profile = "";
      state.errors.profile = {};
    },
    signupRequest(state: any) {
      state.loading.signup = true;
      state.message.signup = "";
      state.errors.signup = {};
    },
    tokenRequest(state: any) {
      state.loading.signupToken = true;
      state.message.signupToken = "";
      state.errors.signupToken = {};
    },
    passwordRequest(state: any) {
      state.loading.signupPassword = true;
      state.message.signupPassword = "";
      state.errors.signupPassword = {};
    },
    forgotPasswordRequest(state: any) {
      state.loading.forgotPassword = true;
      state.message.forgotPassword = "";
      state.errors.forgotPassword = {};
    },
    addItemToCartRequest(state: any) {
      state.loading.cart = true;
      state.message.cart = "";
      state.errors.cart = {};
    },
    sendTicketRequest(state: any) {
      state.loading.ticket = true;
      state.message.ticket = "";
      state.errors.ticket = {};
    },
    sendFormRequest(state: any) {
      state.loading.form = true;
      state.message.form = "";
      state.errors.form = {};
    },
    sendPaymentProveUpload(state: any) {
      state.loading.payment_prove_upload = true;
    },
    // -------- > Request Success
    signInSuccess(state: any, action: any) {
      console.log(action);
      state.message.signin = "";
      state.errors.signin = {};
      state.exp = new Date().getTime() + HOUR_IN_MS * 24;
      state.user = action.payload.user;
      if (action.payload.token) {
        state.token = action.payload.token;
      }
    },
    signupSuccess(state: any) {
      state.message.signup = "";
      state.errors.signup = {};
      state.redirect.signupToken = true;
    },
    tokenSuccess(state: any, action: any) {
      state.redirect.signupPassword = true;
      state.message.signupToken = "";
      state.errors.signupToken = {};
      state.tempTokenActivated = action.payload.tempTokenActivated;
      state.token = action.data;
    },
    passwordSuccess(state: any, action: any) {
      state.redirect.signupToken = false;
      state.redirect.signupPassword = false;
      state.message.signupPassword = "";
      state.errors.signupPassword = {};
      state.tempTokenActivated = "";
      state.password = action.data;
      if (action.payload.registeredFromCheckout) {
        state.registeredFromCheckout = true;
      }
    },
    forgotPasswordSuccess(state: any, action: any) {
      state.message.forgotPassword = action.payload.message;
      state.errors.forgotPassword = {};
    },
    profileSuccess(state: any, action: any) {
      if (action.payload?.data?.new_avatar) {
        state.user.profile.avatar = action.payload.data.new_avatar;
      }
      if (action.payload?.data?.new_profile) {
        state.user.profile = {
          ...state.user.profile,
          ...action.payload.data.new_profile,
        };
      }
      state.message.profile = action.payload.message;
      state.errors.profile = {};
    },
    // -------- > Request Failed
    signInFailure(state: any, action: any) {
      state.errors.signin = action.payload.errors ?? {};
      state.message.signin = action.payload.message ?? "";
    },
    signupFailure(state: any, action: any) {
      state.errors.signup = action.payload.errors;
      state.message.signup = action.payload.message;
    },
    tokenFailure(state: any, action: any) {
      state.errors.signupToken = action.payload.errors;
      state.message.signupToken = action.payload.message;
    },
    passwordFailure(state: any, action: any) {
      state.errors.signupPassword = action.payload.errors;
      state.message.signupPassword = action.payload.message;
    },
    profileFailure(state: any, action: any) {
      state.errors.profile = action.payload.errors;
      state.message.profile = action.payload.message;
    },
    forgotPasswordFailure(state: any, action: any) {
      state.errors.forgotPassword = action.payload.errors;
      state.message.forgotPassword = action.payload.message;
    },
    // -------- > Request Loaders End
    endLoadingSignIn(state: any) {
      state.loading.signin = false;
    },
    endLoadingSignUp(state: any) {
      state.loading.signup = false;
      state.loading.signupToken = false;
      state.loading.signupPassword = false;
    },
    endLoadingForgotPassword(state: any) {
      state.loading.forgotPassword = false;
    },
    endLoadingProfile(state: any) {
      state.loading.profile = false;
    },
    endAddItemToCart(state: any) {
      state.loading.cart = false;
    },
    endSendTicket(state: any) {
      state.loading.ticket = false;
    },
    endSendForm(state: any) {
      state.loading.form = false;
    },
    endPaymentProveUpload(state: any) {
      state.loading.payment_prove_upload = false;
    },
    // -------- > Logout
    logout(state: any) {
      let keysInitial = Object.keys(initialState);
      keysInitial.forEach((k) => {
        state[k] = initialState[k];
      });
      localStorage.removeItem("TEMP_TERM_SIGNED");
    },
  },
});

export const {
  sendPaymentProveUpload,
  endPaymentProveUpload,
  sendTicketRequest,
  endSendTicket,
  sendFormRequest,
  endSendForm,
  allowMarketing,
  erasureRequesting,
  proceedAfterAcceptTerms,
  proceedAfterRenewTerms,
  refreshNotifications,
  countNotifications,
  replaceToken,
  cleanErrorsAndMessages,
  endLoadingForgotPassword,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  endLoadingProfile,
  exitRecovery,
  //Sign In
  addItemToCartRequest,
  endAddItemToCart,
  endLoadingSignIn,
  cartItemRemoval,
  clearShippingSession,
  clearCartAndOrderSession,
  clearCartAndOrderSessionSimple,
  clearSelectedSeller,
  cartSuccess,
  cartFailure,
  orderSessionSuccess,
  signInRequest,
  signInSuccess,
  signInFailure,
  profileSuccess,
  profileRequest,
  profileFailure,
  logout,
  // Sign Out
  endLoadingSignUp,
  resetSignUpStateRedirects,
  signupRequest,
  signupSuccess,
  signupFailure,
  tokenRequest,
  tokenSuccess,
  tokenFailure,
  passwordRequest,
  passwordSuccess,
  passwordFailure,
} = authSlice.actions;

export const sessionGetter = createAsyncThunk("auth/sessionGetter", async (_, { getState }) => {
  const state = getState();
  return state;
});

export default authSlice.reducer;
