import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { ReactElement, JSXElementConstructor, ReactNode, SetStateAction } from "react";
import { Params, NavigateFunction } from "react-router";
import { toast, ToastContentProps } from "react-toastify";
import { buildQueryParamUrl, getQueryParams } from "src/_utils/urls";
import { editProfile } from "src/base/store/authSlice";
import { renderSpinner } from "../Renders/renderSpinner";
import { remotePath } from "src/base/settings/Paths";

export const handleLinkClick = async (
  event: {
    preventDefault: () => void;
    target: {
      [x: string]: any;
      closest: (arg0: string) => any;
    };
  },
  ebsssLayout: {
    exp?: number;
    loading?: boolean;
    message?: string;
    menu?: Record<string, any>[];
    countries?: Record<string, any>[];
    languages: any;
    activeLanguage?: string;
    logo?: string;
    title?: string;
    favicon?: string;
    balance_wallet?: number;
    commonAppSettings?: Record<string, any>;
    styles?: { primary_color: string; secondary_color: string };
    megaMenu?: { linkList: Array<Record<string, any>>; customPages: Array<Record<string, any>>; fixedPages: Array<Record<string, any>> };
    template: any;
  },
  sessionNow: {
    user: any;
    cart?: any[];
    session_shipping?: Record<any, any>;
    token?: string;
    exp?: number;
    tempTokenActivated?: string;
    registeredFromCheckout?: boolean;
    redirect?: { signup: boolean; signupToken: boolean; signupPassword: boolean };
    loading?: {
      signin: boolean;
      signup: boolean;
      signupToken: boolean;
      signupPassword: boolean;
      forgotPassword: boolean;
      profile: boolean;
      cart: boolean;
      notifications: boolean;
      ticket: boolean;
      form: boolean;
    };
    message?: {
      signin: string;
      signup: string;
      signupToken: string;
      signupPassword: string;
      forgotPassword: string;
      profile: string;
      cart: string;
      notifications: string;
      ticket: string;
      form: string;
    };
    errors?: {
      signin: Record<string, string>;
      signup: Record<string, string>;
      signupToken: Record<string, string>;
      signupPassword: Record<string, string>;
      forgotPassword: Record<string, string>;
      profile: Record<string, string>;
      cart: Record<string, string>;
      notifications: Record<string, string>;
      ticket: Record<string, string>;
      form: Record<string, string>;
    };
  },
  product: Record<string, any>,
  paymentTypeSelected: string,
  selectedExtras: number[],
  optionProductSelected: Record<any, any>,
  paramsPage: Readonly<Params<string>>,
  appSettings: Record<string, any>,
  tempProductToAddModal: Record<any, any>,
  tempAppSettingsToAddModal: Record<any, any>,
  htmlPageSettings: Record<any, any>,
  handleFormSubmit: {
    (event: any, target: any, el: any, ebsssLayout: any, navigate: any): Promise<void>;
    (arg0: null, arg1: any, arg2: any, arg3: any, arg4: any): void;
  },
  navigate: NavigateFunction,
  trans: {
    (k: string): string;
    (arg0: string):
      | string
      | number
      | boolean
      | ReactElement<any, string | JSXElementConstructor<any>>
      | Iterable<ReactNode>
      | ((props: ToastContentProps) => React.ReactNode);
  },
  addItemToCart: {
    (productToken: string, paymentToken: string, qt: number, additionalPayload: Record<any, any>): Promise<void>;
    (arg0: any, arg1: any, arg2: any, arg3: {}): Promise<any>;
  },
  changeLanguage: { (k: string): void; (arg0: any): void },
  dispatcher: Dispatch<UnknownAction>,
  addBookmarks: { (tokenProduct: string): Promise<any>; (arg0: any): Promise<any> },
  setModalAddProduct: { (value: SetStateAction<boolean>): void; (arg0: boolean): void },
  fetchProduct: { (productToken: string, silent?: boolean): Promise<any>; (arg0: any): Promise<any> },
  setTempProductToAddModal: any,
  setTempAppSettingsToAddModal: any
) => {
  event.preventDefault();

  let target = event.target;
  let isButton = target.closest("button");
  let formable = target.closest("form");
  console.log(target);

  if (formable && isButton && isButton.type === "submit") {
    console.log("Its a form");
    handleFormSubmit(null, target, formable, ebsssLayout, navigate);
    return;
  }

  if (target.tagName !== "A") {
    target = target.closest("a");
  }

  if (!target) {
    return;
  }

  let path = target.getAttribute("href");

  if (!path) {
    return;
  }

  if (path === "/" || path === process.env.PUBLIC_URL) {
    console.log(ebsssLayout.template.pages);
    navigate(process.env.PUBLIC_URL + "/" + ebsssLayout.template.pages.find((lg: { type: string }) => lg.type === "ini_page").slug);
    return;
  }

  if (path.includes("/set-locale/")) {
    console.log("Its a locale setter");
    let langFromSplit = path.split("/").pop();
    let getIdFromCode = ebsssLayout.languages.find((lg: { code: string }) => lg.code.toLowerCase() === langFromSplit.toLowerCase());

    console.log(langFromSplit, getIdFromCode);

    changeLanguage(langFromSplit.toLowerCase());
    if (sessionNow?.user?.id) {
      dispatcher(
        editProfile({
          id: sessionNow.user.id,
          form: {
            //Address
            language_id: getIdFromCode.id,
            address_line_1: sessionNow?.user?.profile?.address_line_1 ?? "",
            address_line_2: sessionNow?.user?.profile?.address_line_2 ?? "",
            locality: sessionNow?.user?.profile?.locality ?? "",
            postal_code: sessionNow?.user?.profile?.postal_code ?? "",
            country_code: sessionNow?.user?.profile?.country_code ?? "pt",
            administrative_area: sessionNow?.user?.profile?.administrative_area ?? "",
            //Profile
            first_name: sessionNow?.user?.profile?.first_name ?? "",
            last_name: sessionNow?.user?.profile?.last_name ?? "",
            mobile_number: sessionNow?.user?.mobile_number ?? "",
            gender: sessionNow?.user?.profile?.gender ?? "male",
            vat_number: sessionNow?.user?.profile?.vat_number ?? "",
            date_of_birth: sessionNow?.user?.profile?.date_of_birth?.substring(0, 10) ?? "",
          },
        }) as any
      );
    }
    return;
  }

  if (path.includes("cms/favorite/product")) {
    let { spin, removeSpinner } = renderSpinner();

    let copyInnerTargetHTML = target.innerHTML;
    target.innerHTML = "";
    target.classList.add("d-flex");
    target.classList.add("align-items-center");
    spin.classList.add("m-auto");
    target.prepend(spin);

    let getTokenFromUrl = path.split("/").pop();
    await addBookmarks(product ? product?.token : getTokenFromUrl).then((r) => {
      if (product) {
        let isntInFavs = !product?.settings?.favorited;
        toast(
          isntInFavs
            ? product?.name + " " + trans("website.added-successfully-to-favorites")
            : product?.name + " " + trans("website.removed-successfully-from-favorites"),
          { type: isntInFavs ? "success" : "error" }
        );
        return;
      }
      toast(
        r.favorited
          ? r?.product + " " + trans("website.added-successfully-to-favorites")
          : r?.product + " " + trans("website.removed-successfully-from-favorites"),
        { type: r?.favorited ? "success" : "error" }
      );
      removeSpinner();
      target.classList.remove("d-flex");
      target.classList.remove("align-items-center");
      target.innerHTML = copyInnerTargetHTML;
    });
    return;
  }

  if (path.includes("cms/cart/add-product") || target.classList.contains("iclient-buy-now")) {
    let { spin, removeSpinner } = renderSpinner();
    let getSlugFromUrl = path.split("/").pop();
    let fromProductPage = htmlPageSettings?.type === "crm_product";

    if (!fromProductPage) {
      let copyInnerTargetHTML = target.innerHTML;
      target.innerHTML = "";
      target.classList.add("d-flex");
      target.classList.add("align-items-center");
      spin.classList.add("m-auto");
      target.prepend(spin);

      await fetchProduct(getSlugFromUrl, true).then(async (r) => {
        console.log("add item out page: ", r);
        let prodAdd = r.product.product;

        if (
          prodAdd?.settings?.paymentType?.length > 1 ||
          prodAdd?.settings?.productAgeRanges?.length > 0 ||
          prodAdd?.settings?.slots?.length > 0 ||
          prodAdd?.settings?.shiftTimes?.length > 0 ||
          prodAdd?.product_extras?.length > 0 ||
          prodAdd?.settings?.customFields?.length > 0 ||
          prodAdd?.entities?.length > 0 ||
          (prodAdd?.options && Array.isArray(prodAdd?.options) && prodAdd?.options?.length > 0) ||
          (prodAdd?.settings?.productsChild && Array.isArray(prodAdd?.settings?.productsChild) && prodAdd?.settings?.productsChild?.length > 0)
        ) {
          console.log(">> Opening modal out of product page");
          await fetchProduct(getSlugFromUrl);
          setModalAddProduct(true);
          removeSpinner();
          target.classList.remove("d-flex");
          target.classList.remove("align-items-center");
          target.innerHTML = copyInnerTargetHTML;
          return;
        }

        console.log(">> Adding without modal out of product page");

        await addItemToCart(prodAdd?.token, prodAdd?.product_payment_types[0]?.payment_type?.token, 1, {}).then((res: { [x: string]: any }) => {
          console.log("res add: ", res);
          removeSpinner();
          target.classList.remove("d-flex");
          target.classList.remove("align-items-center");
          target.innerHTML = copyInnerTargetHTML;

          if (res["success"]) {
            toast(prodAdd?.name + " " + trans("website.added-successfully-to-cart"), { type: "success" });
            if (target.classList.contains("iclient-buy-now")) {
              navigate(process.env.PUBLIC_URL + "/checkout");
            }
          } else {
            toast(trans("website.try-again"), { type: "error" });
          }
        });
      });
      return;
    }

    console.log("Adding from product page: ", fromProductPage);

    console.log("Product being analyzed for add: ", product);

    if (
      product?.settings?.productAgeRanges?.length > 0 ||
      product?.settings?.slots?.length > 0 ||
      product?.settings?.shiftTimes?.length > 0 ||
      product?.settings?.customFields?.length > 0 ||
      product?.entities?.length > 0 ||
      (!fromProductPage &&
        ((product?.options && Array.isArray(product?.options) && product?.options?.length > 0) ||
          (product?.settings?.productsChild && Array.isArray(product?.settings?.productsChild) && product?.settings?.productsChild?.length > 0) ||
          product?.settings?.paymentType?.length > 1 ||
          product?.product_extras?.length > 0))
    ) {
      console.log(">> Opening modal");
      setModalAddProduct(true);
      return;
    }

    console.log(">> Adding without modal");

    let qtt = document.querySelector(".iclient-cart-quantity");

    if (target) {
      target.classList.add("d-flex");
      target.classList.add("gap-3");
      target.classList.add("align-items-center");
      target.prepend(spin);
    }

    let pload = {};

    if (optionProductSelected) {
      pload["option"] = optionProductSelected?.value;
    }

    if (selectedExtras?.length > 0) {
      pload["extra"] = selectedExtras;
    }

    console.log("pload: ", pload);

    await addItemToCart(
      product?.token,
      !paymentTypeSelected || product?.product_payment_types?.length === 1 ? product?.product_payment_types[0]?.payment_type?.token : paymentTypeSelected,
      qtt ? qtt["value"] : 1,
      pload
    ).then((res: { [x: string]: any }) => {
      console.log("res add: ", res);
      if (target) {
        removeSpinner();
        target.classList.remove("d-flex");
        target.classList.remove("gap-3");
        target.classList.remove("align-items-center");
      }

      if (res["success"]) {
        toast(product?.name + " " + trans("website.added-successfully-to-cart"), { type: "success" });
        if (target.classList.contains("iclient-buy-now")) {
          navigate(process.env.PUBLIC_URL + "/checkout");
        }
      } else {
        toast(trans("website.try-again"), { type: "error" });
      }
    });
    return;
  }

  if (path.includes("http") || path.includes("www")) {
    console.log("Its a full link, going to it");
    let getQparams = getQueryParams(path);
    if (Object.keys(getQparams).length > 0) {
      if (getQparams["slug"] && Object.keys(paramsPage).length > 0) {
        delete getQparams["slug"];
      }
      console.log({ ...getQueryParams(window.location.href), ...getQparams });
      navigate(
        process.env.PUBLIC_URL + window.location.pathname.replace("v2/", "") + buildQueryParamUrl({ ...getQueryParams(window.location.href), ...getQparams })
      );
      return;
    }
    if (Object.keys(getQparams).length === 0) {
      console.log("No params, pure link");
      let newUrl = new URL(path);
      navigate(process.env.PUBLIC_URL + newUrl.pathname.replace("v2/", ""));
    }
    return;
  }

  if (!path.startsWith("#") && !path.includes(":void")) {
    console.log("Its a partial link, going to it");
    navigate(process.env.PUBLIC_URL + path);
  }
};
