import { mainValidEmailDomains } from "src/base/constants/layout";
import { object, string } from "yup";

export const loginSchema = async (cb: Function) =>
  await object({
    email: string().email(),
    password: string().min(6).max(20),
  }).validate(await cb());

export const getCountryCode = () => {
  const language = navigator.language || navigator.language;
  const countryCode = language.split("-")[1] || language.split("_")[1];
  return countryCode.toLowerCase() ? countryCode.toLowerCase() : "pt";
};

export const parseSettingsToBooleanValues = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === "1") {
      obj[key] = 1;
    }
    if (obj.hasOwnProperty(key) && (obj[key] === "0" || obj[key] === "" || obj[key] === "NULL")) {
      obj[key] = 0;
    }
  }
  return obj;
};

// Levenshtein distance function
function levenshteinDistance(a, b) {
  const matrix = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          matrix[i][j - 1] + 1, // insertion
          matrix[i - 1][j] + 1 // deletion
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

export function suggestEmailCorrection(email, tolerance = 2) {
  if (!email.includes("@") && !email.includes(".")) {
    return "";
  }
  const emailParts = email.split("@");

  if (emailParts.length !== 2) {
    return "";
  }

  const [localPart, domainPart] = emailParts;
  const domain = domainPart.split(".")[0];

  if (mainValidEmailDomains.includes(domain)) {
    return "";
  }

  let closestDomain = null;
  let minDistance = Infinity;

  mainValidEmailDomains.forEach((validDomain) => {
    const distance = levenshteinDistance(domain, validDomain);
    if (distance < minDistance && distance <= tolerance) {
      minDistance = distance;
      closestDomain = validDomain;
    }
  });

  if (!closestDomain) {
    return "";
  }

  const correctedEmail = `${localPart}@${closestDomain}.com`;

  return correctedEmail;
}

export function validateEmail(email) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export function countCharacters(char, string) {
  return string.split("").reduce((acc, ch) => (ch === char ? acc + 1 : acc), 0);
}

export function isValidDate(input) {
  if (typeof input === "number") {
    input = input.toString();
  }

  const date = new Date(input);

  if (Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date.getTime())) {
    return true;
  } else {
    return false;
  }
}

export function isValidTime(time) {
  const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;
  return timeRegex.test(time);
}

export const updatePostCodeMask = (country, setter) => {
  switch (country) {
    case "PT":
      setter("9999-999");
      break;
    case "ES":
    case "FR":
      setter("99999");
      break;
    default:
      setter("*********");
  }
};
