import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { scrollUpPage } from "src/_utils/elements";
import { CSS } from "src/_utils/styles";
import { getQueryParams } from "src/_utils/urls";
import { Btn } from "src/AbstractElements";
import EBSSSTable from "src/base/atoms/EBSSSTable";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { remotePath } from "src/base/settings/Paths";
import TablePage from "src/base/TablePage";
import TooltipToggle from "src/base/TooltipToggle";

const OrdersPage = () => {
  const { trans } = useEBSSSLayout();
  const { fetchOrders, appSettings, orders } = useEBSSS();
  const [page, setPage] = useState(null);

  useEffect(() => {
    setPage((p) => {
      if (p !== null) {
        return p;
      } else {
        return Number(getQueryParams(window.location.href)?.page);
      }
    });
  }, [window.location.search]);

  useEffect(() => {
    scrollUpPage();
    if (!orders) {
      fetchOrders(1);
      return;
    }
    if (page) {
      fetchOrders(page);
    }
  }, [page]);

  console.log(orders);

  return (
    <TablePage breads={[{ name: trans("website.orders"), active: true, url: "/crm/orders" }]} title="" description="">
      <EBSSSTable
        pageHandler={setPage}
        data={orders}
        cols={[
          {
            name: "#",
            selector: (row: { id: any }) => <span>{row.id}</span>,
            sortable: true,
            center: true,
          },
          {
            name: trans("website.created_at"),
            selector: (row: { created_at: any }) => row.created_at,
            sortable: true,
            center: false,
          },
          {
            name: trans("website.to"),
            selector: (row: { billing_name: any }) => row.billing_name,
            sortable: true,
            center: false,
          },
          {
            name: trans("website.items"),
            selector: (row: any) =>
              row?.items.slice(0, 3).map(
                (
                  i: {
                    product: { photo: string; name: string | JSX.Element };
                    id: number;
                  },
                  idx: number
                ) => {
                  return i.product.photo ? (
                    <TooltipToggle title={i.product.name} id={i.id} style={CSS({ display: "inline-grid" })}>
                      <img style={CSS({ borderRadius: 50 })} src={remotePath.assetsUrl(i.product.photo)} width="40" height="20" />
                      {idx === row?.items?.length - 2 && row?.items?.length > 3 ? (
                        <small style={CSS({ marginLeft: 2.5 })}> + {row?.items?.length - 3}</small>
                      ) : (
                        <></>
                      )}
                    </TooltipToggle>
                  ) : (
                    <></>
                  );
                }
              ),
            sortable: true,
            center: false,
          },
          {
            name: trans("website.value"),
            selector: (row: { total: any; discount_coupon_value: any }) =>
              (Number(row.total) - Number(row.discount_coupon_value ?? "0")).toFixed(2) + " " + appSettings?.currency_symbol,
            sortable: true,
            center: false,
          },
          {
            name: "",
            selector: (row: any): any => {
              return (
                <div style={CSS({ gap: 5, display: "flex" })}>
                  <Link
                    to={process.env.PUBLIC_URL + "/crm/orders/" + row.token}
                    state={{
                      order: { ...row, settings: orders?.data["settings"] },
                    }}
                  >
                    <Btn
                      className="text-white btn-pill"
                      attrBtn={{
                        color: "info",
                        className: "info",
                      }}
                    >
                      {trans("common.see")}
                    </Btn>
                  </Link>
                </div>
              );
            },
            sortable: true,
            center: true,
          },
        ]}
      />
    </TablePage>
  );
};
export default OrdersPage;
