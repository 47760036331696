import { H6 } from "src/AbstractElements";
import { useEBSSSLayout } from "./context/EBSSSLayoutContext/useEBSSSLayout";

const TableHeaderItem = ({ translationKey }) => {
  const { trans } = useEBSSSLayout();

  return (
    <td className="item">
      <H6 attrH6={{ className: "mb-0" }}>{trans(translationKey)}</H6>
    </td>
  );
};

export default TableHeaderItem;
