//@ts-nocheck
import { Fragment } from "react";
import { H5, H6, Image } from "src/AbstractElements";

const EBSSSProfileTabHeader = ({ h6, title }) => {
  return (
    <Fragment>
      <div className="post-header">
        <div className="media">
          <Image
            attrImage={{
              className: "img-thumbnail rounded-circle me-3 d-none",
              src: ``,
              alt: "Generic placeholder image",
            }}
          />
          <div className="media-body align-self-center">
            <p>
              <H5 attrH5={{ className: "user-name" }}>{title}</H5>
            </p>
            <H6>{h6}</H6>
          </div>
        </div>
        <div className="post-setting d-none">
          <i className="fa fa-ellipsis-h"></i>
        </div>
      </div>
    </Fragment>
  );
};

export default EBSSSProfileTabHeader;
