//@ts-nocheck
import { Fragment } from "react";
import { Container } from "reactstrap";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";

const DefaultContain = ({ children }: { children: React.ReactElement | React.ReactElement[] }) => {
  const { cmsLayout } = useEBSSSLayout();
  return (
    <Fragment>
      {cmsLayout ? (
        <Fragment>{children}</Fragment>
      ) : (
        <Container fluid={cmsLayout ? false : true} className={cmsLayout ? "" : "default-dash"} id="inner-container">
          {children}
        </Container>
      )}
    </Fragment>
  );
};
export default DefaultContain;
