export const formatCurrencyEuro = (amount: string) => {
  return parseFloat(amount).toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });
};

export const formatToStripeAmount = (value: string) => {
  // Convert string to a floating-point number
  const amount = parseFloat(value);

  // Multiply by 100 to convert to the smallest currency unit (e.g., cents)
  const stripeAmount = Math.round(amount * 100);

  return stripeAmount;
};
