const FeedbackMessages = ({ messages }) => {
  if (!messages) {
    return null;
  }

  if (Array.isArray(messages)) {
    return (
      <ul>
        {messages.map((message, index) => (
          <li key={index}>
            <small className="text-danger fw-bold">{message}</small>
          </li>
        ))}
      </ul>
    );
  }
  return <small className="text-danger fw-bold">{messages}</small>;
};

export default FeedbackMessages;
