import { Fragment, useEffect, useRef, useState } from "react";
import { Key, Lock, Mail, Phone, User } from "react-feather";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { getCountryCode } from "src/_utils/validations";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import FeedbackMessages from "src/base/FeedbackMessage";
import { cleanErrorsAndMessages, fetchPassword, fetchSignin, fetchSignup, fetchToken, proceedAfterAcceptTerms, Session } from "src/base/store/authSlice";
import { ReduxDataAuthKey, ReduxDataKey } from "src/base/store/store";
import { EmailAddress, Login } from "src/Constant";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import TermsPage from "../Terms/TermsPage";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { toast } from "react-toastify";
import { Layout } from "src/base/store/layoutSlice";
import { getQueryParams } from "src/_utils/urls";
import { remotePath } from "src/base/settings/Paths";
import HelperHTTP from "src/_utils/http_module_handler";

const RegisterForm = ({
  outOriginalPage,
  startAtTokenVerify,
  setEndRegistrationCheckoutModal,
  verifyLaterButton,
  callbackForm,
  callbackWhenVerifyToken,
  setAsVerified,
  setInTOS,
}: any) => {
  const { trans } = useEBSSSLayout();
  const [registerStage, setRegisterStage] = useState<"start" | "token" | "password">("start");
  const [pass, setPass] = useState({
    password: "",
    password_confirmation: "",
  });
  const [token, setToken] = useState("");
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
  });
  const dispatcher = useDispatch<any>();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const { TOS, POS, fetchTOS, fetchPOS, agreeWithTerms } = useEBSSS();
  const [isPasswordVisible, setisPasswordVisible] = useState(false);
  const [goToTerm, setGoToTerm] = useState<any>(0);
  const [availableEmail, setAvailableEmail] = useState(null);
  const [loadingEmailCheck, setLoadingEmailCheck] = useState(false);
  const [emailConfirmation, setEmailConfirmation] = useState("");
  const [checkRead, setCheckRead] = useState(false);

  const toggle = () => {
    setisPasswordVisible(!isPasswordVisible);
  };

  const handleSubmitStart = async (e) => {
    e.preventDefault();
    dispatcher(fetchSignup({ ...user, activeLanguage: "pt" }));
  };

  const handleSubmitToken = async (e) => {
    e.preventDefault();
    if (!token.trim()) {
      toast(trans("website.enter-token"), { type: "error" });
      return;
    }
    dispatcher(fetchToken({ token, activeLanguage: "pt" }));
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (!pass.password.trim() || !pass.password_confirmation.trim()) {
      toast(trans("website.enter-password"), { type: "error" });
      return;
    }
    if (pass.password !== pass.password_confirmation) {
      toast(trans("website.passwords-dont-match"), { type: "error" });
      return;
    }
    dispatcher(
      fetchPassword({
        ...pass,
        token: sessionNow?.tempTokenActivated,
        activeLanguage: "pt",
        registeredFromCheckout: true,
      })
    );
  };

  useEffect(() => {
    dispatcher(cleanErrorsAndMessages());
  }, []);

  useEffect(() => {
    if ((sessionNow?.redirect?.signupToken && !sessionNow?.redirect?.signupPassword) || startAtTokenVerify) {
      setRegisterStage("token");
    }
    if (
      (sessionNow?.redirect?.signupToken && sessionNow?.redirect?.signupPassword) ||
      (!sessionNow?.redirect?.signupToken && sessionNow?.redirect?.signupPassword && startAtTokenVerify)
    ) {
      setRegisterStage("password");
    }
  }, [sessionNow?.redirect]);

  useEffect(() => {
    fetchTOS();
    fetchPOS();
  }, []);

  useEffect(() => {
    if (goToTerm === 2) {
      console.log("Closing registration flow...");
      dispatcher(proceedAfterAcceptTerms());
      toast(trans("website.youre-registered-now"), { type: "success" });
      setEndRegistrationCheckoutModal(true);
    }
  }, [goToTerm]);

  useEffect(() => {
    const getPermissionParams = getQueryParams(window.location.href);

    if (sessionNow?.registeredFromCheckout) {
      setInTOS();

      let payloadAuth = {
        email: user.email,
        password: pass.password,
        actualSession: sessionNow?.session ? sessionNow?.session : null,
        activeLanguage: ebsssLayout?.activeLanguage,
      };

      // if (Number(getPermissionParams?.newUserActivationRequired) === 1) {
      //   payloadAuth["token"] = token;
      // }

      payloadAuth["token"] = token;

      dispatcher(fetchSignin(payloadAuth));
    }
  }, [sessionNow?.registeredFromCheckout]);

  const accept = async (tkn: string) => {
    let getAlreadySignedTerms = JSON.parse(localStorage.getItem("TEMP_TERM_SIGNED") ?? "[]");
    getAlreadySignedTerms.push({
      term: {
        type: [TOS, POS][goToTerm]?.terms?.type,
        version_no: [TOS, POS][goToTerm]?.terms?.version_no,
      },
      updated_at: new Date().getTime(),
      recent: true,
    });

    try {
      await agreeWithTerms(tkn).then((res) => {
        if (res?.user_service_term_condition) {
          console.log(res);
          setGoToTerm(goToTerm + 1);
          setCheckRead(false);
          localStorage.setItem("TEMP_TERM_SIGNED", JSON.stringify(getAlreadySignedTerms));
        } else {
          toast(trans("website.an_unexpected_error_occurs"));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const debounceTimeoutRef = useRef(null);

  const handleEmailCheck = async (emailToCheck) => {
    setLoadingEmailCheck(true);
    const fetch = await HelperHTTP.customRequest(remotePath.clientInstanceUrl(), remotePath.endPoints.CHECK_EMAIL)
      .api()
      .post({
        data: { email: emailToCheck },
      });

    console.log(fetch);
    if (fetch?.data || fetch?.errors) {
      setLoadingEmailCheck(false);
      setAvailableEmail(fetch?.data ? fetch?.data?.available : fetch?.errors.available);
    }
  };
  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  const handleChangeBouncer = (value: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(async () => {
      await handleEmailCheck(user.email);
    }, 2500);
  };

  useEffect(() => {
    if (user.email && user.email.includes("@") && user.email.includes(".")) {
      handleChangeBouncer(user.email);
    }
  }, [user.email]);

  useEffect(() => {
    if (callbackForm) {
      callbackForm({ ...user });
    }
  }, [user.email, user.first_name, user.last_name, user.mobile_number]);

  useEffect(() => {
    if (outOriginalPage && sessionNow?.tempTokenActivated) {
      setAsVerified();
    }
  }, [sessionNow?.tempTokenActivated]);

  useEffect(() => {
    if (registerStage === "password") {
      let timeoutToClean = setTimeout(() => {
        document.querySelectorAll('input[type="password"]')?.forEach((input) => {
          input["value"] = "";
        });

        clearTimeout(timeoutToClean);
      }, 1);
    }
  }, [registerStage]);

  useEffect(() => {
    if (user.email) {
      localStorage.setItem(remotePath.localStorageKey() + "_TEMP_EMAIL_REGISTERING", user.email);
    }
  }, [user.email]);

  return !sessionNow?.user?.id && !sessionNow?.registeredFromCheckout ? (
    registerStage === "start" ? (
      <>
        <div className="login-header text-center p-4">
          <h4>{trans("auth.sign-up-title")}</h4>
          <h6>{trans("auth.sign-up-description")}</h6>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <FormGroup>
              <Label>{trans("auth.first_name")}</Label>
              <InputGroup>
                <InputGroupText>
                  <User />
                </InputGroupText>
                <Input type="text" required defaultValue={user.first_name} onChange={(event) => setUser({ ...user, first_name: event.target.value })} />
              </InputGroup>
              <FeedbackMessages messages={sessionNow?.errors?.signup["first_name"]} />
            </FormGroup>
          </div>
          <div className="col-sm-6">
            <FormGroup>
              <Label>{trans("auth.last_name")}</Label>
              <InputGroup>
                <InputGroupText>
                  <User />
                </InputGroupText>
                <Input type="text" required defaultValue={user.last_name} onChange={(event) => setUser({ ...user, last_name: event.target.value })} />
              </InputGroup>
              <FeedbackMessages messages={sessionNow?.errors?.signup["last_name"]} />
            </FormGroup>
          </div>
        </div>

        <FormGroup>
          <Label>{trans("auth.mobile")}</Label>
          <div className="d-flex flex-row">
            <InputGroup>
              <InputGroupText>
                <Phone />
              </InputGroupText>
              <PhoneInput
                inputStyle={CSS({ width: "100%", display: "flex", height: 40 })}
                countrySelectorStyleProps={{
                  buttonContentWrapperStyle: CSS({ width: "100%" }),
                  dropdownStyleProps: {
                    style: CSS({
                      zIndex: 999999,
                    }),
                  },
                  style: CSS({ flex: "1 0" }),
                  buttonStyle: CSS({
                    backgroundColor: "#F8F9FA",
                    borderTop: "1px solid #DEE2E6",
                    borderBottom: "1px solid #DEE2E6",
                    border: 0,
                    height: 40,
                    borderRadius: 0,
                  }),
                }}
                required
                defaultCountry={getCountryCode()}
                value={user.mobile_number}
                onChange={(phone) =>
                  setUser({
                    ...user,
                    mobile_number: phone.replace("+", ""),
                  })
                }
              />
            </InputGroup>
          </div>
          <FeedbackMessages messages={sessionNow?.errors?.signup["mobile_number"]} />
        </FormGroup>

        <FormGroup>
          <Label>{trans("auth.email")}</Label>
          <InputGroup>
            <InputGroupText>
              <Mail />
            </InputGroupText>
            <Input
              type="email"
              required
              className={availableEmail === false ? "is-invalid" : availableEmail === true ? "is-valid" : ""}
              defaultValue={user.email}
              onChange={(event) => setUser({ ...user, email: event.target.value })}
            />
          </InputGroup>
          <FeedbackMessages messages={sessionNow?.errors?.signup["email"] || (availableEmail === false ? [trans("website.already-registered-email")] : [])} />
        </FormGroup>
        <FormGroup>
          <Label>{trans("auth.email-confirmation-repeat")}</Label>
          <InputGroup>
            <InputGroupText>
              <Mail />
            </InputGroupText>
            <Input
              onPaste={(e) => {
                e.preventDefault();
              }}
              className={
                emailConfirmation.length > 5 && emailConfirmation.includes("@") && emailConfirmation.includes(".")
                  ? emailConfirmation !== user.email
                    ? "is-invalid"
                    : "is-valid"
                  : ""
              }
              type="email"
              required
              defaultValue={emailConfirmation}
              onChange={(event) => setEmailConfirmation(event.target.value)}
            />
          </InputGroup>
          <FeedbackMessages
            messages={
              emailConfirmation.length > 5 && emailConfirmation.includes("@") && emailConfirmation.includes(".")
                ? emailConfirmation !== user.email
                  ? [trans("website.email-are-not-equal")]
                  : []
                : ""
            }
          />
        </FormGroup>
        <FormGroup>
          {loadingEmailCheck ? (
            <div className="text-center">{trans("website.wait-validating-email")}</div>
          ) : availableEmail && emailConfirmation === user.email ? (
            <EBSSSButton
              style={CSS({
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              })}
              loadingKey={ReduxDataAuthKey.signup}
              onClick={handleSubmitStart}
            >
              {trans("auth.sign_up")}
            </EBSSSButton>
          ) : (
            <EBSSSButton
              style={CSS({
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#e9e9e9",
                cursor: "default",
              })}
              onClick={() => {}}
              color="gray"
            >
              {trans("auth.sign_up")}
            </EBSSSButton>
          )}
        </FormGroup>
      </>
    ) : registerStage === "token" ? (
      <>
        <div className="login-header text-center p-4">
          <h4>{trans("website.verify-your-token")}</h4>
          <h6>{trans("website.text-the-code-from-your-email-that-we-provided")}</h6>
        </div>
        <FormGroup>
          <Label>{trans("website.token")}</Label>
          <InputGroup>
            <InputGroupText>
              <Key />
            </InputGroupText>
            <Input type="text" required defaultValue={token} onChange={(event) => setToken(event.target.value)} />
          </InputGroup>
          <FeedbackMessages messages={sessionNow?.errors?.signupToken["token"]} />
        </FormGroup>
        <FormGroup className="d-flex justify-content-between">
          {verifyLaterButton ? (
            <EBSSSButton color="info" onClick={() => setEndRegistrationCheckoutModal(true)}>
              {trans("website.later")}
            </EBSSSButton>
          ) : (
            <></>
          )}
          <EBSSSButton loadingKey={ReduxDataAuthKey.signupToken} onClick={handleSubmitToken}>
            Verificar
          </EBSSSButton>
        </FormGroup>
      </>
    ) : (
      <>
        <div className="login-header text-center p-4">
          <h4>{trans("website.create-your-password")}</h4>
          <h6>{trans("website.your-account-was-already-verified")}</h6>
        </div>
        <FormGroup>
          <Label>{trans("auth.password")}</Label>
          <InputGroup style={{ position: "relative" }}>
            <InputGroupText>
              <Lock />
            </InputGroupText>
            <Input
              type={isPasswordVisible ? "text" : "password"}
              required
              defaultValue={""}
              onChange={(event) => setPass({ ...pass, password: event.target.value })}
            />
            <div className="text-primary small show-hide-toggle" onClick={toggle}>
              <span>{!isPasswordVisible ? trans("website.show") : trans("website.hide")}</span>
            </div>
          </InputGroup>
          <FeedbackMessages messages={sessionNow?.errors?.signupPassword["password"]} />
        </FormGroup>

        <FormGroup>
          <Label>{trans("auth.password_confirmation")}</Label>
          <InputGroup style={{ position: "relative" }}>
            <InputGroupText>
              <Lock />
            </InputGroupText>
            <Input
              type={isPasswordVisible ? "text" : "password"}
              required
              defaultValue={""}
              onChange={(event) =>
                setPass({
                  ...pass,
                  password_confirmation: event.target.value,
                })
              }
            />
            <div className="text-primary small show-hide-toggle" onClick={toggle}>
              <span>{!isPasswordVisible ? trans("website.show") : trans("website.hide")}</span>
            </div>
          </InputGroup>
          <FeedbackMessages messages={sessionNow?.errors?.signupPassword["password_confirmation"]} />
        </FormGroup>
        <FormGroup>
          <EBSSSButton loadingKey={ReduxDataAuthKey.signupPassword} onClick={handleSubmitPassword}>
            Criar
          </EBSSSButton>
        </FormGroup>
      </>
    )
  ) : !sessionNow?.user?.newly_accepted_terms ? (
    <div style={CSS({ wordBreak: "break-word" })}>
      <div className="d-flex flex-column">
        <h3 className="text-center">
          {[TOS, POS][goToTerm]?.terms?.type.includes("privacy") ? trans("website.menu-privacy-policy") : trans("website.menu-terms-conditions")}
        </h3>
        <h5 className="text-center">{[TOS, POS][goToTerm]?.terms?.published_at}</h5>
        <div
          className="p-3 ebsss-terms-points"
          dangerouslySetInnerHTML={{
            __html: [TOS, POS][goToTerm]?.termTranslations?.content,
          }}
        ></div>
        <div className="checkbox p-3">
          <input
            id="agreement"
            type="checkbox"
            className="form-check-input shadow-sm border border-1"
            checked={checkRead}
            onClick={(e) => {
              console.log("agreement box");
              setCheckRead(!checkRead);
            }}
          />
          <label htmlFor="agreement" className="form-label">
            {trans("website.agree-term")}
          </label>
        </div>
        {checkRead ? (
          <button className="btn btn-primary w-50 m-auto mb-3" onClick={() => accept([TOS, POS][goToTerm]?.terms?.token)}>
            {trans("website.agree-term")}
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <div className="d-flex spinner-border text-primary m-auto" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export default RegisterForm;
