import { Fragment, useEffect, useState } from "react";
import { Lock } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { getQueryParams } from "src/_utils/urls";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import FeedbackMessages from "src/base/FeedbackMessage";
import { remotePath } from "src/base/settings/Paths";
import { fetchPassword, fetchSignin, Session } from "src/base/store/authSlice";
import { Layout } from "src/base/store/layoutSlice";
import { ReduxDataAuthKey, ReduxDataKey } from "src/base/store/store";

const RegisterPagePassword = () => {
  const { trans } = useEBSSSLayout();
  const [pass, setPass] = useState({
    password: "",
    password_confirmation: "",
  });
  const dispatcher = useDispatch<any>();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const [isPasswordVisible, setisPasswordVisible] = useState(false);

  const toggle = () => {
    setisPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatcher(
      fetchPassword({
        ...pass,
        token: sessionNow?.tempTokenActivated,
        activeLanguage: "pt",
      })
    );
  };

  useEffect(() => {
    if (!sessionNow?.tempTokenActivated) {
      try {
        dispatcher(
          fetchSignin({
            email: localStorage.getItem(remotePath.localStorageKey() + "_TEMP_EMAIL_REGISTERING") ?? "",
            password: pass.password,
            actualSession: sessionNow?.session ? sessionNow?.session : null,
            activeLanguage: ebsssLayout?.activeLanguage,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [sessionNow?.tempTokenActivated]);

  useEffect(() => {
    return () => {
      localStorage.removeItem(remotePath.localStorageKey() + "_TEMP_EMAIL_REGISTERING");
    };
  }, []);

  return sessionNow?.user?.id ? (
    <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
  ) : (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col
            xl="7 order-1"
            style={{
              backgroundImage: `url("https://micaela.3.ebsss.dev/platform-images/settings/customer_area_background/7679a69f-f07c-4c7c-9af4-f43d771cee18.jpg?p=slider&s=09962273dea6f982797368e0cd6b4f6b")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "block",
            }}
          ></Col>
          <Col xl="5 p-0">
            <div className="login-card1">
              <Form className="theme-form login-form">
                <div className="login-header text-center">
                  <h4>{trans("website.create-your-password")}</h4>
                  <h6>{trans("website.your-account-was-already-verified")}</h6>
                </div>
                <FormGroup>
                  <Label>{trans("auth.password")}</Label>
                  <InputGroup style={{ position: "relative" }}>
                    <InputGroupText>
                      <Lock />
                    </InputGroupText>
                    <Input
                      type={isPasswordVisible ? "text" : "password"}
                      required
                      defaultValue={pass.password}
                      onChange={(event) => setPass({ ...pass, password: event.target.value })}
                    />
                    <div className="text-primary small show-hide-toggle" onClick={toggle}>
                      <span>{!isPasswordVisible ? trans("website.show") : trans("website.hide")}</span>
                    </div>
                  </InputGroup>
                  <FeedbackMessages messages={sessionNow?.errors?.signupPassword["password"]} />
                </FormGroup>

                <FormGroup>
                  <Label>{trans("auth.password_confirmation")}</Label>
                  <InputGroup style={{ position: "relative" }}>
                    <InputGroupText>
                      <Lock />
                    </InputGroupText>
                    <Input
                      type={isPasswordVisible ? "text" : "password"}
                      required
                      defaultValue={pass.password_confirmation}
                      onChange={(event) =>
                        setPass({
                          ...pass,
                          password_confirmation: event.target.value,
                        })
                      }
                    />
                    <div className="text-primary small show-hide-toggle" onClick={toggle}>
                      <span>{!isPasswordVisible ? trans("website.show") : trans("website.hide")}</span>
                    </div>
                  </InputGroup>
                  <FeedbackMessages messages={sessionNow?.errors?.signupPassword["password_confirmation"]} />
                </FormGroup>
                <FormGroup>
                  <EBSSSButton loadingKey={ReduxDataAuthKey.signupPassword} onClick={handleSubmit}>
                    Criar
                  </EBSSSButton>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RegisterPagePassword;
