//@ts-nocheck
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { Session } from "src/base/store/authSlice";
import { ReduxDataKey } from "src/base/store/store";
import { FormComponent, FormDescription, SentFormFeedback } from "./FormCommon";

const FormPage = () => {
  const { trans } = useEBSSSLayout();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { form, appSettings, fetchForm, sendForm } = useEBSSS();
  const [sentForm, setSentForm] = useState(false);
  const getForm = useParams();

  console.log("FORM: ", form);
  console.log("APPSETTINGS: ", appSettings);

  useEffect(() => {
    fetchForm(getForm?.slug);
  }, []);

  return (
    <Page
      breads={[
        {
          name: form?.form?.title,
          active: false,
          url: "/forms/" + getForm?.slug,
        },
      ]}
    >
      <Card className="text-muted">
        {!sentForm ? (
          <CardBody>
            <div className="d-flex flex-row justify-content-between align-items-top text-muted">
              <FormDescription description={form?.form?.description} />
            </div>

            <FormComponent
              form={form}
              authUser={sessionNow?.user}
              sendForm={sendForm}
              trans={trans}
              appSettings={appSettings}
              setSentForm={setSentForm}
            />
          </CardBody>
        ) : (
          <CardBody>
            <SentFormFeedback trans={trans} authUser={sessionNow?.user} />
          </CardBody>
        )}
      </Card>
    </Page>
  );
};

export default FormPage;
