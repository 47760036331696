import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { CSS } from "src/_utils/styles";
import EBSSSPrintButton from "src/base/atoms/EBSSSPrintButton";
import EBSSSTicket from "src/base/atoms/EBSSSTicket";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { remotePath } from "src/base/settings/Paths";

const DateGap = ({ reservation, trans }) => {
  if (reservation?.order_item?.rental_start_at && reservation?.order_item?.rental_end_at) {
    const rentalStartAt = reservation?.order_item?.rental_start_at.split(" ");
    const rentalEndAt = reservation?.order_item?.rental_end_at.split(" ");

    const dataStart = new Date(rentalStartAt[0]).getTime();
    const dataEnd = new Date(rentalEndAt[0]).getTime();

    const diffTime = Math.abs(dataEnd - dataStart);
    const value = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return (
      <span>
        {value} {trans("website.entity-days-period")}
      </span>
    );
  }
  return <></>;
};

const EntityReservationPage = () => {
  const { trans } = useEBSSSLayout();
  const { reservation, appSettings, fetchReservation } = useEBSSS();
  const getReservation = useParams();

  useEffect(() => {
    fetchReservation(getReservation?.token);
  }, []);

  const orderItem = reservation?.order_item;
  const product = orderItem?.product;
  const includedExtras = orderItem?.extras;

  const calculateDaysPeriod = (start, end) => {
    const startDate = new Date(start).getTime();
    const endDate = new Date(end).getTime();
    const diffTime = Math.abs(endDate - startDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  return (
    <Page
      breads={[
        {
          name: trans("website.menu-entity-reservations"),
          active: false,
          url: "/entity-reservations",
        },
        {
          name: trans("website.menu-entity-reservations") + " #" + (reservation?.id ? reservation?.id : ""),
          active: true,
        },
      ]}
    >
      {!!reservation ? (
        <Card>
          <CardBody>
            <div className="content-body text-muted row">
              <div className="col-lg-6 pl-0 pr-0">
                <div className="card">
                  <div className="card-body invoice-head">
                    <div className="row">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="media">
                            <div className="media-left">
                              <a href="/zeta/dashboard/default">
                                {appSettings?.invoice_logo && (
                                  <img
                                    src={remotePath.assetsUrl(appSettings?.invoice_logo)}
                                    alt="company logo"
                                    width="80px"
                                    className="media-object img-60 media"
                                  />
                                )}
                              </a>
                            </div>
                            <div className="m-l-20 media-body text-muted">
                              <h4 className="media-heading text-muted">{appSettings?.invoice_company_name ? appSettings?.invoice_company_name : ""}</h4>
                              <p>
                                {" "}
                                {appSettings?.invoice_company_address}, {appSettings?.invoice_company_city_state}, {appSettings?.invoice_company_country}
                                <br />
                                <span>289-335-6503</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <li className="list-inline-item">
                            <div className="text-muted">
                              <h4 className="media-heading text-muted">{trans("website.shopping-checkout")}</h4>
                              <p>
                                {reservation?.order_item?.order?.billing_name} {reservation?.order_item?.order?.billing_vat}
                              </p>
                              <p>
                                {reservation?.order_item?.order?.billing_address} {reservation?.order_item?.order?.billing_address_2}
                              </p>
                              <p>
                                {reservation?.order_item?.order?.billing_postal_code} {reservation?.order_item?.order?.billing_city}
                              </p>
                              <p>
                                {reservation?.order_item?.order?.billing_state} {reservation?.order_item?.order?.billing_postal_code}
                              </p>
                            </div>
                          </li>
                        </div>
                        <div className="col-sm-1">
                          <EBSSSPrintButton />
                        </div>
                      </div>

                      <div className="col-md-6 mt-1">
                        <li className="list-inline-item d-none">
                          <div className="float-right d-print-none">
                            <button onClick={() => window.print()} className="print_click btn btn-gradient-info">
                              <i style={{ fontSize: "45px" }} className="la la-print"></i>
                            </button>
                          </div>
                        </li>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-9 text-muted">
                        <h6>{trans("website.reservation-participants-title")}</h6>
                        {reservation?.participants_final?.age_groups?.length > 0 &&
                          reservation?.participants_final?.age_groups?.map((participant) => (
                            <div key={participant.age_range}>
                              <strong>{participant.age_range}: </strong> {participant.quantity}
                            </div>
                          ))}
                      </div>

                      <div className="col-md-3">
                        <div
                          className="text-center"
                          style={CSS({
                            padding: 5,
                            border: "1px solid #f9f9f9",
                            marginBottom: 5,
                            borderRadius: 5,
                          })}
                        >
                          <span
                            style={CSS({
                              color: reservation?.statusColor === "yellow" ? "orange" : reservation?.statusColor,
                            })}
                          >
                            {trans(`entity.reservation-status-${reservation.status}`)}
                          </span>
                        </div>
                        <div className="text-center p-1" style={{ backgroundColor: "#f4f5fa" }}>
                          <h5
                            className="mt-1 p-1 text-primary d-sm-inline-block ps-2 pe-2"
                            style={{
                              color: "black !important",
                              borderRadius: 10,
                            }}
                          >
                            {reservation?.reference}
                          </h5>
                          <div
                            className={`card-content ${reservation?.status !== "done" && reservation?.status !== "cancelled" ? "collapse show" : "collapse"}`}
                          >
                            <div className="card-body" style={{ padding: 0 }}>
                              <img
                                src={`${remotePath.clientInstanceUrl()}/entity-reservation/qrcode/${reservation?.token}`}
                                width="200px"
                                height="200px"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="table-responsive project-invoice">
                          <table className="table table-bordered mb-0">
                            <thead className="thead-light">
                              <tr>
                                <th colSpan={5}>
                                  <h3 className="text-muted">{trans("website.reservation-included")}</h3>
                                </th>
                              </tr>
                              <tr>
                                <th className="text-muted">Name</th>
                                {orderItem?.option && <th>{orderItem?.option?.productOption?.title}</th>}
                                {includedExtras?.length > 0 && <th>Extras</th>}
                                <th></th>
                                <th></th>
                                {reservation?.order_item?.shiftTime && <th>Shift Time</th>}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {product ? (
                                    <div className="d-flex flex-column">
                                      <Link target="_blank" to={`${process.env.PUBLIC_URL}/crm/products/${product.slug}`}>
                                        <span>{product.name}</span>
                                      </Link>
                                      {product.model ? (
                                        <span>
                                          {trans("website.entity")} {product.model}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ) : (
                                    orderItem?.item_name
                                  )}
                                </td>
                                {orderItem?.option && <td>{orderItem?.option.title}</td>}
                                {includedExtras?.length > 0 && (
                                  <td>
                                    {includedExtras?.map((extra) => (
                                      <p key={extra.id}>
                                        <i className="fa fa-plus"></i> {extra.title}
                                      </p>
                                    ))}
                                  </td>
                                )}
                                <td>
                                  <i className="fa fa-calendar-check-o"></i> {trans("website.reservation-checkin")}:{" "}
                                  <span className="text-muted">{new Date(reservation?.order_item?.rental_start_at).toLocaleDateString().split(" ")[0]}</span>
                                  {" - "}
                                  <i className="fa fa-calendar-times-o"></i> {trans("website.reservation-checkout")}:{" "}
                                  <span className="text-muted">{new Date(reservation?.order_item?.rental_end_at).toLocaleDateString().split(" ")[0]}</span>
                                </td>
                                <td>
                                  <DateGap reservation={reservation} trans={trans} />
                                </td>
                                {reservation?.order_item?.shiftTime && (
                                  <td>
                                    <span className="text-muted">{reservation?.order_item?.shiftTime.name}</span>
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-lg-12">
                        <div className="table-responsive project-invoice">
                          {reservation?.payment_requests?.length > 0 && (
                            <table className="table table-bordered mb-0">
                              <thead className="thead-light">
                                <tr>
                                  <th colSpan={5}>
                                    <h3 className="text-muted">{trans("website.reservation-payments")}</h3>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {reservation?.payment_requests?.length === 0 ? (
                                  <></>
                                ) : (
                                  reservation?.payment_requests?.map((paymentRequest) => (
                                    <tr key={paymentRequest?.id}>
                                      <td>{paymentRequest?.paymentMomment && paymentRequest?.paymentMomment?.name}</td>
                                      <td>#{paymentRequest?.id}</td>
                                      <td>
                                        {Number(paymentRequest?.amount).toFixed(2)} {paymentRequest?.currency?.symbol ?? appSettings?.currency_symbol}
                                      </td>
                                      <td>
                                        {new Date(paymentRequest?.expected_date).toLocaleDateString()}{" "}
                                        {new Date(paymentRequest?.expected_date).toLocaleTimeString()}
                                      </td>
                                      <td className="d-flex">
                                        {paymentRequest?.expected_date < new Date().toISOString() && paymentRequest?.status === "open" ? (
                                          <span style={CSS({ height: 27.5 })} className="m-auto badge badge-default badge-danger">
                                            {trans("billing.payment-request-status-expired")}
                                          </span>
                                        ) : (
                                          <span className={`m-auto badge badge-sm badge-${paymentRequest?.statusColorBootStrap} badge-lg`}>
                                            {trans("billing.payment-request-status-" + paymentRequest?.status)}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <Link
                                          style={CSS({ height: 27.5 })}
                                          className="m-auto d-flex btn btn-primary btn-sm align-items-center justify-content-center gap-2"
                                          to={process.env.PUBLIC_URL + `/crm/contract-payment/${paymentRequest.token}`}
                                        >
                                          <i className="fa fa-eye"></i> {trans("entity.reservation-open-payment-request")}
                                        </Link>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div className="row d-flex justify-content-center">
                      <div className="col-lg-12 col-xl-4 ml-auto align-self-center">
                        <div className="text-center">
                          <small className="font-12">{trans("website.note_reservation")}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <EBSSSTicket data={reservation} dataType={"reservation"} callbackRefresh={() => fetchReservation(getReservation?.token)} />
            </div>
          </CardBody>
        </Card>
      ) : (
        <></>
      )}
    </Page>
  );
};

export default EntityReservationPage;
