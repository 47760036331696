import { Dispatch, Fragment, useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { FaUpload } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { Btn, Image } from "src/AbstractElements";
import { useEBSSS } from "../context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "../context/EBSSSLayoutContext/useEBSSSLayout";
import { remotePath } from "../settings/Paths";
import { Session } from "../store/authSlice";
import { ReduxDataKey } from "../store/store";
import EBSSSButton from "./EBSSSButton";
import EBSSSModal from "./EBSSSModal";
import ReCAPTCHA from "react-google-recaptcha";
import EBSSSInitialNameProfile from "./EBSSSInitialNameProfile";
import { Layout } from "../store/layoutSlice";

const ChatMessage = ({ userId, currentUserId, userPic, messageText, messageDate, files, trans, arrTickets, index }) => {
  const isntCurrentUser = userId !== currentUserId;

  return (
    <div className={`ebsss-ticket-chat-message ${isntCurrentUser ? "ebsss-ticket-right gap-2" : "ebsss-ticket-left flex-row-reverse d-flex gap-2"}`}>
      {!isntCurrentUser && arrTickets[index - 1]?.user_id !== userId ? (
        typeof userPic === "string" ? (
          <img src={userPic} alt="User" className="ebsss-ticket-user-pic" />
        ) : (
          userPic
        )
      ) : (
        <div style={{ width: 50 }}></div>
      )}
      <div className="ebsss-ticket-message-content">
        <div className="ebsss-ticket-message-text" dangerouslySetInnerHTML={{ __html: messageText }}></div>
        {files?.length > 0 ? (
          <div className="d-flex flex-column p-1 bg-light rounded-2">
            <small className="text-secondary">
              <i className="fa fa-paperclip"></i> {trans("website.attachments")}
            </small>
            <PreviewTicketFiles outModal={true} fromTickets={files} />
          </div>
        ) : (
          <></>
        )}
        <span className="ebsss-ticket-message-date">{new Date(messageDate).toLocaleDateString() + " " + new Date(messageDate).toLocaleTimeString()}</span>
      </div>
      {isntCurrentUser && arrTickets[index - 1]?.user_id !== userId ? (
        typeof userPic === "string" ? (
          <img src={userPic} alt="User" className="ebsss-ticket-user-pic" />
        ) : (
          userPic
        )
      ) : (
        <div style={{ width: 50 }}></div>
      )}
    </div>
  );
};

const SentTicketFeedback = ({ trans, setSentTicket }) => {
  const [submitted] = useState(true);
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimationComplete(true);
    }, 1000);
  }, []);

  return (
    <div>
      {submitted && (
        <div
          className={`d-flex flex-column text-center ebsss-sent-ticket-feedback-animated-div ${animationComplete ? "ebsss-sent-ticket-feedback-complete" : ""}`}
        >
          {animationComplete && (
            <>
              <i className="fa fa-check-circle fs-4 mb-3"></i>
              <span className="lead">{trans("website.your-ticket-was-sent")}</span>
              <h6 className="fw-bold">{trans("website.we-will-reply-soon")}</h6>
              <hr />
              <div
                className="d-flex flex-row align-items-center gap-2"
                style={CSS({ cursor: "pointer" })}
                onClick={() => {
                  setAnimationComplete((s) => !s);
                  setSentTicket((s) => !s);
                }}
              >
                <i className="fa fa-arrow-left fs-7"></i> <small>{trans("website.back")}</small>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export const PreviewTicketFiles = ({
  outModal,
  fromForm,
  fromTickets,
}: {
  outModal: boolean;
  fromTickets?: Array<any>;
  fromForm?: {
    setTicketFiles: Dispatch<any>;
    ticketFiles: any[];
  };
}) => {
  return (
    <div className={`mt-2 m-auto d-flex justify-content-${outModal ? "start" : "center"} align-items-end`}>
      {(fromTickets ? fromTickets : fromForm.ticketFiles)?.map((f) => {
        return (
          <div
            style={CSS({
              position: "relative",
              display: "flex",
              flexDirection: "row",
            })}
          >
            {!fromTickets ? (
              <div
                style={CSS({
                  position: "absolute",
                  display: "flex",
                  marginLeft: "75%",
                  cursor: "pointer",
                })}
                onClick={() => {
                  fromForm.setTicketFiles((s) => s.filter((fs) => fs.name !== f.name));
                }}
              >
                <i className="fa fa-xmark-circle fs-5 text-danger"></i>
              </div>
            ) : (
              <></>
            )}
            <Image
              attrImage={{
                onClick: () => (fromTickets ? window.open(remotePath.assetsUrl(f.filepath), "_blank") : null),
                width: "50px",
                height: "50px",
                loading: "lazy",
                style: {
                  margin: 5,
                  width: 50,
                  height: 50,
                  borderRadius: 5,
                  cursor: fromTickets ? "pointer" : "default",
                },
                src: !fromTickets
                  ? f?.type?.includes("image")
                    ? URL.createObjectURL(f)
                    : "https://www.svgrepo.com/show/532809/file-zipper.svg"
                  : ["png", "jpg", "jpeg"].includes(f?.filepath?.split(".")[f?.filepath?.split(".")?.length - 1])
                  ? remotePath.assetsUrl(f?.filepath)
                  : "https://www.svgrepo.com/show/532809/file-zipper.svg",
              }}
            />
          </div>
        );
      })}
      {!fromTickets ? (
        fromForm?.ticketFiles?.length > 0 ? (
          ["", "", ""]?.slice(0, fromForm?.ticketFiles?.length ? -fromForm?.ticketFiles?.length : 3)?.map(() => {
            return (
              <div
                style={CSS({
                  marginLeft: 5,
                  width: 50,
                  height: 50,
                  display: "flex",
                  backgroundColor: "#E0E0FA",
                  borderRadius: 5,
                  border: "1px dashed #6362E7",
                  justifySelf: "flex-end",
                })}
              >
                <i className="fa fa-image fs-5 m-auto" style={CSS({ color: "#6362E7" })}></i>
              </div>
            );
          })
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

const EBSSSTicket = ({
  data,
  dataType,
  contactCenter,
  repliedForms,
  dataId,
  secondaryDataId,
  answerTo,
  replierToken,
  ownTicket,
  fullWidth,
  validateBeforeSend,
  callbackRefresh,
}: {
  data: any;
  dataType?: string;
  contactCenter?: boolean;
  repliedForms?: boolean;
  dataId?: number;
  secondaryDataId?: number;
  answerTo?: number;
  replierToken?: string;
  ownTicket?: boolean;
  fullWidth?: boolean;
  validateBeforeSend?: boolean;
  callbackRefresh?: () => Promise<void>;
}) => {
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const { trans } = useEBSSSLayout();
  const { sendTicket, setTickets, tickets, reservation, appSettings } = useEBSSS();
  const [ticketText, setTicketText] = useState("");
  const [ticketFiles, setTicketFiles] = useState<File[]>([]);
  const [modal, setModal] = useState(false);
  const [sentTicket, setSentTicket] = useState(false);
  const navigate = useNavigate();
  const [captchaKey, setCaptchaKey] = useState(null);

  const handleCaptchaResponse = (v) => {
    setCaptchaKey({ "g-recaptcha-response": v });
  };

  const makeTicket = async () => {
    if (!ticketText || !ticketText.trim()) {
      toast(trans("website.type_something"), {
        type: "error",
      });
      return;
    }

    if (ticketText.trim()?.length < 10) {
      toast(trans("website.your_ticket_is_too_short"), {
        type: "error",
      });
      return;
    }

    if (ebsssLayout?.commonAppSettings?.crm_captcha && ebsssLayout?.commonAppSettings?.crm_captcha_key && !captchaKey) {
      toast(trans("website.form-please-confirm-captcha"), {
        type: "error",
      });
      return;
    }

    try {
      if (validateBeforeSend && !dataId) {
        toast(trans("website.please-select-related-item"), { type: "error" });
        return;
      }
      let payloadTicket = {
        [`${dataType}_id`]: contactCenter ? dataId : data?.id,
        type: dataType,
        view: "logged",
        message: ticketText,
        files_quantity: ticketFiles?.length,
      };

      if (captchaKey) {
        payloadTicket = { ...payloadTicket, captchaKey };
      }

      if (answerTo) {
        payloadTicket["answer_to"] = answerTo;
      }

      if (dataType === "reservation") {
        payloadTicket["order_id"] = secondaryDataId ? secondaryDataId : reservation?.order_item?.order_id;
      }

      let fdt = new FormData();
      Object.entries(payloadTicket).forEach((t: [string, any]) => {
        fdt.append(t[0], t[1]);
      });

      if (ticketFiles?.length > 0) {
        ticketFiles.forEach((file, index) => {
          fdt.append(`file_${index}`, file);
        });
      }

      await sendTicket(fdt, ticketFiles?.length > 0, replierToken).then((res) => {
        if (res.ticket_creation && !contactCenter) {
          setSentTicket(true);
          let newTckt = {
            [`${dataType}_id`]: contactCenter ? dataId : data?.id,
            type: dataType,
            user_id: sessionNow?.user?.id,
            view: "logged",
            message: ticketText,
            files: res.files ?? [],
            created_at: new Date(res.ticket_creation),
          };

          if (dataType === "reservation") {
            newTckt["order_id"] = secondaryDataId ? secondaryDataId : reservation?.order_item?.order_id;
          }

          setTickets([newTckt, ...((tickets ?? []) as Array<any>)]);
        } else {
          toast(trans("website.ticket_answered"));
        }

        if (replierToken) {
          toast(trans("website.ticket_answered"));
          let tmtToSendUser = setTimeout(() => {
            if (sessionNow?.user?.id) {
              navigate(process.env.PUBLIC_URL + "/dashboard");
            } else {
              navigate(process.env.PUBLIC_URL + "/auth/sign-in");
            }
            clearTimeout(tmtToSendUser);
          }, 3000);
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setTicketText("");
      setTicketFiles([]);
      if (sessionNow?.user?.id && window.location.href.includes("/crm/contacts")) {
        navigate(process.env.PUBLIC_URL + "/crm/contacts/center");
      }
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    if (ticketFiles?.length < 3) {
      if (ticketFiles.find((f) => f.name === meta.name)) {
        return;
      }
      setTicketFiles([...ticketFiles, file]);
      return;
    }
  };

  return (
    <div className={contactCenter || fullWidth ? "col-12 no-print-ebsss" : "col-lg-6 no-print-ebsss"}>
      <div className={contactCenter || repliedForms || fullWidth ? "" : "card p-3"}>
        {!contactCenter ? (
          <div className={repliedForms ? "mt-4" : "card-header card-head-colour"}>
            <div className="chat-sidebar-toggle d-block d-lg-none mr-1">
              <i className="ft-align-justify font-large-1 cursor-pointer"></i>
            </div>
            <h4 className="card-title mb-0">
              {trans("website." + dataType + "_tickets")}
              <i
                style={{
                  marginLeft: "15px",
                  cursor: "pointer",
                }}
                onClick={callbackRefresh}
                className="la la-refresh refresh_chat"
              ></i>
            </h4>
            <h6 className="mt-2 small">{trans("website.tickets_description")}</h6>
          </div>
        ) : (
          <></>
        )}
        <div className="pl-0 pr-0">
          {!contactCenter ? (
            <div className="col-lg-12 pr-0 pl-0">
              <div className="card-content collapse show show-chat">
                <div
                  className="d-flex flex-column-reverse"
                  style={CSS({
                    minHeight: 400,
                    maxHeight: 500,
                    overflowX: "hidden",
                    overflowY: "auto",
                  })}
                >
                  {tickets?.length === 0 ? (
                    <div className="d-flex align-items-center justify-content-center" style={CSS({ height: 400 })}>
                      <div className="d-flex flex-column align-items-center m-auto">
                        <Image
                          attrImage={{
                            style: CSS({ opacity: 0.5 }),
                            src: remotePath.staticsUrl("notickets.png"),
                            width: "66%",
                            height: "auto",
                          }}
                        />
                        <p className="text-muted">{trans("website.you-have-no-tickets-to-show")}</p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {!sentTicket ? (
                    tickets?.map((tckt, idx) => {
                      return (
                        <Fragment key={tckt.token}>
                          <ChatMessage
                            trans={trans}
                            files={tckt.files}
                            index={idx}
                            arrTickets={tickets}
                            userId={tckt.user_id}
                            currentUserId={sessionNow?.user?.id}
                            userPic={
                              sessionNow?.user?.id === tckt.user_id ? (
                                sessionNow?.user?.profile?.avatar ? (
                                  remotePath.assetsUrl(sessionNow?.user?.profile?.avatar)
                                ) : (
                                  <EBSSSInitialNameProfile user={sessionNow?.user} width={25} height={25} fontSize={12.5} />
                                )
                              ) : (
                                "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"
                              )
                            }
                            messageText={tckt.message}
                            messageDate={tckt.created_at}
                          />
                        </Fragment>
                      );
                    })
                  ) : (
                    <SentTicketFeedback trans={trans} setSentTicket={setSentTicket} />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="col-lg-12 pl-0 pr-0">
            <div className="mt-1 mb-1">
              {!sentTicket && !ownTicket ? (
                <>
                  <div className="d-flex flex-column align-items-center" style={{ marginBottom: "8px" }}>
                    {replierToken ? <label className="align-self-start">{trans("website.type-your-message")}</label> : <></>}
                    <textarea
                      value={ticketText}
                      name="message"
                      className="form-control chat-message-send mx-1"
                      rows={3}
                      onChange={(e) => setTicketText(e.target.value)}
                      style={CSS({ textTransform: "none" })}
                    ></textarea>
                  </div>
                  <PreviewTicketFiles
                    fromForm={{
                      setTicketFiles,
                      ticketFiles,
                    }}
                    outModal={true}
                  />
                  <div className="d-flex align-items-center file_first" style={{ marginBottom: "8px" }}></div>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="">
                      <EBSSSModal
                        hideSubmitButton
                        modalTitle={trans("website.ticket_files")}
                        modal={modal}
                        toggle={toggle}
                        triggerEl={
                          <Btn
                            attrBtn={{
                              onClick: toggle,
                              className: `btn img-fluid ${replierToken ? "btn-info" : "btn-light"}`,
                              alt: "",
                            }}
                          >
                            {trans("common.add-file")}
                          </Btn>
                        }
                      >
                        <Form>
                          <div className="dz-message needsclick">
                            <Dropzone
                              addClassNames={{
                                inputLabelWithFiles: "m-auto",
                              }}
                              onChangeStatus={handleChangeStatus}
                              validate={(f) => {
                                let allowedTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
                                if (!allowedTypes.includes(f.meta.type)) {
                                  toast(trans("website.file_not_accepted"));
                                  return;
                                }
                              }}
                              multiple={true}
                              canCancel={false}
                              inputContent={<FaUpload />}
                              PreviewComponent={() => <></>}
                              styles={{
                                dropzoneActive: { borderColor: "green" },
                              }}
                            />
                            <PreviewTicketFiles
                              fromForm={{
                                setTicketFiles,
                                ticketFiles,
                              }}
                              outModal={false}
                            />
                          </div>
                        </Form>
                      </EBSSSModal>
                    </div>
                    <div className="d-flex align-items-end justify-content-end flex-column">
                      {ebsssLayout?.commonAppSettings?.crm_captcha && ebsssLayout?.commonAppSettings?.crm_captcha_key ? (
                        <ReCAPTCHA
                          stoken
                          sitekey={ebsssLayout?.commonAppSettings?.crm_captcha_key ?? "NO_KEY"}
                          onChange={handleCaptchaResponse}
                          onExpired={() => {
                            toast("website.expired_captcha", { type: "error" });
                          }}
                          onErrored={() => {
                            toast("website.retry_captcha", { type: "error" });
                          }}
                        />
                      ) : (
                        <></>
                      )}
                      <EBSSSButton
                        style={CSS({ marginTop: 5 })}
                        loadingKey="ticket"
                        onClick={makeTicket}
                        children={
                          <div className="d-flex flex-row gap-2 align-items-center">
                            <i className="fa fa-send fs-6"></i>
                            <span className="d-none d-lg-block mx-50">{trans("website.send")}</span>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EBSSSTicket;
