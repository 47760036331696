import { Fragment, useEffect, useRef, useState } from "react";
import { ChevronDown, Grid, List } from "react-feather";
import { Range, getTrackBackground } from "react-range";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { Card, CardBody, CardHeader, Col, Form, Input, InputGroup, InputGroupText, Label, Modal, ModalHeader, Row } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import { remotePath } from "src/base/settings/Paths";
import { Btn, H4, H6, Image, LI, P, UL } from "../../AbstractElements";
import banner from "../../assets/images/ecommerce/banner.jpg";
import errorImg from "../../assets/images/search-not-found.png";
import { AddToCart, FancyShirt, FancyShirtPrice, Filters, NewProducts, Price, ProductDetails, ProductSizeArray, Quantity, ViewDetails } from "../../Constant";
import { ProductPrice } from "./ProductPage";

function updateProductGridColumns(columnsPerPage) {
  // Determine the correct column class based on appSettings.catalog_columns_per_page
  let columnClass = "col-12";
  if (columnsPerPage === 2) {
    columnClass = "col-12 col-md-6"; // 4 columns per row in a 12-column grid (3 items)
  }
  if (columnsPerPage === 3) {
    columnClass = "col-12 col-md-4"; // 3 columns per row in a 12-column grid (4 items)
  }
  if (columnsPerPage === 4) {
    columnClass = "col-12 col-md-3"; // 3 columns per row in a 12-column grid (4 items)
  }

  // Apply the correct column class to each product item
  return columnClass;
}

export const filteringBy = (list, filterPrice = { min: 0, max: 999999999 }, filterSize = "", sortBy = "") => {
  let actualProducts = list ? [...list] : [];

  if (filterPrice.min) {
    actualProducts = actualProducts.filter((prods) => prods.price >= filterPrice.min);
  }

  if (filterPrice.max) {
    actualProducts = actualProducts.filter((prods) => prods.price <= filterPrice.max);
  }
  if (filterSize) {
    actualProducts = actualProducts.filter((prods) => prods.size === filterSize);
  }

  if (sortBy) {
    if (sortBy === "HIGH_PRICE") {
      actualProducts = actualProducts.sort((a, b) => Number(b.price) - Number(a.price));
    }
    if (sortBy === "LOW_PRICE") {
      actualProducts = actualProducts.sort((a, b) => Number(a.price) - Number(b.price));
    }
    if (sortBy === "HIGH_ALPHABETICAL") {
      actualProducts = actualProducts.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
    }
    if (sortBy === "LOW_ALPHABETICAL") {
      actualProducts = actualProducts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
  }
  return actualProducts;
};

export function SkeletonLoader({ isLoading }) {
  return isLoading ? (
    ["", "", "", "", "", "", "", "", "", "", "", ""].map(() => {
      return (
        <div className="p-3 col-lg-4 col-md-6 col-sm-6 d-flex " style={CSS({ height: 400 })}>
          <div className="card w-100 my-2 shadow-2-strong">
            <div className="w-100 card-img-top skeleton p-3" style={CSS({ height: 250 })}></div>
            <div className="card-body d-flex flex-column">
              <h5 className="mb-1 me-1 fw-bold mb-2 skeleton"></h5>
              <div className="d-flex flex-row mb-3 skeleton" style={CSS({ height: 30 })}>
                <h5 className="mb-1 me-1"></h5>
                <span className="text-danger">
                  <s></s>
                </span>
              </div>
              <p className="card-text skeleton" style={CSS({ height: 70 })}></p>
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <></>
  );
}

export const SliderData = (props) => {
  const { star = "" } = props;
  return (
    <Fragment>
      <div className="item">
        <Row className="product-box">
          <Col md="6" className="product-img me-3">
            1
          </Col>
          <Col md="6" className="product-details text-start">
            {" "}
            {star}
            <P attrPara={{ className: "mb-0" }}>{FancyShirt}</P>
            <div className="product-price">{FancyShirtPrice}</div>
          </Col>
        </Row>
        <Row className="product-box">
          <Col md="6" className="product-img me-3">
            2
          </Col>
          <Col md="6" className="product-details text-start">
            {" "}
            {star}
            <P attrPara={{ className: "mb-0" }}>{FancyShirt}</P>
            <div className="product-price">{FancyShirtPrice}</div>
          </Col>
        </Row>
        <Row className="product-box">
          <Col md="6" className="product-img me-3">
            3
          </Col>
          <Col md="6" className="product-details text-start">
            {" "}
            {star}
            <P attrPara={{ className: "mb-0" }}>{FancyShirt}</P>
            <div className="product-price">{FancyShirtPrice}</div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export const Sorting = ({ setSortBy, sortBy }) => {
  const { trans } = useEBSSSLayout();
  const filterSortFunc = (value) => {
    setSortBy(value);
  };
  return (
    <Fragment>
      <Col md="9" sm="10" className="text-end">
        <div className="select2-drpdwn-product select-options d-inline-block" onChange={(e: any) => filterSortFunc(e.target.value)}>
          <Input className="form-control btn-square" type="select" name="select">
            <option value="">{trans("website.relevance")}</option>
            <option value="HIGH_PRICE">{trans("website.prices_high_to_low")}</option>
            <option value="LOW_PRICE">{trans("website.prices_low_to_high")}</option>
            <option value="LOW_ALPHABETICAL">{trans("website.alphabetical_a_to_z")}</option>
            <option value="HIGH_ALPHABETICAL">{trans("website.alphabetgical_z_to_a")}</option>
          </Input>
        </div>
      </Col>
    </Fragment>
  );
};

export const Category = ({ categories, stepSize, setStepSize, filterPrice, setFilterPrice, values, categoryPath, basePath, setValues }) => {
  const { trans } = useEBSSSLayout();
  const colorHandle = (event, color) => {
    var elems = document.querySelectorAll(".color-selector ul li");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    event.target.classList.add("active");
    //filterColor(color);
  };
  const clickBrandHendle = (event, brands) => {
    var index = brands.indexOf(event.target.value);
    if (event.target.checked === true) brands.push(event.target.value);
    else brands.splice(index, 1);
    //filterBrand(brands);
  };
  const ClickCategory = (result, event) => {
    var elems = document.querySelectorAll("div.checkbox-animated");
    categories.forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    event.target.classList.add("active");
    //filterCategory(result);
  };
  const priceHandle = (value) => {
    setValues([...value]);
    //filterPrice(value);
  };

  console.log("filterPrice, stepSize: ", filterPrice, stepSize);

  return (
    <Fragment>
      <div className="product-filter">
        <H6 attrH6={{ className: "f-w-600" }}>Category</H6>
        <div className="checkbox-animated mt-0">
          <div className="categories-list">
            <div className="product-cat ps-container ps-theme-default ps-active-y" id="categories">
              <ul className="treeview list-group list-group-flush">
                <li key={"all"} className="list-group-item">
                  <Link to={basePath ? `${process.env.PUBLIC_URL}${basePath}?page=1` : `${process.env.PUBLIC_URL}/crm/products?page=1`}>
                    <span>{trans("website.all")}</span>
                  </Link>
                </li>
                {categories?.map((cat) => {
                  return (
                    <li key={cat.token} className="list-group-item">
                      <Link
                        to={
                          categoryPath
                            ? `${process.env.PUBLIC_URL}${categoryPath}/${cat.slug}?page=1`
                            : `${process.env.PUBLIC_URL}/crm/products/category/${cat.slug}?page=1`
                        }
                      >
                        <span>{cat.title}</span>
                      </Link>
                      {cat.childrens && Array.isArray(cat.childrens) && cat.childrens?.length > 0 ? (
                        <ul className="list-group list-group-flush">
                          {cat.childrens?.map((catc) => {
                            return (
                              <li key={catc.token} className="list-group-item">
                                <Link
                                  to={
                                    categoryPath
                                      ? `${process.env.PUBLIC_URL}${categoryPath}/${catc.slug}?page=1`
                                      : `${process.env.PUBLIC_URL}/crm/products/category/${catc.slug}?page=1`
                                  }
                                >
                                  <span>{catc.title}</span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <></>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="product-filter d-none">
        <H6 attrH6={{ className: "f-w-600" }}>Brand</H6>
        <div className="checkbox-animated mt-0">
          {[].map((brand, index) => {
            return (
              <Label className="d-block" key={index}>
                <Input
                  className="checkbox_animated"
                  onClick={(e) => clickBrandHendle(e, [])}
                  value={brand}
                  defaultChecked={[].includes(brand) ? true : false}
                  id={brand}
                  type="checkbox"
                  data-original-title=""
                  title=""
                />
                {brand}
              </Label>
            );
          })}
        </div>
      </div>
      <div className="product-filter slider-product d-none">
        <H6 attrH6={{ className: "f-w-600" }}>Colors</H6>
        <div className="color-selector">
          <UL attrUL={{ className: "simple-list d-flex flex-row" }}>
            {[].map((color, i) => {
              return (
                <Fragment key={i}>
                  <LI
                    attrLI={{
                      className: color,
                      title: color,
                      onClick: (e) => colorHandle(e, color),
                    }}
                  ></LI>{" "}
                  &nbsp;
                </Fragment>
              );
            })}
          </UL>
        </div>
      </div>
      <div className="product-filter price-range pb-0">
        <H6 attrH6={{ className: "f-w-600" }}>{Price}</H6>
        <Range
          values={values}
          step={filterPrice?.max ? filterPrice?.max / 200 : 100}
          min={0}
          max={filterPrice?.max ? filterPrice?.max : 10000}
          onChange={(values) => {
            priceHandle(values);
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span style={CSS({ width: 100, fontSize: 12, textAlign: "center" })}>{values[0]}</span>
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values,
                    colors: ["#ccc", "#4d8aff", "#ccc"],
                    min: 0,
                    max: filterPrice?.max ? filterPrice?.max : 10000,
                  }),
                }}
              >
                {children}
              </div>
              <span style={CSS({ width: 100, fontSize: 12, textAlign: "end" })}>{values[1]}</span>
            </div>
          )}
          renderThumb={({ props }) => {
            const { key, ...otherProps } = props;
            return (
              <div
                key={key}
                {...otherProps}
                style={{
                  ...otherProps.style,
                  height: "16px",
                  width: "16px",
                  borderRadius: "60px",
                  backgroundColor: "#4d8aff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 2px 6px #AAA",
                }}
              ></div>
            );
          }}
        />
      </div>
    </Fragment>
  );
};

export const PrizeRange = ({ stepSize, setStepSize, filterPrice, setFilterPrice }) => {
  var settings = {
    slidesToShow: 1,
    swipeToSlide: false,
    arrows: false,
    dots: false,
  };
  const star = (
    <span>
      <i className="fa fa-star font-warning me-1"></i>
      <i className="fa fa-star font-warning me-1"></i>
      <i className="fa fa-star font-warning me-1"></i>
      <i className="fa fa-star font-warning me-1"></i>
      <i className="fa fa-star font-warning"></i>
    </span>
  );
  return (
    <div className="product-filter pb-0 new-products d-none">
      <H6 attrH6={{ className: "f-w-600" }}>{NewProducts}</H6>
      <div className="owl-carousel owl-theme" id="testimonial">
        <Slider {...settings}>
          <SliderData star={star} />
          <SliderData star={star} />
        </Slider>
      </div>
    </div>
  );
};

export const ProductModal = (props) => {
  const { appSettings } = useEBSSS();
  const { value = "", dataid = "", setOpenModal = "" } = props;
  const [open, setOpen] = useState(value); // eslint-disable-next-line
  const [stock, setStock] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [singleProduct, setSingleProduct] = useState<any>([]);
  useEffect(() => {
    [].forEach((product, i) => {
      if (product.id === dataid) {
        setSingleProduct(product);
      }
    }); // eslint-disable-next-line
  }, []);
  const changeQty = (e) => {
    setQuantity(parseInt(e.target.value));
  };
  const plusQty = () => {
    if (quantity >= 1) {
      setQuantity(quantity + 1);
    } else {
      setStock("Out of Stock !");
    }
  };
  const minusQty = () => {
    if (quantity > 1) {
      setStock("InStock");
      setQuantity(quantity - 1);
    }
  };
  const onCloseModal = () => {
    setOpen(false);
    setOpenModal(false);
  };
  const history = useNavigate();
  const AddToCarts = (item, quantity) => {
    //addToCart(item, quantity);
    history(`${process.env.PUBLIC_URL}/ecommerce/cart`);
  };
  return (
    <Fragment>
      <Modal className="modal-dialog modal-lg modal-dialog-centered" isOpen={open}>
        <ModalHeader>
          <Row className="product-box">
            <div className="product-img col-lg-6">
              {/* <Image
                attrImage={{
                  className: "img-fluid",
                  src: `${
                    singleProduct?.img &&
                    require("../../../assets/images/" + singleProduct?.img)
                  }`,
                  alt: "",
                }}
              /> */}
            </div>
            <Col lg="6" className="product-details text-start">
              <a href="#javascript">
                <H4>{singleProduct?.category}</H4>
              </a>
              <div className="product-price">
                {appSettings.currency_symbol}
                {singleProduct?.price}
                <del>
                  {appSettings.currency_symbol}
                  {singleProduct?.discountPrice}
                </del>
              </div>
              <div className="product-view">
                <H6 attrH6={{ className: "f-w-600" }}>{ProductDetails}</H6>
                <P attrPara={{ className: "mb-0" }}>{singleProduct?.discription}</P>
              </div>
              <div className="product-size">
                <UL attrUL={{ className: "simple-list flex-row" }}>
                  {ProductSizeArray.map((items, i) => (
                    <LI key={i}>
                      <Btn
                        attrBtn={{
                          color: "outline-light",
                          type: "button",
                          databsoriginaltitle: "",
                          title: "",
                        }}
                      >
                        {items}
                      </Btn>
                    </LI>
                  ))}
                </UL>
              </div>
              <div className="product-qnty">
                <H6 attrH6={{ className: "f-w-600" }}>{Quantity}</H6>
                <InputGroup style={{ width: "35%" }}>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: "bootstrap-touchspin-down",
                      onClick: minusQty,
                    }}
                  >
                    <i className="fa fa-minus"></i>
                  </Btn>
                  <InputGroupText className="bootstrap-touchspin-prefix" style={{ display: "none" }}></InputGroupText>
                  <Input className="touchspin text-center" type="text" name="quantity" value={quantity} onChange={(e) => changeQty(e)} />
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: "bootstrap-touchspin-up",
                      onClick: plusQty,
                    }}
                  >
                    <i className="fa fa-plus"></i>
                  </Btn>
                </InputGroup>
                <div className="addcart-btn">
                  <Link to={`${process.env.PUBLIC_URL}/ecommerce/cart`} className="btn btn-primary me-3" onClick={() => AddToCarts(singleProduct, quantity)}>
                    {AddToCart}
                  </Link>
                  <Link to={`${process.env.PUBLIC_URL}/ecommerce/productpage`} className="btn btn-primary">
                    {ViewDetails}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Btn
            attrBtn={{
              color: "transprant",
              className: "btn-close",
              onClick: onCloseModal,
              type: "button",
              databsdismiss: "modal",
              arialabel: "Close",
            }}
          ></Btn>
        </ModalHeader>
      </Modal>
    </Fragment>
  );
};

export const ProductGrid = ({ products, values, sortBy, filterSize, trans, perLine }) => {
  const [toShowGrid, setToShowGrid] = useState(false);
  const { appSettings, loaded } = useEBSSS();
  const quantity = 1;
  const [dataid, setDataid] = useState();
  const [openModal, setOpenModal] = useState(false);
  const history = useNavigate();
  const onClickHandle = (i) => {
    setOpenModal(true);
    setDataid(i.id);
  };

  const AddToCarts = (item, quantity) => {
    //addToCart(item, quantity);
    history(`${process.env.PUBLIC_URL}/ecommerce/cart`);
  };

  useEffect(() => {
    setToShowGrid(!loaded);
  }, [loaded]);

  useEffect(() => {
    if (!toShowGrid) {
      let fadeGrid = setTimeout(() => {
        document.querySelector(".product-wrapper-grid")?.classList?.add("show");
        clearTimeout(fadeGrid);
      }, 150);
    }
  }, [toShowGrid]);

  return (
    <Fragment>
      <div className={`product-wrapper-grid fade-container-products `}>
        <Row className="gridRow">
          {true ? (
            filteringBy(products?.data, { min: values[0], max: values[1] }, filterSize, sortBy)?.map((item) => {
              return (
                <div
                  id="gridId"
                  className={`${
                    // layoutColumns === 3
                    //   ? "ebsss-hover-grid-item col-xl-3 col-lg-6 col-sm-6 xl-4"
                    //   : "ebsss-hover-grid-item col-xl-" + layoutColumns
                    `ebsss-hover-grid-item ${updateProductGridColumns(Number(perLine))}`
                  }`}
                  key={item.id}
                >
                  <Card
                    className="product-box-card"
                    style={CSS({ cursor: "pointer" })}
                    onClick={() => history(`${process.env.PUBLIC_URL}/crm/products/${item.slug}`)}
                  >
                    <div className="product-box">
                      <div className="product-img" style={CSS({ minHeight: 300 })}>
                        {item.status === "sale" && <span className="ribbon ribbon-danger">{item.status}</span>}
                        {item.status === "50%" && <span className="ribbon ribbon-success ribbon-right">{item.status}</span>}
                        {item.status === "gift" && (
                          <span className="ribbon ribbon-secondary ribbon-vertical-left">
                            <i className="icon-gift">{item.status}</i>
                          </span>
                        )}
                        {item.status === "love" && (
                          <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
                            <i className="icon-heart">{item.status}</i>
                          </span>
                        )}
                        {item.status === "Hot" && <span className="ribbon ribbon ribbon-clip ribbon-warning">{item.status}</span>}{" "}
                        <Image
                          attrImage={{
                            className: "img-fluid w-100 h-100",
                            src: `${item.photo ? remotePath.assetsUrl(item.photo) : "https://www.coop.se/Assets/Images/missing-image-product.png"}`,
                            alt: "",
                          }}
                        />
                        <div className="product-hover d-none">
                          <UL attrUL={{ className: "simple-list" }}>
                            <LI>
                              <Btn
                                attrBtn={{
                                  color: "transprant",
                                  type: "button",
                                  onClick: () => AddToCarts(item, quantity),
                                }}
                              >
                                <i className="icon-shopping-cart"></i>
                              </Btn>
                            </LI>
                            <LI>
                              <Btn
                                attrBtn={{
                                  color: "transprant",
                                  onClick: () => onClickHandle(item),
                                  type: "button",
                                  datatoggle: "modal",
                                  datatarget: "#exampleModalCenter",
                                }}
                              >
                                <i className="icon-eye" onClick={() => setOpenModal(true)}></i>
                              </Btn>{" "}
                            </LI>
                          </UL>
                        </div>
                      </div>
                      <div className="product-details">
                        <div className="rating d-none">
                          <i className="fa fa-star font-warning"></i>
                          <i className="fa fa-star font-warning"></i>
                          <i className="fa fa-star font-warning"></i>
                          <i className="fa fa-star font-warning"></i>
                          <i className="fa fa-star font-warning"></i>
                        </div>
                        <Link to={`${process.env.PUBLIC_URL}/crm/products/${item.slug}`} className="fs-5">
                          {item.name}
                        </Link>
                        <P>{item.note}</P>
                        <div className="product-price mt-3">
                          <ProductPrice
                            optionProductSelected={null}
                            product={item}
                            trans={trans}
                            appSettings={appSettings}
                            currencySymbol={appSettings?.currency_symbol}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })
          ) : (
            <Image
              attrImage={{
                className: "img-fluid m-auto",
                src: errorImg,
                alt: "",
              }}
            />
          )}
          {openModal && <ProductModal value={openModal} setOpenModal={setOpenModal} dataid={dataid} />}
        </Row>
      </div>
    </Fragment>
  );
};

export const ProductTotal = ({ setSortBy, sortBy }) => {
  const gridLayout = () => {
    document.querySelector(".product-wrapper-grid").classList.remove("list-view");
    var elems = document.querySelector(".gridRow").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = "";
      el.classList.add("col-xl-3");
      el.classList.add("col-sm-6");
      el.classList.add("xl-4");
    });
  };
  const listLayout = () => {
    document.querySelector(".product-wrapper-grid").classList.add("list-view");
    var elems = document.querySelector(".gridRow").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = "";
      el.classList.add("col-xl-12");
    });
  };
  const LayoutView = (layoutColumns) => {
    if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
      var elems = document.querySelector(".gridRow").childNodes;
      [].forEach.call(elems, function (el) {
        el.className = "";
        el.classList.add("col-xl-" + layoutColumns);
      });
    }
  };
  return (
    <Fragment>
      <Row className="m-b-10">
        <Col md="3" sm="2" className="products-total">
          {/* <>
          <div className="square-product-setting d-inline-block">
            <a className="icon-grid grid-layout-view" onClick={gridLayout} href="#javascript">
              <Grid />
            </a>
          </div>
          <div className="square-product-setting d-inline-block">
            <a className="icon-grid m-0 list-layout-view" onClick={listLayout} href="#javascript">
              <List />
            </a>
          </div>
          </> */}
          <div
            className="d-none-productlist filter-toggle"
            onClick={() => {
              /*setFilterSidebar(!filterSidebar)*/
            }}
          >
            <H6 attrH6={{ className: "mb-0" }}>
              Filters
              <span className="ms-2">
                <ChevronDown className="toggle-data " />
              </span>
            </H6>
          </div>
          {false ? (
            <div className="grid-options d-inline-block">
              <UL attrUL={{ className: "simple-list", as: "ul" }}>
                <LI>
                  <a className="product-2-layout-view" onClick={() => LayoutView(6)} href="#javascript">
                    <span className="line-grid line-grid-1 bg-primary"></span>
                    <span className="line-grid line-grid-2 bg-primary"></span>
                  </a>
                </LI>
                <LI>
                  <a className="product-3-layout-view" onClick={() => LayoutView(4)} href="#javascript">
                    <span className="line-grid line-grid-3 bg-primary"></span>
                    <span className="line-grid line-grid-4 bg-primary"></span>
                    <span className="line-grid line-grid-5 bg-primary"></span>
                  </a>
                </LI>
                <LI>
                  <a className="product-4-layout-view" onClick={() => LayoutView(3)} href="#javascript">
                    <span className="line-grid line-grid-6 bg-primary"></span>
                    <span className="line-grid line-grid-7 bg-primary"></span>
                    <span className="line-grid line-grid-8 bg-primary"></span>
                    <span className="line-grid line-grid-9 bg-primary"></span>
                  </a>{" "}
                </LI>
                <LI>
                  <a className="product-6-layout-view" onClick={() => LayoutView(2)} href="#javascript">
                    <span className="line-grid line-grid-10 bg-primary"></span>
                    <span className="line-grid line-grid-11 bg-primary"></span>
                    <span className="line-grid line-grid-12 bg-primary"></span>
                    <span className="line-grid line-grid-13 bg-primary"></span>
                    <span className="line-grid line-grid-14 bg-primary"></span>
                    <span className="line-grid line-grid-15 bg-primary"></span>
                  </a>
                </LI>
              </UL>
            </div>
          ) : (
            <></>
          )}
        </Col>
        <Sorting setSortBy={setSortBy} sortBy={sortBy} />
      </Row>
    </Fragment>
  );
};

export const ProductSidebar = ({
  categories,
  sortBy,
  setSortBy,
  filterSize,
  setFilterSize,
  stepSize,
  setStepSize,
  filterPrice,
  setFilterPrice,
  values,
  setValues,
  categoryPath,
  searchPath,
  basePath,
}: any) => {
  const { trans } = useEBSSSLayout();
  const [sidebaron, setSidebaron] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const debounceTimeoutRef = useRef(null);
  const history = useNavigate();
  const params = useParams();

  console.log("params: ", params);

  useEffect(() => {
    if (params.name) {
      setSearchKeyword(params.name);
    }
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  const handleChangeBouncer = (event, value: string) => {
    if (!value) {
      history(searchPath ? `${process.env.PUBLIC_URL}${basePath}?page=1` : `${process.env.PUBLIC_URL}/crm/products?page=1`);
      return;
    }

    setSearchKeyword(value);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      history(searchPath ? `${process.env.PUBLIC_URL}${searchPath}/${value}?page=1` : `${process.env.PUBLIC_URL}/crm/products/search/${value}?page=1`);
    }, 1500);
  };

  const onClickFilter = () => {
    setSidebaron((prevSidebaron) => !prevSidebaron);
    const productWrapper = document.querySelector(".product-wrapper");
    if (productWrapper) {
      productWrapper.classList.toggle("sidebaron", !sidebaron);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col md="12">
          <div className="pro-filter-sec">
            <div className={`product-sidebar ${sidebaron ? "" : "open"}`}>
              <div className="filter-section">
                <Card>
                  <CardHeader style={CSS({ cursor: "pointer" })} onClick={onClickFilter}>
                    <H6 attrH6={{ className: "mb-0 f-w-600" }}>
                      {trans("website.filters")}
                      <span className="pull-right">
                        <i className="fa fa-chevron-down toggle-data"></i>
                      </span>
                    </H6>
                  </CardHeader>
                  <div className="left-filter">
                    <CardBody className="filter-cards-view animate-chk">
                      <Category
                        values={values}
                        setValues={setValues}
                        categories={categories}
                        stepSize={stepSize}
                        setStepSize={setStepSize}
                        filterPrice={filterPrice}
                        setFilterPrice={setFilterPrice}
                        categoryPath={categoryPath}
                        basePath={basePath}
                      />
                      <PrizeRange stepSize={stepSize} setStepSize={setStepSize} filterPrice={filterPrice} setFilterPrice={setFilterPrice} />
                      <div className="product-filter text-center d-none">
                        <Image
                          attrImage={{
                            className: "img-fluid banner-product",
                            src: `${banner}`,
                            alt: "",
                          }}
                        />
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </div>
            <div className="product-search">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (debounceTimeoutRef.current) {
                    clearTimeout(debounceTimeoutRef.current);
                  }
                  history(
                    searchPath
                      ? `${process.env.PUBLIC_URL}${searchPath}/${searchKeyword}?page=1`
                      : `${process.env.PUBLIC_URL}/crm/products/search/${searchKeyword}?page=1`
                  );
                }}
              >
                <div className="form-group m-0">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    id="product-query"
                    value={searchKeyword}
                    onChange={(e) => handleChangeBouncer(e, e.target.value)}
                  />
                  <i className="fa fa-search"></i>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export const ProductFeatures = ({
  categories,
  sortBy,
  setSortBy,
  filterSize,
  setFilterSize,
  stepSize,
  setStepSize,
  filterPrice,
  setFilterPrice,
  values,
  setValues,
  categoryPath,
  searchPath,
  basePath,
}: any) => {
  return (
    <Fragment>
      <div className="feature-products">
        <ProductTotal setSortBy={setSortBy} sortBy={sortBy} />
        <ProductSidebar
          values={values}
          setValues={setValues}
          categories={categories}
          sortBy={sortBy}
          setSortBy={setSortBy}
          filterSize={filterSize}
          setFilterSize={setFilterSize}
          stepSize={stepSize}
          setStepSize={setStepSize}
          filterPrice={filterPrice}
          setFilterPrice={setFilterPrice}
          categoryPath={categoryPath}
          searchPath={searchPath}
          basePath={basePath}
        />
      </div>
    </Fragment>
  );
};
