import { Fragment, useEffect, useState } from "react";
import { Lock, Mail } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";

import { Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import EBSSSButton from "src/base/atoms/EBSSSButton";
import FeedbackMessages from "src/base/FeedbackMessage";
import { cleanErrorsAndMessages, fetchSignin, Session } from "src/base/store/authSlice";
import { ReduxDataAuthKey, ReduxDataKey } from "src/base/store/store";
import MediaIcons from "src/CommonElements/MediaIcons";
import { Layout } from "src/base/store/layoutSlice";
import LoginForm from "./LoginForm";
import { Image } from "src/AbstractElements";
import { CSS } from "src/_utils/styles";
import { remotePath } from "src/base/settings/Paths";

const LoginPage = () => {
  const { trans } = useEBSSSLayout();
  const dispatcher = useDispatch<any>();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const ebsssLayout: Layout = useSelector((state) => state[ReduxDataKey.LAYOUT]);
  const navigate = useNavigate();

  useEffect(() => {
    dispatcher(cleanErrorsAndMessages());
  }, []);

  useEffect(() => {
    let dettachCMSTemplate = Array.from(document.querySelectorAll('[cms="true"], [href*="cms=true"], [src*="cms=true"]'));
    console.log("dettachCMSTemplate: ", dettachCMSTemplate);
    dettachCMSTemplate.forEach((el) => el.remove());
    return () => {
      let dettachCMSTemplate = Array.from(document.querySelectorAll('[cms="true"], [href*="cms=true"], [src*="cms=true"]'));
      console.log("dettachCMSTemplate: ", dettachCMSTemplate);
      dettachCMSTemplate.forEach((el) => el.remove());
    };
  }, []);

  console.log(ebsssLayout?.commonAppSettings);

  return sessionNow?.user?.id ? (
    <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />
  ) : (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col
            xl="7 order-1"
            style={{
              backgroundImage: `url("https://micaela.3.ebsss.dev/platform-images/settings/customer_area_background/7679a69f-f07c-4c7c-9af4-f43d771cee18.jpg?p=slider&s=09962273dea6f982797368e0cd6b4f6b")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "block",
            }}
          ></Col>
          <Col xl="5 p-0">
            <div className="login-card1">
              <Form className="theme-form login-form">
                <div className="d-flex flex-row w-100 m-auto mb-4">
                  {ebsssLayout?.commonAppSettings?.customer_area_logo ? (
                    <Image
                      attrImage={{
                        style: CSS({ cursor: "pointer" }),
                        onClick: () => navigate(process.env.PUBLIC_URL),
                        className: "align-self-center m-auto",
                        src: remotePath.assetsUrl(ebsssLayout?.commonAppSettings?.customer_area_logo),
                        alt: "logo",
                        width: "125px",
                        height: "100%",
                      }}
                    />
                  ) : (
                    <h1>{ebsssLayout?.commonAppSettings?.instance_title}</h1>
                  )}
                </div>
                <LoginForm />
                {false ? (
                  <>
                    <div className="login-social-title">
                      <h5>Sign in with Email</h5>
                    </div>
                    <FormGroup>
                      <MediaIcons />
                    </FormGroup>
                  </>
                ) : (
                  <></>
                )}
                <p>
                  {trans("auth.create-account-title")}
                  <Link to={`${process.env.PUBLIC_URL}/auth/sign-up`} className="ms-2">
                    {trans("auth.create-account")}
                  </Link>
                </p>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default LoginPage;
