import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { scrollUpPage } from "src/_utils/elements";
import { CSS } from "src/_utils/styles";
import { getQueryParams } from "src/_utils/urls";
import { Btn } from "src/AbstractElements";
import EBSSSTable from "src/base/atoms/EBSSSTable";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import TablePage from "src/base/TablePage";

const ContractsPage = () => {
  const { trans } = useEBSSSLayout();
  const { fetchContracts, contracts } = useEBSSS();
  const [page, setPage] = useState(null);

  useEffect(() => {
    setPage((p) => {
      if (p !== null) {
        return p;
      } else {
        return Number(getQueryParams(window.location.href)?.page);
      }
    });
  }, [window.location.search]);

  useEffect(() => {
    scrollUpPage();
    if (!contracts) {
      fetchContracts(1);
      return;
    }
    if (page) {
      fetchContracts(page);
    }
  }, [page]);

  console.log(contracts);

  return (
    <TablePage
      breads={[
        {
          name: trans("website.contracts"),
          active: true,
          url: "/crm/contracts",
        },
      ]}
      title=""
      description=""
    >
      <EBSSSTable
        pageHandler={setPage}
        data={contracts}
        cols={[
          {
            name: "#",
            selector: (row: { id: any }) => <span>{row.id}</span>,
            sortable: true,
            center: true,
          },
          {
            name: trans("billing.contract-product"),
            selector: (row: any) => (row.product ? row.product?.name : ""),
            sortable: true,
            center: false,
          },
          {
            name: trans("billing.contract-information"),
            selector: (row: any) =>
              row.information ? row.information : trans("website.no-data"),
            sortable: true,
            center: false,
          },
          {
            name: trans("common.created_at"),
            selector: (row: { created_at: any }) => row.created_at,
            sortable: true,
            center: false,
          },
          {
            name: trans("common.status"),
            selector: (row: any) => {
              return (
                <span
                  className={`badge badge-default badge-${
                    row.status === "active"
                      ? "success"
                      : row.status === "waiting-payment"
                      ? "warning"
                      : row.status === "to-active"
                      ? "primary"
                      : "danger"
                  }`}
                >
                  {`${trans("website.contract-status-" + row.status)}`}
                </span>
              );
            },
            sortable: true,
            center: false,
          },
          {
            name: trans("billing.frequency"),
            selector: (row: { frequency: any }) =>
              trans("billing.payment_frequency_" + row.frequency),
            sortable: true,
            center: false,
          },
          {
            name: "",
            selector: (row: any): any => {
              return (
                <div style={CSS({ gap: 5, display: "flex" })}>
                  <Link
                    to={process.env.PUBLIC_URL + "/crm/contracts/" + row.token}
                    state={{
                      order: { ...row, settings: contracts?.data["settings"] },
                    }}
                  >
                    <Btn
                      className="text-white btn-pill"
                      attrBtn={{
                        color: "info",
                        className: "info",
                      }}
                    >
                      {trans("common.see")}
                    </Btn>
                  </Link>
                </div>
              );
            },
            sortable: true,
            center: true,
          },
        ]}
      />
    </TablePage>
  );
};
export default ContractsPage;
