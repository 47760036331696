import { Fragment, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { scrollUpPage } from "src/_utils/elements";
import { getQueryParams } from "src/_utils/urls";
import { EBSSSPagination } from "src/base/atoms/EBSSSPagination";
import { useEBSSS } from "src/base/context/EBSSSContext/useEBSSS";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";
import Page from "src/base/Page";
import { ProductFeatures, ProductGrid, SkeletonLoader } from "./ProductsCommon";
import { Session } from "src/base/store/authSlice";
import { Layout } from "src/base/store/layoutSlice";
import { useSelector } from "react-redux";
import { ReduxDataKey } from "src/base/store/store";

const ProductsPage = () => {
  const { trans } = useEBSSSLayout();
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);
  const { apiErrors, products, categories, loaded, appSettings, fetchProducts } = useEBSSS();
  const getCategory = useParams();
  const location = useLocation();
  const params = useParams();
  const [page, setPage] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [filterSize, setFilterSize] = useState(undefined);
  const [stepSize, setStepSize] = useState(0);
  const [values, setValues] = useState([1, 100]);
  const [filterPrice, setFilterPrice] = useState({
    min: 0,
    max: undefined,
  });
  const navigate = useNavigate();

  console.log("sessionNow: ", sessionNow);

  useEffect(() => {
    if (!!products && Array.isArray(products?.data) && products?.data?.length) {
      let filtersPriceMinMax = {
        ...filterPrice,
        max: Math.ceil(Number(products?.data?.sort((a, b) => Number(b.price) - Number(a.price))[0]?.price)),
      };
      setFilterPrice({
        ...filtersPriceMinMax,
      });
      setStepSize(
        //@ts-ignore
        String(products?.data?.sort((a, b) => Number(b.price) - Number(a.price))[0]?.price ?? 0) / 20
      );
      setValues([filtersPriceMinMax.min, filtersPriceMinMax.max]);
    }
  }, [products?.data]);

  useEffect(() => {
    let actualParams = getQueryParams(window.location.href);
    if (actualParams?.page) {
      setPage((p) => {
        if (p !== null) {
          return p;
        } else {
          return Number(actualParams?.page);
        }
      });
    }
  }, [window.location.search]);

  useEffect(() => {
    scrollUpPage();
    if (products?.data) {
      if (page && page > 0) {
        fetchProducts(page);
      }
    }
  }, [page]);

  useEffect(() => {
    scrollUpPage();
    let actualParams = getQueryParams(window.location.href);
    if (!products?.data) {
      fetchProducts(actualParams?.page);
    }
  }, []);

  useEffect(() => {
    if (!sessionNow?.user?.id && apiErrors && !apiErrors?.public_products) {
      navigate(`${process.env.PUBLIC_URL}/auth/sign-in`);
    }
  }, [apiErrors]);

  return (
    <Page
      breads={[
        {
          name: `${trans("website.products")}`,
          active: true,
          url: "/crm/products?page=1",
        },
      ]}
    >
      <Fragment>
        <Container fluid={true} className="product-wrapper">
          <div className="product-grid mb-3">
            <ProductFeatures
              values={values}
              setValues={setValues}
              categories={categories}
              sortBy={sortBy}
              setSortBy={setSortBy}
              filterSize={filterSize}
              setFilterSize={setFilterSize}
              stepSize={stepSize}
              setStepSize={setStepSize}
              filterPrice={filterPrice}
              setFilterPrice={setFilterPrice}
            />
            {!loaded ? (
              <div className="d-flex flex-row flex-wrap">
                <SkeletonLoader isLoading={!loaded} />
              </div>
            ) : (
              <ProductGrid
                perLine={appSettings?.catalog_columns_per_page}
                products={products}
                trans={trans}
                values={values}
                sortBy={sortBy}
                filterSize={filterSize}
              />
            )}
            <EBSSSPagination page={page} data={products} />
          </div>
        </Container>
      </Fragment>
    </Page>
  );
};
export default ProductsPage;
