import { Fragment, useState } from "react";
import { Link as LinkIcon } from "react-feather";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { CSS } from "src/_utils/styles";
import { buildQueryParamUrl, getQueryParams } from "src/_utils/urls";
import { LI, UL } from "src/AbstractElements";
import { useEBSSSLayout } from "src/base/context/EBSSSLayoutContext/useEBSSSLayout";

export const emailSidbar = [
  {
    id: 1,
    classname: "la la-folder-open",
    title: (trans) => trans("website.contacts-all"),
    category: "ALL",
    count_key: "total_all",
    badge: (val) => val,
    line: "",
  },
  {
    id: 2,
    classname: "la la-paper-plane",
    title: (trans) => trans("website.contacts-sent"),
    category: "SENT",
    count_key: "total_sent",
    badge: (val) => val,
    line: "",
  },
  {
    id: 3,
    classname: "la la-mail-forward",
    title: (trans) => trans("website.contacts-received"),
    category: "RECEIVED",
    count_key: "total_received",
    badge: (val) => val,
    line: "",
  },
  {
    id: 3,
    classname: "la la-trash",
    title: (trans) => trans("website.contacts-trash"),
    category: "TRASH",
    count_key: "total_trash",
    badge: (val) => val,
    line: "",
  },
];

export const filtersSidebar = [
  {
    id: 0,
    classname: "la la-star-o",
    showKey: "menu_show_all",
    title: (trans) => trans("website.contacts-all"),
    filter: "",
    count_key: "",
    badge: (val) => val,
    line: "",
  },
  {
    id: 1,
    classname: "la la-star-o",
    showKey: "menu_show_orders",
    title: (trans) => trans("website.contacts-orders"),
    filter: "ORDERS",
    count_key: "orders",
    badge: (val) => val,
    line: "",
  },
  {
    id: 2,
    classname: "la la-star-o",
    showKey: "menu_show_reservations",
    title: (trans) => trans("website.contacts-reservations"),
    filter: "RESERVATIONS",
    count_key: "reservations",
    badge: (val) => val,
    line: "",
  },
  {
    id: 3,
    classname: "la la-star-o",
    showKey: "menu_show_contracts",
    title: (trans) => trans("website.contacts-subscriptions"),
    filter: "SUBSCRIPTIONS",
    count_key: "subscriptions",
    badge: (val) => val,
    line: "",
  },
  {
    id: 4,
    classname: "la la-star-o",
    showKey: "menu_show_projects",
    title: (trans) => trans("website.contacts-projects"),
    filter: "PROJECTS",
    count_key: "projects",
    badge: (val) => val,
    line: "",
  },
  {
    id: 5,
    classname: "la la-star-o",
    showKey: "menu_show_leads",
    title: (trans) => trans("website.contacts-leads"),
    filter: "LEADS",
    count_key: "leads",
    badge: (val) => val,
    line: "",
  },
];

const ContactCenterSidebar = ({ filters, appSettings, isFromCategory }: any) => {
  const { trans } = useEBSSSLayout();
  const [IsOpen, setIsOpen] = useState("");
  const handleFilterSidebar = (p) => {
    if (IsOpen !== "") {
      setIsOpen("");
      return;
    }
    setIsOpen(p);
  };
  const navigate = useNavigate();
  const getFilteringEntity = getQueryParams(window.location.href);
  const getFilteringCategory = useParams();

  console.log(getFilteringEntity, getFilteringCategory);

  return appSettings ? (
    <Fragment>
      <div className="md-sidebar job-sidebar">
        <a className="btn btn-primary md-sidebar-toggle" href="#javascript" onClick={() => handleFilterSidebar("geral")}>
          {trans("website.geral_filter")}
        </a>
        <div style={CSS({ marginTop: -17.5 })} className={`md-sidebar-aside job-left-aside custom-scrollbar ${IsOpen === "geral" ? "open" : ""}`}>
          <div className="email-left-aside">
            <Card>
              <CardBody>
                <div className="email-app-sidebar">
                  <UL
                    attrUL={{
                      className: "simple-list nav main-menu ",
                      role: "tablist",
                    }}
                  >
                    <LI attrLI={{ className: "nav-item d-none" }}>
                      <LinkIcon className="btn-primary btn-block btn-mail w-100" to={`${process.env.PUBLIC_URL}/email/compose`}>
                        <i className="icofont icofont-envelope me-2"></i> NEW MAIL
                      </LinkIcon>
                    </LI>
                    <LI attrLI={{ className: "nav-item d-none" }}>
                      <a className="show" href="#javascript">
                        <span className="title">
                          <i className="icon-import"></i> Inbox
                        </span>
                        <span className="badge pull-right">(236)</span>
                      </a>
                    </LI>
                    {emailSidbar &&
                      emailSidbar.map((item, idx) => {
                        return (
                          <LI
                            key={item.id}
                            attrLI={{
                              className:
                                getFilteringCategory?.category === item.category || (item.category === "ALL" && !getFilteringCategory?.category)
                                  ? "shadow-sm border-2 rounded-2"
                                  : "",
                            }}
                          >
                            {!item.line ? (
                              <Link
                                to={
                                  item.category === "ALL"
                                    ? process.env.PUBLIC_URL + "/crm/contacts/center?page=1"
                                    : process.env.PUBLIC_URL + "/crm/contacts/center/category/" + item.category + "?page=1"
                                }
                                className={filters?.filter && filters?.filter === item.category ? "shadow-sm border-2 rounded-2" : ""}
                                style={CSS({ cursor: "pointer" })}
                              >
                                <span className="title">
                                  <i className={item.classname}></i>
                                  {item.title(trans)}
                                </span>
                                <span className="badge pull-right">{item.badge(appSettings[item?.count_key] ?? 0)}</span>
                              </Link>
                            ) : (
                              item.line
                            )}
                          </LI>
                        );
                      })}
                  </UL>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      <div className="md-sidebar job-sidebar mt-3">
        <a className="btn btn-primary md-sidebar-toggle" href="#javascript" onClick={() => handleFilterSidebar("entities")}>
          {trans("website.entity_filter")}
        </a>
        <div style={CSS({ marginTop: 37.5 })} className={`md-sidebar-aside job-left-aside custom-scrollbar ${IsOpen === "entities" ? "open" : ""}`}>
          <div className="email-left-aside">
            <Card>
              <CardBody>
                <div className="email-app-sidebar">
                  <UL
                    attrUL={{
                      className: "simple-list nav main-menu ",
                      role: "tablist",
                    }}
                  >
                    <LI attrLI={{ className: "nav-item d-none" }}>
                      <LinkIcon className="btn-primary btn-block btn-mail w-100" to={`${process.env.PUBLIC_URL}/email/compose`}>
                        <i className="icofont icofont-envelope me-2"></i> NEW MAIL
                      </LinkIcon>
                    </LI>
                    <LI attrLI={{ className: "nav-item d-none" }}>
                      <a className="show" href="#javascript">
                        <span className="title">
                          <i className="icon-import"></i> Inbox
                        </span>
                        <span className="badge pull-right">(236)</span>
                      </a>
                    </LI>
                    {filtersSidebar &&
                      filtersSidebar
                        ?.filter((i) => i.showKey === "menu_show_all" || appSettings[i.showKey])
                        ?.map((item, idx) => {
                          return (
                            <LI
                              key={item.id}
                              attrLI={{
                                className:
                                  getFilteringEntity?.entity === item.filter || (item.filter === "" && !getFilteringEntity?.entity)
                                    ? "shadow-sm border-2 rounded-2"
                                    : "",
                              }}
                            >
                              {!item.line ? (
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/crm/contacts/center" +
                                    (isFromCategory ? "/category/" + isFromCategory : "") +
                                    buildQueryParamUrl({
                                      ...getQueryParams(window.location.href),
                                      ...{ filters },
                                      entity: item.filter,
                                      page: 1,
                                    })
                                  }
                                  className={filters?.filter && filters?.filter === item.filter ? "shadow-sm border-2 rounded-2" : ""}
                                  style={CSS({ cursor: "pointer" })}
                                >
                                  <span className="title">
                                    <i className={item.classname}></i>
                                    {item.title(trans)}
                                  </span>
                                  <span className="d-none badge pull-right">{item.badge(appSettings[item?.count_key ?? "count"] ?? 0)}</span>
                                </Link>
                              ) : (
                                item.line
                              )}
                            </LI>
                          );
                        })}
                  </UL>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      <div className="job-sidebar mt-3">
        <div className="email-left-aside">
          <Card>
            <CardBody>
              <div className="email-app-sidebar">
                <UL
                  attrUL={{
                    className: "simple-list nav main-menu ",
                    role: "tablist",
                  }}
                >
                  <LI attrLI={{ className: "nav-item" }}>
                    <Link to={process.env.PUBLIC_URL + "/forms/replied/filter/all"}>
                      <i className="la la-envelope me-2"></i> {trans("website.replied-forms")}
                    </Link>
                  </LI>
                </UL>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </Fragment>
  ) : (
    <></>
  );
};

export default ContactCenterSidebar;
