import { CSSProperties } from "react";
import { useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import { Session } from "../store/authSlice";
import { ReduxDataKey } from "../store/store";

const EBSSSButton = ({
  onClick,
  color,
  style,
  children,
  loadingKey,
  customClassName,
  disableDefaultColor = false,
  type = "button",
}: {
  children: string | JSX.Element;
  onClick?: (args?) => void | Promise<void>;
  color?: string;
  style?: CSSProperties;
  loadingKey?: string | boolean;
  customClassName?: string;
  disableDefaultColor?: boolean;
  type?: "submit" | "button";
}) => {
  const sessionNow: Session = useSelector((state) => state[ReduxDataKey.AUTH]);

  return (
    <Button
      type={type}
      color={color ?? (disableDefaultColor ? "" : "primary")}
      onClick={onClick}
      className={
        customClassName ? customClassName : "btn-block d-flex flex-row gap-2 align-items-center primary-button-cart secondary-hover-button-cart text-white"
      }
      style={style}
    >
      {(typeof loadingKey !== "string" ? loadingKey : sessionNow?.loading[loadingKey]) ? <Spinner size={"sm"} /> : <></>}
      {children}
    </Button>
  );
};

export default EBSSSButton;
